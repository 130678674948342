import { useEffect, useState } from 'react';

import { tutorTabs, pageHeading } from 'pages/auth/myTutors/data';

import MyTutorsSearch from 'pages/auth/myTutors/components/MyTutorsSearch';
import AllTutors from 'pages/auth/myTutors/components/AllTutors';
import FavTutors from 'pages/auth/myTutors/components/FavTutors';

import PageHeader from 'components/pageHeader/PageHeader';
import Tabs from "components/tabs/Tabs";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const MyTutorsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    const [myActiveTab, setMyActiveTab] = useState(tutorTabs.ALL_TUTORS.value)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MY_TUTORS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className='h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <MyTutorsSearch myActiveTab={myActiveTab} />
            <Tabs
                tabs={Object.values(tutorTabs)}
                handleTabs={(activeTab) => {
                    setMyActiveTab(activeTab.value)
                }}
            />
            {myActiveTab === tutorTabs.ALL_TUTORS.value &&
                <AllTutors myActiveTab={myActiveTab} />
            }
            {myActiveTab === tutorTabs.FAV_TUTORS.value &&
                <FavTutors />
            }
        </div>
    );
};

export default MyTutorsPage;
