import clsx from "clsx";
import * as React from "react";
import { FaPen } from "react-icons/fa";
import { useAppState } from "hooks/useStore";

const BillingAddress = "billing@edulyte.com";

export default function BillingInformationCard() {
  const state = useAppState(
    (s) => s.subscriptions.billingInformation.cardInformation
  );

  const onEditPaymentDetails = () => { };
  return (
    <div className="p-4 bg-white rounded-lg shadow-md sm:flex">
      <div className="flex-1 pb-4">
        <div className="flex mb-4 space-x-4">
          <p className="text-xl font-semibold text-left text-sky-500">
            Payment Details
          </p>
          <button onClick={onEditPaymentDetails}>
            <FaPen className="text-sky-500" />
          </button>
        </div>
        <div className="grid grid-cols-10">
          <p className="col-span-4 text-base font-semibold tracking-wide text-slate-500">
            Credit Card
          </p>
          <p className="col-span-6 text-base text-sky-900">
            {state.cardNumber}
          </p>
          <p className="col-span-4 text-base font-semibold tracking-wide text-slate-500">
            Expiry Date
          </p>
          <p className="col-span-6 text-base text-sky-900">
            {state.expiryDate}
          </p>
        </div>
      </div>
      <div
        className={clsx("flex-1 p-2 space-y-6 bg-slate-50", "text-center")}
      >
        <div className="pb-4">
          <p className="pb-2 font-semibold text-slate-600">
            About Your Subscription
          </p>
          <p className="text-slate-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero
            dolorum et perspiciatis ex labore, atque quas expedita
            exercitationem odio necessitatibus officiis modi? Corrupti,
          </p>
        </div>
        <p>
          For all billing enquiries please contact us at{" "}
          <a href={BillingAddress} type="email">
            {BillingAddress}
          </a>
        </p>
      </div>
    </div>
  );
}
