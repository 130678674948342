import { useState, useEffect } from "react";
import SelectSearch, { fuzzySearch } from "react-select-search";
import clsx from "clsx";

import { FaPlusCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import { FaInfoCircle } from 'react-icons/fa';

import { IconWithTextButton } from "components/common-components/Buttons";

import FullPageLoader from "components/loader/FullPageLoader";

import { subjectPreferenceToolTip } from "pages/auth/preferences/data";

import { getAllCategoryList } from "redux/category/category.request";
import { getMasterProficiencyList } from "redux/master/master.request"
import {
  createStudentUserCategoryDetailByUserId,
  deleteStudentCategoryDetailByStudentCategoryId,
  getStudentUserCategoryListByUserId
} from "redux/student/student.request";
import {
  createTutorUserCategoryDetailByUserId,
  deleteTutorCategoryDetailByTutorCategoryId,
  getTutorUserCategoryListByUserId
} from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearCategoryList } from "redux/category/category.slice";
import { setClearProfList } from "redux/master/master.slice";
import { setClearTutorUserCategoryList } from "redux/tutor/tutor.slice";
import { setClearUserStudentCategoryList } from "redux/student/student.slice";
import { userRoles } from "redux/auth/auth.const";

type tCategoryProf = {
  category?: any,
  proficiency?: any
}

const SubjectPreferenceHeader = () => {
  return (
    <div className={"py-3 space-y-3"}>
      <div className="flex justify-center w-full">
        <div className="flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"Subject Preferences"}
          </span>
          <div className={"relative has-tooltip cursor-pointer"}>
            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            <span
              className={clsx(
                "w-56 px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip top-6.5",
                "flex flex-col items-start justify-start overflow-hidden"
              )}
            >
              {subjectPreferenceToolTip}
            </span>
          </div>
        </div>
      </div>
      <div className="h-0.5 bg-gray-300 w-full"></div>
    </div>
  );
}

export default function SubjectPreferenceSettings() {
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)
  const { tutorUserCategoryList } = useAppState((s) => s.tutor)
  const { studentUserCategoryList } = useAppState((s) => s.student)
  const { categoryList } = useAppState((s) => s.category)
  const { profList } = useAppState((s) => s.master)

  const [isVisible, setIsVisible] = useState(false)
  const [selectedCategoryProf, setSelectedCategoryProf] = useState<tCategoryProf>({
    category: categoryList?.categoryList?.category,
    proficiency: profList?.profList?.proficiency
  });

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(getAllCategoryList())
    dispatcher(getMasterProficiencyList())

    return () => {
      dispatcher(setClearCategoryList())
      dispatcher(setClearProfList())
    }
  }, [])

  useEffect(() => {
    if (locals.userRole === userRoles?.STUDENT?.value) {
      dispatcher(getStudentUserCategoryListByUserId(user?.user?.userId))
    }
    if (locals.userRole === userRoles?.TUTOR.value) {
      dispatcher(getTutorUserCategoryListByUserId(user?.user?.userId))
    }

    return () => {
      dispatcher(setClearUserStudentCategoryList())
      dispatcher(setClearTutorUserCategoryList())
    }
  }, [locals.userRole])

  const handleSelection = () => {
    if (locals.userRole === userRoles.TUTOR.value) {
      const categoryProfList = tutorUserCategoryList?.tutorUserCategoryList
      const filteredCategoryProfList = categoryProfList?.filter((categoryProf: any) => (
        categoryProf.category.categoryId === selectedCategoryProf.category && categoryProf.proficiency.masterProficiencyId === selectedCategoryProf.proficiency
      ))

      if (filteredCategoryProfList.length > 0) {
        alert("Duplicate entries not allowed")
        return;
      }

      dispatcher(createTutorUserCategoryDetailByUserId(
        user?.user?.userId,
        {
          categoryId: selectedCategoryProf.category,
          masterProficiencyId: selectedCategoryProf.proficiency
        }
      ))
    }
    if (locals.userRole === userRoles.STUDENT.value) {
      const categoryProfList = studentUserCategoryList?.studentUserCategoryList
      const filteredCategoryProfList = categoryProfList?.filter((categoryProf: any) => (
        categoryProf.category.categoryId === selectedCategoryProf.category && categoryProf.proficiency.masterProficiencyId === selectedCategoryProf.proficiency
      ))

      if (filteredCategoryProfList.length > 0) {
        alert("Duplicate entries not allowed")
        return;
      }

      dispatcher(createStudentUserCategoryDetailByUserId(
        user?.user?.userId,
        {
          categoryId: selectedCategoryProf.category,
          masterProficiencyId: selectedCategoryProf.proficiency
        }
      ))
    }
    setIsVisible(false)
  }

  const removeSelectedSubject = (categoryProf: any) => {
    if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(deleteTutorCategoryDetailByTutorCategoryId(categoryProf?.tutorCategoryId))
    }

    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(deleteStudentCategoryDetailByStudentCategoryId(categoryProf?.studentCategoryId))
    }
  }

  if (tutorUserCategoryList?.isLoading || studentUserCategoryList?.isLoading || categoryList?.isLoading || profList?.isLoading) {
    return (
      <FullPageLoader
        isLoading={tutorUserCategoryList?.isLoading || studentUserCategoryList?.isLoading || categoryList?.isLoading || profList?.isLoading}
      />
    )
  }

  return (
    <div className="">
      <SubjectPreferenceHeader />
      <div className="flex justify-end px-4 my-2">
        <IconWithTextButton
          placeholder="Add New"
          icon={<FaPlusCircle />}
          onClick={() => {
            setIsVisible(!isVisible)
            setSelectedCategoryProf({
              category: null,
              proficiency: null
            })
          }}
        />
      </div>

      {/* Items */}
      <div className="px-5 space-y-5">
        <div className="grid grid-cols-12 gap-5">
          <div className="col-start-1 col-span-4 gap-x-28">
            <span className="text-md font-bold text-text-900 font-bodyPri tracking-wide leading-6">
              {"Subject"}
            </span>
          </div>
          <div className="col-start-5 col-span-7">
            <span className="text-md font-bold text-text-900 font-bodyPri tracking-wide leading-6">
              {"Preferred Level"}
            </span>
          </div>
          {locals.userRole === userRoles.TUTOR.value &&
            tutorUserCategoryList?.tutorUserCategoryList?.map((categoryProf: any) => (
              <>
                <span className="col-start-1 col-span-4 font-bodyPri text-text-800">
                  {categoryProf?.category?.category}
                </span>
                <span className="col-start-5 col-span-3 font-normal font-bodyPri text-text-800">
                  {categoryProf?.proficiency?.proficiency}
                </span>
                <FaTimesCircle
                  size={16}
                  className="text-red-700 cursor-pointer"
                  onClick={() => removeSelectedSubject(categoryProf)}
                />
              </>
            ))}
          {locals.userRole === userRoles.STUDENT.value &&
            studentUserCategoryList?.studentUserCategoryList?.map((categoryProf: any) => (
              <>
                <span className="col-start-1 col-span-4 font-bodyPri text-text-800">
                  {categoryProf?.category?.category}
                </span>
                <span className="col-start-5 col-span-3 font-normal font-bodyPri text-text-800">
                  {categoryProf?.proficiency?.proficiency}
                </span>
                <FaTimesCircle
                  size={16}
                  className="text-red-700 cursor-pointer"
                  onClick={() => removeSelectedSubject(categoryProf)}
                />
              </>
            ))}
          {(tutorUserCategoryList?.errorMsg || studentUserCategoryList?.errorMsg) &&
            <div className={"col-start-0 col-span-6 pt-5"}>
              <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                {tutorUserCategoryList?.errorMsg}
              </div>
            </div>
          }
        </div>
        {isVisible &&
          <div className="w-full shadow-all rounded-lg space-y-8">
            <div className="py-5 px-3 gap-10 flex items-center">
              <div className="w-full h-48 z-[500]">
                <SelectSearch
                  options={categoryList?.categoryList?.map((category: any) => ({
                    name: category.category,
                    value: category.categoryId,
                  }))}
                  search
                  autoFocus={false}
                  placeholder="Select Category"
                  filterOptions={fuzzySearch}
                  value={selectedCategoryProf?.category}
                  onChange={(option: any) => {
                    setSelectedCategoryProf({
                      ...selectedCategoryProf,
                      category: option
                    });
                  }}
                />
              </div>
              <div className="w-full h-48 z-50">
                <SelectSearch
                  options={profList?.profList?.map((proficiency: any) => ({
                    name: proficiency.proficiency,
                    value: proficiency.masterProficiencyId
                  }))}
                  search
                  autoFocus={false}
                  placeholder="Select Level"
                  filterOptions={fuzzySearch}
                  value={selectedCategoryProf?.proficiency}
                  onChange={(option: any) => {
                    setSelectedCategoryProf({
                      ...selectedCategoryProf,
                      proficiency: option
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end p-2 pr-5">
              <span
                className="flex justify-center items-center cursor-pointer w-28 py-1.5 border border-primary-main text-text-900 rounded-full hover:bg-primary-main hover:text-text-50 font-buttons font-bodyPri tracking-wide text-lg"
                onClick={handleSelection}
              >
                {"Done"}
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  );
}