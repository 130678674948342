import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'string-format';

import { FaSpinner } from 'react-icons/fa';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setAddBookingPayload, setClearAddBooking } from 'redux/booking/booking.slice';
import { addWalletCredit } from 'redux/wallet/wallet.request';
import { makeAddBooking } from 'redux/booking/booking.request';
import { setClearModal } from 'redux/local/local.slice';
import { setClearMakePayment } from 'redux/payment/payment.slice';
import { setClearAddCreditToWallet } from 'redux/wallet/wallet.slice';
import { paymentMethodStatus } from 'redux/payment/payment.const';

import { Toggle } from "components/common-components/Toggle";

import MakePaymentFooter from 'components/modals/makePaymentModal/MakePaymentFooter';
import OrderSummery from 'components/modals/makePaymentModal/OrderSummery';

const walletConfirmationUrl = "/wallet/confirmation?status={status}&virtualTxnId={virtualTxnId}"
const bookingConfirmationUrl = "/booking/confirmation?status={status}&bookingId={bookingId}"


const MakePaymentModal = () => {
    const { addBooking } = useAppState(s => s.booking)
    const addBookingPayload = addBooking?.addBookingPayload
    const { makePayment } = useAppState(s => s.payment)
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const { user } = useAppState(s => s.user)
    const { logInSignUp } = useAppState(s => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        if (makePayment?.makePayment?.status === paymentMethodStatus.SUCCEEDED.value) {
            dispatcher(addWalletCredit())
            dispatcher(setClearMakePayment())
        } else if (makePayment?.makePayment) {
            dispatcher(setClearMakePayment())
            dispatcher(setClearAddCreditToWallet())
            const query = {
                status: 'failed'
            }
            dispatcher(setClearModal())
            navigate(format(walletConfirmationUrl.split('&')[0], query))
        }
    }, [makePayment?.makePayment])

    useEffect(() => {
        if (addCreditToWallet?.addCreditToWallet) {
            dispatcher(setAddBookingPayload({
                ...addBookingPayload,
                confirm: true
            }))
            dispatcher(makeAddBooking())
        }
    }, [addCreditToWallet?.addCreditToWallet])

    useEffect(() => {
        if (addBooking?.addBooking) {
            const query = {
                status: addBooking?.addBooking?.status,
                bookingId: addBooking?.addBooking?.bookingId
            }
            dispatcher(setClearAddBooking())
            dispatcher(setClearAddCreditToWallet())
            dispatcher(setClearMakePayment())
            dispatcher(setClearModal())
            navigate(format(bookingConfirmationUrl, query))
        }
    }, [addBooking?.addBooking])

    useEffect(() => {
        setAmountToPay()
    }, [addBookingPayload?.isWalletAdded])

    const setAmountToPay = () => {
        let walletAmountToPay = 0
        let gatewayAmountToPay = 0
        if (logInSignUp?.isLogedIn && user?.user && addBookingPayload?.isWalletAdded) {
            const gatewayAmount = addBookingPayload?.purchasedPrice - user?.user?.netWalletCredit
            if (gatewayAmount > 0) {
                walletAmountToPay = user?.user?.netWalletCredit
                gatewayAmountToPay = gatewayAmount
            } else {
                walletAmountToPay = addBookingPayload?.purchasedPrice
                gatewayAmountToPay = 0
            }
        } else {
            walletAmountToPay = 0
            gatewayAmountToPay = addBookingPayload?.purchasedPrice
        }
        dispatcher(setAddBookingPayload({
            ...addBookingPayload,
            walletAmountToPay: walletAmountToPay,
            gatewayAmountToPay: gatewayAmountToPay,
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block space-y-5 pt-5"}>
                <div className={"flex items-start justify-between"}>
                    <span className={"font-bodyPri font-medium text-lg text-text-800"}>
                        {"Sub total"}
                    </span>
                    <div className={"flex flex-col items-end justify-center"}>
                        <div className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-medium text-lg text-text-900"}>
                            <span>{"USD"}</span>
                            <span>{parseFloat(addBookingPayload?.purchasedPrice / 100).toFixed(2)}</span>
                        </div>
                        <div className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                            <span>{"Approx"} {"INR"}</span>
                            <span>{parseFloat(addBookingPayload?.purchasedPrice / 100).toFixed(2)}</span>
                        </div>
                    </div>
                </div>

                {logInSignUp?.isLogedIn && user?.user && <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>}

                {logInSignUp?.isLogedIn && user?.user &&
                    <div className={"w-full flex items-center justify-between"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                            {"My available balance"}
                        </span>
                        <div className={"flex items-center justify-end gap-2"}>
                            <div className={"flex items-center justify-start gap-1 font-bodyPri font-medium text-base text-text-900"}>
                                <span>{"USD"}</span>
                                <span>{parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}</span>
                            </div>
                            <Toggle
                                value={addBookingPayload?.isWalletAdded}
                                onChange={(isToggle) => dispatcher(setAddBookingPayload({ ...addBookingPayload, isWalletAdded: isToggle }))}
                            />
                        </div>
                    </div>
                }

                <div className={""}>
                    <OrderSummery />
                </div>
                <div className={"w-full h-10"}>
                    {(makePayment?.errorMsg || addCreditToWallet?.errorMsg || addBooking?.errorMsg) &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                                {makePayment?.errorMsg || addCreditToWallet?.errorMsg || addBooking?.errorMsg}
                            </span>
                        </div>
                    }
                    {(makePayment?.isLoading || addCreditToWallet?.isLoading || addBooking?.isLoading) &&
                        <div classsName={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait while processing ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>

            </div>
            <div className={"w-full"}>
                <MakePaymentFooter />
            </div>
        </div>
    )
}

export default MakePaymentModal