import format from "string-format";

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/course/course.const";

class CourseService {

    getCoursePublicList = async ({ query }) => {
        const response = await baseApiInstance().get(
            url.COURSE_LIST,
            { params: query }
        )

        return response
    }

    getCoursePublicProfileByCourseId = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.COURSE_PUBLIC_PROFILE_BY_USER_ID, params),
            { params: query }
        )

        return response

    }

    getCourseTutorUserPublicListByUserId = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.COURSE_TUTOR_USER_PUBLIC_LIST, params),
            { params: query }
        )

        return response
    }

    getMyCourseUserListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.MY_COURSE_USER_LIST, params)
        )

        return response
    }

    getFavCourseUserListByUserId = async ({params}) => {
        const response = await baseAuthApiInstance().get(
            format(url.FAV_COURSE_USER, params)
        )

        return response
    }

    createFavCourseUserDetailByUserId = async ({params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.FAV_COURSE_USER, params),
            body
        )

        return response
    }

    deleteFavCourseDetailByUserId = async ({params, body }) => {
        const response = baseAuthApiInstance().delete(
            format(url.FAV_COURSE_USER, params),
            { data: body }
        )

        return response
    }
}

export default new CourseService()