import {
    // Container,
    AnchorLink,
    LogInCardContent,
    LogInCardHeader,
    Heading,
    SubHeading,
    LogInFormWrapper,
    DividerWrapper,
    SocialLogInWrapper,
} from './LogInCardStyle';
import LogInForm from 'components/logInForm/LogInForm';
import HDividerWithText from 'components/hDividerWithText/HDividerWithText';
import SocialAuth from 'components/socialAuth/SocialAuth';

import { useAppDispatcher } from 'hooks/useStore';
import { login } from "redux/auth/auth.request";
import { pagesInfo } from 'utils/pagesInfo';

const LogInCard = () => {
    
    const dispatcher = useAppDispatcher();

    const formSubmit = async (userData) => {
        if (userData?.password) {
            const user = {
                email: userData.emailOrPhone,
                password: userData.password,
            }
            dispatcher(login(user))
            return;
        }
        if (userData?.otp) {
            const user = {
                email: userData?.email,
                otpNotificationId: userData?.otpNotificationId,
                otp: String(userData?.otp)
            }
            dispatcher(login(user))
            return;
        }
    }

    const submitSocialAuth = async (socialName) => {
        console.log(socialName)
    }

    return (
        <>
            <LogInCardContent>
                <LogInCardHeader>
                    <Heading>{'Log in'}</Heading>
                    <SubHeading>
                        {`Don't have an account?`}
                        <AnchorLink to={pagesInfo.SIGN_UP.pagePath}>{'Sign up'}</AnchorLink>
                    </SubHeading>
                </LogInCardHeader>
                <LogInFormWrapper>
                    <LogInForm formSubmit={formSubmit} />
                </LogInFormWrapper>
                <DividerWrapper>
                    <HDividerWithText text={'or continue with'} />
                </DividerWrapper>
                <SocialLogInWrapper>
                    <SocialAuth submitSocialAuth={submitSocialAuth} />
                </SocialLogInWrapper>
            </LogInCardContent>
        </>
    )
}

export default LogInCard;
