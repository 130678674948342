import clsx from "clsx";
import * as React from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";

import { MdEdit } from 'react-icons/md'
import { AiFillCheckCircle } from 'react-icons/ai'

import { setOfferingCourse } from "redux/offering/offering.slice"

import { useAppDispatcher, useAppState } from "hooks/useStore";

const quilOptions = {
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }],
      ["code-block"],
    ],
  },
  placeholder: "Compose an epic...",
  theme: "snow", // or 'bubble'
};

export const CourseDescriptionEditor = () => {
  const { offeringCourse } = useAppState((s) => s.offering)
  const [quil, setQuil] = React.useState<Quill | null>(null);
  const [editMode, setEditMode] = React.useState(false);
  const dispatcher = useAppDispatcher();

  React.useEffect(() => {
    if (quil) {
      quil.root.innerHTML = offeringCourse?.offeringCourse?.courseDescription || "";
    }
  }, [offeringCourse?.offeringCourse?.courseDescription, quil]);

  const initializeEditor = React.useCallback((node: any) => {
    if (node != null) {
      const quill = new Quill(node, quilOptions);
      if (quill) {
        setQuil(quill);
      }
    }
  }, []);

  const updateContent = () => {
    const content = quil?.root.innerHTML;
    dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, courseDescription: content }));
  };

  return (
    <div
      className={clsx(
        "relative bg-white p-5"
      )}
    >
      <div className={clsx("relative w-full h-full overflow-hidden space-y-3")}>
        <div>
          <button
            className="absolute right-1 top-0"
            onClick={() => {
              if (editMode) {
                updateContent();
              }
              setEditMode(!editMode);
            }}
          >
            {editMode ? (
              <AiFillCheckCircle className="text-secondary-dark" size={25} />
            ) : (
              <MdEdit className="text-secondary-dark" size={25} />
            )}
          </button>
          <div className="flex items-center pb-1 border-b gap-3">
            <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
              {"Class Description"}
            </span>
            <span className="text-base text-text-700 font-normal">
              ({"Class Details"})
            </span>
          </div>
        </div>
        {!editMode && (
          <div className="p-3 border border-secondary-main border-dashed prose prose-h1:text-2xl prose-h2:text-xl prose-h3:text-lg">
            <div
              dangerouslySetInnerHTML={{ __html: offeringCourse?.offeringCourse?.courseDescription }}
            >
            </div>
          </div>
        )}
        {editMode && (
          <div className="p-3 border border-secondary-main border-dashed mt-4 h-[650px] pb-[56px]">
            <div
              className="w-full h-full"
              id="editor"
              ref={initializeEditor}
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
