import { useNavigate} from 'react-router-dom';

import {
    HamburgerHeader,
    HamburgerHeaderContent,
    CloseIcon,
    HamburgerContent,
    AuthSection,
    SignUpIcon,
    LogInIcon,
    HamburgerFeatured,
    HamburgerFeaturedLink,
    CategoryIcon,
    FindTeacherIcon,
    FindCoursesIcon,
    TeachWithUsIcon,

    HamburgerMenuContent,
    Item,
    ProfileImageContainer,
    ItemIcon,
    ItemName
} from './NavBarStyle';

import { HamburgerMenuItems } from "components/navbar/HamburgerMenu.data"
import HamburgerMenuContainer from "components/hamburgerMenu/HamburgerMenu";

import { pagesInfo } from 'utils/pagesInfo';

import { createLogout } from "redux/auth/auth.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const HamburgerMenu = ({ visible, setVisible }) => {
    const { locals } = useAppState((s) => s.local)
    const { logInSignUp } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const handleOnClickItem = (dataItem) => {
        if (dataItem.name === "Log Out") {
            dispatcher(createLogout())
        } else {
            navigate(dataItem.path)
        }
        setVisible(false)
    }

    return (
        <HamburgerMenuContainer visible={visible} setVisible={setVisible}>
            <HamburgerHeader>
                {!logInSignUp?.isLogedIn &&
                    <HamburgerHeaderContent onClick={() => setVisible(false)}>
                        <AuthSection to={pagesInfo.SIGN_UP.pagePath}>
                            <SignUpIcon />
                            {'Sign up'}
                        </AuthSection>
                        <AuthSection to={pagesInfo.LOG_IN.pagePath}>
                            <LogInIcon />
                            {'Log in'}
                        </AuthSection>
                    </HamburgerHeaderContent>
                }
                {logInSignUp?.isLogedIn &&
                    <Item className={"pt-2 flex items-center gap-3"} onClick={() => handleOnClickItem({ name: "profile", path: "/profile" })}>
                        <ProfileImageContainer>
                            <img src={user?.user?.profilePicUrl} alt={"profile"} />
                        </ProfileImageContainer>
                        <ItemName>
                            <div className={"w-full h-full flex flex-col justify-start items-start"}>
                                <span>{user?.user?.firstName + " " + user?.user?.lastName.charAt(0)}</span>
                                <span className={"text-xs text-primary-main"}>{"My Profile"}</span>
                            </div>
                        </ItemName>
                    </Item>
                }
                <CloseIcon onClick={() => setVisible(false)} />
            </HamburgerHeader>
            <HamburgerContent onClick={() => setVisible(false)}>
                <HamburgerFeatured>
                    <HamburgerFeaturedLink to={pagesInfo.SEGMENTS.pagePath}>
                        <CategoryIcon />
                        {'Categories'}
                    </HamburgerFeaturedLink>
                    <HamburgerFeaturedLink to={pagesInfo.TEACH_WITH_US.pagePath}>
                        <TeachWithUsIcon />
                        {'Teach With Us'}
                    </HamburgerFeaturedLink>
                    <HamburgerFeaturedLink to={pagesInfo.FIND_TUTORS.pagePath}>
                        <FindTeacherIcon />
                        {'Find Tutors'}
                    </HamburgerFeaturedLink>
                    <HamburgerFeaturedLink to={pagesInfo.FIND_COURSES.pagePath}>
                        <FindCoursesIcon />
                        {'Find Courses'}
                    </HamburgerFeaturedLink>
                </HamburgerFeatured>
                <hr />
                {logInSignUp?.isLogedIn &&
                    <HamburgerMenuContent>
                        {HamburgerMenuItems?.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                            <Item key={index} onClick={() => handleOnClickItem(dataItem)}>
                                <ItemIcon>
                                    <dataItem.icon />
                                </ItemIcon>
                                <ItemName>
                                    {dataItem.name}
                                </ItemName>
                            </Item>
                        ))}
                    </HamburgerMenuContent>
                }
            </HamburgerContent>
        </HamburgerMenuContainer>
    )
}

export default HamburgerMenu;
