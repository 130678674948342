import { useEffect, useState } from "react";
import clsx from "clsx";

import { FiEdit } from 'react-icons/fi';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { OptionSelector } from "components/common-components/Select";

import { genderOptions, profileEditBtn } from "pages/auth/profile/data";
import SaveAndCancelBtn from "pages/auth/profile/SaveAndCancelBtn";
import LivingInDetail from "pages/auth/profile/personalDetails/LivingInDetail";
import UserFromDetail from "pages/auth/profile/personalDetails/UserFromDetail";

import { updateUserDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { dayjs } from "utils/dateTime.utils";

const PersonalDetail = ({ editBtnConst, setEditBtnConst }) => {
    const { user, userDetail, userCultureDetail } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [gender, setGender] = useState(genderOptions.MALE.value)
    const [birthDate, setBirthDate] = useState(new Date())
    const [allowAge, setAllowAge] = useState(false)

    useEffect(() => {
        if (userDetail?.userDetail?.gender) {
            setGender(userDetail?.userDetail?.gender)
        }
        if (userDetail?.userDetail?.dateOfBirth) {
            setBirthDate(dayjs(userDetail?.userDetail?.dateOfBirth)?.toDate())
        }
    }, [userDetail?.userDetail?.gender || userDetail?.userDetail?.dateOfBirth])

    const onSaveUserDetail = () => {
        if (editBtnConst === profileEditBtn.gender.value) {
            dispatcher(updateUserDetailByUserId(
                user?.user?.userId,
                {
                    gender: gender
                }
            ))
        }
        if (editBtnConst === profileEditBtn.dateOfBirth.value) {
            dispatcher(updateUserDetailByUserId(
                user?.user?.userId,
                {
                    dateOfBirth: dayjs(birthDate).format("YYYY-MM-DD")
                }
            ))
        }
        setEditBtnConst(null)
    }

    return (
        <div className=''>
            <div className='text-xl font-medium text-text-800 font-bodyPri tracking-wide'>
                {"Personal Details:"}
            </div>
            <div className="grid grid-cols-12 gap-3 p-5">
                <div className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri'>
                    {"Gender:"}
                </div>
                {editBtnConst !== profileEditBtn.gender.value &&
                    Object.keys(genderOptions).map((items) => genderOptions[items].value === gender &&
                        <span className='col-start-1 col-span-3 md:col-start-3 md:col-span-3 text-text-900 font-medium font-bodyPri'>
                            {genderOptions[items].label}
                        </span>
                    )}
                {editBtnConst === profileEditBtn.gender.value &&
                    <div className="w-full col-start-1 col-span-8 md:col-start-3 md:col-span-3 flex flex-col items-start justify-center gap-3">
                        <div className={"w-full"}>
                            <OptionSelector
                                options={Object.values(genderOptions)}
                                value={gender}
                                onChange={(option) => setGender(option.value)}
                            />
                        </div>
                        <SaveAndCancelBtn
                            onSave={() => onSaveUserDetail()}
                            onCancel={() => setEditBtnConst(null)}
                        />
                    </div>
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.gender.value)}
                    />
                }
                <span className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"Date of Birth:"}
                </span>
                {editBtnConst !== profileEditBtn.dateOfBirth.value &&
                    <span className='col-start-1 col-span-8 md:col-start-3 md:col-span-3 text-text-900 font-medium font-bodyPri'>
                        {dayjs(userDetail?.userDetail?.dateOfBirth).format('ddd, MMM D, YYYY')}
                        <div className="font-bodyPri font-normal text-sm text-text-500">
                            {!allowAge && ("Other people cannot see your age")}
                        </div>
                    </span>
                }
                {editBtnConst === profileEditBtn.dateOfBirth.value &&
                    <div className="col-start-1 col-span-8 md:col-start-3 md:col-span-3 flex flex-col items-start justify-center gap-3">
                        <div className={"space-y-2"}>
                            <DatePicker
                                className={clsx(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                value={birthDate}
                                selected={birthDate}
                                onChange={(date) => setBirthDate(date)}
                            />
                            <div className="flex items-start justify-start gap-1">
                                <input
                                    type={"checkbox"}
                                    className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                    checked={allowAge}
                                    onChange={(e) => setAllowAge(e.target.checked)}
                                />
                                <span className='font-bodyPri font-medium text-text-900 text-xs text-start w-full'>
                                    {"Allow people to view my age"}
                                </span>
                            </div>
                        </div>
                        <SaveAndCancelBtn
                            onSave={() => onSaveUserDetail()}
                            onCancel={() => setEditBtnConst(null)}
                        />
                    </div>
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.dateOfBirth.value)}
                    />
                }
                <span className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"From:"}
                </span>
                {editBtnConst !== profileEditBtn.from.value &&
                    <div className='col-start-1 col-span-8 md:col-start-3 md:col-span-3'>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {userCultureDetail?.userCultureDetail?.fromCountry?.country && userCultureDetail?.userCultureDetail?.fromCountry?.country}
                            {userCultureDetail?.userCultureDetail?.fromState && ", " + userCultureDetail?.userCultureDetail?.fromState}
                            {userCultureDetail?.userCultureDetail?.fromCity && ", " + userCultureDetail?.userCultureDetail?.fromCity}
                        </span>
                    </div>
                }
                {editBtnConst === profileEditBtn.from.value &&
                    <UserFromDetail
                        setEditBtnConst={setEditBtnConst}
                    />
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.from.value)}
                    />
                }
                <span className='col-start-1 col-span-full md:col-start-1 md:col-span-2 text-text-700 font-normal font-bodyPri whitespace-nowrap'>
                    {"Living In:"}
                </span>
                {editBtnConst !== profileEditBtn.livingIn.value &&
                    <div className='col-start-1 col-span-8 md:col-start-3 md:col-span-3'>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {userCultureDetail?.userCultureDetail?.liveInCountry?.country && userCultureDetail?.userCultureDetail?.liveInCountry?.country}
                            {userCultureDetail?.userCultureDetail?.fromState && ", " + userCultureDetail?.userCultureDetail?.fromState}
                            {userCultureDetail?.userCultureDetail?.fromCity && ", " + userCultureDetail?.userCultureDetail?.fromCity}
                        </span>
                    </div>
                }
                {editBtnConst === profileEditBtn.livingIn.value &&
                    <LivingInDetail
                        setEditBtnConst={setEditBtnConst}
                    />
                }
                {!editBtnConst &&
                    <FiEdit
                        className={"col-start-9 col-span-1 md:col-start-6 md:col-span-1 text-lg text-text-500 cursor-pointer"}
                        onClick={() => setEditBtnConst(profileEditBtn.livingIn.value)}
                    />
                }
            </div>
        </div>
    )
}

export default PersonalDetail