import { studentFilterProps } from "redux/student/student.const";

export const STUDENT_INITIAL_STATE = {
    myStudentList: {
        isLoading: false,
        myStudentList: null,
        filteredMyStudentList: null,
        errorMsg: null
    },

    favStudentList: {
        isLoading: false,
        favStudentList: null,
        filteredFavStudentList: null,
        errorMsg: null
    },
    addFavStudent: {
        isLoading: false,
        errorMsg: null
    },

    studentUserDetail: {
        isLoading: false,
        studentUserDetail: null,
        errorMsg: null
    },
    addStudentUserDetail: {
        isLoading: false,
        errorMsg: null
    },

    studentUserLanguageList: {
        isLoading: false,
        studentUserLanguageList: null,
        errorMsg: null
    },
    addStudentLanguageDetail: {
        isLoading: false,
        errorMsg: null
    },

    studentUserCategoryList: {
        isLoading: false,
        studentUserCategoryList: null,
        errorMsg: null
    },
    addStudentCategoryDetail: {
        isLoading: false,
        errorMsg: null
    },

    filterProps: studentFilterProps,
}