import { useEffect } from 'react';
import clsx from 'clsx';

import { pageHeading, wallet, buttonFeature } from "pages/auth/wallet/data";

import WalletCard from "pages/auth/wallet/WalletCard";
import WalletFeatureButton from "pages/auth/wallet/WalletFeatureButton";
import WalletTxnListCard from "pages/auth/wallet/walletTxn/WalletTxnListCard";
import BookingListCard from "pages/auth/wallet/booking/BookingListCard";

import PageHeader from 'components/pageHeader/PageHeader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';


const WalletPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.WALLET))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">

      <PageHeader pageHeading={pageHeading} />

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>

        <div className="bg-white rounded-lg p-5 w-full">
          <div className={clsx(
            "grid grid-flow-col gap-5 place-items-center overflow-hidden overflow-x-auto px-5",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm scrollbar-h-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
          )}>
            {Object.keys(wallet).map((key, index) => {
              let amount = 0
              if (wallet[key].value === wallet.WALLET_CREDIT.value)
                amount = user?.user?.netWalletCredit
              if (wallet[key].value === wallet.PENDING_CREDIT.value)
                amount = user?.user?.netPendingCredit

              return wallet[key].roles.includes(locals.userRole) && (
                <WalletCard key={index} walletItem={{ ...wallet[key], amount: amount }} />
              )
            })}
          </div>
        </div>

        <div className="bg-white rounded-lg p-6 w-full">
          <div className="flex items-center justify-evenly h-full">
            {Object.keys(buttonFeature).map((key, index) => buttonFeature[key].roles.includes(locals.userRole) && (
              <WalletFeatureButton key={index} featureItem={buttonFeature[key]} />
            ))}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <WalletTxnListCard />
        <BookingListCard />
      </div>
    </div>
  )
};

export default WalletPage;
