import React, { useEffect, useState } from "react";

//context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//helper functions
import deleteGroupUsers from "../../helpers/deleteGroupUsers";

//component imports
import Button from "../presentationcomponents/Button/Button";

//style imports
import {
  StyledGroupDetailsContainer,
  StyledGroupDetailsHeader,
  StyledGroupDetailsTitle,
  StyledGroupDetailsSubtitle,
  StyledGroupDetailsMember,
  StyledGroupDetailsMemberImage,
  StyledGroupDetailsMemberRole,
  StyledGroupDetailsMemberName,
  StyledGroupDetailsMemberList,
  StyledRemoveMemberButton,
} from "./GroupDetails.styles";

export default function GroupDetails({ setShowGroupDetails }) {
  const { groupMembers, selectedChat } = useChat();
  const { user } = useUser();
  const { userId } = user;
  const [removingUser, setRemovingUser] = useState(false);
  const currentMember = groupMembers.filter(
    (member) => member.id === userId
  )[0];
  const canDelete =
    currentMember.role === "moderator" || currentMember.role === "admin";

  useEffect(() => {
    if (selectedChat.type === "individual") {
      setShowGroupDetails(false);
    }
  }, [selectedChat, setShowGroupDetails]);

  function closeGroupMemberDetails() {
    setShowGroupDetails(false);
  }

  async function handleDelete(member) {
    if (removingUser) return;
    if (member.id === userId) return;
    if (!canDelete) return;
    try {
      setRemovingUser(true);
      await deleteGroupUsers(member.id, selectedChat.id);
      setRemovingUser(false);
    } catch (err) {
      setRemovingUser(false);
      console.log(err);
    }
  }

  return (
    <StyledGroupDetailsContainer>
      <StyledGroupDetailsHeader>
        <img
          src={selectedChat.image}
          alt="group"
          className="w-12 h-12 object-cover rounded-full"
        />
        <StyledGroupDetailsTitle>
          Introduction to Modern Art
        </StyledGroupDetailsTitle>
        <StyledGroupDetailsSubtitle>
          {`Group. ${groupMembers.length} members`}
        </StyledGroupDetailsSubtitle>
      </StyledGroupDetailsHeader>
      <StyledGroupDetailsTitle>Group Members</StyledGroupDetailsTitle>
      <StyledGroupDetailsMemberList>
        {groupMembers.map((member) => {
          return (
            <StyledGroupDetailsMember>
              <StyledGroupDetailsMemberImage
                src={member.image}
                alt={member.name}
                className="w-12 h-12 object-cover rounded-full"
              />
              <div>
                <StyledGroupDetailsMemberName>
                  {member.name}
                </StyledGroupDetailsMemberName>
                <StyledGroupDetailsMemberRole>
                  {member.role.charAt(0).toUpperCase() + member.role.slice(1)}
                </StyledGroupDetailsMemberRole>
              </div>
              {canDelete && member.id !== userId && (
                <StyledRemoveMemberButton
                  onClick={() => handleDelete(member)}
                  loading={removingUser}
                >
                  Remove
                </StyledRemoveMemberButton>
              )}
            </StyledGroupDetailsMember>
          );
        })}
      </StyledGroupDetailsMemberList>
      <div className="mt-3 ">
        <Button
          text="Close"
          variant="secondary"
          onClick={closeGroupMemberDetails}
        />
      </div>
    </StyledGroupDetailsContainer>
  );
}
