import { useEffect, useState } from 'react';

import PageHeader from 'components/pageHeader/PageHeader';
import Tabs from "components/tabs/Tabs";

import { courseTabs, pageHeading } from 'pages/auth/myCourses/data';

import MyCoursesSearch from 'pages/auth/myCourses/components/MyCoursesSearch';
import AllCourses from 'pages/auth/myCourses/components/AllCourses';
import FavCourses from 'pages/auth/myCourses/components/FavCourses';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const MyCoursesPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    
    const dispatcher = useAppDispatcher()

    const [myActiveTab, setMyActiveTab] = useState(courseTabs.ALL_COURSES.value)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MY_COURSES))
    }, [dispatcher, currentPageInfo])


    return (
        <div className='h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3'>
            <PageHeader pageHeading={pageHeading} />
            <MyCoursesSearch myActiveTab={myActiveTab} />
            <Tabs
                tabs={Object.values(courseTabs)}
                handleTabs={(activeTab) => {
                    setMyActiveTab(activeTab.value)
                }}
            />
            {myActiveTab === courseTabs.ALL_COURSES.value &&
            <AllCourses />
            }
            {myActiveTab === courseTabs.FAV_COURSES.value &&
            <FavCourses />
            }
        </div>
    );
};

export default MyCoursesPage;
