import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { closeInvoiceModal, closePlansModal } from "../store/SubscriptionSlice";

export default function InvoiceModal() {
  const modalState = useAppState((s) => s.subscriptions._meta.showInvoiceModal);
  const dispatcher = useAppDispatcher();

  function closeModal() {
    dispatcher(closeInvoiceModal());
  }

  return (
    <>
      <Transition appear show={modalState.showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-50 opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  "inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle ",
                  "transition-all transform bg-white shadow-xl rounded-2xl"
                )}
              >
                <Dialog.Title
                  as="h3"
                  className={clsx(
                    "p-4 text-3xl font-bold leading-6 text-center ",
                    "bg-gradient-to-r from-blue-600 via-indigo-400  to-orange-600 text-transparent bg-clip-text"
                  )}
                >
                  Invoice {modalState.invoiceId}
                </Dialog.Title>
                <div
                  className={clsx(
                    "mt-2",
                    "min-h-md w-full" // remove this after adding content
                  )}
                >
                  {/* Add Your Invoice Component Here!  */}
                </div>

                <div className="flex justify-end mt-4 mr-6">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
