import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  MockMySettingsPageState,
  PayoutBankTransfer,
  PayoutUPI,
  Address,
  BillingSetting,
} from "./types";

const MySettingsSlice = createSlice({
  name: "mySettings",
  initialState: MockMySettingsPageState,
  reducers: {
    initializeBankTransferDetails: (
      state,
      action: PayloadAction<{ bankTransferInfo: PayoutBankTransfer }>
    ) => {
      state.payout.bankTransfer = action.payload.bankTransferInfo;
      state.payout._meta.editBankTransfer = true;
    },

    setBankTransferDetails: (
      state,
      action: PayloadAction<{ details: PayoutBankTransfer }>
    ) => {
      state.payout.bankTransfer = action.payload.details;
    },

    submitBankTransferDetails: (state) => {
      state.payout._meta.editBankTransfer = false;
    },
    submitUPIDetails: (state) => {
      state.payout._meta.editPayoutUpi = false;
    },
    editUPIDetails: (state) => {
      state.payout._meta.editPayoutUpi = true;
    },
    editBankTransferDetails: (state) => {
      state.payout._meta.editBankTransfer = true;
    },

    initializePayoutUpiDetails: (state) => {
      state.payout.payoutUpi = { upiId: "" };
      state.payout._meta.editPayoutUpi = true;
    },
    setPayoutUpiDetails: (
      state,
      action: PayloadAction<{ details: PayoutUPI }>
    ) => {
      state.payout.payoutUpi = action.payload.details;
    },

    setDefaultPayoutMethod: (
      state,
      action: PayloadAction<{ method: "upi" | "bank" }>
    ) => {
      state.payout._meta.default = action.payload.method;
    },

    toggleNotificationWithId: (
      state,
      action: PayloadAction<{ id: string; code: string }>
    ) => {
      const match = state.notificationSettings.find(
        (s) => s.id === action.payload.id
      );
      if (match) {
        const setting = match.settings.find(
          (s) => s.code === action.payload.code
        );
        if (setting) {
          setting.status = !setting.status;
        }
      }
    },
    setBillingInformation: (
      state,
      action: PayloadAction<BillingSetting>
    ) => {
      const { bussinessType, GSTStatus, GSTNumber, defaultAddress, addresses } =
        action.payload;
      state.billingSettings.bussinessType = bussinessType;
      state.billingSettings.GSTStatus = GSTStatus;
      state.billingSettings.GSTNumber = GSTNumber;
      state.billingSettings.defaultAddress = defaultAddress;
      state.billingSettings.addresses = addresses;
    },

    addNewAddressToBilling: (state, action: PayloadAction<Address>) => {
      state.billingSettings.addresses.push(action.payload);

      // If the address is first, set it as default
      if (state.billingSettings.defaultAddress == null) {
        state.billingSettings.defaultAddress = action.payload._id;
      }
    },
  },
});

export const {
  initializeBankTransferDetails,
  setBankTransferDetails,
  initializePayoutUpiDetails,
  setPayoutUpiDetails,
  submitBankTransferDetails,
  submitUPIDetails,
  editBankTransferDetails,
  editUPIDetails,
  setDefaultPayoutMethod,
  toggleNotificationWithId,
  setBillingInformation,
  addNewAddressToBilling
} = MySettingsSlice.actions;
export default MySettingsSlice.reducer;
