import { useEffect } from 'react';

import HeroCard from "pages/auth/dashboard/components/HeroCard";
import { DashboardStatistics } from "pages/auth/dashboard/components/DashboardStatistics";
// import { MyMessages } from "pages/auth/dashboard/components/MyMessages";
import { MyStudents } from "pages/auth/dashboard/components/MyStudents";
import { MyOfferings } from "pages/auth/dashboard/components/MyOfferings";
// import { VideoGuides } from "pages/auth/dashboard/components/VideoGuides";

// import { TierView } from "pages/auth/dashboard/components/TierView";
import { UpcomingClasses } from "pages/auth/dashboard/components/UpcomingClasses";
import CalendarComponent from "pages/auth/dashboard/components/CalendarComponent";
import { GlobalClock } from "pages/auth/dashboard/components/GlobalClocks";
import DashboardSideOverPanel from "pages/auth/dashboard/components/DashboardSideOverPanel";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';

const DashboardPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.DASHBOARD))
    }, [dispatcher, currentPageInfo])

    return (
        <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen">
            <div className="grid grid-cols-12 space-x-3">
                <div className="col-span-full lg:col-span-8 space-y-3">
                    <HeroCard />
                    <DashboardSideOverPanel />
                    <DashboardStatistics />
                    <div className="grid grid-cols-12 lg:space-x-3">
                        <div className="col-span-12">
                            <MyStudents />
                        </div>
                        {/* <div className="col-span-12 lg:col-span-6">
                            <MyMessages />
                        </div> */}
                    </div>
                    <MyOfferings />
                    {/* <VideoGuides /> */}
                </div>
                <div className="col-span-full lg:col-span-4 h-full w-full p-5 bg-white rounded-lg space-y-5">
                    {/* <TierView />
                    <hr /> */}
                    <UpcomingClasses />
                    <hr />
                    <CalendarComponent />
                    <hr />
                    <GlobalClock />
                </div>
            </div>
        </div>
    );
};

export default DashboardPage