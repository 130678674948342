const addCreditToWalletPayload = {
    amount: 10000,
    totalAmount: 0,
    gatewayPaymentId: null,
    gatewayName: null,
}

export const WALLET_INITIAL_STATE = {
    addCreditToWallet: {
        isLoading: false,
        addCreditPayload: addCreditToWalletPayload,
        addCreditToWallet: null,
        errorMsg: null,
    },

    walletConfirmation: {
        isLoading: false,
        walletConfirmation: null,
        errorMsg: null
    },
    
    walletTxnList: {
        isLoading: false,
        walletTxnList: null,
        errorMsg: null,
    },
}