import { useState, useEffect } from 'react';
import clsx from "clsx";

import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const Pagination = ({
    page = 1,
    totalPages = 10,
    onChangePage
}) => {
    const [pages] = useState(new Array(totalPages).fill().map((_, idx) => idx + 1))
    const [currentActivePage, setCurrentActivePage] = useState(page)

    useEffect(() => {
        // on page change - scroll to top
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [page]);

    const changePage = (page) => {
        onChangePage(page)
        setCurrentActivePage(page)
    }

    const previousPage = (page) => {
        onChangePage(page - 1)
        setCurrentActivePage(page - 1)
    }

    const nextPage = (page) => {
        onChangePage(page + 1)
        setCurrentActivePage(page + 1)
    }

    return (
        <div className={"w-fit bg-white border-2 border-text-300 rounded-lg shadow-xl p-3"}>
            {totalPages !== 0 && (
                <div className="flex items-center justify-center gap-5">

                    <button
                        className="flex items-center justify-center gap-1 hover:scale-110 group"
                        onClick={() => {
                            if (currentActivePage > 1) {
                                previousPage(currentActivePage);
                            }
                        }}>
                        <FaChevronLeft className={clsx("text-primary-main group-hover:text-primary-dark")} />
                        <span className={"font-bodyPri font-normal text-primary-main text-base group-hover:text-primary-dark"}>
                            {"Previous"}
                        </span>
                    </button>
                    <span className={"text-lg font-semibold text-divider-lightDark"}>
                        {"|"}
                    </span>
                    <div className="flex justify-center items-center gap-1">
                        {currentActivePage > pages[1] &&
                            <div className={clsx(
                                " px-2.5 py-1 cursor-pointer",
                                currentActivePage === pages[0]
                                    ? "font-bold bg-primary-main text-white"
                                    : "text-primary-main",
                                "hover:scale-110 rounded-full "
                            )}
                                onClick={() => {
                                    changePage(pages[0])
                                }}
                            >
                                {pages[0]}
                            </div>
                        }
                        {currentActivePage > 3 &&
                            <div className={"px-2.5 py-1"}>
                                {"..."}
                            </div>
                        }
                        {pages.slice(currentActivePage - 2 < 0 ? 0 : currentActivePage - 2, currentActivePage + 1).map((page, index) => (
                            <button key={index}
                                className={clsx(
                                    " px-2.5 py-1 cursor-pointer",
                                    currentActivePage === page
                                        ? "font-bold bg-primary-main text-white"
                                        : "text-primary-main",
                                    "hover:scale-110 rounded-full "
                                )}
                                onClick={() => {
                                    changePage(page)
                                }}
                            >
                                {page}
                            </button>

                        ))}
                        {currentActivePage <= pages?.length - 3 &&
                            <div className={"px-2.5 py-1"}>
                                {"..."}
                            </div>
                        }
                        {currentActivePage <= pages?.length - 2 &&
                            <div className={clsx(
                                " px-2.5 py-1 cursor-pointer",
                                currentActivePage === pages?.length
                                    ? "font-bold bg-primary-main text-white"
                                    : "text-primary-main",
                                "hover:scale-110 rounded-full "
                            )}
                                onClick={() => {
                                    changePage(pages?.length)
                                }}
                            >
                                {pages?.length}
                            </div>
                        }
                    </div>
                    <span className={"text-lg font-semibold text-divider-lightDark"}>
                        {"|"}
                    </span>
                    <button
                        className="flex items-center justify-center gap-1 hover:scale-110 group"
                        onClick={() => {
                            if (currentActivePage < pages?.length) {
                                nextPage(currentActivePage);
                            }
                        }}>
                        <span className={"font-bodyPri font-normal text-primary-main text-base group-hover:text-primary-dark"}>
                            {"Next"}
                        </span>
                        <FaChevronRight className="text-primary-main group-hover:text-primary-dark" />
                    </button>
                </div>
            )}
        </div>
    )
}

export default Pagination;