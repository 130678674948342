import { useState, useEffect } from "react";
import clsx from 'clsx';

import { viewContainerConst } from 'components/modals/needATutorModal//data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setNeedTutorPayload } from 'redux/needTutor/needTutor.slice';
import { JourneyConst } from 'redux/needTutor/needTutor.const';

const Subject = ({ setStepCount, setViewContainer }) => {
    const { user } = useAppState((state) => state.user)
    const { logInSignUp } = useAppState(s => s.auth)
    const { needTutor } = useAppState((state) => state.needTutor)

    const dispatcher = useAppDispatcher()

    const [subjectName, setSubjectName] = useState("")
    const [subjectNameError, setSubjectNameError] = useState("")

    useEffect(() => {
        if (needTutor?.needTutorPayload?.subject) {
            setSubjectName(needTutor?.needTutorPayload?.subject)
        }
    }, [needTutor?.needTutorPayload?.subject])

    const onAddSubject = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        if (!subjectName) {
            setSubjectNameError("Please enter subject name")
            return;
        }
        if (!logInSignUp.isLogedIn) {
            setStepCount(3)
            setViewContainer(viewContainerConst.NAME.value)
        }
        if (logInSignUp.isLogedIn) {
            if (!user?.user?.mobileNo) {
                setStepCount(6)
                setViewContainer(viewContainerConst.PHONE.value)
            }
            if (user?.user?.mobileNo) {
                setStepCount(7)
                setViewContainer(viewContainerConst.CITY.value)
            }
        }
        dispatcher(setNeedTutorPayload({
            ...needTutor?.needTutorPayload,
            subject: subjectName.trim()
        }))
    }

    return (
        <div className={"w-full h-full flex flex-col items-start justify-start gap-12 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                {needTutor?.needTutorPayload?.journey === JourneyConst.ME.value
                    && "Which topic/subject would you like help with?"
                }
                {needTutor?.needTutorPayload?.journey === JourneyConst.OTHER.value
                    && "Which topics/subject would they like help with?"
                }
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-1"}>
                <input
                    type={"text"}
                    placeholder={"Start Typing - English, Mathematics..."}
                    className={clsx(
                        "w-10/12 p-5 rounded-md focus:outline-none",
                        "font-bodyPri font-normal text-text-800 text-lg",
                        "placeholder:text-text-500 placeholder:text-lg",
                        subjectNameError && "border-2 border-red-500 focus:border-red-700",
                        !subjectNameError && "border-2 border-divider-medium focus:border-2 focus:border-text-400"
                    )}
                    autoFocus={true}
                    value={subjectName}
                    onChange={(event) => setSubjectName(event.target.value)}
                    onKeyDown={onAddSubject}
                />
                {subjectNameError &&
                    <span className={"font-bodyPri font-normal text-red-500 text-xs"}>
                        {subjectNameError}
                    </span>
                }
            </div>
            <span
                className={clsx(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {"Continue"}
            </span>
        </div>
    )
}

export default Subject;