import clsx from "clsx";

import { OptionSelector } from "components/common-components/Select";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps } from "redux/booking/booking.slice";
import { bookingStatus } from "redux/booking/booking.const";
import { courseType } from "redux/course/course.const";
import { filterName } from "redux/booking/booking.const";


const BlueButton: React.FC<{
  name: string;
  onClick: () => void;
  active?: boolean;
}> = ({ name, onClick, active = false }) => (
  <button onClick={onClick} className={clsx(
      "px-5 py-[2px] whitespace-nowrap rounded-xl ",
      "hover:bg-primary-main hover:text-white",
      active ? "bg-primary-main text-white" : "bg-primary-light text-primary-main"
  )}>
    {name}
  </button>
);


export function TableFilerActions() {
  const { bookingList, filterProps } = useAppState((s) => s.booking);
  
  const dispatcher = useAppDispatcher();

  return (
    <div className={clsx("w-full px-6 py-3 bg-white shadow-md rounded-lg")}>
      <div className="flex flex-col items-center justify-center sm:flex-row sm:justify-between">
        <div className="flex items-center justify-start space-x-4 w-min">
          <div>
            <BlueButton
              name={courseType.ALL.label}
              onClick={() => dispatcher(setFilterProps({
                filterProps: { ...filterProps, courseType: courseType.ALL.value },
                list: bookingList.bookingList?.results,
                filterName: filterName.BOOKING.value
              }))
              }
              active={filterProps.courseType === courseType.ALL.value}
            />
          </div>
          <div>
            <BlueButton
              name={courseType.ONE_ON_ONE.label}
              onClick={() => {
                dispatcher(setFilterProps({
                  filterProps: { ...filterProps, courseType: courseType.ONE_ON_ONE.value },
                  list: bookingList.bookingList?.results,
                  filterName: filterName.BOOKING.value
                }))
              }
              }
              active={filterProps.courseType === courseType.ONE_ON_ONE.value}
            />
          </div>
          <div>
            <BlueButton
              name={courseType.GROUP.label}
              onClick={() => {
                dispatcher(setFilterProps({
                  filterProps: { ...filterProps, courseType: courseType.GROUP.value },
                  list: bookingList.bookingList?.results,
                  filterName: filterName.BOOKING.value
                }))
              }
              }
              active={filterProps?.courseType === courseType.GROUP.value}
            />
          </div>
        </div>
        <div className="flex flex-col w-full mt-2 sm:w-auto sm:space-x-2 sm:flex-row sm:mt-0">
          <div className="w-full sm:w-48">
            <OptionSelector
              onChange={(v) => {
                dispatcher(setFilterProps({
                  filterProps: { ...filterProps, bookingStatus: v.value },
                  list: bookingList.bookingList?.results,
                  filterName: filterName.BOOKING.value
                }))
              }}
              options={Object.values(bookingStatus)}
              value={filterProps?.bookingStatus}
              className="w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
