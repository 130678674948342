import tw from 'twin.macro';
import { PageContent, ScreenCard } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)
`flex justify-center items-center min-h-screen my-10 md:my-0`;

export const Container = tw(ScreenCard)``;

export const PageNotFoundCard = tw.div`
    p-10 min-h-full
`;

export const PageNotFoundCardHeader = tw.div`
    flex items-start justify-start gap-5
`;

export const PageNotFoundContentContainer = tw.div`
    pt-3 flex flex-col items-start justify-start gap-5
`;

export const PageNotFoundContent = tw.span`
    flex flex-col items-center justify-center gap-3
`;

export const ErrorCodeHeading = tw.h1`
    text-4xl font-bodySec font-semibold text-text-700
`;

export const ErrorContentHeading = tw.h2`
    text-2xl font-bodySec font-semibold text-text-700
`;

export const SubHeading = tw.span`
    font-subHeading font-normal font-bodyPri text-text-500 text-lg w-full flex flex-col justify-center items-center gap-1
    lg:flex-row
`;

export const HomeButtons = tw.div`
    w-full flex justify-between items-center
`;

export const BackButton = tw.span`
    w-28 py-1.5 flex justify-center items-center border border-secondary-main rounded-full text-secondary-main font-normal font-buttons hover:bg-secondary-main hover:text-text-50 cursor-pointer
`;

export const HomePage = tw.span`
    text-primary-main font-bodyPri font-medium tracking-wide
`;