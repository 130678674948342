import { useState, useEffect } from "react";

import MyTypography from "components/Typography";

import Filters from "./Filters";
import Dropdowns from "./Dropdowns";
import NumberCard from "./NumberCard";

import { useAppState } from 'hooks/useStore';
import { pagesInfo } from "utils/pagesInfo";

const Header = (props) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const pageName = currentPageInfo.pageName;

  const [stickyFilter, setStickyFilter] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setStickyFilter(true)
    } else if (window.scrollY === 0) {
      setStickyFilter(false)
    }
  }

  return !stickyFilter ? (
    <div className={`h-auto lg:h-32 border w-full p-6 transition delay-75 duration-150 ${pageName === pagesInfo.FIND_TUTORS.pageName && 'bg-blue-300'} ${pageName === pagesInfo.FIND_COURSES.pageName && 'bg-cyan-300'}`}>
      <div className="grid grid-cols-12">
        <div className="col-span-1"></div>
        <div className="col-span-10">
          <div className="flex justify-center flex-col items-center">
            <MyTypography className="text-3xl font-medium text-center">
              {props.filterText.title}
            </MyTypography>
          </div>
          {/* <Filters {...props} />
          <Dropdowns {...props} /> */}
          <div className="grid grid-cols-3 gap-5 md:gap-10 mt-8">
            {props.filterText.steps.map((item, index) => (
              <div className="col-span-3 md:col-span-1" key={index}>
                <NumberCard num={item.step} title={item.stepText} />
              </div>
            ))}
          </div>
        </div>
        <div className="col-span-1"></div>
      </div>
    </div>
  ) : (
    <div className="w-full shadow-lg fixed top-0 mt-16 bg-white z-40 transition delay-75 duration-150">
      <div className="grid grid-cols-12">
        <div className="col-span-1"></div>
        <div className="col-span-10">
          {/* <Filters {...props} />
          <Dropdowns {...props} /> */}
        </div>
        <div className="col-span-1"></div>
      </div>
    </div>
  );
};

export default Header
