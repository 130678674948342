import { offeringFilterProps } from "redux/offering/offering.const"

export const OFFERING_INITIAL_STATE = {
    offeringList: {
        isLoading: false,
        offeringList: null,
        filteredOfferingList: null,
        errorMsg: null
    },
    offeringCourse: {
        isLoading: false,
        offeringCourse: null,
        errorMsg: null
    },
    addOffering: {
        isLoading: false,
        addOffering: null,
        errorMsg: null
    },
    filterProps: offeringFilterProps
}