
import Hero2 from "pages/global/company/images/Hero2.jpg";

const Hero = () => {

  return (
    <div className={"w-full h-64"}>
    <img
      src={Hero2}
      className={"w-full h-full object-cover"}
    />
  </div>
  )
}

export default Hero