import { toast } from "react-toastify";

import courseService from 'redux/course/course.services';
import {
    setCourseListLoading,
    setCourseList,
    setCourseListErrorMsg,

    setMyCourseListLoading,
    setMyCourseList,
    setMyCourseListErrorMsg,

    setTutorCourseListLoading,
    setTutorCourseList,
    setTutorCourseListErrorMsg,

    setFavCourseListLoading,
    setFavCourseList,
    setFavCourseListErrorMsg,

    setCourseDetailLoading,
    setCourseDetail,
    setCourseDetailErrorMsg,

    setSelectedCourseLoading,
    setSelectedCourse,
    setSelectedCourseErrorMsg,

    setAddFavCourseLoading,
    setAddFavCourseErrorMsg,
} from 'redux/course/course.slice';

import { setUser } from "redux/user/user.slice";

// request for get course list
export const getCoursePublicList = (query) => async (dispatch) => {
    dispatch(setCourseListLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", status: "published", visibility: "public", bookingType: "regular", ...query }
        }
        const response = await courseService.getCoursePublicList(requestData)
        if (response.status === 200) {
            dispatch(setCourseList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCourseListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCourseListLoading(false))
    }
}

// request for course public profile
export const getCoursePublicProfileByCourseId = (courseId) => async (dispatch) => {
    dispatch(setCourseDetailLoading(true))

    try {
        const requestData = {
            query: {roleStatus: "active", status: "published", bookingType: "regular" },
            params: { courseId: courseId }
        }
        const response = await courseService.getCoursePublicProfileByCourseId(requestData)
        if (response.status === 200) {
            dispatch(setCourseDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCourseDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCourseDetailLoading(false))
    }
}

export const getSelectedCourseByCourseId = (courseId) => async (dispatch) => {
    dispatch(setSelectedCourseLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", status: "published" },
            params: { courseId: courseId }
        }
        const response = await courseService.getCoursePublicProfileByCourseId(requestData)
        if (response.status === 200) {
            dispatch(setSelectedCourse(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setSelectedCourseErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setSelectedCourseLoading(false))
    }
}

export const getCourseTutorUserPublicListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setTutorCourseListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: { status: "published", roleStatus: "active", visibility: "public", bookingType: "regular", ...query },
        }
        const response = await courseService.getCourseTutorUserPublicListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorCourseList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorCourseListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorCourseListLoading(false))
    }
}

export const getMyCourseUserListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setMyCourseListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: { roleStatus: "active", status: "published", visibility: "public", bookingType: "regular", ...query }
        }
        const response = await courseService.getMyCourseUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setMyCourseList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setMyCourseListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setMyCourseListLoading(false))
    }
}

export const getFavCourseUserListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setFavCourseListLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            query: { roleStatus: "active", status: "published", visibility: "public", bookingType: "regular", ...query }
        }
        const response = await courseService.getFavCourseUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setFavCourseList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setFavCourseListErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setFavCourseListLoading(false))
    }
}

export const createFavCourseUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavCourseLoading(true))

    try {
        const {user} = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await courseService.createFavCourseUserDetailByUserId(requestData)
        if (response.status === 200) {
                dispatch(setUser({
                    ...user?.user,
                    favCourses: [...user?.user?.favCourses, { courseId: response.data.data.courseId }]
                }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFavCourseErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddFavCourseLoading(false))
    }
}

export const deleteFavCourseDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavCourseLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await courseService.deleteFavCourseDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favCourses: user?.user?.favCourses?.filter((course) => course?.courseId !== body.courseId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFavCourseErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddFavCourseLoading(false))
    }
}
