export const url = {
    COUNTRY_LIST: "/masters/countries",
    COUNTRY_DETAIL: "/masters/countries/{masterCountryId}",
    TIMEZONE_COUNTRY_DETAIL: "/masters/countries/timezones/{timeZone}",
    STATE_LIST: "/masters/states/{masterCountryId}",
    CITY_LIST: "/masters/cities/{masterStateId}",
    CURRENCY_LIST: "/masters/currencies",
    TIMEZONE_LIST: "/masters/timezones",
    LANGUAGE_LIST: "/masters/languages",
    LANGUAGE_LEVEL_LIST: "/masters/language-levels",
    PROFICIENCY_LIST: "/masters/proficiencies",
    TAG_LIST: "/masters/tags",
}

export const languageLevelConst = {
    A1_BEGINNER: {
        label: "Beginner",
        value: "a1_beginner"
    },
    A2_ELEMENTARY: {
        label: "Elementary",
        value: "a2_elementary"
    },
    B1_INTERMEDIATE: {
        label: "Intermediate",
        value: "b1_intermediate"
    },
    B2_UPPER_INTERMEDIATE: {
        label: "Upper Intermediate",
        value: "b2_upper_intermediate"
    },
    C1_ADVANCE: {
        label: "Advance",
        value: "c1_advance"
    },
    C2_PROFICIENT: {
        label: "Proficient",
        value: "c2_proficiency"
    },
    NATIVE: {
        label: "Native",
        value: "native"
    }
}