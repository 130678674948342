import React, { useEffect, useState } from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Component imports
import ChatViewHeaderUser from "./ChatViewHeaderUser";
import MeetingScheduled from "../MeetingScheduled/MeetingScheduled";
import StartMeeting from "../StartMeeting/StartMeeting";

//Style imports
import { StyledChatViewHeader } from "./ChatViewHeader.styles";
import ChatViewMeetingButton from "../ChatViewMeetingButton/ChatViewMeetingButton";
import MuteNotifications from "../MuteNotifications/MuteNotifications";

export default function ChatViewHeader({
  setScheduleMeeting,
  setShowGroupDetails,
}) {
  const { selectedChat, chats } = useChat();
  const { user } = useUser();

  const isPremium = user.memberShip.includes("premium");
  const isGroup = selectedChat.type === "group";
  const muted = true;

  const { userId } = user;

  const syncedSelectedChat = chats.filter(
    (chat) => chat.id === selectedChat.id
  )[0];

  const [meetingWindow, setMeetingWindow] = useState(false);
  const [meetingScheduled, setMeetingScheduled] = useState(false);
  const [now, setNow] = useState(new Date());
  const { meetingDate, creator, link } = syncedSelectedChat.meeting;
  const isCreator = creator === userId;

  useEffect(() => {
    if (!meetingDate) {
      setMeetingScheduled(false);
      return;
    }
    const transformedDate = new Date(meetingDate.seconds * 1000);
    const meetingDateThreshold = new Date(
      // transformedDate.getTime() + 59 * 60000
      transformedDate.getTime() + 2 * 60000
    );
    const computedSchedule = now < meetingDateThreshold;
    setMeetingScheduled(computedSchedule);
    if (!computedSchedule) return;
    const computedWindow = now > transformedDate && now < meetingDateThreshold;
    setMeetingWindow(computedWindow);
    const delay = 10000;
    const update = setInterval(() => {
      setNow(new Date());
    }, delay);
    return () => clearInterval(update);
  }, [now, meetingDate]);

  function openScheduleMeetingModal() {
    setScheduleMeeting(true);
  }

  function toggleGroupDetails() {
    if (selectedChat.type !== "group") return;
    setShowGroupDetails(true);
  }

  return (
    <StyledChatViewHeader>
      <ChatViewHeaderUser toggleGroupDetails={toggleGroupDetails} />
      {isGroup && <MuteNotifications muted={muted} />}
      {meetingScheduled && !meetingWindow && (
        <MeetingScheduled meetingDate={meetingDate} creator={creator} />
      )}
      {meetingWindow && !isCreator && link && (
        <StartMeeting isCreator={isCreator} text="Join" link={link} />
      )}
      {meetingWindow && isCreator && (
        <StartMeeting isCreator={isCreator} text="Start" link={link} />
      )}
      {!meetingScheduled && isPremium && !meetingWindow && (
        <ChatViewMeetingButton onClick={openScheduleMeetingModal} />
      )}
    </StyledChatViewHeader>
  );
}
