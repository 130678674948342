import { useState } from "react";
import clsx from 'clsx';

import { courseTabs } from "pages/auth/myCourses/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps } from "redux/course/course.slice";
import { filterName } from "redux/course/course.const";
import { userRoles } from "redux/auth/auth.const";


const MyCoursesSearch = ({ myActiveTab }) => {
    const { locals } = useAppState((s) => s.local)
    const { myCourseList, favCourseList, filterProps } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher();

    const [searchText, setSearchText] = useState("");

    const handleFilter = () => {
        if (myActiveTab === courseTabs.ALL_COURSES.value) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, searchText: searchText },
                list: myCourseList?.myCourseList?.results,
                filterName: filterName.ALL_COURSES.value
            }));
        }
        if (myActiveTab === courseTabs.FAV_COURSES.value) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, searchText: searchText },
                list: favCourseList?.favCourseList?.results,
                filterName: filterName.FAV_COURSES.value
            }));
        }
    }

    return (
        <div className="flex justify-center p-4 bg-white rounded-lg">
            <div className="inline-flex mx-auto shadow-sm">
                <input
                    type="text"
                    className={clsx(
                        "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                        "border-sky-200 w-full sm:w-80"
                    )}
                    placeholder={locals.userRole === userRoles.STUDENT.value ? "Class name..." : ""}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>
                    <button
                        className="px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white"
                        onClick={handleFilter}
                    >
                        {"Search"}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default MyCoursesSearch;
