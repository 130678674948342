import authService from './auth.service';
import {
    setLogInSignUpLoading,
    setToken,
    setLogInSignUpErrorMsg,
    setClearLogInSignUp,

    setVerifyReferralLoading,
    setVerifyReferral,
    setVerifyReferralErrorMsg,

    setLogoutLoading,
    setLogout,
    setLogoutErrorMsg,
} from 'redux/auth/auth.slice';

import { getMyProfile } from 'redux/user/user.request';

export const login = (user) => async (dispatch) => {
    dispatch(setLogInSignUpLoading(true));

    try {
        const requestData = {
            body: user
        }
        const response = await authService.login(requestData)
        if (response.status === 200) {
            dispatch(setToken(response.data.accessToken))
            dispatch(getMyProfile())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setLogInSignUpErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setLogInSignUpLoading(false));
    }
}

export const getToken = () => async (dispatch) => {
    dispatch(setLogInSignUpLoading(true));
    try {
        const response = await authService.getToken()
        if (response.status === 200) {
            dispatch(setToken(response.data.accessToken))
            dispatch(getMyProfile())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setLogInSignUpLoading(false));
    }
}

export const createSignUp = (body) => async (dispatch) => {
    dispatch(setLogInSignUpLoading(true));

    try {
        const requestData = {
            body: body
        }
        const response = await authService.createSignUp(requestData)
        if (response.status === 200) {
            dispatch(setToken(response.data.accessToken))
            dispatch(getMyProfile())
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setLogInSignUpErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setLogInSignUpLoading(false))
    }
}

export const createVerifyReferral = (referralCode) => async (dispatch) => {
    dispatch(setVerifyReferralLoading(true))

    try {
        const requestData = {
            params: { referralCode: referralCode }
        }
        const response = await authService.createVerifyReferral(requestData)
        if (response.status === 200) {
            dispatch(setVerifyReferral(response.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setVerifyReferralErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setVerifyReferralLoading(false))
    }
}

export const createLogout = () => async (dispatch) => {
    dispatch(setLogoutLoading(true))

    try {
        const response = await authService.logout()
        if (response.status === 200) {
            dispatch(setLogout(response.data))
            dispatch(setClearLogInSignUp())
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setLogoutErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setLogoutLoading(false))
    }
}

// const requestData = {
//     headers: {token: userAuth.tokens.accessToken},
//     params: {tutorId: "1234"},
//     query: {status: "active", verified: "true"},
//     body: {
//         email: "",
//         password: ""
//     }
//    for a delete request body should be inside an object with key data. {data: body }
// }