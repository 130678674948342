import { userRoles } from "redux/auth/auth.const";

export const pageHeading = {
    heading: "My Profile",
    subHeading: "View and update your professional profile!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/profile.svg"
}

export const importantLinks = [
    {
        label: "Preferences",
        url: "/preferences",
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    {
        label: "Availability",
        url: "/preferences/availability",
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    {
        label: "Price",
        url: "/preferences/price",
        roles: [userRoles.TUTOR.value]
    },
    {
        label: "Onboarding",
        url: "/tutor-onboard",
        roles: [userRoles.TUTOR.value]
    },
]

export const data = {
    infoIcon: "We use your profile information to give you a personalised experience, everything you share here will not be visible publically view your public profile. Please make sure to save after making changes.",
    aboutMe: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
    skills: ["Microsoft Excel", "Functions", "Charts", "Graphs in excel", "Pivot Table", "Calculations", "Financial Terms", "Interview Skills", "Automation"],
    qualifications: ["Masters"],
    links: [
        {
            title: "Preferences",
            url: "/preferences"
        },
        {
            title: "Price",
            url: "/preferences/price"
        },
        {
            title: "Availability",
            url: "/preferences/availability"
        },
        {
            title: "onboarding",
            url: "/tutor-onboard"
        }
    ],
    StudentinfoIcon: "We use your profile information to give you a personalised experience, everything you share here will not be visible publically view your public profile. Please make sure to save after making changes.",
    Studentlinks: [
        {
            title: "Preferences",
            url: "/preferences"
        },
    ],
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male"
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const profileEditBtn = {
    profilePicUrl: {
        value: 'profilePicUrl',
    },
    firstName: {
        value: 'firstName',
    },
    lastName: {
        value: 'lastName',
    },
    gender: {
        value: "gender",
    },
    dateOfBirth: {
        value: "dateOfBirth",
    },
    educations: {
        value: 'educations',
    },
    videoUrl: {
        value: 'videoUrl',
    },
    elevatorPitch: {
        value: 'elevatorPitch',
    },
    bio: {
        value: 'bio',
    },
    learningStyleBio: {
        value: "learningStyleBio",
    },
    teachingStyleBio: {
        value: 'teachingStyleBio',
    },
    skills: {
        value: 'skills',
    },
    languages: {
        value: 'languages',
    },
    from: {
        value: 'from'
    },
    livingIn: {
        value: 'livingIn'
    }
}