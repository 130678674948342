import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
  onPaymentFailure,
  onPaymentSuccess,
  paymentModal,
} from "../store/GiftCardSlice";

export default function MockPaymentDialog() {
  const modalState = useAppState((s) => s.buyGiftCard._meta.showPaymentModal);
  const dispatcher = useAppDispatcher();

  function closeModal() {
    dispatcher(paymentModal({ show: false }));
  }

  function onSuccess() {
    dispatcher(
      // This is mock information!
      onPaymentSuccess({
        orderId: "mockorderid",
        otherdetails: "mockotherdetails",
        paymentId: "mockpaymentid",
        amountPaid: 500,
      })
    );
    closeModal();
  }

  function onFailure() {
    // THIS IS MOCK INFORMATION!
    dispatcher(
      onPaymentFailure({
        message: "mockmessage",
        amount: 500,
      })
    );
    closeModal();
  }

  return (
    <>
      <Transition appear show={modalState} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-50 opacity-60" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                className={clsx(
                  "inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle ",
                  "transition-all transform bg-white shadow-xl rounded-2xl"
                )}
              >
                <Dialog.Title
                  as="h3"
                  className={clsx(
                    "p-4 text-3xl font-bold leading-6 text-center ",
                    "bg-gradient-to-r text-blue-500"
                  )}
                >
                  Payment Confirmation Dialog
                </Dialog.Title>
                <div
                  className={clsx(
                    "mt-2",
                    "w-full" // remove this after adding content
                  )}
                >
                  <p className="px-4 py-3">
                    This is a mock implementation for payment gateway. Please
                    click success to mock a successful transaction and failure
                    to mock failed transaction.
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium border border-transparent rounded-md text-lime-900 bg-lime-100 hover:bg-lime-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={onSuccess}
                    >
                      Payment Success
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={onFailure}
                    >
                      Payment Failed
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
