import React from 'react';

// Components
import Typography from '../Typography';

const CircleNumber = (props) => {
    return (
        <div className={`rounded-full flex justify-center items-center cursor-pointer w-10 h-10 ${props.circleCSS}`}>
            <Typography className={props.textCSS}>
                {props.num}
            </Typography>
        </div>
    )
}

export default CircleNumber;