import {
    Container,
    BrandContainer,
    BrandName,
    GoogleLogo,
    FacebookLogo,
} from './SocialAuthStyle';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const SocialAuth = ({ submitSocialAuth }) => {
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const handleSubmit = (socialName) => {
        submitSocialAuth(socialName)
    }

    const handleOnClick = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COMING_SOON_MODAL.stateKey]: true
        }))
    }

    return (
        <Container onClick={handleOnClick}>
            <BrandContainer onClick={() => handleSubmit("google")}>
                <GoogleLogo />
                <BrandName>{'Google'}</BrandName>
            </BrandContainer>
            <BrandContainer onClick={() => handleSubmit("facebook")}>
                <FacebookLogo />
                <BrandName>{'Facebook'}</BrandName>
            </BrandContainer>
        </Container>
    )
}

export default SocialAuth;
