import { useEffect } from 'react';
import clsx from "clsx"
import { Link, useNavigate } from 'react-router-dom';

import { IoIosStats } from 'react-icons/io';
import { FiMessageSquare } from 'react-icons/fi'
import { MdOutlineVideoCall } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';

import { getSelectedCourseByCourseId } from "redux/course/course.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setChooseTimeSlotPayload, setClearAddBookingPayload } from "redux/booking/booking.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { chooseTimeSlotAction, weeklyTimeSlot } from "redux/booking/booking.const";

import { setClearMessageTemplate, setMessageTemplate } from 'redux/firebase/firebase.slice';
import { createFBIndividulaChat, getFBChat, getFBChatId, sendFBMessage } from 'redux/firebase/firebase.request';

import { pagesInfo } from 'utils/pagesInfo';


const StickyCard = ({ tutor, ...props }) => {
  const { user } = useAppState((s) => s.user)
  const { addBooking } = useAppState(s => s.booking)
  const { modal } = useAppState(s => s.local)
  const { logInSignUp } = useAppState(s => s.auth)
  const { messageTemplate } = useAppState((s) => s.firebase)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const isMyOffering = user?.user?.userId === tutor?.userId

  useEffect(() => {
    if (messageTemplate?.message && user?.user?.userId === messageTemplate?.userIdOne && tutor?.userId === messageTemplate?.userIdTwo) {
      createFBChatAndRedirect()
    }
  }, [messageTemplate?.message])

  const createFBChatAndRedirect = async () => {
    const fbChat = await createFBIndividulaChat(messageTemplate?.userIdOne, messageTemplate?.userIdTwo)

    if (!fbChat || fbChat === undefined) {
      dispatcher(setMessageTemplate({
        ...messageTemplate,
        isLoading: false,
        errorMsg: "Chat not exist!",
      }))
      return;
    }

    await sendFBMessage({
      senderUserId: user?.user?.userId,
      text: messageTemplate?.message,
      chatId: fbChat.chatId
    })

    dispatcher(setClearMessageTemplate())
    dispatcher(setModal({
      ...modal,
      [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: false
    }))

    navigate(`/message/${fbChat.chatId}`)
  }

  const handleMessage = async () => {
    if (!logInSignUp?.isLogedIn) {
      dispatcher(setModal({
        ...modal,
        [modalConst.AUTHORIZATION_MODAL.stateKey]: true
      }))
      return;
    }

    if (isMyOffering) return;

    const userIdOne = user?.user?.userId
    const userIdTwo = tutor?.userId

    const chatId = await getFBChatId(userIdOne, userIdTwo)
    let fbChat = await getFBChat(chatId)

    if (!fbChat || fbChat === undefined) {
      dispatcher(setMessageTemplate({
        ...messageTemplate,
        isLoading: false,
        userIdOne: userIdOne,
        userIdTwo: userIdTwo,
        message: '',
        errorMsg: null
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
      }))
      return;
    }

    navigate(`/message/${fbChat.chatId}`)
  }

  const handleTrialBooking = () => {
    if (isMyOffering) return;

    dispatcher(getSelectedCourseByCourseId(tutor?.trialCourse?.courseId))
    dispatcher(setClearAddBookingPayload())
    dispatcher(setChooseTimeSlotPayload({
      ...addBooking?.chooseTimeSlotPayload,
      chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
      actionItem: { ...addBooking?.chooseTimeSlotPaload?.actionItem, courseId: tutor?.trialCourse?.courseId },
      sessionDuration: tutor?.trialCourse?.sessionDuration,
      noOfSessions: 1,
      startDate: null,
      endDate: null,
      weeklyTimeSlot: weeklyTimeSlot
    }))
    dispatcher(setModal({ ...modal, [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true }))
  }

  return tutor && (
    <div className={props.scrollValue > 170 ? `fixed top-[4rem] animate-fade-in-down bg-white border-2 rounded-xl shadow-md z-20 hidden md:block ` : `absolute bg-white border-2 rounded-xl shadow-md z-20 hidden md:block`}>
      <div className="w-96 rounded-t-lg overflow-hidden md:w-full h-full">
        <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${tutor?.videoUrl}`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
        </iframe>
      </div>
      <div className="space-y-3 pt-3 px-4">
        <div className="flex justify-start items-center text-text-600 font-lg font-bodyPri gap-3">
          {"From"}
          <span className="text-text-900 text-xl font-semibold">
            {"USD " + parseFloat(tutor?.price / 100).toFixed(2)}
          </span>
          {"per hour"}
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col">
            <div className='w-full flex items-center justify-evenly'>
              <Link to={`${pagesInfo.TUTOR.pagePath}/${tutor?.userId}/class`}
                className='w-2/3 py-1 flex justify-center items-center border border-secondary-main rounded-full text-secondary-main font-normal font-buttons hover:bg-secondary-main hover:text-text-50 cursor-pointer'>
                {"View Classes"}
              </Link>
              <span onClick={handleMessage} className={clsx(
                'text-3xl',
                isMyOffering
                  ? "text-text-300"
                  : "text-text-700 hover:text-primary-main cursor-pointer"
              )}>
                <FiMessageSquare />
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div className={"flex items-center gap-2"}>
              <IoIosStats className="text-gray-700 text-xl" />
              <span className="text-text-700 font-normal font-bodyPri">{"Learner Level:"}</span>
              <span className='text-text-900 font-medium font-bodyPri'>{tutor?.proficiencies.length > 2 ? tutor?.proficiencies.slice(0, 2).join(" , ") : tutor?.proficiencies.join(" , ")}</span>
            </div>
            <div className="flex items-center gap-2">
              <BiCalendar className="text-gray-500 text-lg" />
              <div className="text-text-700 font-normal font-bodyPri">{"Availability:"} </div>
              <Link to={"/preferences"} className="font-medium font-bodyPri text-primary-main underline cursor-pointer">{"See Calendar"}
              </Link>
            </div>
            <div className="flex text-base font-bodyPri tracking-wide gap-2">
              <MdOutlineVideoCall className="text-gray-700 text-xl" />
              <div className="text-text-700 font-normal font-bodyPri">Trial Session: </div>
              <div className="flex gap-1">
                <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                  {tutor?.trialCourse?.price !== 0 ? "USD " + parseFloat(tutor?.trialCourse?.price / 100).toFixed(2) : "Free"}
                </span>
                <span className="text-text-900 font-medium font-bodyPri flex gap-1">
                  ({tutor?.trialCourse?.sessionDuration} {"min"})
                </span>
              </div>
            </div>
            <hr />
            <span onClick={handleTrialBooking} className={clsx('w-full py-1 mb-2 flex justify-center items-center rounded-full font-normal font-buttons border',
              isMyOffering
                ? 'border-text-300 text-text-300'
                : 'border-text-700 text-text-700 hover:border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer'
            )}>
              {"Book a Trial"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyCard;
