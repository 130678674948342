import axios from 'axios';

import { baseApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/document/document.const"


class DocumentService {

    generateS3PresignedPost = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.GENERATE_S3_PRESIGNED_POST,
            body
        )

        return response
    }

    uploadDocumentToS3 = async ({ s3PresignedUrl, formData }) => {
        await axios.post(s3PresignedUrl, formData)
    }
}

export default new DocumentService()