import { useState, useEffect } from "react";

import { StudentInformationCard } from "pages/auth/myStudents/components/StudentInformationCard";

import Pagination from "components/generalComponent/pagination/Pagination";
import StudentCardLoader from "components/loader/StudentCardLoader";

import { 
    getFavStudentUserListByUserId,
    createFavStudentUserDetailByUserId, 
    deleteFavStudentUserDetailByUserId,
} from 'redux/student/student.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";

import { StudentProfile } from "pages/auth/myStudents/types";
import { setClearFavStudentList } from "redux/student/student.slice";


export const FavStudents = () => {
    const { user } = useAppState((s) => s.user)
    const { favStudentList } = useAppState((s) => s.student)
    
    const [firstIndex, setFirstIndex] = useState<number>(0)
    const [lastIndex, setLastIndex] = useState<number>(5)
    const [itemsPerPage] = useState(5)
    const dispatcher = useAppDispatcher();

    useEffect(() => {
        dispatcher(getFavStudentUserListByUserId(user?.user?.userId))

        return () => {
            dispatcher(setClearFavStudentList())
        }
    }, [])

    useEffect(() => {
        setFirstIndex(0)
        setLastIndex(itemsPerPage)
    }, [itemsPerPage, favStudentList?.filteredFavStudentList])

    return (
        <div className="rounded-lg">
            <div className="px-4 py-6 border rounded-md sm:px-10 md:px-18 lg:px-20 xl:px-30">
                <div className="flex flex-col space-y-4">
                    {new Array(itemsPerPage).fill("").map(item => <StudentCardLoader isLoading={favStudentList?.isLoading} />)}
                    {favStudentList?.filteredFavStudentList?.slice(firstIndex, lastIndex).map((student: any, index:number) => (
                        <div key={index}>
                            <StudentInformationCard
                                student={student}
                                isFav={user?.user?.favStudents?.filter((favStudent: StudentProfile) =>
                                    favStudent.studentId === student.studentId).length > 0 ? true : false}
                                handleFav={(studentId: number) => {
                                    const favoriteStudents = user?.user?.favStudents?.filter((favStudent: StudentProfile) =>
                                        favStudent.studentId === studentId)
                                    if (favoriteStudents?.length > 0) {
                                        dispatcher(deleteFavStudentUserDetailByUserId({ studentId: studentId }))
                                    }
                                    else {
                                        dispatcher(createFavStudentUserDetailByUserId({ studentId: studentId }))
                                    }
                                }}
                            />
                        </div>
                    ))}
                    {favStudentList?.filteredFavStudentList && favStudentList?.filteredFavStudentList?.length !== 0 &&
                        <div className="py-3 space-x-1 bg-white rounded-lg shadow-md border border-solid">
                            <Pagination
                                onStartIndex={(firstIndex: number) => (setFirstIndex(firstIndex))}
                                onLastIndex={(lastIndex: number) => (setLastIndex(lastIndex))}
                                totalItems={favStudentList?.filteredFavStudentList?.length}
                                itemsPerPage={itemsPerPage}
                            />
                        </div>
                    }
                    {(favStudentList?.filteredFavStudentList?.length === 0 || favStudentList?.errorMsg) &&
                        <span className='w-full h-full flex items-center justify-center text-center text-base font-bodyPri tracking-wide'>
                            {"No Students Found!"}
                        </span>
                    }
                </div>
            </div>
        </div>
    );
};
