import { useEffect } from 'react';

import Accordion from 'components/accordion/Accordion';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { getFAQListByCompanyId } from 'redux/company/company.request';

const CompanyFAQ = () => {
  const { companyPublicProfile, FAQList } = useAppState((state) => state.company)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (companyPublicProfile?.companyPublicProfile?.companyId) {
      dispatcher(getFAQListByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId))
    }
  }, [companyPublicProfile?.companyPublicProfile?.companyId])
  return (
    <div className={"flex items-center justify-center px-5 md:px-0"}>
      <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
        <div className={"grid grid-cols-12 w-full bg-white p-5"}>
          <div className={"col-span-12 md:(col-start-2 col-span-10) lg:(col-start-1 col-span-12)"}>
            {FAQList?.FAQList?.length > 0 &&
              <Accordion
                title={'Frequently Asked Questions?'}
                dataFAQ={FAQList?.FAQList}
              />
            }
            {FAQList?.FAQList?.length === 0 &&
              <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                {"No FAQs added yet!"}</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyFAQ