import { createSlice } from "@reduxjs/toolkit";
import { FIREBASE_INITIAL_STATE } from "redux/firebase/firebase.initialState";


const firebase = createSlice({
    name: "firebase",
    initialState: FIREBASE_INITIAL_STATE,
    reducers: {
        // Reducer for message template
        setMessageTemplate: (state, { payload }) => {
            state.messageTemplate = payload
        },
        setClearMessageTemplate: (state) => {
            state.messageTemplate = FIREBASE_INITIAL_STATE.messageTemplate
        }
    }
})

export const {
    setMessageTemplate,
    setClearMessageTemplate,
} = firebase.actions

export default firebase.reducer