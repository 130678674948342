import { useAppState } from 'hooks/useStore';


const EventDetailTitle = () => {
  const { eventDetail } = useAppState(s => s.event)

  return eventDetail?.eventDetail?.eventName ? (
    <div className={"flex items-center justify-center overflow-hidden"}>
      <span className={"font-bodyPri font-semibold text-lg text-text-900 text-center truncate"}>
        {eventDetail?.eventDetail?.eventName}
      </span>
    </div>
  ) : null
}

export default EventDetailTitle