import * as React from "react";
import clsx from "clsx";
import { OptionSelector } from "components/common-components/Select";

export type TabConfig = {
  label: string;
  element: React.ReactNode;
};

type TabLayoutProps = {
  className?: string;
  config: TabConfig[];
};

export default function TabLayout({ config, className }: TabLayoutProps) {
  const [activeTab, setActiveTab] = React.useState<TabConfig>(config[0]);
  const options = config.map((tab) => ({
    label: tab.label,
    value: tab.label,
  }));
  return (
    <div>
      <div>
        <div className="sm:hidden">
          {
            <OptionSelector
              onChange={(v) => {
                const element = config.find(
                  (c) => c.label === v.value
                )?.element;
                setActiveTab({ ...v, element });
              }}
              value={activeTab.label}
              options={options}
            />
          }
        </div>
        <div className="hidden sm:block">
          <nav
            className="relative z-0 flex mx-auto divide-x divide-gray-200 rounded-lg shadow w-80"
            aria-label="Tabs"
          >
            {config.map((tab, tabIdx) => (
              <button
                key={tab.label}
                className={clsx(
                  tab.label === activeTab?.label
                    ? "text-gray-800 font-bold"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-l-lg" : "",
                  tabIdx === config.length - 1 ? "rounded-r-lg" : "",
                  "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                )}
                onClick={() => setActiveTab(tab)}
              >
                <span>{tab.label}</span>
                <span
                  className={clsx(
                    tab.label === activeTab?.label
                      ? "bg-blue-500"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-1"
                  )}
                />
              </button>
            ))}
          </nav>
        </div>
      </div>
      {/* Display Active Tab Element */}
      <div className="py-4">{activeTab.element}</div>
    </div>
  );
}
