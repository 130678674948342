import React from 'react'

const StudentCardLoader = ({isLoading}) => {
    return isLoading ? (
        <div className="w-full p-5 border rounded-md space-y-3 bg-white">
            <div className="animate pulse flex items-center justify-between w-full">
                <div>
                    {/* profile dp */}
                    <div className="flex items-center justify-center space-x-4">
                        <div className={"w-12 h-12 rounded-full relative bg-slate-200"}>
                        </div>
                        <div className='w-16 h-5 bg-slate-200'></div>
                        <div className='w-4 h-4 rounded-full bg-slate-200'></div>
                    </div>
                </div>
                <div className='w-8 h-8 rounded-full bg-slate-200'></div>
            </div>
            <div className="grid grid-cols-4 gap-3">
                <div className='animate-pulse grid col-span-full md:col-span-3 grid-cols-1 sm:grid-cols-2 gap-2 pl-3 font-bodyPri font-normal text-base text-text-800"'>
                    <div className='col-span-1 sm:col-span-2 inline-flex items-center justify-start space-x-3'>
                        <span className='w-48 h-5 bg-slate-200'></span>
                    </div>
                    <div className='col-span-1 sm:col-span-2 inline-flex items-center justify-start space-x-3'>
                        <span className='w-28 h-5 bg-slate-200'></span>
                    </div>
                    <div className='col-span-1 sm:col-span-2 inline-flex items-center justify-start space-x-3'>
                        <span className='w-36 h-5 bg-slate-200'></span>
                    </div>
                </div>
                <div className='animate-pulse col-span-full md:col-span-1 flex items-end justify-end'>
                    <span className='px-12 py-5 rounded-md bg-slate-200'></span>
                </div>
            </div>
        </div>
    ): null
}

export default StudentCardLoader