import React, {useEffect} from "react";
import {useNavigate } from 'react-router-dom'

import { FaLanguage } from "react-icons/fa";
import { MdOutlineCategory } from "react-icons/md";
import { BsBarChart } from "react-icons/bs";
import { BiBookReader } from "react-icons/bi";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { StudentProfile } from "pages/auth/myStudents/types";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setClearMessageTemplate, setMessageTemplate } from 'redux/firebase/firebase.slice';
import { createFBIndividulaChat, getFBChat, getFBChatId, sendFBMessage } from 'redux/firebase/firebase.request';

type DocumentData = any;


export const StudentInformationCard: React.FC<{
  student: StudentProfile; isFav: boolean, handleFav: (studentId: number) => void
}> = ({ student, isFav = false, handleFav }) => {
  const { logInSignUp } = useAppState(s => s.auth)
  const { user } = useAppState((s) => s.user)
  const { messageTemplate } = useAppState((s) => s.firebase)
  const { modal } = useAppState((s) => s.local)

  const navigate = useNavigate()
  const dispatcher = useAppDispatcher()

  const handleSendMessage = async () => {
    if (!logInSignUp?.isLogedIn) {
      dispatcher(setModal({
        ...modal,
        [modalConst.AUTHORIZATION_MODAL.stateKey]: true
      }))
      return;
    }
    
    const userIdOne = user?.user?.userId
    const userIdTwo = student?.userId
    
    if (userIdOne === userIdTwo) {
      alert("Something went wrong!")
      return;
    }  

    const chatId = await getFBChatId(userIdOne, userIdTwo)
    let fbChat: DocumentData | null | undefined = await getFBChat(chatId)

    if (!fbChat || fbChat === undefined) {
      dispatcher(setMessageTemplate({
        ...messageTemplate,
        isLoading: false,
        userIdOne: userIdOne,
        userIdTwo: userIdTwo,
        message: '',
        errorMsg: null
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
      }))
      return;
    }

    navigate(`/message/${fbChat.chatId}`)
  }

  useEffect(() => {
    if (messageTemplate?.message && user?.user?.userId === messageTemplate?.userIdOne && student?.userId === messageTemplate?.userIdTwo) {
      createFBChatAndRedirect()
    }
  }, [messageTemplate?.message])
  
  const createFBChatAndRedirect = async () => {
    const fbChat = await createFBIndividulaChat(messageTemplate?.userIdOne, messageTemplate?.userIdTwo)

    if (!fbChat || fbChat === undefined) {
      dispatcher(setMessageTemplate({
        ...messageTemplate,
        isLoading: false,
        errorMsg: "Chat not exist!",
      }))
      return;
    }

    await sendFBMessage({
      senderUserId: user?.user?.userId,
      text: messageTemplate?.message,
      chatId: fbChat.chatId
    })

    dispatcher(setClearMessageTemplate())
    dispatcher(setModal({
        ...modal,
        [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: false
    }))

    navigate(`/message/${fbChat.chatId}`)
  }
  
  return (
    <div className="w-full p-5 border rounded-md space-y-3 bg-white">
      <div className="flex items-center justify-between w-full">
        {/* profile dp */}
        <div className="flex items-center justify-center space-x-4">
          <div className={"w-12 h-12 rounded-full relative"}>
            <img className={"w-full h-full object-cover overflow-hidden"}
              src={student?.profilePicUrl} alt={'profile'}
            />
            <div className="absolute w-4 h-4 right-0 bottom-0 rounded-full overflow-hidden">
              <img className="w-full h-full object-cover"
                src={`https://countryflagsapi.com/png/${student?.fromCountryDomain}`} alt="country-flag" />
            </div>

          </div>
          <span className="font-subHeading font-semibold tracking-wide text-base text-text-900">
            {student?.firstName + " " + student?.lastName?.charAt(0)}
            </span>
          {student?.onlineStatus === 'online' &&
            <div className={"w-3 h-3 rounded-full bg-green-600"}></div>
          }
        </div>
        {/* Fav Ico */}
        <div>
          <span onClick={() => handleFav(student?.studentId)} className="cursor-pointer">
            {isFav ?
              (<FavoriteIcon className='text-red-500 cursor-pointer' style={{ fontSize: "1.8rem" }} />) :
              <FavoriteBorderIcon className='text-text-500 cursor-pointer' style={{ fontSize: "1.8rem" }} />
            }
          </span>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-3">
          <div className="grid col-span-full md:col-span-3 grid-cols-1 sm:grid-cols-2 gap-2 pl-3 font-bodyPri font-normal text-base text-text-800">
            <div className="col-span-1 sm:col-span-2 inline-flex items-center justify-start space-x-3">
              <BiBookReader size={18} />
              <p>{"Course Title: "}
                <span className={'font-medium text-text-900 truncate'}>{student?.course[0]?.courseTitle}</span></p>
            </div>
            <div className="col-span-1 inline-flex items-center justify-start space-x-3">
              <FaLanguage size={18} color={'text-text-500'} />
              <p>{"Speaks:"}
                <span className={'font-medium text-text-900 truncate'}> {student?.course[0]?.language}</span>
              </p>
            </div>
            <div className="col-span-1 inline-flex items-center justify-start space-x-3">
              <MdOutlineCategory size={18} />
              <p>{"Category:"}
                <span className={'font-medium text-text-900 truncate'}> {student?.course[0]?.category}</span>
              </p>
            </div>
            <div className="col-span-1 inline-flex items-center justify-start space-x-3">
              <BsBarChart size={18} />
              <p>{"Level:"}
                <span className={'font-medium text-text-900 truncate'}> {student?.course[0]?.proficiency}</span>
              </p>
            </div>
          </div>
        <div className="col-span-full md:col-span-1 flex items-end justify-end">
          <button className="px-3 py-1 rounded-md border-2 border-primary-main bg-white text-primary-main hover:bg-primary-main hover:text-white"
          onClick={handleSendMessage}
          >
            {"Message"}
          </button>
        </div>
      </div>
    </div>
  );
};
