export const JourneyConst = {
    ME: {
        label: "Me",
        value: "me"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const contactTimeConst = {
    MORNING: {
        label: "Morning",
        value: "morning",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/morning.png"
    },
    AFTERNOON: {
        label: "Afternoon",
        value: "afternoon",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/afternoon.png"
    },
    EVENING: {
        label: "Evening",
        value: "evening",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/evening.png"
    },
    ANYTIME: {
        label: "Anytime",
        value: "anyTime",
        imgUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/need-a-tutor-modal/anytime.png"
    },
}