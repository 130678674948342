export const url = {
    TUTOR_PUBLIC_LIST: "/tutors",
    TUTOR_USER_PUBLIC_PROFILE: "/tutors/users/{userId}",
    MY_TUTOR_USER_LIST: "/tutors/users/{userId}/my-tutors",
    FAV_TUTOR_USER_LIST: "/tutors/users/{userId}/fav-tutors",
    TUTOR_USER_DETAIL: "/tutors/users/{userId}/details",
    TUTOR_USER_PRICE: "/tutors/users/{userId}/prices",
    TUTOR_USER_LESSON: "/tutors/users/{userId}/lessons",
    TUTOR_USER_LANGUAGE_LIST: "/tutors/users/{userId}/languages",
    TUTOR_LANGUAGE_DETAIL: "/tutors/languages/{tutorLanguageId}",
    TUTOR_USER_CATEGORY_LIST: "/tutors/users/{userId}/categories",
    TUTOR_CATEGORY_DETAIL: "/tutors/categories/{tutorCategoriesId}"
}

export const tutorFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    courseType: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: ""
}

export const filterName = {
    FIND_TUTORS: {
        value: "find_tutors"
    },
    ALL_TUTORS: {
        value: "all_tutors"
    },
    FAV_TUTORS: {
        value: "fav_tutors"
    }
}
