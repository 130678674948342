import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/meeting/meeting.const"


class MeetingService {

    getMeetingByMeetingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_MEETING_BY_MEETING_ID, params)
        )

        return response
    }
}

export default new MeetingService()