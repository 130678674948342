import * as React from "react";
import { Button } from "components/common-components/Buttons";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { validateEmail } from "utils/validation-functions";
import { paymentModal, setOrderDetails } from "../store/GiftCardSlice";
import MockPaymentDialog from "./MockPaymentDialog";

function InputField({
  onChange,
  value,
  placeholder,
  label,
}: {
  label: string;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="flex flex-col">
      <label className="pb-1 text-sm font-semibold opacity-70">{label}</label>
      <input
        className="px-4 py-3 border border-blue-200 rounded-lg shadow-sm"
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export const OrderSummary: React.FC = () => {
  const orderState = useAppState((s) => s.buyGiftCard.orderSummary);
  const dispatcher = useAppDispatcher();

  const onPay = () => {
    if (!validateEmail(orderState.paymentEmail)) {
      alert("Please enter a valid email");
      return;
    } else if (
      orderState.paymentPhone.length !== 10 ||
      isNaN(Number(orderState.paymentPhone))
    ) {
      alert("Please enter a valid phone number");
      return;
    }

    dispatcher(paymentModal({ show: true }));
  };

  return (
    <div>
      <div>
        <div className="py-6">
          <OrderSummaryCard />
        </div>
        <div className="px-6 py-5 space-y-3 bg-white shadow-lg rounded-xl">
          <p className="text-sm text-center text-gray-400">Payment Details</p>
          <InputField
            label="Your Email"
            onChange={(t) => {
              dispatcher(
                setOrderDetails({ email: t, phone: orderState.paymentPhone })
              );
            }}
            placeholder="Enter your email address"
            value={orderState.paymentEmail}
          />
          <InputField
            label="Your Phone"
            onChange={(t) => {
              dispatcher(
                setOrderDetails({ email: orderState.paymentEmail, phone: t })
              );
            }}
            placeholder="Enter your phone number"
            value={orderState.paymentPhone}
          />
        </div>
        <div className="w-full my-6">
          <Button
            onClick={() => onPay()}
            name="Pay"
            className="w-full"
            // eslint-disable-next-line react/style-prop-object
            style="primary"
            size="medium"
          />
        </div>
      </div>
      <MockPaymentDialog />
    </div>
  );
};

function OrderSummaryCard() {
  const recipient = useAppState(
    (s) => s.buyGiftCard.recipentSelection.selectedRecipent
  );
  const cardStatus = useAppState((s) => s.buyGiftCard.cardSelection);
  const formattedDeliveryDate = () => {
    const date = recipient.selectedDate
    const time = recipient.selectedTime.value
    return `${date}, ${time}`
  }

  return (
    <div className="w-full px-8 py-6 bg-white shadow-lg rounded-2xl">
      <h3 className="text-lg font-medium text-center text-gray-900">
        Order Summary
      </h3>

      <div className="flex justify-between">
        <p className="max-w-2xl mt-1 text-gray-500">
          {cardStatus.selectedCard?.name}
        </p>
        <p>${cardStatus.selectedCard?.price}</p>
      </div>
      <div className="flex flex-col my-4 space-y-1">
        <div className="flex w-full space-x-4">
          <p className="pr-4 text-gray-500">Your Name:</p>
          <p>{recipient.name}</p>
        </div>
        {
          recipient.type === "someone_else" &&
          <div className="flex w-full space-x-4">
            <p className="pr-4 text-gray-500">Recipient Name:</p>
            <p>{recipient.recipientName}</p>
          </div>
        }
        <div className="flex w-full space-x-4">
          <p className="pr-4 text-gray-500">Recipient Email:</p>
          <p>{recipient.recipientEmail}</p>
        </div>
        <div className="flex w-full space-x-4">
          {
            recipient.type === "someone_else" &&
            <>
              <p className="pr-4 text-gray-500">Message:</p>
              <p>{recipient.message ? recipient.message : "No message"}</p>
            </>
          }
        </div>
        <div className="flex w-full space-x-4">
          {recipient.deliveryType === "Custom Date" &&
            <>
              <p className="pr-4 text-gray-500">Date Time:</p>
              <p>{formattedDeliveryDate()}</p>
            </>
          }
        </div>
      </div>

      <div className="bg-gray-100 h-0.5 w-full mx-auto" />

      <div className="flex justify-between w-full py-3 space-x-4">
        <p className="pr-4 text-gray-500">Delivery Charges</p>
        <p>$0</p>
      </div>

      <div className="bg-gray-100 h-0.5 w-full mx-auto" />

      <div className="flex justify-between w-full space-x-4">
        <p className="pr-4 text-gray-500">Total</p>
        <p>${cardStatus.selectedCard?.price}</p>
      </div>
    </div>

  );
}