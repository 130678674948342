import { useEffect, useState } from 'react';

import { FiEdit } from 'react-icons/fi';

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/SaveAndCancelBtn';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { updateTutorUserDetailByUserId } from "redux/tutor/tutor.request";

import { getYouTubeVideoIdFromUrl } from "utils/generators.utils";


const VideoSelector = ({ editBtnConst, setEditBtnConst }) => {
    const { user } = useAppState((state) => state.user)
    const { tutorUserDetail } = useAppState((s) => s.tutor)

    const dispatcher = useAppDispatcher()

    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        setVideoUrl(`https://www.youtube.com/watch?v=${tutorUserDetail?.tutorUserDetail?.videoUrl}`)
    }, [tutorUserDetail?.tutorUserDetail?.videoUrl])

    const updateVideoSrc = () => {
        const youtubeVideoId = getYouTubeVideoIdFromUrl(videoUrl)
        dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { [editBtnConst]: youtubeVideoId }))
        setEditBtnConst(null)
    }

    return (
        <div className={"flex flex-col justify-start items-center gap-2"}>
            <div className="rounded-lg overflow-hidden w-[25rem] h-40">
                <iframe
                    width="100%"
                    height="100%"
                    src={`https://www.youtube.com/embed/${tutorUserDetail?.tutorUserDetail?.videoUrl}`}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                </iframe>
            </div>

            {editBtnConst !== profileEditBtn.videoUrl.value &&
                <div className="w-full flex justify-between items-center">
                    <span className='font-bodyPri font-normal text-base tracking-wide text-text-900'>
                        {videoUrl}
                    </span>
                    {!editBtnConst &&
                        <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                            onClick={() => setEditBtnConst(profileEditBtn.videoUrl.value)}
                        />
                    }
                </div>
            }
            {editBtnConst === profileEditBtn.videoUrl.value &&
                <div className="flex flex-col items-start justify-start gap-3 w-full">
                    <input
                        className={"w-full px-2 py-1 border-2 border-dashed border-secondary-dark focus:outline-none font-bodyPri font-normal text-base text-text-800"}
                        type="text"
                        name="videoInfo"
                        autoFocus={true}
                        value={videoUrl}
                        onChange={(event) => setVideoUrl(event.target.value)}
                    />
                    <SaveAndCancelBtn
                        onCancel={() => setEditBtnConst(null)}
                        onSave={updateVideoSrc}
                    />
                </div>
            }
        </div>
    )
}

export default VideoSelector