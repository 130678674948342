import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { IoIosArrowDown } from 'react-icons/io';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearUpdateEvent } from 'redux/event/event.slice';
import { setClearUpdateBooking } from 'redux/booking/booking.slice';
import { completeEventByEventId } from 'redux/event/event.request';
import { eventStatus, eventStatusActions, eventUserType } from 'redux/event/event.const';
import { courseType } from 'redux/course/course.const';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';


const EventDetailStatus = () => {
    const { eventDetail } = useAppState(s => s.event)
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()

    const [isStatusChangeable, setIsStatusChangeable] = useState(false)
    const [isEventStarted, setIsEventStarted] = useState(false)
    const [isEventEnded, setIsEventEnded] = useState(false)

    useEffect(() => {
        if (eventDetail?.eventDetail) {
            const eventStatusActionsObject = eventStatusActions[eventDetail?.eventDetail?.role?.toUpperCase()][eventDetail?.eventDetail?.course?.courseType.toUpperCase()][eventDetail?.eventDetail?.status?.toUpperCase()]
            setIsStatusChangeable(Object.values(eventStatusActionsObject).length > 1 ? true : false)
        }
    }, [eventDetail?.eventDetail])

    useEffect(() => {
        let interval
        if (eventDetail?.eventDetail) {
            const nowTimeStamp = parseInt(dayjs().tz(timeZone).valueOf() / 1000)
            const startTimeStamp = parseInt(dayjs(eventDetail?.eventDetail?.startDateTime).tz(timeZone).valueOf() / 1000)
            const endTimeStamp = parseInt(dayjs(eventDetail?.eventDetail?.endDateTime).tz(timeZone).valueOf() / 1000)

            const deltaTimeStampToStart = startTimeStamp - nowTimeStamp
            const deltaTimeStampToEnd = endTimeStamp - nowTimeStamp
            const deltaTimeStamp15MinBeforeStart = startTimeStamp - (60 * 15) - nowTimeStamp

            setIsEventStarted(deltaTimeStampToStart < 0)
            setIsEventEnded(deltaTimeStampToEnd < 0)

            interval = setInterval(() => {
                if (eventDetail?.eventDetail?.status === eventStatus.SCHEDULED.value && !isEventEnded) {
                    setIsStatusChangeable(deltaTimeStamp15MinBeforeStart > 0)
                }
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    })

    const eventLogStatusObject = eventDetail?.eventDetail?.eventLogs?.find(event => event?.status === eventDetail?.eventDetail?.status)

    const onHandleModal = () => {
        if (!isStatusChangeable) return;

        dispatcher(setClearUpdateEvent())
        dispatcher(setClearUpdateBooking())
        dispatcher(setModal({
            ...modal,
            [modalConst.EVENT_DETAIL_MODAL.stateKey]: false,
            [modalConst.EVENT_ACTION_MODAL.stateKey]: true,
        }))
    }

    const handleOnComplete = () => {
        dispatcher(completeEventByEventId(eventDetail?.eventDetail?.eventId, { note: '' }))
    }

    return eventDetail?.eventDetail?.status ? (
        <div className={"flex flex-col gap-3"}>
            <div className={"flex items-center justify-between gap-1"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-base text-text-800"}>
                        {"Status: "}
                    </span>
                    <div onClick={onHandleModal} className={clsx(
                        "flex items-center justify-center gap-1 border-2 rounded-lg px-2 py-0.5",
                        "font-bodyPrim font-medium text-base",
                        `border-${eventStatus[eventDetail?.eventDetail?.status?.toUpperCase()].darkColor}`,
                        `text-${eventStatus[eventDetail?.eventDetail?.status?.toUpperCase()].darkColor}`,
                        isStatusChangeable && "cursor-pointer",
                    )}>
                        <span className={""}>
                            {eventStatus[eventDetail?.eventDetail?.status?.toUpperCase()].label}
                        </span>
                        {isStatusChangeable &&
                            <IoIosArrowDown className={"text-xl"} />
                        }
                    </div>
                </div>

                <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                    {dayjs(eventLogStatusObject?.createdAt).tz(timeZone).fromNow()}
                </span>
            </div>

            {eventDetail?.eventDetail?.eventUserType === eventUserType.MODERATOR.value &&
                isEventEnded && eventDetail?.eventDetail?.status === eventStatus.ON_GOING.value &&
                <div className={""}>
                    <div onClick={handleOnComplete} className={clsx(
                        "flex items-center justify-center px-3 py-0.5",
                        "rounded-lg border-2 border-primary-dark",
                        "font-buttons font-medium text-base text-primary-dark",
                        "hover:text-text-50 hover:bg-primary-dark cursor-pointer"
                    )}>
                        <span>{"Make Complete"}</span>
                    </div>
                </div>
            }
        </div>
    ) : null
}

export default EventDetailStatus