import { userRoles } from "redux/auth/auth.const"
import bookingService from "redux/booking/booking.service"
import {
    setBookingListLoading,
    setBookingDetailLoading,
    setBookingList,

    setBookingDetail,
    setBookingListErrorMsg,
    setBookingDetailErrorMsg,

    setAddBookingLoading,
    setAddBooking,
    setAddBookingErrorMsg,

    setBookingConfirmationLoading,
    setBookingConfirmation,
    setBookingConfirmationErrorMsg,

    setUpdateBookingLoading,
    setUpdateBooking,
    setUpdateBookingErrorMsg,
} from "redux/booking/booking.slice";

import { getUserEventListByUserId } from "redux/event/event.request";
import { getMyProfile } from "redux/user/user.request";

export const getBookingList = (query) => async (dispatch, getState) => {
    dispatch(setBookingListLoading(true))
    const { locals } = getState().local
    const { user } = getState().user

    try {
        let response

        if (locals.userRole === userRoles.STUDENT.value) {
            const requestData = {
                params: { studentId: user?.user?.student?.studentId },
                query: { ...query }
            }
            response = await bookingService.getStudentBookingList(requestData)
        }
        if (locals.userRole === userRoles.TUTOR.value) {
            const requestData = {
                params: { tutorId: user.user.tutor.tutorId },
                query: { ...query }
            }
            response = await bookingService.getTutorBookingList(requestData)
        }
        if (response.status === 200) {
            dispatch(setBookingList(response.data))
        }
        else {
            dispatch(setBookingListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setBookingListErrorMsg(error.message))
    } finally {
        dispatch(setBookingListLoading(false))
    }
}

export const getBookingDetail = (bookingId) => async (dispatch) => {
    dispatch(setBookingDetailLoading(true))

    const requestData = {
        params: { bookingId: bookingId }
    }
    try {
        const response = await bookingService.getBookingDetail(requestData)
        if (response.status === 200) {
            dispatch(setBookingDetail(response.data))
        }
        else {
            dispatch(setBookingDetailErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setBookingDetailErrorMsg(error.message))
    } finally {
        dispatch(setBookingDetailLoading(false))
    }
}

export const makeAddBooking = () => async (dispatch, getState) => {
    dispatch(setAddBookingLoading(true))
    const { addBookingPayload } = getState()?.booking?.addBooking

    try {
        const requestData = {
            body: {
                courseId: addBookingPayload?.course?.courseId,
                coursePrice: addBookingPayload?.coursePrice,
                purchasedPrice: addBookingPayload?.purchasedPrice,
                coursePctDiscount: addBookingPayload?.coursePctDiscount,
                volumePctDiscount: addBookingPayload?.volumePctDiscount,
                tutorPctDiscount: addBookingPayload?.tutorPctDiscount,
                offerPctDiscount: addBookingPayload?.offerPctDiscount,
                sessionDuration: addBookingPayload?.sessionDuration,
                noOfSessions: addBookingPayload?.noOfSessions,
                confirm: addBookingPayload?.confirm,
                startDate: addBookingPayload?.startDate,
                endDate: addBookingPayload?.endDate,
                weeklyTimeSlot: addBookingPayload?.weeklyTimeSlot
            }
        }
        const response = await bookingService.addBooking(requestData)
        if (response.status === 200) {
            dispatch(setAddBooking(response.data))
            dispatch(getMyProfile())
        } else {
            dispatch(setAddBookingErrorMsg(response.data.message))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setAddBookingErrorMsg(error.response.data.message))
    } finally {
        dispatch(setAddBookingLoading(false))
    }
}

export const getBookingConfirmationByBookingId = (bookingId) => async (dispatch) => {
    dispatch(setBookingConfirmationLoading(true))

    const requestData = {
        params: { bookingId: bookingId }
    }
    try {
        const response = await bookingService.getBookingConfirmationByBookingId(requestData)
        if (response.status === 200) {
            dispatch(setBookingConfirmation(response.data))
        } else {
            dispatch(setBookingConfirmationErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setBookingConfirmationErrorMsg(error.message))
    } finally {
        dispatch(setBookingConfirmationLoading(false))
    }
}

export const cancelBookingByBookingId = (bookingId, body) => async (dispatch, getState) => {
    dispatch(setUpdateBookingLoading(true))

    const { user } = getState().user

    const requestData = {
        params: { bookingId: bookingId },
        body: body
    }
    try {
        const response = await bookingService.cancelBookingByBookingId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateBooking(response.data))
        }
        else {
            dispatch(setUpdateBookingErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateBookingErrorMsg(error.message))
    } finally {
        dispatch(setUpdateBookingLoading(false))
    }
}

export const cancelBookingByCourseId = (courseId, body) => async (dispatch, getState) => {
    dispatch(setUpdateBookingLoading(true))

    const { user } = getState().user

    const requestData = {
        params: { courseId: courseId },
        body: body
    }
    try {
        const response = await bookingService.cancelBookingByCourseId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateBooking(response.data))
        }
        else {
            dispatch(setUpdateBookingErrorMsg(response.data.message))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateBookingErrorMsg(error.response.data.message))
    } finally {
        dispatch(setUpdateBookingLoading(false))
    }
}