import { Link, useNavigate } from "react-router-dom"
import {
    PageContentStyle,
    Container,
    UnauthorizedCard,
    UnauthorizedCardHeader,
    UnauthorizedContentContainer,
    UnAuthorizedContent,
    Heading,
    SubHeading,
    HomeButtons,
    BackButton,
    HomePage
} from "pages/global/unauthorized/UnauthorizedPageStyle";

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const UnauthorizedPage = ({ errorMsg = "Unauthorized Access" }) => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <PageContentStyle>
            <Container>
                <UnauthorizedCard>
                    <UnauthorizedCardHeader>
                        <ReportProblemOutlinedIcon className={"text-yellow-400 drop-shadow-lg rounded-full"} sx={{ fontSize: 60 }}/>
                        <UnauthorizedContentContainer>
                        <UnAuthorizedContent>
                                <Heading>
                                    {errorMsg}
                                </Heading>
                                <SubHeading>
                                    {"Your Account does not have access to this page. Please switch or login your account"}
                                </SubHeading>
                        </UnAuthorizedContent>
                        <HomeButtons>
                            <BackButton onClick={goBack}>
                                {"Go Back"}
                            </BackButton>
                            <HomePage>
                                <Link to={"/"}>{"Visit Our Homepage"}</Link>
                            </HomePage>
                        </HomeButtons>
                        </UnauthorizedContentContainer>
                    </UnauthorizedCardHeader>
                </UnauthorizedCard>
            </Container>
        </PageContentStyle>
    )
}

export default UnauthorizedPage
