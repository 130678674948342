import {
    FaBookReader,
    FaCalendar,
    FaCertificate,
    FaGifts,
    FaGoogle,
    FaHockeyPuck,
    FaLevelUpAlt,
    FaChalkboardTeacher,
} from 'react-icons/fa';

import { MdOutlineAddBusiness, MdOutlineCastForEducation, MdReduceCapacity } from 'react-icons/md';

import { userRoles } from 'redux/auth/auth.const';

export const pageHeading = {
    heading: "My Tools",
    subHeading: "Quickly navigate to your frequently used tools!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/tools.svg"
}

export const popularTools = [
    {
        icon: <FaHockeyPuck className="text-primary-main" size={40} />,
        label: 'Create a live class',
        to: "/create-class",
        toolTip: "Create a live class and share it with your students",
        roles: [userRoles.TUTOR.value],
    },
    {
        icon: <FaCertificate className="text-primary-main" size={40} />,
        label: 'Tutor Onboarding',
        to: "/tutor-onboard",
        toolTip: "Get certified by completing a class",
        roles: [userRoles.TUTOR.value],
    },
    {
        icon: <FaCalendar className="text-primary-main" size={40} />,
        label: 'Weekly Schedule',
        to: "/preferences/availability",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaCalendar className="text-primary-main" size={40} />,
        label: 'Refer & Earn',
        to: "/referral",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    // {
    //     icon: <FaGifts className="text-primary-main" size={40} />,
    //     label: 'Send a gift',
    //     to: "/buy-gift-card",
    //     toolTip: "Check and update your weekly schedule",
    //     roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    // },
    {
        icon: <FaLevelUpAlt className="text-primary-main" size={40} />,
        label: 'Upgrade Plan',
        to: "/tools",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaGoogle className="text-primary-main" size={40} />,
        label: 'Google Calendar',
        to: "/settings/calender",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaBookReader className="text-primary-main" size={40} />,
        label: 'Bookings',
        to: "/bookings",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <FaChalkboardTeacher className="text-primary-main" size={40} />,
        label: 'Apply to Teach',
        to: "/teach-with-us",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.STUDENT.value],
    },
]

export const companyTools = [
    {
        icon: <MdOutlineAddBusiness className="text-primary-main" size={40} />,
        label: 'Edulyte Page',
        to: "/company/edulyte",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <MdOutlineCastForEducation className="text-primary-main" size={40} />,
        label: 'CFI Education Page',
        to: "/company/cfieducation",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <MdReduceCapacity className="text-primary-main" size={40} />,
        label: 'pepp Page',
        to: "/company/pepp",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
    {
        icon: <MdOutlineAddBusiness className="text-primary-main" size={40} />,
        label: 'Edulyte Edit Page',
        to: "/edit-company/edulyte",
        toolTip: "Check and update your weekly schedule",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
    },
]

export const sidebarTools = [
    {
        label: "Add 1 to 1 Session",
        to: "/add-1-to-1-session",
        roles: [userRoles.TUTOR.value],
        path: '/create-class'
    },
    {
        label: "Add Group Session",
        to: "/add-group-session",
        roles: [userRoles.TUTOR.value],
        path: '/create-class'
    },
    {
        label: "Redeem Gift Card",
        to: "/gift-card",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        path: '/wallet'
    },
    {
        label: "Your Todo List",
        to: "/to-do",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        path: '/calendar/#todoSection'
    },
    {
        label: "Offers and discount",
        to: "/offers-discounts",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value],
        path: '/offers'
    },
    // {
    //     label: "Withdraw",
    //     to: "/withdraw",
    //     roles: [userRoles.TUTOR.value],
    //     path: '/wallet'
    // },
    // {
    //     label: "Attend free class",
    //     to: "/free-class",
    //     roles: [userRoles.STUDENT.value],
    //     path: '/'
    // },
]