import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { JaaSMeeting } from "@jitsi/react-sdk";

import { XCircleIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/outline";

import {
    StyledJitsiMeetComponent,
    StyledCloseButton,
    StyledJitsiRenderSpinner
} from "pages/auth/meetingRoom/MeetingRoomPage.style";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getMeetingByMeetingId } from 'redux/meeting/meeting.request';
import { setClearMeeting } from 'redux/meeting/meeting.slice';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';


const MeetingRoomRenderSpinner = () => {
    return (
        <StyledJitsiRenderSpinner>
            <RefreshIcon className="h-6 w- animate-spin text-white" />
        </StyledJitsiRenderSpinner>
    );
}


const MeetingRoomPage = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)
    const { meeting } = useAppState((s) => s.meeting)

    const dispatcher = useAppDispatcher()
    const params = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const apiRef = useRef();

    const from = location.state?.from?.pathname || pagesInfo.MEETING.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MEETING_ROOM))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        if (!params.appId || !params.meetingId) {
            navigate(from, { replace: true })
        } else {
            dispatcher(getMeetingByMeetingId(params.meetingId))
        }

        return () => {
            dispatcher(setClearMeeting())
        }
    }, [params.appId, params.meetingId])

    useEffect(() => {
        if (meeting?.errorMsg) {
            navigate(from, { replace: true })
        }
    }, [meeting?.errorMsg])

    const handleChatUpdates = (payload, ref) => {
        if (payload.isOpen || !payload.unreadCount) {
            return;
        }
        ref.current.executeCommand("toggleChat");
    };

    const handleJitsiIFrameRef1 = (iframeRef) => {
        iframeRef.style.border = "1px solid cadetblue";
        iframeRef.style.background = "cadetblue";
        iframeRef.style.height = "100vh";
        iframeRef.style.width = "100vw";
    };

    const handleApiReady = (apiObj, ref) => {
        ref.current = apiObj;
        ref.current.addEventListeners({
            // Listening to events from the external API
            chatUpdated: (payload) => handleChatUpdates(payload, ref),
        });
    };

    const closeMeetingComponent = () => {
        navigate(pagesInfo.MEETING.pagePath, { replace: true })
    }

    return (
        <StyledJitsiMeetComponent>
            {/* {!meeting?.isLoading && meeting?.meeting && (
                <StyledCloseButton onClick={closeMeetingComponent}>
                    <XCircleIcon className="h-10 w-10 text-white" />
                </StyledCloseButton>
            )} */}
            {!meeting?.meeting && meeting?.isLoading && <MeetingRoomRenderSpinner />}
            {!meeting?.isLoading && meeting?.meeting && (
                <JaaSMeeting
                    appId={params.appId}
                    roomName={params?.meetingId}
                    jwt={meeting?.meeting?.jaasJwtToken}
                    configOverwrite={{
                        disableThirdPartyRequests: true,
                        disableLocalVideoFlip: false,
                        backgroundAlpha: 0.5,
                    }}
                    interfaceConfigOverwrite={{
                        VIDEO_LAYOUT_FIT: "nocrop",
                        MOBILE_APP_PROMO: false,
                        TILE_VIEW_MAX_COLUMNS: 4,
                    }}
                    spinner={MeetingRoomRenderSpinner}
                    onApiReady={(externalApi) => handleApiReady(externalApi, apiRef)}
                    getIFrameRef={handleJitsiIFrameRef1}
                    onReadyToClose={closeMeetingComponent}
                />
            )}
        </StyledJitsiMeetComponent>
    )
}

export default MeetingRoomPage