import {
    LearningItemContainer,
    ColorGradient,
    LearningItemContent,
    Title,
    Description,
    Button,
} from './LearningNeverStopsStyle';

const LearningItem = ({ learningItem, className }) => {
    return (
        <LearningItemContainer className={className}>
            <img src={learningItem.bgImage} alt={learningItem.title} className={"w-full h-full object-cover"} />
            <ColorGradient />
            <LearningItemContent>
                <Title>{learningItem.title}</Title>
                <Description>
                    <Description>{learningItem.description1}</Description>
                    <Description>{learningItem.description2}</Description>
                </Description>
                <Button to={learningItem.path}>{learningItem.buttonText}</Button>
            </LearningItemContent>
        </LearningItemContainer>
    )
}

export default LearningItem;
