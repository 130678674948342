import { createSlice } from "@reduxjs/toolkit";
import { USER_INITIAL_STATE } from "redux/user/user.initialState";


const user = createSlice({
    name: "user",
    initialState: USER_INITIAL_STATE,
    reducers: {
        // user
        setUserLoading: (state, { payload }) => {
            state.user.isLoading = payload
        },
        setUser: (state, { payload }) => {
            state.user.user = payload
        },
        setUserErrorMsg: (state, { payload }) => {
            state.user.errorMsg = payload
        },
        setClearUser: (state) => {
            state.user = USER_INITIAL_STATE.user
        },

        // reducers for user detail
        setUserDetailLoading: (state, { payload }) => {
            state.userDetail.isLoading = payload
        },
        setUserDetail: (state, { payload }) => {
            state.userDetail.userDetail = payload
            state.userDetail.errorMsg = payload
        },
        setUserDetailErrorMsg: (state, { payload }) => {
            state.userDetail.errorMsg = payload
            state.userDetail.userDetail = USER_INITIAL_STATE.userDetail.userDetail
        },
        setClearUserDetail: (state) => {
            state.userDetail = USER_INITIAL_STATE.userDetail
        },

        // reducers for add user detail
        setAddUserDetailLoading: (state, { payload }) => {
            state.addUserDetail.isLoading = payload
        },
        setAddUserDetailErrorMsg: (state, { payload }) => {
            state.addUserDetail.errorMsg = payload
        },
        setClearAddUserDetail: (state) => {
            state.addUserDetail = USER_INITIAL_STATE.addUserDetail
        },

        // reducers for culture detail
        setUserCultureDetailLoading: (state, { payload }) => {
            state.userCultureDetail.isLoading = payload
        },
        setUserCultureDetail: (state, { payload }) => {
            state.userCultureDetail.userCultureDetail = payload
            state.userCultureDetail.errorMsg = USER_INITIAL_STATE.userCultureDetail.errorMsg
        },
        setUserCultureDetailErrorMsg: (state, { payload }) => {
            state.userCultureDetail.errorMsg = payload
            state.userCultureDetail.userCultureDetail = USER_INITIAL_STATE.userCultureDetail.userCultureDetail
        },
        setClearUserCultureDetail: (state) => {
            state.userCultureDetail = USER_INITIAL_STATE.userCultureDetail
        },

        // reducers for add user culture
        setAddUserCultureDetailLoading: (state, { payload }) => {
            state.addUserCultureDetail.isLoading = payload
        },
        setAddUserCultureDetailErrorMsg: (state, { payload }) => {
            state.addUserCultureDetail.errorMsg = payload
        },
        setClearAddUserCulture: (state) => {
            state.addUserCultureDetail = USER_INITIAL_STATE.addUserCultureDetail
        },

        // reducers for user education
        setUserEducationListLoading: (state, { payload }) => {
            state.userEducationList.isLoading = payload
        },
        setUserEducationList: (state, { payload }) => {
            state.userEducationList.userEducationList = payload
            state.userEducationList.errorMsg = USER_INITIAL_STATE.userEducationList.errorMsg
        },
        setUserEducationListErrorMsg: (state, { payload }) => {
            state.userEducationList.errorMsg = payload
            state.userEducationList.userEducationList = USER_INITIAL_STATE.userEducationList.userEducationList
        },
        setClearUserEducationList: (state) => {
            state.userEducationList = USER_INITIAL_STATE.userEducationList
        },

        // reducers for update user education detail
        setAddUserEducationDetailLoading: (state, {payload}) => {
            state.addUserEducationDetail.isLoading = payload
        },
        seAddUserEducationDetailErrorMsg: (state, {payload}) => {
            state.addUserEducationDetail.errorMsg = payload
        },
        setClearAddUserEducationDetail: (state) => {
            state.addUserEducationDetail = USER_INITIAL_STATE.addUserEducationDetail
        },

        // reducers for user other pref
        setUserOtherPrefLoading: (state, { payload }) => {
            state.userOtherPref.isLoading = payload
        },
        setUserOtherPref: (state, { payload }) => {
            state.userOtherPref.userOtherPref = payload
            state.userOtherPref.errorMsg = USER_INITIAL_STATE.userOtherPref.errorMsg
        },
        setUserOtherPrefErrorMsg: (state, { payload }) => {
            state.userOtherPref.errorMsg = payload
            state.userOtherPref.userOtherPref = USER_INITIAL_STATE.userOtherPref.userOtherPref
        },
        setClearUserOtherPref: (state) => {
            state.userOtherPref = USER_INITIAL_STATE.userOtherPref
        },

        // reducers for add user other pref
        setAddUserOtherPrefLoading: (state, {payload}) => {
            state.addUserOtherPref.isLoading = payload
        },
        setAddUserOtherPrefErrorMsg: (state, {payload}) => {
            state.addUserOtherPref.errorMsg = payload
        },
        setClearAddUserOtherPref: (state) => {
            state.addUserOtherPref = USER_INITIAL_STATE.updateUserOtherPref
        },

        // reducers for social media presence
        setUserSocialMediaLinksLoading: (state, {payload}) => {
            state.userSocialMediaLinks.isLoading = payload
        },
        setUserSocialMediaLinks: (state, {payload}) => {
            state.userSocialMediaLinks.userSocialMediaLinks = payload
            state.userSocialMediaLinks.errorMsg = USER_INITIAL_STATE.userSocialMediaLinks.errorMsg
        },
        setUserSocialMediaLinksErrorMsg: (state, {payload}) => {
            state.userSocialMediaLinks.errorMsg = payload
            state.userSocialMediaLinks.userSocialMediaLinks = USER_INITIAL_STATE.userSocialMediaLinks.userSocialMediaLinks
        },
        setClearUserSocialMediaLinks: (state, {payload}) => {
            state.userSocialMediaLinks = USER_INITIAL_STATE.userSocialMediaLinks
        },

        // reducers for updating social media Links
        setAddUserSocialMediaLinksLoading: (state, {payload}) => {
            state.addUserSocialMediaLinks.isLoading = payload
        },
        setAddUserSocialMediaLinksErrorMsg: (state, {payload}) => {
            state.addUserSocialMediaLinks.errorMsg = payload
        },
        setClearAddUserSocialMediaLinks: (state) => {
            state.addUserSocialMediaLinks = USER_INITIAL_STATE.addUserSocialMediaLinks
        },

        // reducers for user general setting details payload
        setUserGeneralDetailPayload: (state, {payload}) => {
            state.userGeneralDetailPayload.userGeneralDetailPayload = payload
        },
        setClearUserGeneralDetailPayload: (state) => {
            state.userGeneralDetailPayload = USER_INITIAL_STATE.userGeneralDetailPayload
        }
    }
})

export const {
    setUserLoading,
    setUser,
    setUserErrorMsg,
    setClearUser,

    setUserCultureDetailLoading,
    setUserCultureDetail,
    setUserCultureDetailErrorMsg,
    setClearUserCultureDetail,

    setAddUserCultureDetailLoading,
    setAddUserCultureDetailErrorMsg,
    setClearAddUserCulture,

    setUserDetailLoading,
    setUserDetail,
    setUserDetailErrorMsg,
    setClearUserDetail,

    setAddUserDetailLoading,
    setAddUserDetailErrorMsg,
    setClearAddUserDetail,

    setUserEducationListLoading,
    setUserEducationList,
    setUserEducationListErrorMsg,
    setClearUserEducationList,

    setAddUserEducationDetailLoading,
    seAddUserEducationDetailErrorMsg,
    setClearUpdateUserEducationDetail,

    setUserOtherPrefLoading,
    setUserOtherPref,
    setUserOtherPrefErrorMsg,
    setClearUserOtherPref,

    setAddUserOtherPrefLoading,
    setAddUserOtherPrefErrorMsg,
    setClearAddUserOtherPref,

    setUserSocialMediaLinksLoading,
    setUserSocialMediaLinks,
    setUserSocialMediaLinksErrorMsg,
    setClearUserSocialMediaLinks,

    setAddUserSocialMediaLinksLoading,
    setAddUserSocialMediaLinksErrorMsg,
    setClearAddUserSocialMediaLinks,

    setUserGeneralDetailPayload,
    setClearUserGeneralDetailPayload
} = user.actions

export default user.reducer