import React from "react";

//Helper functions
import updateNotifications from "../../helpers/updateNotifications";

//Component imports
import NotificationListItem from "../NotificationListItem/NotificationListItem";
import GroupNotificationListItem from "../GroupNotificationListItem/GroupNotificationListItem";

//Style imports
import {
  StyledNotificationList,
  StyledNotificationListHeader,
} from "./NotificationList.styles";

export default function NotificationList({
  notifications,
  groupNotifications,
  setShowList,
  user,
}) {
  const combinedNotifications = [...notifications, ...groupNotifications];

  async function closeNotificationList() {
    await updateNotifications(combinedNotifications, user);
    setShowList(false);
  }

  return (
    <StyledNotificationList>
      <StyledNotificationListHeader>Notifications</StyledNotificationListHeader>
      {combinedNotifications.map((notification) => {
        return (
          <div onClick={closeNotificationList} key={notification.id}>
            {notification.chatType === "individual" && (
              <NotificationListItem notification={notification} />
            )}
            {notification.chatType === "group" && (
              <GroupNotificationListItem notification={notification} />
            )}
          </div>
        );
      })}
    </StyledNotificationList>
  );
}
