import { courseType } from "redux/course/course.const"

export const url = {
    GET_USER_EVENT_LIST_BY_USER_ID: "/events/list/user/{userId}",
    GET_EVENT_DETAIL_BY_EVENT_ID: "/events/{eventId}",
    ACCEPT_EVENT_BY_EVENT_ID: "/events/{eventId}/accept",
    DECLINE_EVENT_BY_EVENT_ID: "/events/{eventId}/decline",
    RESCHEDULE_EVENT_BY_EVENT_ID: "/events/{eventId}/reschedule",
    START_EVENT_BY_EVENT_ID: "/events/{eventId}/start",
    COMPLETE_EVENT_BY_EVENT_ID: "/events/{eventId}/complete",
}

export const filterName = {
    SCHEDULE: {
        value: "event"
    }
}

export const eventStatus = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    NOT_MAPPED: {
        label: "Not Mapped",
        value: "not_mapped",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    DECLINED: {
        label: "Declined",
        value: "declined",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const eventType = {
    COURSE: {
        label: "Course",
        value: "course"
    },
    INTERVIEW: {
        label: "Interview",
        value: "interview"
    },
    PREMIUM: {
        label: "Premium",
        value: "premium"
    }
}

export const eventUserType = {
    PARTICIPANT: {
        label: "Participant",
        value: "participant"
    },
    MODERATOR: {
        label: "Moderator",
        value: "moderator"
    }
}

export const eventStatusActions = {
    STUDENT: {
        ONE_ON_ONE: {
            PENDING: {
                PENDING: {
                    label: 'Pending',
                    value: 'pending'
                },
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            DECLINED: {
                DECLINED: {
                    label: 'Declined',
                    value: 'declined'
                },
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            RESCHEDULED: {
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            SCHEDULED: {
                SCHEDULED: {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            ON_GOING: {
                ON_GOING: {
                    label: 'On Going',
                    value: 'on_going'
                }
            },
            COMPLETED: {
                COMPLETED: {
                    label: 'Completed',
                    value: 'completed'
                }
            },
            CANCELLED: {
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                }
            },
        },
        GROUP: {
            PENDING: {
                PENDING: {
                    label: 'Pending',
                    value: 'pending'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            DECLINED: {
                DECLINED: {
                    label: 'Declined',
                    value: 'declined'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            RESCHEDULED: {
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            SCHEDULED: {
                SCHEDULED: {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            ON_GOING: {
                ON_GOING: {
                    label: 'On Going',
                    value: 'on_going'
                }
            },
            COMPLETED: {
                COMPLETED: {
                    label: 'Completed',
                    value: 'completed'
                }
            },
            CANCELLED: {
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                }
            },
        },
    },
    TUTOR: {
        ONE_ON_ONE: {
            PENDING: {
                PENDING: {
                    label: 'Pending',
                    value: 'pending'
                },
                SCHEDULED: {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                DECLINED: {
                    label: 'Declined',
                    value: 'declined'
                }
            },
            DECLINED: {
                DECLINED: {
                    label: 'Declined',
                    value: 'declined'
                }
            },
            RESCHEDULED: {
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            SCHEDULED: {
                SCHEDULED: {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                }
            },
            ON_GOING: {
                ON_GOING: {
                    label: 'On Going',
                    value: 'on_going'
                }
            },
            COMPLETED: {
                COMPLETED: {
                    label: 'Completed',
                    value: 'completed'
                }
            },
            CANCELLED: {
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                }
            },
        },
        GROUP: {
            PENDING: {
                PENDING: {
                    label: 'Pending',
                    value: 'pending'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            DECLINED: {
                DECLINED: {
                    label: 'Declined',
                    value: 'declined'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            RESCHEDULED: {
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            SCHEDULED: {
                SCHEDULED: {
                    label: 'Scheduled',
                    value: 'scheduled'
                },
                RESCHEDULED: {
                    label: 'Rescheduled',
                    value: 'rescheduled'
                },
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                },
            },
            ON_GOING: {
                ON_GOING: {
                    label: 'On Going',
                    value: 'on_going'
                }
            },
            CANCELLED: {
                CANCELLED: {
                    label: 'Cancelled',
                    value: 'cancelled'
                }
            },
            COMPLETED: {
                COMPLETED: {
                    label: 'Completed',
                    value: 'completed'
                }
            },
        },
    }
}

export const eventFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    courseType: courseType.ALL.value,
    eventStatus: eventStatus.ALL.value
}