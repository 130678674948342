import { useEffect, useState } from "react";
import clsx from "clsx";

import PeopleCard from "pages/global/company/components/people/PeopleCard";

import { getEmployeeListByCompanyId } from "redux/company/company.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearEmployeeList } from "redux/company/company.slice";

const itemsPerPage = 4

const People = () => {
    const {companyPublicProfile, employeeList } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.companyId) {
            dispatcher(getEmployeeListByCompanyId({ activePage: activePage, itemsPerPage: itemsPerPage }, companyPublicProfile?.companyPublicProfile?.companyId))
        }

        return () => {
            dispatcher(setClearEmployeeList())
        }
    }, [activePage, companyPublicProfile?.companyPublicProfile?.companyId])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"PEOPLE"}>
            <div className={"relative max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"People"}
                    </span>
                    <div className={clsx(
                        "w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-x-5 gap-y-8 p-5"
                    )}>
                        {employeeList?.employeeList?.list?.map((employee, index) => (
                            <div className={"col-span-1 flex items-center justify-center"}>
                                <PeopleCard
                                    key={index}
                                    employee={employee}
                                />
                            </div>
                        ))}
                    </div>
                    {employeeList?.employeeList?.totalPages !== activePage &&
                        <div
                            className={clsx(
                                "w-full flex justify-center items-center border border-text-500 p-2 rounded",
                                "font-bodyPri font-medium text-text-800 text-base cursor-pointer",
                                "hover:bg-background-medium"
                            )}
                            onClick={() => setActivePage(activePage + 1)}
                        >
                            {"Show more"}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default People;