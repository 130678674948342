import { userRoles } from 'redux/auth/auth.const';
import { dayjs } from 'utils/dateTime.utils';

export const pageHeading = {
    heading: "Preferences",
    subHeading: "Manage your time, topics, pricing etc.",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/preferences.svg"
}

export const navigationOptions = [
    { label: "Availability", to: "/availability", roles: [userRoles.STUDENT.value, userRoles.TUTOR.value] },
    { label: "Subject/Topic", to: "/subjects", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Lesson", to: "/lesson", roles: [userRoles.TUTOR.value] },
    { label: "Price", to: "/price", roles: [userRoles.TUTOR.value] },
    { label: "Other", to: "/other", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
];

const lessonPrefConst = {
    autoConfirmLesson: {
        label: "Automatically Confirm Lessons",
        description: "Using this option would confirm all lesson request automatically."
    },
    instantBookNewStudent: {
        label: "Accept Lesson Request from current student",
        description: "If green, we will confirm all new requests/ bookings automatically from any student you met at least once."
    },
    instantBookCurrentStudent: {
        label: "Accept Lesson Request from new student",
        description: "If Green, we will confirm all new requests/ bookings automatically from new students."
    },
    allowNewStudent: {
        label: "Instant Booking From Current Student",
        description: "If Green, your current students can book start a meeting with you after making payment.You must be online and not in a meeting."
    },
    allowCurrentStudent: {
        label: "Instant Booking From New Student",
        description: "If Green, your new students can book start a meeting with you after making payment. You must be online and not in a meeting."
    }
}

export const setGetLessonPref = (lessonPref) => (
    Object.keys(lessonPref)?.map((lesson) => ({
        key: lesson,
        label: lessonPrefConst[lesson]?.label,
        description: lessonPrefConst[lesson]?.description,
        value: lessonPref[lesson]
    }))
)

const otherPrefConst = {
    contentCreation: {
        label: "Count me in for content creation work",
        description: "You will create your own content."
    },
    paidInfluencer: {
        label: "Count me in for paid influencer work",
        description: "We will get in touch for any influencer work."
    },
    personalisedReco: {
        label: "Personal recommendation",
        description: "Allow personal recommendation."
    }
}

export const setGetOtherPref = (otherPref) => (
    Object.keys(otherPref).map((other) => ({
        key: other,
        label: otherPrefConst[other].label,
        description: otherPrefConst[other].description,
        value: otherPref[other]
    }))
)

export const pricingPrefDurationConst = [
    {
        label: "30 min",
        value: 30,
    },
    {
        label: "60 min",
        value: 60,
    },
    {
        label: "90 min",
        value: 90,
    },
    {
        label: "120 min",
        value: 120,
    }
]
export const pricingPrefDiscountConst = [
    {
        label: "5%",
        value: 5
    },
    {
        label: "10%",
        value: 10
    },
    {
        label: "15%",
        value: 15
    },
    {
        label: "20%",
        value: 20
    }
]

export const setGetOffDays = (offDays) => (
    offDays.map((offDay) => ({
        date: offDay.date,
        message: offDay.name,
        schedule: offDay.timeSlots
    }))
)

export const validateTimeRange = (st, et, nt) => {
    const startTime = dayjs(st, "hh:m A");
    const endTime = dayjs(et, "hh:m A");
    const newTime = dayjs(nt, "hh:m A");

    return (
        newTime.isBefore(startTime) ||
        newTime.isAfter(endTime) ||
        newTime.isSame(endTime)
    );
}

export const weeklyScheduleToolTip = "Add or update your general weekly schedule for seven days by the hour.Use plus sign to add hours for any given day.Click on pencil icon to modify time table/hours on any day.Click on red cross icon to delete hours.Please don't forget to save after making changes."

export const subjectPreferenceToolTip = "Choose topic and level of students you would like to teach.Use plus sign to add a new topic and student level."

export const pricingPreferenceToolTip = "Set default price for your trial classes, One-on-One and group classes.Use your judgment and not set your price very low or very high.Offer discounts to students who book One-on-One sessions in a package. They can book sessions depending on your available time slots in your calendar."

export const lessonPreferenceToolTip = "This is used to automate actions for yourself.Toggle button to switch on the automate action."

export const otherPreferenceToolTip = "We will contact you if you opt for some extra work, subject to available opportunities."