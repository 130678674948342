import clsx from "clsx";

import { OptionSelector } from "components/common-components/Select";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps } from "redux/event/event.slice";
import { filterName, eventStatus } from "redux/event/event.const";
import { courseType } from "redux/course/course.const";


const BlueButton = ({ name, onClick = () => { }, active = false }) => (
  <button onClick={onClick} className={clsx(
    "px-5 py-[2px] whitespace-nowrap  rounded-xl ",
    "hover:bg-primary-main hover:text-white",
    active ? "bg-primary-main text-white" : "bg-primary-light text-primary-main"
  )}>
    {name}
  </button>
);


const ScheduleFilter = () => {
  const { userEventList, filterProps } = useAppState(s => s.event);

  const dispatcher = useAppDispatcher();

  return (
    <div className="p-5 bg-white rounded-lg">
      <div className={"flex flex-col justify-center sm:flex-row sm:justify-between items-center"}>
        <div className="flex flex-col items-center w-full gap-4 sm:space-x-4 sm:flex-row sm:justify-between">
          <div className="flex items-center justify-start space-x-4 w-min">
            <div>
              <BlueButton
                name={courseType.ALL.label}
                onClick={() => dispatcher(setFilterProps({
                  filterProps: { ...filterProps, courseType: courseType.ALL.value },
                  list: userEventList?.userEventList,
                  filterName: filterName.SCHEDULE.value
                }))
                }
                active={filterProps.courseType === courseType.ALL.value}
              />
            </div>
            <div>
              <BlueButton
                name={courseType.ONE_ON_ONE.label}
                onClick={() => dispatcher(setFilterProps({
                  filterProps: { ...filterProps, courseType: courseType.ONE_ON_ONE.value },
                  list: userEventList?.userEventList,
                  filterName: filterName.SCHEDULE.value
                }))}
                active={filterProps.courseType === courseType.ONE_ON_ONE.value}
              />
            </div>
            <div>
              <BlueButton
                name={courseType.GROUP.label}
                onClick={() => dispatcher(setFilterProps({
                  filterProps: { ...filterProps, courseType: courseType.GROUP.value },
                  list: userEventList?.userEventList,
                  filterName: filterName.SCHEDULE.value
                }))}
                active={filterProps.courseType === courseType.GROUP.value}
              />
            </div>
          </div>
          <div className="my-2 w-72">
            <OptionSelector
              options={Object.values(eventStatus)}
              onChange={(v) => dispatcher(setFilterProps({
                filterProps: { ...filterProps, eventStatus: v.value },
                list: userEventList?.userEventList,
                filterName: filterName.SCHEDULE.value
              }))}
              value={filterProps?.eventStatus}
              className="text-base"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleFilter;