// Public Page
import BuyGifCardPage from "pages/global/buyGiftCard";
import CompanyPage from "pages/global/company/index";
import CoursePage from "pages/global/course/CoursePage";
import FindCoursesPage from "pages/global/findCourses/FindCoursesPage";
import FindTutorsPage from 'pages/global/findTutors/FindTutorsPage';
import HomePage from 'pages/global/home/HomePage';
import LogInPage from "pages/global/logIn/LogInPage";
import PageNotFoundPage from "pages/global/pageNotFound/PageNotFoundPage";
import ReferralSignUpPage from "pages/global/referralSignUp";
import SegmentsPage from "pages/global/segments";
import SignUpPage from "pages/global/signUp/SignUpPage";
import TeachWithUsPage from "pages/global/teachWithUs/TeachWithUsPage";
import ThankYouPage from "pages/global/thankYou/ThankYouPage";
import TutorPage from "pages/global/tutor/TutorPage";
import TutorCourse from "pages/global/tutorCourse/TutorCourse";
import UnauthorizedPage from "pages/global/unauthorized/UnauthorizedPage";

// Private Page
import BookingsPage from "pages/auth/bookings";
import BookingConfirmationPage from "pages/auth/bookingConfirmation/BookingConfirmationPage";
import CreateCoursePage from "pages/auth/createCourse/CreateCoursePage";
import DashboardPage from "pages/auth/dashboard/DashboardPage";
import EditCoursePage from "pages/auth/editCourse";
import MeetingPage from "pages/auth/meeting/MeetingPage";
import MeetingRoomPage from "pages/auth/meetingRoom/MeetingRoomPage";
import MessagePage from "pages/auth/message";
import MyStudentsPage from "pages/auth/myStudents";
import MyCoursesPage from "pages/auth/myCourses";
import OfferingsPage from "pages/auth/offerings/OfferingsPage";

import OffersPage from "pages/auth/offersAndDiscount";

import PreferancesPage from "pages/auth/preferences";
import ProfilePage from 'pages/auth/profile';
import ReferralPage from "pages/auth/referral/ReferralPage";
import SettingsPage from "pages/auth/settings";
import SchedulePage from "pages/auth/schedule/MySchedulePage";
import Subscriptions from "pages/auth/subscriptions";
import SupportPage from "pages/auth/support";
import MyTutorsPage from "pages/auth/myTutors";
import ToolsPage from "pages/auth/tools";
import TutorsOnboardPage from "pages/auth/tutorOnboard/TutorOnboardPage";
import WalletPage from "pages/auth/wallet/WalletPage";
import WalletConfirmationPage from "pages/auth/walletConfirmation/WalletConfirmationPage";

import { userRoles } from 'redux/auth/auth.const';
import EditCompanyPage from "pages/auth/editCompany";


export const pagesInfo = {
    // Public pages
    BUY_GIFT_CARD: {
        pageName: 'BUY_GIFT_CARD_PAGE',
        pagePath: '/buy-gift-card',
        routePath: 'buy-gift-card',
        component: BuyGifCardPage,
        roles: [],
    },
    COMPANY: {
        pageName: 'COMPANY',
        pagePath: '/company',
        routePath: '/company/:companyName',
        component: CompanyPage,
        roles: []
    },
    COURSE: {
        pageName: 'COURSE_PAGE',
        pagePath: '/class',
        routePath: 'class/:courseId',
        component: CoursePage,
        roles: [],
    },
    FIND_COURSES: {
        pageName: 'FIND_COURSES_PAGE',
        pagePath: '/find-classes',
        routePath: 'find-classes',
        component: FindCoursesPage,
        roles: [],
    },
    FIND_TUTORS: {
        pageName: 'FIND_TUTORS_PAGE',
        pagePath: '/find-tutors',
        routePath: 'find-tutors',
        component: FindTutorsPage,
        roles: [],
    },
    HOME: {
        pageName: 'HOME_PAGE',
        pagePath: '/',
        routePath: '/',
        component: HomePage,
        roles: [],
    },
    LOG_IN: {
        pageName: 'LOG_IN_PAGE',
        pagePath: '/log-in',
        routePath: 'log-in',
        component: LogInPage,
        roles: [],
    },
    PAGE_NOT_FOUND: {
        pageName: 'PAGE_NOT_FOUND_PAGE',
        pagePath: '/page-not-found',
        routePath: 'page-not-found',
        component: PageNotFoundPage,
        roles: [],
    },
    REFERRAL_SIGN_UP: {
        pageName: 'REFERRAL_SIGN_UP_PAGE',
        pagePath: '/referral',
        routePath: 'referral/:referralCode',
        component: ReferralSignUpPage,
        roles: [],
    },
    SEGMENTS: {
        pageName: 'SEGMENTS_PAGE',
        pagePath: '/skills',
        routePath: 'skills',
        component: SegmentsPage,
        roles: [],
    },
    SIGN_UP: {
        pageName: 'SIGN_UP_PAGE',
        pagePath: '/sign-up',
        routePath: 'sign-up',
        component: SignUpPage,
        roles: [],
    },
    TEACH_WITH_US: {
        pageName: 'TEACH_WITH_US_PAGE',
        pagePath: '/teach-with-us',
        routePath: 'teach-with-us',
        component: TeachWithUsPage,
        roles: [],
    },
    THANK_YOU: {
        pageName: 'THANK_YOU_PAGE',
        pagePath: '/thankyou',
        routePath: 'thankyou',
        component: ThankYouPage,
        roles: [],
    },
    TUTOR: {
        pageName: 'TUTOR_PAGE',
        pagePath: '/tutors',
        routePath: 'tutors/:userId',
        component: TutorPage,
        roles: [],
    },
    TUTOR_COURSE: {
        pageName: 'TUTOR_COURSE',
        pagePath: '/tutors',
        routePath: 'tutors/:userId/class',
        component: TutorCourse,
        roles: [],
    },

    // Private pages
    BOOKINGS: {
        pageName: 'BOOKINGS_PAGE',
        pagePath: '/bookings',
        routePath: 'bookings',
        component: BookingsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    BOOKING_CONFIRMATION: {
        pageName: 'BOOKING_CONFIRMATION_PAGE',
        pagePath: '/booking/confirmation',
        routePath: 'booking/confirmation',
        component: BookingConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    CREATE_COURSE: {
        pageName: 'CREATE_COURSE_PAGE',
        pagePath: '/create-class',
        routePath: 'create-class',
        component: CreateCoursePage,
        roles: [userRoles.TUTOR.value]
    },
    DASHBOARD: {
        pageName: 'DASHBOARD_PAGE',
        pagePath: '/dashboard',
        routePath: 'dashboard',
        component: DashboardPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    EDIT_COMPANY: {
        pageName: 'EDIT_COMPANY_PAGE',
        pagePath: '/edit-company',
        routePath: 'edit-company/:companyName',
        component: EditCompanyPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    EDIT_COURSE: {
        pageName: 'EDIT_COURSE_PAGE',
        pagePath: '/edit-class',
        routePath: 'edit-class/:courseId',
        component: EditCoursePage,
        roles: [userRoles.TUTOR.value]
    },
    MEETING: {
        pageName: 'MEETING_PAGE',
        pagePath: '/meeting',
        routePath: 'meeting',
        component: MeetingPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MEETING_ROOM: {
        pageName: 'MEETING_ROOM_PAGE',
        pagePath: '/meeting',
        routePath: 'meeting/:appId/:meetingId',
        component: MeetingRoomPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MESSAGE: {
        pageName: 'MESSAGE_PAGE',
        pagePath: '/message',
        routePath: 'message',
        component: MessagePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MESSAGES: {
        pageName: 'MESSAGES_PAGE',
        pagePath: '/message/:id',
        routePath: 'message/:id',
        component: MessagePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    MY_STUDENTS: {
        pageName: 'MY_STUDENTS_PAGE',
        pagePath: '/my-students',
        routePath: 'my-students',
        component: MyStudentsPage,
        roles: [userRoles.TUTOR.value]
    },
    MY_TUTORS: {
        pageName: 'MY_TUTORS_PAGE',
        pagePath: '/my-tutors',
        routePath: 'my-tutors',
        component: MyTutorsPage,
        roles: [userRoles.STUDENT.value]
    },
    MY_COURSES: {
        pageName: 'MY_COURSES_PAGE',
        pagePath: '/my-classes',
        routePath: 'my-classes',
        component: MyCoursesPage,
        roles: [userRoles.STUDENT.value]
    },
    OFFERINGS: {
        pageName: 'OFFERINGS_PAGE',
        pagePath: '/classes',
        routePath: 'classes',
        component: OfferingsPage,
        roles: [userRoles.TUTOR.value]
    },
    OFFERS: {
        pageName: 'OFFERS',
        pagePath: '/offers',
        routePath: 'offers',
        component: OffersPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PREFERENCES: {
        pageName: 'PREFERENCES_PAGE',
        pagePath: '/preferences',
        routePath: 'preferences/*',
        component: PreferancesPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    PROFILE: {
        pageName: 'PROFILE_PAGE',
        pagePath: '/profile',
        routePath: 'profile',
        component: ProfilePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    REFERRAL: {
        pageName: 'REFERRAL_PAGE',
        pagePath: '/referral',
        routePath: 'referral',
        component: ReferralPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SETTINGS: {
        pageName: 'SETTINGS_PAGE',
        pagePath: '/settings',
        routePath: 'settings/*',
        component: SettingsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SCHEDULE: {
        pageName: 'SCHEDULE_PAGE',
        pagePath: '/calendar',
        routePath: 'calendar',
        component: SchedulePage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SUBSCRIPTIONS: {
        pageName: 'SUBSCRIPTIONS_PAGE',
        pagePath: '/subscriptions',
        routePath: 'subscriptions',
        component: Subscriptions,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    SUPPORT: {
        pageName: 'SUPPORT_PAGE',
        pagePath: '/support',
        routePath: 'support',
        component: SupportPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    TOOLS: {
        pageName: 'TOOLS_PAGE',
        pagePath: '/tools',
        routePath: 'tools',
        component: ToolsPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    TUTORS_ONBOARD: {
        pageName: 'TUTORS_ONBOARD_PAGE',
        pagePath: '/tutor-onboard',
        routePath: 'tutor-onboard',
        component: TutorsOnboardPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    UNAUTHORIZED: {
        pageName: 'UNAUTHORIZED_PAGE',
        pagePath: '/unauthorized',
        routePath: 'unauthorized',
        component: UnauthorizedPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    WALLET: {
        pageName: 'WALLET_PAGE',
        pagePath: '/wallet',
        routePath: 'wallet',
        component: WalletPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    },
    WALLET_CONFIRMATION: {
        pageName: 'WALLET_CONFIRMATION_PAGE',
        pagePath: '/wallet/confirmation',
        routePath: 'wallet/confirmation',
        component: WalletConfirmationPage,
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value]
    }
}

export const sideTopBarPagesName = [
    pagesInfo.DASHBOARD.pageName,
    pagesInfo.SCHEDULE.pageName,
    pagesInfo.TOOLS.pageName,
    pagesInfo.WALLET.pageName,
    pagesInfo.MESSAGE.pageName,
    pagesInfo.MY_TUTORS.pageName,
    pagesInfo.MY_COURSES.pageName,
    pagesInfo.MY_STUDENTS.pageName,
    pagesInfo.PROFILE.pageName,
    pagesInfo.PREFERENCES.pageName,
    pagesInfo.OFFERINGS.pageName,
    pagesInfo.BOOKINGS.pageName,
    pagesInfo.REFERRAL.pageName,
    pagesInfo.SUBSCRIPTIONS.pageName,
    pagesInfo.SETTINGS.pageName,
    pagesInfo.SUPPORT.pageName,
    pagesInfo.CREATE_COURSE.pageName,
]