import { useState, useEffect } from 'react';
import clsx from 'clsx';

import { FiEdit } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { OptionSelector } from 'components/common-components/Select';
import PersonalSocialMediaPresence from 'components/modals/personalDetailModal/PersonalSocialMediaPresence';

import {
  onBoardingStatus,
  titleOptions,
  genderOptions,
  resumeCategory,
  resumeTableHeader
} from 'pages/auth/tutorOnboard/TutorOnboardData';

import { getMasterCountryList, getMasterStateList } from 'redux/master/master.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore'
import { setModal } from 'redux/local/local.slice'
import { modalConst } from 'redux/local/local.const'
import {
  setAddPersonalDetailPayload,
  setDocumentCategoryList,
  setClearDocumentCategoryList,
  setDocumentList,
  setAddDocumentPayload,
  setClearAddDocument
} from 'redux/tutorOnboarding/tutorOnboarding.slice';

import {
  validateEmail,
  validateMobileNo,
  validatePostalCode,
  validateZipCode
} from 'utils/validation-functions';

const pattern = /[a-zA-z]{1}/;

const PersonalDetailModal = () => {
  const { stateList, countryList } = useAppState(s => s.master)
  const { modal } = useAppState((s) => s.local)
  const { addPersonalDetail, documentList } = useAppState((s) => s.tutorOnboarding)
  const dispatcher = useAppDispatcher()

  const [setStartDate] = useState(new Date())

  const [emailErrorText, setEmailErrorText] = useState('')
  const [mobilePhoneErrorText, setMobilePhoneErrorText] = useState('')
  const [homePhoneErrorText, setHomePhoneErrorText] = useState('')
  const [workPhoneErrorText, setWorkPhoneErrorText] = useState('')
  const [zipOrPostalError, setZipOrPostalError] = useState('')
  const [masterCountryId, setMasterCountryId] = useState(null)

  // get country list request
  useEffect(() => {
    dispatcher(getMasterCountryList())
  }, [])

  // get state list request using country Name
  useEffect(() => {
    if (addPersonalDetail?.addPersonalDetailPayload?.countryName) {
      dispatcher(getMasterStateList(addPersonalDetail?.addPersonalDetailPayload?.countryName))
    }
  }, [addPersonalDetail?.addPersonalDetailPayload?.countryName])

  // validations for email and mobile number
  useEffect(() => {
    if (addPersonalDetail?.addPersonalDetailPayload?.email && !validateEmail(addPersonalDetail?.addPersonalDetailPayload?.email)) setEmailErrorText('Enter valid Email!');
    else setEmailErrorText('');

    if (addPersonalDetail?.addPersonalDetailPayload?.mobilePhone && !validateMobileNo(addPersonalDetail?.addPersonalDetailPayload?.mobilePhone)) setMobilePhoneErrorText('Enter valid MobileNo!');
    else setMobilePhoneErrorText('');
  }, [addPersonalDetail?.addPersonalDetailPayload?.mobilePhone, addPersonalDetail?.addPersonalDetailPayload?.email])

  // validation for home phone and work phone
  useEffect(() => {
    if (addPersonalDetail?.addPersonalDetailPayload?.homePhone && !validateMobileNo(addPersonalDetail?.addPersonalDetailPayload?.homePhone)) {
      setHomePhoneErrorText('Enter Valid Phone Number')
    }
    else setHomePhoneErrorText('')
    if (addPersonalDetail?.addPersonalDetailPayload?.workPhone && !validateMobileNo(addPersonalDetail?.addPersonalDetailPayload?.workPhone)) {
      setWorkPhoneErrorText('Enter Valid Phone Number')
    }
    else setWorkPhoneErrorText('')
  }, [addPersonalDetail?.addPersonalDetailPayload?.homePhone, addPersonalDetail?.addPersonalDetailPayload?.workPhone])

  // validation for zip/postal code
  useEffect(() => {
    if ((addPersonalDetail?.addPersonalDetailPayload?.zipCode)
      && (!validateZipCode(addPersonalDetail?.addPersonalDetailPayload?.zipCode) || !validatePostalCode(addPersonalDetail?.addPersonalDetailPayload?.zipCode))) setZipOrPostalError('Enter valid Zip/Postal code');
    else setZipOrPostalError('')

  }, [addPersonalDetail?.addPersonalDetailPayload?.zipCode])

  const resumedDetail = documentList?.documentList?.filter((documentList) => documentList?.fileCategory?.value === resumeCategory.RESUME.value)


  const handlePersonalDetailForm = (event) => {
    event.preventDefault()
    if (documentList) {
      dispatcher(setAddPersonalDetailPayload({
        ...addPersonalDetail?.addPersonalDetailPayload,
        isFormSubmitted: true,
        userId: Math.floor((Math.random() * 10) + 1),
        personalDetailStatus: onBoardingStatus.SUBMITTED.value,
      }))
      alert("personal details submitted successfully")
    }
    if (!documentList) {
      alert("Please Upload Your Resume")
    }
  }

  const handleEditResume = (documentId) => {
    const filteredResume = documentList?.documentList?.find((document) => document?.documentId === documentId)
    dispatcher(setAddDocumentPayload(filteredResume))
    dispatcher(setDocumentCategoryList({
      categoryListItems: resumeCategory,
      resumeTask: true
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true,
      [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: false
    }))
  }

  const handleDeleteResume = (documentId) => {
    const filteredDocumentList = documentList?.documentList?.filter((document) => document?.documentId !== documentId)
    dispatcher(setDocumentList(filteredDocumentList))
  }

  const uploadResume = () => {
    dispatcher(setClearAddDocument())
    dispatcher(setClearDocumentCategoryList())
    dispatcher(setDocumentCategoryList({
      categoryListItems: resumeCategory,
      resumeTask: true
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true,
      [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: false
    }))
  }

  return (
    <form onSubmit={handlePersonalDetailForm} className="space-y-5 overflow-x-hidden">
      <div className={"min-h-[24rem] block w-full border border-text-400 space-y-5"}>
        <div className='space-y-1'>
          <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
            {"Personal Details"}
          </div>
          <span className='p-2 font-bodyPri font-normal text-xs text-text-900'>
            {"(* is Mandatory Field)"}
          </span>
          <div className='grid grid-cols-12 gap-5 px-2'>
            <div className='col-start-0 col-span-3'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Title"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <OptionSelector
                options={Object.values(titleOptions)}
                placeholder={"Please pick a type"}
                required={true}
                value={addPersonalDetail?.addPersonalDetailPayload?.title}
                onChange={(option) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  title: option.value
                }))}
              />
            </div>
            <div className='col-start-0 col-span-4'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"First Name"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"John Doe"}
                value={addPersonalDetail?.addPersonalDetailPayload?.firstName}
                className={clsx(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => {
                  dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    firstName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                  }))
                }}
                required
              />
            </div>
            <div className='col-start-0 col-span-5'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"last Name"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"John Doe"}
                value={addPersonalDetail?.addPersonalDetailPayload?.lastName}
                className={clsx(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  lastName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                }))}
                required
              />
            </div>
            <div className='col-start-0 col-span-5'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Preferred Name"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"Smith"}
                value={addPersonalDetail?.addPersonalDetailPayload?.preferredName}
                className={clsx(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  preferredName: (e.target.value).charAt(0).toUpperCase() + (e.target.value.slice(1))
                }))}
                required
              />
            </div>
            <div className='col-start-0 col-span-3'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Gender"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <OptionSelector
                options={Object.values(genderOptions)}
                placeholder={"Please pick a type"}
                value={addPersonalDetail?.addPersonalDetailPayload?.gender}
                onChange={(option) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  gender: option.value
                }))}
                required
              />
            </div>
            <div className='col-start-0 col-span-4'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Date Of Birth"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <DatePicker
                className={clsx(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                value={addPersonalDetail?.addPersonalDetailPayload?.dateOfBirth}
                selected={addPersonalDetail?.addPersonalDetailPayload?.dateOfBirth}
                onChange={(date) => {
                  dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    dateOfBirth: date
                  }))
                  setStartDate(date)
                }}
                required
              />
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Email"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <input
                type={"text"}
                placeholder={"e.g. johndoe@gmail.com"}
                value={addPersonalDetail?.addPersonalDetailPayload?.email}
                className={clsx(
                  "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  email: (e.target.value).toLowerCase()
                }))}
                required
              />
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {emailErrorText}
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Mobile Phone"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
              <div className={clsx(
                "px-3 py-1 border-2 border-divider-medium bg-white rounded-md focus:outline-none flex items-center gap-3",
                "text-text-900 text-base font-bodyPri w-full"
              )}>
                {!pattern.test(addPersonalDetail?.addPersonalDetailPayload?.mobilePhone) &&
                  <select name='countryCode' className='focus:outline-none' onChange={(event) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    countryCode: event.target.value
                  }))}>
                    {countryList?.countryList?.map((item) => (
                      <option className='w-auto h-auto' value={addPersonalDetail?.addPersonalDetailPayload?.countryCode}>
                        {"+" + item.isdCode}
                      </option>
                    ))}
                  </select>
                }
                <input
                  type={"number"}
                  required
                  placeholder={"e.g. 0123456789"}
                  value={addPersonalDetail?.addPersonalDetailPayload?.mobilePhone}
                  className={clsx(
                    "w-full input-number-spin-none focus:outline-none",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    mobilePhone: e.target.value
                  }))}
                />
              </div>
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {mobilePhoneErrorText}
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Home Phone"}
              </span>
              <input
                type={"number"}
                placeholder={"e.g. (01) 10203040"}
                value={addPersonalDetail?.addPersonalDetailPayload?.homePhone}
                className={clsx(
                  "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  homePhone: e.target.value
                }))}
              />
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {homePhoneErrorText}
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Work Phone"}
              </span>
              <input
                type={"number"}
                placeholder={"e.g. (01) 10203040"}
                value={addPersonalDetail?.addPersonalDetailPayload?.workPhone}
                className={clsx(
                  "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                  ...addPersonalDetail?.addPersonalDetailPayload,
                  workPhone: e.target.value
                }))}
              />
              <span className="font-bodyPri font-normal text-xs text-red-500">
                {workPhoneErrorText}
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className='w-full px-2 py-1 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
            {"Address Details"}
          </div>
          <div className='grid grid-cols-12 gap-5 px-2'>
            <div className='col-start-0 col-span-full'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Street Address"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                <input
                  type={"text"}
                  placeholder={"e.g. 142 Palm Avenue"}
                  value={addPersonalDetail?.addPersonalDetailPayload?.streetAddress}
                  className={clsx(
                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    streetAddress: e.target.value
                  }))}
                  required
                />
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Country"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                <select
                  name='country'
                  className={clsx(
                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    countryName: e.target.value
                  }))}>
                  {!addPersonalDetail?.addPersonalDetailPayload?.countryName &&
                    <option value={addPersonalDetail?.addPersonalDetailPayload?.countryName}>{"--Select Country--"}</option>
                  }
                  {addPersonalDetail?.addPersonalDetailPayload?.countryName &&
                    <option value={addPersonalDetail?.addPersonalDetailPayload?.countryName}>{addPersonalDetail?.addPersonalDetailPayload?.countryName}</option>
                  }
                  {countryList?.countryList?.map((country, index) => (
                    <option key={index} value={country?.country}>{country?.country}</option>
                  ))}
                </select>
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"State/Province/Region"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                <select
                  name='state'
                  className={clsx(
                    "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    state: e.target.value
                  }))}>
                  {!addPersonalDetail?.addPersonalDetailPayload?.state &&
                    <option>{"--Select State--"}</option>
                  }
                  {addPersonalDetail?.addPersonalDetailPayload?.state &&
                    <option value={addPersonalDetail?.addPersonalDetailPayload?.state}>{addPersonalDetail?.addPersonalDetailPayload?.state}</option>
                  }
                  {stateList?.stateList?.map((state, index) => (
                    <option key={index} value={state?.state}>{state?.state}</option>
                  ))}
                </select>
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"City/Suburb"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                <input
                  type={"text"}
                  placeholder={"e.g. "}
                  value={addPersonalDetail?.addPersonalDetailPayload?.city}
                  className={clsx(
                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    city: e.target.value
                  }))}
                  required
                />
              </span>
            </div>
            <div className='col-start-0 col-span-6'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Postal / ZIP Code"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
                <input
                  type={"number"}
                  placeholder={"e.g. 33619"}
                  value={addPersonalDetail?.addPersonalDetailPayload?.zipCode}
                  className={clsx(
                    "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                    "placeholder:text-text-500 placeholder:text-sm"
                  )}
                  onChange={(e) => dispatcher(setAddPersonalDetailPayload({
                    ...addPersonalDetail?.addPersonalDetailPayload,
                    zipCode: e.target.value
                  }))}
                  required
                />
                <span className="font-bodyPri font-normal text-xs text-red-500">
                  {zipOrPostalError}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className='w-full px-2 py-1 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
            {"Resume Details"}
          </div>
          <div className='grid grid-cols-12 gap-3 px-2 pb-2'>
            <div className='col-start-0 col-span-full'>
              <span className='font-bodyPri font-normal text-text-900 text-sm'>
                {"Upload Resume"}
                <span className="text-red-500 text-lg">{"*"}
                </span>
              </span>
            </div>
            {resumedDetail?.length > 0 &&
              <div className={clsx("col-start-0 col-span-full")}>
                <table className="table-fixed">
                  <thead>
                    <tr>
                      {resumeTableHeader.map((head, index) => (
                        <th
                          key={index}
                          className={clsx(
                            "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                            "font-bold whitespace-nowrap border border-gray-200"
                          )}
                        >
                          {head}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-text-200">
                    {resumedDetail?.map((document) => (
                      <tr className={''} >
                        <td className="border border-gray-200">
                          <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                            {document?.fileCategory?.label}
                          </span>
                        </td>
                        <td className={"overflow-hidden border border-gray-200"}>
                          <a
                            href={document?.fileUrl}
                            target={"_blank"}
                            className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center hover:underline truncate'>
                            {document?.fileName?.slice(0, 8) + "..."}
                          </a>
                        </td>
                        <td className="px-2 font-bodyPri font-normal text-text-900 text-base italic text-center">
                          {document?.documentStatus}
                        </td>
                        <td className={"px-5 py-2 border border-gray-200"}>
                          <FiEdit
                            className={"text-xl text-primary-main cursor-pointer hover:text-primary-dark"}
                            onClick={() => handleEditResume(document?.documentId)}
                          />
                        </td>
                        <td className={"px-5 py-2 border border-gray-200"}>
                          <GiCancel
                            className={"text-xl text-text-500 hover:text-text-600 cursor-pointer"}
                            onClick={() => handleDeleteResume(document?.documentId)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            }
            {resumedDetail?.length === 0 &&
              <div className='col-start-0 col-span-6 w-full font-bodyPri font-normal text-text-800 text-sm italic'>
                {"Upload Your Resume Here"}
              </div>
            }
            {resumedDetail?.length === 0 &&
              <div className='col-start-0 col-span-6'>
                <span
                  className={clsx("px-3 py-1.5 w-32 font-buttons text-primary-dark border border-primary-dark rounded",
                    "hover:text-text-50 hover:bg-secondary-dark hover:border-secondary-dark",
                    "text-center cursor-pointer font-normal text-sm whitespace-nowrap"
                  )}
                  onClick={uploadResume}
                >{"Upload"}
                </span>
              </div>
            }
          </div>
        </div>

        {/* users online Presence */}
        <PersonalSocialMediaPresence />
      </div>
      <div className={"flex justify-end px-5"}>
        <button
          type={"submit"}
          className={clsx(
            "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
            "font-buttons font-normal text-base",
            "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
          )}>
          {"Submit"}
        </button>
      </div>
    </form>
  )
}

export default PersonalDetailModal;