import clsx from "clsx";

import { dashboardStats } from 'pages/auth/dashboard/data';

import ComponentLoader from "components/loader/ComponentLoader";

import { useAppState } from "hooks/useStore";
import { userRoles } from 'redux/auth/auth.const';


const TeacherStastics = ({ statItem }) => {
  const { user } = useAppState((s) => s.user)
  const { locals } = useAppState((s) => s.local)

  switch (statItem.value) {
    case dashboardStats.HOURS_TAUGHT.value:
      statItem.data = locals.userRole === userRoles.TUTOR.value ? user?.user?.tutor?.taughtHours : user?.user?.student?.learningHours
      break;
    case dashboardStats.LEARNERS.value:
      statItem.data = user?.user?.tutor?.myStudentCount || 0
      break;
    case dashboardStats.SESSION_COMPLETED.value:
      statItem.data = locals.userRole === userRoles.TUTOR.value ? user?.user?.tutor?.noOfSessionTaken : user?.user?.student?.noOfSessionTaken
      break;
    case dashboardStats.MY_BALANCE.value:
      statItem.data = parseFloat("" + (user?.user?.netWalletCredit / 100)).toFixed(2) || 0
      break;
    case dashboardStats.REVIEWS_EARNED.value:
      statItem.data = user?.user?.tutor?.ratingCount || 0
      break;
    case dashboardStats.BOOKINGS.value:
      statItem.data = locals.userRole === userRoles.TUTOR.value ? user?.user?.tutor?.bookingCount : user?.user?.student?.bookingCount
      break;
    default:
      statItem.data = 0
  }

  return (
    <div className={"flex flex-col bg-white rounded-lg px-5 py-3 gap-3"}>
      <div className={"flex justify-between"}>
        {statItem.icon}
        <span className={"font-bodyComp font-medium text-lg text-text-800"}>
          {statItem.label}
        </span>
      </div>
      <div className="flex justify-between items-end">
        <span className="font-bodyPri font-bold text-xl text-text-900">{statItem.data}</span>
        <span className="font-bodyPri font-normal text-sm text-text-500">{statItem.unit}</span>
      </div>
    </div>
  )
}

export const DashboardStatistics = () => {
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)

  return (
    <div className={"grid-cols-1 md:grid-cols-2 grid gap-3 w-full"}>
      <ComponentLoader isLoading={user?.isLoading} />
      {Object.keys(dashboardStats).map((item, idx) => dashboardStats[item].roles.includes(locals.userRole) && (
        <TeacherStastics key={idx} statItem={dashboardStats[item]} />
      ))}
    </div>
  );
};
