import React from "react";

const Container = (props) => {
  return (
    <div id={props.id} className={props.marginTop ? `grid grid-cols-12 space-y-3` : `grid grid-cols-12 space-y-3`}>

      {/* Left Space */}
      <div className="col-span-2 hidden 2xl:block"></div>

      {/* Center Space */}
      <div className="col-span-12 2xl:col-span-8">
        <div className="grid grid-cols-12 gap-2">

          {/* Left Part */}
          <div className="col-span-12 md:col-span-7 xl:col-span-8">
            <div className="h-full shadow bg-white">
              {props.leftSide}
            </div>
          </div>

          {/* Right Part */}
          <div className="col-span-12 md:col-span-5 xl:col-span-4">
            <div className="h-full">
              {props.rightSide}
            </div>
          </div>

        </div>
      </div>

      {/* Right Space */}
      <div className="col-span-2 hidden 2xl:block"></div>
    </div>
  );
};

export default Container;
