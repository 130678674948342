import { doc, updateDoc } from "firebase/firestore";
import { database } from "config/firebase"

export default function updateMessageStatus(messageIds, chatId) {
  messageIds.forEach(async (messageId) => {
    const messageRef = doc(database, "chats", chatId, "messages", messageId);
    await updateDoc(messageRef, {
      status: "seen",
    });
    const chatRef = doc(database, "chats", chatId);
    await updateDoc(chatRef, {
      "lastMessage.status": "seen",
    });
  });
}
