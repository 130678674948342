const howDoesItWorkLeftSide = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/featured-one-refer-and-earn.png"
const howDoesItWorkMiddle = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/featured-two-refer-and-earn.png"
const howDoesItWorkRightSide = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/featured-three-refer-and-earn.png"

export const cols = [
  "Date Joined",
  "Name",
  "Purchase Date",
  "Status",
  "Amount",
];

export const howDoesItWork = [
  {
    title: "Invite your friends using unique code or link",
    image: howDoesItWorkLeftSide
  },
  {
    title: "Your friends use you link or code to register on our app website, and purchase a lesson",
    image: howDoesItWorkMiddle
  },
  {
    title: "You get USD 5 in your wallet your and friends also get USD 5 to spend on lessons",
    image: howDoesItWorkRightSide
  },
]

export const data = {
  referURL: "https://www.edulyte.com/refer/code/uid",
  referCode: "XYX-454545",
  referrals: [
    {
      dateJoined: "1 Dec 2021",
      name: "John Doe",
      purchaseDate: "1 Dec 2021",
      status: "completed",
      earnedRs: "USD 5",
    },
    {
      dateJoined: "2 Nov 2021",
      name: "Honey Marry",
      purchaseDate: "2 Nov 2021",
      status: "pending",
      earnedRs: "USD 5"
    },
    {
      dateJoined: "24 Oct 2021",
      name: "James Ward",
      purchaseDate: "24 Oct 2021",
      status: "pending",
      earnedRs: "USD 5"
    },
    {
      dateJoined: "30 Oct 2021",
      name: "Chris Bradford",
      purchaseDate: "30 Oct 2021",
      status: "completed",
      earnedRs: "USD 5"
    },
    {
      dateJoined: "18 Dec 2021",
      name: "Erik Jin",
      purchaseDate: "18 Dec 2021",
      status: "completed",
      earnedRs: "USD 5"
    },
    {
      dateJoined: "1 Dec 2021",
      name: "Alex Cooper",
      purchaseDate: "1 Dec 2021",
      status: "completed",
      earnedRs: "USD 5"
    },
  ],
};

