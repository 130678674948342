import createCoursePicUrl from './images/createCourse.svg';

export const pageHeading = {
    heading: "Create a class",
    subHeading: "Learners can book and attend live sessions in small groups or one-on-one!",
    headingPicUrl: createCoursePicUrl
}

export const pageProgressIndicators = [
    {
        name: "Select a template",
        status: "in-progress",
    },
    {
        name: "Edit",
        status: "incomplete",
    },
    {
        name: "Publish",
        status: "incomplete",
    },
];