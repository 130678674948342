import { useState, useEffect } from "react";
import clsx from "clsx";

import { FaPlusCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

import NewOffDayCreationForm from "./NewOffDayCreationForm";
import OffDayAvailabilityCard from './OffDayAvailabilityCard';

import { Button } from "components/common-components/Buttons";

import {
  getUserAvailabilityByUserId,
  updateAvailabilityByUserId
} from 'redux/availability/availability.request';
import { getUserEventListByUserId } from "redux/event/event.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearUserEventList } from "redux/event/event.slice";

import { dayjs, timeZone } from 'utils/dateTime.utils';


export default function OffDaysSection() {
  const { user } = useAppState((s) => s.user)
  const { availability } = useAppState((s) => s.availability)

  const dispatcher = useAppDispatcher()

  const [showAddForm, setShowAddForm] = useState(false);
  const [selectedOffDay, setSelectedOffDay] = useState(null)

  useEffect(() => {
    dispatcher(getUserEventListByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearUserEventList())
    }
  }, [])

  const handleShowAddForm = () => {
    if (showAddForm) {
      setSelectedOffDay(null)
    } else {
      setSelectedOffDay({
        name: '',
        date: dayjs().tz(timeZone).format('YYYY-MM-DD'),
        timeSlots: []
      })
    }
    setShowAddForm(!showAddForm)
  }

  const handleOnReset = () => {
    dispatcher(getUserAvailabilityByUserId(user?.user?.userId))
  }

  const handleOnSave = () => {
    dispatcher(updateAvailabilityByUserId(
      user?.user?.userId,
      { offDays: availability?.availability?.offDays }
    ))
  }

  return (
    <div className="space-y-3">

      <div className={"flex items-center justify-center"}>
        <div onClick={handleShowAddForm} className={clsx(
          "flex justify-center gap-3 py-3 px-5 w-fit rounded-lg",
          "border-2 border-text-300 cursor-pointer group hover:scale-105",
          "transition duration-150 delay-75 ease-in-out"
        )}>
          {!showAddForm &&
            <p className="font-bodyPri font-medium text-lg text-primary-main group-hover:text-primary-dark">
              {"Add a day off"}
            </p>
          }
          {showAddForm &&
            <p className="font-bodyPri font-medium text-lg text-text-500 group-hover:text-text-500">
              {"Close Form"}
            </p>
          }
          <button className={clsx(
            "inline-flex items-center justify-center",
            "transform rounded-full bg-gray-50"
          )}>
            {!showAddForm && <FaPlusCircle className={"text-xl text-primary-main group-hover:text-primary-dark"} />}
            {showAddForm && <AiFillCloseCircle className={"text-xl text-text-500 group-hover:text-text-500"} />}
          </button>
        </div>
      </div>

      {showAddForm &&
        <NewOffDayCreationForm
          selectedOffDay={selectedOffDay}
          setSelectedOffDay={setSelectedOffDay}
          setShowAddForm={setShowAddForm}
        />
      }

      {availability?.availability?.offDays && availability?.availability?.offDays.length > 0 &&
        availability?.availability?.offDays?.map((offDay, index) => (
          <OffDayAvailabilityCard key={index} offDay={offDay} />
        ))
      }

      <div className="flex justify-center py-6 space-x-4">
        <Button name="Reset" style="outline" size="medium" onClick={handleOnReset} />
        <Button name="Save" style="primary" size="medium" onClick={handleOnSave} />
      </div>
    </div>
  );
}
