import { useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentPayload } from 'redux/payment/payment.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { paymentType } from 'redux/payment/payment.const';
import { setAddCreditPayload } from 'redux/wallet/wallet.slice';

import PaymentMethodSummery from 'components/modals/paymentMethodModal/PaymentMethodSummary';
import PaymentForm from 'components/modals/paymentMethodModal/PaymentForm';

const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);
const GATEWAY_NAME = "stripe"
const PROCESSING_PCT_FEE = 2.5
const CURRENCY = "usd"


const PaymentMethodModal = () => {
    const { modal } = useAppState(s => s.local)
    const { user } = useAppState(s => s.user)
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        const totalAmount = Math.ceil((makePaymentPayload?.amountToPay * 100) / (100 - PROCESSING_PCT_FEE))
        dispatcher(setMakePaymentPayload({
            ...makePaymentPayload,
            firstName: user?.user?.firstName || '',
            lastName: user?.user?.lastName || '',
            email: user?.user?.email || '',
            totalAmountToPay: totalAmount,
            gatewayName: GATEWAY_NAME,
            processingPctFee: PROCESSING_PCT_FEE,
            currency: CURRENCY
        }))
    }, [user?.user])

    useEffect(() => {
        if (makePayment?.makePayment) {
            if (makePayment?.makePaymentPayload?.paymentType === paymentType.ADD_CREDIT_TO_WALLET.type) {
                dispatcher(setAddCreditPayload({
                    ...addCreditToWallet?.addCreditPayload,
                    totalAmount: parseInt(makePayment?.makePaymentPayload?.totalAmountToPay),
                    gatewayPaymentId: makePayment?.makePayment?.paymentIntentId,
                    gatewayName: makePayment?.makePaymentPayload?.gatewayName
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PAYMENT_METHOD_MODAL.stateKey]: false,
                    [modalConst.ADD_CREDIT_MODAL.stateKey]: true
                }))
            } else if ([paymentType.BUY_COURSE.type, paymentType.BUY_COURSE_AS_GUEST.type].includes(makePayment?.makePaymentPayload?.paymentType)) {
                dispatcher(setAddCreditPayload({
                    ...addCreditToWallet?.addCreditPayload,
                    totalAmount: parseInt(makePayment?.makePaymentPayload?.totalAmountToPay),
                    gatewayPaymentId: makePayment?.makePayment?.paymentIntentId,
                    gatewayName: makePayment?.makePaymentPayload?.gatewayName
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.PAYMENT_METHOD_MODAL.stateKey]: false,
                    [modalConst.MAKE_PAYMENT_MODAL.stateKey]: true
                }))
            }
        }
    }, [makePayment?.makePayment])

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5"}>
                <div>
                    <PaymentMethodSummery />
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"self-center w-full"}>
                    <Elements stripe={stripePromise}>
                        <PaymentForm />
                    </Elements>
                </div>
            </div>
        </div>
    )
}

export default PaymentMethodModal