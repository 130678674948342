/* This example requires Tailwind CSS v2.0+ */
import clsx from "clsx";
import { Fragment } from "react";
import { FaCheck as CheckIcon, FaMinus as MinusIcon } from "react-icons/fa";
import { Button } from "components/common-components/Buttons";
import { AllPlans, MockPlanInformation } from "../store/types";

export default function SubscriptionPlans() {
  const plans = MockPlanInformation.plans;
  const features = MockPlanInformation.features;
  return (
    <div className="bg-white">
      <div className="mx-auto bg-white max-w-full sm:px-6 lg:px-8">
        {/* For Mobile and Tablet Screen :xs to lg */}
        <div className="max-w-3xl mx-auto space-y-16 lg:hidden">
          {AllPlans.map((plan, tierIdx) => (
            <section key={plans[plan].id}>
              <div className="px-4 mb-8">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  {plans[plan].name}
                </h2>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">
                    ${plans[plan].pricePerMonth}
                  </span>{" "}
                  <span className="text-base font-medium text-gray-500">
                    /mo
                  </span>
                </p>
                <p className="mt-4 text-sm text-gray-500">
                  {plans[plan].description}
                </p>
                <button
                  className={clsx(
                    "block w-full py-2 mt-6 text-sm font-semibold text-center text-white bg-gray-800",
                    "border border-gray-800 rounded-md hover:bg-gray-900"
                  )}
                >
                  Buy {plans[plan].name}
                </button>
              </div>

              {
                <table className="w-full">
                  {/* <caption className="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50">
                    {section.name}
                  </caption> */}
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {features.map((feature) => (
                      <tr
                        key={feature.feature}
                        className="border-t border-gray-200"
                      >
                        <th
                          className="px-4 py-5 text-sm font-normal text-left text-gray-500"
                          scope="row"
                        >
                          {feature.feature}
                        </th>
                        <td className="py-5 pr-4">
                          {feature.applicablity[plan].value ? (
                            <span className="block text-sm text-right text-gray-700">
                              {feature.applicablity[plan].value}
                            </span>
                          ) : (
                            <>
                              {feature.applicablity[plan].isApplicable ? (
                                <CheckIcon
                                  className="w-5 h-5 ml-auto text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="w-5 h-5 ml-auto text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.applicablity[plan].isApplicable ===
                                true
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
              {/* Uncomment Below to display button at the end of each plan */}
              {/* 
              <div
                className={clsx(
                  tierIdx < tiers.length - 1 ? "py-5 border-b" : "pt-5",
                  "border-t border-gray-200 px-4"
                )}
              >
                <button className="block w-full py-2 text-sm font-semibold text-center text-white bg-gray-800 border border-gray-800 rounded-md hover:bg-gray-900">
                  Buy {plans[plan].name}
                </button>
              </div> */}
            </section>
          ))}
        </div>

        {/* For Desktop: lg+ */}
        <div className="hidden lg:block">
          <table className="w-full table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th
                  className="w-1/4 px-6 pb-4 text-sm font-medium text-left text-gray-900"
                  scope="col"
                >
                  <span className="sr-only">Feature by</span>
                  <span>Plans</span>
                </th>
                {AllPlans.map((plan) => (
                  <th
                    key={plans[plan].id}
                    className="w-1/4 px-6 pb-4 text-lg font-medium leading-6 text-left text-gray-900"
                    scope="col"
                  >
                    {plans[plan].name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 divide-y divide-gray-200">
              <tr>
                <th
                  className="px-6 py-8 text-sm font-medium text-left text-gray-900 align-top"
                  scope="row"
                >
                  Pricing
                </th>
                {AllPlans.map((plan) => (
                  <td
                    key={plans[plan].id}
                    className="h-full px-6 py-8 align-top"
                  >
                    <div className="flex flex-col justify-between h-full space-y-6">
                      <div>
                        <p className="whitespace-nowrap">
                          <span className="text-4xl font-extrabold text-gray-900">
                            ${plans[plan].pricePerMonth}
                          </span>{" "}
                          <span className="text-base font-medium text-gray-500">
                            /mo
                          </span>
                        </p>
                        <p className="mt-4 mb-16 text-sm text-gray-500">
                          {plans[plan].description}
                        </p>
                      </div>
                      <Button
                        name={`Buy ${plans[plan].name}`}
                        style="primary"
                        size="medium"
                      />
                    </div>
                  </td>
                ))}
              </tr>
              {/* {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="py-3 pl-6 text-sm font-medium text-left text-gray-900 bg-gray-50"
                      colSpan={4}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr> */}
              {features.map((feature) => (
                <tr key={feature.feature}>
                  <th className="py-3 pl-6 pr-2 text-sm font-medium text-left text-gray-900 bg-gray-50">
                    {feature.feature}
                  </th>
                  {AllPlans.map((plan) => (
                    <td key={plans[plan].id} className="px-6 py-5">
                      {feature.applicablity[plan].value ? (
                        <span className="block text-sm text-gray-700">
                          {feature.applicablity[plan].value}
                        </span>
                      ) : (
                        <>
                          {feature.applicablity[plan].isApplicable === true ? (
                            <CheckIcon
                              className="w-5 h-5 text-green-500"
                              aria-hidden="true"
                            />
                          ) : (
                            <MinusIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}

                          <span className="sr-only">
                            {feature.applicablity[plan].isApplicable === true
                              ? "Included"
                              : "Not included"}{" "}
                            in {plans[plan].name}
                          </span>
                        </>
                      )}
                    </td>
                  ))}
                </tr>
                //   ))}
                // </Fragment>
              ))}
            </tbody>
            {/* Uncomment this for adding buy now at end of cols */}
            {/* <tfoot>
              <tr className="border-t border-gray-200">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {AllPlans.map((plan) => (
                  <td key={plans[plan].name} className="px-6 pt-5">
                    <a
                      //   href={tier.href} //j TODO: Add reference link later
                      className="block w-full py-2 text-sm font-semibold text-center text-white bg-gray-800 border border-gray-800 rounded-md hover:bg-gray-900"
                    >
                      Buy {plans[plan].name}
                    </a>
                  </td>
                ))}
              </tr>
            </tfoot> */}
          </table>
        </div>
      </div>
    </div>
  );
}
