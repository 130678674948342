import clsx from "clsx";
import * as React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

type Props = {
  className?: string;
  headers: string[];
  rows: any[];
  title?: string;
  rowHeaderColor?: string;
  alternateRowColor?: string;
  pageSize?: number;
  onPageChange?: (pageNumber: number) => void;
  activePageNumber?: number;
};

export default function TableComponent({
  headers,
  rows,
  className,
  title,
  onPageChange = () => { },
  pageSize = 0,
  activePageNumber = 0,
  alternateRowColor = "bg-gray-100",
  rowHeaderColor = "bg-gray-100",
}: Props) {
  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= pageSize; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 overflow-x-auto">
          <div className="min-w-full lg:w-[48rem] border-b border-gray-200 rounded-lg shadow">
            {title && (
              <p className="px-6 pt-6 font-bold tracking-wider text-left text-slate-700">
                {title}
              </p>
            )}
            {/* table */}
            <table className="w-full divide-y divide-gray-200">
              <thead className={clsx(rowHeaderColor)}>
                <tr>
                  {headers.map((head, index) => (
                    <th
                      key={index}
                      scope="col"
                      className={clsx(
                        "px-6 py-6 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
                        "font-bold whitespace-nowrap"
                      )}
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {rows?.map((row: any[], idx) => (
                  <tr
                    key={idx}
                    className={clsx(
                      idx % 2 === 0 ? "bg-white" : alternateRowColor
                    )}
                  >
                    {row.map((r) => (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 capitalize">{r}</div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* table-pagination */}
            {pageSize !== 0 && (
              <div className="flex items-center justify-center w-full space-x-2 bg-white rounded-lg shadow-md py-7">
                <button
                  className="p-2 bg-white border rounded-full shadow-sm border-sky-400"
                  onClick={() => {
                    if (activePageNumber !== 1) {
                      onPageChange(activePageNumber - 1);
                    }
                  }}
                >
                  <FaChevronLeft className="text-sky-500" />
                </button>
                <div className="flex space-x-1">
                  {getPageNumbers().map((page) => (
                    <button
                      key={page}
                      className={clsx(
                        " px-3 py-1 cursor-pointer rounded-full",
                        activePageNumber === page
                          ? "font-bold bg-blue-500 text-white"
                          : "text-sky-500",
                        "hover:scale-110 "
                      )}
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </button>
                  ))}
                </div>
                <button
                  onClick={() => {
                    if (activePageNumber !== pageSize) {
                      onPageChange(activePageNumber + 1);
                    }
                  }}
                  className="p-2 bg-white border rounded-full shadow-sm border-sky-400"
                >
                  <FaChevronRight className="text-sky-500" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
