import clsx from 'clsx';
import Slider from 'react-input-slider';

import { AiFillPlusCircle } from 'react-icons/ai';
import { AiFillMinusCircle } from 'react-icons/ai';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddCreditPayload } from 'redux/wallet/wallet.slice';

import { amountOptionList } from 'components/modals/addCreditModal/addCreditModal.data';

const MIN_AMOUNT_TO_ADD = 1
const MAX_AMOUNT_TO_ADD = 1500


const AmountTopUpCard = () => {
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const addCreditPayload = addCreditToWallet?.addCreditPayload

    const dispatcher = useAppDispatcher()

    const handleInputChange = (value) => {
        dispatcher(setAddCreditPayload({
            ...addCreditPayload,
            amount: (
                value < MIN_AMOUNT_TO_ADD
                    ? (MIN_AMOUNT_TO_ADD * 100)
                    : value > MAX_AMOUNT_TO_ADD
                        ? (MAX_AMOUNT_TO_ADD * 100)
                        : value * 100
            )
        }))
    }

    return (
        <div className={"p-5 space-y-8 border-2 border-divider-medium rounded-2xl overflow-hidden select-none"}>
            <span>{"Amount to top up"}</span>
            <div className={"w-full flex items-center justify-around"}>
                <AiFillMinusCircle
                    onClick={() => handleInputChange(parseFloat(addCreditPayload?.amount / 100) - 10)}
                    className={"text-text-500 hover:text-text-700 text-3xl cursor-pointer"}
                />
                <div className={clsx(
                    "flex items-center justify-center gap-1",
                    "text-primary-dark text-xl font-bodyPri font-extrabold tracking-wider"
                )}>
                    <span>{"USD"}</span>
                    <input
                        className={"w-20 font-bodyPri font-extrabold tracking-wider input-number-spin-none focus:outline-none select-none"}
                        type={"number"}
                        value={parseInt(addCreditPayload?.amount / 100)}
                        onChange={event => handleInputChange(parseFloat(event.target.value))}
                    />
                </div>
                <AiFillPlusCircle
                    onClick={() => handleInputChange(parseFloat(addCreditPayload?.amount / 100) + 10)}
                    className={"text-text-500 hover:text-text-700 text-3xl cursor-pointer"}
                />
            </div>
            <div className={"flex items-center justify-center"}>
                <Slider
                    axis="x"
                    xmin={MIN_AMOUNT_TO_ADD}
                    xmax={MAX_AMOUNT_TO_ADD}
                    x={addCreditPayload?.amount / 100}
                    onChange={({ x }) => handleInputChange(parseFloat(x))}
                    styles={{
                        track: {
                            backgroundColor: "#e0e0e0",
                            height: 8,
                        },
                        active: {
                            backgroundColor: "#0049b7"
                        },
                        thumb: {
                            backgroundColor: "#fafafa",
                            boxShadow: "0 0 5px 1px rgba(0,0,0, 0.3)",
                            width: 15,
                            height: 15
                        },
                        disabled: {
                            opacity: 0.5
                        }
                    }}
                />
            </div>
            <div className={"flex flex-wrap items-center justify-center gap-x-2 gap-y-3"}>
                {amountOptionList.map((amountOption, index) => (
                    <div key={index} onClick={() => handleInputChange(parseFloat(amountOption.amount))}
                        className={clsx(
                            "flex items-center justify-center gap-1 w-14 py-1 rounded-lg cursor-pointer",
                            "font-bodyPri font-medium text-base",
                            (amountOption.amount * 100) === addCreditPayload?.amount
                                ? "bg-primary-dark text-text-50"
                                : "bg-primary-light text-primary-dark"
                        )}>
                        <span>{amountOption.label}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AmountTopUpCard