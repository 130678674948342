import tw from 'twin.macro';

export const Section = tw.div`
p-5 flex flex-wrap justify-evenly gap-3 overflow-hidden bg-white shadow-sm rounded-xl
`;

export const Footer = tw.div`
h-[28rem] bg-white overflow-hidden text-center p-8 mb-28 shadow-sm rounded-xl
`;

export const HLine = tw.div
  `pt-2 mx-20`;

  export const Button = tw.button`
  w-36 h-32 flex flex-col items-center justify-center cursor-pointer p-3 border-2 rounded-lg border-primary-light hover:border-secondary-main
`;