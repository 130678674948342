import { setAndGetLocalStorage } from "redux/local/local.utils"
import { localsConst } from 'redux/local/local.const';


export const LOCAL_INITIAL_STATE = {
    locals: {
        userRole: setAndGetLocalStorage(localsConst.USER_ROLE.value),
        timeZone: setAndGetLocalStorage(localsConst.TIME_ZONE.value),
        currency: setAndGetLocalStorage(localsConst.CURRENCY.value)
    },

    pages: {
        isThankYou: false
    },

    shareSocial: {
        socialAction: null,
        url: ""
    },
    termsAndConditions: {
        termsAndConditionsAction: null    
    },

    modal: {
        isShowRoleSwitchModal: false,
        isShowCourseScheduleModal: false,
        isShowNoOfSessionModal: false,
        isShowChooseTimeSlotModal: false,
        isShowCourseCheckoutModal: false,
        isShowMakePaymentModal: false,
        isShowPaymentMethodModal: false,
        isShowTodoModal: false,
        isShowEventDetailModal: false,
        isShowEventActionModal: false,
        isShowCourseScheduleEditModal: false,
        isShowImagePickerModal: false,
        isShowUploadDocumentModal: false,
        isShowPreviewDocumentModal: false,
        isShowQuestionAnswerModal: false,
        isShowChooseIntervalSlot: false,
        isShowComingSoonModal: false,
        isShowMessageTemplateModal: false,
        isShowApplyReferralModal: false,
        isShowAuthorizationModal: false,
        isShowReferenceFormModal: false,
        isShowCodeVerificationModal: false,
        isShowSubmitCourseModal: false,
        isShowShareSocialModal: false,
        isShowPostRequirementModal: false,
        isShowCompanyPeopleModal: false,
        isShowSpeakToUsModal: false,
        isShowNeedATutorModal: false,
        isShowTermsAndConditionsModal: false
    },
}