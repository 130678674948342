import { toast } from "react-toastify";

import eventService from 'redux/event/event.service';
import {
    setUserEventListLoading,
    setUserEventList,
    setUserEventListErrorMsg,

    setEventDetailLoading,
    setEventDetail,
    setEventDetailErrorMsg,

    setUpdateEventLoading,
    setUpdateEvent,
    setUpdateEventErrorMsg,
} from "redux/event/event.slice";


export const getUserEventListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserEventListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await eventService.getUserEventListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserEventList(response.data))
        }
        else {
            dispatch(setUserEventListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUserEventListErrorMsg(error.message))
    } finally {
        dispatch(setUserEventListLoading(false))
    }
}

export const getEventDetailByEventId = (eventId) => async (dispatch) => {
    dispatch(setEventDetailLoading(true))

    try {
        const requestData = {
            params: { eventId: eventId }
        }
        const response = await eventService.getEventDetailByEventId(requestData)
        if (response.status === 200) {
            dispatch(setEventDetail(response.data))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setEventDetailErrorMsg(error.message))
    } finally {
        dispatch(setEventDetailLoading(false))
    }
}

export const acceptEventByEventId = (eventId, body) => async (dispatch, getState) => {
    dispatch(setUpdateEventLoading(true))

    const { user } = getState().user

    try {
        const requestData = {
            params: { eventId: eventId },
            body: body
        }
        const response = await eventService.acceptEventByEventId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateEvent(response.data))
            toast.success(response.data.message)
        }
        else {
            dispatch(setUpdateEventErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateEventErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setUpdateEventLoading(false))
    }
}

export const declineEventByEventId = (eventId, body) => async (dispatch, getState) => {
    dispatch(setUpdateEventLoading(true))

    const { user } = getState().user

    try {
        const requestData = {
            params: { eventId: eventId },
            body: body
        }
        const response = await eventService.declineEventByEventId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateEvent(response.data))
            toast.success(response.data.message)
        }
        else {
            dispatch(setUpdateEventErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateEventErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setUpdateEventLoading(false))
    }
}

export const rescheduleEventByEventId = (eventId, body) => async (dispatch, getState) => {
    dispatch(setUpdateEventLoading(true))

    const { user } = getState().user

    try {
        const requestData = {
            params: { eventId: eventId },
            body: body
        }
        const response = await eventService.rescheduleEventByEventId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateEvent(response.data))
            toast.success(response.data.message)
        }
        else {
            dispatch(setUpdateEventErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateEventErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setUpdateEventLoading(false))
    }
}

export const startEventByEventId = (eventId, body) => async (dispatch, getState) => {
    dispatch(setUpdateEventLoading(true))

    const { user } = getState().user

    try {
        const requestData = {
            params: { eventId: eventId },
            body: body
        }
        const response = await eventService.startEventByEventId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateEvent(response.data))
            toast.success(response.data.message)
        }
        else {
            dispatch(setUpdateEventErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateEventErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setUpdateEventLoading(false))
    }
}

export const completeEventByEventId = (eventId, body) => async (dispatch, getState) => {
    dispatch(setUpdateEventLoading(true))

    const { user } = getState().user

    try {
        const requestData = {
            params: { eventId: eventId },
            body: body
        }
        const response = await eventService.completeEventByEventId(requestData)
        if (response.status === 200) {
            getUserEventListByUserId(user?.user?.userId)
            dispatch(setUpdateEvent(response.data))
            toast.success(response.data.message)
        }
        else {
            dispatch(setUpdateEventErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUpdateEventErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setUpdateEventLoading(false))
    }
}