import { createSlice } from "@reduxjs/toolkit";
import { MEETING_INITIAL_STATE } from "redux/meeting/meeting.initialState";


const meeting = createSlice({
    name: "meeting",
    initialState: MEETING_INITIAL_STATE,
    reducers: {
        // Reducers for meeting
        setMeetingLoading: (state, { payload }) => {
            state.meeting.isLoading = payload
        },
        setMeeting: (state, { payload }) => {
            state.meeting.meeting = payload
            state.meeting.errorMsg = MEETING_INITIAL_STATE.meeting.errorMsg
        },
        setMeetingErrorMsg: (state, { payload }) => {
            state.meeting.errorMsg = payload
            state.meeting.meeting = MEETING_INITIAL_STATE.meeting.meeting
        },
        setClearMeeting: (state) => {
            state.meeting = MEETING_INITIAL_STATE.meeting
        },
    }
})

export const {
    setMeetingLoading,
    setMeeting,
    setMeetingErrorMsg,
    setClearMeeting,
} = meeting.actions

export default meeting.reducer