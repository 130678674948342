import { createSlice } from "@reduxjs/toolkit";
import { DOCUMENT_INITIAL_STATE } from "redux/document/document.initialState";


const document = createSlice({
    name: "document",
    initialState: DOCUMENT_INITIAL_STATE,
    reducers: {
        // Reducers for uploadDocument
        setUploadDocumentLoading: (state, { payload }) => {
            state.uploadDocument.isLoading = payload
        },
        setUploadDocument: (state, { payload }) => {
            state.uploadDocument.uploadDocument = payload
            state.uploadDocument.errorMsg = DOCUMENT_INITIAL_STATE.uploadDocument.errorMsg
        },
        setUploadDocumentPayload: (state, { payload }) => {
            state.uploadDocument.uploadDocumentPayload = payload
        },
        setUploadDocumentErrorMsg: (state, { payload }) => {
            state.uploadDocument.errorMsg = payload
            state.uploadDocument.uploadDocument = DOCUMENT_INITIAL_STATE.uploadDocument.uploadDocument
        },
        setClearUploadDocument: (state) => {
            state.uploadDocument = DOCUMENT_INITIAL_STATE.uploadDocument
        },
    }
})

export const {
    setUploadDocumentLoading,
    setUploadDocument,
    setUploadDocumentPayload,
    setUploadDocumentErrorMsg,
    setClearUploadDocument,
} = document.actions

export default document.reducer