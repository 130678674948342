import clsx from 'clsx';
import { useNavigate } from "react-router-dom";

import { switchRoleOption } from 'components/modals/roleSwitchModal/RoleSwitchModal.data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal, setLocals } from 'redux/local/local.slice';
import { modalConst, localsConst } from 'redux/local/local.const';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo'


const RoleSwitchModal = () => {
    const { modal, locals } = useAppState((state) => state.local)
    const { user } = useAppState(state => state.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const handleRole = (role) => {
        if (role !== locals.userRole) {
            dispatcher(setLocals({
                ...locals,
                [localsConst.USER_ROLE.value]: role
            }))
            navigate(pagesInfo.DASHBOARD.pagePath, { replaceAll: true })
        }

        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: false
        }))
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"block space-y-8"}>
                <div className={"flex flex-col md:flex-row items-center justify-evenly gap-5"}>
                    {user?.user?.roles.filter(role => [userRoles.STUDENT.value, userRoles.TUTOR.value].includes(role.role)).map((role, index) => (
                        <div key={index} onClick={() => handleRole(role.role)}
                            className={clsx(
                                "relative flex flex-col items-center justify-center gap-5 cursor-pointer",
                                "border-[0.2rem] rounded-xl p-5 text-primary-dark hover:border-primary-dark hover:bg-primary-light",
                                role.role === locals.userRole
                                    ? "border-primary-dark"
                                    : "border-primary-light"
                            )}
                        >
                            <div className={"overflow-hidden w-32 h-32 "}>
                                <img src={switchRoleOption[role.role.toUpperCase()].roleImageUrl} alt={"role-icon"} className={"w-full h-full object-cover"} />
                            </div>
                            <span className={"font-bodyPri font-semibold text-xl tracking-wide uppercase"}>
                                {userRoles[role.role.toUpperCase()].label}
                            </span>
                            {role.role === locals.userRole &&
                                <span className={"absolute top-2 left-2 w-4 h-4 rounded-full bg-green-500"}></span>
                            }
                        </div>
                    ))}
                </div>

                <div className={"flex justify-end"}>
                    <span onClick={() => handleRole(locals.userRole)} className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    )}>
                        {"Cancel"}
                    </span>
                </div>
            </div>
        </div >
    )
}

export default RoleSwitchModal