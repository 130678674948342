import clsx from "clsx";
import format from 'string-format';

import { heroCard } from 'pages/auth/dashboard/data';

import ComponentLoader from "components/loader/ComponentLoader";
import { setSideOverPanel } from "../store/DashboardSlice";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { userRoles } from 'redux/auth/auth.const';


const HeroCard = () => {
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s) => s.user)
  const dispatcher = useAppDispatcher();

  const onClick = () => {
    dispatcher(setSideOverPanel({ status: true }));
  };

  return (
    <div className="px-2 py-4 w-full bg-white rounded-lg sm:px-12 sm:py-8">
      {user?.isLoading && <ComponentLoader isLoading={user?.isLoading} />}
      <div className="flex flex-col-reverse items-center justify-between w-full max-w-3xl sm:flex-row">

        <div className="w-full max-w-sm px-3 space-y-5">
          <p className="text-2xl text-text-900 font-medium font-bodyPri ">
            {format(heroCard.title, user?.user?.firstName + " " + user?.user?.lastName)}
          </p>
          <div className="text-lg font-normal font-bodyPri text-text-700">
            {locals.userRole === userRoles.TUTOR.value &&
              format(heroCard.tutorText, user?.user?.tutor?.noOfSessionTaken, user?.user?.tutor?.teachingScores)
            }
            {locals.userRole === userRoles.STUDENT.value &&
              format(heroCard.studentText, user?.user?.student?.noOfSessionTaken, user?.user?.student?.learningScores)
            }
          </div>
          <button onClick={onClick} className={clsx(
            "px-3 py-1 border-2 border-primary-main w-min rounded-xl whitespace-nowrap",
            "text-primary-main text-bodyPri font-medium",
            "hover:bg-primary-dark hover:text-white hover:border-primary-dark cursor-pointer"
          )}>
            {"Do Something"}
          </button>
        </div>

        <div className="flex justify-center w-full sm:w-min">
          <div className="w-24 h-24 overflow-hidden rounded-full ring ring-primary-main p-1">
            <img src={user?.user?.profilePicUrl} alt={"profile-img"} className={"w-full h-full object-cover"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCard;
