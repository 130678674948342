export const companyEditBtn = {
    coverPicUrl: {
        value: "coverPicUrl"
    },
    companyLogoUrl: {
        value: "companyLogoUrl"
    },
    companyVideoUrl: {
        value: "companyVideoUrl"
    },
    companyName: {
        value: "companyName"
    },
    elevatorPitch: {
        value: "elevatorPitch"
    },
    currentLocation: {
        value: "currentLocation"
    },
    bio: {
        value: "bio"
    },
    courses: {
        value: "courses"
    },
    People: {
        value: "People"
    },
    importantLinks: {
        value: "linkUrl"
    },
    companyFAQ: {
        value: "companyFAQ"
    }
}

export const tabsList = {
    tabs: [
        {
            tab: "Home"
        },
        {
            tab: "About"
        },
        {
            tab: "Courses"
        },
        {
            tab: "People"
        },
        {
            tab: "Links"
        },
        {
            tab: "FAQs"
        },
    ]
}

export const logoOptions = {
    WEBSITE_LOGO: {
        label: "Website logo",
        value: "https://www.clipartmax.com/png/middle/159-1598472_clip-art-earth-22-earth-logo-vector-png.png"
    },
    FACEBOOK_LOGO: {
        label: "Facebook logo",
        value: "https://www.facebook.com/images/fb_icon_325x325.png"
    },
    TWITTER_LOGO: {
        label: "Twitter logo",
        value: "https://logodownload.org/wp-content/uploads/2014/09/twitter-logo-6.png"
    },
    LINKEDIN_LOGO: {
        label: "Linkedin logo",
        value: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/800px-LinkedIn_logo_initials.png"
    },
    INSTAGRAM_LOGO: {
        label: "Instagram logo",
        value: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
    },
    YOUTUBE_LOGO: {
        label: "Youtube logo",
        value: "https://www.logo.wine/a/logo/YouTube/YouTube-Icon-Full-Color-Logo.wine.svg"
    }
}