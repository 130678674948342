import { url } from "redux/auth/auth.const";
import { setToken, setLogIn } from "redux/auth/auth.slice";
import { baseCredApiInstance } from 'apiInstances/axios.apiInstance';

const getToken = async () => {
    try {
        const response = await baseCredApiInstance().get(url.GET_TOKEN)
        if (response.status === 200) {
            return response.data
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

const axiosInterceptor = (instance, store) => {
    const { dispatch } = store
    const { auth } = store.getState()

    instance.interceptors.request.use(
        config => {
            if (auth?.logInSignUp?.accessToken && !config.headers['Authorization']) {
                config.headers['Authorization'] = auth?.logInSignUp?.accessToken
            }
            return config;
        }, (error) => Promise.reject(error)
    );

    instance.interceptors.response.use(
        response => response,
        async (error) => {
            const prevRequest = error?.config;
            if (error?.response?.status === 403 && !prevRequest?.sent) {
                prevRequest.sent = true;
                const token = await getToken()
                if (token?.accessToken) {
                    dispatch(setToken(token?.accessToken))
                    dispatch(setLogIn())
                    prevRequest.headers['Authorization'] = token?.accessToken
                    const apiInstance = instance
                    return apiInstance(prevRequest);
                }
            }
            return Promise.reject(error);
        }
    );
}

export default axiosInterceptor;