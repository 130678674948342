import { useState, useEffect } from 'react'
import clsx from 'clsx'

import { BsPlusLg } from "react-icons/bs"

import { sampleCourseThumbnail } from 'pages/auth/editCourse/data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setClearUploadDocument, setUploadDocumentPayload } from 'redux/document/document.slice';
import { setOfferingCourse } from 'redux/offering/offering.slice';
import { modalConst } from 'redux/local/local.const';
import { fileCategories } from 'redux/document/document.const';


const SelectThumbnails = () => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const { uploadDocument } = useAppState((s) => s.document)
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()

    const [finalSelection, setFinalSelection] = useState<string>(offeringCourse?.offeringCourse?.thumbnailPicUrl)
    const [selectedImage, setSelectedImage] = useState<string>(offeringCourse?.offeringCourse?.thumbnailPicUrl)

    useEffect(() => {
        if (uploadDocument?.uploadDocument) {
            dispatcher(setOfferingCourse({
                ...offeringCourse?.offeringCourse,
                thumbnailPicUrl: uploadDocument?.uploadDocument?.documentUrl
            }))
        }
    }, [uploadDocument?.uploadDocument])

    const handleOnSelectImage = () => {
        dispatcher(setClearUploadDocument())
        dispatcher(setUploadDocumentPayload({
            ...uploadDocument?.uploadDocumentPayload,
            fileCategory: fileCategories.courseThumbnail.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
        }))
    }

    return (
        <div className='shadow-md bg-white space-y-3 p-5'>
            <div className="flex items-center pb-1 border-b gap-3">
                <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                    {"Class Thumbnail"}
                </span>
                <span className="text-base text-text-700 font-normal">
                    ({"Upload your thumbnail or choose one"})
                </span>
            </div>
            <div className='grid grid-cols-12 gap-3'>
                {sampleCourseThumbnail.map((item, index) => (
                    <div key={index} onClick={() => setFinalSelection(item.image)} className={clsx(
                        'col-span-3 p-2 w-full h-full flex justify-center items-center border hover:border-2 hover:border-[#FF4081] hover:scale-105 ease-in-out duration-200 overflow-hidden',
                        finalSelection === item.image
                            ? "border-2 border-[#FF4081] scale-105"
                            : ""
                    )}>
                        <div className='overflow-hidden'>
                            <img src={item.image} alt={"course-thumbnail"} className={"w-full  h-full object-cover"} />
                        </div>
                    </div>
                ))}
                <div onClick={handleOnSelectImage} className={clsx(
                    "w-full h-full flex items-center justify-center cursor-pointer p-2 ease-in-out duration-200 hover:scale-105 hover:border-2 hover:border-[#FF4081] sm:w-32 xs:w-24",
                    selectedImage && finalSelection === '' ? "border-2 border-[#FF4081] scale-105" : "border",
                    finalSelection === selectedImage ? "border-2 border-[#FF4081] scale-105" : "border"
                )}>
                    <label className={clsx(
                        'relative w-full h-full flex items-center justify-center overflow-hidden',
                        finalSelection === selectedImage
                            ? ""
                            : "bg-background-medium"
                    )}>
                        {finalSelection === selectedImage &&
                            <img id="imgFileUpload" src={offeringCourse?.offeringCourse?.thumbnailPicUrl} alt={"Select File"} className='w-full object-cover opacity-100' />
                        }
                        <BsPlusLg className='absolute text-5xl text-center text-text-600' />
                    </label>
                </div>
            </div>
        </div>
    )
}

export default SelectThumbnails