import tw, { styled } from 'twin.macro';
import { Link as RouteLink } from 'react-router-dom';

export const Container = styled.div`
    ${tw`
        w-screen h-screen bg-center bg-white flex justify-center overflow-hidden
        md:(justify-start)
    `}
`;

export const Content = tw.div`
    grid grid-cols-12 h-full w-full
`;

export const TeachWithUsCard = tw.div`
    min-w-[24rem] col-start-2 col-span-8 self-center flex flex-col gap-5
    md:col-start-5 md:col-span-5 lg:col-start-2 lg:col-span-4
`;

export const TeachWithUsImage = tw.div`
    hidden col-start-5 col-span-full overflow-hidden rounded-md
    md:block lg:block lg:col-start-7 lg:col-span-3 lg:w-[36rem] lg:h-[36rem] md:w-[22rem] md:h-[22rem]
`;

export const CardHeader = tw.div`
    flex flex-col items-center justify-center gap-3
`;

export const Heading = tw.h1`
    text-xl font-bodyPri font-bold text-text-900
`;

export const SubHeading = tw.span`
    font-subHeading font-normal text-text-500 flex flex-col justify-center w-full gap-1 items-center text-base
    lg:flex-row leading-5
`;

export const CardFooter = tw.span`
    w-full font-bodyPri font-normal text-sm text-text-700 leading-6
`;

export const AnchorLink = tw(RouteLink)`
    text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer
`;