import React from "react";

//Component imports
import ChatFilterModalListItem from "./ChatFilterModalListItem";
import Button from "../presentationcomponents/Button/Button";

//style imports
import { StyledChatFilterModal } from "./ChatFilterModal.styles";

export default function ChatFilterModal({ setShowFiltersModal }) {
  const availableFilters = ["group", "individual", "sent"];

  function closeFilterModal() {
    setShowFiltersModal(false);
  }

  return (
    <StyledChatFilterModal style={{ transform: "translate(-50%, -50%)" }}>
      <h3>Select Filters</h3>
      {availableFilters.map((filter) => {
        return <ChatFilterModalListItem key={filter} filter={filter} />;
      })}
      <Button text="Close" variant="secondary" onClick={closeFilterModal} />
    </StyledChatFilterModal>
  );
}
