import React from "react";
import {
  FaUserPlus,
  FaSignOutAlt,
  FaAward,
  FaShare,
  FaCalendarAlt,
  FaAngleDoubleUp,
  FaRegComment,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import {
  BsShieldFillCheck,
  BsLightningFill,
  BsPatchCheckFill,
  BsFlag,
  BsFillStarFill,
  BsFillCameraVideoFill,
  BsQuestionCircleFill,
  BsThreeDotsVertical,
  BsHeart,
  BsChevronDown,
  BsSearch,
  BsStarHalf,
  BsStar,
  BsCalendar2,
  BsThreeDots,
  BsGlobe,
  BsCurrencyDollar,
  BsPeople,
  BsInfoCircle,
  BsCheck2,
} from "react-icons/bs";
import { FiFilter } from "react-icons/fi";
import { CgArrowsExchange } from "react-icons/cg";
import {
  AiOutlineDollarCircle,
  AiOutlineDoubleLeft,
  AiOutlineDoubleRight,
  AiFillWallet,
  AiOutlineRight,
} from "react-icons/ai";
import { FcGraduationCap } from "react-icons/fc";
import { ImClock } from "react-icons/im";
import { CgProfile } from "react-icons/cg";
import { IoIosFlash } from "react-icons/io";
import { MdModeEditOutline, MdClose } from "react-icons/md";

const MyIcon = (props) => {
  const getIcon = () => {
    switch (props.type) {
      case "signup":
        return <FaUserPlus />;

      case "login":
        return <FaSignOutAlt />;

      case "star":
        return <BsFillStarFill size={20} color="#FDCC0D" />;

      case "halfStar":
        return <BsStarHalf size={20} color="#FDCC0D" />;

      case "emptyStar":
        return <BsStar size={20} color="#FDCC0D" />;

      case "medal":
        return <FaAward size={24} color="red" />;

      case "shield":
        return <BsShieldFillCheck size={24} color="blue" />;

      case "certified":
        return <BsPatchCheckFill size={24} color="green" />;

      case "light":
        return <BsLightningFill size={24} color="#DB2777" />;

      case "heart":
        return <BsHeart size={24} color="gray" />;

      case "double-left":
        return <i className="pi pi-angle-double-left"></i>;

      case "double-right":
        return <i className="pi pi-angle-double-right"></i>;

      case "filter":
        return <FiFilter size={32} color="#CCC" />;

      case "arrows":
        return <CgArrowsExchange size={32} color="#CCC" />;

      case "flag":
        return <BsFlag size={24} color="#CCC" />;

      case "share":
        return <FaShare size={24} color="#CCC" />;

      case "calendar":
        return <FaCalendarAlt size={24} />;

      case "dollor":
        return <AiOutlineDollarCircle size={24} />;

      case "upArrows":
        return <FaAngleDoubleUp size={24} />;

      case "comment":
        return <FaRegComment size={24} color="#CCC" />;

      case "teach":
        return <FcGraduationCap size={24} color="#CCC" />;

      case "rightArrow":
        return <FaChevronRight size={24} color="gray" />;

      case "leftArrow":
        return <FaChevronLeft size={24} color="gray" />;

      case "doubleLeft":
        return <AiOutlineDoubleLeft size={24} />;

      case "doubleRight":
        return <AiOutlineDoubleRight size={24} />;

      case "clock":
        return <ImClock size={30} color="#ff4081" />;

      case "profile":
        return <CgProfile size={30} color="#ff4081" />;

      case "video":
        return <BsFillCameraVideoFill size={30} color="#ff4081" />;

      case "wallet":
        return <AiFillWallet size={30} color="#ff4081" />;

      case "star2":
        return <BsFillStarFill size={30} color="#ff4081" />;

      case "flash":
        return <IoIosFlash size={30} color="#ff4081" />;

      case "questionmark":
        return <BsQuestionCircleFill size={24} color="gray" />;

      case "threedot":
        return <BsThreeDotsVertical size={24} color="gray" />;

      case "threedot2":
        return <BsThreeDots size={24} color="gray" />;

      case "downArrow":
        return <BsChevronDown size={16} />;

      case "search":
        return <BsSearch size={16} />;

      case "calendar2":
        return <BsCalendar2 size={20} color="gray" />;

      case "globe":
        return <BsGlobe size={20} color="gray" />;

      case "currencyDollor":
        return <BsCurrencyDollar size={20} color="gray" />;

      case "grayClock":
        return <ImClock size={20} color="gray" />;

      case "right":
        return <AiOutlineRight size={20} color="black" />;

      case "camera":
        return (
          <BsFillCameraVideoFill size={32} color={props.color || "#2194f3"} />
        );

      case "people":
        return <BsPeople size={32} color={props.color || "#2194f3"} />;

      case "info":
        return <BsInfoCircle size={24} color="gray" />;

      case "edit":
        return <MdModeEditOutline size={24} color="gray" />;

      case "check":
        return <BsCheck2 size={24} color="red" />;

      case "close":
        return <MdClose size={24} color="#0049b7" />;

      default:
        return <FaUserPlus />;
    }
  };

  if (props.background) {
    return (
      <div {...props} className={`p-3 ${props.background}`}>
        <div className="cursor-pointer">{getIcon()}</div>
      </div>
    );
  } else {
    return (
      <div {...props} className="cursor-pointer">
        {getIcon()}
      </div>
    );
  }
};

export default MyIcon;
