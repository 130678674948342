import { default as clsx, default as cx } from "clsx";

import { getOfferingByCourseId, updateOfferingByCourseId } from "redux/offering/offering.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from "redux/local/local.const";
import { courseType } from "redux/course/course.const";
import { setModal } from "redux/local/local.slice";
import { toast } from "react-toastify";

const SubmitFormBar = () => {
    const { modal } = useAppState((s) => s.local)
    const { offeringCourse } = useAppState((s) => s.offering)
    const { profList, languageList } = useAppState((s) => s.master)
    const dispatcher = useAppDispatcher()

    const generateOfferings = async () => {
        const offering = {
            courseTitle: offeringCourse?.offeringCourse?.courseTitle,
            courseTitleDescription: offeringCourse?.offeringCourse?.courseTitleDescription,
            courseDescription: offeringCourse?.offeringCourse?.courseDescription,
            category: offeringCourse?.offeringCourse?.category,
            skills: offeringCourse?.offeringCourse?.skills,
            masterProficiencyId: profList?.profList?.filter((prof: any) => (
                prof?.proficiency === offeringCourse?.offeringCourse?.proficiency
            ))[0]?.masterProficiencyId,
            masterLanguageId: languageList?.languageList?.filter((language: any) => (
                language?.language === offeringCourse?.offeringCourse?.language
            ))[0]?.masterLanguageId,
            price: offeringCourse?.offeringCourse?.price,
            discountPct: offeringCourse?.offeringCourse?.discountPct,
            thumbnailPicUrl: offeringCourse?.offeringCourse?.thumbnailPicUrl,
            videoUrl: offeringCourse?.offeringCourse?.videoUrl
        }

        let restGroupOffering = {}
        if (offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value) {
            restGroupOffering = {
                studentCapacity: offeringCourse?.offeringCourse?.studentCapacity,
                sessionDuration: offeringCourse?.offeringCourse?.sessionDuration,
                noOfSessions: offeringCourse?.offeringCourse?.noOfSessions,
                totalDuration: offeringCourse?.offeringCourse?.totalDuration,
                startDate: offeringCourse?.offeringCourse?.startDate,
                endDate: offeringCourse?.offeringCourse?.endDate,
                weeklyTimeSlot: offeringCourse?.offeringCourse?.weeklyTimeSlot
            }
        }
        return {
            ...offering,
            ...restGroupOffering,
            courseType: offeringCourse?.offeringCourse?.courseType
        }
    }

    const onReset = () => {
        dispatcher(getOfferingByCourseId(offeringCourse?.offeringCourse?.courseId))
    }

    const onSave = async () => {
        if (offeringCourse?.offeringCourse?.skills?.length < 5) {
            toast.warning("Please add at least 5 skills!")
            return;
        }

        const offering = await generateOfferings()
        dispatcher(updateOfferingByCourseId(offeringCourse?.offeringCourse?.courseId, offering))

    }

    const onSubmit = () => {
        if (offeringCourse?.offeringCourse?.skills?.length < 5) {
            toast.warning("Please add at least 5 skills!")
            return;
        }

        dispatcher(setModal({
            ...modal,
            [modalConst.SUBMIT_COURSE_MODAL.stateKey]: true
        }))
    }

    return (
        <div className="px-2">
            <div
                className={cx(
                    "shadow-lg rounded-md m-2",
                    "flex items-center justify-between max-w-4xl px-3 py-3 mx-auto bg-white",
                    "flex-col md:flex-row"
                )}
            >
                <p className="text-xl font-medium text-text-800 font-bodyPri tracking-wide">
                    DRAFT: Creating your own {offeringCourse?.offeringCourse?.courseType === courseType?.ONE_ON_ONE?.value ? courseType?.ONE_ON_ONE?.label + " lesson" : courseType?.GROUP?.label + " lesson"}
                </p>
                <div className="flex mt-2 space-x-2 md:m-0 font-medium text-text-800 font-bodyPri tracking-wide">
                    <button
                        className={clsx(
                            "px-5 py-1 uppercase border rounded-lg",
                            "hover:scale-95 transition-transform"
                        )}
                        onClick={onReset}
                    >
                        {"Reset"}
                    </button>
                    <button
                        className={clsx(
                            "px-5 py-1 uppercase border rounded-lg",
                            "hover:scale-95 transition-transform"
                        )}
                        onClick={onSave}
                    >
                        {"Save"}
                    </button>
                    <button
                        className={cx(
                            "text-center py-1 px-4  text-primary-main border-2 border-primary-main hover:bg-primary-main hover:text-text-50 rounded-lg",
                            "hover:scale-95 transition-transform",
                            "whitespace-nowrap "
                        )}
                        onClick={onSubmit}
                    >
                        {"Submit"}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubmitFormBar;