import clsx from "clsx";
import * as React from "react";
import { FaPenAlt, FaPlusCircle } from "react-icons/fa";
import { AiOutlineCheck } from "react-icons/ai";

import { Button } from "components/common-components/Buttons";
import { InputWithLabel } from "components/common-components/Form";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
  editBankTransferDetails,
  editUPIDetails,
  initializeBankTransferDetails,
  initializePayoutUpiDetails,
  setBankTransferDetails,
  setDefaultPayoutMethod,
  setPayoutUpiDetails,
  submitBankTransferDetails,
  submitUPIDetails,
} from "../store/MySettingsSlice";
import { PayoutBankTransfer } from "../store/types";

export default function PayoutPage() {
  return (
    <div>
      <div className="py-4 mx-8 border-b">
        <p className="text-lg text-center">Payout Methods</p>
      </div>

      <div>
        <p className="my-4 tracking-wider text-center opacity-80">
          We will use this information to safely send earning to your account
        </p>

        <div className="space-y-4">
          <BankTransferSection />
          <UPITransferSection />
        </div>
      </div>
    </div>
  );
}

const BankTransferSection = () => {
  const state = useAppState((s) => s.mySettings.payout);
  const [confirmAccountNumber, setConfirmAccountNumber] = React.useState("");
  const dispatcher = useAppDispatcher();

  const initializeBankTransfer = () => {
    dispatcher(
      initializeBankTransferDetails({
        bankTransferInfo: {
          IFSCCode: "",
          bankAccountNumber: "",
          bankName: "",
        },
      })
    );
  };

  const updateDetails = (key: keyof PayoutBankTransfer) => (e: any) => {
    const copy = state.bankTransfer;
    if (copy != null) {
      dispatcher(
        setBankTransferDetails({
          details: {
            ...copy,
            [key]: e.target.value,
          },
        })
      );
    }
  };

  const onSubmit = () => {
    dispatcher(submitBankTransferDetails());
  };

  const makeDefault = () => {
    dispatcher(setDefaultPayoutMethod({ method: "bank" }));
  };

  return (
    <div className="flex flex-col px-2 md:flex-row">
      <div className="space-y-0">
        <p className="px-5 py-2 font-bold">Bank Transfer</p>
        {state._meta.default !== "bank" && state.bankTransfer != null && (
          <button
            className="px-5 text-blue-600 underline"
            onClick={makeDefault}
          >
            Make Default
          </button>
        )}
        {state._meta.default === "bank" && (
          <div className={"flex items-center justify-center gap-1.5"}>
            <span className={"font-bodyPri font-normal text-text-800 text-sm italic"}>
              {"Default"}
            </span>
            <AiOutlineCheck className={"text-lg text-green-500"} />
          </div>
        )}
      </div>
      {state.bankTransfer !== null ? (
        <div className="relative w-full max-w-sm p-4 pl-6 space-y-4 border rounded-md md:w-96">
          {/* Edit */}

          <button
            className={clsx(
              "absolute top-0 right-0 text-blue-500",
              state._meta.editBankTransfer ? "opacity-0" : "opacity-100",
              "p-1.5 rounded-full border"
            )}
            onClick={() => dispatcher(editBankTransferDetails())}
          >
            <FaPenAlt />
          </button>

          <InputWithLabel
            label="Bank Name"
            onChange={updateDetails("bankName")}
            placeholder="Eg. HDFC Bank"
            value={state.bankTransfer.bankName}
          />
          <InputWithLabel
            label="Account Number"
            onChange={updateDetails("bankAccountNumber")}
            placeholder="Eg. 1234567890"
            value={state.bankTransfer.bankAccountNumber}
          />
          <InputWithLabel
            label="Confirm Account Number"
            onChange={(e) => {
              setConfirmAccountNumber(e.target.value);
            }}
            placeholder="Eg. 1234567890"
            value={confirmAccountNumber}
          />
          <InputWithLabel
            label="IFSC Code"
            onChange={updateDetails("IFSCCode")}
            placeholder="Eg. HDFC000001"
            value={state.bankTransfer.IFSCCode}
          />
          {state._meta.editBankTransfer && (
            <div className="flex justify-end">
              <Button
                name="Submit"
                size="small"
                style="primary"
                onClick={onSubmit}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex justify-center flex-1 py-4 mx-4 bg-blue-100 rounded-md cursor-pointer"
          onClick={initializeBankTransfer}
        >
          <div className="flex flex-col items-center justify-center">
            <FaPlusCircle className="text-blue-500 " size={30} />
            <p className="text-lg font-bold text-blue-500">Add Bank Account</p>
          </div>
        </div>
      )}
    </div>
  );
};

const UPITransferSection = () => {
  const state = useAppState((s) => s.mySettings.payout);
  const dispatcher = useAppDispatcher();

  const updateUPITransfer = (e: any) => {
    dispatcher(setPayoutUpiDetails({ details: { upiId: e.target.value } }));
  };

  const makeDefault = () => {
    dispatcher(setDefaultPayoutMethod({ method: "upi" }));
  };

  return (
    <div className="flex flex-col px-2 md:flex-row">
      <div className="px-2 space-y-0">
        <p className="px-5 py-2 font-bold">Pay ID Transfer</p>
        {state._meta.default !== "upi" && state.payoutUpi != null && (
          <button
            onClick={makeDefault}
            className="px-5 text-blue-600 underline"
          >
            Make Default
          </button>
        )}
        {state._meta.default === "upi" && (
          <div className={"flex items-center justify-center gap-1.5"}>
            <span className={"font-bodyPri font-normal text-text-800 text-sm italic"}>
              {"Default"}
            </span>
            <AiOutlineCheck className={"text-lg text-green-500"} />
          </div>
        )}
      </div>
      {state.payoutUpi != null ? (
        <div className="relative w-full max-w-sm p-4 pl-6 space-y-4 border rounded-md md:w-96">
          <button
            className={clsx(
              "absolute top-0 right-0 text-blue-500",
              state._meta.editPayoutUpi ? "opacity-0" : "opacity-100",
              "p-1.5 rounded-full border"
            )}
            onClick={() => dispatcher(editUPIDetails())}
          >
            <FaPenAlt />
          </button>
          <InputWithLabel
            label="Your Pay ID"
            onChange={updateUPITransfer}
            placeholder="Eg. JohnDoe@pay"
            value={state.payoutUpi.upiId}
            disabled={!state._meta.editPayoutUpi}
          />
          {state._meta.editPayoutUpi && (
            <div className="flex justify-end">
              <Button
                name="Submit"
                size="small"
                style="primary"
                onClick={() => dispatcher(submitUPIDetails())}
              />
            </div>
          )}
        </div>
      ) : (
        <div
          className="flex justify-center flex-1 py-4 mx-4 ml-6 bg-blue-100 rounded-md cursor-pointer"
          onClick={() => {
            dispatcher(initializePayoutUpiDetails());
          }}
        >
          <div className="flex flex-col items-center justify-center">
            <FaPlusCircle className="text-blue-500 " size={30} />
            <p className="text-lg font-bold text-blue-500">Add Pay ID</p>
          </div>
        </div>
      )}
    </div>
  );
};
