import { pagesInfo } from 'utils/pagesInfo';
import {
    CenterNavLinksContainer,
    NavLink,
} from './NavBarStyle';

const CenterNavLinks = () => {
    return (
        <CenterNavLinksContainer>
            <NavLink to={pagesInfo.FIND_TUTORS.pagePath}>{'Find tutors'}</NavLink>
            <NavLink to={pagesInfo.FIND_COURSES.pagePath}>{'Find classes'}</NavLink>
        </CenterNavLinksContainer>
    )
}

export default CenterNavLinks;