import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { GiCheckMark } from "react-icons/gi";
import { FiEdit } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { MdAdd } from "react-icons/md";

import AccordionItem from 'components/accordion/AccordionItem';

import { companyEditBtn } from "pages/auth/editCompany/data";
import SaveAndCancelBtn from "pages/auth/editCompany/components/saveAndCancelBtn/SaveAndCancelBtn"

import { getFAQListByCompanyId, createFAQByFAQId, updateFAQByFAQId, deleteFAQByFAQId } from "redux/company/company.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearFAQList, setClearFAQPayload, setFAQPayload } from "redux/company/company.slice";

const EditCompanyFAQ = ({ editBtnConst, setEditBtnConst }) => {
    const { companyPublicProfile, FAQList, FAQPayload } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    const [isAddNewFAQBoxEnabled, setIsAddNewFAQBoxEnabled] = useState(false)
    const [isFAQSelected, setIsFAQSelected] = useState(false)
    const [selectedFAQ, setSelectedFAQ] = useState(null)

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.companyId) {
            dispatcher(getFAQListByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId))

            return () => {
                dispatcher(setClearFAQList())
            }
        }
    }, [companyPublicProfile?.companyPublicProfile?.companyId])

    const handleAddFAQ = () => {
        if (FAQList?.FAQList?.length > 5) {
            alert("This is a pro feature! buy our plan to unlock it")
            return;
        }
        dispatcher(setClearFAQPayload())
        setIsAddNewFAQBoxEnabled(!isAddNewFAQBoxEnabled)
    }

    const handleEditFAQ = () => {
        if (!selectedFAQ) return;
        dispatcher(setFAQPayload(selectedFAQ))
        setIsAddNewFAQBoxEnabled(true)
    }

    const handleDeleteFAQ = () => {
        if (!selectedFAQ) return;
        dispatcher(deleteFAQByFAQId(selectedFAQ))
        setIsFAQSelected(false)
        setSelectedFAQ(null)
    }

    const handleEditDeleteFAQ = (myFAQ) => {
        if (isFAQSelected && selectedFAQ === myFAQ) {
            setSelectedFAQ(null)
            setIsFAQSelected(false)
        } else {
            setSelectedFAQ(myFAQ)
            setIsFAQSelected(!isFAQSelected)
        }
    }

    const onSubmitFAQ = () => {
        if (FAQPayload?.FAQPayload?.length === 0 || FAQPayload?.FAQPayload?.answer?.length === 0) {
            alert("FAQ cannot be empty!")
            return;
        }
        if (FAQList?.FAQList?.length > 5) {
            alert("This is a pro feature! buy our plan to unlock it")
            return;
        }
        if (FAQPayload?.FAQPayload?.FAQId) {
            dispatcher(updateFAQByFAQId(FAQPayload?.FAQPayload))
        }
        if (!FAQPayload?.FAQPayload?.FAQId) {
            const createFAQ = { ...FAQPayload?.FAQPayload, }
            dispatcher(createFAQByFAQId(FAQPayload?.FAQPayload))
        }
        setIsAddNewFAQBoxEnabled(false)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"FAQ"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"bg-white p-5 space-y-3"}>
                    <div className={"flex justify-start items-center gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"Frequently Asked Questions?"}
                        </span>
                        {!editBtnConst &&
                            <FiEdit
                                className={"text-xl text-text-500 hover:text-text-700 cursor-pointer"}
                                onClick={() => setEditBtnConst(companyEditBtn.companyFAQ.value)}
                            />
                        }
                        {editBtnConst === companyEditBtn.companyFAQ.value &&
                            <div className={"flex items-center justify-center gap-12"}>
                                <div className={"flex justify-center items-center gap-5"}>
                                    <div
                                        className={clsx(
                                            "flex item-center justify-center px-2 py-0.5 rounded-md",
                                            "font-buttons font-normal text-base text-text-50",
                                            "hover:bg-primary-dark bg-primary-main cursor-pointer",
                                        )}
                                        onClick={handleAddFAQ}
                                    >
                                        <MdAdd className={"text-xl"} />
                                        <span className={"whitespace-nowrap"}>
                                            {"Add FAQs"}
                                        </span>
                                    </div>
                                    <div
                                        className={clsx(
                                            "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                            "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                                            "hover:bg-background-medium hover:text-text-900"
                                        )}
                                        onClick={() => {
                                            setIsAddNewFAQBoxEnabled(false)
                                            setEditBtnConst(null)
                                        }}
                                    >
                                        <span className={""}>
                                            {"Close"}
                                        </span>
                                    </div>
                                </div>
                                <div className={"flex items-center justify-center gap-1.5"}>
                                    <FiEdit className={clsx(
                                        "text-lg cursor-pointer text-text-300",
                                        selectedFAQ && " text-text-500 hover:text-text-700"
                                    )}
                                        onClick={handleEditFAQ}
                                    />
                                    <MdOutlineDeleteOutline className={clsx(
                                        "text-lg cursor-pointer text-red-300",
                                        selectedFAQ && " text-red-500 hover:text-red-700")}
                                        onClick={handleDeleteFAQ}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className={"grid grid-cols-12 py-5"}>
                        <div className={"col-span-12 md:(col-start-2 col-span-10) lg:(col-start-1 col-span-12)"}>
                            <div className={"mx-10 flex flex-col items-center gap-3"}>
                                {FAQList?.FAQList?.length > 0 &&
                                    FAQList?.FAQList?.map((itemFAQ, index) => (
                                        <div className={"w-full flex items-center justify-start gap-3"}>
                                            {editBtnConst === companyEditBtn.companyFAQ.value &&
                                                <div
                                                    className={"relative w-4 h-4 border-2 border-text-500 hover:border-text-700 rounded cursor-pointer"}
                                                    onClick={() => handleEditDeleteFAQ(itemFAQ)}
                                                >
                                                    {selectedFAQ === itemFAQ &&
                                                        <div className={"absolute -top-0.5 -right-0.5 flex items-center justify-center"}>
                                                            <GiCheckMark className={"text-sm text-primary-dark"} />
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            <AccordionItem
                                                key={index}
                                                index={index}
                                                itemFAQ={itemFAQ}
                                            />
                                        </div>
                                    ))}
                                {FAQList?.FAQList?.length === 0 &&
                                    <div className={"font-bodyPri font-normal text-text-800 text-ms tracking-wide"}>
                                        {"No FAQ's added!"}
                                        <span
                                            className={"cursor-pointer text-primary-main"}
                                            onClick={() => setEditBtnConst(companyEditBtn.companyFAQ.value)}
                                        >
                                            {"Add FAQ's"}
                                        </span>
                                    </div>
                                }
                            </div>
                            {isAddNewFAQBoxEnabled &&
                                <div className={"w-full flex flex-col items-start justify-center gap-5 py-5 px-10"}>
                                    <input
                                        type={"text"}
                                        placeholder={"Enter Question"}
                                        value={FAQPayload?.FAQPayload?.question}
                                        className={clsx(
                                            "w-full px-2 py-1.5 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                            "font-bodyPri font-normal text-text-800 text-base",
                                            "placeholder:text-text-500 placeholder:text-sm"
                                        )}
                                        onChange={(event) => dispatcher(setFAQPayload({
                                            ...FAQPayload?.FAQPayload,
                                            question: event.target.value
                                        }))}
                                    />
                                    <textarea
                                        type={"text"}
                                        placeholder={"Enter Answer"}
                                        rows={4}
                                        value={FAQPayload?.FAQPayload?.answer}
                                        className={clsx(
                                            "w-full px-3 py-1.5 resize-none border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                            "font-bodyPri font-normal text-text-800 text-base",
                                            "placeholder:text-text-500 placeholder:text-sm"
                                        )}
                                        onChange={(event) => dispatcher(setFAQPayload({
                                            ...FAQPayload?.FAQPayload,
                                            answer: event.target.value
                                        }))}
                                    />
                                    <SaveAndCancelBtn
                                        onCancel={() => setIsAddNewFAQBoxEnabled(false)}
                                        onSave={() => onSubmitFAQ()}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditCompanyFAQ