import clsx from "clsx";
import { Link } from "react-router-dom";

// Components
import MyTypography from "components/Typography";
import CourseCard from "components/courseCard/CourseCard";
import Container from "./Container";

import { createFavCourseUserDetailByUserId, deleteFavCourseDetailByUserId } from "redux/course/course.request"
import { useAppState, useAppDispatcher } from "hooks/useStore";
import { pagesInfo } from "utils/pagesInfo";

const Courses = ({...props }) => {
  const { user } = useAppState((s) => s.user)
  const { tutorCourseList } = useAppState((s) => s.course)
  const { tutorPublicProfile } = useAppState((s) => s.tutor)
  const dispatcher = useAppDispatcher()

  return (
    <Container
      id={props.id}
      leftSide={
        <div className="p-6">
          <div className="flex justify-between items-center">
            <MyTypography className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
              {"My Classes"}
            </MyTypography>
            <Link
              to={`${pagesInfo.TUTOR_COURSE.pagePath}/${tutorPublicProfile?.tutorPublicProfile?.userId}/class` + "?pageNo=1"}
              className="cursor-pointer text-sm text-primary-main font-bodyPri tracking-wide"
            >
              {"View All"}
            </Link>
          </div>
          <div className={clsx(
            "grid grid-flow-col gap-5 p-5 overflow-hidden overflow-x-scroll",
            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
          )}>
            {tutorCourseList?.tutorCourseList?.records > 0 
            && tutorCourseList?.tutorCourseList?.results?.slice(0, 5)?.map((course, index) => (
              <CourseCard
                key={index}
                course={course}
                isFav={user?.user?.favCourses?.filter((favCourse) =>
                  favCourse.courseId === course.courseId).length > 0 ? true : false}
                handleFav={(courseId) => {
                  const favoriteCourses = user?.user?.favCourses?.filter((favCourse) =>
                    favCourse?.courseId === courseId)
                  if (favoriteCourses.length > 0) {
                    dispatcher(deleteFavCourseDetailByUserId(user?.user?.userId, { courseId: courseId }))
                  }
                  else {
                    dispatcher(createFavCourseUserDetailByUserId(user?.user?.userId, { courseId: courseId }))
                  }
                }}
              />
            ))}
            {tutorCourseList?.errorMsg || tutorCourseList?.tutorCourseList?.records === 0 &&
            <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                {tutorCourseList?.errorMsg || "No Class Found!"}
            </span>
            }
          </div>
        </div>
      }
      rightSide={null}
      marginTop
    />
  );
};

export default Courses;
