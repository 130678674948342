import LogItem from 'components/logTimeLine/LogItem';

const LogTimeLine = ({ logList }) => {

    return logList && (
        <div className={"flex flex-col items-start"}>
            {logList?.map((logItem, index) => (
                <LogItem key={index} index={index} logItem={logItem} />
            ))}
        </div>
    )
}

export default LogTimeLine