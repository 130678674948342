export const MASTER_INITIAL_STATE = {
    countryList: {
        isLoading: false,
        countryList: null,
        errorMsg: null
    },

    countryDetail: {
        isLoading: false,
        countryDetail: null,
        errorMsg: null
    },

    timeZoneCountryDetail: {
        isLoading: false,
        timeZoneCountryDetail: null,
        errorMsg: null
    },

    stateList: {
        isLoading: false,
        stateList: null,
        errorMsg: null
    },

    cityList: {
        isLoading: false,
        cityList: null,
        errorMsg: null
    },

    currencyList: {
        isLoading: false,
        currencyList: null,
        errorMsg: null
    },

    timeZoneList: {
        isLoading: false,
        timeZoneList: null,
        errorMsg: null
    },

    languageList: {
        isLoading: false,
        languageList: null,
        errorMsg: null
    },

    languageLevelList: {
        isLoading: false,
        languageLevelList: null,
        errorMsg: null
    },

    profList: {
        isLoading: false,
        profList: null,
        errorMsg: null
    },

    tagList: {
        isLoading: false,
        tagList: null,
        errorMsg: null
    },
}