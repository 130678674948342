import clsx from 'clsx';
import { useEffect } from 'react';

import { FaInfoCircle } from "react-icons/fa";

import { weeklyScheduleToolTip } from "pages/auth/preferences/data";
import TabLayout from "../../components/TabLayout";
import OffDaysSection from "./OffDaysSection";
import WeeklyScheduleSection from "./WeeklyScheduleSection";

import FullPageLoader from "components/loader/FullPageLoader";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { getUserAvailabilityByUserId } from 'redux/availability/availability.request';
import { setClearAvailability } from 'redux/availability/availability.slice';

import { timeZone, getTimeZoneOffset } from 'utils/dateTime.utils';


function AvailabilityHeader() {
  return (
    <div className={"py-3 space-y-3"}>
      <div className="flex justify-center w-full">
        <div className="flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"Your Weekly Schedule"}
          </span>
          <div className={"relative has-tooltip cursor-pointer"}>
            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            <span
              className={clsx(
                "w-56 px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip top-6.5",
                "flex flex-col items-start justify-start overflow-hidden"
              )}
            >
              {weeklyScheduleToolTip}
            </span>
          </div>
        </div>
      </div>
      <p className="font-bodyPri font-normal text-sm text-text-700 text-right">
        {timeZone} {getTimeZoneOffset(timeZone)}
      </p>
      <div className="h-0.5 bg-gray-300 w-full"></div>
    </div>
  );
}

const AvailabilityPreferencePage = () => {
  const { user } = useAppState((s) => s.user)
  const { availability } = useAppState((s) => s.availability)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getUserAvailabilityByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearAvailability())
    }
  }, [])

  const tabConfig = [
    {
      element: <WeeklyScheduleSection />,
      label: "Weekly Schedule",
    },
    {
      element: <OffDaysSection />,
      label: "Off Days",
    },
  ];

  if (availability?.isLoading) {
    return <FullPageLoader isLoading={availability?.isLoading} />
  }

  return (
    <div className="">
      <AvailabilityHeader />
      <div className="px-3 py-6">
        <TabLayout config={tabConfig} />
      </div>
    </div>
  );
};

export default AvailabilityPreferencePage;
