import RatingStars from 'components/ratingStars/RatingStars';
import {
    TutorItemContainer,
    ImageContainer,
    TextContainer,
    CardHeading,
    NameText,
    LongText,
    SpecialitiesText,
    Description,
    TrustAndBadges,
    TrustContainer,
    TrustAndBadgesContainer,
    VerticalLineContainer,
    TrustAndBadgesTitle,
    RatingSection,
    Text,
    ReviewSection,
    ReviewTitle,
    ReviewProviderName,
    BadgesItem,
    BadgeItemName,
} from './FeaturedTutorsStyle';

const FeaturedTutorItem = ({ tutorItem }) => {
    return (
        <TutorItemContainer>
            <ImageContainer>
                <img src={tutorItem.imageUrl} alt={'tutor-feature'} className={"w-full h-full object-cover lg:rounded-l-xl"} />
            </ImageContainer>
            <TextContainer>
                <CardHeading>
                    <NameText>{tutorItem.name}</NameText>
                    <SpecialitiesText>{'Specialities: ' + tutorItem.specialities}</SpecialitiesText>
                </CardHeading>
                <LongText>
                    <Description>{tutorItem.description?.slice(0, 160) + " ..."}</Description>
                </LongText>
                <TrustAndBadges>
                    <TrustContainer>
                        <ReviewSection>
                            <ReviewTitle>{'WHAT THE REVIEWS SAY'}</ReviewTitle>
                            <Text>{tutorItem.review}</Text>
                        </ReviewSection>
                        <ReviewProviderName>~{tutorItem.reviewProviderName}</ReviewProviderName>
                        <TrustAndBadgesTitle>{'TRUST'}</TrustAndBadgesTitle>
                        <RatingSection>
                            <RatingStars rating={tutorItem.rating.toFixed(1)} fullRating={5} size={1.2} />
                            <Text>{`${tutorItem.rating.toFixed(1)} stars form ${tutorItem.reviewsCount} reviews.`}</Text>
                        </RatingSection>
                    </TrustContainer>
                    <VerticalLineContainer>
                        <hr />
                    </VerticalLineContainer>
                    <TrustAndBadgesContainer>
                        <TrustAndBadgesTitle>{'BADGES'}</TrustAndBadgesTitle>
                        {tutorItem.badges.map((badgesItem, index) => (
                            <BadgesItem key={index}>
                                <badgesItem.icon className={"text-primary-main"} />
                                <BadgeItemName>{badgesItem.badgeName}</BadgeItemName>
                            </BadgesItem>
                        ))}
                    </TrustAndBadgesContainer>
                </TrustAndBadges>
            </TextContainer>
        </TutorItemContainer>
    )
}

export default FeaturedTutorItem
