import { useRef, useEffect } from 'react';
import {
    DropDownMenuContainer,
} from './DropDownMenuStyle';
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";

const DropDownMenu = ({
    children = <div>{'My menu box!'}</div>,
    isClick = false,
    setIsClick = (item) => console.log(item),
    boxStyle = {},
    arrowStyle = {}
}) => {
    const dropdownRef = useRef(null)
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

    useEffect(() => {
        setIsActive(isClick)
    }, [isClick])

    useEffect(() => {
        if (!isActive)
            setIsClick(isActive)
    }, [isActive])

    return (
        <DropDownMenuContainer
            style={boxStyle}
            arrowStyle={arrowStyle}
            ref={dropdownRef}
            isActive={isActive}
        >
            {children}
        </DropDownMenuContainer>
    )
}

export default DropDownMenu
