import clsx from "clsx";

import {
    Container,
    Content,
    LinksSection,
    HorizontalDivider,
    SocialMediaSection,
    LinksTitle,
    TermsAndCondition,
    TCItem,
    TCItemLink,
    AnchorLink
} from './FooterStyle';

import { footerData } from './FooterData';
import FooterItemsList from './FooterItemsList';
import SocialIcons from './SocialIcons';

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const copyrightYear = new Date().getFullYear()

const Footer = () => {
    return (
        <Container>
            <Content>
                <LinksSection>
                    {footerData.map((footerItemsList, index) => (
                        <FooterItemsList key={index} footerItemsList={footerItemsList} />
                    ))}
                </LinksSection>
                <HorizontalDivider />
                <SocialMediaSection>
                    <LinksTitle>{'Follow us on Social Media'}</LinksTitle>
                    <SocialIcons />
                </SocialMediaSection>
                <HorizontalDivider />
                <TermsAndCondition>
                    <TCItem>&copy;{` ${copyrightYear - 1}-${copyrightYear} Eduventure Technologies Pvt. Ltd.`}</TCItem>
                    <AnchorLink
                        href={edulyteTermsOfService}
                        target={"_blank"}
                        rel={"noreferrer"}
                    >
                        {'Terms of Service'}
                    </AnchorLink>
                    <AnchorLink
                        href={edulytePrivacyPolicy}
                        target={"_blank"}
                        rel={"noreferrer"}
                        className={clsx("col-span-1 font-bodyPri font-normal text-base bg-[#14212D]",
                            "text-text-50 text-left self-start hover:text-primary-light hover:underline"
                        )}
                    >
                        {'Privacy Policy'}
                    </AnchorLink>
                    <TCItemLink to={'#'}>{'Disclaimer'}</TCItemLink>
                </TermsAndCondition>
            </Content>
        </Container>
    )
}

export default Footer