import clsx from 'clsx';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentPayload, setClearMakePaymentPayload } from 'redux/payment/payment.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { paymentType } from 'redux/payment/payment.const';


const AddCreditModalFooter = () => {
    const { modal } = useAppState(s => s.local)
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const { makePayment } = useAppState(s => s.payment)

    const dispatcher = useAppDispatcher()

    const handleToPay = () => {
        dispatcher(setClearMakePaymentPayload())
        dispatcher(setMakePaymentPayload({
            ...makePayment?.makePaymentPayload,
            paymentType: paymentType.ADD_CREDIT_TO_WALLET.type,
            amountToPay: addCreditToWallet?.addCreditPayload?.amount,
            description: paymentType.ADD_CREDIT_TO_WALLET.type
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.ADD_CREDIT_MODAL.stateKey]: false,
            [modalConst.PAYMENT_METHOD_MODAL.stateKey]: true,
        }))
    }

    return (
        <span onClick={handleToPay} className={clsx(
            "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
            "font-buttons font-normal text-base text-secondary-main",
            "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
            addCreditToWallet?.isLoading && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
        )}>
            {"Proceed To Pay"}
        </span>
    )
}

export default AddCreditModalFooter