import { useState } from "react";
import clsx from "clsx";

const PasswordBox = ({ setIsUpdatePassword }) => {
    const [password, setPassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    })

    return (
        <>
            <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
                {"Password"}
            </span>
            <span
                className={clsx(
                    "col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 py-1.5 font-buttons font-medium text-text-800 text-sm text-center rounded-md tracking-wider",
                    "bg-text-200 hover:bg-text-300 cursor-pointer"
                )}
                onClick={() => {
                    setPassword({
                        oldPassword: "",
                        newPassword: "",
                        confirmPassword: ""
                    })
                    setIsUpdatePassword(true)
                }}
            >
                {"Update Password"}
            </span>
        </>
    )
}

export default PasswordBox