import { useState, useEffect } from 'react';

import {
    TaskItemContainer,
    TaskDetails,
    TaskNo,
    TaskTitleDesc,
    TaskTitleText,
    TaskDescText,
    TaskAction,
    Normal,
    Submited,
    UnderReview,
    Verified,
    ArrowToggleIcon,
    TaskTime,
    VerifiedIcon,
    SubmitedIcon,

    NameFieldTextContainer,
    VideoUrlContainer,
    DocumentTableContainer,
    SelectSlotContainer,
    ReferencesCardContainer,
} from './TutorOnboardStyle';

import OtpBoxContainer from "pages/auth/tutorOnboard/components/OtpBoxContainer"
import VideoContainer from 'pages/auth/tutorOnboard/components/VideoContainer';
import InterviewSlotSelection from 'pages/auth/tutorOnboard/components/InterviewSlotSelection';
import DocumentsTable from 'pages/auth/tutorOnboard/components/DocumentsTable';
import ReferenceList from 'pages/auth/tutorOnboard/components/professionalReferences/ReferenceList'
import {
    fileCategory,
    onBoardingEventStatus,
    onBoardingStatus
} from 'pages/auth/tutorOnboard/TutorOnboardData';

import {
    createPersonalDetail,
    createDocument,
    createReference
} from "redux/tutorOnboarding/tutorOnboarding.request"

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';
import {
    setClearAddReference,
    setAddReferencePayload,

    setAddPersonalDetailPayload,

    setAddDocumentPayload,
    setClearAddDocument,

    setDocumentCategoryList,
    setClearDocumentCategoryList,
} from "redux/tutorOnboarding/tutorOnboarding.slice"

const TaskItem = ({ index, task, calculatePctValue }) => {
    const { personalDetail, addPersonalDetail, addReference, addDocument, categoryList } = useAppState((s) => s.tutorOnboarding)
    const { user } = useAppState((s) => s.user)
    const { modal } = useAppState(s => s.local)
    const dispatcher = useAppDispatcher()

    const [openOtpBox, setOpenOtpBox] = useState(false)
    const [openVideoBox, setVideoBox] = useState(false)
    const [clickedItemIndex, setClickedItemIndex] = useState(null);
    const [isOpenDocumentTable, setIsOpenDocumentTable] = useState(false)
    const [openSlotBox, setOpenSlotBox] = useState(false)
    const [isOpenReferenceList, setIsOpenReferenceList] = useState(false)

    const arrowToggleVariants = {
        collapsed: { rotate: 0 },
        open: { rotate: 90 }
    }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    const isOpen = clickedItemIndex === index;

    useEffect(() => {
        calculatePctValue()
    }, [task.status])

    useEffect(() => {
        if (task.taskNo === 3 && addPersonalDetail?.addPersonalDetailPayload?.isFormSubmitted === true) {
            dispatcher(createPersonalDetail())
            dispatcher(setAddPersonalDetailPayload({
                ...addPersonalDetail?.addPersonalDetailPayload,
                isFormSubmitted: false
            }))
        }
    }, [addPersonalDetail?.addPersonalDetailPayload.isFormSubmitted])

    useEffect(() => {
        if (task.taskNo === 3 && addPersonalDetail?.addPersonalDetail) {
            task.status = onBoardingStatus.SUBMITTED.value
            dispatcher(setModal({
                ...modal,
                [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: false
            }))
            // dispatcher(setClearAddPersonalDetail())
        }
    }, [addPersonalDetail?.addPersonalDetail])

    useEffect(() => {
        if (task.taskNo === 7 && addDocument?.addDocumentPayload?.isDocumentSubmitted) {
            dispatcher(createDocument())
            dispatcher(setAddDocumentPayload({
                ...addDocument?.addDocumentPayload,
                isDocumentSubmitted: false
            }))
        }
    }, [addDocument?.addDocumentPayload?.isDocumentSubmitted])

    useEffect(() => {
        if (task.taskNo === 7 && addDocument?.addDocument && !categoryList?.categoryList?.resumeTask) {
            task.status = onBoardingStatus.SUBMITTED.value
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
            }))
            dispatcher(setClearAddDocument())
        }
    }, [addDocument?.addDocument])

    useEffect(() => {
        if (task.taskNo === 8 && addReference?.addReferencePayload?.isFormSubmitted === true) {
            dispatcher(createReference())
            dispatcher(setAddReferencePayload({
                ...addReference?.addReferencePayload,
                isFormSubmitted: false
            }))
        }
    }, [addReference?.addReferencePayload?.isFormSubmitted])

    useEffect(() => {
        if (task.taskNo === 8 && addReference?.addReference) {
            task.status = onBoardingStatus.SUBMITTED.value
            dispatcher(setModal({
                ...modal,
                [modalConst.REFERENCE_FORM_MODAL.stateKey]: false
            }))
            dispatcher(setClearAddReference())
        }
    }, [addReference?.addReference])

    const handleTaskItems = () => {
        if (task.taskNo === 1 && openOtpBox) {
            setOpenOtpBox(false)
        }
        if (task.taskNo === 1 && !openOtpBox) {
            if ((task.status === onBoardingStatus.NOT_SUBMITTED.value || task.status === onBoardingStatus.SUBMITTED.value) && !user?.user?.isEmailVerified) {
                setOpenOtpBox(!openOtpBox)
                alert("Code Sent Succeessfully!")
            }
        }
        if (task.taskNo === 2 && openOtpBox) {
            setOpenOtpBox(false)
        }
        if (task.taskNo === 2 && !openOtpBox) {
            if ((task.status === onBoardingStatus.NOT_SUBMITTED.value || task.status === onBoardingStatus.SUBMITTED.value) && !user?.user?.isMobileVerified) {
                setOpenOtpBox(!openOtpBox)
                alert("Code Sent Successfully!")
            }
        }
        if (task.taskNo === 3) {
            dispatcher(setClearAddDocument())
            dispatcher(setAddPersonalDetailPayload({
                ...personalDetail?.personalDetail,
                isFormSubmitted: false
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: true
            }))
        }
        if (task.taskNo === 4) {
            setClickedItemIndex(isOpen ? null : index)
            setVideoBox(!openVideoBox)
        }
        if (task.taskNo === 5) {
            dispatcher(setModal({
                ...modal,
                [modalConst.QUESTION_ANSWER_MODAL.stateKey]: true
            }))
        }
        if (task.taskNo === 6) {
            setClickedItemIndex(isOpen ? null : index)
            setOpenSlotBox(!openSlotBox)
            if (task.status === onBoardingEventStatus.NOT_SUBMITTED.value) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]: true
                }))
            }
        }
        if (task.taskNo === 7) {
            if (task.status === onBoardingStatus.NOT_SUBMITTED.value) {
                dispatcher(setClearAddDocument())
                dispatcher(setClearDocumentCategoryList())
                dispatcher(setDocumentCategoryList({
                    categoryListItems: fileCategory,
                    resumeTask: false
                }))
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
                }))
            }
            if (task.status === onBoardingStatus.SUBMITTED.value) {
                setClickedItemIndex(isOpen ? null : index)
                setIsOpenDocumentTable(!isOpenDocumentTable)
            }
        }
        if (task.taskNo === 8) {
            if (task.status === onBoardingStatus.NOT_SUBMITTED.value) {
                dispatcher(setClearAddReference())
                dispatcher(setModal({
                    ...modal,
                    [modalConst.REFERENCE_FORM_MODAL.stateKey]: true
                }))
            }
            if (task.status === onBoardingStatus.SUBMITTED.value) {
                setClickedItemIndex(isOpen ? null : index)
                setIsOpenReferenceList(!isOpenReferenceList)
            }
        }
    }

    return (
        <TaskItemContainer index={index}>
            <TaskDetails>
                <TaskNo>{task.taskNo}</TaskNo>
                <TaskTitleDesc>
                    <TaskTitleText>{task.title}</TaskTitleText>
                    <TaskDescText>{task.description}</TaskDescText>
                </TaskTitleDesc>
            </TaskDetails>
            <TaskAction>
                {task.status === onBoardingStatus.NOT_SUBMITTED.value &&
                    <Normal taskNo={task.taskNo}
                        onClick={handleTaskItems}>
                        {task.btnName[task.status]}
                    </Normal>
                }
                {((task.status === onBoardingStatus.SUBMITTED.value)
                    || task.status === onBoardingEventStatus.SCHEDULED.value) &&
                    <Submited onClick={handleTaskItems}>
                        <SubmitedIcon />
                        {task.btnName[task.status]}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </Submited>
                }
                {((task.status === onBoardingStatus.UNDER_REVIEW.value)
                    || task.status === onBoardingEventStatus.SCHEDULED.value) &&
                    <UnderReview onClick={handleTaskItems}>
                        <SubmitedIcon />
                        {task.btnName[task.status]}
                        <ArrowToggleIcon
                            variants={arrowToggleVariants}
                            initial={'collapsed'}
                            animate={isOpen ? 'open' : 'collapsed'}
                            transition={transition}
                            className={"cursor-pointer"}
                        />
                    </UnderReview>
                }
                {task.status === onBoardingStatus.VERIFIED.value &&
                    <Verified>
                        <VerifiedIcon />
                        {task.btnName[task.status]}
                    </Verified>
                }
                <TaskTime>{task.taskTime}</TaskTime>
            </TaskAction>
            {openOtpBox &&
                <NameFieldTextContainer>
                    <OtpBoxContainer
                        task={task}
                        openOtpBox={openOtpBox}
                        closeOtpContainer={() => setOpenOtpBox(false)}
                    />
                </NameFieldTextContainer>
            }

            {/* upload resume button */}
            {/* {isOpenResumeContainer &&
                <PersonalDetailContainer>
                    <PersonalDetailForm task={task} />
                </PersonalDetailContainer>
            } */}

            {/* upload video */}
            {openVideoBox &&
                <VideoUrlContainer>
                    <VideoContainer task={task} />
                </VideoUrlContainer>
            }

            {/* select time slot */}
            {openSlotBox &&
                <SelectSlotContainer>
                    <InterviewSlotSelection task={task} />
                </SelectSlotContainer>
            }

            {/* upload documents */}
            {isOpenDocumentTable &&
                <DocumentTableContainer>
                    <DocumentsTable task={task} />
                </DocumentTableContainer>
            }

            {/* upload professional reference */}
            {isOpenReferenceList &&
                <ReferencesCardContainer>
                    <ReferenceList task={task} />
                </ReferencesCardContainer>
            }
        </TaskItemContainer>
    )
}

export default TaskItem;