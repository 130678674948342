import { logInPayload, signUpPayload } from "redux/auth/auth.const";

export const AUTH_INITIAL_STATE = {
    logInSignUp: {
        isLoading: false,
        isLogedIn: false,
        accessToken: null,
        errorMsg: null,
        logInPayload: logInPayload,
        signUpPayload: signUpPayload
    },

    verifyReferral: {
        isLoading: false,
        verifyReferral: null,
        errorMsg: null
    },

    logout: {
        isLoading: false,
        logout: null,
        errorMsg: null
    }
}