export const url = {
    COURSE_LIST: "/courses",
    COURSE_PUBLIC_PROFILE_BY_USER_ID: "/courses/{courseId}",
    COURSE_TUTOR_USER_PUBLIC_LIST: "/courses/tutors/users/{userId}",
    MY_COURSE_USER_LIST: "/courses/users/{userId}/my-courses",
    FAV_COURSE_USER: "/courses/users/{userId}/fav-courses",

}

export const courseStatus = {
    ALL: {
        value: 'all',
        label: 'All',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    DRAFT: {
        value: 'draft',
        label: 'Draft',
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    UNDER_REVIEW: {
        value: 'under_review',
        label: 'Under Review',
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    PUBLISHED: {
        value: 'published',
        label: 'Published',
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    INACTIVE: {
        value: 'inactive',
        label: 'Inactive',
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const courseType = {
    ALL: {
        label: "All",
        value: "all"
    },
    ONE_ON_ONE: {
        label: "One on One",
        value: "one_on_one"
    },
    GROUP: {
        label: "Group",
        value: "group"
    }
}

export const filterName = {
    FIND_COURSES: {
        value: "find_courses"
    },
    ALL_COURSES: {
        value: "all_courses"
    },
    FAV_COURSES: {
        value: "fav_courses"
    }
}

export const courseFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    courseType: courseType.ALL.value,
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: ""
}

