import { useState } from "react";
import clsx from 'clsx';

import { FiEdit } from 'react-icons/fi';

import { companyEditBtn } from "pages/auth/editCompany/data";
import SaveAndCancelBtn from "pages/auth/editCompany/components/saveAndCancelBtn/SaveAndCancelBtn";

import { updateCompanyPublicProfileByCompanyId } from "redux/company/company.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const MIN_CHARACTER_LENGTH = 50
const MAX_CHARACTER_LENGTH = 700

const EditAbout = ({ editBtnConst, setEditBtnConst }) => {
    const { companyPublicProfile } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    const [inputBio, setInputBio] = useState("")
    const [companyAboutLength, setCompanyAboutLength] = useState(180)

    const handleSeeMoreAbout = () => {
        setCompanyAboutLength(companyPublicProfile?.companyPublicProfile?.bio?.length)
    }
    const handleSeeLessAbout = () => {
        setCompanyAboutLength(180)
    }

    const onHandleSave = (value) => {
        if (!(inputBio.length >= MIN_CHARACTER_LENGTH && inputBio.length <= MAX_CHARACTER_LENGTH)) {
            alert("Please enter between 50 to 700 characters")
            return;
        }

        dispatcher(updateCompanyPublicProfileByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId, value))
        setEditBtnConst(null)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"ABOUT"}>
            <div className={"relative max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"p-5 space-y-3 bg-white"}>
                    <div className={"flex items-center justify-start gap-5"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"About"}
                        </span>
                        {!editBtnConst &&
                            <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                onClick={() => {
                                    setInputBio(companyPublicProfile?.companyPublicProfile?.bio || "")
                                    setEditBtnConst(companyEditBtn.bio.value)
                                }}
                            />
                        }
                    </div>
                    {editBtnConst !== companyEditBtn.bio.value &&
                        <span className={clsx(
                            "font-bodyPri font-normal text-text-700 text-base"
                        )}>
                            {companyPublicProfile?.companyPublicProfile?.bio}
                            {/* <span
                                className={"text-secondary-main cursor-pointer"}
                                onClick={handleSeeMoreAbout}
                            >
                                {"....see more"}
                            </span>
                            {companyPublicProfile?.companyPublicProfile?.bio?.length <= companyAboutLength &&
                                <span
                                    className={"text-secondary-main cursor-pointer"}
                                    onClick={handleSeeLessAbout}
                                >
                                    {" see less"}
                                </span>
                            } */}
                        </span>
                    }
                    {editBtnConst === companyEditBtn.bio.value &&
                        <div className="w-full flex flex-col items-start justify-start gap-2">
                            <div className="w-full flex flex-col justify-center gap-1">
                                <textarea
                                    className="w-full p-5 border-2 border-secondary-main border-dashed focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                                    rows={5}
                                    minLength={50}
                                    maxLength={700}
                                    value={inputBio}
                                    onChange={(event) => setInputBio(event.target.value)}
                                />
                            </div>
                            <div className={"w-full flex justify-between"}>
                                <span className="font-bodyPri font-normal tracking-wide text-red-500 text-xs">
                                    {"Please enter between 50 and 700 characters"}
                                </span>
                                <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                                    {inputBio.length + "/" + MAX_CHARACTER_LENGTH}
                                </span>
                            </div>
                            <SaveAndCancelBtn
                                onCancel={() => setEditBtnConst(null)}
                                onSave={() => onHandleSave(inputBio)}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditAbout;