export const url = {
    MY_STUDENT_USER_LIST: "/students/users/{userId}/my-students",
    FAV_STUDENT_USER_LIST: "/students/users/{userId}/fav-students",
    STUDENT_USER_DETAIL: "/students/users/{userId}/details",
    STUDENT_USER_LANGUAGE_LIST: "/students/users/{userId}/languages",
    STUDENT_LANGUAGE_DETAIL: "/students/languages/{studentLanguageId}",
    STUDENT_USER_CATEGORY_LIST: "/students/users/{userId}/categories",
    STUDENT_CATEGORY_DETAIL: "/students/categories/{studentCategoryId}",
}

export const studentFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    courseType: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: ""
}

export const filterName = {
    ALL_STUDENTS: {
        value: "all_students"
    },
    FAV_STUDENTS: {
        value: "fav_students"
    }
}

