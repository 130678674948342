import dayjs from "dayjs";


const BookingItem = ({ bookingItem }) => {
    return (
        <div className={"space-y-1"}>
            <div className="flex flex-row items-center justify-between">
                <span className={"font-bodyPri font-medium text-base text-text-900 truncate"}>
                    {bookingItem.course.courseTitle}
                </span>
                <div className={"flex items-center justify-center gap-1"}>
                    <span className={'font-bodyPri font-normal text-sm text-primary-main'}>
                        {"USD "}
                    </span>
                    <span className={'font-bodyPri font-normal text-sm text-primary-main'}>
                        {parseFloat(bookingItem.purchasedPrice / 100).toFixed(2)}
                    </span>
                </div>
            </div>
            <div className={"flex flex-row items-center justify-between"}>
                <span className={"font-bodyPri font-base text-sm text-text-900"}>
                    {dayjs(bookingItem.createdAt).local().format('DD/MM/YY hh:mm a').toString()}
                </span>
                <span className={'font-bodyPri font-normal text-sm text-text-900 capitalize'}>
                    {bookingItem.status}
                </span>
            </div>
        </div>
    )
}

export default BookingItem;