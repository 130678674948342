export default function chatName(selectedChat, user) {
  if (selectedChat.type === "group") {
    const { name } = selectedChat;
    return name;
  }

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== user.userId
  )[0];
  const userDetails = selectedChat[otherUser];
  const { name } = userDetails;
  return name;
}
