export const url = {
    MAKE_PAYMENT: "/payments/make-payment",
}

export const paymentType = {
    BUY_COURSE: {
        type: 'buy_course'
    },
    BUY_COURSE_AS_GUEST: {
        type: 'buy_course_as_guest'
    },
    ADD_CREDIT_TO_WALLET: {
        type: 'add_credit_to_wallet'
    },
    BUY_GIFT_CARD: {
        type: 'buy_gift_card'
    },
    REEDEME_GIFT_CARD: {
        type: 'reedeme_gift_card'
    },
}

export const paymentMethodStatus = {
    SUCCEEDED: {
        label: 'Succeeded',
        value: 'succeeded',
        lightColor: '',
        darkColor: '',
    },
    PROCESSING: {
        label: 'Processing',
        value: 'processing',
        lightColor: '',
        darkColor: '',
    },
    CANCELED: {
        label: 'Cenceled',
        value: 'canceled',
        lightColor: '',
        darkColor: '',
    },
    REQUIRES_PAYMENT_METHOD: {
        label: 'Requires Payment Method',
        value: 'requires_payment_method',
        lightColor: '',
        darkColor: '',
    },
    requires_confirmation: {
        label: 'Requires Confirmation',
        value: 'requires_confirmation',
        lightColor: '',
        darkColor: '',
    },
    REQUIRES_CAPTURE: {
        label: 'Requires Capture',
        value: 'requires_capture',
        lightColor: '',
        darkColor: '',
    },
    REQUIRES_ACTION: {
        label: 'Requires Action',
        value: 'requires_action',
        lightColor: '',
        darkColor: '',
    },
}