import { toast } from "react-toastify"

import offeringListService from "redux/offering/offering.service"
import {
    setOfferingListLoading,
    setOfferingList,
    setOfferingListErrorMsg,

    setOfferingCourseLoading,
    setOfferingCourse,
    setOfferingCourseErrorMsg,

    setAddOfferingLoading,
    setAddOffering,
    setAddOfferingErrorMsg
} from "redux/offering/offering.slice"

export const getOfferingList = (query) => async (dispatch) => {
    dispatch(setOfferingListLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", bookingType: "regular", ...query }
        }
        let response = await offeringListService.getOfferingList(requestData)
        if (response.status === 200) {
            dispatch(setOfferingList(response.data))
        }
        else {
            dispatch(setOfferingListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error)
        dispatch(setOfferingListErrorMsg(error.message))
    } finally {
        dispatch(setOfferingListLoading(false))
    }
}

export const getOfferingByCourseId = (courseId) => async (dispatch) => {
    dispatch(setOfferingCourseLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            query: { roleStatus: "active", bookingType: "regular" }
        }
        const response = await offeringListService.getOfferingByCourseId(requestData)

        if (response.status === 200) {
            dispatch(setOfferingCourse(response.data))
        }
        else {
            dispatch(setOfferingCourseErrorMsg(response.statusText))
        }
    } catch (error) {
        dispatch(setOfferingCourseErrorMsg(error.message))
    } finally {
        dispatch(setOfferingCourseLoading(false))
    }
}

export const createOffering = (tempCourseId) => async (dispatch, getState) => {
    dispatch(setAddOfferingLoading(true))

    try {
        const requestData = {
            body: { tempCourseId: tempCourseId }
        }
        const response = await offeringListService.createOffering(requestData)
        if (response.status === 200) {
            dispatch(setAddOffering(response.data))
        }
        else {
            dispatch(setAddOfferingErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error)
        dispatch(setAddOfferingErrorMsg(error.message))
    } finally {
        dispatch(setAddOfferingLoading(false))
    }
}
export const updateOfferingByCourseId = (courseId, body) => async (dispatch) => {
    dispatch(setOfferingListLoading(true))

    try {
        const requestData = {
            params: { courseId: courseId },
            body: body
        }
        const response = await offeringListService.updateOfferingByCourseId(requestData)
        if (response.status === 200) {
            dispatch(setOfferingCourse(response.data.data))
            toast.success(response.data.message)
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setOfferingListErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.message)
    } finally {
        dispatch(setOfferingListLoading(false))
    }
}