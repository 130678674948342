import { useState, useEffect } from "react";
import clsx from "clsx";

import { FaSpinner } from "react-icons/fa";

import SelectSearch, { fuzzySearch } from "react-select-search";

import SaveAndCancelBtn from "pages/auth/profile/SaveAndCancelBtn";

import { getMasterCityList, getMasterCountryList, getMasterStateList } from 'redux/master/master.request';
import { updateUserCultureDetailByUserId } from "redux/user/user.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const LivingInDetail = ({ setEditBtnConst }) => {
    const { user, userCultureDetail } = useAppState((state) => state.user)
    const { countryList, stateList, cityList } = useAppState((s) => s.master)

    const dispatcher = useAppDispatcher()

    const [livingInLocationName, setLivingInLocationName] = useState({
        countryDetail: {
            masterCountryId: 0,
            country: "",
            countryDomain: "",
            isdCode: ""
        },
        stateDetail: {
            masterStateId: 0,
            state: ""
        },
        cityDetail: {
            masterCityId: 0,
            city: ""
        }
    })

    useEffect(() => {
        dispatcher(getMasterCountryList())
    }, [])

    useEffect(() => {
        if (livingInLocationName?.countryDetail?.masterCountryId) {
            dispatcher(getMasterStateList(livingInLocationName?.countryDetail?.masterCountryId))
        }
        if (livingInLocationName?.stateDetail?.masterStateId) {
            dispatcher(getMasterCityList(livingInLocationName?.stateDetail?.masterStateId))
        }
    }, [livingInLocationName?.countryDetail?.masterCountryId || livingInLocationName?.stateDetail?.masterStateId])

    useEffect(() => {
        if (userCultureDetail?.userCultureDetail?.liveInCountry) {
            setLivingInLocationName({
                ...livingInLocationName,
                countryDetail: userCultureDetail?.userCultureDetail?.liveInCountry
            })
        }
        if (userCultureDetail?.userCultureDetail?.liveInState) {
            setLivingInLocationName({
                ...livingInLocationName,
                stateDetail: userCultureDetail?.userCultureDetail?.liveInState
            })
        }
        if (userCultureDetail?.userCultureDetail?.liveInCity) {
            setLivingInLocationName({
                ...livingInLocationName,
                cityDetail: userCultureDetail?.userCultureDetail?.liveInCity
            })
        }
    }, [userCultureDetail?.userCultureDetail?.liveInCountry || userCultureDetail?.userCultureDetail?.liveInState || userCultureDetail?.userCultureDetail?.liveInCity])

    const onSelectCountry = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option)
        setLivingInLocationName({
            ...livingInLocationName,
            countryDetail: selectedCountry
        })
    }

    const onSelectState = (option) => {
        const selectedState = stateList?.stateList?.find((state) => state?.masterStateId === option)
        setLivingInLocationName({
            ...livingInLocationName,
            stateDetail: selectedState
        })
    }

    const onSelectCity = (option) => {
        const selectedCity = cityList?.cityList?.find((city) => city?.masterCityId === option)
        setLivingInLocationName({
            ...livingInLocationName,
            cityDetail: selectedCity
        })
    }

    const onSaveLivingInLocation = () => {
        dispatcher(updateUserCultureDetailByUserId(
            user?.user?.userId,
            {
                liveInMasterCountryId: livingInLocationName?.countryDetail?.masterCountryId,
                liveInMasterStateId: livingInLocationName?.stateDetail?.masterStateId,
                liveInMasterCityId: livingInLocationName?.cityDetail?.masterCityId
            }
        ))
        setEditBtnConst(null)
    }

    return (
        <div className={"col-start-1 col-span-full md:col-start-3 md:col-span-7 flex flex-col items-start justify-start gap-3"}>
            <div className={"flex flex-wrap items-center justify-start gap-3 md:flex-nowrap"}>
                {countryList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                {(!countryList?.isLoading && (countryList?.countryList || [])) &&
                    <div className={clsx(
                        "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm"
                    )}>
                        <SelectSearch
                            options={countryList?.countryList?.map((country) => ({
                                name: country?.country,
                                value: country?.masterCountryId,
                            }))}
                            search
                            filterOptions={fuzzySearch}
                            value={livingInLocationName?.countryDetail?.masterCountryId}
                            onChange={(option) => onSelectCountry(option)}
                        />
                    </div>
                }
                {stateList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                {(!stateList?.isLoading && (stateList?.stateList || [])) &&
                    <div className={clsx(
                        "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm"
                    )}>
                        <SelectSearch
                            options={stateList?.stateList?.map((state) => ({
                                name: state?.state,
                                value: state?.masterStateId,
                            }))}
                            search
                            filterOptions={fuzzySearch}
                            value={livingInLocationName?.stateDetail?.masterStateId}
                            onChange={(option) => onSelectState(option)}
                        />
                    </div>
                }
                {cityList?.isLoading &&
                    <div className={"flex items-center justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-700 text-sm whitespace-nowrap"}>
                            {"Please wait ..."}
                        </span>
                        <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    </div>
                }
                {(!cityList?.isLoading && (cityList?.cityList || [])) &&
                    <div className={clsx(
                        "w-full border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-base",
                        "placeholder:text-text-500 placeholder:text-sm"
                    )}>
                        <SelectSearch
                            options={cityList?.cityList?.map((city) => ({
                                name: city?.city,
                                value: city?.masterCityId,
                            }))}
                            search
                            filterOptions={fuzzySearch}
                            value={livingInLocationName?.cityDetail?.masterCityId}
                            onChange={(option) => onSelectCity(option)}
                        />
                    </div>
                }
            </div>
            <SaveAndCancelBtn
                onSave={() => onSaveLivingInLocation()}
                onCancel={() => setEditBtnConst(null)}
            />
        </div>
    )
}

export default LivingInDetail