import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader"

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getMyTutorUserListByUserId } from 'redux/tutor/tutor.request';
import { getMyStudentUserListByUserId } from "redux/student/student.request";
import { setClearMyTutorList } from "redux/tutor/tutor.slice"
import { setClearMyStudentList } from "redux/student/student.slice";
import { userRoles } from 'redux/auth/auth.const';

const RECORDS = 10


const UserProfileInfo = ({ userProfileInfo }: any) => {
  return (
    <div className={"flex items-center gap-5 py-2 rounded-md hover:bg-gray-50 cursor-pointer"}>
      <img src={userProfileInfo?.profilePicUrl} alt="" className={"w-10 h-10 rounded-full"} />
      <p className={"font-bodyPri font-normal text-base text-text-900"}>
        {userProfileInfo?.firstName + " " + userProfileInfo?.lastName.charAt(0)}
      </p>
    </div>
  )
}


export const MyStudents: React.FC = () => {
  const { locals } = useAppState((s: any) => s.local)
  const { user } = useAppState((s: any) => s.user)
  const { myStudentList } = useAppState((s: any) => s.student)
  const { myTutorList } = useAppState((s) => s.tutor)

  const dispatcher = useAppDispatcher()

  React.useEffect(() => {
    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(getMyTutorUserListByUserId(user?.user?.userId, { page: 1, records: RECORDS }))
    }
    if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(getMyStudentUserListByUserId(user?.user?.userId))
    }

    return () => {
      dispatcher(setClearMyTutorList())
      dispatcher(setClearMyStudentList())
    }
  }, [dispatcher, locals.userRole])

  return (
    <div className="p-5 space-y-3 w-full bg-white shadow-md rounded-lg">
      <div className="flex justify-between">
        {locals.userRole === userRoles.TUTOR.value &&
          <p className="text-xl font-medium font-bodyPri text-text-900 tracking-wider">
            {'My Students'}
          </p>
        }
        {locals.userRole === userRoles.STUDENT.value &&
          <p className="text-xl font-medium font-bodyPri text-text-900 tracking-wider">
            {'My Teachers'}
          </p>
        }
        <p className="text-sm text-text-700 font-bodyPri">
          {locals.userRole === userRoles.TUTOR.value &&
            <Link to={"/my-students"}>
              {"View all"}
            </Link>
          }
          {locals.userRole === userRoles.STUDENT.value &&
            <Link to={"/my-tutors"}>
              {"View all"}
            </Link>
          }
        </p>
      </div>
      <div className={clsx(
        "flex flex-col w-full h-80 overflow-hidden overflow-y-scroll",
        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight",
        locals.userRole === userRoles.STUDENT.value && (!myTutorList?.myTutorList || myTutorList?.myTutorList?.records === 0) && "flex justify-center items-center",
        locals.userRole === userRoles.TUTOR.value && (!myStudentList?.myStudentList || myStudentList?.myStudentList?.length === 0) && "flex justify-center items-center",
      )}>
        <ComponentLoader isLoading={myStudentList?.isLoading || myTutorList?.isLoading} />
        {locals.userRole === userRoles.STUDENT.value &&
          myTutorList?.myTutorList?.results?.map((tutor: any, index: number) => (
            <UserProfileInfo key={index} userProfileInfo={tutor} />
          ))
        }
        {locals.userRole === userRoles.TUTOR.value &&
          myStudentList?.myStudentList?.map((student: any, index: number) => (
            <UserProfileInfo key={index} userProfileInfo={student} />
          ))
        }
        {(myStudentList?.errorMsg || myTutorList?.errorMsg) &&
          <p className="font-bodyPri font-normal text-text-800 text-md text-center">
            {myStudentList?.errorMsg || myTutorList?.errorMsg}
          </p>
        }
      </div>
    </div>
  );
};