import clsx from "clsx";
import * as React from "react";
import { Button } from "components/common-components/Buttons";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setCardSelection } from "../store/GiftCardSlice";
import { MOCK_GIFT_CARD_PLANS } from "../store/mock-data";
import { GiftCardPlan } from "../store/types";

export const MINIMUM_AMOUNT = 10;
export const GiftCardSelection: React.FC = () => {
  const [anyprice, setAnyPrice] = React.useState("10");

  const dispatcher = useAppDispatcher();

  const onBuy = (card: GiftCardPlan) => {
    if (card.id === "Any") {
      const price = Number(anyprice);
      if (!isNaN(price) && price > MINIMUM_AMOUNT) {
        dispatcher(setCardSelection({ selectedCard: card, isCompleted: true }));
      } else {
        alert("Invalid price");
      }
    } else {
      dispatcher(setCardSelection({ selectedCard: card, isCompleted: true }));
    }
  };

  return (
    <div className="divide-y">
      {MOCK_GIFT_CARD_PLANS.map((card) => (
        <>
          {card.id === "Any" ? (
            <div className="px-4 py-3 my-2">
              <div className="flex items-center justify-between">
                <p className="pr-6 font-semibold text-sky-600">Any</p>
                <div className="flex ">
                  <input
                    type="text"
                    placeholder="Amount"
                    className="w-20 px-3 py-2 rounded-l-lg outline-none focus:ring-1 focus:ring-blue-300"
                    onChange={(e) => setAnyPrice(e.target.value)}
                    value={anyprice}
                  />
                  <button
                    onClick={() => onBuy(card)}
                    className={clsx(
                      "px-4 py-2 text-white bg-blue-600 rounded-r-lg outline-none",
                      "focus:ring-1 focus:ring-blue-300 ring-offset-2"
                    )}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-between px-4 py-3">
              <p className="font-bold text-sky-700">{card.name}</p>
              <div className="inline-flex items-center space-x-2">
                <p className="pr-6 font-semibold text-sky-600">${card.price}</p>
                <Button
                  name="Buy"
                  size="medium"
                  style="primary"
                  onClick={() => onBuy(card)}
                />
              </div>
            </div>
          )}
        </>
      ))}
    </div>
  );
};
