import clsx from "clsx";
import * as React from "react";

export function Toggle({
  onChange,
  value,
}: {
  value: Boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <div className="flex items-center justify-between p-2 bg-white">
      <button
        type="button"
        className={clsx(
          "relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ",
          "ease-in-out bg-gray-200 border-2 border-transparent rounded-full ",
          "cursor-pointer w-11 focus:outline-none",
          value ? "bg-green-600" : "bg-gray-200"
        )}
        role="switch"
        aria-checked="false"
        onClick={() => onChange(!value)}
      >
        <span className="sr-only">Use setting</span>

        <span
          aria-hidden="true"
          className={clsx(
            value ? "translate-x-5" : "translate-x-0",
            "inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 ",
            "bg-white rounded-full shadow pointer-events-none ring-0"
          )}
        ></span>
      </button>
    </div>
  );
}

type ToggleWithTextProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  title: string;
  description: string;
};

export function ToggleWithText({
  description,
  onChange,
  title,
  value,
}: ToggleWithTextProps) {

  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="text-base font-semibold text-gray-700">{title}</p>
        {description && (
          <p className="text-sm font-light text-gray-400">{description}</p>
        )}
      </div>
      <div>
        <Toggle onChange={(v) => onChange(v)} value={value} />
      </div>
    </div>
  );
}
