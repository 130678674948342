import { useEffect } from 'react';
import clsx from 'clsx';
import { useParams, useNavigate } from 'react-router-dom';

import { FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";

import FullPageLoader from "components/loader/FullPageLoader";

import { PageContentStyle } from './style';

import { useAppState, useAppDispatcher } from "hooks/useStore"
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { setSignUpPayload, setClearVerifyReferral } from "redux/auth/auth.slice";
import { createVerifyReferral } from "redux/auth/auth.request";

import { pagesInfo } from 'utils/pagesInfo';
import { Link } from 'react-router-dom';


function ReferalSignUpForm() {
  const { signUp, verifyReferral } = useAppState((s) => s.auth)
  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const { referralCode } = useParams()

  useEffect(() => {
    dispatcher(createVerifyReferral(referralCode))

    return () => {
      dispatcher(setClearVerifyReferral())
    }
  }, [referralCode])

  const handleSignUp = () => {
    dispatcher(setSignUpPayload({
      ...signUp?.signUpPayload,
      referralCode: verifyReferral?.verifyReferral?.referralCode
    }))
    navigate(`/sign-up`)
  }

  if (verifyReferral?.isLoading) {
    return (<FullPageLoader isLoading={verifyReferral?.isLoading} />)
  }

  return (
    <div className="px-4 rounded-lg shadow-xl bg-white w-96 py-7">
      {verifyReferral?.verifyReferral && verifyReferral?.verifyReferral?.referralCode === referralCode ?
        <h1 className="text-4xl font-semibold text-left text-gray-900">
          {verifyReferral?.verifyReferral?.firstName + " " + verifyReferral?.verifyReferral?.lastName?.charAt(0) + "."} <br />
          {"Invited you to join Edulyte!"}
        </h1>
        : <h1 className="text-center text-4xl font-bodyPri text-text-900 font-semibold">
          {"Invalid Referral Code"}
        </h1>
      }
      {verifyReferral?.errorMsg &&
      <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
      {verifyReferral?.errorMsg}
    </div>
      }
      <p className="pt-1 pb-6 text-sm text-gray-400">
        {"Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores, beatae. Sequi, nobis molestias quo porro"}
      </p>
      <div className="pb-6 space-y-2 border-b border-gray-200">
        <button
          className={clsx(
            "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
            "relative  bg-gray-200 rounded-md px-14 hover:bg-opacity-90",
            "font-semibold text-slate-700 whitespace-nowrap"
          )}
        >
          <span className="absolute left-4">
            <FcGoogle size={20} />
          </span>
          {" Sign Up With Google"}
        </button>
        <button
          className={clsx(
            "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
            "relative  bg-gray-200 rounded-md px-14 hover:bg-opacity-90",
            "font-semibold text-slate-700 whitespace-nowrap"
          )}
        >
          <span className="absolute left-4">
            <FaFacebook size={20} className="text-blue-600" />
          </span>
          {"Sign Up With Facebook"}
        </button>
      </div>
      <div className="w-10/12 mx-auto bg-gray-100 h-0.5" />
      <button
        className="w-full px-4 py-3 mt-6 text-white bg-blue-600 rounded-sm"
        onClick={handleSignUp}
      >
        {" Sign up with email"}
      </button>
      <div className="my-2 text-center">
        <p>Already signed up?{"  "}
          <Link to={"/log-in"} className="text-base text-gray-700 underline hover:text-purple-700">
            {"Sign In"}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default function ReferalSignUpPage() {
  const { currentPageInfo } = useAppState((state: any) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
      dispatcher(setPageInfo(currentPageInfo, pagesInfo.REFERRAL_SIGN_UP))
  }, [dispatcher, currentPageInfo])
  
  return (
    <PageContentStyle>
      <ReferalSignUpForm />
    </PageContentStyle>
  )
}