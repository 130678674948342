import { useEffect } from "react";
import clsx from "clsx";

import { getImportantLinksByCompanyId } from "redux/company/company.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const ImportantLinks = () => {
  const { companyPublicProfile, importantLinksList } = useAppState((state) => state.company)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (companyPublicProfile?.companyPublicProfile?.companyId) {
      dispatcher(getImportantLinksByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId))
    }
  }, [companyPublicProfile?.companyPublicProfile?.companyId])

  return (
    <div className={"flex items-center justify-center px-5 md:px-0"} id={"LINKS"}>
      <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
        <div className={"p-5 bg-white space-y-3"}>
          {/* {isProFeatureEnabled &&
            <div className={clsx(
              "w-8 flex item-center justify-center px-1 py-0.5 rounded-md cursor-pointer",
              "font-buttons font-normal text-sm text-text-50 bg-red-600",
              "hover:bg-red-700"
            )}
              onClick={() => alert("This is pro feature! buy plan to unlock this feature")}
            >
              <span>{"Pro"}</span>
            </div>
          } */}
          <div className={"font-bodyPri font-semibold text-text-800 text-xl"}>
            {"Important Links"}
          </div>
          <div className={"grid grid-cols-1 md:grid-cols-2 gap-5 p-5"}>
            {importantLinksList?.importantLinksList?.length > 0 &&
              importantLinksList?.importantLinksList?.map((link, index) => (
                <a key={index} href={link?.link} target={"_blank"}>
                  <div className={clsx(
                    "col-span-1 flex justify-start items-center gap-5",
                    "border border-text-500 p-3 rounded",
                    "group hover:bg-primary-light hover:border-primary-main cursor-pointer"
                  )}>
                    <div className={"w-5 h-5 overflow-hidden"}>
                      <img
                        src={link?.logoUrl}
                        alt={"brand-logo"}
                        className={"w-full h-full object-cover"}
                      />
                    </div>
                    <span className={"font-bodyPri font-normal text-primary-main text-sm cursor-pointer group-hover:underline"}>
                      {link?.name}
                    </span>
                  </div>
                </a>
              ))}
            {importantLinksList?.importantLinksList?.length === 0 &&
              <span className={"font-bodyPri font-normal text-text-800 text-md"}>
                {"No links added yet!"}
              </span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImportantLinks;