import React, { useState } from "react";

//Firebase imports
import { Timestamp } from "firebase/firestore";

//Context imports
import { useUser } from "../../contexts/userContext";
import { useChat } from "../../contexts/chatContext";

//Helper functions
import sendNotification from "../../helpers/sendNotification";
import { shareCourseError } from "../../helpers/inputValidation";

//Component imports
import SelectCourse from "../SelectCourse/SelectCourse";
import Button from "../presentationcomponents/Button/Button";
import sendMessage from "../../helpers/sendMessage";

//Style imports
import {
  StyledShareCourseModalContent,
  StyledShareCourseModal,
  StyledShareCourseModalHeader,
  StyledError,
} from "./ShareCourse.styles";
import Input from "../presentationcomponents/Input/Input";

export default function ShareCourse({ setShareCourse }) {
  const { user } = useUser();
  const { selectedChat, groupMembers, courses } = useChat();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { userId } = user;
  const userName = `${user.firstName} ${user.lastName}`;

  const isGroup = selectedChat.type === "group";
  const otherChatUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  async function shareCourse() {
    const { valid, shareErrors } = shareCourseError(
      selectedCourse,
      description
    );
    if (!valid) {
      setErrors(shareErrors);
      return;
    }
    try {
      setErrors({});
      setLoading(true);
      const messageObject = {
        course: selectedCourse.classTitle,
        sender: userId,
        sentAt: Timestamp.fromDate(new Date()),
        courseUrl: selectedCourse.link,
        classCoverImageUrl: selectedCourse.classCoverImageUrl,
        type: "shareCourse",
        description: description,
        status: "sent",
      };

      const individualNotificationObject = {
        fromName: userName,
        fromId: userId,
        receiver: otherChatUser,
        type: "course",
        sentAt: Timestamp.fromDate(new Date()),
        seen: false,
        chatId: selectedChat.id,
        chatType: selectedChat.type,
      };

      const groupNotificationObject = {
        sentAt: Timestamp.fromDate(new Date()),
        type: "course",
      };

      const notificationObject = isGroup
        ? groupNotificationObject
        : individualNotificationObject;

      await sendMessage(messageObject, selectedChat);
      await sendNotification(
        notificationObject,
        selectedChat,
        groupMembers,
        user
      );
      setLoading(false);
      setShareCourse(false);
    } catch (err) {
      setLoading(false);
      setErrors({ ...errors, submit: "Database Error! Try again" });
      console.log(err);
    }
  }

  function closeShareCourseModal() {
    setShareCourse(false);
  }

  function handleComments(e) {
    setErrors({ ...errors, description: "" });
    setDescription(e.target.value);
  }

  return (
    <StyledShareCourseModal>
      <StyledShareCourseModalHeader>Share Course</StyledShareCourseModalHeader>
      <SelectCourse
        courses={courses}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        errors={errors}
        setErrors={setErrors}
      />
      {errors && errors.selectedCourse && (
        <StyledError>{errors.selectedCourse}</StyledError>
      )}
      <StyledShareCourseModalContent>
        <Input
          placeholder="Description"
          onChange={handleComments}
          type="text"
          value={description}
        />
      </StyledShareCourseModalContent>
      {errors && errors.description && (
        <StyledError>{errors.description}</StyledError>
      )}
      <StyledShareCourseModalContent>
        <Button
          variant="primary"
          text="Share Course"
          onClick={shareCourse}
          loading={loading}
          disabled={loading}
        />
      </StyledShareCourseModalContent>

      <StyledShareCourseModalContent>
        <Button
          variant="secondary"
          text="Cancel"
          onClick={closeShareCourseModal}
        />
      </StyledShareCourseModalContent>
      {errors && errors.submit && <StyledError>{errors.submit}</StyledError>}
    </StyledShareCourseModal>
  );
}
