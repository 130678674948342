import { useNavigate } from 'react-router-dom';

import { BsFillCheckCircleFill } from 'react-icons/bs';

import { Container } from "pages/auth/walletConfirmation/WalletConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';


const SuccessWalletConfirmation = () => {
    const { walletConfirmation } = useAppState((state) => state.wallet)

    const navigate = useNavigate()

    const pageRedirect = () => {
        navigate(pagesInfo.FIND_COURSES.pagePath, { replace: true })
    }

    return walletConfirmation?.walletConfirmation ? (
        <Container className={"!shadow-all-md"}>
            <BsFillCheckCircleFill className={"text-8xl text-green-500"} />
            <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
                <span>{"USD"}</span>
                <span>{parseFloat(walletConfirmation?.walletConfirmation?.amount / 100).toFixed(2)}</span>
            </div>
            <div className={"flex flex-col items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"You have added credit to you wallet"}
                </span>
                <span className={"font-bodyPri font-semibold text-lg text-green-500 tracking-wider"}>
                    {"Successfully"}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-2"}>
                <span className={"font-bodyPri font-normal text-base text-800"}>
                    {"Transaction ID:"}
                </span>
                <span className={"font-bodyPri font-semibold text-md text-primary-dark"}>
                    {walletConfirmation?.walletConfirmation?.virtualTxnId}
                </span>
            </div>
            <div onClick={pageRedirect}>
                <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                    {"Buy a Lesson"}
                </span>
            </div>
        </Container>
    ) : null
}

export default SuccessWalletConfirmation