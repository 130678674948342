import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    PageContentStyle,
    SignUpContainer,
    SignUpCardContainer,
    ImageContainer
} from './SignUpPageStyle';

import SignUpCard from './signUpCard/SignUpCard';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';

const signUpOneImageUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/auth-page/sign-up-one.png"


const SignUpPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { logInSignUp } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const from = location.state?.from?.pathname || pagesInfo.DASHBOARD.pagePath;

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.SIGN_UP))
    }, [dispatcher, currentPageInfo])

    if (logInSignUp?.isLogedIn) {
        navigate(from, { replace: true });
    }

    return (
        <PageContentStyle>
            <div className='-ml-[34rem] w-1/2 h-screen bg-primary-dark rounded-br-full'>
            </div>
            <div className='-mr-[34rem] w-1/2 h-screen bg-secondary-dark rounded-tl-full'>
            </div>
            <SignUpContainer>
                <SignUpCardContainer>
                    <SignUpCard />
                </SignUpCardContainer>
                <ImageContainer>
                    <img src={signUpOneImageUrl} alt={"sign-up-one"} className={"w-full h-full object-cover"} />
                </ImageContainer>
            </SignUpContainer>
        </PageContentStyle>
    )
}
export default SignUpPage;