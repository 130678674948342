import { weeklyTimeSlot, chooseTimeSlotAction, bookingType } from "redux/booking/booking.const"
import { bookingFilterProps } from "redux/booking/booking.const"

const addBookingPayload = {
    course: null,
    coursePrice: 0,
    purchasedPrice: 0,
    coursePctDiscount: 0,
    volumePctDiscount: 0,
    tutorPctDiscount: 0,
    offerPctDiscount: 0,
    sessionDuration: 60,
    noOfSessions: 5,
    confirm: false,
    chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot,
    bookingType: bookingType.REGULAR.value,
    isWalletAdded: true,
    walletAmountToPay: 0,
    gatewayAmountToPay: 0,
}

const chooseTimeSlotPayload = {
    chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
    actionItem: {
        courseId: null,
        eventId: null,
        bookingId: null,
    },
    sessionDuration: 60,
    noOfSessions: 5,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot,
}

export const BOOKING_INITIAL_STATE = {
    bookingList: {
        isLoading: false,
        bookingList: null,
        filteredBookingList: null,
        errorMsg: null
    },

    bookingDetail: {
        isLoading: false,
        bookingDetailPayload: null,
        bookingDetail: null,
        errorMsg: null
    },

    addBooking: {
        isLoading: false,
        addBookingPayload: addBookingPayload,
        chooseTimeSlotPayload: chooseTimeSlotPayload,
        addBooking: null,
        errorMsg: null
    },

    bookingConfirmation: {
        isLoading: false,
        bookingConfirmation: null,
        errorMsg: null
    },

    updateBooking: {
        isLoading: false,
        updateBooking: null,
        errorMsg: null
    },

    filterProps: bookingFilterProps
}