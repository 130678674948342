const userGeneralDetailPayload = {
    email: "",
    password: "",
    mobileNo: "",
    isdCode: "",
    isOnlyVerifyEmail: false,
    isOnlyVerifyMobileNo: false,
}

export const USER_INITIAL_STATE = {
    user: {
        isLoading: false,
        user: null,
        errorMsg: null
    },

    userDetail: {
        isLoading: false,
        userDetail: null,
        errorMsg: null
    },
    addUserDetail: {
        isLoading: null,
        errorMsg: null
    },

    userCultureDetail: {
        isLoading: false,
        userCultureDetail: null,
        errorMsg: null
    },
    addUserCultureDetail: {
        isLoading: false,
        errorMsg: null
    },

    userEducationList: {
        isLoading: false,
        userEducationList: null,
        errorMsg: null
    },
    addUserEducationDetail: {
        isLoading: false,
        errorMsg: null
    },

    userOtherPref: {
        isLoading: false,
        userOtherPref: null,
        errorMsg: null
    },
    addUserOtherPref: {
        isLoading: false,
        errorMsg: null
    },

    userSocialMediaLinks: {
        isLoading: false,
        userSocialMediaLinks: null,
        errorMsg: null
    },
    addUserSocialMediaLinks: {
        isLoading: false,
        errorMsg: null
    },

    userGeneralDetailPayload: {
        userGeneralDetailPayload: userGeneralDetailPayload
    }
}