import { useEffect, useState, useRef } from 'react'

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { centerAspectCrop, canvasPreview } from 'components/modals/imagePickerModal/imagePickerModal.data';

import {
    base64StringToFile,
    downloadBase64File,
    extractImageFileExtensionFromBase64
} from 'utils/generators.utils';


const useDebounceEffect = (fn, waitTime, deps) => {
    useEffect(() => {
        const t = setTimeout(() => {
            fn.apply(undefined, deps)
        }, waitTime)

        return () => {
            clearTimeout(t)
        }
    }, deps)
}

const ImageCropper = ({ selectedFile, aspectRatio = 1 / 1, setCroppedFile }) => {
    const [imgSrc, setImgSrc] = useState('')
    const [crop, setCrop] = useState({ aspect: aspectRatio })
    const [aspect, setAspect] = useState(aspectRatio)
    const [completedCrop, setCompletedCrop] = useState()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)

    const imgRef = useRef()
    const previewCanvasRef = useRef()

    useEffect(() => {
        const fileReader = new FileReader()
        fileReader.addEventListener("load", () => {
            setImgSrc(fileReader.result.toString() || "")
        }, false)
        fileReader.readAsDataURL(selectedFile)
    }, [selectedFile])

    useEffect(() => {
        const canvasRef = previewCanvasRef.current
        if (canvasRef) {
            const fileExtension = extractImageFileExtensionFromBase64(imgSrc)
            const imageData64 = canvasRef.toDataURL("image/" + fileExtension)

            const fileName = selectedFile.name

            const newCroppedFile = base64StringToFile(imageData64, fileName)
            setCroppedFile(newCroppedFile)
        }
    }, [completedCrop, previewCanvasRef.current])

    useDebounceEffect(
        async () => {
            if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
                canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate)
            }
        }, 100, [completedCrop, scale, rotate],
    )

    // const handleOnDownloadClick = (event) => {
    //     event.preventDefault()
    //     const canvasRef = previewCanvasRef.current
    //     const fileExtension = extractImageFileExtensionFromBase64(imgSrc)
    //     const imageData64 = canvasRef.toDataURL("image/" + fileExtension)

    //     // const fileName = "preview." + fileExtension
    //     const fileName = selectedFile.name

    //     // // file uploaded
    //     const newCroppedFile = base64StringToFile(imageData64, fileName)
    //     setCroppedFile(newCroppedFile)

    //     // file download
    //     downloadBase64File(imageData64, fileName)
    // }

    const handleOnCropComplete = (pixelCrop, percentCrop) => {
        setCompletedCrop(pixelCrop)
    }

    const onImageLoad = (event) => {
        if (aspect) {
            const { width, height } = event.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    const handleOnCropChange = (pixelCrop, percentCrop) => {
        setCrop(pixelCrop)
    }

    return (
        <div className={"w-full block"}>
            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5"}>
                <div className={"w-full flex flex-col items-center gap-3"}>

                    <span className={"font-bodyPri font-medium text-base text-text-800"}>
                        {"Crop your image"}
                    </span>

                    <ReactCrop
                        className={"w-full col-span-1 border border-black bg-background-dark"}
                        crop={crop}
                        onChange={handleOnCropChange}
                        onComplete={handleOnCropComplete}
                        aspect={aspect}
                        keepSelection={true}
                    >
                        <img
                            ref={imgRef}
                            src={imgSrc}
                            onLoad={onImageLoad}
                            alt={"cropped-pic"}
                            className={"w-full object-contain"}
                        />
                    </ReactCrop>
                </div>
                <div className={"w-full col-span-1 flex flex-col items-center gap-3"}>

                    <span className={"font-bodyPri font-medium text-base text-text-800"}>
                        {"Preview"}
                    </span>

                    {Boolean(completedCrop) && (
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: '100%'
                                // width: completedCrop.width,
                                // height: completedCrop.height,
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ImageCropper