import ShareSocialPreview from "components/modals/shareSocialModal/ShareSocialPreview";
import { socialLinks } from "components/modals/shareSocialModal/shareSocialData";
import { useAppState } from 'hooks/useStore';

const ShareSocialModal = () => {
  const { shareSocial } = useAppState((s) => s.local)

  return (
    <div className="space-y-5 overflow-x-hidden select-none pt-5">
      <span className={"w-full flex justify-center items-center font-bodyPri font-semibold text-lg text-text-800 text-center truncate tracking-wide"}>
        {shareSocial?.socialAction.label}
      </span>
      <div className="min-h-[20rem] space-y-5 flex flex-col justify-between">
        {/* {shareSocial && <LinkPreview
          url={shareSocial?.url}
          render={({ loading, preview }) => (
            <ShareSocialPreview loading={loading} preview={{ ...preview, url: shareSocial?.url }} />
          )}
        />
        } */}
        <div className={"flex flex-col justify-center items-center gap-3"}>
          <div className="font-bodypri font-semibold text-text-900 text-md">
            {"Share Via"}
          </div>
          <div className='flex flex-wrap gap-3'>
            {socialLinks && Object.values(socialLinks).map((sociallink) => sociallink.component({ url: "", size: 50 }))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareSocialModal