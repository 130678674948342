import clsx from 'clsx';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddInterviewSlotPayload } from 'redux/tutorOnboarding/tutorOnboarding.slice';
import { weeklyTimeSlot } from "redux/tutorOnboarding/tutorOnboarding.initialState";
import { modalConst } from 'redux/local/local.const';

import { userEventList } from 'pages/auth/tutorOnboard/TutorOnboardData';
import { dayjs, timeZone } from 'utils/dateTime.utils';
import { getIsAvailable } from 'utils/availability.utils'


const ChooseTimeSlot = ({ dailyAvail, timeSlot }) => {
  const { modal } = useAppState((s) => s.local)
  const { addInterviewSlot } = useAppState((s) => s.tutorOnboarding)
  const addInterviewPayload = addInterviewSlot?.addInterviewSlotPayload

  const dispatcher = useAppDispatcher()

  const availDateTime = {
    start: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format(),
    end: dayjs(dailyAvail.date + " " + timeSlot.endTime + "+00:00").utc().format()
  }

  const isAvailable = getIsAvailable(availDateTime, userEventList)

  const handleSelect = () => {
    if (isAvailable) {
      dispatcher(setAddInterviewSlotPayload({
        ...addInterviewPayload,
        startDate: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('YYYY-MM-DD'),
        endDate: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('YYYY-MM-DD'),
        weeklyTimeSlot: {
          ...weeklyTimeSlot,
          [dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('dddd')]: [{
            startTime: timeSlot.startTime,
            endTime: timeSlot.endTime,
          }]
        }
      }))
    }
  }


  const handleConfirm = () => {
    alert("Time Slot Selected")
    dispatcher(setModal({
      ...modal,
      [modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]: false
    }))
  }

  const day = dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('dddd')
  const isSelected = dayjs(dailyAvail.date).utc().format("YYYY-MM-DD") === dayjs(addInterviewPayload?.startDate).utc().format("YYYY-MM-DD") &&
    timeSlot.startTime === addInterviewPayload?.weeklyTimeSlot[day][0].startTime &&
    timeSlot.endTime === addInterviewPayload?.weeklyTimeSlot[day][0].endTime

  return (
    <div className={"flex flex-row items-center justify-center"}>
      <span onClick={handleSelect} className={clsx(
        "px-2 py-0.5 border-2 cursor-pointer border-primary-main",
        "font-bodyPri font-medium text-sm text-primary-main rounded",
        isSelected && "!text-text-50 bg-primary-main rounded-r-none",
        !isAvailable && "text-secondary-light border-secondary-light"
      )}>
        {dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
      </span>
      {isSelected &&
        <span onClick={handleConfirm} className={clsx(
          "px-2 py-0.5 border-2 cursor-pointer border-secondary-main rounded-r",
          "font-bodyPri font-medium text-sm",
          "transition ease-in-out delay-150",
          isSelected
            ? "bg-secondary-main text-text-50"
            : "hidden"
        )}>
          {"Confirm"}
        </span>
      }
    </div>
  )
}

export default ChooseTimeSlot