import { tutorFilterProps } from "redux/tutor/tutor.const"

export const TUTOR_INITIAL_STATE = {
    tutorList: {
        isLoading: false,
        tutorList: null,
        filteredTutorList: null,
        errorMsg: null
    },

    tutorPublicProfile: {
        isLoading: false,
        tutorPublicProfile: null,
        errorMsg: null
    },

    myTutorList: {
        isLoading: false,
        myTutorList: null,
        filteredMyTutorList: null,
        errorMsg: null
    },

    favTutorList: {
        isLoading: false,
        favTutorList: null,
        filteredFavTutorList: null,
        errorMsg: null
    },

    addFavTutor: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserDetail: {
        isLoading: false,
        tutorUserDetail: null,
        errorMsg: null
    },

    addTutorUserDetail: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserPrice: {
        isLoading: false,
        tutorUserPrice: null,
        errorMsg: null
    },

    addTutorUserPrice: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserLesson: {
        isLoading: false,
        tutorUserLesson: null,
        errorMsg: null
    },

    addTutorUserLesson: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserLanguageList: {
        isLoading: false,
        tutorUserLanguageList: null,
        errorMsg: null
    },

    addTutorLanguageDetail: {
        isLoading: false,
        errorMsg: null
    },

    tutorUserCategoryList: {
        isLoading: false,
        tutorUserCategoryList: null,
        errorMsg: null
    },

    addTutorCategoryDetail: {
        isLoading: false,
        errorMsg: null
    },

    filterProps: tutorFilterProps
}