import { useState, useEffect } from 'react';

import {
    PageContentStyle,
    Header,
    Heading,
    SubHeading,
    Description,
    ProgressBarContainer,
    ProgressBarText,
    TasksContainer,
    Tasks,
    FAQContainer
} from './TutorOnboardStyle';
import { tasks, dataFAQ } from './TutorOnboardData';
import TaskItem from './TaskItem';

import ProgressBar from 'components/progressBar/ProgressBar';
import Accordion from 'components/accordion/Accordion';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

const optionalTasks = 1;


const TutorOnboardPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const [pctValue, setPctValue] = useState(parseFloat(0));

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTORS_ONBOARD))
    }, [dispatcher, currentPageInfo])

    const calculatePctValue = async () => {
        let taskCount = 0;
        tasks.forEach((task) => { if (task.status === 'verified') taskCount++; });
        setPctValue(parseFloat(((taskCount / (tasks.length - optionalTasks)) * 100).toFixed(2)));
    }

    useEffect(() => {
        calculatePctValue()
    }, [])

    return (
        <PageContentStyle>
            <Header>
                <Heading>{'Tutor Onboarding'}</Heading>
                <SubHeading>{'Be your own boss and share your knowledge anytime, anywhere!'}</SubHeading>
                <Description>{'We welcome credible tutors and experts capable to teach or guide learners. Submitting information on this page does not guarantee acceptance.'}</Description>
                <Description>{'The qualities we look for in our tutors / experts are subject knowledge, teaching prowess, spontaneity, flair for content development, patience and strong communication skill.'}</Description>
            </Header>
            <ProgressBarContainer>
                <ProgressBarText>{"Progress Bar"}</ProgressBarText>
                <ProgressBar pctValue={pctValue} />
            </ProgressBarContainer>
            <TasksContainer>
                <Tasks>
                    {tasks.map((task, index) => (
                        <TaskItem key={index} index={index} task={task} calculatePctValue={calculatePctValue} />
                    ))}
                </Tasks>
            </TasksContainer>
            <FAQContainer>
                <Accordion
                    title={'Frequently Asked Question?'}
                    dataFAQ={dataFAQ}
                />
            </FAQContainer>
        </PageContentStyle>
    )
}

export default TutorOnboardPage
