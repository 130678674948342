import {useState, useEffect} from "react";

import { listOfTimeZones } from 'pages/auth/dashboard/data';

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';


export const GlobalClock = () => {
  const [day, setDay] = useState(new Date());

  useEffect(() => {
    setTimeout(() => {
      setDay(new Date());
    }, 5000);
  }, []);

  return (
    <div className="px-5 py-2 space-y-3">
      <div className="flex justify-between">
        <span className="text-lg font-medium font-bodyPri text-text-900 tracking-wider">
          {"World Clock"}
        </span>
        <span className="text-sm text-text-700 font-bodyPri whitespace-nowrap">
          {`${timeZone} ${getTimeZoneOffset(timeZone)}`}
        </span>
      </div>
      {listOfTimeZones.map((zone, index) => (
        <div key={index} className="p-4 m-2 rounded-md shadow-md">
          <p>{zone.name}</p>
          <p>{dayjs.tz(day, zone.zone).format("hh:mm A")}</p>
        </div>
      ))}
    </div>
  );
};
