import format from 'string-format';

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance"
import { url } from "redux/event/event.const"


class EventServices {

    getUserEventListByUserId = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.GET_USER_EVENT_LIST_BY_USER_ID, params)
        )
        return response
    }

    getEventDetailByEventId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_EVENT_DETAIL_BY_EVENT_ID, params)
        )

        return response
    }

    acceptEventByEventId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.ACCEPT_EVENT_BY_EVENT_ID, params),
            body
        )

        return response
    }

    declineEventByEventId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.DECLINE_EVENT_BY_EVENT_ID, params),
            body
        )

        return response
    }

    rescheduleEventByEventId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.RESCHEDULE_EVENT_BY_EVENT_ID, params),
            body
        )

        return response
    }

    startEventByEventId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.START_EVENT_BY_EVENT_ID, params),
            body
        )

        return response
    }

    completeEventByEventId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.COMPLETE_EVENT_BY_EVENT_ID, params),
            body
        )

        return response
    }

}
export default new EventServices();