import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Address,
  AvailabilityRange,
  AvailabilityStatus,
  BillingPreference,
  INITIAL_STATE,
  OffDaysAvailability,
  PricingPreferences,
  SubjectPreference,
} from "./types";

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState: INITIAL_STATE,
  reducers: {
    addAvailability: (
      state,
      action: PayloadAction<{
        day: AvailabilityStatus["day"];
        status: AvailabilityStatus["schedule"][number];
      }>
    ) => {
      const avail = state.availabilities.find(
        (availability) => availability.day === action.payload.day
      );

      if (avail) {
        avail.schedule.push(action.payload.status);
      }
      alert("Availability added");
    },
    updateAvailability: (
      state,
      action: PayloadAction<{
        day: AvailabilityStatus["day"];
        id: number;
        nStartTime: string;
        nEndTime: string;
      }>
    ) => {
      const avail = state.availabilities.find(
        (availability) => availability.day === action.payload.day
      );

      if (avail) {
        avail.schedule.map((schedule) => {
          if (schedule._id === action.payload.id) {
            schedule.startTime = action.payload.nStartTime;
            schedule.endTime = action.payload.nEndTime;
          }
          return schedule;
        });
      }
    },

    removeAvailability: (
      state,
      action: PayloadAction<{
        day: AvailabilityStatus["day"];
        id: number;
      }>
    ) => {
      const avail = state.availabilities.find(
        (availability) => availability.day === action.payload.day
      );

      if (avail) {
        avail.schedule = avail.schedule.filter((schedule) => {
          return schedule._id !== action.payload.id;
        });
      }
    },

    applyToAll: (state, action) => {
      const sDay = state.availabilities.find((a) => a.day === "Monday")!;

      state.availabilities.map((a) => {
        a.schedule = [...sDay.schedule];
      });
    },

    addOffDaysAvailability: (
      state,
      action: PayloadAction<OffDaysAvailability>
    ) => {
      state.offDaysAvailabilities.push(action.payload);
    },

    addOffDayNewTimeRange: (
      state,
      action: PayloadAction<{ date: string; schedule: AvailabilityRange }>
    ) => {
      const offDay = state.offDaysAvailabilities.find(
        (offDay) => offDay.date === action.payload.date
      );

      if (offDay) {
        offDay.schedule.push(action.payload.schedule);
      }
    },

    updateOffDaysAvailability: (
      state,
      action: PayloadAction<{ date: string; schedule: AvailabilityRange }>
    ) => {
      const offDay = state.offDaysAvailabilities.find(
        (offDay) => offDay.date === action.payload.date
      );

      if (offDay) {
        offDay.schedule.map((schedule) => {
          if (schedule._id === action.payload.schedule._id) {
            schedule.startTime = action.payload.schedule.startTime;
            schedule.endTime = action.payload.schedule.endTime;
          }
          return schedule;
        });
      }
    },
    removeOffDaysAvailability: (
      state,
      action: PayloadAction<{ date: string }>
    ) => {
      state.offDaysAvailabilities = state.offDaysAvailabilities.filter(
        (offDay) => offDay.date !== action.payload.date
      );
    },
    removeOffDayTimeSchedule: (
      state,
      action: PayloadAction<{ date: string; id: number }>
    ) => {
      const offDay = state.offDaysAvailabilities.find(
        (offDay) => offDay.date === action.payload.date
      );

      if (offDay) {
        offDay.schedule = offDay.schedule.filter(
          (schedule) => schedule._id !== action.payload.id
        );
      }
    },

    updateLessonPreference: (
      state,
      action: PayloadAction<{ id: string; status: boolean }>
    ) => {
      const { id, status } = action.payload;
      const lessonPreference = state.lessonPreferences.find(
        (lesson) => lesson.id === id
      );
      if (lessonPreference) {
        lessonPreference.status = status;
      }
    },

    updateOtherPreference: (
      state,
      action: PayloadAction<{ id: string; status: boolean }>
    ) => {
      const { id, status } = action.payload;
      const otherPreference = state.otherPreferences.find(
        (other) => other.id === id
      );
      if (otherPreference) {
        otherPreference.status = status;
      }
    },

    updatePricingPreference: (
      state,
      action: PayloadAction<PricingPreferences>
    ) => {
      const {
        trial_lesson,
        minimum_hourly_price_one_to_one,
        minimum_hourly_price_group,
        discount,
      } = action.payload;
      state.pricingPreferences.trial_lesson = trial_lesson;
      state.pricingPreferences.minimum_hourly_price_one_to_one =
        minimum_hourly_price_one_to_one;
      state.pricingPreferences.minimum_hourly_price_group =
        minimum_hourly_price_group;
      state.pricingPreferences.discount = discount;
    },

    addSubjectPreference: (
      state,
      action: PayloadAction<{ subject: string; preferredStudentLevel: string }>
    ) => {
      const { subject, preferredStudentLevel } = action.payload;
      state.subjectPreferences.push({
        _id: Math.floor(Math.random() * 10000),
        subject,
        preferredStudentLevel,
      });
    },

    updateSubjectPreference: (
      state,
      action: PayloadAction<SubjectPreference>
    ) => {
      const { _id, subject, preferredStudentLevel } = action.payload;
      const subjectPreference = state.subjectPreferences.find(
        (pref) => pref._id === _id
      );
      if (subjectPreference) {
        subjectPreference.subject = subject;
        subjectPreference.preferredStudentLevel = preferredStudentLevel;
      }
    },

    deleteSubjectPreference: (
      state,
      action: PayloadAction<{ _id: number }>
    ) => {
      const { _id } = action.payload;
      const subjectPreference = state.subjectPreferences.filter((pref) => {
        return pref._id !== _id;
      });
      state.subjectPreferences = subjectPreference;
    },

    setBillingInformation: (
      state,
      action: PayloadAction<BillingPreference>
    ) => {
      const { bussinessType, GSTStatus, GSTNumber, defaultAddress, addresses } =
        action.payload;
      state.billingPreferences.bussinessType = bussinessType;
      state.billingPreferences.GSTStatus = GSTStatus;
      state.billingPreferences.GSTNumber = GSTNumber;
      state.billingPreferences.defaultAddress = defaultAddress;
      state.billingPreferences.addresses = addresses;
    },

    addNewAddressToBilling: (state, action: PayloadAction<Address>) => {
      state.billingPreferences.addresses.push(action.payload);

      // If the address is first, set it as default
      if (state.billingPreferences.defaultAddress == null) {
        state.billingPreferences.defaultAddress = action.payload._id;
      }
    },

    // End
  },
});

export const {
  applyToAll,
  updateLessonPreference,
  updateOtherPreference,
  updatePricingPreference,
  addSubjectPreference,
  deleteSubjectPreference,
  updateSubjectPreference,
  setBillingInformation,
  addNewAddressToBilling,
  addAvailability,
  updateAvailability,
  removeAvailability,
  addOffDaysAvailability,
  addOffDayNewTimeRange,
  updateOffDaysAvailability,
  removeOffDaysAvailability,
  removeOffDayTimeSchedule,
} = preferencesSlice.actions;

export default preferencesSlice.reducer;
