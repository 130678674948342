import { useState, useEffect } from 'react';

import { BsFillCameraFill } from 'react-icons/bs'
import { FiEdit } from 'react-icons/fi';

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/SaveAndCancelBtn';
import VideoSelector from 'pages/auth/profile/VideoSelector';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { updateUserDetailByUserId } from 'redux/user/user.request';
import { setClearUploadDocument, setUploadDocumentPayload } from 'redux/document/document.slice';
import { setModal } from 'redux/local/local.slice';
import { userRoles } from "redux/auth/auth.const";
import { fileCategories } from 'redux/document/document.const';
import { modalConst } from 'redux/local/local.const';


const NameDetails = ({ editBtnConst, setEditBtnConst }) => {
  const { user, userDetail } = useAppState((s) => s.user)
  const { locals, modal } = useAppState((s) => s.local)
  const { uploadDocument } = useAppState(s => s.document)

  const dispatcher = useAppDispatcher()

  const [inputFirstName, setInputFirstName] = useState("")
  const [inputLastName, setInputLastName] = useState("")

  useEffect(() => {
    if (uploadDocument?.uploadDocument) {
      onSubmit(profileEditBtn.profilePicUrl.value, uploadDocument?.uploadDocument?.documentUrl)
    }
  }, [uploadDocument?.uploadDocument])

  const handleOnSelectImage = () => {
    dispatcher(setClearUploadDocument())
    dispatcher(setUploadDocumentPayload({
      ...uploadDocument?.uploadDocumentPayload,
      fileCategory: fileCategories.profilePicture.value
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.IMAGE_PICKER_MODAL.stateKey]: true
    }))
  }

  const onSubmit = (key, value) => {
    dispatcher(updateUserDetailByUserId(user?.user?.userId, { [key]: value }))
    setEditBtnConst(null)
  }

  const EditNameDetails = () => {
    return (
      <div className={"space-y-3"}>

        {/* firstName */}
        <div className='flex items-start justify-start gap-2'>
          <div className={"text-xl text-text-900 font-medium font-bodyPri tracking-wide"}>
            {"First Name:"}
          </div>
          {editBtnConst !== profileEditBtn.firstName.value &&
            <div className={"flex items-center justify-center gap-3"}>
              <span className={"font-bodyPri font-normal tracking-wide text-text-800 text-base"}>
                {userDetail?.userDetail?.firstName}
              </span>
              {!editBtnConst &&
                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                  onClick={() => {
                    setInputFirstName(userDetail?.userDetail?.firstName)
                    setEditBtnConst(profileEditBtn.firstName.value)
                  }}
                />
              }
            </div>
          }
          {editBtnConst === profileEditBtn.firstName.value &&
            <div className={"flex flex-col items-start justify-center gap-3"}>
              <input
                type={"text"}
                autoFocus={true}
                className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                minLength={0}
                maxLength={20}
                value={inputFirstName}
                onChange={(e) => setInputFirstName(e.target.value)}
              />
              <SaveAndCancelBtn
                onCancel={() => setEditBtnConst(null)}
                onSave={() => onSubmit(editBtnConst, inputFirstName)}
              />
            </div>
          }
        </div>

        {/* lastName */}
        <div className='flex items-start justify-start gap-2'>
          <div className={"text-xl text-text-900 font-medium font-bodyPri tracking-wide"}>
            {"Last Name:"}
          </div>
          {editBtnConst !== profileEditBtn.lastName.value &&
            <div className={"flex items-center justify-center gap-3"}>
              <span className={"font-bodyPri font-normal tracking-wide text-text-800 text-base"}>
                {userDetail?.userDetail?.lastName}
              </span>
              {!editBtnConst &&
                <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                  onClick={() => {
                    setInputLastName(userDetail?.userDetail?.lastName)
                    setEditBtnConst(profileEditBtn.lastName.value)
                  }}
                />
              }
            </div>
          }
          {editBtnConst === profileEditBtn.lastName.value &&
            <div className={"flex flex-col items-start justify-center gap-3"}>
              <input
                type={"text"}
                autoFocus={true}
                className="w-full px-2 py-1 border-secondary-dark border-dashed border-2 focus:outline-none font-bodyPri font-normal text-lg text-text-600 tracking-wide"
                minLength={0}
                maxLength={20}
                value={inputLastName}
                onChange={(e) => setInputLastName(e.target.value)}
              />
              <SaveAndCancelBtn
                onCancel={() => setEditBtnConst(null)}
                onSave={() => onSubmit(editBtnConst, inputLastName)}
              />
            </div>
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center gap-5">

        <div className={"flex flex-col items-center justify-center gap-3 shadow-md rounded-lg border border-text-300 p-5"}>
          <div className="relative w-32 h-32 xl:w-36 xl:h-36 rounded-full">
            <img src={userDetail?.userDetail?.profilePicUrl} alt={"my-profile"}
              className={"w-full h-32 xl:h-36 rounded-full p-2 object-cover"}
            />
            <div className="text-2xl text-text-800 absolute right-6 bottom-3 cursor-pointer rounded-full">
              <BsFillCameraFill onClick={handleOnSelectImage} />
            </div>
          </div>
          <div className='flex items-center justify-center gap-3'>
            <span className="text-xl text-text-900 font-medium font-bodyPri tracking-wid">
              {userDetail?.userDetail?.firstName + " " + userDetail?.userDetail?.lastName.charAt(0) + "."}
            </span>
          </div>
        </div>

        {locals.userRole === userRoles.TUTOR.value &&
          <VideoSelector
            editBtnConst={editBtnConst}
            setEditBtnConst={setEditBtnConst}
          />
        }
      </div>
      <EditNameDetails />
    </>
  );
};

export default NameDetails;