import tw, { styled } from 'twin.macro';
import { NavLink as RouteLink } from 'react-router-dom';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)``;

export const Content = tw(SectionContent)`
    flex flex-col flex-col-reverse lg:flex-row grid-cols-3 gap-5
`;

export const TextContainer = tw.div`
    col-start-1 col-span-3 lg:col-span-2
`;

export const Header = tw.div`
    flex flex-col items-start gap-2
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800
`;

export const Lists = styled.ul`
    ${tw`
        list-disc list-inside mt-5
    `}
    li {
        ${tw`
            font-bulletPoints font-medium text-xl text-text-700 list-inside
        `}
    }
`;

export const ImageContainer = styled.div`
    ${tw`
        col-start-2 col-span-1 lg:(col-start-3 col-span-1) flex items-center justify-center
    `}
    img {
        ${tw`
            w-56 h-56 md:(w-72 h-72) lg:(w-96 h-96) bg-cover
        `}
    }
`;

export const BtnSection = tw.div`
    mt-5 flex flex-col md:flex-row gap-5 items-center justify-center md:justify-start
`;

export const BtnApplyTeach = tw(RouteLink)`
    font-buttons font-normal text-lg text-text-50 bg-primary-main whitespace-nowrap
    rounded cursor-pointer w-48 py-1.5 flex justify-center items-center
    hover:(bg-secondary-main)
`;