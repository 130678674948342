import { createSlice } from "@reduxjs/toolkit";

import { COMPANY_INITIAL_STATE } from "redux/company/company.initialState";

const company = createSlice({
    name: "company",
    initialState: COMPANY_INITIAL_STATE,
    reducers: {
        // reducers for company public profile
        setCompanyPublicProfileLoading: (state, { payload }) => {
            state.companyPublicProfile.isLoading = payload
        },
        setCompanyPublicProfile: (state, { payload }) => {
            state.companyPublicProfile.companyPublicProfile = payload
            state.companyPublicProfile.errorMsg = COMPANY_INITIAL_STATE.companyPublicProfile.errorMsg
        },
        setCompanyPublicProfileErrorMsg: (state, { payload }) => {
            state.companyPublicProfile.errorMsg = payload
            state.companyPublicProfile.companyPublicProfile = COMPANY_INITIAL_STATE.companyPublicProfile.companyPublicProfile
        },
        setClearCompanyPublicProfile: (state) => {
            state.companyPublicProfile = COMPANY_INITIAL_STATE.companyPublicProfile
        },

        // reducers for update company public profile
        setAddCompanyPublicProfileLoading: (state, {payload}) => {
            state.addCompanyPublicProfile.isLoading = payload
        },
        setAddCompanyPublicProfileErrorMsg: (state, {payload}) => {
            state.addCompanyPublicProfile.errorMsg = payload
        },
        setClearAddCompanyPublicProfile: (state) => {
            state.addCompanyPublicProfile = COMPANY_INITIAL_STATE.addCompanyPublicProfile
        },

        // reducers for company course list
        setCompanyCourseListLoading: (state, { payload }) => {
            state.companyCourseList.isLoading = payload
        },
        setCompanyCourseList: (state, { payload }) => {
            state.companyCourseList.companyCourseList = payload
            state.companyCourseList.errorMsg = COMPANY_INITIAL_STATE.companyCourseList.errorMsg
        },
        setCompanyCourseListErrorMsg: (state, { payload }) => {
            state.companyCourseList.errorMsg = payload
            state.companyCourseList.companyCourseList = COMPANY_INITIAL_STATE.companyCourseList.companyCourseList
        },
        setClearCompanyCourseList: (state) => {
            state.companyCourseList = COMPANY_INITIAL_STATE.companyCourseList
        },

        // reducers for employee list
        setEmployeeListLoading: (state, { payload }) => {
            state.employeeList.isLoading = payload
        },
        setEmployeeList: (state, { payload }) => {
            state.employeeList.employeeList = payload
            state.employeeList.errorMsg = COMPANY_INITIAL_STATE.employeeList.errorMsg
        },
        setEmployeeListErrorMsg: (state, { payload }) => {
            state.employeeList.errorMsg = payload
            state.employeeList.employeeList = COMPANY_INITIAL_STATE.employeeList.employeeList
        },
        setClearEmployeeList: (state) => {
            state.employeeList = COMPANY_INITIAL_STATE.employeeList
        },

        // reducers for add employee
        setAddEmployeeLoading: (state, {payload}) => {
            state.addEmployee.isLoading = payload
        },
        setAddEmployeeErrorMsg: (state, {payload}) => {
            state.addEmployee.errorMsg = payload
        },
        setClearAddEmployee: (state) => {
            state.addEmployee = COMPANY_INITIAL_STATE.addEmployee
        },

        // reducers for edit employee detail payload
        setEmployeeDetailPayload: (state, {payload}) => {
            state.employeeDetailPayload.employeeDetailPayload = payload
        },
        setClearEmployeeDetailPayload: (state, {payload}) => {
            state.employeeDetailPayload = COMPANY_INITIAL_STATE.employeeDetailPayload
        },

        // reducers for company review list
        setCompanyReviewListLoading: (state, { payload }) => {
            state.companyReviewList.isLoading = payload
        },
        setCompanyReviewList: (state, { payload }) => {
            state.companyReviewList.companyReviewList = payload
            state.companyReviewList.errorMsg = COMPANY_INITIAL_STATE.companyReviewList.errorMsg
        },
        setCompanyReviewListErrorMsg: (state, { payload }) => {
            state.companyReviewList.errorMsg = payload
            state.companyReviewList.companyReviewList = COMPANY_INITIAL_STATE.companyReviewList.companyReviewList
        },
        setClearCompanyReviewList: (state) => {
            state.companyReviewList = COMPANY_INITIAL_STATE.companyReviewList
        },

        // reducers for important links list
        setImportantLinksListLoading: (state, {payload}) => {
            state.importantLinksList.isLoading = payload
        },
        setImportantLinksList: (state, {payload}) => {
            state.importantLinksList.importantLinksList = payload
            state.importantLinksList.errorMsg = COMPANY_INITIAL_STATE.importantLinksList.errorMsg
        },
        setImportantLinksListErrorMsg: (state, {payload}) => {
            state.importantLinksList.errorMsg = payload
            state.importantLinksList.importantLinksList = COMPANY_INITIAL_STATE.importantLinksList.importantLinksList
        },
        setClearImportantLinksList: (state) => {
            state.importantLinksList = COMPANY_INITIAL_STATE.importantLinksList
        },

        // reducers for edit important links
        setAddImportantLinksLoading: (state, {payload}) => {
            state.addImportantLinks.isLoading = payload
        },
        setAddImportantLinksErrorMsg: (state, {payload}) => {
            state.addImportantLinks.errorMsg = payload
        },
        setClearAddImportantLinks: (state, {payload}) => {
            state.addImportantLinks = COMPANY_INITIAL_STATE.addImportantLinks
        },

        // reducers for links detail payload
        setImportantLinksDetailPayload: (state, {payload}) => {
            state.importantLinksDetailPayload.importantLinksDetailPayload = payload
        },
        setClearImportantLinksDetailPayload: (state) => {
            state.importantLinksDetailPayload = COMPANY_INITIAL_STATE.importantLinksDetailPayload
        },

        // reducers for similar company list
        setSimilarCompanyListLoading: (state, { payload }) => {
            state.similarCompanyList.isLoading = payload
        },
        setSimilarCompanyList: (state, { payload }) => {
            state.similarCompanyList.similarCompanyList = payload
            state.similarCompanyList.errorMsg = COMPANY_INITIAL_STATE.similarCompanyList.errorMsg
        },
        setSimilarCompanyListErrorMsg: (state, { payload }) => {
            state.similarCompanyList.errorMsg = payload
            state.similarCompanyList.similarCompanyList = COMPANY_INITIAL_STATE.similarCompanyList.similarCompanyList
        },
        setClearSimilarCompanyList: (state) => {
            state.similarCompanyList = COMPANY_INITIAL_STATE.similarCompanyList
        },

        // reducers for company FAQs
        setFAQListLoading: (state, {payload}) => {
            state.FAQList.isLoading = payload
        },
        setFAQList: (state, {payload}) => {
            state.FAQList.FAQList = payload
            state.FAQList.errorMsg = COMPANY_INITIAL_STATE.FAQList.errorMsg
        },
        setFAQListErrorMsg: (state, {payload}) => {
            state.FAQList.errorMsg = payload
            state.FAQList.FAQList = COMPANY_INITIAL_STATE.FAQList.FAQList
        },
        setClearFAQList: (state) => {
            state.FAQList = COMPANY_INITIAL_STATE.FAQList
        },

        // reducers for edit FAQ's
        setAddFAQLoading: (state, {payload}) => {
            state.addFAQ.isLoading = payload
        },
        setAddFAQErrorMsg: (state, {payload}) => {
            state.addFAQ.errorMsg = payload
        },
        setClearAddFAQ: (state) => {
            state.addFAQ = COMPANY_INITIAL_STATE.addFAQ
        },

        // reducers for FAQ payload
        setFAQPayload: (state, {payload}) => {
            state.FAQPayload.FAQPayload = payload
        },
        setClearFAQPayload: (state) => {
            state.FAQPayload = COMPANY_INITIAL_STATE.FAQPayload
        }
    }
})

export const {
    setCompanyPublicProfileLoading,
    setCompanyPublicProfile,
    setCompanyPublicProfileErrorMsg,
    setClearCompanyPublicProfile,

    setAddCompanyPublicProfileLoading,
    setAddCompanyPublicProfileErrorMsg,

    setCompanyCourseListLoading,
    setCompanyCourseList,
    setCompanyCourseListErrorMsg,
    setClearCompanyCourseList,

    setEmployeeListLoading,
    setEmployeeList,
    setEmployeeListErrorMsg,
    setClearEmployeeList,

    setAddEmployeeLoading,
    setAddEmployeeErrorMsg,

    setEmployeeDetailPayload,
    setClearEmployeeDetailPayload,

    setCompanyReviewListLoading,
    setCompanyReviewList,
    setCompanyReviewListErrorMsg,
    setClearCompanyReviewList,

    setImportantLinksListLoading,
    setImportantLinksList,
    setImportantLinksListErrorMsg,
    setClearImportantLinksList,

    setAddImportantLinksLoading,
    setAddImportantLinksErrorMsg,
    setClearAddImportantLinks,

    setImportantLinksDetailPayload,
    setClearImportantLinksDetailPayload,

    setSimilarCompanyListLoading,
    setSimilarCompanyList,
    setSimilarCompanyListErrorMsg,
    setClearSimilarCompanyList,

    setFAQListLoading,
    setFAQList,
    setFAQListErrorMsg,
    setClearFAQList,

    setAddFAQLoading,
    setAddFAQErrorMsg,

    setFAQPayload,
    setClearFAQPayload
} = company.actions

export default company.reducer