const makePaymentPayload = {
    paymentType: null,
    currency: null,
    amountToPay: 0,
    processingPctFee: 0,
    totalAmountToPay: 0,
    description: null,
    paymentMethodId: null,
    gatewayName: null,
    firstName: '',
    lastName: '',
    email: '',
}


export const PAYMENT_INITIAL_STATE = {
    makePayment: {
        isLoading: false,
        makePaymentPayload: makePaymentPayload,
        makePayment: null,
        errorMsg: null
    },
}