// Components
import Typography from "components/Typography";
import Icon from "components/Icon";

const CountBox = ({ icon, count, title, iconColor, textColor }) => {
  return (
    <>
      <div className="w-24 border-2 border-gray-300 rounded-md p-4 items-center justify-center flex flex-col">
        <Icon type={icon} color={iconColor} />
        <Typography className={`${textColor} text-xl font-medium`}>
          {count}
        </Typography>
      </div>
      <Typography className={`${textColor} text-xs w-20 text-center mt-2 font-bodyPri tracking-wide text-text-800`}>
        {title}
      </Typography>
    </>
  );
};

export default CountBox;
