import { Link, useParams } from "react-router-dom";

import { FaShareAlt } from 'react-icons/fa';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { RightArrow } from './CourseHeaderStyle';

import CourseHeaderCard from './CourseHeaderCard';
import RatingStars from 'components/ratingStars/RatingStars';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { createFavCourseUserDetailByUserId, deleteFavCourseDetailByUserId } from "redux/course/course.request";

import { pagesInfo } from "utils/pagesInfo";

const CourseHeader = () => {
    const { logInSignUp } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { courseDetail } = useAppState((s) => s.course)
    const dispatcher = useAppDispatcher()
    const { courseId } = useParams()

    const filteredFavoriteCourse = user?.user?.favCourses?.filter((course) => course.courseId === Number(courseId))?.length > 0
    const AVG_RATING = 2

    const handleFavCourse = () => {
        if (filteredFavoriteCourse) {
            dispatcher(deleteFavCourseDetailByUserId(user?.user?.userId, { courseId: Number(courseId) }))
        }
        else {
            dispatcher(createFavCourseUserDetailByUserId(user?.user?.userId, { courseId: Number(courseId) }))
        }
    }

    return (
        <div className="w-full grid grid-cols-12 gap-5" >
            <div className="flex items-center justify-between col-start-1 col-span-12">
                <div className="flex items-center justify-center gap-2 text-text-50">
                    <Link to={pagesInfo?.FIND_COURSES.pagePath} className='hover:text-secondary-main'>{"Browse"}</Link>
                    <RightArrow className="text-sm" />
                    <Link to={pagesInfo?.FIND_COURSES.pagePath} className='hover:text-secondary-main'>{courseDetail?.courseDetail?.segment}</Link>
                    <RightArrow className="text-sm" />
                    <Link to={pagesInfo?.FIND_COURSES.pagePath} className='hover:text-secondary-main'>{courseDetail?.courseDetail?.category}</Link>
                </div>
                <div className={"flex items-center"}>
                    {logInSignUp?.isLogedIn &&
                        <div className={"px-2 cursor-pointer"} onClick={handleFavCourse}>
                            {filteredFavoriteCourse
                                ? <FavoriteIcon className='text-red-500' style={{ fontSize: "1.8rem" }} />
                                : <FavoriteBorderIcon className='text-text-500' style={{ fontSize: "1.8rem" }} />
                            }
                        </div>
                    }
                    <FaShareAlt className="text-text-50 text-xl" />
                </div>
            </div>
            <div className='col-start-1 col-span-12 grid grid-cols-12 gap-5'>
                <div className="w-full h-full col-start-1 col-span-8 flex flex-col gap-3 text-white">
                    <span className={'text-3xl font-semibold font-bodyPri tracking-wide'}>
                        {courseDetail?.courseDetail?.courseTitle}
                    </span>
                    <span className={"text-lg font-normal font-bodyPri tracking-wide"}>
                        {courseDetail?.courseDetail?.courseTitleDescription}
                    </span>
                </div>
                <div className="relative col-start-1 col-span-12 md:col-start-1 md:col-span-10 lg:col-start-9 lg:col-span-4">
                    <div className='rounded-lg overflow-hidden w-full h-48 md:w-[28rem] lg:w-full'>
                        <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${courseDetail?.courseDetail?.videoUrl}`}
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                        </iframe>
                    </div>
                    <div className='hidden lg:block lg:relative'>
                        <CourseHeaderCard position="absolute w-full" />
                    </div>
                </div>
            </div>
            <div className="w-full col-start-1 col-span-12 lg:col-span-8 space-y-5 py-5">
                <div className="w-full flex items-center justify-start gap-3">
                    <div className='relative w-20 h-20'>
                        <Link to={`/tutor/${courseDetail?.courseDetail?.tutor?.tutorId}`}>
                            <img src={courseDetail?.courseDetail?.tutor?.profilePicUrl} alt={"tutor-public-profile"} className="w-full h-full object-cover rounded-full" />
                        </Link>
                        <div className='absolute bottom-0.5 right-0.5 w-6 h-6 rounded-full overflow-hidden border-2 border-white'>
                            <img
                                src={`https://countryflagsapi.com/png/${courseDetail?.courseDetail?.tutor?.fromCountryDomain}`}
                                alt={"country-flag"}
                                className='w-full h-full object-cover'
                            />
                        </div>
                    </div>
                    <div className="text-white flex flex-col items-start justify-center">
                        <div className='flex items-center gap-2'>
                            <span className="text-lg font-bodyPri">
                                {courseDetail?.courseDetail?.tutor?.firstName + " " + courseDetail?.courseDetail?.tutor?.lastName.charAt(0)}
                            </span>
                            {courseDetail?.courseDetail?.tutor?.onlineStatus === "online"
                                && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>
                            }
                        </div>
                        <div className='flex items-center'>
                            {courseDetail?.courseDetail?.tutor?.avgRating > AVG_RATING
                                ? <>
                                    <RatingStars rating={courseDetail?.courseDetail?.tutor?.avgRating} fullRating={5} />
                                    <span className="font-bold">{courseDetail?.courseDetail?.tutor?.avgRating}
                                        ({courseDetail?.courseDetail?.tutor?.ratingCount})
                                    </span>
                                </>
                                : <div className='text-sm font-bodyPri tracking-wide text-complementry-main'>
                                    {"New"}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CourseHeader
