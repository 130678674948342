import {
    Container,
    Completed,
    InCompleted,
} from './ProgressBarStyle';

const ProgressBar = ({ pctValue = 0 }) => {

    return (
        <Container>
            <Completed pctValue={pctValue}>
                {pctValue !== 0 ? pctValue + '%' : " "}
            </Completed>
            <InCompleted pctValue={100 - pctValue}>
                {pctValue === 0 ? pctValue + '% completed' : " "}
            </InCompleted>
        </Container>
    )
}

export default ProgressBar;
