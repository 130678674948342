import { useState } from 'react';
import clsx from 'clsx';

import { IoIosArrowForward } from 'react-icons/io';

import { createEventLog } from 'components/modals/eventDetailModal/eventDetailModal.data';

import LogTimeLine from 'components/logTimeLine/LogTimeLine';

import { useAppState } from 'hooks/useStore';

const EventDetailEventLog = () => {
    const { eventDetail } = useAppState(s => s.event)

    const [isShow, setIsShow] = useState(false)

    return eventDetail?.eventDetail?.eventLogs ? (
        <div className={"space-y-3"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 px-3 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Event Logs"}
                </span>
                <IoIosArrowForward className={clsx(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={clsx(
                isShow
                    ? "flex flex-col gap-3 px-5 transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                <div className={""}>
                    <LogTimeLine logList={createEventLog(eventDetail?.eventDetail?.eventLogs)} />
                </div>
            </div>
        </div>
    ) : null
}

export default EventDetailEventLog