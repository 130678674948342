import { dayjs, utcWeekDays } from 'utils/dateTime.utils';


export const getDayAvailability = async (availability, duration) => {
    const weeklyAvail = availability?.weeklyAvail
    let dailyAvailabilities = []
    let today = dayjs().utc()
    for (let index = 0; index < 90; index++) {
        const toDate = today.add(index, 'day')
        const dayName = toDate.format("dddd").toString()
        if (weeklyAvail[dayName].length > 0) {
            dailyAvailabilities.push({
                date: toDate.format('dddd, DD MMM YYYY').toString(),
                timeSlots: weeklyAvail[dayName]
            })
        }
    }
    const dailyAvailabilitiesWithDruation = await getDailyAvailabilitiesWithDuration(dailyAvailabilities, duration)
    const dailyAvailabilitiesExcludeOffDays = await getDailyAvailabilitiesExcludeOffDays(dailyAvailabilitiesWithDruation, availability?.offDays)
    return dailyAvailabilitiesExcludeOffDays
}

const getDailyAvailabilitiesWithDuration = async (dailyAvailabilities, duration) => {
    const dailyAvailabilitiesWithDruation = []
    dailyAvailabilities = dailyAvailabilities?.forEach(dailyAvailability => {
        const timeSlots = []
        dailyAvailability.timeSlots.forEach(timeSlot => {
            let startDayjs = dayjs(dailyAvailability.date + " " + timeSlot.startTime + "+00:00").utc()
            let endDayjs = dayjs(dailyAvailability.date + " " + timeSlot.endTime + "+00:00").utc()
            while (startDayjs.isBefore(endDayjs) && startDayjs.add(duration, "minute").isSameOrBefore(endDayjs)) {
                const slot = {
                    startTime: startDayjs.format("HH:mm:ss").toString(),
                    endTime: startDayjs.add(duration, "minute").format("HH:mm:ss").toString()
                }
                timeSlots.push(slot)
                startDayjs = startDayjs.add(duration, "minute")
            }
        })
        if (timeSlots.length > 0) {
            dailyAvailabilitiesWithDruation.push({
                date: dailyAvailability.date,
                timeSlots: timeSlots
            })
        }
    })
    return dailyAvailabilitiesWithDruation
}

const getDailyAvailabilitiesExcludeOffDays = (dailyAvailabilities, offDays) => {

    const dailyAvailabilitiesExcludeOffDays = dailyAvailabilities.filter(dailyAvail => {
        dailyAvail.timeSlots = dailyAvail.timeSlots.filter(timeSlot => {
            const availDateTime = {
                start: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format(),
                end: dayjs(dailyAvail.date + " " + timeSlot.endTime + "+00:00").utc().format()
            }
            return getIsAvailabilityOff(availDateTime, offDays)
        })
        return dailyAvail.timeSlots.length > 0 ? true : false
    })
    return dailyAvailabilitiesExcludeOffDays
}

const getIsAvailabilityOff = (availDateTime, offDays) => {
    let count = 0
    offDays?.forEach(offDay => {
        offDay?.timeSlots.forEach(timeSlot => {
            const startDateTime = dayjs(offDay?.date + " " + timeSlot.startTime + "+00:00").utc()
            const endDateTime = dayjs(offDay?.date + " " + timeSlot.endTime + "+00:00").utc()
            if ((dayjs(availDateTime.start).isSameOrBefore(startDateTime) && dayjs(availDateTime.end).isSameOrBefore(startDateTime)) ||
                (dayjs(availDateTime.start).isSameOrAfter(endDateTime) && dayjs(availDateTime.end).isSameOrAfter(endDateTime))) {

            } else {
                count++
            }
        })
    })
    return count > 0 ? false : true
}

export const getIsAvailable = (availDateTime, userEventList) => {
    userEventList = userEventList?.filter(event => event.eventType !== 'premium').filter(event => {
        if (['pending', 'scheduled', 'on_going', 'rescheduled'].includes(event.status)) return true
        return false
    }).filter(event => {
        const startDateTime = dayjs(event.startDateTime)
        const endDateTime = dayjs(event.endDateTime)
        if ((dayjs(availDateTime.start).isSameOrBefore(startDateTime) && dayjs(availDateTime.end).isSameOrBefore(startDateTime)) ||
            (dayjs(availDateTime.start).isSameOrAfter(endDateTime) && dayjs(availDateTime.end).isSameOrAfter(endDateTime))) {
            return false
        } else {
            return true
        }
    })
    return userEventList?.length > 0
        ? false
        : dayjs(availDateTime.start).isBefore(dayjs())
            ? false
            : true
}

export const validateIsEndBeforeStart = (day, selectedTimeSlot) => {
    const startDayjs = dayjs(utcWeekDays[day.toUpperCase()].date + " " + selectedTimeSlot.startTime + "+00:00")
    const endDayjs = dayjs(utcWeekDays[day.toUpperCase()].date + " " + selectedTimeSlot.endTime + "+00:00")
    const isSameDay = startDayjs.format("DD") === endDayjs.format("DD")

    return endDayjs.isSameOrBefore(startDayjs)
        ? isSameDay
            ? true
            : false
        : false
}

export const validateIsTimeSlotExist = (day, selectedTimeSlot, timeSlots) => {
    const selectStartDayjs = dayjs(utcWeekDays[day.toUpperCase()].date + " " + selectedTimeSlot.startTime + "+00:00").utc()
    const selectEndDayjs = dayjs(utcWeekDays[day.toUpperCase()].date + " " + selectedTimeSlot.endTime + "+00:00").utc()

    timeSlots = timeSlots?.filter(timeSlot => {
        const startDayjs = dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot.startTime + "+00:00").utc()
        const endDayjs = dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot.endTime + "+00:00").utc()
        if ((selectStartDayjs.isSameOrBefore(startDayjs) && selectEndDayjs.isSameOrBefore(startDayjs)) ||
            (selectStartDayjs.isSameOrAfter(endDayjs) && selectEndDayjs.isSameOrAfter(endDayjs))) {
            return false
        } else {
            return true
        }
    })
    return timeSlots?.length > 0 ? true : false
}

export const getIsTimeSlotBooked = (selectedTimeSlot, userEventList) => {
    userEventList = userEventList?.filter(event => event.eventType !== 'premium').filter(event => {
        if (['pending', 'scheduled', 'on_going', 'rescheduled'].includes(event.status)) return true
        return false
    }).filter(event => {
        const startDateTime = dayjs(event.startDateTime)
        const endDateTime = dayjs(event.endDateTime)
        if ((dayjs(selectedTimeSlot.start).isSameOrBefore(startDateTime) && dayjs(selectedTimeSlot.end).isSameOrBefore(startDateTime)) ||
            (dayjs(selectedTimeSlot.start).isSameOrAfter(endDateTime) && dayjs(selectedTimeSlot.end).isSameOrAfter(endDateTime))) {
            return false
        } else {
            return true
        }
    })
    return userEventList?.length > 0 ? true : false
}