import axios from "axios";
import store from "redux/store";
import axiosAuthInterceptor from 'interceptors/axiosAuth.interceptor';

// const { REACT_APP_BASE_API_URL } = process.env;
const REACT_APP_BASE_API_URL = "http://3.111.216.173:8000/api/v1"
// const REACT_APP_BASE_API_URL = "https://dev-api.edulyte.net/api/v1"
// const REACT_APP_BASE_API_URL = "https://api.stage.edulyte.net/api/v1"


export const baseApiInstance = () => {

    const instance = axios.create({
        baseURL: REACT_APP_BASE_API_URL,
        responseType: "json",
        headers: { 'Content-Type': "application/json" }
    })

    return instance
};

export const baseCredApiInstance = () => {
    const instance = axios.create({
        baseURL: REACT_APP_BASE_API_URL,
        responseType: "json",
        headers: { 'Content-Type': "application/json" },
        withCredentials: true
    })

    return instance
};

export const baseAuthApiInstance = () => {

    const instance = axios.create({
        baseURL: REACT_APP_BASE_API_URL,
        responseType: "json",
        headers: { 'Content-Type': "application/json" },
        withCredentials: true
    })

    axiosAuthInterceptor(instance, store)

    return instance
};