import React from "react";

// Components
import MyTypography from "components/Typography";
import ReadMore from "components/ReadMore";

// Local Components
import Container from "./Container";
import { useAppState } from "hooks/useStore";

const AboutMe = () => {
  const {tutorPublicProfile} = useAppState((s) => s.tutor)
  
  return tutorPublicProfile?.tutorPublicProfile && (
    <Container
      leftSide={
        <div className="p-5 space-y-5">
          <MyTypography className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
            {"About"}
          </MyTypography>
          <MyTypography className="text-lg text-text-700 px-3">
            <ReadMore>{tutorPublicProfile?.tutorPublicProfile?.bio}</ReadMore>
          </MyTypography>
        </div>
      }
      rightSide={null}
      marginTop
    />
  );
};

export default AboutMe;
