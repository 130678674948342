import { doc, updateDoc } from "firebase/firestore";
import { database } from "config/firebase"

export default async function updateGroupMessageStatus(
  currentMember,
  selectedChat
) {
  if (parseInt(currentMember.unreadMessages) === 0) return;
  const memberRef = doc(
    database,
    "chats",
    selectedChat.id,
    "members",
    currentMember.id
  );
  await updateDoc(memberRef, {
    unreadMessages: "0",
  });
}
