import tw, { styled } from 'twin.macro';
import { Link as RouteLink } from 'react-router-dom';
import {
    ArrowDropDown,
    PersonAdd,
    Login,
    HelpOutlineRounded,
    Menu,
    CancelOutlined,
    Close,
    Category,
    People,
    LocalLibrary,
    DynamicForm,
    ChatBubbleOutline,
    FavoriteBorder,
    NotificationsNone,
} from '@mui/icons-material';

import { pagesInfo } from 'utils/pagesInfo';

import { motion } from 'framer-motion';

// hover:(bg-white text-text-900)
export const Container = styled.header`
    ${tw`
        grid grid-cols-12
        fixed top-0 z-50 w-full text-black font-bodyPri bg-white
        hover:(bg-primary-dark text-white)
    `}
    ${({ isScrolled, pageName }) => (pageName === pagesInfo.HOME.pageName && isScrolled) && tw`
        bg-primary-dark text-white shadow-lg
    `}
    ${({pageName }) => (pageName !== pagesInfo.HOME.pageName) && tw`
        bg-white text-black shadow-lg hover:(bg-white text-black)
    `}
`;

export const LargeNavBar = tw.div`
    hidden
    lg:(col-start-2 col-span-10 flex flex-row items-center justify-between h-16)
`;

export const SideBarHamburgerButton = tw(Menu)`
    hidden
    lg:(grid col-start-1 col-span-1 self-center justify-self-center text-4xl hover:text-secondary-dark)
`;

export const SideBarCloseButton = tw(Close)`
    hidden
    lg:(grid col-start-1 col-span-1 self-center justify-self-center text-4xl hover:text-secondary-dark)
`;

export const LeftNavLinksContainer = tw.div`
    flex items-center gap-48
    lg:(gap-10)
`;

export const CenterNavLinksContainer = tw.div`
    hidden
    lg:(flex items-center justify-center gap-10)
`;

export const RightNavLinksContainer = tw.div`
    flex items-center justify-end gap-10
`;

export const ArrowDropDownIcon = styled(ArrowDropDown)`
    font-size: 16px;
`;

export const NavLinks = tw.div`
    inline-block
`;

export const NavLink = tw(RouteLink)`
    font-normal tracking-wide text-lg text-base cursor-pointer
    hover:(text-secondary-main) 
`;

export const TeachWithUsLink = styled(NavLink)`
    ${tw`
        border-2 rounded-lg border-white px-2 py-1 border-text-900
        hover:(border-black border-secondary-main)
    `}
    ${({ isScrolled, isMouseHover, pageName }) => (isScrolled || isMouseHover || pageName !== pagesInfo.HOME.pageName) && tw`
        border-white
    `}
`;

export const LogoLink = styled(NavLink)`
    ${tw`
        font-bodyPri! text-3xl!
        hover:text-white
    `}
    ${({ isScrolled, isMouseHover, pageName }) => (pageName === pagesInfo.HOME.pageName && (isScrolled || isMouseHover)) && tw`
        text-white
    `}
    ${({ pageName }) => (pageName !== pagesInfo.HOME.pageName) && tw`
        text-black hover:text-primary-dark
    `}
`;

export const QuestionIcon = styled(HelpOutlineRounded)`
    ${tw`
        text-text-900 text-xl cursor-pointer hover:text-secondary-dark
    `}
`;

export const AuthSection = tw(NavLink)`
    flex gap-2
`;

export const SignUpIcon = styled(PersonAdd)``;

export const LogInIcon = styled(Login)``;

export const MediumNavBar = tw.div`
    hidden
    md:(col-start-2 col-span-10 flex flex-row items-center justify-between h-16)
    lg:hidden
`;

export const SmallNavBar = tw.div`
    col-start-2 col-span-10 flex flex-row items-center justify-between h-16
    md:hidden
`;

export const HamburgerButton = styled(Menu)`
    ${tw`
        flex justify-end text-3xl hover:text-secondary-dark cursor-pointer
    `}
    ${({ isScrolled, isMouseHover }) => (isScrolled || isMouseHover) && tw`
        text-text-900
    `}
`;

export const HamburgerHeader = tw.div`
    flex items-center justify-between
`;

export const HamburgerHeaderContent = tw.div`
    flex flex[1] items-center justify-evenly
`;

export const CloseIcon = styled(CancelOutlined)`
    ${tw`
        text-text-900 cursor-pointer shadow-xl hover:text-primary-dark
    `}
`;

export const HamburgerContent = tw.div`
    flex flex-col gap-3 mt-10
`;

export const HamburgerFeatured = tw.div`
    grid grid-cols-2 gap-5
`;

export const HamburgerFeaturedLink = tw(RouteLink)`
    flex flex-col items-center justify-center gap-1 cursor-pointer select-none
    bg-primary-light text-primary-dark text-base font-bodyPri font-medium py-5 rounded-xl
    hover:(bg-secondary-light text-secondary-dark)
`;

export const CategoryIcon = styled(Category)``;

export const FindTeacherIcon = styled(People)``;

export const FindCoursesIcon = styled(LocalLibrary)``;

export const TeachWithUsIcon = styled(DynamicForm)``;

// loged in style

export const LogedInNavLink = tw.div`
    flex flex-row gap-5 relative
`;

export const ReferFriend = tw(TeachWithUsLink)``;

export const IconSection = tw.span`
    flex flex-row items-center gap-3
`;

export const IconContainer = tw.span`
    relative cursor-pointer
    font-normal tracking-wide text-lg text-base cursor-pointer
    hover:(text-secondary-dark) 
`;

// export const IconContainer = tw(NavLink)`
//     relative cursor-pointer
// `;

export const MessageIcon = tw(ChatBubbleOutline)`
    text-2xl
`;

export const QuestionHelpIcon = tw(HelpOutlineRounded)`
    text-2xl
`;

export const FavoriteIcon = tw(FavoriteBorder)`
    text-2xl
`;

export const NotificationIcon = tw(NotificationsNone)`
    text-2xl
`;

export const BadgeCounter = tw.span`
    absolute z-40 right-[-0.5rem] top-[-0.5rem] w-5 h-5 rounded-full bg-secondary-dark
    font-bodyPri font-normal text-xs text-text-50 flex items-center justify-center
`;

export const ProfileIcon = styled.div`
    ${tw`
        w-8 h-8 rounded-full overflow-hidden relative
        border-2 border-text-500 cursor-pointer hover:border-secondary-dark
    `}
    img {
        ${tw`
            w-full h-full bg-cover
        `}
    }
`;

export const ToggleMenuContent = tw.div`
    w-full max-h-[36rem] space-y-3
`;

export const ItemIcon = tw.span`
    text-primary-main text-4xl px-5 flex items-center h-full
`;

export const ItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri font-normal text-base whitespace-nowrap !text-text-800
`);

export const Item = tw.div`
     w-full flex flex-row h-12 cursor-pointer select-none rounded-md
    hover:(bg-text-100 text-primary-main! scale-150!) 
`;

export const HamburgerMenuContent = tw.div`
    w-full h-full space-y-3
`;

export const ItemLink = tw(RouteLink)`
     w-full flex flex-row h-12 cursor-pointer select-none rounded-md
    hover:(bg-text-100 text-primary-main! scale-150!) 
`;

export const ProfileImageContainer = styled.div`
    ${tw`
        w-8 h-8 rounded-full overflow-hidden relative
        border-2 border-text-500 cursor-pointer
    `}
    img {
        ${tw`
            w-full h-full bg-cover bg-center
        `}
    }
`;