import './styles/global.css';

import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import store from "redux/store";

import InitRouter from './routers/Router';

import { UserContextProvider } from "pages/auth/message/contexts/userContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const App = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <Provider store={store}>
      <UserContextProvider>
        <Elements stripe={stripePromise}>
          <Router>
            <Routes>
              <Route path={"/*"} element={<InitRouter />} />
            </Routes>
          </Router>
        </Elements>
      </UserContextProvider>
    </Provider>
  );
}

export default App;