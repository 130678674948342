import { courseFilterProps } from "redux/course/course.const"


export const COURSE_INITIAL_STATE = {
    courseList: {
        isLoading: false,
        courseList: null,
        filteredCourseList: null,
        errorMsg: null
    },
    
    myCourseList: {
        isLoading: false,
        myCourseList: null,
        filteredCourseList: null,
        errorMsg: null
    },

    favCourseList: {
        isLoading: false,
        favCourseList: null,
        filteredFavCourseList: null,
        errorMsg: null
    },

    tutorCourseList: {
        isLoading: false,
        tutorCourseList: null,
        filteredTutorCourseList: null,
        errorMsg: null
    },
    
    courseDetail: {
        isLoading: false,
        courseDetail: null,
        errorMsg: null
    },

    selectedCourse: {
        isLoading: false,
        selectedCourse: null,
        errorMsg: null
    },

    addFavCourse: {
        isLoading: false,
        addFavCourse: null,
        errorMsg: null
    },

    filterProps: courseFilterProps
}