export const timeLineData = [
    {
        title: 'Apply as a Teacher',
        description: 'Fill up the Application Form and upload color scanned copies of your required documents.',
    },
    {
        title: 'Initial Screening',
        description: 'We will review your application to find the right fit. We welcome credible teachers or experts from various fields. The qualities we look for in our experts are subject knowledge, teaching prowess, spontaneity, use of different techniques among other things.',
    },
    {
        title: 'Teacher Onboarding and Training',
        description: 'Welcome aboard! At this stage we ask you to understand Tools and Techniques to be used, Tutor’s Code of Conduct, Create some lesson plans, Update your monthly schedule in your digital calendar, update some records on the tutor dashboard. Our team will enhance your profile which plays on your strengths to get you right students.',
    },
    {
        title: 'First Online Class',
        description: 'Congratulations! You are now ready to start your first online class and achieve your goal of being an excellent educator. You can create your own short self-paced online courses under your name, group or one-on-one sessions for your favorite topics, conduct trial sessions, record and upload your video lessons and much more. Teach online, get the reward you deserve. All the best!.',
    }
]