import tw from "twin.macro"
import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)`
    space-y-5 py-5
`;

export const Header = tw.div`
grid grid-cols-8  p-5 space-y-3  bg-white mb-6
`;

export const SearchDiv = tw.div`
    border-2 border-gray-400 px-3 py-1 rounded-xl whitespace-nowrap cursor-pointer
    font-bodyPri font-normal text-base text-text-700
`;

export const Section = tw.div`
grid grid-cols-12 p-2 bg-white
`;

export const Container = tw.div`
col-start-2 col-span-10 lg:(col-start-3 col-span-8)
flex flex-col flex-wrap gap-5 py-5`
    ;