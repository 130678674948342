import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

import { BiArrowBack } from "react-icons/bi";

import ButtonLoader from "components/loader/ButtonLoader";
import InputOtp from "components/inputOtp/InputOtp";

import { createVerifyOtp, } from 'redux/notification/notification.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { modalConst } from "redux/local/local.const";
import { otpStatus } from "redux/notification/notification.const";

const ShowAndVerifyOtp = ({ setIsCodeSent }) => {
    const { modal } = useAppState((state) => state.local)
    const { userGeneralDetailPayload } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            dispatcher(setModal({
                ...modal,
                [modalConst.VERIFY_EMAIL_MODAL.stateKey]: false
            }))
            setIsCodeSent(false)
            toast.success("Email verified successfully!")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            setIsCodeSent(false)
            toast.warning("Something went wrong")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            setIsCodeSent(false)
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            setIsCodeSent(true)
            setIsOtpError(true)
            toast.error("Incorrect code!")
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (myOtp?.length === 4 && sendOtp?.sendOtp?.otpNotificationId) {
            dispatcher(setClearVerifyOtp())
            dispatcher(createVerifyOtp({
                otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                otp: myOtp
            }))
        }
    }, [myOtp])

    const handleBackButton = () => {
        setMyOtp("")
        setIsOtpError(false)
        setIsCodeSent(false)
    }

    const handleVerifyCode = () => {
        if (myOtp?.length < 4) return;
        dispatcher(setClearVerifyOtp())
        if (sendOtp?.sendOtp?.otpNotificationId) {
            dispatcher(createVerifyOtp({
                otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                otp: myOtp
            }))
        }
    }

    return (
        <div className={"flex flex-col items-center justify-center gap-3"}>
            <div className={"flex items-center justify-center gap-3"}>
                {!userGeneralDetailPayload?.userGeneralDetailPayload?.isOnlyVerifyMobileNo &&
                    <BiArrowBack className={"text-lg text-text-800"} onClick={handleBackButton} />
                }
                <span className={"font-bodyPri font-normal text-text-800 text-sm italic tracking-wide"}>
                    {"+" + userGeneralDetailPayload?.userGeneralDetailPayload?.isdCode + " - " + userGeneralDetailPayload?.userGeneralDetailPayload?.mobileNo}
                </span>
            </div>
            <InputOtp
                email={userGeneralDetailPayload?.userGeneralDetailPayload?.mobileNo}
                setMyOtp={setMyOtp}
                setIsOtpError={setIsOtpError}
                isOtpError={isOtpError}
            />
            <div
                className={clsx(
                    "w-full flex items-center justify-center space-x-5 py-1.5 rounded-lg",
                    "text-text-50 font-buttons font-medium text-base text-center",
                    myOtp?.length < 4 && "bg-divider-medium",
                    myOtp?.length === 4 && "bg-primary-main hover:bg-secondary-dark cursor-pointer"
                )}
                onClick={handleVerifyCode}
            >
                {verifyOtp?.isLoading && <ButtonLoader isLoading={verifyOtp?.isLoading} />}
                {!verifyOtp?.isLoading && "Verify"}
            </div>
        </div>
    )
}

export default ShowAndVerifyOtp