export const url = {
    SEGMENT_CATEGORY_LIST: 'segments/category/list'
}

export const filterName = {
    SEGMENT: {
        value: "segment"
    }
}

export const segmentFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
}