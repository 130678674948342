export const data = {
  filterText: {
    title: 'Choose your online live classes',
    steps: [
      {
        step: '1',
        stepText: 'Choose a live classes',
      },
      {
        step: '2',
        stepText: 'Confirm booking',
      },
      {
        step: '3',
        stepText: 'Start learning',
      },
    ]
  },
  filters: {
    topics: {
      label: "TOPIC",
      options: [
        {
          id: 1,
          name: "Any"
        },
        {
          id: 2,
          name: "Options 1"
        }
      ]
    },
    hourlyFees: {
      label: "HOURLY FEE",
      options: [
        {
          id: 1,
          name: "100 Rs - 300 Rs"
        },
        {
          id: 2,
          name: "200 Rs - 400 Rs"
        }
      ]
    },
    availability: {
      label: "AVAILABILITY",
      options: [
        {
          id: 1,
          name: "Any time"
        },
        {
          id: 2,
          name: "Option 1"
        }
      ]
    },
    tutorIsFrom: {
      label: "TUTOR IS FROM",
      options: [
        {
          id: 1,
          name: "Any country"
        },
        {
          id: 2,
          name: "Option 1"
        }
      ]
    },
    speaks: {
      label: "Speaks",
      options: [
        {
          id: 1,
          name: "Speaks 1"
        },
        {
          id: 2,
          name: "Speaks 2"
        }
      ]
    },
    sessionType: {
      label: "Session Type",
      options: [
        {
          id: 1,
          name: "Session Type 1"
        },
        {
          id: 2,
          name: "Session Type 2"
        }
      ]
    },
    studentLevel: {
      label: "Student Level",
      options: [
        {
          id: 1,
          name: "Student Level 1"
        },
        {
          id: 2,
          name: "Student Level 2"
        }
      ]
    },
    learnerAge: {
      label: "Learner's Age",
      options: [
        {
          id: 1,
          name: "Learner's Age 1"
        },
        {
          id: 2,
          name: "Learner's Age 2"
        }
      ]
    }
  },
}

export const sortingMenuItems = {
  RELEVANCE: {
    label: "Relevance",
    value: "relevance"
  },
  PRICE_LOWEST_FIRST: {
    label: "Price lowest first",
    value: "price_lowest_first"
  },
  PRICE_HIGHEST_FIRST: {
    label: "Price highest first",
    value: "price_highest_first"
  }
}

export const MOCK_DATA = [{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": 'https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80',
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRawRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": 'https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80',
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRawRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},
{
  "courseId": 101,
  "courseTitle": "Learn Finance",
  "category": "Computer Class",
  "courseType": "Group",
  "price": 100.0,
  "discountPct": 5.8,
  "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
  "updatedAt": "",
  "createdAt": "",
  "tutor": {
    "tutorId": 1,
    "userId": 1,
    "firstName": "Shivi",
    "lastName": "Rawat",
    "profilePicUrl": "https://th.bing.com/th/id/R.4e7f3ef04d80a0eebc422be50c73aa39?rik=e3YyFy4aF45d%2fQ&pid=ImgRaw",
    "countryDomain": "us",
    "avgRating": 3.4,
    "ratingCount": 2,
    "onlineStatus": "online",
    "lastActiveTime": ""
  },

},]