import { addDoc, collection, doc, runTransaction } from "firebase/firestore";
import { database } from "config/firebase"

export default async function sendNotification(
  notificationObject,
  selectedChat,
  groupMembers,
  user
) {
  const otherGroupMembers = groupMembers.filter(
    (groupMember) => groupMember.id !== user.userId
  );
  const memberIds = otherGroupMembers.map((member) => member.id);
  if (selectedChat.type !== "group") {
    const notificationsRef = collection(database, "notifications");
    await addDoc(notificationsRef, notificationObject);
  } else {
    const groupNotificationsRef = doc(
      database,
      "groupnotifications",
      selectedChat.id
    );

    await runTransaction(database, async (transaction) => {
      const notification = await transaction.get(groupNotificationsRef);
      if (!notification.exists()) {
        const newNotification = {
          ...notificationObject,
          groupName: selectedChat.name,
          chatId: selectedChat.id,
          chatType: selectedChat.type,
          members: memberIds,
          muted: [],
        };
        transaction.set(groupNotificationsRef, newNotification);
      } else {
        const finalNotification = {
          ...notificationObject,
          members: memberIds,
        };
        transaction.update(groupNotificationsRef, finalNotification);
      }
    });
  }
}
