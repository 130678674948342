import {
    ListItem,
    ItemLink,
} from './TopNavBarStyle';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';
import { createLogout } from "redux/auth/auth.request";


const TopNavBarItem = ({ dataItem }) => {
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const handleCommingSoone = () => {
        if (["Buy Gift Card"].includes(dataItem.name)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.COMING_SOON_MODAL.stateKey]: true
            }))
        }
    }

    const handleLogOut = () => {
        if (["Log Out"].includes(dataItem.name)) {
            dispatcher(createLogout())
        }
    }


    if (["Buy Gift Card"].includes(dataItem.name)) {
        return (
            <ListItem onClick={() => handleCommingSoone()}>
                <dataItem.icon />
                {dataItem.name}
            </ListItem>
        )
    }

    if (["Log Out"].includes(dataItem.name)) {
        return (
            <ListItem onClick={() => handleLogOut()}>
                <dataItem.icon />
                {dataItem.name}
            </ListItem>
        )
    }

    return (
        <ItemLink to={dataItem.path}>
            <dataItem.icon />
            {dataItem.name}
        </ItemLink>
    )
}

export default TopNavBarItem
