import { useNavigate, useLocation } from 'react-router-dom';

import { PeopleAlt } from '@mui/icons-material';

import {
    ToggleMenuContent,
    Item,
    ItemIcon,
    ItemName
} from './NavBarStyle';

import { RightLoggedInMenuItems } from 'components/navbar/RightLogedInToggleMenu.data'

import { createLogout } from "redux/auth/auth.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore';


const RightLogedInToggleMenu = ({ setIsClick }) => {
    const { locals } = useAppState((s) => s.local)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const handleOnClickItem = (dataItem) => {
        if (dataItem.name === "Log Out") {
            dispatcher(createLogout())
        } else {
            navigate(dataItem.path)
        }
        setIsClick(false)
    }

    return (
        <ToggleMenuContent>
            <Item className={"pt-2"} onClick={() => handleOnClickItem({ name: "profile", path: "/profile" })}>
                <ItemIcon>
                    <PeopleAlt />
                </ItemIcon>
                <ItemName>
                    <div className={"w-full h-full flex flex-col justify-start items-start"}>
                        <span>{user?.user?.firstName + " " + user?.user?.lastName.charAt(0)}</span>
                        <span className={"text-xs text-primary-main"}>{"My Profile"}</span>
                    </div>
                </ItemName>
            </Item>
            {RightLoggedInMenuItems?.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                <Item key={index} onClick={() => handleOnClickItem(dataItem)}>
                    <ItemIcon>
                        <dataItem.icon />
                    </ItemIcon>
                    <ItemName>
                        {dataItem?.name}
                    </ItemName>
                </Item>
            ))}
        </ToggleMenuContent>
    )
}

export default RightLogedInToggleMenu
