import tempCourseService from "redux/template/template.service";
import {
    setTempCourseListLoading,
    setTempCourseList,
    setTempCourseListErrorMsg
} from "redux/template/template.slice";
import { tempStatus, tempIsVerified } from 'redux/template/template.const';
import { bookingType } from "redux/booking/booking.const";


export const getTempCourseList = () => async (dispatch) => {
    dispatch(setTempCourseListLoading(true))

    try {
        const requestData = {
            query: {
                tempStatus: tempStatus.PUBLISHED.value,
                tempIsVerified: tempIsVerified.VERIFIED.value,
                bookingType: bookingType.REGULAR.value
            }
        }
        let response = await tempCourseService.getTempCourseList(requestData)
        if (response.status === 200) {
            dispatch(setTempCourseList(response.data))
        }
        else {
            dispatch(setTempCourseListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setTempCourseListErrorMsg(error.message))
    } finally {
        dispatch(setTempCourseListLoading(false))
    }
}