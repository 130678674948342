export const dataFAQ = [
    {
        question: "What are Edulyte’s online tutors expected to do?",
        answer: "Our tutors are the face of our Company. You are expected to provide interactive and thought-provoking lessons to our students. Continuous improvisation is encouraged."
    },
    {
        question: "Why should I become a tutor with Edulyte?",
        answer: "Teaching enables you to transform lives with your knowledge and passion. We give importance to our tutors and enable them to pick up skills that are expected to shape their future."
    },
    {
        question: "Will my students be only from my home country?",
        answer: "Although Edulyte has its roots in Australia and India, you will have the enriching experience of teaching students from anywhere in the world."
    },
    {
        question: "I do not have an online tutoring experience? Can I still apply to Edulyte?",
        answer: "Of course! You will be welcomed into our family if you have an appetite for learning and a zeal for teaching. We ask aspirants to go through our free tutoring fundamentals course to equip themselves before taking their first lesson."
    },
    {
        question: "What is the recruitment process?",
        answer: "Step 1: Submit your resume and an introductory video and fill out the application form on our site. Step 2: If you meet our criteria, you will be interviewed.Step 3: You will be notified about the result of the interview within 2 days.Step 4: Depending on the outcome, an offer will be made to you to teach our students and change the world."
    },
    {
        question: "What qualification should I have to be a tutor with Edulyte?",
        answer: "Industry based qualification meeting the requirements of Tutoring Fundamentals (CORE) modules:	Managing the Tutoring Environment •	How Students Learn •	Reporting •	An Introduction to Differentiation •	Child Protection Strong academic background and a thorough understanding of the online education system. Strong fluency in English language, as your students are going to be from different parts of the world.A zeal for teaching and finding innovative ways to educate the learners."
    },
    {
        question: "Which video conferencing tool should I have to be a tutor with Edulyte ?",
        answer: "We have an integrated video conferencing tool to conduct classes. In the near future, we will add zoom and other popular solutions."
    },
    {
        question: "Do I have to tutor for a certain number of hours every day?",
        answer: "We allow you to fix a schedule that suits you. You can take classes whenever you want, on weekdays or weekends. But yes, we expect you to respect your commitment once you have made it."
    },
    {
        question: "What happens if there is a technological issue?",
        answer: "We understand that there may be a tech snag once in a while. We give you the authority to reschedule your class that has been affected by tech issues, after consulting with your student."
    },
    {
        question: "Do I have to look for students after joining Edulyte?",
        answer: "We will do all the marketing for you, however, sharing your profile and courses with your network will help you build your brand and trust. After you are on board, we get you students as per your availability and requirement, it should not be treated as a guarantee. Our team will keep sharing the best practices to succeed in this industry."
    },
    {
        question: "Can I tutor more than one subject on Edulyte?",
        answer: "Of course! In case you have expertise in more than one subject, you are welcome to teach them on our platform."
    }
];