import tw, { styled } from "twin.macro";

export const StyldChatViewPaymentRequestContainer = styled.div(
  ({ isSender }) => [tw`flex items-center mb-2 `, isSender && tw`justify-end`]
);

export const StyldChatViewPaymentRequest = styled.div(({ isSender }) => [
  tw`flex flex-col py-2.5 rounded-md  overflow-hidden shadow-md width[250px]`,
  isSender && tw`bg-primary-dark text-white`,
]);

export const StyledChatViewPaymentRequestCourse = styled.div`
  ${tw`mx-2.5 text-center font-semibold`}
`;

export const StyledChatViewPaymentRequestAmount = styled.div`
  ${tw`mx-2.5 text-center`}
`;

export const StyledChatViewPaymentRequestStatus = styled.div(({ isSender }) => [
  tw`mx-2.5 py-1 mt-2.5 font-semibold text-center bg-primary-main text-white rounded-md shadow-2xl cursor-pointer`,
  isSender && tw`bg-primary-main`,
]);

export const StyledChatViewPaymentRequestTime = styled.p(({ isSender }) => [
  tw`italic text-xs text-gray-500 flex justify-end mr-2.5 mt-1.5`,
  isSender && tw`text-white`,
]);
