import { useEffect } from 'react';

import { PageContentStyle } from './TeachWithUsPageStyle';

import TeachWithUsHero from './teachWithUsHero/TeachWithUsHero';
import TeachWithUsFeatured from './teachWithUsFeatured/TeachWithUsFeatured';
import BenifitsOfOnlineTutoring from './benifitsOfOnlineTutoring/BenifitsOfOnlineTutoring';
import TeachWithUsHowItWork from './teachWithUsHowItWork/TeachWithUsHowItWork';
import TeachWithUsFAQ from './teachWithUsFAQ/TeachWithUsFAQ';
import Footer from 'components/footer/Footer';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';


const TeachWithUsPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TEACH_WITH_US))
    }, [dispatcher, currentPageInfo])

    return (
        <PageContentStyle>
            <TeachWithUsHero />
            <TeachWithUsFeatured />
            <BenifitsOfOnlineTutoring />
            <TeachWithUsHowItWork />
            <TeachWithUsFAQ />
            <Footer />
        </PageContentStyle>
    )
}

export default TeachWithUsPage;
