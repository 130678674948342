import documentService from 'redux/document/document.service';
import {
    setUploadDocumentLoading,
    setUploadDocument,
    setUploadDocumentErrorMsg,
} from 'redux/document/document.slice'
import { generateFormData } from './document.utils';


export const uploadDocumentToS3 = () => async (dispatch, getState) => {
    dispatch(setUploadDocumentLoading(true));

    const { uploadDocument } = getState().document
    const { fileCategory, fileName, fileType, file } = uploadDocument?.uploadDocumentPayload

    try {
        const requestData = {
            body: { fileCategory, fileName, fileType }
        }
        const response = await documentService.generateS3PresignedPost(requestData)
        if (response.status === 200) {
            const { presignedData } = response.data
            presignedData.fields["file"] = file
            const formData = await generateFormData(presignedData)
            const uploadData = {
                s3PresignedUrl: presignedData.url,
                formData: formData
            }
            await documentService.uploadDocumentToS3(uploadData)
            dispatch(setUploadDocument({ documentUrl: presignedData.url + presignedData.fields.key }))
        } else {
            dispatch(setUploadDocumentErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setUploadDocumentErrorMsg(error.message))
    } finally {
        dispatch(setUploadDocumentLoading(false));
    }
}