import clsx from "clsx";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";

import { slotStatus, interviewerDetails, selectedslotTableHeader } from "pages/auth/tutorOnboard/TutorOnboardData";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setTutorOnboardingPayload, setClearAddInterviewPayload } from "redux/tutorOnboarding/tutorOnboarding.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';

const InterviewSlotSelection = ({ task }) => {
    const { addInterviewSlot } = useAppState((s) => s.tutorOnboarding)
    const { modal } = useAppState(s => s.local)
    const addInterviewPayload = addInterviewSlot?.addInterviewSlotPayload
    const selectedTimeSlot = addInterviewPayload?.weeklyTimeSlot[dayjs(addInterviewPayload?.startDate).format('dddd').toString()]
    const [openSelectedSlotTable, setOpenSelectedSlotTable] = useState(false)
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (addInterviewPayload && addInterviewPayload.startDate !== null) {
            task.status = slotStatus.statusName.submitted
            setOpenSelectedSlotTable(true)
        }
    }, [addInterviewPayload])

    const handleEditDocument = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]: true
        }))
    }

    const handleDeleteEntry = () => {
        task.status = slotStatus.statusName.not_submitted
        dispatcher(setClearAddInterviewPayload())
        setOpenSelectedSlotTable(false)
    }

    return (
        <div className="">
            {openSelectedSlotTable &&
                <table className="table-fixed pt-5">
                    <thead>
                        <tr>
                            {selectedslotTableHeader.map((head, index) => (
                                <th
                                    key={index}
                                    className={clsx(
                                        "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                        "font-bold whitespace-nowrap border border-gray-200"
                                    )}
                                >
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-text-200">
                        <tr className={''} >
                            <td className="border border-gray-200">
                                <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                    {interviewerDetails?.interviewerProfile?.firstName + " " + interviewerDetails?.interviewerProfile?.lastName.charAt(0) + " ."}
                                </span>
                            </td>
                            <td className={"overflow-hidden border border-gray-200"}>
                                <span
                                    className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center'>
                                    {dayjs(addInterviewPayload?.startDate).format('YYYY-MM-DD').toString()}
                                </span>
                            </td>
                            <td className={"overflow-hidden border border-gray-200"}>
                                {selectedTimeSlot?.map((timeSlot) => (
                                    <span className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center'>
                                        {dayjs(addInterviewPayload?.startDate + " " + timeSlot.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
                                    </span>
                                ))}
                            </td>
                            <td className={"overflow-hidden border border-gray-200"}>
                                <span className="px-2 font-bodyPri font-normal text-text-900 text-base italic text-center">
                                    {task.status}
                                </span>
                            </td>
                            <td className={"px-5 py-2 border border-gray-200"}>
                                <FiEdit
                                    className={"text-xl text-primary-main cursor-pointer hover:text-primary-dark"}
                                    onClick={handleEditDocument}
                                />
                            </td>
                            <td className={"px-5 py-2 border border-gray-200"}>
                                <GiCancel
                                    className={"text-xl text-text-500 hover:text-text-600 cursor-pointer"}
                                    onClick={handleDeleteEntry}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
        </div>
    )
}

export default InterviewSlotSelection;