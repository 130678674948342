import clsx from 'clsx';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddBookingPayload } from 'redux/booking/booking.slice';
import { courseType } from 'redux/course/course.const';


const SessionCount = ({ count }) => {
    const { addBooking } = useAppState((s) => s.booking)
    const { selectedCourse } = useAppState((s) => s.course)
    const addBookingPayload = addBooking?.addBookingPayload

    const dispatcher = useAppDispatcher()

    const getCoursePrice = () => {
        let sellingPrice = 0
        if (selectedCourse?.selectedCourse?.courseType === courseType.ONE_ON_ONE.value) {
            sellingPrice = parseFloat(selectedCourse?.selectedCourse?.price / 2.0) * (addBookingPayload?.sessionDuration / 30)
            sellingPrice = parseFloat(sellingPrice * ((100 - selectedCourse?.selectedCourse?.discountPct) / 100))
            if (count === 5) {
                sellingPrice = parseFloat(sellingPrice * ((100 - selectedCourse?.selectedCourse?.volumePctDiscount) / 100))
            }
        }

        if (selectedCourse?.selectedCourse?.courseType === courseType.GROUP.value) {
            sellingPrice = parseFloat(selectedCourse?.selectedCourse?.price)
            sellingPrice = parseFloat(sellingPrice * ((100 - selectedCourse?.selectedCourse?.discountPct) / 100))
        }

        return sellingPrice * count
    }

    return (
        <div className={"flex flex-col items-center gap-2"}>
            <div onClick={() => dispatcher(setAddBookingPayload({ ...addBookingPayload, noOfSessions: count }))}
                className={clsx(
                    "flex items-center justify-center gap-1 px-5 py-1 w-fit rounded-lg cursor-pointer",
                    "font-bodyPri font-normal text-sm",
                    addBookingPayload?.noOfSessions === count
                        ? "bg-primary-dark text-text-50"
                        : "bg-primary-light text-primary-dark"
                )}
            >
                {count + (count > 1 ? " sessions" : "session")}
            </div>
            <div className={clsx(
                "font-bodyPri font-normal text-sm text-center",
                addBookingPayload?.noOfSessions === count
                    ? "text-primary-dark"
                    : "text-text-800"
            )}>
                {"USD "}
                {parseFloat(getCoursePrice() / 100).toFixed(2)}
            </div>
            {(count === 5 && selectedCourse?.selectedCourse?.volumePctDiscount > 0) &&
                <div className={clsx(
                    "text-xs text-text-800 text-center"
                )}>
                    {parseFloat(selectedCourse?.selectedCourse?.volumePctDiscount).toFixed(2) + "% OFF"}
                </div>
            }
        </div>
    )
}

export default SessionCount