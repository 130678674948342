import { Link } from "react-router-dom";

import { SiGooglecalendar } from "react-icons/si";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { modalConst } from "redux/local/local.const";
import { setModal } from "redux/local/local.slice";

export const CalenderPage = () => {
  const { modal } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()

  const handleOpenCalendar = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.COMING_SOON_MODAL.stateKey]: true
    }))
  }

  return (
    <div>
      <div className="py-4 mx-8 border-b">
        <p className="text-lg text-center">Calendar Settings</p>
      </div>

      <div className="max-w-md mx-auto">
        <p className="text-lg text-center text-slate-500">
          Connect Your Google Calendar To Edulyte Appointment Scheduling System
        </p>
        <div className="flex justify-center my-6">
          <SiGooglecalendar size={50} />
        </div>
        <div>
          <p className="text-sm text-center text-slate-500">
            Google Calendar is free time management software offered by google
          </p>
        </div>

        <div className="flex justify-center my-4">
          <button className="px-4 py-1 text-white bg-blue-700 rounded-2xl" onClick={handleOpenCalendar}>
            {" Connect"}
          </button>
        </div>

        <div className="my-10 opacity-80">
          <p className="text-center">
            Update your weekly schedule here{" "}
            <Link to={"/preferences/availability"} className="px-3 py-1 border rounded-xl cursor-pointer">
              Availability
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
