import { useEffect } from 'react';

import { cols } from "pages/auth/referral/data";

import Refer from "pages/auth/referral/Refer";
import HowWork from "pages/auth/referral/HowWork";
import Referrals from "pages/auth/referral/Referrals";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';


const ReferralPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.REFERRAL))
  }, [dispatcher, currentPageInfo])

  return (
    <div id="refer" className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
      <Refer />
      <HowWork />
      <Referrals cols={cols} />
    </div>
  );
};

export default ReferralPage;
