import clsx from "clsx";
import { useState } from "react";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/booking/booking.slice";
import { filterName } from "redux/booking/booking.const";
import { userRoles } from "redux/auth/auth.const";

const MyBookingSearch = () => {
    const { bookingList, filterProps } = useAppState((s) => s.booking);
    const { locals } = useAppState((s) => s.local)
    
    const dispatcher = useAppDispatcher();
    
    const [searchText, setSearchText] = useState("");

    return (
        <div className="flex justify-center p-4 bg-white rounded-lg">
            <div className="inline-flex mx-auto shadow-sm">
                <input
                    type="text"
                    className={clsx(
                        "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                        "border-sky-200 w-full sm:w-80"
                    )}
                    placeholder={locals.userRole === userRoles.TUTOR.value ? "Student name..." : "Tutor name..."}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>
                    <button
                        className="px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white"
                        onClick={() => {
                            dispatcher(setFilterProps({
                                filterProps: { ...filterProps, searchText: searchText },
                                list: bookingList?.bookingList?.results,
                                filterName: filterName.BOOKING.value
                            }));
                        }}
                    >
                        {"Search"}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default MyBookingSearch;
