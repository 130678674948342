import {
  MOCK_AVAILABILITIES,
  MOCK_LESSON,
  MOCK_OTHER_PREFERENCES,
  MOCK_PRICING_PREFERENCES,
} from "./mock-data";

export type AvailabilityRange = {
  _id: number;
  startTime: string;
  endTime: string;
};

export type AvailabilityStatus = {
  day:
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday"
    | "Sunday";
  schedule: AvailabilityRange[];
};

export type OffDaysAvailability = {
  date: string;
  message: string;
  schedule: AvailabilityRange[];
};

export type PreferenceStatus = {
  id: string;
  title: string;
  description: string;
  status: boolean;
};

export type PricingPreferences = {
  trial_lesson: {
    time: number;
    price: number;
  };
  minimum_hourly_price_one_to_one: number;
  minimum_hourly_price_group: number;
  discount: number;
};

export type SubjectPreference = {
  _id: number;
  subject: string;
  preferredStudentLevel: string;
};

export type Address = {
  _id: number;
  billingAddress: string;
  shippingAddress: string;
};

export type BillingPreference = {
  bussinessType: string;
  GSTStatus: boolean;
  GSTNumber?: string;
  defaultAddress?: number;
  addresses: Address[];
};

export type PreferencesState = {
  availabilities: AvailabilityStatus[];
  lessonPreferences: PreferenceStatus[];
  otherPreferences: PreferenceStatus[];
  pricingPreferences: PricingPreferences;
  subjectPreferences: SubjectPreference[];
  billingPreferences: BillingPreference;
  offDaysAvailabilities: OffDaysAvailability[];
};

export const INITIAL_STATE: PreferencesState = {
  availabilities: MOCK_AVAILABILITIES,
  offDaysAvailabilities: [],
  lessonPreferences: MOCK_LESSON,
  otherPreferences: MOCK_OTHER_PREFERENCES,
  pricingPreferences: MOCK_PRICING_PREFERENCES,
  subjectPreferences: [],
  billingPreferences: {
    GSTStatus: false,
    addresses: [],
    bussinessType: "Freelancer",
  },
};
