import { useEffect } from "react";
import clsx from "clsx";

import { FiEdit } from "react-icons/fi";

import { userSocialMediaLinksConst } from "pages/auth/settings/settings.data";
import SaveAndCancelBtn from "pages/auth/settings/components/SaveAndCancelBtn";

import {
  getUserSocialMediaLinks,
  updateUserSocialMediaLink,
} from "redux/user/user.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";

const MySocialMediaPresence = ({ editBtnConst, setEditBtnConst }) => {
  const { userSocialMediaLinks } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getUserSocialMediaLinks())
  }, [])

  const onHandleSave = (key, value) => {
    if (value?.includes("https://")) {
      alert("Invalid link!")
      return;
    }
    dispatcher(updateUserSocialMediaLink({ [key]: value }))
  }


  return (
    <div className={"flex flex-col items-start justify-start gap-5"}>
      <span className='text-xl font-medium text-text-800 font-bodyPri tracking-wide'>
        {"My Social Media Presence:"}
      </span>

      {userSocialMediaLinks?.userSocialMediaLinks?.map((socialLinks) => (
        <div className={"grid grid-cols-12 gap-3"} key={socialLinks?.linkId}>
          <span className={"col-start-0 col-span-2 font-bodyPri font-semibold text-text-800 text-md"}>
            {userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].label}
          </span>
          {editBtnConst !== userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].value &&
            <span className={"col-start-3 col-span-3 font-bodyPri font-normal text-text-600 text-sm"}>
              {socialLinks?.userSocialLinks}
            </span>
          }
          {editBtnConst !== userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].value &&
            <span className={"col-start-7 col-span-1"}>
              <FiEdit className="text-lg text-text-500 cursor-pointer"
                onClick={() => {
                  setEditBtnConst(userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].value)
                }}
              />
            </span>
          }
          {editBtnConst === userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].value &&
            <div className={"col-start-0 col-span-full flex flex-col justify-start items-start gap-3"}>
              <div className={"flex flex-col items-start justify-start gap-1"}>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                  {userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].demoKey1}
                </span>
                <span className={"font-bodyPri font-normal text-text-600 text-base"}>
                  {userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].demoKey2}
                </span>
              </div>
              <input
                type={"text"}
                autoFocus={true}
                placeholder={userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].placeholder}
                className={clsx(
                  "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                  "font-bodyPri font-normal text-text-800 text-base",
                  "placeholder:text-text-500 placeholder:text-sm"
                )}
                value={socialLinks?.userSocialLinks?.split(".com/")[1]}
                onChange={(event) => {
                  dispatcher(updateUserSocialMediaLink(socialLinks?.socialLinId, { userSocialLinks: userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].socialLinkUrl + event.target.value }))
                }}
              />
              <SaveAndCancelBtn
                onCancel={() => setEditBtnConst(null)}
                onSave={() => onHandleSave()}
              />
            </div>
          }
        </div>
      ))}
    </div>
  )
}

export default MySocialMediaPresence