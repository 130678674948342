import { AiFillWallet } from "react-icons/ai";
import {
  BsFillCameraVideoFill,
  BsFillStarFill,
  BsPeople,
} from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { ImClock } from "react-icons/im";
import { IoIosFlash } from "react-icons/io";
import {RiWirelessChargingFill} from 'react-icons/ri';
import { MdAccountBalanceWallet } from 'react-icons/md'
import { AiFillPlusCircle } from 'react-icons/ai'
import { DashboardPage, Schedule } from "./types";

export const getSelectedDaySchedule = (selectedDate: Date) => {
  const schedules: Schedule[] = [
    {
      id: "1",
      from: "11:00 AM",
      to: "12:00 PM",
      type: "session",
      display: {
        profile: "https://i.pravatar.cc/50",
        name: "John Doe",
      },
    },
    {
      id: "1",
      from: "10:30 AM",
      to: "11:00 AM",
      type: "session",
      display: {
        profile: "https://i.pravatar.cc/50",
        name: "Sunny Singh",
      },
    },
    {
      id: "1",
      from: "2:30 PM",
      to: "3:00 PM",
      type: "session",
      display: {
        profile: "https://i.pravatar.cc/50",
        name: "Jane Doe",
      },
    },
    {
      id: "2",
      to: "02:00 PM",
      from: "01:00 PM",
      type: "break",
    },
    {
      id: "2",
      to: "06:00 PM",
      from: "04:00 PM",
      type: "break",
    },
    {
      id: "1",
      from: "6:30 PM",
      to: "8:00 PM",
      type: "session",
      display: {
        profile: "https://i.pravatar.cc/50",
        name: "Jane Doe",
      },
    },
  ];

  return schedules;
};

export const MOCK_DASHBOARD_DATA: DashboardPage = {
  _meta: {
    dashboardSideOverVisible: false,
  },
  schedules: [
    {
      id: "1",
      from: "10:30 AM",
      to: "11:00 AM",
      type: "session",
      display: {
        profile: "https://i.pravatar.cc/50?img=6",
        name: "John Doe",
      },
    },
    {
      id: "1",
      from: "11:00 AM",
      to: "12:00 PM",
      type: "session",
      display: {
        profile: "https://i.pravatar.cc/50?img=9",
        name: "John Doe",
      },
    },
    {
      id: "2",
      to: "01:00 PM",
      from: "02:00 PM",
      type: "break",
    },
  ],
  tier: {
    name: "bronze",
    label: "Bronze Tier",
  },
  heroCard: {
    description:
      "You completed 350 sessions in 90 days! Your current teaching score is 4500",
    title: "Welcome back, ",
  },
  statistics: [
    {
      title: "Hours Taught",
      content: "499",
      icon: <ImClock size={30} color="#ff4081" />,
      unit: "Hours",
    },
    {
      title: "Learners",
      content: "30",
      icon: <CgProfile size={30} color="#ff4081" />,
      unit: "students",
    },
    {
      title: "Sessions Conducted",
      content: "350",
      icon: <BsFillCameraVideoFill size={30} color="#ff4081" />,
      unit: "Sessions",
    },
    {
      title: "My Balance",
      content: "1500",
      icon: <AiFillWallet size={30} color="#ff4081" />,
      unit: "$",
    },
    {
      title: "Reviews Earned",
      content: "25",
      icon: <BsFillStarFill size={30} color="#ff4081" />,
      unit: "4 star and above",
    },
    {
      title: "Bookings",
      content: "35",
      icon: <IoIosFlash size={30} color="#ff4081" />,
      unit: "Scheduled & pending",
    },
  ],
  Studentstatistics: [
    {
      title: "My Balance",
      content: "$ 200",
      icon: <MdAccountBalanceWallet size={30} color="#ff4081" />,
      addIcon: <AiFillPlusCircle size={30} color= '#0049b7'/>, 
    },
    {
      title: "Lessons Scheduled",
      content: "3",
      icon: <RiWirelessChargingFill size={30} color="#ff4081" />,
      addIcon: <AiFillPlusCircle size={30} color='#0049b7' />,
    },
  ],
  students: [
    {
      profile: "https://i.pravatar.cc/50?img=3",
      name: "John Doe",
    },
    {
      profile: "https://i.pravatar.cc/50?img=2",
      name: "Jane Doe",
    },
    {
      profile: "https://i.pravatar.cc/50?img=21",
      name: "Sunny Singh",
    },
    {
      profile: "https://i.pravatar.cc/50?img=23",
      name: "Eve Doe",
    },
    {
      profile: "https://i.pravatar.cc/50?img=22",
      name: "Wendy Doe",
    },
  ],
  teachers: [
    {
      teacherProfile: "https://i.pravatar.cc/50?img=3",
      teacherName: "John Doe",
    },
    {
      teacherProfile: "https://i.pravatar.cc/50?img=2",
      teacherName: "Jane Doe",
    },
    {
      teacherProfile: "https://i.pravatar.cc/50?img=21",
      teacherName: "Sunny Singh",
    },
    {
      teacherProfile: "https://i.pravatar.cc/50?img=23",
      teacherName: "Eve Doe",
    },
    {
      teacherProfile: "https://i.pravatar.cc/50?img=22",
      teacherName: "Wendy Doe",
    },
  ],
  messages: [
    {
      firstName: "Uhn Ji",
      lastName: "Doe",
      profilePicUrl: "https://i.pravatar.cc/50?img=1",
      lastMessage: "Hello, how are you doing today? Hello, how are you doing today? Hello, how are you doing today? Hello, how are you doing today? Hello, how are you doing today?",
    },
    {
      firstName: "Jim Doe",
      lastName: "May",
      profilePicUrl: "https://i.pravatar.cc/50?img=4",
      lastMessage: "Hey, thank you for everything.",
    },
    {
      firstName: "John Doe",
      lastName: "Nayab",
      profilePicUrl: "https://i.pravatar.cc/50?img=9",
      lastMessage: "Hey, thanks.",
    },
    {
      firstName: "Karishma",
      lastName: "Doe",
      profilePicUrl: "https://i.pravatar.cc/50?img=10",
      lastMessage: "I have doubts regarding ......",
    },
    {
      firstName: "Krish",
      lastName: "Doe",
      profilePicUrl: "https://i.pravatar.cc/50?img=12",
      lastMessage: "Can we have a call tomorrow?",
    },
  ],
  upcomingClasses: [
    {
      classTitle: "Uhn Ji",
      classCoverPicUrl: "https://i.pravatar.cc/50?img=1",
    },
    {
      classTitle: "Jim Doe",
      classCoverPicUrl: "https://i.pravatar.cc/50?img=4",

    },
    {
      classTitle: "John Doe",
      classCoverPicUrl: "https://i.pravatar.cc/50?img=9",

    },
    {
      classTitle: "Karishma",
      classCoverPicUrl: "https://i.pravatar.cc/50?img=10",
    },
    {
      classTitle: "Krish",
      classCoverPicUrl: "https://i.pravatar.cc/50?img=12",
    },
  ],
  offerings: [
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
  ],
  liveClasses: [
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
    {
      thumbnail: "https://via.placeholder.com/150",
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo nostrum voluptatum, exercitationem distinctio assumenda? Corporis vel ex, error dolores porro quibusdam exercitationem, dolor consequuntur dolorem quia placeat, distinctio pariatur!",
      title: "Business English",
      footer: "Intermediate | 10 lessons",
    },
  ],
  videos: [
    {
      thumbnail: "https://www.youtube-nocookie.com/embed/Ewj85eIda74",
      title: "Nouns | Common Nounse | Pronouns | Learn Grammar",
    },
    {
      thumbnail: "https://www.youtube-nocookie.com/embed/Ewj85eIda74",
      title: "Nouns | Common Nounse | Pronouns | Learn Grammar",
    },
    {
      thumbnail: "https://www.youtube-nocookie.com/embed/Ewj85eIda74",
      title: "Nouns | Common Nounse | Pronouns | Learn Grammar",
    },
    {
      thumbnail: "https://www.youtube-nocookie.com/embed/Ewj85eIda74",
      title: "Nouns | Common Nounse | Pronouns | Learn Grammar",
    },
  ],
};
