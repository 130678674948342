import { userRoles } from 'redux/auth/auth.const';
import { localsConst } from 'redux/local/local.const';
import { timeZone } from 'utils/dateTime.utils';


const setDefaultLocalStorage = (key) => {
    switch (key) {
        case localsConst.USER_ROLE.value:
            localStorage.setItem(key, userRoles.STUDENT.value)
            break;
        case localsConst.TIME_ZONE.value:
            localStorage.setItem(key, timeZone)
            break;
        case localsConst.CURRENCY.value:
            localStorage.setItem(key, "usd")
            break;
        default:
            break;
    }
}

export const setAndGetLocalStorage = (key, value = null) => {
    let local = localStorage.getItem(key)
    if (!local) {
        setDefaultLocalStorage(key)
    }
    if (value) {
        localStorage.setItem(key, value)
    }
    local = localStorage.getItem(key)
    return local
}

export const setMyLocals = (locals) => {
    Object.keys(locals).forEach(localKey => {
        setAndGetLocalStorage(localKey, locals[localKey])
    })
}