import { useNavigate } from 'react-router-dom';

import { AiFillInfoCircle } from 'react-icons/ai';

import { Container } from "pages/auth/walletConfirmation/WalletConfirmationPageStyle";

import { useAppState } from 'hooks/useStore';
import { bookingStatus } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';


const PendingBookingConfirmation = () => {
    const { bookingConfirmation } = useAppState((state) => state.booking)

    const navigate = useNavigate()

    const pageRedirect = () => {
        navigate(pagesInfo.BOOKINGS.pagePath, { replace: true })
    }

    return bookingConfirmation?.bookingConfirmation ? (
        <Container className={"!shadow-all-md"}>
            <AiFillInfoCircle className={"text-8xl text-orange-500"} />
            <div className={"flex items-center justify-center gap-2 font-bodyPri font-semibold text-2xl text-primary-dark"}>
                <span>{"USD"}</span>
                <span>{parseFloat(bookingConfirmation?.bookingConfirmation?.purchasedPrice / 100).toFixed(2)}</span>
            </div>
            <div className={"flex flex-col items-center gap-2"}>
                <span className={"font-bodyPri font-medium text-md text-text-800"}>
                    {"You have purchased the course"}
                </span>
                <span className={"font-bodyPri font-semibold text-lg text-orange-500 tracking-wider"}>
                    {bookingStatus.PENDING.label}
                </span>
            </div>
            <div className={"flex items-center justify-center gap-2"}>
                <span className={"font-bodyPri font-normal text-base text-800"}>
                    {"Booking ID:"}
                </span>
                <span className={"font-bodyPri font-semibold text-md text-text-900"}>
                    {bookingConfirmation?.bookingConfirmation?.bookingId}
                </span>
            </div>
            <div onClick={pageRedirect}>
                <span className={"px-5 py-1.5 rounded-full cursor-pointer font-buttons font-medium text-md text-secondary-main border-2 border-secondary-main hover:text-text-50 hover:bg-secondary-main"}>
                    {"Confirm Booking"}
                </span>
            </div>
        </Container>
    ) : null
}

export default PendingBookingConfirmation