export const pageHeading = {
    heading: "Get the help you need",
    subHeading: "Our team is here to help you succeed, there are many ways to get support!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/support.svg"
}

export const SupportActionList = [
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/knowledge-base.svg",
        text: "Knowledge Base"
    },
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/send-an-email.svg",
        text: "Send an email"
    },
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/create-a-ticket.svg",
        text: "Create a ticket"
    },
    {
        image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/support-page/chat-with-us.svg",
        text: "Chat with us"
    }
]

export const actionTextConst  = {
    KNOWLEDGE_BASE: {
        label: "Knowledge Base",
        value: "knowledge_base"
    },
    SEND_AN_EMAIL: {
        label: "Send an email",
        value: "send_an_email"
    },
    CREATE_A_TICKET: {
        label: "Create a ticket",
        value: "create_a_ticket"
    },
    CHAT_WITH_US: {
        label: "Chat with us",
        value: "chat_with_us"
    }
}