import clsx from "clsx";
import * as React from "react";
import { useAppState } from "hooks/useStore";
import { GiftCardStep, StepValues } from "../store/types";

export const GiftCardUsageCard = () => {
  const activeState: GiftCardStep = useAppState((s) => s.buyGiftCard.activeStep);
  return (
    <div className="max-w-4xl p-4 mx-4 shadow-lg md:mx-auto rounded-xl">
      <p className="pb-4 text-lg font-semibold tracking-wide text-center">
        Buy a gift card for special someone
      </p>
      <div className="grid grid-cols-3 py-2 gap-x-8">
        <div
          className={clsx(
            "inline-flex flex-col p-4 ",
            "border border-t-4 rounded-lg shadow-md ",
            StepValues[activeState] >= 1 && "border-lime-500"
          )}
        >
          <p className="text-sm font-semibold tracking-wider">Step 1</p>
          <p>Choose a digital gift card</p>
        </div>
        <div
          className={clsx(
            "inline-flex flex-col p-4 ",
            "border border-t-4 rounded-lg shadow-md ",
            StepValues[activeState] >= 2 && "border-lime-500"
          )}
        >
          <p className="text-sm font-semibold tracking-wider">Step 2</p>
          <p>Add details and pay</p>
        </div>
        <div
          className={clsx(
            "inline-flex flex-col p-4 ",
            "border border-t-4 rounded-lg shadow-md ",
            StepValues[activeState] >= 4 && "border-lime-500"
          )}
        >
          <p className="text-sm font-semibold tracking-wider">Step 3</p>
          <p>Delivery by email</p>
        </div>
      </div>
    </div>
  );
};
