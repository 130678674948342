import {
    Container,
    Content,
    ItemImage,
    ItemContent,
    Header,
    Heading,
    SubHeading,
    Lists,
    ListItem,
    AppStoreContainer,
    AppStore,
    AppIcon,
} from './LearningAppStyle';

import { appData } from './LearningAppData';
import { ReactComponent as PlayStore } from './LearningAppImages/Play-Store.svg';
import { ReactComponent as AppleStore } from './LearningAppImages/Apple-Store.svg';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const LEARNING_APP_URL = 'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/Edusanta+Learning+App.png';

const LearningApp = () => {
    const { modal } = useAppState((s) => s.local)
    const dispatcher = useAppDispatcher()

    const handleAppStoreClick = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COMING_SOON_MODAL.stateKey]: true
        }))
    }

    return (
        <Container>
            <Content>
                <ItemImage>
                    <img src={LEARNING_APP_URL} alt={'learning-app'} />
                </ItemImage>
                <ItemContent>
                    <Header>
                        <Heading>{'Edulyte'}<sup>&#x00AE;</sup>{' learning app'}</Heading>
                        <SubHeading>{'Learn from anywhere, anytime'}</SubHeading>
                    </Header>
                    <Lists>
                        {appData.map((item, index) => (
                            <ListItem key={index}>{item.listItem}</ListItem>
                        ))}
                    </Lists>
                    <AppStoreContainer onClick={handleAppStoreClick}>
                        <AppStore to={'#'}>
                            <AppIcon>
                                <PlayStore />
                            </AppIcon>
                            GOOGLE PLAY
                        </AppStore>
                        <AppStore to={'#'}>
                            <AppIcon>
                                <AppleStore />
                            </AppIcon>
                            APP STORE
                        </AppStore>
                    </AppStoreContainer>
                </ItemContent>
            </Content>
        </Container>
    )
}

export default LearningApp
