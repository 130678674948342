import { useState } from 'react';
import clsx from "clsx";

import { FaCheck } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

import { Button, IconWithTextButton } from "components/common-components/Buttons";
import { DatePicker, InputWithLabel } from "components/common-components/Form";

import TimeRange from './TimeRange';
import TimeRangeSelection from './TimeRangeSelection';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAvailability } from "redux/availability/availability.slice";

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { validateIsEndBeforeStart, validateIsTimeSlotExist, getIsTimeSlotBooked } from 'utils/availability.utils';


const NewOffDayCreationForm = ({ selectedOffDay, setSelectedOffDay, setShowAddForm }) => {
    const { availability } = useAppState((s) => s.availability)
    const { userEventList } = useAppState((s) => s.event)

    const dispatcher = useAppDispatcher();

    const [isAddNew, setIsAddNew] = useState(false);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null)

    const handleOnAdd = () => {
        if (isAddNew) {
            setSelectedTimeSlot(null)
        } else {
            setSelectedTimeSlot({
                startTime: dayjs().utc().format('HH:[00:00]'),
                endTime: dayjs().utc().format('HH:[15:00]')
            })
        }
        setIsAddNew(!isAddNew)
    }

    const handleOnCreate = () => {
        const day = dayjs(selectedOffDay.date).utc().format("dddd")
        if (validateIsEndBeforeStart(day, selectedTimeSlot)) {
            alert("End time should be after the start time!")
            return;
        }

        if (validateIsTimeSlotExist(day, selectedTimeSlot, selectedOffDay?.timeSlots)) {
            alert("Slot is already exist!")
            return;
        }

        const offDayDateTime = {
            start: dayjs(selectedOffDay.date + " " + selectedTimeSlot.startTime + "+00:00").utc().format(),
            end: dayjs(selectedOffDay.date + " " + selectedTimeSlot.endTime + "+00:00").utc().format()
        }

        if (getIsTimeSlotBooked(offDayDateTime, userEventList?.userEventList)) {
            alert("Slot are overlapping with booked session!")
            return;
        }

        let newOffDays = []
        selectedOffDay?.timeSlots?.forEach(item => {
            if (item.date === selectedOffDay?.date) {
                item = {
                    name: item.name,
                    date: item.date,
                    timeSlots: [...item.timeSlots, selectedTimeSlot]
                }
            }
            newOffDays.push(item)
        })

        setSelectedOffDay({
            ...selectedOffDay,
            timeSlots: [...selectedOffDay.timeSlots, selectedTimeSlot]
        })

        setIsAddNew(false)
    }

    const handleOnUpdate = (oldTimeSlot, newTimeSlot) => {
        const day = dayjs(selectedOffDay.date).utc().format("dddd")
        if (validateIsEndBeforeStart(day, newTimeSlot)) {
            alert("End time should be after the start time!")
            return;
        }

        let timeSlots = selectedOffDay.timeSlots.filter(slot => oldTimeSlot.startTime !== slot.startTime)

        if (validateIsTimeSlotExist(day, newTimeSlot, timeSlots)) {
            alert("Slot is already exist!")
            return;
        }

        const offDayDateTime = {
            start: dayjs(selectedOffDay.date + " " + newTimeSlot.startTime + "+00:00").utc().format(),
            end: dayjs(selectedOffDay.date + " " + newTimeSlot.endTime + "+00:00").utc().format()
        }

        if (getIsTimeSlotBooked(offDayDateTime, userEventList?.userEventList)) {
            alert("Slot are overlapping with booked session!")
            return;
        }

        timeSlots = selectedOffDay.timeSlots.map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
        ))

        setSelectedOffDay({ ...selectedOffDay, timeSlots: timeSlots })
    }

    const handleOnDelete = (selectedSlot) => {
        const timeSlots = selectedOffDay.timeSlots.filter(slot => slot.startTime !== selectedSlot.startTime)
        setSelectedOffDay({ ...selectedOffDay, timeSlots: timeSlots })
    }

    const handleOnCreateOffDay = () => {
        if (!selectedOffDay.name) {
            alert("Message required!")
            return;
        }
        if (!selectedOffDay.date) {
            alert("Date required!")
            return;
        }
        if (!selectedOffDay.timeSlots.length) {
            alert("At least one time range required!")
            return;
        }

        const offDays = availability?.availability?.offDays?.filter(offDay => offDay.date === selectedOffDay.date)

        if (offDays.length > 0) {
            alert("Day off is already exist!")
            return;
        }

        dispatcher(setAvailability({
            ...availability?.availability,
            offDays: [...availability?.availability?.offDays, selectedOffDay]
        }))

        setSelectedOffDay(null)
        setShowAddForm(false)
    }

    const handleOnDateChange = (newDate) => {
        newDate = dayjs(newDate).tz(timeZone).format('YYYY-MM-DD')
        const offDayHasBooked = selectedOffDay?.timeSlots?.filter(slot => {
            const offDayDateTime = {
                start: dayjs(newDate + " " + slot.startTime + "+00:00").utc().format(),
                end: dayjs(newDate + " " + slot.endTime + "+00:00").utc().format()
            }
            if (getIsTimeSlotBooked(offDayDateTime, userEventList?.userEventList)) {
                return true
            }
            return false
        })

        if (offDayHasBooked.length > 0) {
            alert("Slot are overlapping with booked session!")
            return;
        }

        setSelectedOffDay({
            ...selectedOffDay,
            date: newDate
        })
    }


    return (
        <div className="p-4 border rounded-lg shadow-sm">
            <div className={""}>
                <InputWithLabel
                    onChange={(event) => setSelectedOffDay({ ...selectedOffDay, name: event.target.value })}
                    placeholder="Reason for Offday (private to you only)"
                    value={selectedOffDay.name}
                    label="Message"
                    className="py-3"
                />
                <div className={""}>
                    <p>Select Date</p>
                    <DatePicker
                        date={selectedOffDay.date}
                        onDateChange={(newDate) => handleOnDateChange(newDate)}
                    />
                </div>
            </div>

            {/* Add new schedule */}
            <div className={"flex items-center justify-center"}>
                <div onClick={handleOnAdd} className="p-2 my-2 w-fit flex items-center justify-center gap-2 border border-text-300 rounded-lg cursor-pointer group hover:scale-105">
                    {!isAddNew &&
                        <p className="font-bold text-base tracking-wide text-center text-primary-main group-hover:text-primary-dark">
                            {"Add Time Range"}
                        </p>
                    }
                    {isAddNew &&
                        <p className="font-bold text-base tracking-wide text-center text-text-500 group-hover:text-text-500">
                            {"Close"}
                        </p>
                    }
                    <button className={clsx(
                        "inline-flex items-center justify-center",
                        "transform rounded-full bg-gray-50"
                    )}>
                        {!isAddNew && <FaPlusCircle className={"text-xl text-primary-main group-hover:text-primary-dark"} />}
                        {isAddNew && <AiFillCloseCircle className={"text-xl text-text-500 group-hover:text-text-500"} />}
                    </button>
                </div>
            </div>

            {isAddNew &&
                <div className="flex flex-col lg:flex-row items-center w-full p-4 space-x-4 space-y-2 lg:space-y-0 ">
                    <TimeRangeSelection
                        timeSlot={selectedTimeSlot}
                        setTimeSlot={setSelectedTimeSlot}
                    />
                    <div className="flex mt-4 space-x-4 md:mt-0">
                        <IconWithTextButton
                            icon={<FaCheck />}
                            placeholder="Create"
                            className="text-green-700 bg-green-300 border shadow-sm"
                            onClick={handleOnCreate}
                        />
                    </div>
                </div>
            }

            {/* View existing time ranges */}
            <div className="px-4 py-2 border rounded-md">
                {selectedOffDay?.timeSlots?.map((timeSlot, index) => (
                    <TimeRange
                        key={index}
                        day={dayjs(selectedOffDay.date).utc().format("dddd")}
                        timeSlot={timeSlot}
                        updateTimeSlot={handleOnUpdate}
                        deleteTimeSlot={handleOnDelete}
                    />
                ))}
                {selectedOffDay.timeSlots.length === 0 && (
                    <p className={"py-5 font-bodyPri font-semibold text-base text-text-500 text-center"}>
                        No time range has been added yet
                    </p>
                )}
            </div>
            <div className="flex justify-end my-2">
                <Button name="Create" size="small" style="primary" onClick={handleOnCreateOffDay} />
            </div>
        </div>
    );
};

export default NewOffDayCreationForm