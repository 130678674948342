import React, { useState } from "react";

//style imports
import {
  StyledDropdownContainer,
  StyledDropdownList,
  StyledDropdownListItem,
} from "./SelectCourse.styles";

export default function SelectCourse(props) {
  const {
    courses,
    selectedCourse,
    setSelectedCourse,
    setErrors,
    errors,
    setAmount,
  } = props;
  const [showCourses, setShowCourses] = useState(false);

  function showCoursesList() {
    setSelectedCourse("Select Course");
    setShowCourses(!showCourses);
  }

  function selectCourse(course) {
    setErrors({ ...errors, selectedCourse: "" });
    if (setAmount) {
      setAmount(course.price);
    }
    setSelectedCourse(course);
    setShowCourses(false);
  }

  return (
    <StyledDropdownContainer>
      <div onClick={showCoursesList}>
        {selectedCourse?.classTitle ?? "Select Course"}
      </div>
      {showCourses && (
        <StyledDropdownList>
          {courses.map((course) => {
            return (
              <StyledDropdownListItem
                key={course.id}
                onClick={() => selectCourse(course)}
              >
                {course.classTitle}
              </StyledDropdownListItem>
            );
          })}
        </StyledDropdownList>
      )}
    </StyledDropdownContainer>
  );
}
