import clsx from 'clsx';
import dayjs from 'dayjs';

import { BsCheckLg } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';
import { MdOutlineDeleteOutline } from 'react-icons/md';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { updateUserTodo, deleteUserTodo } from "redux/todo/todo.request";
import { setAddTodoPayload, setClearAddTodo } from "redux/todo/todo.slice";
import { todoStatus, todoAction } from 'redux/todo/todo.const';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';


const TodoItem = ({ todoItem }) => {
    const { addTodo } = useAppState(s => s.todo)
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher()

    const onEditTodo = () => {
        dispatcher(setClearAddTodo())
        dispatcher(setAddTodoPayload({
            ...addTodo?.addTodoPayload,
            todoAction: todoAction.UPDATE_TODO,
            userTodoId: todoItem?.userTodoId,
            todo: todoItem?.todo,
            status: todoItem?.status
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.TODO_MODAL.stateKey]: true
        }))
    }

    const onUpdateTodo = () => {
        dispatcher(setClearAddTodo())
        dispatcher(setAddTodoPayload({
            ...addTodo?.addTodoPayload,
            userTodoId: todoItem?.userTodoId,
            todo: todoItem?.todo,
            status: (
                todoItem?.status === todoStatus.COMPLETED.value
                    ? todoStatus.PENDING.value
                    : todoStatus.COMPLETED.value
            )
        }))
        dispatcher(updateUserTodo())
    }

    const onDeleteTodo = () => {
        dispatcher(deleteUserTodo(todoItem?.userTodoId))
    }

    const isCompleted = todoItem?.status === todoStatus.COMPLETED.value

    return (
        <div className={"p-3 shadow-all rounded space-y-1"}>

            <div className={"flex items-center justify-between gap-2"}>
                <div className={"flex items-center justify-start gap-2 overflow-hidden"}>
                    <div onClick={onUpdateTodo} className={clsx(
                        "w-5 h-5 rounded cursor-pointer flex items-center justify-center border-2",
                        isCompleted
                            ? `border-${todoStatus.COMPLETED.darkColor}`
                            : "border-text-500"
                    )}>
                        {isCompleted && <BsCheckLg className={`text-md text-${todoStatus.COMPLETED.darkColor}`} />}
                        {!isCompleted && <BsCheckLg className={`text-md text-white`} />}
                    </div>
                    <span onClick={onUpdateTodo}
                        className={clsx(
                            "font-bodyPri font-medium text-base text-text-800 truncate cursor-pointer",
                            isCompleted && "line-through"
                        )}
                    >
                        {todoItem?.todo}
                    </span>
                </div>

                <div className={"flex items-center justify-end gap-3"}>
                    <FiEdit onClick={onEditTodo}
                        className={"text-2xl text-primary-main cursor-pointer hover:text-primary-dark"}
                    />
                    <MdOutlineDeleteOutline onClick={onDeleteTodo}
                        className={"text-3xl text-text-500 cursor-pointer hover:text-text-700"}
                    />
                </div>
            </div>

            <div className={"flex items-center justify-between gap-2"}>
                <span className={clsx(
                    "font-bodyPri font-normal text-sm",
                    `text-${todoStatus[todoItem?.status.toUpperCase()].darkColor}`
                )}>
                    {todoStatus[todoItem?.status.toUpperCase()].label}
                </span>
                <span className={"font-bodyPri font-base text-sm text-text-800"}>
                    {dayjs(todoItem?.createdAt).local().format('DD/MM/YY hh:mm a').toString()}
                </span>
            </div>
        </div>
    )
}

export default TodoItem;