import { useEffect, useState } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";

import SelectSearch, { fuzzySearch } from "react-select-search";

import { OptionSelector } from "components/common-components/Select";

import { timeFormatConst } from "pages/auth/settings/settings.data";
import ChangePassword from "pages/auth/settings/components/general/ChangePassword";
import PasswordBox from "pages/auth/settings/components/general/PasswordBox";

import { getUserCultureDetailByUserId, updateUserCultureDetailByUserId } from "redux/user/user.request";
import { createSendOtp } from "redux/notification/notification.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setUserGeneralDetailPayload } from "redux/user/user.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { getMasterCurrencyList, getMasterLanguageList, getMasterTimezoneList } from "redux/master/master.request";

const TEST_PASSWORD = "Test@123"

const General = () => {
  const { modal } = useAppState((state) => state.local)
  const { languageList, timeZoneList, currencyList } = useAppState((state) => state.master)
  const { user, userCultureDetail, userGeneralDetailPayload } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  const [isUpdatePassword, setIsUpdatePassword] = useState(false)

  useEffect(() => {
    dispatcher(getMasterLanguageList())
    dispatcher(getMasterTimezoneList())
    dispatcher(getMasterCurrencyList())
    dispatcher(getUserCultureDetailByUserId(user?.user?.userId))
  }, [])

  const onHandleEmail = () => {
    if (user?.user?.email) {
      dispatcher(setUserGeneralDetailPayload({
        ...userGeneralDetailPayload?.userGeneralDetailPayload,
        email: user?.user?.email,
        password: TEST_PASSWORD,
        isOnlyVerifyEmail: false
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.VERIFY_EMAIL_MODAL.stateKey]: true
      }))
    }
    if (!user?.user?.email) {
      dispatcher(setModal({
        ...modal,
        [modalConst.VERIFY_EMAIL_MODAL.stateKey]: true
      }))
    }
  }

  const handleVerifyEmail = () => {
    dispatcher(createSendOtp({
      otpDeliveryMethod: "email",
      email: user?.user?.email
    }))
    dispatcher(setUserGeneralDetailPayload({
      ...userGeneralDetailPayload?.userGeneralDetailPayload,
      email: user?.user?.email,
      isOnlyVerifyEmail: true
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.VERIFY_EMAIL_MODAL.stateKey]: true
    }))
    toast.success("Code sent successfully")
  }

  const onHandleMobileNo = () => {
    if (user?.user?.mobileNo) {
      dispatcher(setUserGeneralDetailPayload({
        ...userGeneralDetailPayload?.userGeneralDetailPayload,
        mobileNo: user?.user?.mobileNo,
        password: TEST_PASSWORD,
        isOnlyVerifyMobileNo: false
      }))
      dispatcher(setModal({
        ...modal,
        [modalConst.VERIFY_MOBILE_NO_MODAL.stateKey]: true
      }))
    }
    if (!user?.user?.mobileNo) {
      dispatcher(setModal({
        ...modal,
        [modalConst.VERIFY_MOBILE_NO_MODAL.stateKey]: true
      }))
    }
  }

  const handleVerifyMobileNo = () => {
    dispatcher(createSendOtp({
      otpDeliveryMethod: "mobileNo",
      mobileNo: user?.user?.mobileNo
    }))
    dispatcher(setUserGeneralDetailPayload({
      ...userGeneralDetailPayload?.userGeneralDetailPayload,
      mobileNo: user?.user?.mobileNo,
      isOnlyVerifyMobileNo: true
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.VERIFY_MOBILE_NO_MODAL.stateKey]: true
    }))
    toast.success("Code sent successfully")
  }

  return (
    <div className={"grid grid-cols-12 gap-3 md:gap-5 lg:gap-8 p-5"}>

      {/* email container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Email"}
      </span>
      {user?.user?.email &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-5 lg:col-start-3 lg:col-span-3 flex flex-col items-start justify-center gap-2"}>
          <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
            {user?.user?.email}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-sm tracking-wide cursor-pointer hover:text-primary-main"}
            onClick={onHandleEmail}
          >
            {"Change Email"}
          </span>
        </div>
      }
      {(user?.user?.email && user?.user?.isEmailVerified) &&
        <span className={"col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2 font-bodyPri font-normal text-green-500"}>
          {"Verified"}
        </span>
      }
      {(user?.user?.email && !user?.user?.isEmailVerified) &&
        <div
          className={clsx(
            "col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2",
          )} 
          onClick={handleVerifyEmail}
        >
          <span className={clsx(
            "font-bodyPri font-normal text-sm text-red-500 italic cursor-pointer whitespace-nowrap",
            "px-2 py-0.5 rounded-md border border-red-500"
          )}>
            {"Verify Email"}
          </span>
        </div>
      }
      {!user?.user?.email &&
        <span
          className={clsx(
            "col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 py-1.5 font-buttons font-medium text-text-800 text-sm text-center rounded-md tracking-wider",
            "bg-text-200 hover:bg-text-300 cursor-pointer"
          )}
          onClick={onHandleEmail}
        >
          {"Add Email"}
        </span>
      }

      {/* phone container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Phone"}
      </span>
      {user?.user?.mobileNo &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-5 lg:col-start-3 lg:col-span-3 flex flex-col items-start justify-center gap-2"}>
          <span className={"font-bodyPri font-normal text-text-700 text-base tracking-wide"}>
            {"+" + user?.user?.isdCode + " - " + user?.user?.mobileNo}
          </span>
          <span
            className={"font-bodyPri font-medium text-text-800 text-sm tracking-wide cursor-pointer hover:text-primary-main"}
            onClick={onHandleMobileNo}
          >
            {"Change Phone"}
          </span>
        </div>
      }
      {(user?.user?.mobileNo && user?.user?.isMobileNoVerified) &&
        <span className={"col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2 font-bodyPri font-normal text-green-500"}>
          {"Verified"}
        </span>
      }
      {(user?.user?.mobileNo && !user?.user?.isMobileNoVerified) &&
        <div
          className={clsx(
            "col-start-1 col-span-full md:col-start-10 md:col-span-2 lg:col-start-7 lg:col-span-2",
          )} onClick={handleVerifyMobileNo}
        >
          <span className={clsx(
            "font-bodyPri font-normal text-sm text-red-500 italic cursor-pointer whitespace-nowrap",
            "px-2 py-0.5 rounded-md border border-red-500"
            )}>
            {"Verify Phone"}
          </span>
        </div>
      }
      {!user?.user?.mobileNo &&
        <span
          className={clsx(
            "col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 py-1.5 font-buttons font-medium text-text-800 text-sm text-center rounded-md tracking-wider",
            "bg-text-200 hover:bg-text-300 cursor-pointer"
          )}
          onClick={onHandleMobileNo}
        >
          {"Add Phone"}
        </span>
      }

      {/* password container */}
      {!isUpdatePassword &&
        <PasswordBox
          setIsUpdatePassword={setIsUpdatePassword}
        />
      }
      {isUpdatePassword &&
        <ChangePassword
          setIsUpdatePassword={setIsUpdatePassword}
        />
      }

      {/* language container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base lg:whitespace-nowrap"}>
        {"Display Language"}
      </span>
      {languageList?.languageList &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 bg-white shadow-all rounded-lg"}>
          <SelectSearch
            options={languageList?.languageList?.map((language) => ({
              name: language?.language,
              value: language?.masterLanguageId
            }))?.filter((language) => language?.name === "English")}
            search
            filterOptions={fuzzySearch}
            value={userCultureDetail?.userCultureDetail?.displayLanguage?.masterLanguageId}
            onChange={(option) => {dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { masterLanguageId: option }))}}
          />
        </div>
      }

      {/* timezone container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Timezone"}
      </span>
      {timeZoneList?.timeZoneList &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 bg-white shadow-all rounded-lg"}>
          <SelectSearch
            options={timeZoneList?.timeZoneList?.map((timezone) => ({
              name: timezone?.timeZone,
              value: timezone?.masterTimeZoneId
            }))}
            search
            placeholder={"Asia/Kolkata(UTC+05:30)"}
            filterOptions={fuzzySearch}
            value={userCultureDetail?.userCultureDetail?.timeZone?.masterTimeZoneId}
            onChange={(option) => dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { masterTimeZoneId: option }))}
          />
        </div>
      }

      {/* currency container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Currency"}
      </span>
      {currencyList?.currencyList &&
        <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 bg-white shadow-all rounded-lg"}>
          <SelectSearch
            options={currencyList?.currencyList?.map((currency) => ({
              name: currency?.currency,
              value: currency?.masterCurrencyId
            }))}
            search
            filterOptions={fuzzySearch}
            value={userCultureDetail?.userCultureDetail?.currency?.masterCurrencyId}
            onChange={(option) => dispatcher(updateUserCultureDetailByUserId(user?.user?.userId, { masterCurrencyId: option }))}
          />
        </div>
      }

      {/* time format container */}
      <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 font-bodyPri font-medium text-text-800 text-base"}>
        {"Time Format"}
      </span>
      <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5 flex items-center justify-start gap-8"}>
        <div 
        className={"flex items-center justify-center gap-1 cursor-pointer"} 
        onClick={() => dispatcher(updateUserCultureDetailByUserId(
          user?.user?.userId, { timeFormat: timeFormatConst.HRS_12.value }))}
          >
          <input
            type={"radio"}
            className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
            id={"time_format1"}
            name={"time_format"}
            checked={userCultureDetail?.userCultureDetail?.timeFormat === timeFormatConst.HRS_12.value && true}
          />
          <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
            {timeFormatConst.HRS_12.label}
          </span>
        </div>
        <div 
        className={"flex items-center justify-center gap-1 cursor-pointer"} 
        onClick={() => dispatcher(updateUserCultureDetailByUserId(
          user?.user?.userId, { timeFormat: timeFormatConst.HRS_24.value }))}
        >
          <input
            type={"radio"}
            className={"border-2 border-text-100 hover:border-text-700 cursor-pointer"}
            id={"time_format2"}
            name={"time_format"}
            checked={userCultureDetail?.userCultureDetail?.timeFormat === timeFormatConst.HRS_24.value && true}
          />
          <span className={"font-bodyPri font-normal text-text-800 text-sm tracking-wider"}>
            {timeFormatConst.HRS_24.label}
          </span>
        </div>
      </div>
    </div>
  )
}

export default General