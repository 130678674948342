import tw, { styled } from "twin.macro";

export const StyledChatViewMessageList = styled.div`
  ${tw`relative px-5 pt-2.5 flex flex-col overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-primary-dark scrollbar-track-gray-200 height[calc(100vh - 140px)] md:max-height[calc(100% - 140px)]`}

  div {
    &:first-of-type {
      ${tw`mt-auto`}
    }
  }
`;
