import { useEffect } from 'react';
import clsx from "clsx";

import { AiOutlinePlusCircle } from "react-icons/ai";

import { todoHeading } from 'pages/auth/schedule/todos/todo.data';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
    setAddTodoPayload,
    setClearAddTodo,
    setFilteredProps,
    setClearFilterProps
} from "redux/todo/todo.slice";
import { filterName, todoStatus, todoAction } from 'redux/todo/todo.const';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';


const TodosHeader = () => {
    const { todoList, filterProps, addTodo } = useAppState((s) => s.todo)
    const { modal } = useAppState(s => s.local)

    const dispatcher = useAppDispatcher();

    useEffect(() => {
        return () => {
            dispatcher(setClearFilterProps())
        }
    }, [])

    const onAddTodo = () => {
        dispatcher(setClearAddTodo())
        dispatcher(setAddTodoPayload({
            ...addTodo?.addTodoPayload,
            todoAction: todoAction.ADD_TODO,
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.TODO_MODAL.stateKey]: true
        }))
    }

    return (
        <div className="p-5 bg-white rounded-lg flex flex-col md:flex-row items-start md:items-center justify-between gap-3" id={'todoSection'}>

            {/* todo heading */}
            <div className="flex flex-col items-start gap-3">
                <span className="font-tagLine font-bold text-3xl text-text-900">
                    {todoHeading.heading}
                </span>
                <span className="font-bodyPri font-medium text-base text-text-600 whitespace-nowrap">
                    {todoHeading.subHeading}
                </span>
            </div>

            {/* todo filter */}
            <div className="w-full flex justify-end items-center gap-5">
                {Object.keys(todoStatus).map((key, index) => (
                    <div key={index} onClick={() => dispatcher(setFilteredProps({
                        filterProps: { ...filterProps, todoStatus: todoStatus[key].value },
                        list: todoList?.todoList,
                        filterName: filterName.TODO.value
                    }))}>
                        <span className={clsx(
                            "px-3 py-1 whitespace-nowrap cursor-pointer rounded-xl",
                            "font-buttons font-normal text-base ",
                            "hover:text-text-50 hover:bg-primary-main",
                            filterProps.todoStatus === todoStatus[key].value
                                ? "text-text-50 bg-primary-main"
                                : "text-primary-main bg-primary-light"
                        )}>
                            {todoStatus[key].label}
                        </span>
                    </div>
                ))}
                <div onClick={onAddTodo} className={"p-1 rounded-xl bg-primary-light hover:bg-primary-main text-primary-main hover:text-text-50 cursor-pointer"}>
                    <AiOutlinePlusCircle className={"text-2xl"} />
                </div>
            </div>

        </div>
    )
}

export default TodosHeader