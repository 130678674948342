import React, { useState } from "react";
import DatePicker from "react-datepicker";

//Datepicker styling
import "react-datepicker/dist/react-datepicker.css";

//Firebase imports
import { doc, updateDoc } from "firebase/firestore";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Component imports
import Button from "../presentationcomponents/Button/Button";
import { database } from "config/firebase"

//Style imports

import {
  StyledScheduleMeeting,
  StyledScheduleMeetingButtons,
  StyledError,
} from "./ScheduleMeeting.styles";

export default function ScheduleMeeting({ setScheduleMeeting }) {
  const [meetingDate, setMeetingDate] = useState(new Date());
  const { selectedChat } = useChat();
  const { user } = useUser();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { userId } = user;

  async function scheduleMeeting() {
    const minimumDeadline = new Date(new Date().getTime() + 1 * 60000);
    if (meetingDate <= minimumDeadline) {
      setError("Please schedule meeting at least 1 minutes in advance");
      return;
    }

    try {
      setError(null);
      setLoading(true);
      const chatId = selectedChat.id;
      const chatRef = doc(database, "chats", chatId);
      await updateDoc(chatRef, {
        meeting: { meetingDate: meetingDate, creator: userId, link: "" },
      });
      console.log("meeting scheduled");
      setScheduleMeeting(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError("Could not schedule meeitng");
      setLoading(false);
    }
  }

  function closeScheduleMeetingModal() {
    setScheduleMeeting(false);
  }

  return (
    <StyledScheduleMeeting>
      <h3>Shedule Meeting</h3>
      <DatePicker
        selected={meetingDate}
        showTimeSelect
        onChange={(date) => setMeetingDate(date)}
        dateFormat="MMMM d, yyyy h:mm aa"
        minDate={new Date()}
      />
      <StyledScheduleMeetingButtons>
        <Button
          variant="primary"
          text="Schedule Meeting"
          onClick={scheduleMeeting}
          loading={loading}
          disabled={loading}
        />
        <Button
          variant="secondary"
          text="Cancel"
          onClick={closeScheduleMeetingModal}
        />
      </StyledScheduleMeetingButtons>
      {error && <StyledError>{error}</StyledError>}
    </StyledScheduleMeeting>
  );
}
