import { useLocation, Navigate, Outlet } from 'react-router-dom';

import { useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

export const PrivateRouter = ({ allowedRoles }) => {
    const { logInSignUp } = useAppState(s => s.auth);
    const { user } = useAppState(s => s.user);
    const { locals } = useAppState(s => s.local)

    const location = useLocation();

    return (
        logInSignUp?.isLogedIn && user?.user?.roles?.find(role => allowedRoles?.includes(role.role) && role.role === locals.userRole)
            ? <Outlet />
            : logInSignUp?.isLogedIn
                ? <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: location }} replace />
                : <Navigate to={pagesInfo.LOG_IN.pagePath} state={{ from: location }} replace />
    );
}

export default PrivateRouter
