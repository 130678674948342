import { useState } from 'react';
import {
    Container,
    Content,
    Header,
    Heading,
    ItemContainer,
    InputContainer,
    BtnSubmit,
} from './SubscribeStyle';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const Subscribe = () => {
    const { modal } = useAppState((s) => s.local)
    const dispatcher = useAppDispatcher()

    const [email, setEmail] = useState('');

    const submit = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COMING_SOON_MODAL.stateKey]: true
        }))
    }

    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'Subscribe to our newsletter, we do not spam'}</Heading>
                </Header>
                <ItemContainer>
                    <InputContainer>
                        <input type={'email'} placeholder={'Enter your email...'} value={email} onChange={(e) => setEmail(e.target.value)} />
                        <BtnSubmit onClick={submit}>{'Submit'}</BtnSubmit>
                    </InputContainer>
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default Subscribe
