import { Oval } from "react-loader-spinner";

const ButtonLoader = ({ isLoading = false, className = "" }) => {
    return (
        <div className={`w-full h-full flex justify-center items-center ${className}`}>
            <Oval
                ariaLabel="loading-indicator"
                height={22}
                width={22}
                strokeWidth={10}
                strokeWidthSecondary={10}
                color="grey"
                secondaryColor="white"
            />
        </div>
    )
}

export default ButtonLoader;