import { Link } from "react-router-dom";

import { BiTimeFive } from 'react-icons/bi';
import { IoIosStats } from 'react-icons/io';
import { FaLanguage } from 'react-icons/fa';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';

import Icon from "components/Icon";

import { DOMAIN_NAME } from 'const/default.const';

import CountBox from "pages/auth/offerings/CountBox";
import Menu from "pages/auth/offerings/Menu";
import { menuItems } from "pages/auth/offerings/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { offeringStatus } from "redux/offering/offering.const";
import { setModal, setShareSocial } from "redux/local/local.slice";
import { modalConst, socialAction } from "redux/local/local.const";

const OfferCard = ({ offering }) => {
  const { modal, shareSocial } = useAppState((state) => state.local)

  const dispatcher = useAppDispatcher()

  const TUTOR_COURSE_URL = `${DOMAIN_NAME}/course/${offering?.courseId}`

  const Published = {
    border: "border-2 border-green-500",
    bg: "bg-green-100",
    text: "text-green-500",
    iconColor: '#2194f3',
    textColor: 'text-text-800'
  };

  const UnderReview = {
    border: "border-2 border-yellow-500",
    bg: "bg-yellow-100",
    text: "text-yellow-500",
    iconColor: '#e0e0e0',
    textColor: 'text-text-300'
  };

  const InActive = {
    border: "border-2 border-red-500",
    bg: "bg-red-100",
    text: "text-red-500",
    iconColor: '#2194f3',
    textColor: 'text-text-800'
  };

  const Draft = {
    border: "border-2 border-orange-500",
    bg: "bg-orange-100",
    text: "text-orange-500",
    iconColor: '#e0e0e0',
    textColor: 'text-text-300'
  };

  let borderAsPerStatus = ''
  let bgAsPerStatus = ''
  let textAsPerStatus = ''
  let iconColorAsPerStatus = ''
  let textColorAsPerStatus = ''

  switch (offering?.status) {
    case offeringStatus.PUBLISHED.value:
      borderAsPerStatus = Published.border
      bgAsPerStatus = Published.bg
      textAsPerStatus = Published.text
      iconColorAsPerStatus = Published.iconColor
      textColorAsPerStatus = Published.textColor
      break;
    case offeringStatus.UNDER_REVIEW.value:
      borderAsPerStatus = UnderReview.border
      bgAsPerStatus = UnderReview.bg
      textAsPerStatus = UnderReview.text
      iconColorAsPerStatus = UnderReview.iconColor
      textColorAsPerStatus = UnderReview.textColor
      break;
    case offeringStatus.DRAFT.value:
      borderAsPerStatus = Draft.border
      bgAsPerStatus = Draft.bg
      textAsPerStatus = Draft.text
      iconColorAsPerStatus = UnderReview.iconColor
      textColorAsPerStatus = UnderReview.textColor
      break;
    case offeringStatus.INACTIVE.value:
      borderAsPerStatus = InActive.border
      bgAsPerStatus = InActive.bg
      textAsPerStatus = InActive.text
      iconColorAsPerStatus = Published.iconColor
      textColorAsPerStatus = Published.textColor
      break;
    default:
      borderAsPerStatus = ''
      bgAsPerStatus = ''
      textAsPerStatus = ''
      break;
  }

  const Public = {
    border: borderAsPerStatus,
    bg: bgAsPerStatus,
    text: textAsPerStatus,
    promote: "border-2 border-primary-main text-primary-main cursor-pointer hover:bg-primary-main hover:text-white",
    iconColor: '#2194f3',
    textColor: 'text-text-800'
  };

  const Hidden = {
    border: borderAsPerStatus,
    bg: bgAsPerStatus,
    text: textAsPerStatus,
    promote: "border-2 border-text-300 text-text-300",
    iconColor: '#e0e0e0',
    textColor: 'text-text-300'
  };

  let border = ''
  let background = ''
  let text = ''
  let promote = ''
  let iconColor = ''
  let textColor = ''

  switch (offering.visibility) {
    case 'public':
      border = Public.border
      background = Public.bg
      text = Public.text
      promote = Public.promote
      iconColor = Public.iconColor
      textColor = Public.textColor
      break;
    case 'hidden':
      border = Hidden.border
      background = Hidden.bg
      text = Hidden.text
      promote = Hidden.promote
      iconColor = Hidden.iconColor
      textColor = Hidden.textColor
      break;
    default:
      border = ''
      background = ''
      text = ''
      promote = ''
      iconColor = ''
      textColor = ''
      break;
  }

  promote = offering?.status === offeringStatus.UNDER_REVIEW.value || offering?.status === offeringStatus.DRAFT.value || offering?.status === offeringStatus.INACTIVE.value ? Hidden.promote : promote
  const status = Object?.values(offeringStatus).filter(dropDown => dropDown.value === offering?.status)[0]?.label

  const onHandleShareOffering = () => {
    dispatcher(setShareSocial({
      ...shareSocial,
      socialAction: socialAction.SHARE_TUTOR_COURSE,
      url: TUTOR_COURSE_URL
    }))
    dispatcher(setModal({
      ...modal,
      [modalConst.SHARE_SOCIAL_MODAL.stateKey]: true
    }))
  }

  return (
    <div className={`${border} rounded-md p-5 bg-white space-y-3`}>
      <div className="flex flex-row items-center justify-between gap-5">
        <div className={`${border} ${background} ${text} rounded-md font-medium font-bodyPri tracking-wide leading-6 py-1 w-32 flex justify-center items-center`}
        >{status}
        </div>
        <span className="hidden sm:flex truncate overflow-hidden ... text-xl text-center font-bodyPri tracking-wide">
          {offering?.courseTitle.length > 50 ? offering?.courseTitle.substring(0, 50) + '...' : offering?.courseTitle}
        </span>
        <div className="flex items-center gap-5">
          {(offering?.visibility === menuItems.MAKE_PUBLIC.value && offering?.status !== offeringStatus.INACTIVE.value) &&
            <div className="h-4 w-4 rounded-full bg-green-600">
            </div>
          }
          {(offering?.visibility === menuItems.MAKE_PRIVATE.value) &&
            <div className="h-4 w-4 rounded-full bg-text-600">
            </div>
          }
          <Menu offering={offering} />
        </div>
      </div>
      <span className="flex sm:hidden truncate overflow-hidden ... text-xl text-center self-center font-bodyPri tracking-wide">
        {offering?.courseTitle.length > 50 ? offering?.courseTitle.substring(0, 50) + '...' : offering?.courseTitle}
      </span>
      <div className="h-0.5 bg-background-medium"></div>
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-8">
          <div className="pl-8">
            {/* category */}
            <div className="flex items-center mb-4">
              <FaLanguage className="text-gray-700 text-xl" />
              <span className="mx-4 text-md text-text-800 font-normal font-bodyPri tracking-wide">
                {'Category:'}
              </span>
              <span className="text-md text-text-900 font-medium font-bodyPri tracking-wide">
                {offering?.segment + "/" + offering?.category}
              </span>
            </div>
            {/* Level */}
            <div className="flex items-center mb-4">
              <IoIosStats className="text-gray-700 text-xl" />
              <span className="mx-4 text-md text-text-800 font-normal font-bodyPri tracking-wide">
                {'Level:'}
              </span>
              <span className="text-md text-text-900 font-medium font-bodyPri tracking-wide">
                {offering?.proficiency}
              </span>
            </div>
            {/* Price */}
            <div className="flex items-center mb-4">
              <MdOutlineAccountBalanceWallet className="text-gray-700 text-xl" />
              <span className="mx-4 text-md text-text-800 font-normal">
                {'Price:'}
              </span>
              <span className="text-md text-text-900 font-medium font-bodyPri tracking-wide">
                {'USD ' + parseFloat('' + (offering?.price / 100)).toFixed(2)}
              </span>
            </div>
            {/* Booking */}
            <div className="flex items-center mb-4">
              <BiTimeFive className="text-gray-700 text-xl" />
              <span className="mx-4 text-md text-text-800 font-normal font-bodyPri tracking-wide">
                {'Booking:'}
              </span>
              <span className="text-md text-text-900 font-medium font-bodyPri tracking-wide">
                {'as per your availability'}
              </span>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4">
          <div className="grid grid-cols-12">
            <div className="col-span-6">
              <div className="flex justify-center pl-8 md:pl-0 flex-col items-center">
                <CountBox
                  icon={'emptyCamera'}
                  count={offering?.noOfSessionCompleted}
                  title={'Session conducted'}
                  iconColor={iconColorAsPerStatus}
                  textColor={textColorAsPerStatus}
                />

                {status === offeringStatus.PUBLISHED.label &&
                  <Link to={`/class/${offering?.courseId}`} className="text-md font-semibold font-bodyPri tracking-wide text-text-800">
                    <div className="flex justify-center items-center mt-14 cursor-pointer">
                      {"View Details"}
                      <Icon type="right" />
                    </div>
                  </Link>
                }
              </div>
            </div>
            <div className="col-span-6">
              <div className="flex justify-center pl-8 md:pl-0 flex-col items-center">
                <CountBox
                  icon={'people'}
                  count={offering?.noOfBookings}
                  title={'Sold'}
                  iconColor={iconColorAsPerStatus}
                  textColor={textColorAsPerStatus}
                />
                {status === offeringStatus.PUBLISHED.label &&
                  <div
                    className={`${promote} flex items-center justify-center gap-2 rounded-md font-medium font-bodyPri tracking-wide px-3 py-1 mt-16 text-text-900`}
                    onClick={onHandleShareOffering}
                  >
                    <span className={""}>
                      {"Share"}
                    </span>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;