import { useAppState } from 'hooks/useStore';
import { eventType } from 'redux/event/event.const';

const EventDetailRoleView = () => {
    const { eventDetail } = useAppState(s => s.event)

    return eventDetail?.eventDetail?.eventType ? (
        <div className={"w-full flex items-center justify-center gap-1"}>
            <span className={"font-bodyPri font-medium text-md text-primary-dark"}>
                ({eventType[eventDetail?.eventDetail?.eventType?.toUpperCase()].label + " Meeting"})
            </span>
        </div>
    ) : null
}

export default EventDetailRoleView