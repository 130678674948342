import React from "react";

// Components
import MyTypography from "components/Typography";

// Local Components
import Container from "./Container";

const Skills = ({ tutor, ...props }) => {
  return (
    <Container
      id={props.id}
      leftSide={
        <div className="p-5 space-y-5">
          <MyTypography className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
            {"Skills"}
          </MyTypography>
          <div className="flex gap-5 flex-wrap px-3">
            {tutor?.skills?.length > 0
              && tutor?.skills?.map((ele, idx) => (
                <MyTypography
                  key={idx}
                  className='bg-primary-light py-1 px-3 rounded text-text-900 font-bodyPri font-normal text-base'
                >
                  {ele?.skill}
                </MyTypography>
              ))}
            {tutor?.skills?.length === 0
              && <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                {"No Skills Added!"}
              </span>
            }
          </div>
        </div>
      }
      rightSide={null}
      marginTop
    />
  );
};

export default Skills;
