import clsx from "clsx";

import homeHero from "pages/global/home/hero/heroImages/home-hero.png";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";
import { setNeedTutorPayload } from "redux/needTutor/needTutor.slice";
import { JourneyConst } from "redux/needTutor/needTutor.const";

const NeedATutor = () => {
    const { modal } = useAppState((state) => state.local)
    const { needTutor } = useAppState((state) => state.needTutor)
    const dispatcher = useAppDispatcher()

    const onHandleMeButton = () => {
        dispatcher(setNeedTutorPayload({
            ...needTutor?.needTutorPayload,
            journey: JourneyConst.ME.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.NEED_A_TUTOR_MODAL.stateKey]: true
        }))
    }
    const onHandleOtherButton = () => {
        dispatcher(setNeedTutorPayload({
            ...needTutor?.needTutorPayload,
            journey: JourneyConst.OTHER.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.NEED_A_TUTOR_MODAL.stateKey]: true
        }))
    }

    return (
        <div className={"w-full h-full flex flex-col items-center justify-center gap-3 md:gap-5 lg:gap-8"}>
            <div className={"flex flex-col items-center justify-center gap-3 md:gap-5 w-full"}>
                <span className={"font-tagLine font-bold text-center text-3xl md:text-4xl lg:text-5xl text-text-900"}>
                    {"Online live classes to help you succeed"}
                </span>
                <span className={"font-subHeading font-medium text-xl md:text-2xl lg:text-3xl text-text-900"}>
                    {"Who needs a tutor?"}
                </span>
            </div>
            <div className={"flex flex-col items-center justify-center gap-2 sm:gap-3 md:gap-5 w-full"}>
                <span
                    className={clsx(
                        "w-52 sm:w-64 md:w-72 lg:w-80 px-3 py-0.5 md:py-1 lg:py-1.5 flex justify-center items-center rounded-full cursor-pointer tracking-wide",
                        "font-buttons font-medium text-text-50 text-base sm:text-md md:text-lg lg:text-xl whitespace-nowrap",
                        "bg-secondary-dark hover:bg-text-50 border-2 border-secondary-dark hover:text-secondary-dark"
                    )}
                    onClick={onHandleMeButton}
                >
                    {"Me"}
                </span>
                <span
                    className={clsx(
                        "w-52 sm:w-64 md:w-72 lg:w-80 px-3 py-0.5 md:py-1 lg:py-1.5 flex justify-center items-center rounded-full cursor-pointer tracking-wide",
                        "font-buttons font-medium text-text-50 text-base sm:text-md md:text-lg lg:text-xl whitespace-nowrap",
                        "bg-secondary-dark hover:bg-text-50 border-2 border-secondary-dark hover:text-secondary-dark"
                    )}
                    onClick={onHandleOtherButton}
                >
                    {"My child / someone else"}
                </span>
            </div>
        </div>
    )
}

const Hero = () => {

    return (
        <div className={"grid grid-cols-12 gap-10 w-full h-full md:h-[60vh] lg:h-[80vh] bg-white pt-24 sm:pt-24 md:pt-0"}>
            <div className={"col-start-2 col-span-10 md:col-start-2 md:col-span-5 lg:col-start-2 lg:col-span-6 w-full flex items-center justify-center pb-10 md:pb-0"}>
                <NeedATutor />
            </div>
            <div className={"col-start-2 col-span-10 md:col-start-7 md:col-span-5 lg:col-start-8 lg:col-span-4 md:flex items-center justify-center overflow-hidden w-full"}>
                <img
                    src={homeHero}
                    className={"w-full h-full object-contain"}
                    alt={"home-hero-lg"}
                />
            </div>
        </div>
    )
}

export default Hero