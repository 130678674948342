import * as React from "react";
import cx from "clsx";
import { MdEdit } from 'react-icons/md'
import { AiFillCheckCircle } from 'react-icons/ai'

import { setOfferingCourse } from "redux/offering/offering.slice"
import { useAppDispatcher, useAppState } from "hooks/useStore";

type Props = {
  value: any;
  onChange: (value: any) => void;
  textClassName?: string;
  rows?: number;
  className?: string;
};

export const InlineTextEditor: React.FC<Props> = ({
  value,
  onChange,
  className,
  textClassName,
  children,
  rows = 1,
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  React.useEffect(() => {
    if (inputRef != null) {
      inputRef.current?.focus();
    }
  }, [editMode]);

  return (
    <div className={cx(className, "relative")}>
      <button
        className="absolute p-2 top-1 right-1"
        onClick={() => {
          setEditMode(!editMode)
        }}
      >
        {editMode ? (
          <AiFillCheckCircle className="text-lg text-primary-dark" />
        ) : (
          <MdEdit className="text-lg text-secondary-dark" />
        )}
      </button>
      {editMode &&
        <textarea
          ref={inputRef}
          rows={rows}
          className={cx(
            textClassName,
            "p-2 w-full bg-transparent resize-none",
            "focus:outline-none"
          )}
          disabled={!editMode}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      }
      {!editMode &&
        <div
          className={cx(
            textClassName,
            "p-2 w-full bg-transparent border-secondary-dark border-dashed focus:outline-none"
          )}
        >{value}
        </div>
      }
    </div>
  );
};

export const AffixedInlineTextEditor: React.FC<
   {
     value: any;
     className?: string;
     textClassName: string;
    discountPercentage?: any;
    discountValue?: any;
    prefix: string;
    suffix: string;
    prefixClassName?: string;
    suffixClassName?: string;
  }
> = ({
  discountPercentage,
  discountValue,
  value,
  className,
  prefix,
  suffix,
  children,
}) => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const [price, setPrice] = React.useState(0)
    const [discountedPercentage, setDiscountedPercentage] = React.useState(0)
    const [editMode, setEditMode] = React.useState(false);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const dispatcher = useAppDispatcher()


    React.useEffect(() => {
      if (inputRef != null) {
        inputRef.current?.focus();
      }
    }, [editMode]);

  const handleOnEditBtn = () => {
    setEditMode(!editMode)
    if (editMode) { 
      dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, price: price * 100, discountPct: discountedPercentage }))
      setPrice(0)
      setDiscountedPercentage(0)
    } else {
      setPrice(offeringCourse?.offeringCourse?.price/100)
      setDiscountedPercentage(offeringCourse?.offeringCourse?.discountPct)
    }
  }

    return (
      <>
        <div className={cx(className, "w-full relative")}>
          <div className="flex items-center justify-between gap-2 w-full p-2">
            <div className={"flex justify-start items-center gap-2"}>
              <div className='flex justify-center items-center gap-2'>
                <span className='text-lg text-text-800'>
                  {prefix}
                </span>
                <span className='text-2xl text-text-900'>
                  {parseFloat("" + (discountValue/100)).toFixed(2)}
                </span>
              </div>
              <div className='flex flex-col'>
                {discountPercentage > 0 &&
                  <span className='text-text-900 font-semibold'>
                    <del className='text-text-600'>
                      {value && prefix + " " + parseFloat("" + (value/100)).toFixed(2)}
                    </del>
                    {discountPercentage && " " + discountPercentage + "% off"}
                  </span>
                }
                <span className="text-text-900 font-medium font-bodyPri">
                  {suffix}
                </span>
              </div>
            </div>
            <span onClick={handleOnEditBtn}>
              {editMode 
                ? <AiFillCheckCircle className="inline text-lg text-secondary-dark cursor-pointer" />
                : <MdEdit className="inline text-lg text-secondary-dark cursor-pointer" />
              }
            </span>
          </div>
        </div>
        <div className={cx(className, "w-full relative")}>
          {editMode &&
            <>
              <hr className="m-2 border border-solid" />
              <div className="px-3 flex flex-col gap-2">
                <div className="flex items-center w-full space-x-1">
                  <span className="text-text-700 font-normal font-bodyPri tracking-wide whitespace-nowrap">
                    {"Price:"}
                </span>
                <div className={"inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                  <span className={cx("text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide")}>
                    {prefix}
                  </span>
                  <input
                    autoFocus={true}
                    type="number"
                    className={cx(
                      "bg-transparent w-full input-number-spin-none focus:outline-none",
                      "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                    )}
                    placeholder={"Price..."}
                    value={price}
                    onChange={(e) => {
                      let safe_number = Number(e.target.value)
                      if (isNaN(safe_number)) {
                        safe_number = 0
                      }
                      if (safe_number <= 1500) {
                        setPrice(safe_number)
                      }
                    }}
                  />
                  <p className={cx("whitespace-nowrap font-bodyPri tracking-wide")}>
                    {suffix}
                  </p>
                  </div>
                </div>
                <div className="flex items-center w-full space-x-2">
                  <span className="text-text-700 font-normal font-bodyPri tracking-wide whitespace-nowrap">
                    {"Discount: "}
                  </span>
                <div className={"inline-flex items-center gap-1 bg-transparent border-2 border-text-300 px-1 py-0.5 rounded-md"}>
                  <input
                      type={"number"}
                      className={cx(
                        "bg-transparent w-full input-number-spin-none focus:outline-none",
                        "text-text-700 font-normal whitespace-nowrap px-1 font-bodyPri tracking-wide"
                      )}
                      placeholder={'0'}
                      autoFocus={true}
                      value={discountedPercentage}
                      onChange={(e) => {
                        let safe_number = Number(e.target.value)
                        if (isNaN(safe_number)) {
                          safe_number = 0
                        }
                        if (safe_number >= 0 && safe_number <= 100) {
                          setDiscountedPercentage(safe_number)
                        }
                      }}
                  />
                  <p className={cx("whitespace-nowrap")}>
                    {"%"}
                  </p>
                </div>
                </div>
              </div>
            </>
          }
        </div>
      </>
    );
  };
