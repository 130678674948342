import { createSlice } from "@reduxjs/toolkit";
import { NEED_TUTOR_INITIAL_STATE } from "redux/needTutor/needTutor.initialState";

const needTutor = createSlice({
    name: "needTutor",
    initialState: NEED_TUTOR_INITIAL_STATE,
    reducers: {
        setNeedTutorPayload: (state, { payload }) => {
            state.needTutor.needTutorPayload = payload
        },
        setClearNeedTutorPayload: (state) => {
            state.needTutor = NEED_TUTOR_INITIAL_STATE.needTutor
        },
    }
})

export const {
    setNeedTutorPayload,
    setClearNeedTutorPayload,
} = needTutor.actions

export default needTutor.reducer;