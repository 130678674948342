import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import {
    PageContentStyle
} from "pages/auth/bookingConfirmation/BookingConfirmationPageStyle";

import FullPageLoader from 'components/loader/FullPageLoader';

import PendingBookingConfirmation from 'pages/auth/bookingConfirmation/components/PendingBookingConfirmation';
import ConfirmedBookingConfirmation from 'pages/auth/bookingConfirmation/components/ConfirmedBookingConfirmation';
import FailedBookingConfirmation from 'pages/auth/bookingConfirmation/components/FailedBookingConfirmation';
import NotExistBookingConfirmation from 'pages/auth/bookingConfirmation/components/NotExistBookingConfirmation';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getBookingConfirmationByBookingId } from 'redux/booking/booking.request';
import { setClearBookingConfirmation } from 'redux/booking/booking.slice';
import { setClearModal } from 'redux/local/local.slice';
import { bookingStatus } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';


const BookingConfirmationPage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { bookingConfirmation } = useAppState((state) => state.booking)

    const dispatcher = useAppDispatcher()
    const location = useLocation()
    const navigate = useNavigate()

    const from = location.state?.from?.pathname || pagesInfo.DASHBOARD.pagePath;

    const [bookingConfirmationAction, setBookingConfirmationAction] = useState('')

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKING_CONFIRMATION))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(setClearModal())
    }, [])

    useEffect(() => {
        let query = generateQueryParams(location.search)
        query = query.status ? query : null

        if (!query) {
            setBookingConfirmationAction("not_exist")
            navigate(from, { replace: true })
        } else if (query.bookingId && [bookingStatus.PENDING.value, bookingStatus.CONFIRMED.value, bookingStatus.COMPLETED.value, bookingStatus.CANCELLED.value, bookingStatus.REFUNDED.value].includes(query.status)) {
            setBookingConfirmationAction(query.status)
            dispatcher(getBookingConfirmationByBookingId(query.bookingId))
        } else {
            setBookingConfirmationAction("not_exist")
        }

        return () => {
            dispatcher(setClearBookingConfirmation())
        }

    }, [location.search])

    if (!bookingConfirmationAction || bookingConfirmation.isLoading) {
        return (
            <FullPageLoader isLoading={!bookingConfirmationAction || bookingConfirmation.isLoading} />
        )
    }

    if (bookingConfirmation.errorMsg) {
        return (
            <PageContentStyle>
                <FailedBookingConfirmation />
            </PageContentStyle>
        )
    }

    return (
        <PageContentStyle>
            {bookingConfirmationAction === bookingStatus.PENDING.value &&
                <PendingBookingConfirmation />
            }
            {[bookingStatus.CONFIRMED.value, bookingStatus.COMPLETED.value, bookingStatus.CANCELLED.value, bookingStatus.REFUNDED.value].includes(bookingConfirmationAction) &&
                <ConfirmedBookingConfirmation />
            }
            {bookingConfirmationAction === "not_exist" &&
                <NotExistBookingConfirmation />
            }
        </PageContentStyle>
    )
};

export default BookingConfirmationPage;
