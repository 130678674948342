import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FaEye } from "react-icons/fa";

import { pageHeading, myBookingToolTip, tHeaders, sHeaders } from "pages/auth/bookings/data";

import MyBookingSearch from "pages/auth/bookings/components/BookingSearch";
import { TableFilerActions } from "pages/auth/bookings/components/TableFilterActions";
import BookingSideOverContent from 'pages/auth/bookings/components/SideOverBookingPage';

import FullPageLoader from "components/loader/FullPageLoader";
import PageHeader from 'components/pageHeader/PageHeader';
import SideOverPanel from "components/common-components/SideOverPanel";
import TableComponent from "components/common-components/Table";
import Pagination from "components/pagination/Pagination";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getBookingList } from 'redux/booking/booking.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
  setBookingDetailPayload,
  setClearBookingList,
  setClearFilterProps
} from "redux/booking/booking.slice";
import { userRoles } from 'redux/auth/auth.const';
import { courseType } from 'redux/course/course.const';
import { bookingStatus } from 'redux/booking/booking.const';

import { pagesInfo } from 'utils/pagesInfo';
import { dayjs, timeZone } from "utils/dateTime.utils";
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 10


const BookingsPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { bookingList } = useAppState((s) => s.booking)
  const { locals } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()

  const [sideOverPanel, setSideOverPanel] = useState(false)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKINGS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    const query = generateQueryParams(location.search)

    dispatcher(getBookingList({ page: query.pn || "1", records: RECORDS }))

    return () => {
      dispatcher(setClearBookingList())
      dispatcher(setClearFilterProps())
    }
  }, [location.search])

  const rows = bookingList?.filteredBookingList?.results.map((booking) => [
    booking?.bookingId,
    booking?.course?.bookingType === "trial" ? "Yes" : "No",
    booking?.student?.firstName + " " + booking?.student?.lastName.charAt(0),
    "USD " + parseFloat('' + (booking?.purchasedPrice / 100)).toFixed(2),
    courseType[booking?.course?.courseType.toUpperCase()].label,
    bookingStatus[booking?.status.toUpperCase()].label,
    dayjs(booking?.createdAt).tz(timeZone).format('dddd, MMM DD, YYYY h:mm A'),
    <div className="flex space-x-1">
      <button
        className="p-1 text-sky-400"
        onClick={() => {
          dispatcher(setBookingDetailPayload({ bookingId: booking.bookingId }));
          setSideOverPanel(true)
        }}
      >
        <FaEye size={20} className="hover:scale-110" />
      </button>
    </div>,
  ]);

  if (bookingList?.isLoading) {
    return <FullPageLoader isLoading={bookingList?.isLoading} />
  }

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen">
      <SideOverPanel
        child={<BookingSideOverContent />}
        isOpen={sideOverPanel}
        onClose={(isShow) => setSideOverPanel(isShow)}
      />
      <div className={"space-y-3"}>
        <PageHeader pageHeading={pageHeading} toolTipText={myBookingToolTip}/>
        <MyBookingSearch />
        <TableFilerActions />
        <TableComponent
          headers={locals.userRole === userRoles?.TUTOR.value ? tHeaders : sHeaders}
          rows={rows}
          alternateRowColor="bg-sky-100"
          rowHeaderColor="bg-sky-200"
        />
        {bookingList?.filteredBookingList && bookingList?.filteredBookingList?.records > 0 &&
          <div className="w-full flex justify-center items-center">
            <Pagination
              page={bookingList?.filteredBookingList?.page}
              totalPages={bookingList?.filteredBookingList?.totalPages}
              onChangePage={(page) => navigate(pagesInfo.BOOKINGS.pagePath + "?pn=" + page)}
            />
          </div>
        }
        {(bookingList?.filteredBookingList?.records === 0 || bookingList?.errorMsg) &&
          <div className={"w-full h-full font-bodyPri font-semibold text-text-800 text-md"}>
            {bookingList?.errorMsg}
          </div>
        }
      </div>
    </div>
  );
}

// const BookingsPage = () => {
//   const { currentPageInfo } = useAppState((state) => state.pageInfo)

//   const dispatcher = useAppDispatcher();

//   useEffect(() => {
//     dispatcher(setPageInfo(currentPageInfo, pagesInfo.BOOKINGS))
//   }, [dispatcher, currentPageInfo])

//   return (
//     <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen">
//       <MyBookingFeature />
//     </div>
//   );
// }

export default BookingsPage;
