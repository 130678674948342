export function requestPaymentError(selectedCourse, amount) {
  const requestErrors = {};
  if (!selectedCourse) {
    requestErrors.selectedCourse = `Please select a course`;
  }
  const updatedAmount = parseFloat(amount);
  if (isNaN(updatedAmount)) {
    requestErrors.amount = `Please enter a valid amount`;
  } else if (updatedAmount < 0) {
    requestErrors.amount = `Please enter a valid amount`;
  }

  return {
    valid: Object.keys(requestErrors).length === 0,
    requestErrors,
  };
}

export function shareCourseError(selectedCourse, description) {
  const shareErrors = {};
  if (description.trim() === "") {
    shareErrors.description = `Please provide a description`;
  }
  if (!selectedCourse) {
    shareErrors.selectedCourse = `Please select a course`;
  }
  return {
    valid: Object.keys(shareErrors).length === 0,
    shareErrors,
  };
}
