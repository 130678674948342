import React from 'react'

const TutorCardLoader = ({ isLoading = false }) => {
  return isLoading ? (
    <div className="shadow-[0_0_10px_1px_rgba(0,0,0,0.3)] rounded-md bg-white grid grid-cols-12 p-3 space-y-5">
      <div className="animate-pulse col-start-1 col-span-12 md:col-start-1 md:col-span-7 flex justify-between items-start px-5">
        <div className="flex gap-14 md:gap-5 lg:gap-14">
          <div className='flex items-center flex-col justify-between gap-5'>
            <div className='relative w-16 h-16 rounded-full bg-slate-200'></div>
            <div className='flex items-center gap-1'>
              <div className='h-3 w-16 bg-slate-200 rounded'></div>
            </div>
            <div className='flex flex-col justify-center items-center gap-2'>
              <div className='w-10 h-10 bg-slate-200 rounded-full'></div>
              <div className='h-3 w-16 bg-slate-200 rounded'></div>
            </div>
            <div className='h-5 w-24 bg-slate-200 rounded'></div>
          </div>
          <div className='flex flex-col items-start gap-3'>
            <div className='flex flex-col gap-2'>
              <div className='flex items-center gap-3'>
                <div className='h-5 w-36 rounded bg-slate-200'></div>
              </div>
              <div className='h-5 w-28 rounded bg-slate-200'></div>
            </div>

            <div className='flex flex-col gap-2 items-center justify-center'>
              <div className='relative w-10 h-10 rounded-full bg-slate-200'>
              </div>
              <div className='h-5 w-28 rounded bg-slate-200'></div>
            </div>

            <div className='flex flex-col gap-5'>
              <div className='flex flex-col gap-1'>
                <div className='w-28 h-3 rounded bg-slate-200'></div>
                <div className='w-36 h-3 rounded bg-slate-200'></div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='w-28 h-3 rounded bg-slate-200'></div>
                <div className='w-48 h-3 rounded bg-slate-200'></div>
              </div>
              <div className='flex flex-col gap-3'>
                <div className='w-28 h-3 rounded bg-slate-200'></div>
                <div className='w-48 h-3 rounded bg-slate-200'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='flex pt-2'>
          <div className='rounded-full w-10 h-10 bg-slate-200'></div>
        </div>
      </div>

      <div className='animate-pulse col-start-1 col-span-12 md:col-start-8 md:col-span-5 px-5 flex flex-col justify-between border-l-2 pt-5 md:pt-0'>
        <div className='w-full flex flex-col justify-start gap-3'>
          <div className='flex justify-center items-center gap-2 pt-3'>
            <div className='w-24 h-6 bg-slate-200'></div>
            <div className='w-24 h-6 bg-slate-200'></div>
          </div>

          <div className='w-full flex justify-center items-center p-20 bg-slate-200 '>
            <div className='rounded-lg overflow-hidden w-3/4 md:w-full h-full'></div>
          </div>

        </div>
        <div className='flex flex-col items-center justify-center gap-3'>
          <div className='w-48 h-5 bg-slate-200'></div>
          <div className='flex justify-center items-center space-x-5'>
            <div className='w-28 h-6 bg-slate-200'></div>
            <div className='w-28 h-5 bg-slate-200'></div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default TutorCardLoader;