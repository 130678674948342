import { todoFilterProps, todoStatus } from 'redux/todo/todo.const';

const addTodoPayload = {
    userTodoId: null,
    todo: "",
    status: todoStatus.PENDING.value,
    todoAction: null
}

export const TODO_INITIAL_STATE = {
    todoList: {
        isLoading: false,
        todoList: null,
        filteredTodoList: null,
        errorMsg: null
    },

    addTodo: {
        isLoading: false,
        addTodo: null,
        errorMsg: null,
        addTodoPayload: addTodoPayload,
    },

    filterProps: todoFilterProps
}