import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { FaKeyboard } from 'react-icons/fa';

import { PageContentStyle } from 'pages/auth/meeting/MeetingPage.style';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { generateJaasJwtToken } from 'redux/meeting/meeting.request';
import { setJaasJwtToken, setClearJaasJwtToken } from 'redux/meeting/meeting.slice';

import { DOMAIN_NAME } from 'const/default.const';

const meetingPageImage = "https://images.unsplash.com/photo-1531545514256-b1400bc00f31?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80"


const MeetingPage = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)

    const dispatcher = useAppDispatcher()

    const [meetingId, setMeetingId] = useState('')

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.MEETING))
    }, [dispatcher, currentPageInfo])

    const handleJoinMeetingRoom = () => {
        if (!meetingId) return;

        window.open(`${DOMAIN_NAME}${pagesInfo.MEETING_ROOM.pagePath}/${process.env.REACT_APP_JAAS_APP_ID}/${meetingId}`, "_blank")
        return;
    }

    return (
        <PageContentStyle>
            <div className={"w-full grid grid-cols-12 items-center gap-10 lg:gap-0"}>
                <div className={"col-start-2 col-span-10 lg:col-start-2 lg:col-span-4 flex flex-col items-center lg:items-start gap-8"}>
                    <div className={"flex flex-col items-start gap-5"}>
                        <span className={"font-tagLine font-semibold text-4xl text-text-800 text-center lg:text-left"}>
                            {"Premium video meetings."}
                        </span>
                        <span className={"font-bodyPri font-base text-lg text-text-700 text-center lg:text-left"}>
                            {"We re-engineered the service that we built for secure meetings."}
                        </span>
                    </div>
                    <div className={"flex flex-col md:flex-row items-center justify-start gap-5 group"}>
                        <div className={clsx(
                            "flex items-center justify-start gap-2 px-4 py-2 border-2  rounded-md",
                            meetingId
                                ? "border-primary-main"
                                : "border-text-500 group-focus-within:border-primary-main"
                        )}>
                            <FaKeyboard className={"text-lg text-text-500 group-focus:text-text-700"} />
                            <input
                                className={clsx(
                                    "font-bodyPri font-normal text-md text-text-800 outline-none",
                                    "placeholder:font-bodyPri placeholder:font-normal placeholder:text-md placeholder:text-text-500"
                                )}
                                type={"text"}
                                placeholder={"Enter meeting id or link"}
                                value={meetingId}
                                onChange={(event) => setMeetingId(event.target.value)}
                            />
                        </div>
                        <span onClick={handleJoinMeetingRoom} className={clsx(
                            "transition ease-in-out duration-300 delay-150",
                            "font-buttons font-medium text-base",
                            meetingId
                                ? "flex text-primary-main cursor-pointer"
                                : "hidden group-focus-within:flex group-focus-within:text-text-500",
                        )}>
                            {"Join"}
                        </span>
                    </div>
                </div>
                <div className={"col-start-2 col-span-10 lg:col-start-8 lg:col-span-4 flex flex-col items-center justify-center gap-5"}>
                    <div className={"w-96 h-96 rounded-full overflow-hidden"}>
                        <img src={meetingPageImage} alt={"meeting"} className={"w-full h-full object-cover"} />
                    </div>
                    <div className={"flex flex-col items-center gap-1"}>
                        <span className={"font-bodyPri font-medium text-lg text-text-800 text-center"}>
                            {"Get a link that you can share"}
                        </span>
                        <span className={"font-bodyPri font-normal text-base text-text-700 text-center px-20"}>
                            {"Enter the room id or meeting link that you can join the meeting"}
                        </span>
                    </div>
                </div>
            </div>
        </PageContentStyle>
    )
}

export default MeetingPage