import tw, { styled } from "twin.macro";

export const StyledGroupNotificationListItem = styled.div`
  ${tw`border-b border-b-gray-200 border-l-4 border-l-primary-dark py-2 px-4 cursor-pointer`}
`;

export const StyledGroupNotificationListItemName = styled.span`
  ${tw`font-semibold text-primary-dark`}
`;

export const StyledGroupNotificationListItemDate = styled.p`
  ${tw`text-sm text-gray-400 mt-2`}
`;
