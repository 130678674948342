import * as React from "react";

import { FiFilter } from 'react-icons/fi';

import { makeStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { useAppState } from "hooks/useStore";
import { referralStatus } from 'redux/referral/referral.const';

const useStyles = makeStyles({
  root: {},
});

const TableFilter = ({ handleFilters }) => {
  const { filterProps } = useAppState((s) => s.referral)
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    handleFilters(option);
  };

  return (
    <div>
      <FiFilter onClick={handleClick} className={"text-xl text-text-600 hover:text-text-800"} />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        classes={{
          root: classes.root,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {Object.values(referralStatus).map((option, idx) => (
          <MenuItem key={idx} onClick={() => handleClose(option.value)} value={filterProps?.referralStatus}>{option.label}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default TableFilter;
