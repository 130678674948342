import React, { useState } from "react";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return text?.length > 0 ? (
    <p className="text">
      {isReadMore ? text?.slice(0, 200) : text}
      <span onClick={toggleReadMore} className="read-or-hide text-blue-500 cursor-pointer">
        {isReadMore ? "... Read more" : " Show less"}
      </span>
    </p>
  )
    : <span className="font-bodyPri font-normal text-text-900 text-medium">{"Tell us something about yourself..."} </span>
};

export default ReadMore;
