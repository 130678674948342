import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Style imports
import { StyledChatFilterItem } from "./ChatFilter.styles";

export default function ChatFilterItem({ filter }) {
  const { filters, dispatch } = useChat();
  const filterToDisplay = filter === "sent" ? "unread" : filter;

  function removeFilter() {
    const newFilters = filters;
    const index = filters.indexOf(filter);
    newFilters.splice(index, 1);
    dispatch({
      type: "SET_FILTERS",
      payload: newFilters,
    });
  }

  return (
    <StyledChatFilterItem>
      <p>
        {filterToDisplay.charAt(0).toUpperCase() + filterToDisplay.slice(1)}
      </p>
      <div onClick={removeFilter}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#ffffff"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </StyledChatFilterItem>
  );
}
