import { segmentFilterProps } from "redux/segment/segment.const"

export const SEGMENT_INITIAL_STATE = {
    segmentList: {
        isLoading: false,
        segmentList: null,
        filteredSegmentList: null,
        errorMsg: null
    },
    filterProps: segmentFilterProps
}