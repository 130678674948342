import clsx from "clsx";

import { viewContainerConst, levelBtnConst } from "components/modals/needATutorModal/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { JourneyConst } from "redux/needTutor/needTutor.const";
import { setNeedTutorPayload } from "redux/needTutor/needTutor.slice";

const Level = ({ setStepCount, setViewContainer }) => {
    const { needTutor } = useAppState((state) => state.needTutor)
    const dispatcher = useAppDispatcher()

    const onHandleButton = (selectedGrade) => {
        dispatcher(setNeedTutorPayload({
            ...needTutor?.needTutorPayload,
            level: selectedGrade?.value
        }))
        setStepCount(2)
        setViewContainer(viewContainerConst.SUBJECT.value)
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-12 px-2"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                {needTutor?.needTutorPayload?.journey === JourneyConst.ME.value 
                && "Which grade are you in?"
                }
                {needTutor?.needTutorPayload?.journey === JourneyConst.OTHER.value
                && "Which grade are they in?"
                }
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-3"}>
                {Object.values(levelBtnConst)?.map((btn) => (
                    <span className={clsx(
                        "w-11/12 p-3 flex justify-start items-center rounded-md cursor-pointer",
                        "font-buttons font-medium text-lg text-primary-dark bg-primary-light hover:text-text-50 hover:bg-primary-dark",
                        "border border-text-300 hover:border-primary-dark"
                    )}
                        onClick={() => onHandleButton(btn)}
                    >
                        {btn?.label}
                    </span>
                ))}
            </div>
        </div>
    )
}

export default Level