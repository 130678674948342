import { createSlice } from "@reduxjs/toolkit";
import { AVAILABILITY_INITIAL_STATE } from "redux/availability/availability.initialState";


const auth = createSlice({
    name: "availability",
    initialState: AVAILABILITY_INITIAL_STATE,
    reducers: {
        // Reducers for availability
        setAvailabilityLoading: (state, { payload }) => {
            state.availability.isLoading = payload
        },
        setAvailability: (state, { payload }) => {
            state.availability.availability = payload
        },
        setAvailabilityErrorMsg: (state, { payload }) => {
            state.availability.errorMsg = payload
            state.availability.availability = AVAILABILITY_INITIAL_STATE.availability.availability
        },
        setClearAvailability: (state) => {
            state.availability = AVAILABILITY_INITIAL_STATE.availability
        },

        // Reducers for update availability
        setUpdateAvailabilityLoading: (state, { payload }) => {
            state.updateAvailability.isLoading = payload
        },
        setUpdateAvailabilityErrorMsg: (state, { payload }) => {
            state.updateAvailability.errorMsg = payload
            state.updateAvailability.updateAvailability = AVAILABILITY_INITIAL_STATE.updateAvailability.updateAvailability
        },
        setClearUpdateAvailability: (state) => {
            state.updateAvailability = AVAILABILITY_INITIAL_STATE.updateAvailability
        }
    }
})

export const {
    setAvailabilityLoading,
    setAvailability,
    setAvailabilityErrorMsg,
    setClearAvailability,

    setUpdateAvailabilityLoading,
    setUpdateAvailabilityErrorMsg,
    setClearUpdateAvailability,
} = auth.actions

export default auth.reducer