import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Icon from "./Icon";

import { TimelineView } from "pages/auth/dashboard/components/TimelimeView";

import { useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';


const CalendarComponent = () => {
  const { locals } = useAppState((s: any) => s.local)
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [optionsVisible, setOptionsVisible] = React.useState(false);

  return (
    <div className="px-5 space-y-1 flex flex-col md:flex-row lg:flex-col">
      <div className="flex justify-between">
        <span className="text-lg font-medium font-bodyPri text-text-900 tracking-wider">
          {"Schedule"}
        </span>
        <span className="text-sm text-text-700 font-bodyPri whitespace-nowrap">
          {`${timeZone} ${getTimeZoneOffset(timeZone)}`}
        </span>
      </div>
      <div className="flex flex-col items-center justify-center w-full">
        <Calendar
          onChange={(newDate: Date) => setSelectedDate(newDate)}
          value={selectedDate}
          showNeighboringMonth={true}
          locale={"en-US"}
        />
      </div>
      <div className="w-full space-y-2">
        <div className="w-full flex justify-between">
          <p className="w-full flex justify-center text-medium text-text-600 font-bodypri font-medium tracking-wide">
            {dayjs().isSame(selectedDate, "day")
              ? "Today's Schedule"
              : `${dayjs(selectedDate).format("dddd, MMMM DD")} Schedule`}
          </p>
          {locals.userRole === userRoles.TUTOR.value &&
            <div className="relative">
              <button
                className=""
                onClick={() => setOptionsVisible(!optionsVisible)}
              >
                <Icon type="threedot2" />
              </button>
              {optionsVisible && (
                <Link to={"/preferences/availability"}>
                  <div
                    className={clsx(
                      "absolute w-52 right-0 -bottom-7",
                      "px-3 py-2 bg-text-100 rounded-md shadow-xl",
                      "font-bodyPri text-sm border-2 cursor-pointer"
                    )}
                  >
                    <p>{"Set Weekly Schedule"}</p>
                  </div>
                </Link>
              )}
            </div>
          }
        </div>
        <div className={clsx(
          "max-h-full overflow-scroll",
          "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm scrollbar-w-sm",
          "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
        )}>
          <TimelineView selectedDate={selectedDate} />
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;
