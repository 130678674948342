import React from "react";
import tw, { styled } from "twin.macro";

//Icon imports
import { PaperClipIcon } from "@heroicons/react/outline";
import { RefreshIcon } from "@heroicons/react/outline";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

//Style imports
import {
  StyledChatViewSendMessageAttachInput,
  StyledChatViewSendMessageAttachLabel,
} from "./ChatViewSendMessage.styles";

export default function ChatViewSendMessageAttach({
  uploadFile,
  fileUploading,
  fileUploadError,
}) {
  return (
    <div>
      <StyledChatViewSendMessageAttachLabel>
        <StyledChatViewSendMessageAttachInput
          type="file"
          onChange={(e) => uploadFile(e)}
        />
        {fileUploading ? (
          <RefreshIcon className="h-6 w-6 text-gray-500 animate-spin" />
        ) : fileUploadError ? (
          <ExclamationCircleIcon className="h-6 w-6 text-secondary-dark" />
        ) : (
          <PaperClipIcon className="h-6 w-6 text-gray-500" />
        )}
      </StyledChatViewSendMessageAttachLabel>
    </div>
  );
}
