import { useEffect } from 'react';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';

import { MdGroups } from 'react-icons/md';
import { BsInfoCircle } from "react-icons/bs";
import { IoMdPeople } from 'react-icons/io';
import { FiMessageSquare } from 'react-icons/fi';

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import RatingStars from 'components/ratingStars/RatingStars';

import { getSelectedCourseByCourseId } from 'redux/course/course.request';
import {
    createFBIndividulaChat,
    getFBChat,
    getFBChatId,
    sendFBMessage
} from 'redux/firebase/firebase.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { courseType } from "redux/course/course.const";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setClearAddBookingPayload } from 'redux/booking/booking.slice'
import { setClearMessageTemplate, setMessageTemplate } from 'redux/firebase/firebase.slice';

const CourseCard = ({ course, isFav = false, handleFav }) => {
    const { logInSignUp } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { messageTemplate } = useAppState((s) => s.firebase)
    const { modal } = useAppState((s) => s.local)
    const { myCourseList } = useAppState((s) => s.course)

    const navigate = useNavigate()
    const dispatcher = useAppDispatcher()

    const RATING_COUNT = 2

    const isMyOffering = user?.user?.userId === course?.tutor?.userId
    const isMyCourse = myCourseList?.filteredCourseList?.filter((course) => course?.courseType === courseType?.GROUP.value)
        .filter((myCourse) => myCourse?.courseId === course?.courseId)?.length > 0

    const handleMessage = async () => {
        if (!logInSignUp.isLogedIn) {
            dispatcher(setModal({
                ...modal,
                [modalConst.AUTHORIZATION_MODAL.stateKey]: true
            }))
            return;
        }

        if (isMyOffering) return;

        const userIdOne = user?.user?.userId
        const userIdTwo = course?.tutor?.userId

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`/message/${fbChat.chatId}`)
    }

    useEffect(() => {
        if (messageTemplate?.message && user?.user?.userId === messageTemplate?.userIdOne && course?.tutor?.userId === messageTemplate?.userIdTwo) {
            createFBChatAndRedirect()
        }
    }, [messageTemplate?.message])

    const createFBChatAndRedirect = async () => {
        const fbChat = await createFBIndividulaChat(messageTemplate?.userIdOne, messageTemplate?.userIdTwo)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                errorMsg: "Chat not exist!",
            }))
            return;
        }

        await sendFBMessage({
            senderUserId: user?.user?.userId,
            text: messageTemplate?.message,
            chatId: fbChat.chatId
        })

        dispatcher(setClearMessageTemplate())
        dispatcher(setModal({
            ...modal,
            [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: false
        }))

        navigate(`/message/${fbChat.chatId}`)
    }

    const handleBookNow = () => {
        if (isMyCourse || isMyOffering) return;

        dispatcher(getSelectedCourseByCourseId(course?.courseId))
        dispatcher(setClearAddBookingPayload())
        if (course?.courseType === courseType.GROUP.value) {
            dispatcher(setModal({ ...modal, [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true }))
        }

        if (course?.courseType === courseType.ONE_ON_ONE.value) {
            dispatcher(setModal({ ...modal, [modalConst.NO_SESSION_MODAL.stateKey]: true }))
        }
    }

    return (
        <div className='w-[22rem] h-full flex flex-col bg-white rounded-lg shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]'>
            <div className='w-full h-48 overflow-hidden relative'>
                <div className='rounded-t-lg overflow-hidden h-full w-full'>
                    <Link to={`/class/${course?.courseId}`}>
                        <img
                            src={course?.thumbnailPicUrl}
                            alt={"class-thumbnail"}
                            className='w-full h-full object-contain'
                        />
                    </Link>
                </div>
                {/* {logInSignUp.isLogedIn &&
                    <div className='absolute right-2 top-2 p-1.5 bg-white rounded-full shadow-md'>
                        <span onClick={() => handleFav(course.courseId)} className="cursor-pointer">
                            {isFav
                                ? (<FavoriteIcon className='text-red-500' style={{ fontSize: "1.5rem" }} />)
                                : <FavoriteBorderIcon className='text-text-700' style={{ fontSize: "1.5rem" }} />
                            }
                        </span>
                    </div>
                } */}
            </div>
            <div className='flex w-full flex-col gap-3 p-5 pt-3'>
                <div className='h-full flex flex-col justify-between gap-5'>
                    <div className='flex flex-col justify-start gap-5'>
                        <div className='flex justify-between items-center'>
                            <span className='text-text-900 font-normal font-bodyPri text-sm tracking-wide'>
                                {course?.category}
                            </span>
                            <div className='flex justify-center items-center gap-2'>
                                {Object.keys(courseType).map((item, index) => courseType[item].value === course?.courseType &&
                                    <span key={index} className='px-2 py-0.5 flex items-center justify-center rounded-full bg-text-300 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap'>
                                        {courseType[item]?.label}
                                    </span>
                                )}
                                {course?.courseType === courseType.GROUP.value
                                    ? <MdGroups className='text-text-700 text-2xl' />
                                    : <IoMdPeople className='text-text-700 text-2xl' />
                                }
                            </div>
                        </div>
                        <Link to={`/class/${course?.courseId}`} className={clsx(
                            'text-text-900 font-bodyPri font-medium text-lg tracking-wide truncate',
                            "hover:text-secondary-main hover:underline decoration-1"
                        )}>
                            {course?.courseTitle}
                        </Link>
                    </div>
                    <div className='flex flex-col justify-end gap-5'>
                        <div className='flex justify-between items-center'>
                            <div className='flex justify-start gap-2'>
                                <div className='relative w-12 h-12 rounded-full'>
                                    <Link to={`/tutor/${course?.tutor?.tutorId}`}>
                                        <img src={course?.tutor?.profilePicUrl} alt={"profile-pic"} className={"w-full h-full rounded-full object-cover"} />
                                    </Link>
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img
                                            src={`https://countryflagsapi.com/png/${course?.tutor?.fromCountryDomain}`}
                                            alt={"country-flag"} className={'w-full h-full object-cover'}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col justify-evenly items-start'>
                                    <div className='flex justify-center items-center gap-2'>
                                        <Link to={`/tutor/${course?.tutor?.tutorId}`}>
                                            <span className='font-medium font-bodyPri text-md tracking-wide'>
                                                {course?.tutor?.firstName + " " + course?.tutor?.lastName.charAt(0) + "."}
                                            </span>
                                        </Link>
                                        {course?.tutor?.onlineStatus === "online" && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>}
                                    </div>
                                    <div className='flex justify-start items-center gap-0.5'>
                                        {course?.tutor?.ratingCount > RATING_COUNT
                                            ? <>
                                                <RatingStars rating={course?.tutor?.avgRating} fullRating={5} size={0.8} />
                                                <span className='font-medium text-sm font-bodyPri tracking-wide'>{course?.tutor?.avgRating}</span>
                                                <span className='font-normal text-sm font-bodyPri'>({String(course?.tutor?.ratingCount).padStart(2, "0")})</span>
                                            </>
                                            : <span className='font-normal text-sm font-bodyPri tracking-wide text-complementry-main'>{"New"}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className={clsx(
                                'flex flex-col justify-evenly items-center gap-1 font-normal text-sm font-bodyPri tracking-wide',
                            )}>
                                <span onClick={handleMessage} className={clsx(
                                    isMyOffering
                                        ? "text-text-300"
                                        : "text-text-700 cursor-pointer",
                                )}>
                                    {"Message >"}
                                </span>
                                <Link to={`/class/${course?.courseId}`}>
                                    <span className='text-text-700 cursor-pointer'>
                                        {"View Detail >"}
                                    </span>
                                </Link>
                            </div> */}
                        </div>
                        <hr />
                        <div className='flex justify-between items-center gap-2'>
                            <div className='flex items-center gap-2 whitespace-nowrap'>
                                <span className='font-medium text-md font-bodyPri tracking-wide'>{"USD"} {parseFloat(course?.price / 100).toFixed(2)}</span>
                                <span className='font-normal text-sm font-bodyPri tracking-wide'>{course?.courseType === courseType.GROUP.value ? "per session" : "per hour"}</span>
                            </div>
                            <div className={"flex items-center justify-center gap-3"}>
                                <span onClick={handleBookNow} className={clsx(
                                    'px-3 py-1 flex justify-center items-center gap-1 rounded-full font-normal font-buttons border whitespace-nowrap',
                                    "border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                                )}>
                                    <span>
                                        {"Know More"}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseCard;