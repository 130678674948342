import {
    TestimonialItemContainer,
    ImageContainer,
    TextContainer,
    Quote,
    UserInfo,
} from './TestimonialsStyle';

const TestimonialItem = ({ testimonialItem }) => {

    return (
        <TestimonialItemContainer>
            <ImageContainer>
                <img src={testimonialItem.imageUrl} alt={'testimonial'} />
            </ImageContainer>
            <TextContainer>
                <Quote>"{testimonialItem.quote}"</Quote>
                <UserInfo>{testimonialItem.userName}</UserInfo>
            </TextContainer>
        </TestimonialItemContainer>
    )
}

export default TestimonialItem
