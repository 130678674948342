import React from "react";

// Components
import MyTypography from "components/Typography";

// Local Components
import Container from "./Container";

const Topics = ({ tutor, ...props }) => {
  return (
    <Container
      id={props.id}
      leftSide={
        <div className="p-5 space-y-5">
          <MyTypography className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
            {"Topics/Subjects"}
          </MyTypography>
          <div className="mt-12">
            {tutor?.categoryProficiency?.length > 0
             && tutor?.categoryProficiency?.map((ele, idx) => (
                <div key={idx} className="grid grid-cols-2 px-3 space-y-3">
                  <div>
                    <MyTypography className="text-text-700 font-semibold font-bodyPri">
                      {ele.category}
                    </MyTypography>
                  </div>
                  <div>
                    <MyTypography className="text-text-900 font-medium font-bodyPri">
                      {ele.proficiency}
                    </MyTypography>
                  </div>
                </div>
              ))}
            {tutor?.categoryProficiency?.length === 0
            && <span className={"font-bodyPri font-normal text-text-800 text-base"}>
              {"No subject Added!"}
            </span>
            }
          </div>
        </div>
      }
      rightSide={null}
      marginTop
    />
  );
};

export default Topics;
