import { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Pagination from 'components/pagination/Pagination';
import TutorCard from 'components/tutorCard/TutorCard';
import TutorCardLoader from "components/loader/TutorCardLoader"

import { TutorProfile } from 'pages/auth/myTutors/types';

import {
    getFavTutorUserListByUserId,
    createFavTutorUserDetailByUserId,
    deleteFavTutorUserDetailByUserId
} from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearFavTutorList } from 'redux/tutor/tutor.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 5


const FavTutors = () => {
    const { favTutorList } = useAppState((s) => s.tutor)
    const { user } = useAppState((s) => s.user)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()


    useEffect(() => {
        const query: any = generateQueryParams(location.search)

        dispatcher(getFavTutorUserListByUserId(user?.user?.userId, { page: query.pn || "1", records: RECORDS }))

        return () => {
            dispatcher(setClearFavTutorList())
        }
    }, [location.search])

    return (
        <div className="grid grid-cols-12">
            <div className='col-start-2 col-span-10 w-full space-y-3 md:col-start-1 md:col-span-12 lg:col-start-2 lg:col-span-10'>
                {favTutorList?.filteredFavTutorList?.results?.map((tutor: any, index: number) => (
                    <TutorCard key={index}
                        tutor={tutor}
                        isFav={user?.user?.favTutors?.filter((favTutor: TutorProfile) => favTutor?.tutorId === tutor?.tutorId).length > 0}
                        handleFav={(tutorId: number) => {
                            const favoriteTutors = user?.user?.favTutors?.filter((favTutor: TutorProfile) => favTutor?.tutorId === tutorId)
                            if (favoriteTutors.length > 0) {
                                dispatcher(deleteFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                            }
                            else {
                                dispatcher(createFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                            }
                        }}
                    />
                ))}
                {new Array(RECORDS).fill("").map((_, index) => (<TutorCardLoader key={index} isLoading={favTutorList?.isLoading} />))}
                {favTutorList?.filteredFavTutorList && favTutorList?.filteredFavTutorList?.records > 0 &&
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={favTutorList?.filteredFavTutorList?.page}
                            totalPages={favTutorList?.filteredFavTutorList?.totalPages}
                            onChangePage={(page: Number) => navigate(pagesInfo.MY_TUTORS.pagePath + "?pn=" + page)}
                        />
                    </div>
                }
                {(favTutorList?.filteredFavTutorList?.records === 0 || favTutorList?.errorMessage) &&
                    <span className='w-full h-full flex items-center justify-center text-center text-base font-bodyPri tracking-wide pt-5'>
                        {"No Teacher Found!"}
                        <Link to={"/find-tutors"}
                            className="underline underline-offset-2 font-bodyPri tracking-wide text-primary-main"
                        >
                            {"Find Teachers"}
                        </Link>
                    </span>
                }
            </div>
        </div>
    )
}

export default FavTutors;