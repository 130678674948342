import clsx from 'clsx';

import { eventNoteConst } from 'components/modals/eventActionModal/eventActionModal.data';

import { OptionSelector } from 'components/common-components/Select';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { eventStatusActions } from 'redux/event/event.const';
import { courseType } from 'redux/course/course.const';
import { setUpdateEventPayload } from 'redux/event/event.slice';


const EventActionInput = ({ selectedAction, setSelectedAction }) => {
    const { eventDetail, updateEvent } = useAppState((s) => s.event)
    const { updateBooking } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()

    const minCharacterLength = 20
    const maxCharacterLength = 100

    const eventStatusActionsObject = (eventDetail?.eventDetail
        ? eventStatusActions[eventDetail?.eventDetail?.role?.toUpperCase()][eventDetail?.eventDetail?.course?.courseType.toUpperCase()][eventDetail?.eventDetail?.status?.toUpperCase()]
        : {}
    )

    const handleNote = (event) => {
        event.preventDefault()

        dispatcher(setUpdateEventPayload({
            ...updateEvent?.updateEventPayload,
            note: event.target.value
        }))
    }

    return eventDetail?.eventDetail && selectedAction ? (
        <div className={"space-y-3"}>
            {/* Option Selector */}
            <div className={"flex items-center gap-2"}>
                <spna className={"font-bodyPri font-medium text-base text-text-800 whitespace-nowrap"}>
                    {"Change status:"}
                </spna>
                <div className={"w-32"}>
                    <OptionSelector
                        onChange={(option) => setSelectedAction(option)}
                        options={Object.values(eventStatusActionsObject)}
                        value={selectedAction.value}
                    />
                </div>
            </div>

            {/* Note */}
            <div className={"flex flex-col items-start gap-2"}>
                <div className={"w-full flex items-center justify-between gap-2 whitespace-nowrap"}>
                    <div className={"flex items-center justify-start gap-2"}>
                        <spna className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Write a note:"}
                        </spna>
                        <span className={"font-bodyPri font-normal text-xs text-text-800"}>
                            {eventNoteConst[selectedAction.value.toUpperCase()].isNoteRequired ? "(Required)" : "(Optional)"}
                        </span>
                    </div>
                </div>
                <div className={"w-full flex flex-col items-start gap-3 px-3"}>
                    <textarea
                        className={clsx(
                            "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                            "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                            "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                        )}
                        minLength={minCharacterLength}
                        maxLength={maxCharacterLength}
                        rows={4}
                        name={"todo"}
                        placeholder="Write your note ...."
                        value={updateEvent?.updateEventPayload?.note}
                        onChange={handleNote}
                    />
                    <div className={"w-full flex justify-between"}>
                        <span className={clsx(
                            "font-bodyPri font-normal text-xs text-text-800",
                            eventNoteConst[selectedAction.value.toUpperCase()].isNoteRequired
                            && (updateEvent?.updateEventPayload?.note.length < minCharacterLength
                                || updateEvent?.updateEventPayload?.note.length > maxCharacterLength)
                            && "text-red-500"
                        )}>
                            {`Please enter between ${minCharacterLength} and ${maxCharacterLength} characters`}
                        </span>
                        <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                            {updateEvent?.updateEventPayload?.note.length + "/" + maxCharacterLength}
                        </span>
                    </div>

                    {(updateEvent?.errorMsg || updateBooking?.errorMsg) &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {(updateEvent?.errorMsg || updateBooking?.errorMsg)}
                        </span>
                    }
                </div>
            </div>
        </div>
    ) : null
}

export default EventActionInput