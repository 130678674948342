import { createSlice } from "@reduxjs/toolkit";
import { BOOKING_INITIAL_STATE } from "redux/booking/booking.initialState";

import { courseType } from "redux/course/course.const";
import { filterName } from "redux/booking/booking.const";
import { bookingStatus } from "redux/booking/booking.const";


const booking = createSlice({
    name: "booking",
    initialState: BOOKING_INITIAL_STATE,
    reducers: {
        // reducers for booking list
        setBookingListLoading: (state, { payload }) => {
            state.bookingList.isLoading = payload
        },
        setBookingList: (state, { payload }) => {
            state.bookingList.bookingList = payload
            state.bookingList.filteredBookingList = payload
            state.bookingList.errorMsg = BOOKING_INITIAL_STATE.bookingList.errorMsg
        },
        setBookingListErrorMsg: (state, { payload }) => {
            state.bookingList.errorMsg = payload
            state.bookingList.bookingList = BOOKING_INITIAL_STATE.bookingList.bookingList
            state.bookingList.filteredBookingList = BOOKING_INITIAL_STATE.bookingList.filteredBookingList
        },
        setClearBookingList: (state) => {
            state.bookingList = BOOKING_INITIAL_STATE.bookingList
        },

        // reducers for booking detail
        setBookingDetailLoading: (state, { payload }) => {
            state.bookingDetail.isLoading = payload
        },
        setBookingDetail: (state, { payload }) => {
            state.bookingDetail.bookingDetail = payload
            state.bookingDetail.errorMsg = BOOKING_INITIAL_STATE.bookingDetail.errorMsg
        },
        setBookingDetailErrorMsg: (state, { payload }) => {
            state.bookingDetail.errorMsg = payload
            state.bookingDetail.bookingDetail = BOOKING_INITIAL_STATE.bookingDetail.bookingDetail
        },
        setClearBookingDetail: (state) => {
            state.bookingDetail = BOOKING_INITIAL_STATE.bookingDetail
        },
        setBookingDetailPayload: (state, { payload }) => {
            state.bookingDetail.bookingDetailPayload = payload
        },
        setClearBookingDetailPayload: (state) => {
            state.bookingDetail.bookingDetailPayload = BOOKING_INITIAL_STATE.bookingDetail.bookingDetailPayload
        },

        // reducers for add booking
        setAddBookingLoading: (state, { payload }) => {
            state.addBooking.isLoading = payload
            if (payload) state.addBooking.errorMsg = null
        },
        setAddBooking: (state, { payload }) => {
            state.addBooking.addBooking = payload
            state.addBooking.errorMsg = BOOKING_INITIAL_STATE.addBooking.errorMsg
        },
        setAddBookingErrorMsg: (state, { payload }) => {
            state.addBooking.errorMsg = payload
            state.addBooking.addBooking = BOOKING_INITIAL_STATE.addBooking.addBooking
        },
        setClearAddBooking: (state) => {
            state.addBooking = BOOKING_INITIAL_STATE.addBooking
        },
        setAddBookingPayload: (state, { payload }) => {
            state.addBooking.addBookingPayload = payload
        },
        setClearAddBookingPayload: (state) => {
            state.addBooking.addBookingPayload = BOOKING_INITIAL_STATE.addBooking.addBookingPayload
        },
        setChooseTimeSlotPayload: (state, { payload }) => {
            state.addBooking.chooseTimeSlotPayload = payload
        },
        setClearChooseTimeSlotPayload: (state) => {
            state.addBooking.chooseTimeSlotPayload = BOOKING_INITIAL_STATE.addBooking.chooseTimeSlotPayload
        },

        // reducers for booking confirmation
        setBookingConfirmationLoading: (state, { payload }) => {
            state.bookingConfirmation.isLoading = payload
        },
        setBookingConfirmation: (state, { payload }) => {
            state.bookingConfirmation.bookingConfirmation = payload
            state.bookingConfirmation.errorMsg = BOOKING_INITIAL_STATE.bookingConfirmation.errorMsg
        },
        setBookingConfirmationErrorMsg: (state, { payload }) => {
            state.bookingConfirmation.errorMsg = payload
            state.bookingConfirmation.bookingConfirmation = BOOKING_INITIAL_STATE.bookingConfirmation.bookingConfirmation
        },
        setClearBookingConfirmation: (state) => {
            state.bookingConfirmation = BOOKING_INITIAL_STATE.bookingConfirmation
        },

        // reducers for update booking
        setUpdateBookingLoading: (state, { payload }) => {
            state.updateBooking.isLoading = payload
        },
        setUpdateBooking: (state, { payload }) => {
            state.updateBooking.updateBooking = payload
            state.updateBooking.errorMsg = BOOKING_INITIAL_STATE.updateBooking.errorMsg
        },
        setUpdateBookingErrorMsg: (state, { payload }) => {
            state.updateBooking.errorMsg = payload
            state.updateBooking.updateBooking = BOOKING_INITIAL_STATE.updateBooking.updateBooking
        },
        setClearUpdateBooking: (state) => {
            state.updateBooking = BOOKING_INITIAL_STATE.updateBooking
        },

        // reducers for filter booking list
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
            const filteredList = payload.list
                .filter((booking) => (
                    (!payload.filterProps.searchText || (booking.student.firstName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())
                        || booking.student.lastName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase()))
                        && (booking.teacher.firstName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())
                            || booking.teacher.lastName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())))
                ))
                .filter((booking) => (
                    payload.filterProps.courseType === courseType.ALL.value || booking.course.courseType === payload.filterProps.courseType
                ))
                .filter((booking) => (
                    payload.filterProps.bookingStatus === bookingStatus.ALL.value || booking.status === payload.filterProps.bookingStatus
                ))

            switch (payload.filterName) {
                case filterName.BOOKING.value:
                    state.bookingList.filteredBookingList.records = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = BOOKING_INITIAL_STATE.filterProps
        }

    }
})

export const {
    setBookingListLoading,
    setBookingList,
    setBookingListErrorMsg,
    setClearBookingList,

    setBookingDetailLoading,
    setBookingDetail,
    setBookingDetailErrorMsg,
    setClearBookingDetail,
    setBookingDetailPayload,
    setClearBookingDetailPayload,

    setAddBookingLoading,
    setAddBooking,
    setAddBookingErrorMsg,
    setClearAddBooking,
    setAddBookingPayload,
    setClearAddBookingPayload,
    setChooseTimeSlotPayload,
    setClearChooseTimeSlotPayload,

    setBookingConfirmationLoading,
    setBookingConfirmation,
    setBookingConfirmationErrorMsg,
    setClearBookingConfirmation,

    setUpdateBookingLoading,
    setUpdateBooking,
    setUpdateBookingErrorMsg,
    setClearUpdateBooking,

    setFilterProps,
    setClearFilterProps
} = booking.actions

export default booking.reducer