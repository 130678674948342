import React from "react";

//Style imports
import { StyledChatViewMessageRead } from "./ChatViewMessageRead.styles";

//icon imports
import messageseen from "../../icons/messageseen.svg";
import messagesent from "../../icons/messagesent.svg";

export default function ChatViewMessageRead({ seen }) {
  return (
    <StyledChatViewMessageRead>
      <img src={seen ? messageseen : messagesent} alt="status icon" />
    </StyledChatViewMessageRead>
  );
}
