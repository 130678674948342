import tw, { styled } from "twin.macro";

export const StyledCourseMenu = styled.div`
  ${tw`absolute bottom[52px] left-0 text-center z-30 flex flex-col items-center rounded-t-md bg-primary-light w-full`}

  div {
    ${tw`text-base text-primary-main py-1 w-full flex justify-center cursor-pointer`}
    &:last-of-type {
      ${tw`border-t border-dotted border-primary-main`}
    }
  }
`;
