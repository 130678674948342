import { useReducer, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import {
    Container,
    Section,
    InputBox,
    SelectCheckBox,
    CheckBox,
    Boxes,
    BackButton,
    DigitBox,
    EmailLabel,
    ResendOtpContainer,
    ForgotPasswordSection,
    LogInButton,
} from './LogInFormStyle'

import { BsCheckAll } from "react-icons/bs";
import { FaSpinner } from 'react-icons/fa';
import { MdCancel } from "react-icons/md"
import { BiArrowBack } from "react-icons/bi";

import ButtonLoader from 'components/loader/ButtonLoader';
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import {
    validateEmail,
    validateMobileNo,
    validatePassword
} from 'utils/validation-functions';

import { createSendOtp } from 'redux/notification/notification.request';
import { getMasterCountryList, getTimeZoneMasterCountryDetailByTimeZone } from 'redux/master/master.request';
import { setClearCountryList, setClearTimeZoneCountryDetail } from 'redux/master/master.slice';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { timeZone } from "utils/dateTime.utils";

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    }
}

const pattern = /[a-zA-z]{1}/;

const LogInForm = ({ formSubmit }) => {
    const { logInSignUp } = useAppState((s) => s.auth)
    const { sendOtp } = useAppState((s) => s.notification)
    const { user } = useAppState((s) => s.user)
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)

    const [formData, setFromData] = useReducer(formReducer, {});
    const [checkLoginType, setCheckLoginType] = useState('password')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [emailOrPhoneErrorText, setEmailOrPhoneErrorText] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otp, setOtp] = useState(new Array(4).fill(''))
    const [isOtpError, setIsOtpError] = useState(false)
    const [minutes, setMinutes] = useState(1)
    const [seconds, setSeconds] = useState(59)
    const [digits, setDigits] = useState()
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })

    const dispatcher = useAppDispatcher()

    const loadingElement = logInSignUp?.isLoading || user?.isLoading

    useEffect(() => {
        const otpMapping = otp.map(Number)
        const singleNumber = Number(otpMapping.join(''))
        setDigits(singleNumber)
    }, [otp])

    useEffect(() => {
        dispatcher(getTimeZoneMasterCountryDetailByTimeZone(timeZone))
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearTimeZoneCountryDetail())
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        const { emailOrPhone, password } = formData;
        if (pattern.test(formData.emailOrPhone)) {
            if (emailOrPhone && !validateEmail(emailOrPhone)) setEmailOrPhoneErrorText('Enter valid Email!');
            else setEmailOrPhoneErrorText('');
        }
        else {
            if (emailOrPhone && !validateMobileNo(emailOrPhone)) setEmailOrPhoneErrorText('Enter valid Email!');
            else setEmailOrPhoneErrorText('');
        }
        if (password && !validatePassword(password)) setPasswordError("Invalid Password")
        else setPasswordError('');

    }, [formData])

    useEffect(() => {
        const timer = setInterval(() => {
            if (isSubmitted && seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (isSubmitted && seconds === 0) {
                if (minutes === 0) {
                    clearInterval(timer)
                }
                else {
                    setMinutes(minutes - 1)
                    setSeconds(59)
                }
            }
        }, 1000)
        if (!isSubmitted) {
            setSeconds(59)
            setMinutes(1)
        }
        return () => {
            clearInterval(timer)
        }
    })

    const submitForm = (event) => {
        event.preventDefault();

        if (logInSignUp?.isLoading) return;

        if (!formData.emailOrPhone) {
            toast.warning("please Enter Email or Phone")
            return;
        }

        if (checkLoginType === "password" && !formData.password) {
            toast.warning("please Enter Password")
            return;
        }

        if (checkLoginType === "password") {
            formSubmit(formData);
        } else if (checkLoginType === "verified") {
            formSubmit({
                email: formData?.emailOrPhone,
                otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                otp: digits
            })
        }
        else {
            dispatcher(createSendOtp({
                otpDeliveryMethod: "email",
                email: formData.emailOrPhone
            }))
            toast.success("Code sent successfully")
            setIsSubmitted(true)
        }
    }

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d)]);
        // focus next input
        if (element.nextSibling) {
            element.nextSibling.focus()
        }
    };

    const resendOtp = () => {
        if (minutes === 0 && seconds === 0) {
            toast.success("Code sent successfully")
            setIsSubmitted(true)
            setMinutes(1)
            setSeconds(59)
            dispatcher(createSendOtp({
                otpDeliveryMethod: "email",
                email: formData.emailOrPhone
            }))
        }
    }

    const handleOnChange = (event) => {
        setFromData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    if (isSubmitted) {
        return (
            <Container onSubmit={submitForm}>
                <BackButton onClick={() => {
                    setOtp([...otp.map((d, idx) => '')]);
                    setIsOtpError(false)
                    setCheckLoginType("otp")
                    setIsSubmitted(false)
                }}>
                    <BiArrowBack />
                    <EmailLabel>
                        <span>
                            {!pattern.test(formData.emailOrPhone) ?
                                "Phone:" : "Email:"}</span>
                        <span>{formData.emailOrPhone} </span>
                        <BsCheckAll className='text-green-500' />
                    </EmailLabel>
                </BackButton>
                <Boxes>
                    {otp.map((data, index) => (
                        <DigitBox
                            isOtpError={isOtpError}
                            type='text'
                            maxLength={1}
                            key={index}
                            name={"otp"}
                            value={data}
                            autoFocus={index === 0 && true}
                            onChange={e => handleOtpChange(e.target, index)}
                            onFocus={e => e.target.select()}
                        />
                    ))}
                    {isOtpError && <MdCancel className=' text-base font-bodyPri text-red-800' />}
                </Boxes>
                <ResendOtpContainer
                    onClick={resendOtp}
                    minutes={minutes}
                    seconds={seconds}
                >
                    {minutes === 0 && seconds === 0 ? "Resend Code" :
                        `Resend Code in ${minutes < 10 ? '0' + minutes : minutes}: ${seconds < 10 ? '0' + seconds : seconds}`}
                </ResendOtpContainer>
                <LogInButton type='submit' onClick={() => setCheckLoginType("verified")}>
                    <div className={" whitespace-nowrap px-5"}>
                        {'Verify Code'}
                    </div>
                    {loadingElement &&
                        <div className={""}>
                            <ButtonLoader isLoading={loadingElement} />
                        </div>
                    }
                </LogInButton>
                {logInSignUp?.errorMsg &&
                    <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-sm"}>
                        {logInSignUp?.errorMsg}
                    </span>
                }
            </Container>
        )
    }

    return (
        <Container onSubmit={submitForm}>
            <Section>
                {!pattern.test(formData.emailOrPhone) && countryList?.isLoading &&
                    <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                }
                {!pattern.test(formData.emailOrPhone) && countryList?.countryList &&
                    <IsdCodeSelector
                        options={countryList?.countryList?.map((country) => ({
                            label: country?.isdCode,
                            value: country?.masterCountryId,
                            country: country
                        }))}
                        value={myCountry?.masterCountryId}
                        onChange={(option) => onSelectIsdCode(option)}
                    />
                }
                <InputBox type='text' name='emailOrPhone' placeholder='Enter your email or phone' onChange={handleOnChange} value={formData.emailOrPhone} onFocus={true} required />
                {formData.emailOrPhone && emailOrPhoneErrorText.length === 0 && <BsCheckAll className='text-green-500' />}
                {formData.emailOrPhone && emailOrPhoneErrorText.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
            </Section>
            <SelectCheckBox>
                <CheckBox>
                    <input type="radio" name="status" checked={checkLoginType === "password"} onClick={() => { setCheckLoginType("password") }} />
                    <label htmlFor='password'>{"Password"}</label>
                </CheckBox>
                <CheckBox>
                    <input type="radio" name="status" checked={checkLoginType === "otp"} onClick={() => { setCheckLoginType("otp") }} />
                    <label htmlFor='otp'>{"Code"}</label>
                </CheckBox>
            </SelectCheckBox>
            {checkLoginType === "password" &&
                <>
                    <Section>
                        <InputBox type='password' name='password' placeholder='Password' onChange={handleOnChange} required />
                        {formData.password && passwordError.length === 0 && <BsCheckAll className='text-green-500' />}
                        {formData.password && passwordError.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
                    </Section>
                    <ForgotPasswordSection to={'#'}>{'Forgot Password?'}</ForgotPasswordSection>
                    <LogInButton
                        type='submit'
                        countryListLoading={!pattern.test(formData.emailOrPhone) && countryList?.isLoading}
                    >
                        <div className={clsx(
                            " whitespace-nowrap px-5",
                        )}>
                            {'Log in'}
                        </div>
                        {loadingElement &&
                            <div className={""}>
                                <ButtonLoader isLoading={loadingElement} />
                            </div>
                        }
                    </LogInButton>
                    {logInSignUp?.errorMsg &&
                        <span className={"w-full text-center font-bodyPri font-normal text-red-500 text-sm"}>
                            {logInSignUp?.errorMsg}
                        </span>
                    }
                </>
            }
            {checkLoginType === "otp" &&
                <LogInButton type='submit'>{'Send Code'}</LogInButton>
            }
        </Container>
    )
};

export default LogInForm;
