import { contactTimeConst } from "redux/needTutor/needTutor.const";

const needTutorPayload = {
    journey: "",
    level: "",
    subject: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    city: "",
    selectedTime: contactTimeConst.ANYTIME.value,
    isEmailVerified: false,
    isDone: false
}

export const NEED_TUTOR_INITIAL_STATE = {
    needTutor: {
        isLoading: false,
        needTutor: null,
        needTutorPayload: needTutorPayload,
        errorMsg: null
    },
}