import { FaSpinner } from 'react-icons/fa';

import EventDetailTitle from 'components/modals/eventDetailModal/EventDetailTitle';
import EventDetailDateTimeSlot from 'components/modals/eventDetailModal/EventDetailDateTimeSlot';
import EventDetailCountDown from 'components/modals/eventDetailModal/EventDetailCountDown';
import EventDetailTypeView from 'components/modals/eventDetailModal/EventDetailTypeView';
import EventDetailRoleView from 'components/modals/eventDetailModal/EventDetailRoleView';
import EventDetailCourseType from 'components/modals/eventDetailModal/EventDetailCourseType';
import EventDetailStatus from 'components/modals/eventDetailModal/EventDetailStatus';
import EventDetailParticipants from 'components/modals/eventDetailModal/EventDetailParticipants';
import EventDetailEventLog from 'components/modals/eventDetailModal/EventDetailEventLog';
// import EventDetailFooter from 'components/modals/eventDetailModal/EventDetailFooter';

import { useAppState } from 'hooks/useStore';


const EventDetailModal = () => {
    const { eventDetail } = useAppState(s => s.event)

    return (
        <div className={"space-y-5 overflow-x-hidden select-none"}>

            <div className={"min-h-[24rem] block space-y-3 pt-2"}>

                {eventDetail?.isLoading &&
                    <div className={"h-full w-full flex items-center justify-center gap-2"}>
                        <span className={"font-bodyPri font-medium text-xl text-text-700"}>
                            {"Loading"}
                        </span>
                        <FaSpinner className="text-2xl text-primary-main animate-spin" />
                    </div>
                }

                <EventDetailTitle />

                <EventDetailTypeView />

                <EventDetailDateTimeSlot />

                <EventDetailCountDown />

                <EventDetailRoleView />

                <EventDetailCourseType />

                <EventDetailStatus />

                {eventDetail?.eventDetail && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                <EventDetailParticipants />

                {eventDetail?.eventDetail && <div className={"h-0.5 bg-background-medium w-full rounded-full"}></div>}

                <EventDetailEventLog />

            </div>

            {/* <EventDetailFooter /> */}
        </div>
    )
}

export default EventDetailModal