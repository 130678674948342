import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Icon imports
import { VideoCameraIcon } from "@heroicons/react/outline";

//Style imports
import { StyledStartMeetingButton } from "./StartMeeting.styles";

export default function StartMeeting({ isCreator, text, link }) {
  const { dispatch } = useChat();
  function joinMeeting() {
    window.open(link, "_blank");
  }

  function handleStartMeeting() {
    if (!isCreator) {
      joinMeeting();
      return;
    }

    dispatch({
      type: "START_MEETING",
      payload: true,
    });
  }

  return (
    <StyledStartMeetingButton onClick={handleStartMeeting}>
      {text}
      <VideoCameraIcon className="h-5 w-5 ml-1.5" />
    </StyledStartMeetingButton>
  );
}
