import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { FiEdit } from 'react-icons/fi';
import { MdOutlineEmail } from 'react-icons/md';

import ComponentLoader from 'components/loader/ComponentLoader';
import ButtonLoader from 'components/loader/ButtonLoader';
import InputOtp from 'components/inputOtp/InputOtp';

import { viewContainerConst, editBtnConst } from 'components/modals/needATutorModal/data';

import { createSendOtp, createVerifyOtp } from "redux/notification/notification.request";
import { createSignUp } from 'redux/auth/auth.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setNeedTutorPayload } from 'redux/needTutor/needTutor.slice';
import { otpStatus } from 'redux/notification/notification.const';

import { validateEmail } from 'utils/validation-functions';
import SaveAndCancelBtn from 'components/modals/needATutorModal/SendAndCancelBtn';
import { FaSpinner } from 'react-icons/fa';

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const VerifyCode = ({ setStepCount, setViewContainer }) => {
    const { logInSignUp } = useAppState((s) => s.auth)
    const { user } = useAppState((state) => state.user)
    const { sendOtp, verifyOtp } = useAppState((state) => state.notification)
    const { needTutor } = useAppState((state) => state.needTutor)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [editBtn, setEditBtn] = useState(null)
    const [emailAddress, setEmailAddress] = useState()
    const [emailAddressError, setEmailAddressError] = useState()

    useEffect(() => {
        if (needTutor?.needTutorPayload?.email) {
            setEmailAddress(needTutor?.needTutorPayload?.email)
        }
    }, [needTutor?.needTutorPayload?.email])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setEmailAddressError("Invalid Email")
        } else {
            setEmailAddressError("")
        }
    }, [emailAddress])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(createSignUp({
                email: needTutor?.needTutorPayload?.email,
                firstName: needTutor?.needTutorPayload?.firstName,
                lastName: needTutor?.needTutorPayload?.lastName,
                companyId: 1,
            }))
            dispatcher(setNeedTutorPayload({
                ...needTutor?.needTutorPayload,
                isEmailVerified: true
            }))
            toast.success("Code verified successfully")
        }
        if (verifyOtp?.verifyOtp && (verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value || verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value)) {
            setViewContainer(viewContainerConst.EMAIL.value)
            toast.warning("Something went wrong! Please wait!")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            toast.error("Incorrect Code!")
            setIsOtpError(true)
            return;
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            if (!user?.user?.mobileNo) {
                setStepCount(6)
                setViewContainer(viewContainerConst.PHONE.value)
            }
            if (user?.user?.mobileNo) {
                setStepCount(7)
                setViewContainer(viewContainerConst.CITY.value)
            }
        }
    }, [user?.user])

    // useEffect(() => {
    //     if (myOtp?.length === 4 && sendOtp?.sendOtp?.otpNotificationId) {
    //         dispatcher(setClearVerifyOtp())
    //         dispatcher(createVerifyOtp({
    //             otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
    //             otp: myOtp
    //         }))
    //     }
    // }, [myOtp])

    const onSendCode = () => {
        dispatcher(setClearSendOtp())
        if (!emailAddress) {
            alert("Please enter email!")
            return;
        }
        if (emailAddress && (emailAddress !== needTutor?.needTutorPayload?.email || !needTutor?.needTutor?.isEmailVerified)) {
            dispatcher(setNeedTutorPayload({
                ...needTutor?.needTutorPayload,
                email: emailAddress
            }))
            dispatcher(createSendOtp({
                otpDeliveryMethod: "email",
                email: emailAddress
            }))
            toast.success("Code sent successfully")
            setEditBtn(null)
            return;
        }
        if (emailAddress && (emailAddress === needTutor?.needTutorPayload?.email && needTutor?.needTutorPayload?.isEmailVerified)) {
            toast.warning("Email already verified!")
            return;
        }
    }

    const onCancel = () => {
        if (!validateEmail(emailAddress)) {
            alert("Please enter valid email address")
            return;
        }
        setEmailAddress(needTutor?.needTutorPayload?.email)
        setEditBtn(null)
    }

    const onHandleVerifyButton = () => {
        if (myOtp?.length < 4) return;
        dispatcher(setClearVerifyOtp())
        if (sendOtp?.sendOtp?.otpNotificationId) {
            dispatcher(createVerifyOtp({
                otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                otp: myOtp
            }))
        }
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <div className={"flex flex-col items-start justify-start gap-2"}>
                <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                    <span className={"capitalize"}>{needTutor?.needTutorPayload?.firstName}</span>
                    {", we've emailed a code to verify your email address."}
                </span>
                <span className={"font-bodyPri font-normal text-md text-text-800 italic"}>
                    {"You will get a shiny new dashboard with many other tools to support your learning process."}
                </span>
            </div>
            <div className={"w-full flex flex-col items-start justify-center gap-2"}>
                {editBtn !== editBtnConst.EMAIL.value &&
                    <div className={"flex items-center justify-start gap-3"}>
                        <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide italic"}>
                            {emailAddress}
                        </span>
                        <FiEdit className={"text-text-500 text-lg cursor-pointer"} onClick={() => setEditBtn(editBtnConst.EMAIL.value)} />
                    </div>
                }
                {editBtn === editBtnConst.EMAIL.value &&
                    <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                        <div className={clsx(
                            "w-1/2 px-2 py-3 flex items-center justify-start gap-1.5 border-2 border-divider-medium focus:border-2 focus:border-text-400 rounded-md"
                        )}>
                            <MdOutlineEmail className={"text-3xl text-text-500"} />
                            <input
                                type={"text"}
                                placeholder={"email@domain.com"}
                                className={clsx(
                                    "w-full px-2 focus:outline-none font-bodyPri font-normal text-text-800 text-lg",
                                    "placeholder:text-text-500 placeholder:text-lg"
                                )}
                                autoFocus={true}
                                value={emailAddress}
                                onChange={(event) => setEmailAddress(event.target.value)}
                            />
                        </div>
                        {emailAddressError &&
                            <span className={"font-bodyPri font-normal text-red-500 text-base"}>
                                {emailAddressError}
                            </span>
                        }
                        <SaveAndCancelBtn
                            onCancel={() => onCancel()}
                            onSend={() => onSendCode()}
                        />
                    </div>
                }
                {(editBtn !== editBtnConst.EMAIL.value) &&
                    <div className={"space-y-1.5"}>
                        <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                            {"Code"}
                        </span>
                        {sendOtp?.isLoading && <ComponentLoader isLoading={sendOtp?.isLoading} />}
                        {sendOtp?.sendOtp &&
                            <InputOtp
                                email={emailAddress}
                                setMyOtp={setMyOtp}
                                isOtpError={isOtpError}
                                setIsOtpError={setIsOtpError}
                            />
                        }
                        {sendOtp?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-text-800 text-sm"}>
                                {sendOtp?.errorMsg}
                            </span>
                        }
                    </div>
                }
            </div>
            {(editBtn !== editBtnConst.EMAIL.value) &&
                <span
                    className={clsx(
                        "w-48 py-3 flex justify-center items-center rounded-full",
                        "font-buttons font-medium text-2xl text-text-50",
                        myOtp?.length === 4 && "bg-primary-dark cursor-pointer transition ease-in-out delay-100 duration-300 transform hover:scale-105",
                        myOtp?.length < 4 && "bg-divider-medium",
                    )}
                    onClick={onHandleVerifyButton}
                >
                    {verifyOtp?.isLoading && <ButtonLoader isLoading={verifyOtp?.isLoading} />}
                    {!verifyOtp?.isLoading && "Verify"}
                </span>
            }
            {logInSignUp?.isLoading &&
                <div className={"flex items-center justify-center gap-2"}>
                    <FaSpinner className={"inline-flex text-primary-main animate-spin"} />
                    <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                        {"Please wait ..."}
                    </span>
                </div>
            }
            <div className={"font-bodyPri font-normal text-text-800 text-xs tracking-wide"}>
                {"Rest assured, we will not spam you. By signing up, you agree to"}
                <a
                    href={edulyteTermsOfService}
                    rel={"noreferrer"}
                    target={"_blank"}
                    className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                >
                    {"Terms of service"}
                </a>
                {"and"}
                <a
                    href={edulytePrivacyPolicy}
                    rel={"noreferrer"}
                    target={"_blank"}
                    className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                >
                    {"Privacy Policy"}
                </a>
            </div>
        </div>
    )
}

export default VerifyCode;