import { useState, useEffect } from 'react';
import clsx from 'clsx';

import { MdCloudUpload } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";

import { imageType } from 'components/modals/imagePickerModal/imagePickerModal.data';

import ImageCropper from 'components/modals/imagePickerModal/ImageCropper';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { uploadDocumentToS3 } from 'redux/document/document.request';
import { setUploadDocumentPayload } from 'redux/document/document.slice';


const ProfilePicModal = () => {
    const { modal } = useAppState(s => s.local)
    const { uploadDocument } = useAppState(s => s.document)

    const dispatcher = useAppDispatcher()

    const [selectedFile, setSelectedFile] = useState(null)
    const [croppedFile, setCroppedFile] = useState(null)

    useEffect(() => {
        if (uploadDocument?.uploadDocument) {
            dispatcher(setModal({
                ...modal,
                [modalConst.IMAGE_PICKER_MODAL.stateKey]: false
            }))
        }
    }, [uploadDocument?.uploadDocument])

    const handleOnFileChange = (event) => {
        const files = event.target.files;
        if (!files || files.length === 0) {
            alert("Image not selected!")
            return;
        }

        const file = files[0];
        setSelectedFile(file)
    }

    const onHandleImage = () => {
        if (!croppedFile) return;

        dispatcher(setUploadDocumentPayload({
            ...uploadDocument?.uploadDocumentPayload,
            fileName: croppedFile.name,
            fileType: croppedFile.type,
            file: croppedFile
        }))

        dispatcher(uploadDocumentToS3())
    }

    const handleOnClearSelect = () => {
        setSelectedFile(null)
        setCroppedFile(null)
    }

    return (
        <div className='space-y-5'>
            <div className={"min-h-[24rem] flex flex-col gap-5 overflow-auto"}>

                <div className={"w-full flex items-center justify-center"}>
                    <span className={"truncate text-center font-bodyPri font-medium text-lg text-text-900"}>
                        {imageType[uploadDocument?.uploadDocumentPayload?.fileCategory].title}
                    </span>
                </div>

                <div className='w-full flex flex-col justify-center items-center p-3'>
                    {!selectedFile &&
                        <div className={"flex flex-col items-center justify-center "}>
                            <MdCloudUpload className={"text-9xl text-text-600"} />
                            <label className='relative flex flex-col justify-center items-center overflow-hidden px-3 py-1 rounded-lg border-2 border-text-600 hover:bg-text-300 cursor-pointer'>
                                <input
                                    className={'border border-yellow-300'}
                                    type={"file"}
                                    id={"file"}
                                    name={"image"}
                                    style={{ display: "none" }}
                                    multiple={false}
                                    accept={imageType[uploadDocument?.uploadDocumentPayload?.fileCategory].acceptFileType}
                                    title={'upload'}
                                    onChange={handleOnFileChange}
                                />
                                <span className='font-bodyPri font-medium text-2xl text-text-600 text-wider'>
                                    {"Upload Image"}
                                </span>
                            </label>
                        </div>
                    }
                    {selectedFile &&
                        <ImageCropper
                            selectedFile={selectedFile}
                            aspectRatio={imageType[uploadDocument?.uploadDocumentPayload?.fileCategory].aspect}
                            setCroppedFile={setCroppedFile}
                        />
                    }
                </div>
            </div>

            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center gap-2"}>
                    {uploadDocument?.isLoading &&
                        <>
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {"Loading"}
                            </span>
                            <FaSpinner className="text-primary-main animate-spin" />
                        </>
                    }
                </div>
                <div className={"flex items-center justify-center gap-5"}>
                    <span onClick={handleOnClearSelect} className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        (croppedFile && !uploadDocument?.isLoading)
                            ? "text-text-800 border border-text-600 hover:bg-background-lightDark hover:text-text-50"
                            : "border border-divider-darkLight text-text-300",
                    )}>
                        {"Clear"}
                    </span>
                    <span onClick={onHandleImage} className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        (croppedFile && !uploadDocument?.isLoading)
                            ? "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                            : "border border-secondary-light text-secondary-light",
                    )}>
                        {"Submit"}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default ProfilePicModal


// const url = URL.createObjectURL(file)