import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { dayjs, timeZone } from 'utils/dateTime.utils';

import { MdAccessTimeFilled } from 'react-icons/md';
import { HiVideoCamera } from 'react-icons/hi';
import { AiTwotoneCalendar } from 'react-icons/ai';

import ComponentLoader from 'components/loader/ComponentLoader';
import TimeSlot from 'components/modals/chooseTimeSlotModal/TimeSlot';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getUserAvailabilityByUserId } from 'redux/availability/availability.request';
import { getUserEventListByUserId } from 'redux/event/event.request';
import { getSelectedCourseByCourseId } from 'redux/course/course.request';
import { setClearAvailability } from 'redux/availability/availability.slice';
import { setClearUserEventList } from 'redux/event/event.slice';

import { getDayAvailability } from 'utils/availability.utils';


const ChooseTimeSlotModal = () => {
    const { selectedCourse } = useAppState((s) => s.course)
    const { availability } = useAppState((s) => s.availability)
    const { userEventList } = useAppState((s) => s.event)
    const { addBooking } = useAppState((s) => s.booking)

    const dispatcher = useAppDispatcher()

    const [dailyAvailabilities, setDailyAvailabilities] = useState(null)

    useEffect(() => {
        if (selectedCourse?.selectedCourse) {
            dispatcher(getUserAvailabilityByUserId(selectedCourse?.selectedCourse?.tutor?.userId))
            dispatcher(getUserEventListByUserId(selectedCourse?.selectedCourse?.tutor?.userId))
        } else if (!selectedCourse?.selectedCourse && selectedCourse?.isLoading === false && !selectedCourse?.errorMsg) {
            dispatcher(getSelectedCourseByCourseId(addBooking?.chooseTimeSlotPayload?.actionItem?.courseId))
        }

        return () => {
            dispatcher(setClearAvailability())
            dispatcher(setClearUserEventList())
        }
    }, [selectedCourse?.selectedCourse])

    useEffect(() => {
        setAvailability()
    }, [availability?.availability, addBooking?.chooseTimeSlotPayload?.sessionDuration])

    const setAvailability = async () => {
        const dAvailability = await getDayAvailability(availability?.availability, addBooking?.chooseTimeSlotPayload?.sessionDuration)
        setDailyAvailabilities(dAvailability)
    }

    if (selectedCourse?.isLoading || availability?.isLoading || userEventList.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedCourse?.isLoading || availability?.isLoading || userEventList.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedCourse?.errorMsg || availability?.errorMsg) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedCourse?.errorMsg || availability?.errorMsg}
                </span>
            </div>
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block"}>

                <div className={'grid grid-col-12 gap-5'}>

                    <div className={"col-span-12 space-y-3"}>
                        <span className={"turncate block text-center font-bodyPri font-medium text-base text-text-900"}>
                            {selectedCourse?.selectedCourse?.courseTitle?.substring(0, 50) + (selectedCourse?.selectedCourse?.courseTitle?.length > 50 ? "..." : "")}
                        </span>
                        <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
                    </div>

                    <div className={"col-span-12 md:col-span-4 space-y-3"}>

                        <div className={"w-52 h-32 border border-border-medium rounded-lg overflow-hidden"}>
                            <img src={selectedCourse?.selectedCourse?.thumbnailPicUrl} alt={"course-thumbnail"}
                                className={"w-full h-full object-cover"}
                            />
                        </div>

                        <div className={"w-full md:w-52 flex flex-col items-start gap-2 overflow-hidden"}>
                            <div className={"flex items-center justify-start gap-2"}>
                                <div className='relative w-12 h-12 rounded-full'>
                                    <img src={selectedCourse?.selectedCourse?.tutor?.profilePicUrl} alt={"profile-pic"}
                                        className="w-full h-full rounded-full object-cover"
                                    />
                                    <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                        <img src={`https://countryflagsapi.com/png/${selectedCourse?.selectedCourse?.tutor?.countryDomain}`} alt={"country-flag"}
                                            className='w-full h-full object-cover'
                                        />
                                    </div>
                                </div>
                                <span className='font-normal font-bodyPri text-sm tracking-wide'>
                                    {selectedCourse?.selectedCourse?.tutor?.firstName + " " + selectedCourse?.selectedCourse?.tutor?.lastName.charAt(0) + "."}
                                </span>
                            </div>
                            <span className={"font-bodyPri font-normal text-sm text-text-700 flex justify-start items-center gap-1"}>
                                <MdAccessTimeFilled className={"text-sm"} />
                                {addBooking?.chooseTimeSlotPayload?.sessionDuration + " min | " + addBooking?.chooseTimeSlotPayload?.noOfSessions + " sessions"}
                            </span>
                            <span className={"inline space-x-2 font-bodyPri font-normal text-sm text-text-700"}>
                                <HiVideoCamera className={"text-md inline"} />
                                {selectedCourse?.selectedCourse?.courseTitleDescription}
                            </span>
                        </div>
                    </div>

                    <div className={"col-span-12 md:col-span-8 space-y-2"}>

                        <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
                            {"Available Slots"}
                        </span>

                        <div className={clsx(
                            "w-full flex flex-col items-start justify-start gap-5 h-full md:h-96 overflow-hidden overflow-y-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {dailyAvailabilities?.map((dailyAvail, index) => (
                                <div key={index} className={"flex flex-col items-start justify-start gap-3"}>
                                    <span className={"font-bodyPri font-medium text-base text-text-900 flex items-center justify-start gap-2"}>
                                        <AiTwotoneCalendar />
                                        {dayjs(dailyAvail.date + " " + dayjs().utc().format("HH:mm:ss") + "+00:00").tz(timeZone).format('dddd, DD MMM YYYY')}
                                    </span>
                                    <div className={"px-3 flex flex-wrap items-center gap-2"}>
                                        {dailyAvail.timeSlots.map((timeSlot, index) => (
                                            <TimeSlot key={index} timeSlot={timeSlot} dailyAvail={dailyAvail} />
                                        ))}
                                    </div>
                                </div>
                            ))}
                            {dailyAvailabilities?.length <= 0 &&
                                <div className={"font-bodyPri font-semibold text-md text-text-700"}>
                                    {"No Available Slots"}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseTimeSlotModal