import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { NavLink as RouterLink } from 'react-router-dom';

import { School } from '@mui/icons-material';
import { FaChalkboardTeacher } from 'react-icons/fa';

import { sideTopBarPagesName } from 'utils/pagesInfo';

export const Container = motion(styled.div`
    ${tw`
        hidden
    `}
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        lg:flex h-screen w-full pt-16 bg-primary-main sticky top-0 overflow-y-auto
        scrollbar scrollbar-track-primary-main scrollbar-thumb-rounded-full
        hover:scrollbar-thumb-divider-medium
    `}
`);

export const Content = tw.div``;

export const ListItems = styled.div`
    ${tw`
        flex flex-col justify-center text-text-50 gap-2 py-2
    `}
    .active {
        ${tw`
            bg-white text-primary-main shadow-xl
        `}
    }
`;

export const ListItem = tw.div`
    w-full flex flex-row items-center h-12 cursor-pointer select-none rounded-r-full pr-5
    hover:(bg-primary-light text-primary-main! scale-125)
`;

export const ItemLink = tw(RouterLink)`
    w-full flex flex-row items-center h-12 cursor-pointer select-none rounded-r-full pr-5
    hover:(bg-primary-light text-primary-main! scale-125) 
`;

export const ItemIcon = tw.span`
    text-4xl px-5 flex items-center h-full
`;

export const ItemName = motion(tw.span`
    inline-block overflow-hidden flex flex-nowrap items-center h-full
    font-bodyPri font-normal text-base whitespace-nowrap
`);

export const StudentIcon = motion(tw(School)``);

export const TutorIcon = motion(tw(FaChalkboardTeacher)`
    text-2xl
`);
