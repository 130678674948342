import React, { useEffect, useState } from "react";

//Firebase imports
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { database } from "config/firebase"

// Context
import { useUser } from "../../contexts/userContext"

//Helper functions
import updateNotifications from "../../helpers/updateNotifications";

//Component imports
import NotificationList from "../NotificationList/NotificationList";

//Icon imports
import { BellIcon } from "@heroicons/react/outline";

//Style imports
import {
  StyledNotificationBar,
  StyledNotificationBellContainer,
  StyledNotificationCount,
} from "./NotificationBar.styles";

export default function NotificationBar() {
  const { user } = useUser()
  const { userId } = user;

  const [showList, setShowList] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [groupNotifications, setGroupNotifications] = useState([]);
  const combinedNotifications = [...notifications, ...groupNotifications];

  //Fetch notifications for individual chats
  useEffect(() => {
    const notificationsRef = collection(database, "notifications");
    const notificationsQuery = query(
      notificationsRef,
      where("receiver", "==", userId),
      where("seen", "==", false),
      orderBy("sentAt", "desc"),
      limit(10)
    );
    const unsubscribe = onSnapshot(
      notificationsQuery,
      (notificationsListSnapshot) => {
        const notificationsList = notificationsListSnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

        setNotifications(notificationsList);
      }
    );
    return () => unsubscribe();
  }, [userId]);

  //fetch notifications for groups
  useEffect(() => {
    const groupNotificationsRef = collection(database, "groupnotifications");
    const groupNotificationsQuery = query(
      groupNotificationsRef,
      where("members", "array-contains", userId),
      orderBy("sentAt", "desc"),
      limit(5)
    );
    const unsubscribe = onSnapshot(
      groupNotificationsQuery,
      (notificationsListSnapshot) => {
        const notificationsList = notificationsListSnapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          };
        });
        const userNotifications = notificationsList.filter(
          (notification) => !notification.muted.includes(userId)
        );
        setGroupNotifications(userNotifications);
      }
    );
    return () => unsubscribe();
  }, [userId]);

  function countNotifications() {
    if (combinedNotifications.length === 0) return "";
    if (combinedNotifications.length > 9) return "9 +";
    return combinedNotifications.length;
  }

  const numberOfNotifications = countNotifications();

  async function showNotificationList() {
    if (combinedNotifications.length === 0) return;
    setShowList(!showList);
    if (showList) {
      await updateNotifications(combinedNotifications, user);
    }
  }

  return (
    // <StyledNotificationBar>
    //   </StyledNotificationBar>
    <div className={'relative'} >
      <StyledNotificationBellContainer onClick={showNotificationList}>
        <BellIcon className="h-8 w-8" />
        {/* <BellIcon className="text-primary-dark h-8 w-8" /> */}
        <StyledNotificationCount>
          {numberOfNotifications}
        </StyledNotificationCount>
      </StyledNotificationBellContainer>
      {showList && (
        <NotificationList
          notifications={notifications}
          groupNotifications={groupNotifications}
          setShowList={setShowList}
          user={user}
        />
      )}
    </div>
  );
}

  // const user = {
  //   userId: "cd3obmfY6wKHQXiLVLqj",
  //   email: "testmail@gmail.com",
  //   mobile: "1234567890",
  //   emailVerify: true,
  //   mobileVerify: true,
  //   firstName: "John",
  //   lastName: "Doe",
  //   profilePicUrl:
  //     "https://firebasestorage.googleapis.com/v0/b/edulyte-7223b.appspot.com/o/resize3.jpg?alt=media&token=77a8779a-d384-45ef-bab3-1c575babc9da",
  //   roles: ["tutor"],
  //   memberShip: ["premium"],
  // };
