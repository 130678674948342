import {
    FeaturedItemContainer,
    TitleText,
    Description,
} from './TeachWithUsFeaturedStyle';

const FeaturedItem = ({ featuredItem }) => {

    return (
        <FeaturedItemContainer>
            <img src={featuredItem.icon} alt={featuredItem.title} />
            <TitleText>{featuredItem.title}</TitleText>
            <Description>{featuredItem.description}</Description>
        </FeaturedItemContainer>
    )
}

export default FeaturedItem;
