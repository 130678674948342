import { createSlice } from "@reduxjs/toolkit";
import { TUTOR_ONBOARDING_INITIAL_STATE } from "redux/tutorOnboarding/tutorOnboarding.initialState"

const tutorOnboarding = createSlice({
    name: "tutorOnboarding",
    initialState: TUTOR_ONBOARDING_INITIAL_STATE,
    reducers: {
        setTutorOnboardingLoading: (state, { payload }) => {
            state.tutorOnboarding.isLoading = payload
        },
        setTutorOnboarding: (state, { payload }) => {
            state.tutorOnboarding.tutorOnboarding = payload
        },
        setTutorOnboardingErrorMsg: (state, { payload }) => {
            state.tutorOnboarding.errorMsg = payload
        },

        setTutorOnboardingPayload: (state, { payload }) => {
            console.log(payload)
            state.tutorOnboarding.tutorOnboardingPayload = payload
        },
        clearTutorOnboardingPayload: (state) => {
            state.tutorOnboarding.tutorOnboardingPayload = TUTOR_ONBOARDING_INITIAL_STATE.tutorOnboarding.tutorOnboardingPayload
        },
        ///////////////////////////////////////////////////////////////////////////////////////////////
        // Interview slot reducers
        setInterviewSlotLoading: (state, { payload }) => {
            state.interviewSlot.isLoading = payload
        },
        setInterviewSlot: (state, { payload }) => {
            state.interviewSlot.interviewSlot = payload
            state.interviewSlot.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.interviewSlot.errorMsg
        },
        setInterviewSlotErrorMsg: (state, { payload }) => {
            state.interviewSlot.errorMsg = payload
            state.interviewSlot.interviewSlot = TUTOR_ONBOARDING_INITIAL_STATE.interviewSlot.interviewSlot
        },
        setClearInterviewSlot: (state, { payload }) => {
            state.interviewSlot = TUTOR_ONBOARDING_INITIAL_STATE.interviewSlot
        },

        // Add Interview time slot reducers
        setAddInterviewSlotLoading: (state, { payload }) => {
            state.addInterviewSlot.isLoading = payload
        },
        setAddInterviewSlot: (state, { payload }) => {
            state.addInterviewSlot.addInterviewSlot = payload
            state.addInterviewSlot.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.addInterviewSlot.errorMsg
        },
        setAddInterviewSlotPayload: (state, { payload }) => {
            state.addInterviewSlot.addInterviewSlotPayload = payload
            state.addInterviewSlot.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.addInterviewSlot.errorMsg
        },
        setAddInterviewSlotErrorMsg: (state, { payload }) => {
            state.addInterviewSlot.errorMsg = payload
            state.addInterviewSlot.addInterviewSlot = TUTOR_ONBOARDING_INITIAL_STATE.addInterviewSlot.addInterviewSlot
        },
        setClearAddInterviewPayload: (state) => {
            state.addInterviewSlot.addInterviewSlotPayload = TUTOR_ONBOARDING_INITIAL_STATE.addInterviewSlot.addInterviewSlotPayload
        },

        // reference list reducers
        setReferenceListLoading: (state, { payload }) => {
            state.referenceList.isLoading = payload
        },
        setReferenceList: (state, { payload }) => {
            state.referenceList.referenceList = payload
            state.referenceList.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.referenceList.errorMsg
        },
        setReferenceListErrorMsg: (state, { payload }) => {
            state.referenceList.errorMsg = payload
            state.referenceList.referenceList = TUTOR_ONBOARDING_INITIAL_STATE.referenceList.referenceList
        },
        setClearReferenceList: (state) => {
            state.referenceList = TUTOR_ONBOARDING_INITIAL_STATE.referenceList
        },

        // Add professional references reducers
        setAddReferenceLoading: (state, { payload }) => {
            state.addReference.isLoading = payload
        },
        setAddReference: (state, { payload }) => {
            state.addReference.addReference = payload
            state.addReference.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.addReference.errorMsg
        },
        setAddReferencePayload: (state, { payload }) => {
            state.addReference.addReferencePayload = payload
        },
        setAddReferenceErrorMsg: (state, { payload }) => {
            state.addReference.errorMsg = payload
            state.addReference.addReference = TUTOR_ONBOARDING_INITIAL_STATE.addReference.addReference
        },
        setClearAddReference: (state) => {
            state.addReference = TUTOR_ONBOARDING_INITIAL_STATE.addReference
        },

        // personal detail reducers
        setPersonalDetailLoading: (state, { payload }) => {
            state.personalDetail.isLoading = payload
        },
        setPersonalDetail: (state, { payload }) => {
            state.personalDetail.personalDetail = payload
            state.personalDetail.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.personalDetail.errorMsg
        },
        setPersonalDetailErrorMsg: (state, { payload }) => {
            state.personalDetail.errorMsg = payload
            state.personalDetail.personalDetail = TUTOR_ONBOARDING_INITIAL_STATE.personalDetail.personalDetail
        },
        setClearPersonalDetail: (state, { payload }) => {
            state.personalDetail = TUTOR_ONBOARDING_INITIAL_STATE.personalDetail
        },

        // Add Personal Detail reducers
        setAddPersonalDetailLoading: (state, { payload }) => {
            state.addPersonalDetail.isLoading = payload
        },
        setAddPersonalDetail: (state, { payload }) => {
            state.addPersonalDetail.addPersonalDetail = payload
            state.addPersonalDetail.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.addPersonalDetail.errorMsg
        },
        setAddPersonalDetailErrorMsg: (state, { payload }) => {
            state.addPersonalDetail.errorMsg = payload
            state.addPersonalDetail.addPersonalDetail = TUTOR_ONBOARDING_INITIAL_STATE.addPersonalDetail.addPersonalDetail
        },
        setAddPersonalDetailPayload: (state, { payload }) => {
            console.log(payload)
            state.addPersonalDetail.addPersonalDetailPayload = payload
        },
        setClearAddPersonalDetail: (state) => {
            state.addPersonalDetail = TUTOR_ONBOARDING_INITIAL_STATE.addPersonalDetail
        },

        // Document List reducers
        setDocumentListLoading: (state, { payload }) => {
            state.documentList.isLoading = payload
        },
        setDocumentList: (state, { payload }) => {
            state.documentList.documentList = payload
            state.documentList.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.documentList.errorMsg
        },
        setDocumentListErrorMsg: (state, { payload }) => {
            state.documentList.errorMsg = payload
            state.documentList.documentList = TUTOR_ONBOARDING_INITIAL_STATE.documentList.documentList
        },
        setClearDocumentList: (state, { payload }) => {
            state.documentList = TUTOR_ONBOARDING_INITIAL_STATE.documentList
        },

        // Add Document reducers
        setAddDocumentLoading: (state, { payload }) => {
            state.addDocument.isLoading = payload
        },
        setAddDocument: (state, { payload }) => {
            state.addDocument.addDocument = payload
            state.addDocument.errorMsg = TUTOR_ONBOARDING_INITIAL_STATE.addDocument.errorMsg
        },
        setAddDocumentPayload: (state, { payload }) => {
            state.addDocument.addDocumentPayload = payload
        },
        setAddDocumentErrorMsg: (state, { payload }) => {
            state.addDocument.errorMsg = payload
            state.addDocument.addDocument = TUTOR_ONBOARDING_INITIAL_STATE.addDocument.addDocument
        },
        setClearAddDocument: (state) => {
            state.addDocument = TUTOR_ONBOARDING_INITIAL_STATE.addDocument
        },

        // set category list for document
        setDocumentCategoryList: (state, {payload}) => {
            state.categoryList.categoryList = payload
        },
        setClearDocumentCategoryList: (state, {payload}) => {
            state.categoryList = TUTOR_ONBOARDING_INITIAL_STATE.categoryList
        }
    }
})

export const {
    setTutorOnboardingLoading,
    setTutorOnboarding,
    setTutorOnboardingErrorMsg,
    setTutorOnboardingPayload,
    clearTutorOnboardingPayload,

    setAddInterviewSlotPayload,
    setClearAddInterviewPayload,

    setReferenceListLoading,
    setReferenceList,
    setReferenceListErrorMsg,

    setAddReferenceLoading,
    setAddReference,
    setAddReferenceErrorMsg,
    setAddReferencePayload,
    setClearAddReference,

    setPersonalDetailLoading,
    setPersonalDetail,
    setPersonalDetailErrorMsg,
    setClearPersonalDetail,

    setAddPersonalDetailLoading,
    setAddPersonalDetail,
    setAddPersonalDetailPayload,
    setAddPersonalDetailErrorMsg,
    setClearAddPersonalDetail,

    setDocumentListLoading,
    setDocumentList,
    setDocumentListErrorMsg,
    setClearDocumentList,

    setAddDocumentLoading,
    setAddDocument,
    setAddDocumentPayload,
    setAddDocumentErrorMsg,
    setClearAddDocument,

    setDocumentCategoryList,
    setClearDocumentCategoryList
} = tutorOnboarding.actions

export default tutorOnboarding.reducer;