import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { data } from "pages/global/tutor/data";

import { PageContentStyle } from 'pages/global/tutor/TutorPage.style';

import FullPageLoader from 'components/loader/FullPageLoader';
import Footer from 'components/footer/Footer';

import Profile from "pages/global/tutor/Profile";
import AboutMe from "pages/global/tutor/AboutMe";
import Tabs from "pages/global/tutor/Tabs";
import Skills from "pages/global/tutor/Skills";
import Topics from "pages/global/tutor/Topics";
import Courses from "pages/global/tutor/Courses";
import Reviews from "pages/global/tutor/Reviews";
import MobileCard from "pages/global/tutor/MobileCard";

import { getTutorUserPublicProfileByUserId } from "redux/tutor/tutor.request";
import { getCourseTutorUserPublicListByUserId } from "redux/course/course.request"
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearTutorCourseList } from "redux/course/course.slice";
import { setClearTutorPublicProfile } from "redux/tutor/tutor.slice";

import { pagesInfo } from 'utils/pagesInfo';

const PROFILE = "PROFILE";
const TABS = "TABS";
const ABOUT_ME = "ABOUT_ME";
const SKILLS = "SKILLS";
const TOPICS = "TOPICS";
const CLASSES = "CLASSES";
const REVIEWS = "REVIEWS";


const TutorPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { tutorPublicProfile } = useAppState((s) => s.tutor)
  const { tutorCourseList } = useAppState((s) => s.course)
  const { tutorReviewList } = useAppState((s) => s.review)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()

  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR))
  }, [dispatcher, currentPageInfo])


  useEffect(() => {
    window.addEventListener("scroll", handleScrollListener);

    return () => {
      window.removeEventListener("scroll", handleScrollListener);
    };
  });

  const handleScrollListener = () => {
    setScrollValue(window.scrollY);
  };

  useEffect(() => {
    dispatcher(getTutorUserPublicProfileByUserId(Number(userId)))
    dispatcher(getCourseTutorUserPublicListByUserId(Number(userId), { page: 1, records: 5 }))

    return () => {
      dispatcher(setClearTutorPublicProfile())
      dispatcher(setClearTutorCourseList())
    }
  }, [])

  if (tutorPublicProfile?.isLoading) {
    return (
      <FullPageLoader isLoading={tutorPublicProfile?.isLoading || tutorReviewList?.isLoading || tutorCourseList?.isLoading} />
    )
  }

  if (tutorPublicProfile?.errorMsg) {
    return (
      <div className={"w-screen h-screen flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
        {tutorPublicProfile?.errorMsg}
      </div>
    )
  }

  return (
    <PageContentStyle>
      <div className={"hidden md:block px-5"}>
        <Profile
          tutor={tutorPublicProfile?.tutorPublicProfile}
          id={PROFILE}
          scrollValue={scrollValue}
        />
      </div>
      <div className="block md:hidden p-3">
        <MobileCard tutor={tutorPublicProfile?.tutorPublicProfile} id={PROFILE} />
      </div>
      <div className="sticky top-[4rem] z-10 px-3 lg:px-5 md:px-5">
        <Tabs
          data={data}
          id={TABS}
          containerIDs={[PROFILE, TABS, ABOUT_ME, SKILLS, TOPICS, CLASSES, REVIEWS]}
        />
      </div>
      <div className='px-3 md:block md:px-5'>
        <AboutMe tutor={tutorPublicProfile?.tutorPublicProfile} id={ABOUT_ME} />
      </div>
      <div className='px-3 md:block md:px-5'>
        <Skills tutor={tutorPublicProfile?.tutorPublicProfile} id={SKILLS} /></div>
      <div className='px-3 md:block md:px-5'>
        <Topics tutor={tutorPublicProfile?.tutorPublicProfile} id={TOPICS} />
      </div>
      <div className='px-3 md:block md:px-5'>
        <Courses id={CLASSES} />
      </div>
      <div className='px-3 md:block md:px-5'>
        <Reviews id={REVIEWS} />
      </div>
      <div className={"pt-3"}>
        <Footer />
      </div>
    </PageContentStyle>
  );
};

export default TutorPage;
