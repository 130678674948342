import { useEffect, useState } from "react";
import clsx from "clsx";

import { FiEdit } from "react-icons/fi";
import { MdAdd } from "react-icons/md";
import { MdOutlineDeleteOutline } from 'react-icons/md';

import { OptionSelector } from "components/common-components/Select";

import { companyEditBtn } from "pages/auth/editCompany/data";
import { logoOptions } from "pages/auth/editCompany/data";
import SaveAndCancelBtn from "pages/auth/editCompany/components/saveAndCancelBtn/SaveAndCancelBtn";

import {
    getImportantLinksByCompanyId,
    createImportantLinks,
    updateImportantLinksByLinkId,
    deleteImportantLinkByLinkId
} from "redux/company/company.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setImportantLinksDetailPayload, setClearImportantLinksDetailPayload, setClearImportantLinksList } from "redux/company/company.slice";

const EditImportantLinks = ({ editBtnConst, setEditBtnConst }) => {
    const {companyPublicProfile, importantLinksList, importantLinksDetailPayload } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    const [isAddNewLinkContainer, setIsAddNewLinkContainer] = useState(false)
    const [isProFeatureEnabled, setIsProFeatureEnabled] = useState(true)

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.companyId) {
            dispatcher(getImportantLinksByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId))
        }

        return () => {
            dispatcher(setClearImportantLinksList())
        }
    }, [companyPublicProfile?.companyPublicProfile?.companyId])

    const handleAddLinks = () => {
        dispatcher(setClearImportantLinksDetailPayload())
        setIsAddNewLinkContainer(!isAddNewLinkContainer)
    }

    const handleEditLink = (link) => {
        dispatcher(setImportantLinksDetailPayload(link))
        setIsAddNewLinkContainer(true)
    }

    const handleDeleteLink = (link) => {
        dispatcher(deleteImportantLinkByLinkId(link?.linkId))
    }

    const onSubmitLink = () => {
        if (importantLinksDetailPayload?.importantLinksDetailPayload?.name?.length === 0 || importantLinksDetailPayload?.importantLinksDetailPayload?.link?.length === 0) {
            alert("Url and Link name are required field!")
            return;
        }
        if (!importantLinksDetailPayload?.importantLinksDetailPayload?.linkId) {
            dispatcher(createImportantLinks({
                ...importantLinksDetailPayload?.importantLinksDetailPayload,
                linkId: Math.floor((Math.random() * 10) + 1)
            }))
        }
        if (importantLinksDetailPayload?.importantLinksDetailPayload?.linkId) {
            dispatcher(updateImportantLinksByLinkId(importantLinksDetailPayload?.importantLinksDetailPayload))
        }
        setIsAddNewLinkContainer(false)
    }

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"LINKS"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"p-5 bg-white space-y-3"}>
                    {isProFeatureEnabled &&
                        <div className={clsx(
                            "w-8 flex item-center justify-center px-1 py-0.5 rounded-md cursor-pointer",
                            "font-buttons font-normal text-sm text-text-50 bg-red-600",
                            "hover:bg-red-700"
                        )}
                            onClick={() => alert("This is pro feature! buy plan to unlock this feature")}
                        >
                            <span>{"Pro"}</span>
                        </div>
                    }
                    <div className="flex justify-start items-center gap-5">
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"Important Links"}
                        </span>
                        {(!editBtnConst) &&
                            <FiEdit
                                className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                onClick={() => {
                                    if (isProFeatureEnabled) {
                                        alert("This is a pro feature! buy plan to use this feature")
                                        return;
                                    }
                                    setEditBtnConst(companyEditBtn.importantLinks.value)
                                }}
                            />
                        }
                        {editBtnConst === companyEditBtn.importantLinks.value &&
                            <div className={"flex justify-center items-center gap-5"}>
                                <div
                                    className={clsx(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-50 bg-primary-main",
                                        "hover:bg-primary-dark"
                                    )}
                                    onClick={handleAddLinks}
                                >
                                    <MdAdd className={"text-xl"} />
                                    <span className={"whitespace-nowrap"}>
                                        {"Add Link"}
                                    </span>
                                </div>
                                <div
                                    className={clsx(
                                        "flex item-center justify-center px-2 py-0.5 rounded-md cursor-pointer",
                                        "font-buttons font-normal text-base text-text-800 bg-background-darkLight",
                                        "hover:bg-background-medium hover:text-text-900"
                                    )}
                                    onClick={() => {
                                        setIsAddNewLinkContainer(false)
                                        setEditBtnConst(null)
                                    }}
                                >
                                    <span className={""}>
                                        {"Close"}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={"grid grid-cols-1 md:grid-cols-2 gap-5 p-5"}>
                        {importantLinksList?.importantLinksList?.length > 0 && 
                        importantLinksList?.importantLinksList?.map((link, index) => (
                            <div key={index} className={"relative"}>
                                <a href={link?.link} target={"_blank"}
                                    className={clsx(
                                        "relative col-span-1 flex justify-start items-center gap-5",
                                        "border border-text-500 p-3 rounded",
                                        "group hover:bg-primary-light hover:border-primary-main cursor-pointer"
                                    )}>
                                    <div className={"w-5 h-5 overflow-hidden"}>
                                        <img
                                            src={link?.logoUrl}
                                            alt={"brand-logo"}
                                            className={"w-full h-full object-cover"}
                                        />
                                    </div>
                                    <span className={"font-bodyPri font-normal text-primary-main text-sm cursor-pointer group-hover:underline"}>
                                        {link?.name}
                                    </span>
                                </a>
                                {editBtnConst === companyEditBtn.importantLinks.value &&
                                    <div className={"absolute top-2.5 right-6 flex items-center justify-center gap-1.5"}>
                                        <FiEdit className={"text-lg text-text-500 hover:text-text-700 cursor-pointer"}
                                            onClick={() => handleEditLink(link)}
                                        />
                                        <MdOutlineDeleteOutline className={"text-lg text-red-500 hover:text-red-700 cursor-pointer"}
                                            onClick={() => handleDeleteLink(link)}
                                        />
                                    </div>
                                }
                            </div>
                        ))}
                        {importantLinksList?.importantLinksList?.length === 0 &&
                            <div className="flex justify-start items-center font-bodyPri font-normal text-text-800 text-base tracking-wide">
                                {"No links added yet! "}
                                <span 
                                className={" text-primary-main cursor-pointer hover:underline"}
                                    onClick={() => {
                                        if (isProFeatureEnabled) {
                                            alert("This is a pro feature! buy plan to use this feature")
                                            return;
                                        }
                                        setEditBtnConst(companyEditBtn.importantLinks.value)
                                    }}
                                >
                                    {"Add Links"}
                                </span>
                            </div>
                        }
                    </div>
                    {isAddNewLinkContainer &&
                        <div className={"flex flex-col justify-center items-start px-5 gap-5"}>
                            <div className={"w-full flex flex-col justify-center items-start gap-3"}>
                                <div className={"w-1/2"}>
                                    <OptionSelector
                                        options={Object.values(logoOptions)}
                                        value={importantLinksDetailPayload?.importantLinksDetailPayload?.logoUrl}
                                        onChange={(option) => dispatcher(setImportantLinksDetailPayload({
                                            ...importantLinksDetailPayload?.importantLinksDetailPayload,
                                            logoUrl: option.value
                                        }))}
                                    />
                                </div>
                                <input
                                    type={"text"}
                                    placeholder={"Enter link Name"}
                                    value={importantLinksDetailPayload?.importantLinksDetailPayload?.name}
                                    className={clsx(
                                        "w-1/2 px-2 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                        "font-bodyPri font-normal text-text-800 text-base",
                                        "placeholder:text-text-500 placeholder:text-sm"
                                    )}
                                    onChange={(event) => dispatcher(setImportantLinksDetailPayload({
                                        ...importantLinksDetailPayload?.importantLinksDetailPayload,
                                        name: event.target.value
                                    }))}
                                />
                                <input
                                    type={"text"}
                                    placeholder={"Enter Url"}
                                    value={importantLinksDetailPayload?.importantLinksDetailPayload?.link}
                                    className={clsx(
                                        "w-1/2 px-2 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                        "font-bodyPri font-normal text-text-800 text-base",
                                        "placeholder:text-text-500 placeholder:text-sm"
                                    )}
                                    onChange={(event) => dispatcher(setImportantLinksDetailPayload({
                                        ...importantLinksDetailPayload?.importantLinksDetailPayload,
                                        link: event.target.value
                                    }))}
                                />
                            </div>
                            <SaveAndCancelBtn
                                onCancel={() => setIsAddNewLinkContainer(false)}
                                onSave={() => onSubmitLink()}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default EditImportantLinks;