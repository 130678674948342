import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import tw, { styled } from 'twin.macro';

export const Container = tw(Section)`
    pt-3 bg-white grid grid-cols-12 w-full
`;

export const Content = tw(SectionContent)`
    grid col-start-2 col-span-10 lg:(col-start-3 col-span-8) grid-cols-1 md:grid-cols-3 gap-16
`;

export const FeaturedItemContainer = styled.div`
    ${tw`flex flex-col justify-center items-center gap-2 cursor-pointer`}
    img {
        ${tw`
            mb-5 max-w-[8rem] w-full bg-cover bg-center
        `}
    }
`;

export const TitleText = tw.div`
    font-subHeading font-semibold text-xl text-text-900 text-center
`;

export const Description = tw.div`
    font-bodyPri font-medium text-base text-text-700 text-center w-4/5
`;