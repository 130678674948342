import clsx from 'clsx';
import { dayjs, timeZone } from 'utils/dateTime.utils';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddBookingPayload, setChooseTimeSlotPayload } from 'redux/booking/booking.slice';
import { weeklyTimeSlot } from 'redux/local/local.const';
import { modalConst } from 'redux/local/local.const';
import { chooseTimeSlotAction } from 'redux/booking/booking.const';

import { getIsAvailable } from 'utils/availability.utils'


const TimeSlot = ({ dailyAvail, timeSlot }) => {
    const { modal } = useAppState((s) => s.local)
    const { addBooking } = useAppState((s) => s.booking)
    const { addBookingPayload, chooseTimeSlotPayload } = addBooking
    const { userEventList } = useAppState((s) => s.event)

    const dispatcher = useAppDispatcher()

    const availDateTime = {
        start: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format(),
        end: dayjs(dailyAvail.date + " " + timeSlot.endTime + "+00:00").utc().format()
    }

    const isAvailable = getIsAvailable(availDateTime, userEventList?.userEventList)

    const handleSelect = () => {
        if (isAvailable) {
            dispatcher(setChooseTimeSlotPayload({
                ...chooseTimeSlotPayload,
                startDate: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('YYYY-MM-DD'),
                endDate: dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('YYYY-MM-DD'),
                weeklyTimeSlot: {
                    ...weeklyTimeSlot,
                    [dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('dddd')]: [{
                        startTime: timeSlot.startTime,
                        endTime: timeSlot.endTime,
                    }]
                }
            }))
        }
    }

    const handleConfirm = () => {
        if (chooseTimeSlotPayload.chooseTimeSlotAction === chooseTimeSlotAction.BOOKING.value) {
            dispatcher(setAddBookingPayload({
                ...addBookingPayload,
                sessionDuration: chooseTimeSlotPayload.sessionDuration,
                noOfSessions: chooseTimeSlotPayload.noOfSessions,
                startDate: chooseTimeSlotPayload.startDate,
                endDate: chooseTimeSlotPayload.endDate,
                weeklyTimeSlot: chooseTimeSlotPayload.weeklyTimeSlot
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: false,
                [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true
            }))
        }
    }

    const day = dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").utc().format('dddd')
    const isSelected = dayjs(dailyAvail.date).utc().format("YYYY-MM-DD") === dayjs(chooseTimeSlotPayload?.startDate).utc().format("YYYY-MM-DD") &&
        timeSlot.startTime === chooseTimeSlotPayload?.weeklyTimeSlot[day][0].startTime &&
        timeSlot.endTime === chooseTimeSlotPayload?.weeklyTimeSlot[day][0].endTime

    return (
        <div className={"flex flex-row items-center justify-center"}>
            <span onClick={handleSelect} className={clsx(
                "px-2 py-0.5 border-2 cursor-pointer border-primary-main",
                "font-bodyPri font-medium text-sm text-primary-main rounded",
                isSelected && "!text-text-50 bg-primary-main rounded-r-none",
                !isAvailable && "text-secondary-light border-secondary-light"
            )}>
                {dayjs(dailyAvail.date + " " + timeSlot.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
            </span>
            {isSelected &&
                <span onClick={handleConfirm} className={clsx(
                    "px-2 py-0.5 border-2 cursor-pointer border-secondary-main rounded-r",
                    "font-bodyPri font-medium text-sm",
                    "transition ease-in-out delay-150",
                    isSelected
                        ? "bg-secondary-main text-text-50"
                        : "hidden"
                )}>
                    {"Confirm"}
                </span>
            }
        </div>
    )
}

export default TimeSlot