import { useState } from 'react'
import clsx from 'clsx'

import { OptionSelector } from 'components/common-components/Select'

import { onBoardingStatus } from 'pages/auth/tutorOnboard/TutorOnboardData'

import { updateDocument } from "redux/tutorOnboarding/tutorOnboarding.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore'
import { setModal } from 'redux/local/local.slice'
import { modalConst } from 'redux/local/local.const';
import { setAddDocumentPayload } from 'redux/tutorOnboarding/tutorOnboarding.slice'

const UploadDocumentModal = () => {
    const { modal } = useAppState(s => s.local)
    const { addDocument, categoryList } = useAppState((s) => s.tutorOnboarding)
    const [fileError, setFileError] = useState(false)
    const dispatcher = useAppDispatcher()

    const onHandleFileUpload = (event) => {
        let selectedFile = event.target.files[0];
        const url = URL.createObjectURL(selectedFile)
        dispatcher(setAddDocumentPayload({
            ...addDocument?.addDocumentPayload,
            file: selectedFile.name,
            fileUrl: url
        }))
    }

    const onSubmitDocument = (event) => {
        event.preventDefault()
        if (!addDocument?.addDocumentPayload?.documentId) {
            dispatcher(setAddDocumentPayload({
                ...addDocument?.addDocumentPayload,
                isDocumentSubmitted: true,
                documentStatus: onBoardingStatus.SUBMITTED.value,
                documentId: Math.floor((Math.random() * 10) + 1),
                fileCategory: addDocument?.addDocumentPayload?.fileCategory ? addDocument?.addDocumentPayload?.fileCategory : Object.values(categoryList?.categoryList?.categoryListItems)[0]
            }))
            if (categoryList?.categoryList?.resumeTask) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
                    [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: true
                }))
                alert("Resume Uploaded Successfully")
            }
            if (!categoryList?.categoryList?.resumeTask) {
                dispatcher(setModal({
                    ...modal,
                    [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
                }))
                alert("Document Uploaded Successfully")
            }
        }
        if (addDocument?.addDocumentPayload?.documentId && !categoryList?.categoryList?.resumeTask) {
            dispatcher(updateDocument())
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
            }))
            alert("document updated successfully")
        }
        if (addDocument?.addDocumentPayload?.documentId && categoryList?.categoryList?.resumeTask) {
            dispatcher(updateDocument())
            dispatcher(setModal({
                ...modal,
                [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: false,
                [modalConst.PERSONAL_DETAIL_MODAL.stateKey]: true
            }))
            alert("Resume Updated Successfully")
        }
    }

    return (
        <form onSubmit={onSubmitDocument} className="space-y-5">
            <div className={"min-h-[24rem] block w-full border border-text-400"}>
                <div className='grid grid-cols-12 gap-5 p-3'>
                    <div className='col-start-0 col-span-6 space-y-2'>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Category"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <OptionSelector
                            options={Object.values(categoryList?.categoryList?.categoryListItems)}
                            placeholder={"Please pick a type"}
                            value={addDocument?.addDocumentPayload?.fileCategory?.value}
                            onChange={(option) => dispatcher(setAddDocumentPayload({
                                ...addDocument?.addDocumentPayload,
                                fileCategory: option
                            }))}
                            required={true}
                        />
                    </div>
                    <div className={"col-start-0 col-span-6 space-y-2"}>
                        <label className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"File Name"}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </label>
                        <input
                            type={"text"}
                            rows={2}
                            className={clsx(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            minLength={5}
                            maxLength={50}
                            placeholder={"tell us something about the document"}
                            value={addDocument?.addDocumentPayload?.fileName}
                            onChange={(e) => dispatcher(setAddDocumentPayload({
                                ...addDocument?.addDocumentPayload,
                                fileName: e.target.value
                            }))}
                            required={true}
                        />
                    </div>
                    <div className='col-start-0 col-span-6 space-y-2'>
                        <span className='font-bodyPri font-normal text-text-900 text-sm whitespace-nowrap space-x-1'>
                            <span>{"Select File"}</span>
                            <span>{"(maximum 3 MB each)"}</span>
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <label className='relative inline-flex mx-auto shadow-sm'>
                            <input
                                type="file"
                                style={{ display: "none" }}
                                accept=".doc, .docx,.pdf, application/pdf, application/msword"
                                onChange={onHandleFileUpload}
                            />
                            <input
                                type={"text"}
                                className={clsx(
                                    "w-full px-3 py-1 border-2 border-divider-medium rounded-l-md focus:outline-none focus:border-2 focus:border-text-400",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                placeholder={"No File Chosen"}
                                required
                                value={addDocument?.addDocumentPayload?.file}
                            />
                            <span className='p-2 rounded-r-md bg-primary-light border-2 border-primary-light hover:(text-text-50 border-secondary-dark) cursor-pointer font-buttons font-normal text-sm text-primary-dark whitespace-nowrap'
                            >
                                {"Choose a file.."}
                            </span>
                        </label>
                        {fileError &&
                            <span className='font-bodyPri font-normal text-text-900 text-sm tracking-wide'>
                                {"No File Choosen"}
                            </span>
                        }
                    </div>
                </div>
            </div>
            <div className={"flex justify-end"}>
                <button
                    type={"submit"}
                    className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    )}>
                    {"Submit"}
                </button>
            </div>
        </form>
    )
}

export default UploadDocumentModal;