import { useAppState } from 'hooks/useStore'
import { useEffect, useState } from 'react'

const PreviewDocumentModal = () => {
    const { tutorOnboarding } = useAppState((s) => s.tutorOnboarding)
    const [fileUrl, setFileUrl] = useState(null)

    useEffect(() => {
        setFileUrl(tutorOnboarding?.tutorOnboarding?.imgUrl)
    }, [])
    return (
        <div className='space-y-5 overflow-x-hidden select-none'>
            {fileUrl !== null ?
                <div className='min-h-[24rem]'>
                    <iframe src={fileUrl} width={"800"} height={"600"}></iframe>
                </div>
                : <span className='w-full h-full flex justify-center items-center font-bodyPri font-large text-text-900 tracking-wide'>
                    {"No Document Found to Preview!"}
                </span>
            }
        </div>
    )
}

export default PreviewDocumentModal