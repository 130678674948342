import clsx from 'clsx'
import { MdGroups } from 'react-icons/md'
import { IoMdPeople } from 'react-icons/io'

import { courseType } from "redux/course/course.const";

const OfferingCard = ({ offering }) => {

    return (
        <div
            className={clsx(
                "flex flex-col content-between justify-between bg-white rounded-lg shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]",
                "w-52"
            )}
        >
            <div className='space-y-3'>
                <div className='rounded-t-lg overflow-hidden'>
                    <img
                        src={offering?.thumbnailPicUrl}
                        alt=""
                        className="object-contain w-full h-32 rounded-t-lg"
                    />
                </div>
                <div className='px-3 pb-3 flex flex-col justify-start gap-3'>
                    <div className='flex justify-between gap-2'>
                        {offering?.courseType === courseType.GROUP.value &&
                            <MdGroups className='text-text-700 text-xl' />
                        }
                        {offering?.courseType === courseType.ONE_ON_ONE.value &&
                            <IoMdPeople className='text-text-700 text-xl' />
                        }
                        {Object.keys(courseType).map((item, index) => courseType[item].value === offering?.courseType &&
                            <span
                                key={index}
                                className='px-2 py-0.5 flex items-center justify-center rounded-full bg-text-300 font-normal font-bodyPri text-sm tracking-wide whitespace-nowrap'
                            >
                                {courseType[item]?.label}
                            </span>
                        )}
                    </div>
                    <span className='text-text-900 font-medium text-base font-bodyPri tracking-wide truncate'>
                        {offering?.courseTitle}
                    </span>
                    <span className='text-text-900 font-normal font-bodyPri text-base tracking-wide'>{offering?.category}</span>
                </div>
            </div>
        </div>
    )
}

export default OfferingCard