export type BillingInformation = {
  name: string;
  company: string;
  billingAddress: string;
  GST: string;
  cardInformation: {
    cardNumber: string;
    expiryDate: string;
  };
};

export type ReferalInformation = {
  url: string;
  code: string;
  shareContent?: string;
};

export type Subscription = {
  id: number;
  planName: string;
  amount: string;
  nextPayment: string;
  status: string;
  paymentInvoice: string; // this is be invoice ID, can be used for sending it to backend to obtain pdf
  paymentMethod: string;
};

export type SubscriptionFAQs = {
  id: number;
  question: string;
  answer: string;
};

export type SubscriptionPageState = {
  billingInformation: BillingInformation;
  referal: ReferalInformation;
  subscriptions: Subscription[];
  FAQs: SubscriptionFAQs[];
  _meta: {
    showPlansModal: boolean;
    showInvoiceModal: {
      invoiceId: string;
      showModal: boolean;
    };
  };
};

/*
  + To Add new Plan, add its name to 'AllPlans'
  + Do as directed by the compiler, i.e. add plan information in MockPlanInformation

  + To Add feature to the plan, follow the existing structure of SubscriptionPlanFeature

*/

// Append new plan name to array below, and proceed to add feature information
export const AllPlans = ["Free", "Basic", "Plus", "Standard"] as const;
export type Plan = typeof AllPlans[number];

type TPlanInformation = {
  [key in Plan]: {
    name: string;
    id: number;
    pricePerMonth: number;
    description: string;
  };
};

export type FeatureAplicability = {
  [key in Plan]: {
    isApplicable: boolean;
    value?: string;
  };
};

export type SubscriptionPlanFeature = {
  feature: string;
  applicablity: FeatureAplicability;
};

export type SubscriptionPlans = {
  plans: TPlanInformation;
  features: SubscriptionPlanFeature[];
};

export const MockPlanInformation: SubscriptionPlans = {
  plans: {
    Free: {
      id: 2,
      name: "Free",
      pricePerMonth: 0,
      description: "Free plan",
    },
    Basic: {
      id: 1,
      name: "Basic",
      pricePerMonth: 5.45,
      description: "Basic plan",
    },
    Plus: {
      id: 3,
      name: "Plus",
      pricePerMonth: 10.95,
      description: "Plus plan",
    },
    Standard: {
      id: 4,
      name: "Standard",
      pricePerMonth: 27.45,
      description: "Standard plan",
    },
  },
  features: [
    {
      feature: "Fixed Priced Project Fee",
      applicablity: {
        Free: {
          isApplicable: false,
          value: "10% or $5.50 AUD*",
        },
        Basic: {
          isApplicable: true,
          value: "10% or $5.50 AUD*",
        },
        Plus: {
          isApplicable: true,
          value: "10% or $5.50 AUD*",
        },
        Standard: {
          isApplicable: true,
          value: "5% or $4.40 AUD*",
        },
      },
    },
    {
      feature: "Express Withdrawals",
      applicablity: {
        Basic: {
          isApplicable: false,
        },
        Free: {
          isApplicable: false,
        },
        Plus: {
          isApplicable: true,
        },
        Standard: {
          isApplicable: true,
        },
      },
    },
  ],
};
