import format from "string-format";

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/offering/offering.const";

class offeringListService {
    getOfferingList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.OFFERING_LIST,
            { params: query }
        )

        return response;
    }

    getOfferingByCourseId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.OFFERING_COURSE_BY_COURSE_ID, params),
            { params: query }
        )

        return response
    }

    createOffering = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.ADD_OFFERING,
            body
        )
        return response;
    }

    updateOfferingByCourseId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.UPDATE_OFFERING_BY_COURSE_ID, params),
            body
        )

        return response;
    }
}
export default new offeringListService()