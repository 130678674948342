import clsx from "clsx";
import * as React from "react";

type Props = {
  className?: string;
  icon?: React.ReactNode;
  placeholder?: string;
  placeholderClassName?: string;
  onClick?: () => void;
};

export function IconWithTextButton({
  icon,
  className: styles,
  placeholder,
  placeholderClassName,
  onClick,
}: Props) {
  return (
    <button
      className={clsx(
        "inline-flex items-center px-3 py-2 shadow-sm",
        "text-sm leading-4 font-medium rounded-md",
        styles
      )}
      onClick={onClick}
    >
      <span className="-ml-0.5 mr-1 h-4 w-4 ">{icon}</span>

      {placeholder}
    </button>
  );
}

type LargeButtonProps = {
  className?: string;
  name: string;
  style?: keyof typeof ButtonStyles;
  size?: keyof typeof Size;
  onClick?: () => void;
};

const ButtonStyles = {
  primary:
    "text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 border border-transparent ",
  secondary:
    "text-white bg-gray-600 hover:bg-gray-900 focus:ring-blue-500 border border-transparent ",
  //   tertiary:
  // "text-white bg-gray-800 hover:bg-gray-900 focus:ring-blue-500 border border-transparent ",
  outline:
    "text-neutral-700 bg-white hover:shadow-md focus:ring-blue-500 border",
};

const Size = {
  small: "px-2 py-1",
  medium: "px-3 py-2",
  large: "px-6 py-3",
};

export const Button: React.FC<LargeButtonProps> = ({
  name,
  className,
  style = "secondary",
  size = "large",
  onClick,
}) => {
  return (
    <button
      type="button"
      className={clsx(
        "text-base font-medium whitespace-nowrap",
        "rounded-md shadow-sm",
        "focus:outline-none focus:ring-2 focus:ring-offset-2 ",
        "sm:flex-shrink-0 sm:inline-flex sm:items-center sm:justify-center",
        ButtonStyles[style],
        Size[size],
        className
      )}
      onClick={onClick}
    >
      {name}
    </button>
  );
};
