import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FullPageLoader from 'components/loader/FullPageLoader';
import NavBar from 'components/navbar/NavBar';
import ModalContainer from 'components/modalContainer/ModalContainer';

import { PageStyle, PageContainerStyle } from './Router.style';

import { useUser } from 'pages/auth/message/contexts/userContext';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearLogout } from 'redux/auth/auth.slice';
import { setFBUserLogin, setFBUserLogout } from 'redux/firebase/firebase.request';

import { pagesInfo } from 'utils/pagesInfo';


const LayoutRouter = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((state) => state.user)
    const { logout } = useAppState((s) => s.auth)
    const pageName = currentPageInfo.pageName;

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()
    const userContext = useUser()

    useEffect(() => {
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [location.pathname]);

    useEffect(() => {
        if (user?.user) {
            setFBUserLogin(user?.user?.userId, userContext)
        }
    }, [user?.user])

    useEffect(() => {
        if (logout?.logout) {
            dispatcher(setClearLogout())
            setFBUserLogout(userContext)
            const from = location.state?.from?.pathname || pagesInfo.LOG_IN.pagePath;
            navigate(from, { replace: true });
        }
    }, [logout?.logout])

    return (
        <PageStyle pageName={pageName}>
            <NavBar />
            <ToastContainer
                position={"bottom-right"}
                hideProgressBar={false}
                autoClose={2000}
                closeOnClick={true}
                pauseOnFocusLoss={true}
                pauseOnHover={true}
                draggable={true}
            />
            <ModalContainer />
            <PageContainerStyle pageName={pageName}>
                {logout?.isLoading
                    ? <FullPageLoader isLoading={logout?.isLoading} />
                    : <Outlet />
                }
            </PageContainerStyle>
        </PageStyle>
    )
}

export default LayoutRouter