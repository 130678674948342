import React, { useEffect, useState } from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Component imports
import ChatViewHeader from "../ChatViewHeader/ChatViewHeader";
import ChatViewMessageList from "../ChatViewMessageList/ChatViewMessageList";
import ChatViewSendMessage from "../ChatViewSendMessage/ChatViewSendMessage";
import CourseMenu from "../CourseMenu/CourseMenu";
import ChatUserMenu from "../ChatUserMenu/ChatUserMenu";
import ModalBackground from "../ModalBackground/ModalBackground";
import ReportUser from "../ReportUser/ReportUser";
import RequestPayment from "../RequestPayment/RequestPayment";
import MakePayment from "../MakePayment/MakePayment";
import JitsiMeetComponent from "../JitsiMeetComponent/JitsiMeetComponent";

//Style imports
import { StyledChatView } from "./ChatView.styles";
import ScheduleMeeting from "../ScheduleMeeting/ScheduleMeeting";
import ShareCourse from "../ShareCourse/ShareCourse";
import GroupDetails from "../GroupDetails/GroupDetails";

export default function ChatView() {
  const { payment, meeting, dispatch, selectedChat } = useChat();
  const [showCourseMenu, setShowCourseMenu] = useState(false);
  const [showChatUserMenu, setShowChatUserMenu] = useState(false);
  const [showReportUserModal, setShowReportUserModal] = useState(false);
  const [showPaymentRequest, setShowPaymentRequest] = useState(false);
  const [scheduleMeeting, setScheduleMeeting] = useState(false);
  const [showShareCourse, setShareCourse] = useState(false);
  const [showGroupDetails, setShowGroupDetails] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);

  useEffect(() => {
    if (!selectedChat.id) return;
    localStorage.setItem("lastChat", selectedChat.id);
  }, [selectedChat]);

  return (
    <>
      <StyledChatView>
        <ChatViewHeader
          setShowChatUserMenu={setShowChatUserMenu}
          showChatUserMenu={showChatUserMenu}
          setScheduleMeeting={setScheduleMeeting}
          setShowGroupDetails={setShowGroupDetails}
        />
        <ChatViewMessageList />
        <ChatViewSendMessage
          setShowCourseMenu={setShowCourseMenu}
          showCourseMenu={showCourseMenu}
        />
        {showCourseMenu && (
          <CourseMenu
            setShowPaymentRequest={setShowPaymentRequest}
            setShowCourseMenu={setShowCourseMenu}
            setShareCourse={setShareCourse}
          />
        )}
        {showChatUserMenu && (
          <>
            <ModalBackground setModalState={setShowChatUserMenu} />
            <ChatUserMenu
              setShowReportUserModal={setShowReportUserModal}
              setShowChatUserMenu={setShowChatUserMenu}
            />
          </>
        )}
        {showGroupDetails && (
          <GroupDetails setShowGroupDetails={setShowGroupDetails} />
        )}
      </StyledChatView>
      {scheduleMeeting && (
        <>
          <ScheduleMeeting setScheduleMeeting={setScheduleMeeting} />
          <ModalBackground
            setModalState={setScheduleMeeting}
            backgroundColor={"#000000"}
          />
        </>
      )}
      {showReportUserModal && (
        <>
          <ModalBackground
            setModalState={setShowReportUserModal}
            backgroundColor={"#000000"}
          />
          <ReportUser setShowReportUserModal={setShowReportUserModal} />
        </>
      )}
      {showPaymentRequest && (
        <>
          <ModalBackground
            setModalState={setShowPaymentRequest}
            backgroundColor={"#000000"}
          />
          <RequestPayment setShowPaymentRequest={setShowPaymentRequest} />
        </>
      )}
      {showShareCourse && (
        <>
          <ModalBackground
            setModalState={setShareCourse}
            backgroundColor={"#000000"}
          />
          <ShareCourse setShareCourse={setShareCourse} />
        </>
      )}
      {showCourseMenu && (
        <ModalBackground
          setModalState={setShowCourseMenu}
          backgroundColor={"#ffffff"}
        />
      )}
      {payment && (
        <>
          <MakePayment
            loading={paymentLoading}
            setLoading={setPaymentLoading}
            dispatch={dispatch}
          />
          <ModalBackground backgroundColor="#000000" loading={paymentLoading} />
        </>
      )}
      {meeting && <JitsiMeetComponent />}
    </>
  );
}
