import { createSlice } from "@reduxjs/toolkit";
import { PAYMENT_INITIAL_STATE } from "redux/payment/payment.initialState";


const payment = createSlice({
    name: "payment",
    initialState: PAYMENT_INITIAL_STATE,
    reducers: {
        // Actions for make payment
        setMakePaymentLoading: (state, { payload }) => {
            state.makePayment.isLoading = payload
        },
        setMakePayment: (state, { payload }) => {
            state.makePayment.makePayment = payload
        },
        setMakePaymentErrorMsg: (state, { payload }) => {
            state.makePayment.errorMsg = payload
        },
        setClearMakePayment: (state) => {
            state.makePayment.isLoading = PAYMENT_INITIAL_STATE.makePayment.isLoading
            state.makePayment.makePayment = PAYMENT_INITIAL_STATE.makePayment.makePayment
            state.makePayment.errorMsg = PAYMENT_INITIAL_STATE.makePayment.errorMsg
        },
        setMakePaymentPayload: (state, { payload }) => {
            state.makePayment.makePaymentPayload = payload
        },
        setClearMakePaymentPayload: (state) => {
            state.makePayment.makePaymentPayload = PAYMENT_INITIAL_STATE.makePayment.makePaymentPayload
        }
    }
})

export const {
    setMakePaymentLoading,
    setMakePayment,
    setMakePaymentErrorMsg,
    setClearMakePayment,
    setMakePaymentPayload,
    setClearMakePaymentPayload,
} = payment.actions

export default payment.reducer