import { useState } from 'react';
import clsx from 'clsx';

import { IoIosArrowForward } from 'react-icons/io';

import { useAppState } from 'hooks/useStore';
import { eventUserType } from 'redux/event/event.const';
import { userRoles } from 'redux/auth/auth.const';


const UserProfileInfo = ({ userProfileInfo }) => {
    return (
        <div className={"flex items-center justify-between gap-3 py-2 px-5 rounded-md hover:bg-gray-50 cursor-pointer"}>
            <div className={"flex items-center justify-start gap-3 "}>
                <img src={userProfileInfo?.profilePicUrl} alt="" className={"w-10 h-10 rounded-full"} />
                <div className={"flex flex-col items-start justify-evenly h-full"}>
                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                        {userProfileInfo?.firstName + " " + userProfileInfo?.lastName.charAt(0)}
                    </span>
                    <span className={"font-bodyPri font-normal text-sm text-text-700"}>
                        {userRoles[userProfileInfo.role.toUpperCase()].label}
                    </span>
                </div>
            </div>
            <div className={"flex flex-col items-end justify-start h-full"}>
                <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                    {eventUserType[userProfileInfo.eventUserType.toUpperCase()].label}
                </span>
            </div>
        </div>
    )
}


const EventDetailParticipants = () => {
    const { eventDetail } = useAppState(s => s.event)

    const [isShow, setIsShow] = useState(false)

    return eventDetail?.eventDetail?.participants ? (
        <div className={"space-y-1"}>
            <div onClick={() => setIsShow(!isShow)}
                className={"flex items-center justify-between gap-2 px-3 py-1 cursor-pointer rounded-lg hover:bg-background-light"}
            >
                <span className={"font-bodyPri font-semibold text-md text-text-800"}>
                    {"Participants"}
                </span>
                <IoIosArrowForward className={clsx(
                    "font-bold text-2xl text-text-800 transition ease-in-out delay-50 duration-100",
                    isShow
                        ? "rotate-90"
                        : "rotate-0"
                )} />
            </div>
            <div className={clsx(
                isShow
                    ? "flex flex-col transition ease-in-out delay-200 duration-300"
                    : "hidden"
            )}>
                {eventDetail?.eventDetail?.participants?.map((userProfileInfo, index) => (
                    <UserProfileInfo key={index} userProfileInfo={userProfileInfo} />
                ))}
            </div>
        </div>
    ) : null
}

export default EventDetailParticipants