import { useState } from "react";
import clsx from "clsx";

import { FaCheck } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";

import TimeRangeSelection from './TimeRangeSelection';

import { IconWithTextButton } from "components/common-components/Buttons";

import { dayjs, utcWeekDays, timeZone } from 'utils/dateTime.utils';


const TimeRange = ({ day, timeSlot, updateTimeSlot, deleteTimeSlot }) => {

    const [isEditEnabled, setIsEditEnable] = useState(false);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    const handleOnDelete = () => {
        deleteTimeSlot(timeSlot)
    }

    const handleOnUpdate = () => {
        updateTimeSlot(timeSlot, selectedTimeSlot)
        setSelectedTimeSlot(null)
        setIsEditEnable(false)
    }

    const handleOpenEdit = () => {
        setSelectedTimeSlot({
            startTime: timeSlot.startTime,
            endTime: timeSlot.endTime
        })
        setIsEditEnable(true)
    }

    const handleCloseEdit = () => {
        setSelectedTimeSlot(null)
        setIsEditEnable(false)
    }

    return (
        <div className={clsx(
            "relative flex items-center w-full p-4 space-x-4 md:flex-row",
            isEditEnabled && "flex-col items-start"
        )}>
            <div className={clsx(
                "inline-flex items-center space-x-1 font-semibold"
            )}>
                {!isEditEnabled &&
                    <div>
                        <span>
                            {dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot?.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
                        </span>
                        <span className="px-1">-</span>
                        <span>
                            {dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot?.endTime + "+00:00").tz(timeZone).format("hh:mm A")}
                        </span>
                    </div>
                }
                {isEditEnabled && (
                    <div className="flex flex-col items-center justify-center space-x-2 space-y-2 md:space-y-0 md:flex-row">
                        <TimeRangeSelection
                            timeSlot={selectedTimeSlot}
                            setTimeSlot={setSelectedTimeSlot}
                        />
                    </div>
                )}
            </div>
            <div className={"flex flex-row items-center space-x-3"}>
                {!isEditEnabled && // edit
                    <button onClick={handleOpenEdit} className={clsx(
                        "inline-flex items-center justify-center p-2 ",
                        "transform bg-green-200 rounded-full hover:scale-105"
                    )}>
                        <FaEdit size={16} className="text-green-700 " />
                    </button>
                }
                {!isEditEnabled && // delete
                    <button onClick={handleOnDelete} className={clsx(
                        "inline-flex items-center justify-center ",
                        "transform rounded-full bg-red-50 hover:scale-105"
                    )}>
                        <FaTimesCircle size={20} className="text-red-400 " />
                    </button>
                }
                {isEditEnabled &&
                    <IconWithTextButton
                        icon={<FaCheck />}
                        placeholder="Update"
                        className="text-green-700 bg-green-300 border shadow-sm"
                        onClick={handleOnUpdate}
                    />
                }
                {isEditEnabled &&
                    <IconWithTextButton
                        icon={<FaTimes />}
                        placeholder="Cancel"
                        className="ml-4 bg-white border shadow-sm"
                        onClick={handleCloseEdit}
                    />
                }
            </div>
        </div>
    );
};

export default TimeRange