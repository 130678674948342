import { eventFilterProps } from 'redux/event/event.const';
import { weeklyTimeSlot } from 'redux/booking/booking.const';


const updateEventPayload = {
    note: '',
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot
}


export const EVENT_INITIAL_STATE = {
    userEventList: {
        isLoading: false,
        userEventList: null,
        errorMsg: null,
        filteredUserEventList: null,
    },

    eventDetail: {
        isLoading: false,
        eventDetail: null,
        errorMsg: null
    },

    updateEvent: {
        isLoading: false,
        updateEvent: null,
        errorMsg: null,
        updateEventPayload: updateEventPayload,
    },

    filterProps: eventFilterProps
}