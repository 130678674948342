import {
    ListItem,
    ItemLink,
    ItemIcon,
    ItemName,
} from './SideNavBarStyle';

import { createLogout } from "redux/auth/auth.request"

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';

const SideBarItem = ({ dataItem, visible }) => {
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const itemNameVarients = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }

    const handleCommingSoone = () => {
        if (["Buy Gift Card"].includes(dataItem.name)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.COMING_SOON_MODAL.stateKey]: true
            }))
        }
    }

    const handleLogOut = () => {
        if (["Log Out"].includes(dataItem.name)) {
            dispatcher(createLogout())
        }
    }

    if (["Buy Gift Card"].includes(dataItem.name)) {
        return (
            <ListItem onClick={() => handleCommingSoone()}>
                <ItemIcon>
                    <dataItem.icon />
                </ItemIcon>
                <ItemName
                    variants={itemNameVarients}
                    initial={'open'}
                    animate={visible ? 'open' : 'close'}
                    transition={transition}
                >
                    {dataItem.name}
                </ItemName>
            </ListItem>
        )
    }

    if (["Log Out"].includes(dataItem.name)) {
        return (
            <ListItem onClick={() => handleLogOut()}>
                <ItemIcon>
                    <dataItem.icon />
                </ItemIcon>
                <ItemName
                    variants={itemNameVarients}
                    initial={'open'}
                    animate={visible ? 'open' : 'close'}
                    transition={transition}
                >
                    {dataItem.name}
                </ItemName>
            </ListItem>
        )
    }

    return (
        <ItemLink to={dataItem.path}>
            <ItemIcon>
                <dataItem.icon />
            </ItemIcon>
            <ItemName
                variants={itemNameVarients}
                initial={'open'}
                animate={visible ? 'open' : 'close'}
                transition={transition}
            >
                {dataItem.name}
            </ItemName>
        </ItemLink>
    )
}

export default SideBarItem;
