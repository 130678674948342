import clsx from "clsx";
import * as React from "react";
import { toast } from "react-toastify";

import { FaTimes } from "react-icons/fa";
import { MdEdit } from 'react-icons/md'
import { AiFillCheckCircle } from 'react-icons/ai'

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setOfferingCourse } from "redux/offering/offering.slice"

const SkillChipView: React.FC<{ onRemove?: () => void; name: string; editMode: boolean }> = ({
  editMode,
  name,
  onRemove,
}) => (
  <div className="flex items-center px-3 py-1 bg-blue-200 rounded-xl">
    <p className="font-medium text-blue-500">{name}</p>
    <button className="pl-3">
      {editMode && <FaTimes className="text-blue-500" onClick={onRemove} />}
    </button>
  </div>
);

export const SkillsSection = () => {
  const { offeringCourse } = useAppState((s) => s.offering)

  const [editMode, setEditMode] = React.useState(false);
  const [newSkill, setNewSkill] = React.useState("");
  const dispatcher = useAppDispatcher();

  const removeSkill = React.useCallback(
    (skill: string) => {
      const newSkills = offeringCourse?.offeringCourse?.skills?.filter((skills: any) => skills?.skill !== skill);
      dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, skills: newSkills }));
    },
    [dispatcher, offeringCourse?.offeringCourse?.skills]
  );

  const handleSave = (e: any) => {
    if ((e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) && newSkill.trim()) {
      const skillTag = newSkill.trim()
      const mySkills = offeringCourse?.offeringCourse?.skills
      const filteredSkills = offeringCourse?.offeringCourse?.skills?.filter((skill: any) => skill?.skill === skillTag)
      if (filteredSkills?.length > 0) {
        toast.warning("Duplicate skills not allowed!")
        setNewSkill("");
        const element = document.getElementById("skill")
        element?.focus()
        return;
      }

      dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, skills: [...mySkills, { skill: skillTag }] }))
      setNewSkill("");
      const element = document.getElementById("skill")
      element?.focus()
    }
  };

  return (
    <div
      className={clsx(
        "p-5 bg-white space-y-3"
      )}
    >
      <div className="relative flex items-center justify-between">
        <div className="flex items-center space-x-3 ">
          <p className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
            {"Skills you will gain"}
          </p>
          <p className="text-base text-text-700 font-normal">
            ({"Benefits from this class"})
          </p>
        </div>
        <button
          className="absolute right-1 top-0"
          onClick={() => {
            if (editMode && offeringCourse?.offeringCourse?.skills?.length < 5) {
              toast.warning("Enter at least 5 skills")
            }
            else {
              setEditMode(!editMode)
            }

          }}
        >
          {editMode ? (
            <AiFillCheckCircle className="text-secondary-dark" size={25} />
          ) : (
            <MdEdit className="text-secondary-dark" size={25} />
          )}
        </button>
      </div>

      {!editMode &&
        <div className="border border-secondary-main border-dashed flex flex-wrap py-5 space-x-3 space-y-2">
          <div></div>
          {offeringCourse?.offeringCourse?.skills?.map((skill: any, index: number) => (
            <SkillChipView
              key={index}
              name={skill?.skill}
              editMode={false}
            />
          ))}
        </div>
      }
      {editMode &&
        <div className="border border-secondary-main border-dashed flex flex-wrap py-5 space-x-3 space-y-2">
          <div></div>
          {offeringCourse?.offeringCourse?.skills?.map((skill: any, index: number) => (
            <SkillChipView
              key={index}
              name={skill?.skill}
              editMode={true}
              onRemove={() => { removeSkill(skill?.skill) }}
            />
          ))}
          <div className="flex items-center w-40 space-x-2">
            <div className="relative">
              <input
                className="w-40 h-10 rounded-lg mt-1 px-2 py-0 border-2 border-gray-100 focus:outline-none"
                type="text"
                placeholder="Skill name"
                id="skill"
                autoFocus={true}
                value={newSkill}
                onChange={(e) => {
                  setNewSkill(e.target.value);
                }}
                onKeyDown={handleSave}
              />
            </div>
          </div>
        </div>
      }

      {offeringCourse?.offeringCourse?.skills?.length < 5 && (
        <div className="flex items-center justify-center pt-3 font-bodyPri font-normal text-red-500 text-xs">
          ({"Minimum 5 Skills"})
        </div>
      )}
    </div>
  );
};
