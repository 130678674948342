export const url = {
    GET_STUDENT_PREFERENCE: "/preference/student",
    UPDATE_STUDENT_PREFERENCE: "/preference/student/update",
    GET_TEACHER_PREFERENCE: "/preference/tutor",
    UPDATE_TEACHER_PREFERENCE: "/preference/tutor/update",
    GET_USER_PREFERENCE: "/preference/user",
    UPDATE_USER_PREFERENCE: "/preference/user/update"
}

export const PREFERENCE_INITIAL_STATE = {
    preference: {
        isLoading: false,
        preference: null,
        errorMsg: null
    },
    userPreference: {
        isLoading: false,
        userPreference: null,
        errorMsg: null
    }
}