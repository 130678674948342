import { useEffect } from 'react';
import clsx from "clsx";

import { FaInfoCircle } from "react-icons/fa";
import { userSocialMediaLinksConst } from 'components/modals/personalDetailModal/PersonalSocialMediaConst';

import { getUserSocialMediaLinks, updateUserSocialMediaLink } from 'redux/user/user.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';

const PersonalSocialMediaPresence = () => {
    const { userSocialMediaLinks } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getUserSocialMediaLinks())
    }, [])

    return (
        <div className={"flex flex-col items-start justify-start gap-5 px-2 py-1"}>
            <span className='font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                {"My Social Media Presence:"}
            </span>

            <div className={"grid grid-cols-12 gap-5 w-full"}>
                {userSocialMediaLinks?.userSocialMediaLinks?.map((socialLinks) => (
                    <div className={"col-start-0 col-span-6"}>
                        <div className={"flex items-center justify-start gap-3"}>
                            <span className={"font-bodyPri font-normal text-text-900 text-sm"}>
                                {userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].label}
                            </span>
                            <div className={"relative has-tooltip"}>
                                <FaInfoCircle className="text-sm text-text-400" />
                                <span
                                    className={clsx(
                                        "px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip -top-5",
                                        "flex items-center justify-start overflow-hidden"
                                    )}
                                >
                                    {userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].demoKey1}
                                </span>
                            </div>
                        </div>
                        <input
                            type={"text"}
                            autoFocus={true}
                            placeholder={userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].placeholder}
                            className={clsx(
                                "w-full px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            value={socialLinks?.userSocialLinks?.split(".com/")[1]}
                            onChange={(event) => {
                                let mySocialLinkValue = userSocialMediaLinksConst[socialLinks?.userSocialName?.toUpperCase()].socialLinkUrl + event.target.value
                                dispatcher(updateUserSocialMediaLink(
                                    socialLinks?.userSocialId,
                                    { userSocialLink: mySocialLinkValue }
                                ))
                            }}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PersonalSocialMediaPresence;