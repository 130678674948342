import { useEffect, useState } from 'react';

import { studentTabs, pageHeading } from 'pages/auth/myStudents/data';

import { MyStudentSearch } from "pages/auth/myStudents/components/MyStudentsSearch";
import { AllStudents } from "pages/auth/myStudents/components/AllStudents";
import { FavStudents } from 'pages/auth/myStudents/components/FavStudents';

import PageHeader from 'components/pageHeader/PageHeader';
import Tabs from "components/tabs/Tabs";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

function MyStudentPage() {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const dispatcher = useAppDispatcher()

  const [myActiveTab, setMyActiveTab] = useState(studentTabs.ALL_STUDENT.value)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.MY_STUDENTS))
  }, [dispatcher, currentPageInfo])

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <MyStudentSearch myActiveTab={myActiveTab} />
      <Tabs
        tabs={Object.values(studentTabs)}
        handleTabs={(activeTab) => {
          setMyActiveTab(activeTab.value)
        }}
      />
      {myActiveTab === studentTabs.ALL_STUDENT.value &&
      <AllStudents />
      }
      {myActiveTab === studentTabs.FAV_STUDENT.value &&
      <FavStudents />
      }
    </div>
  );
}

export default MyStudentPage;
