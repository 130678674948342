import React, { useEffect, useMemo, useState } from "react";

//Stripe imports
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

//helper functions
import updatePaymentStatus from "../../helpers/updatePaymentStatus";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Component imports
import Button from "../presentationcomponents/Button/Button";

//Style imports
import {
  StyledMakePayment,
  StyledMakePaymentHeader,
  StyledMakePaymentButton,
  StyledMakePaymentError,
} from "./MakePayment.styles";

//Payment elements styling
const useOptions = () => {
  const options = useMemo(() => ({
    style: {
      base: {
        color: "#000000",
        letterSpacing: "0.025em",
      },
      invalid: {
        color: "#FF4081",
      },
    },
  }));

  return options;
};

export default function MakePayment({ loading, setLoading }) {
  const { payment, dispatch, selectedChat } = useChat();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const { amount } = payment;

  //Client secret
  const [clientSecret, setClientSecret] = useState("");

  //Errors
  const [cardError, setCardError] = useState(null);
  const [expiryError, setExpiryError] = useState(null);
  const [cvcError, setCvcError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  //Create stripe payment intent
  useEffect(() => {
    async function createPaymentIntent() {
      setLoading(true);
      const response = await fetch("/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount }),
      });

      const data = await response.json();
      setClientSecret(data.clientSecret);
      setLoading(false);
    }
    createPaymentIntent();
  }, [amount, setLoading]);

  //Card details
  function handleCardNumber(event) {
    if (event.error) {
      setCardError(event.error.message);
    }
  }

  function handleExpiryDate(event) {
    if (event.error) {
      setExpiryError(event.error.message);
    }
  }

  function handleCVC(event) {
    if (event.error) {
      setCvcError(event.error.message);
    }
  }

  //Make payment
  async function handleSubmit(e) {
    e.preventDefault();
    if (!clientSecret) return;
    if (!stripe || !elements) return;
    if (cardError || expiryError || cvcError) {
      setPaymentError("Invalid card details");
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: "John Doe",
        },
      },
    });

    if (error) {
      console.log(error);
      setPaymentError("Could not make payment");
      setLoading(false);
      return;
    } else {
      await updatePaymentStatus(selectedChat, payment);
      dispatch({ type: "SET_MAKE_PAYMENT", payload: null });
    }
    setLoading(false);
  }

  //Close modal
  function closePaymentModal() {
    dispatch({
      type: "SET_MAKE_PAYMENT",
      payload: null,
    });
  }

  return (
    <StyledMakePayment>
      <StyledMakePaymentHeader>{`Make $${amount} payment`}</StyledMakePaymentHeader>
      <form id="payment-form" style={{ width: "100%" }} onSubmit={handleSubmit}>
        <CardNumberElement options={options} onChange={handleCardNumber} />
        <CardExpiryElement options={options} onChange={handleExpiryDate} />
        <CardCvcElement options={options} onChange={handleCVC} />
        <Button text="Make Payment" variant="primary" loading={loading} />
      </form>
      <StyledMakePaymentButton>
        <Button
          onClick={closePaymentModal}
          text="Cancel"
          variant="secondary"
          loading={loading}
        />
      </StyledMakePaymentButton>
      {paymentError && (
        <StyledMakePaymentError>{paymentError}</StyledMakePaymentError>
      )}
    </StyledMakePayment>
  );
}
