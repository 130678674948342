import { ArrowForwardIos } from '@mui/icons-material';
import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';

export const Container = tw.div`mx-10 flex flex-col items-center gap-3`;

export const AccordionTitle = tw.span`
    font-subHeading font-bold text-3xl text-text-900 text-center mb-4
`;

export const AccordionContainer = tw.div`
    w-full cursor-pointer select-none w-full h-full bg-white rounded-lg
`;

export const Question = motion(tw.header`
    flex flex-row items-center justify-between px-5 py-3 bg-background-darkLight rounded-lg
`);

export const QuestionText = tw.span`
    font-subHeading font-medium text-lg text-text-900
`;

export const ArrowToggleIcon = motion(styled(ArrowForwardIos)``);

export const Answer = motion(styled.section`
    ${tw`
        px-10 bg-white rounded-lg overflow-hidden
        font-bodyPri font-normal text-base text-text-800
    `}
    ${({ isOpen }) => isOpen && tw`
        py-3
    `}
`);