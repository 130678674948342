import format from 'string-format';

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/availability/availability.const"


class AvailabilityService {

    getUserAvailabilityByUserId = async ({ params }) => {
        const response = await baseApiInstance().get(
            format(url.USER_AVAILABILITY_BY_USER_ID, params)
        )

        return response
    }

    updateAvailabilityByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.USER_AVAILABILITY_BY_USER_ID, params),
            body
        )

        return response
    }
}

export default new AvailabilityService()