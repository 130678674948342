import { createSlice } from "@reduxjs/toolkit";
import { STUDENT_INITIAL_STATE } from "redux/student/student.initialState";
import { filterName } from "redux/student/student.const";

const student = createSlice({
    name: "student",
    initialState: STUDENT_INITIAL_STATE,
    reducers: {
        // reducers for my student list
        setMyStudentListLoading: (state, { payload }) => {
            state.myStudentList.isLoading = payload
        },
        setMyStudentList: (state, { payload }) => {
            state.myStudentList.myStudentList = payload
            state.myStudentList.filteredMyStudentList = payload
            state.myStudentList.errorMsg = STUDENT_INITIAL_STATE.myStudentList.errorMsg
        },
        setMyStudentListErrorMsg: (state, { payload }) => {
            state.myStudentList.errorMsg = payload
            state.myStudentList.myStudentList = STUDENT_INITIAL_STATE.myStudentList.myStudentList
            state.myStudentList.filteredMyStudentList = STUDENT_INITIAL_STATE.myStudentList.filteredMyStudentList
        },
        setClearMyStudentList: (state) => {
            state.myStudentList = STUDENT_INITIAL_STATE.myStudentList
        },

        // reducers for fav student list
        setFavStudentListLoading: (state, { payload }) => {
            state.favStudentList.isLoading = payload
        },
        setFavStudentList: (state, { payload }) => {
            state.favStudentList.favStudentList = payload
            state.favStudentList.filteredFavStudentList = payload
            state.favStudentList.errorMsg = STUDENT_INITIAL_STATE.favStudentList.errorMsg
        },
        setFavStudentListErrorMsg: (state, { payload }) => {
            state.favStudentList.errorMsg = payload
            state.favStudentList.favStudentList = STUDENT_INITIAL_STATE.favStudentList.favStudentList
            state.favStudentList.filteredFavStudentList = STUDENT_INITIAL_STATE.favStudentList.filteredFavStudentList
        },
        setClearFavStudentList: (state) => {
            state.favStudentList = STUDENT_INITIAL_STATE.favStudentList
        },

        // reducers for add fav student
        setAddFavStudentLoading: (state, { payload }) => {
            state.addFavStudent.isLoading = payload
        },
        setAddFavStudentErrorMsg: (state, { payload }) => {
            state.addFavStudent.errorMsg = payload
            state.addFavStudent.addFavStudent = STUDENT_INITIAL_STATE.addFavStudent.addFavStudent
        },
        setClearAddFavStudent: (state) => {
            state.addFavStudent = STUDENT_INITIAL_STATE.addFavStudent
        },

        // reducers for student user detail
        setStudentUserDetailLoading: (state, { payload }) => {
            state.studentUserDetail.isLoading = payload
        },
        setStudentUserDetail: (state, { payload }) => {
            state.studentUserDetail.studentUserDetail = payload
            state.studentUserDetail.errorMsg = STUDENT_INITIAL_STATE.studentUserDetail.errorMsg
        },
        setStudentUserDetailErrorMsg: (state, { payload }) => {
            state.studentUserDetail.errorMsg = payload
            state.studentUserDetail.studentUserDetail = STUDENT_INITIAL_STATE.studentUserDetail.studentUserDetail
        },
        setClearStudentUserDetail: (state) => {
            state.studentUserDetail = STUDENT_INITIAL_STATE.studentUserDetail
        },

        // reducers for update student user detail
        setAddStudentUserDetailLoading: (state, { payload }) => {
            state.addStudentUserDetail.isLoading = payload
        },
        setAddStudentUserDetailErrorMsg: (state, { payload }) => {
            state.addStudentUserDetail.errorMsg = payload
        },
        setClearAddStudentUserDetail: (state) => {
            state.addStudentUserDetail = STUDENT_INITIAL_STATE.addStudentUserDetail
        },

        // reducers for student language list
        setStudentUserLanguageListLoading: (state, { payload }) => {
            state.studentUserLanguageList.isLoading = payload
        },
        setStudentUserLanguageList: (state, { payload }) => {
            state.studentUserLanguageList.studentUserLanguageList = payload
            state.studentUserLanguageList.errorMsg = STUDENT_INITIAL_STATE.studentUserLanguageList.errorMsg
        },
        setStudentUserLanguageListErrorMsg: (state, { payload }) => {
            state.studentUserLanguageList.errorMsg = payload
            state.studentUserLanguageList.studentUserLanguageList = STUDENT_INITIAL_STATE.studentUserLanguageList.studentUserLanguageList
        },
        setClearStudentUserLanguageList: (state) => {
            state.studentUserLanguageList = STUDENT_INITIAL_STATE.studentUserLanguageList
        },

        // reducers for update student language
        setAddStudentLanguageDetailLoading: (state, { payload }) => {
            state.addStudentLanguageDetail.isLoading = payload
        },
        setAddStudentLanguageDetailErrorMsg: (state, { payload }) => {
            state.addStudentLanguageDetail.errorMsg = payload
        },
        setClearAddStudentLanguageDetail: (state) => {
            state.addStudentLanguageDetail = STUDENT_INITIAL_STATE.addStudentLanguageDetail
        },

        // reducers for student category list
        setStudentUserCategoryListLoading: (state, { payload }) => {
            state.studentUserCategoryList.isLoading = payload
        },
        setStudentUserCategoryList: (state, { payload }) => {
            state.studentUserCategoryList.studentUserCategoryList = payload
            state.studentUserCategoryList.errorMsg = STUDENT_INITIAL_STATE.studentUserCategoryList.errorMsg
        },
        setStudentUserCategoryListErrorMsg: (state, { payload }) => {
            state.studentUserCategoryList.errorMsg = payload
            state.studentUserCategoryList.studentUserCategoryList = STUDENT_INITIAL_STATE.studentUserCategoryList.studentUserCategoryList
        },
        setClearUserStudentCategoryList: (state) => {
            state.studentUserCategoryList = STUDENT_INITIAL_STATE.studentUserCategoryList
        },

        // reducers for update category details
        setAddStudentCategoryDetailLoading: (state, { payload }) => {
            state.addStudentCategoryDetail.isLoading = payload
        },
        setAddStudentCategoryDetailErrorMsg: (state, { payload }) => {
            state.addStudentCategoryDetail.errorMsg = payload
        },
        setClearAddStudentCategoryDetail: (state) => {
            state.addStudentCategoryDetail = STUDENT_INITIAL_STATE.addStudentCategoryDetail
        },

        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((myStudents) => (
                    !payload.filterProps.searchText || (myStudents.firstName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())
                        || myStudents.lastName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase()))
                ))

            switch (payload.filterName) {
                case filterName.ALL_STUDENTS.value:
                    state.myStudentList.filteredMyStudentList = filteredList
                    break;
                case filterName.FAV_STUDENTS.value:
                    state.myStudentList.filteredMyStudentList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = STUDENT_INITIAL_STATE.filterProps
        }

    }
});

export const {
    setMyStudentListLoading,
    setMyStudentList,
    setMyStudentListErrorMsg,
    setClearMyStudentList,

    setFavStudentListLoading,
    setFavStudentList,
    setFavStudentListErrorMsg,
    setClearFavStudentList,

    setAddFavStudentLoading,
    setAddFavStudentErrorMsg,
    setClearAddFavStudent,

    setStudentUserDetailLoading,
    setStudentUserDetail,
    setStudentUserDetailErrorMsg,
    setClearStudentUserDetail,

    setAddStudentUserDetailLoading,
    setAddStudentUserDetailErrorMsg,
    setClearAddStudentUserDetail,

    setStudentUserLanguageListLoading,
    setStudentUserLanguageList,
    setStudentUserLanguageListErrorMsg,
    setClearStudentUserLanguageList,

    setAddStudentLanguageDetailLoading,
    setAddStudentLanguageDetailErrorMsg,
    setClearAddStudentLanguageDetail,

    setStudentUserCategoryListLoading,
    setStudentUserCategoryList,
    setStudentUserCategoryListErrorMsg,
    setClearUserStudentCategoryList,

    setAddStudentCategoryDetailLoading,
    setAddStudentCategoryDetailErrorMsg,
    setClearAddStudentCategoryDetail,

    setFilterProps,
    setClearFilterProps,
} = student.actions

export default student.reducer;