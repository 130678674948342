import { createSlice } from "@reduxjs/toolkit";
import { SEGMENT_INITIAL_STATE } from "redux/segment/segment.initialState";
import { filterName } from "redux/segment/segment.const"

export const segmentSlice = createSlice({
    name: "segment",
    initialState: SEGMENT_INITIAL_STATE,
    reducers: {
        // reducers for segment list
        setSegmentListLoading: (state, { payload }) => {
            state.segmentList.isLoading = payload
        },
        setSegmentList: (state, { payload }) => {
            const segments = Object.values(payload)
            const segmentslist = []

            segments.map((segment) => {
                segmentslist.push({
                    segmentId: segment.segmentId,
                    segment: segment.segment,
                    categories: segment.categories
                })
            })
            state.segmentList.segmentList = segmentslist.filter(segment => (segment.segment !== "Others"))
            state.segmentList.filteredSegmentList = segmentslist.filter(segment => (segment.segment !== "Others"))
            state.segmentList.errorMsg = SEGMENT_INITIAL_STATE.segmentList.errorMsg
        },
        setSegmentListErrorMsg: (state, { payload }) => {
            state.segmentList.errorMsg = payload
            state.segmentList.segmentList = SEGMENT_INITIAL_STATE.segmentList.segmentList
            state.segmentList.filteredSegmentList = SEGMENT_INITIAL_STATE.segmentList.filteredSegmentList
        },
        setClearSegmentList: (state) => {
            state.segmentList = SEGMENT_INITIAL_STATE.segmentList
        },

        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = []
            payload.list.forEach((segment) => {
                const segmentsCategories = { ...segment };
                segmentsCategories.categories = segmentsCategories.categories.filter((category) => (
                    category.category.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())
                ));

                if (segmentsCategories.categories.length > 0) {
                    filteredList.push(segmentsCategories);
                }
            });

            switch (payload.filterName) {
                case filterName.SEGMENT.value:
                    state.segmentList.filteredSegmentList = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = SEGMENT_INITIAL_STATE.filterProps
        }
    },
})

export const {
    setSegmentListLoading,
    setSegmentList,
    setSegmentListErrorMsg,
    setClearSegmentList,

    setFilterProps,
    setClearFilterProps
} = segmentSlice.actions;

export default segmentSlice.reducer;