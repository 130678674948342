import tw, { styled } from "twin.macro";

export const StyledScheduleMeeting = styled.div`
  ${tw`absolute width[100vw] height[100vh] flex flex-col items-center justify-center bg-white rounded-md shadow-md px-5 z-30 md:(width[350px] height[fit-content])  top[50%] left[50%] transform[translate(-50%, -50%)] py-5 `}
  h3 {
    ${tw`mb-2.5 font-semibold text-lg`}
  }
  input {
    ${tw`py-1.5 px-1.5 outline-none border border-gray-300 rounded-md w-full`}
  }
`;

export const StyledScheduleMeetingButtons = styled.div`
  ${tw`mt-2.5 w-full`}
  button:last-of-type {
    ${tw`mt-2.5`}
  }
`;

export const StyledError = styled.p`
  ${tw`text-secondary-dark text-sm font-bold mt-1`}
`;
