export const tasks = [
    {
        taskNo: 1,
        taskName: 'EmailVerify',
        title: 'Verify email',
        description: 'This is the first step, we would like to connect with real person with active and verified email address.',
        btnName: {
            not_submitted: 'Send code to email',
            submitted: 'Code submited',
            verified: 'Email verified',
        },
        status: 'not_submitted',
        taskTime: '1 min',
        block_period: ''
    },
    {
        taskNo: 2,
        taskName: 'MobilVerify',
        title: 'Verify mobile number',
        description: 'We would like to connect with real person with active and verified phone number.',
        btnName: {
            not_submitted: 'Send code to mobile',
            submitted: 'Code submited',
            verified: 'Mobile no. verified',
        },
        status: 'not_submitted',
        taskTime: '1 min',
        block_period: ""
    },
    {
        taskNo: 3,
        taskName: 'Resume',
        title: 'Share your Personal details',
        description: 'Please upload your latest resume highlighting your achievements, credentials and motivations to teach online.',
        btnName: {
            not_submitted: "Fill Your Details",
            submitted: "Details submited",
            under_review: 'Details submited',
            verified: 'Details Verified',
            rejected: 'Rejected',
            re_submit: 'Fill Your Details',
        },
        status: 'not_submitted',
        taskTime: '1 min',
        block_period: '90 days'
    },
    {
        taskNo: 4,
        taskName: 'IntroVideo',
        title: 'Share your introduction video',
        description: 'Please upload your introduction video highlighting your achievements, credentials and motivations to teach online. The video should not be more than 2 minutes long.',
        btnName: {
            not_submitted: 'Upload short video',
            submitted: 'Video submited',
            under_review: "Video submitted",
            verified: "Video verified",
            rejected: "Rejected",
            re_submit: "Upload short video",
        },
        status: 'not_submitted',
        taskTime: '5 mins',
        block_period: '90 days'
    },
    {
        taskNo: 5,
        taskName: 'QuestionAnswer',
        title: 'Answer a few relevant questions',
        description: 'Answer these questions to help our recruiter learn more about your circumstances.',
        btnName: {
            not_submitted: 'Show Questions',
            submitted: 'Show Response',
            under_review: 'Submitted',
            verified: "Verified",
            rejected: "rejected",
            re_submit: "Show Questions",
        },
        status: 'not_submitted',
        taskTime: '5 mins',
        block_period: '90 days'
    },
    {
        taskNo: 6,
        taskName: 'VideoCall',
        title: 'Attend a video call ',
        description: 'Please select a suitable timeSlot to meet our team member, video meeting link will be shared on confirmation.',
        btnName: {
            not_submitted: 'Select a slot',
            submitted: "Meeting scheduled",
            under_review: "Slot Selected",
            verified: "Slot Scheduled",
            rejected: "Slot rejected",
            re_submit: "Select a slot",
            scheduled: "Meeting scheduled"
        },
        status: 'not_submitted',
        taskTime: '15 - 30 mins',
        block_period: '90 days'
    },
    {
        taskNo: 7,
        taskName: 'DocumentVerification',
        title: 'Document Verification',
        description: 'Please upload your relevant documents to confirm your identity, qualifications and professional experiences.',
        btnName: {
            not_submitted: 'Upload Documents',
            submitted: "Submitted",
            under_review: "Submitted",
            verified: "Document approved",
            re_submit: "Upload Documents",
            rejected: "Rejected"
        },
        status: 'not_submitted',
        taskTime: '5 mins',
        block_period: '90 days'
    },
    {
        taskNo: 8,
        taskName: 'ProfessionalReferences',
        title: 'Professional References',
        description: 'A professional reference is a person who can provide a recommendation or confirm your qualifications for a position. Please add 2 references.',
        btnName: {
            not_submitted: 'Add Reference',
            submitted: "Submitted",
            under_review: "Submitted",
            verified: "Document approved",
            re_submit: "Upload Documents",
            rejected: "Rejected"
        },
        status: 'not_submitted',
        taskTime: '5 mins',
        block_period: '90 days'
    },
    {
        taskNo: 9,
        taskName: 'TutorBadge',
        title: 'Qualified Tutor Badge, Tutoring Fundamentals (CORE)',
        description: 'Completion of this course (all five modules) is the minimum requirement to become a qualified tutor. If you are already teaching online or have previously taught online, you can upload the evidence with your documents.',
        btnName: {
            not_submitted: 'Start Now',
            submitted: 'submitted',
            under_review: "Under Review",
            verified: "Approved",
            re_submit: "Start Now",
            rejected: "rejected"
        },
        status: 'not_submitted',
        taskTime: 'Optional',
        block_period: '90 days'
    },
    {
        taskNo: 10,
        taskName: 'CreateFirstClass',
        title: 'Create your first class',
        description: 'Congratulations! You are now ready to start your first online class and start creating success stories.',
        btnName: {
            not_submitted: 'Create Class',
            submitted: "Class submited",
            under_review: "Under Review",
            verified: "Class Verified",
            re_submit: "Create Class",
            rejected: "Class Rejected"
        },
        status: 'not_submitted',
        taskTime: '15 mins',
        block_period: '90 days'
    },
]

export const dataFAQ = [
    {
        question: "What are Edulyte’s online tutors expected to do?",
        answer: "Our tutors are the face of our Company. You are expected to provide interactive and thought-provoking lessons to our students. Continuous improvisation is encouraged."
    },
    {
        question: "Why should I become a tutor with Edulyte?",
        answer: "Teaching enables you to transform lives with your knowledge and passion. We give importance to our tutors and enable them to pick up skills that are expected to shape their future."
    },
    {
        question: "Will my students be only from my home country?",
        answer: "Although Edulyte has its roots in Australia and India, you will have the enriching experience of teaching students from anywhere in the world."
    },
    {
        question: "I do not have an online tutoring experience? Can I still apply to Edulyte?",
        answer: "Of course! You will be welcomed into our family if you have an appetite for learning and a zeal for teaching. We ask aspirants to go through our free tutoring fundamentals course to equip themselves before taking their first lesson."
    },
    {
        question: "What is the recruitment process?",
        answer: "Step 1: Submit your resume and an introductory video and fill out the application form on our site. Step 2: If you meet our criteria, you will be interviewed.Step 3: You will be notified about the result of the interview within 2 days.Step 4: Depending on the outcome, an offer will be made to you to teach our students and change the world."
    },
    {
        question: "What qualification should I have to be a tutor with Edulyte?",
        answer: "Industry based qualification meeting the requirements of Tutoring Fundamentals (CORE) modules:	Managing the Tutoring Environment •	How Students Learn •	Reporting •	An Introduction to Differentiation •	Child Protection Strong academic background and a thorough understanding of the online education system. Strong fluency in English language, as your students are going to be from different parts of the world.A zeal for teaching and finding innovative ways to educate the learners."
    },
    {
        question: "Which video conferencing tool should I have to be a tutor with Edulyte ?",
        answer: "We have an integrated video conferencing tool to conduct classes. In the near future, we will add zoom and other popular solutions."
    },
    {
        question: "Do I have to tutor for a certain number of hours every day?",
        answer: "We allow you to fix a schedule that suits you. You can take classes whenever you want, on weekdays or weekends. But yes, we expect you to respect your commitment once you have made it."
    },
    {
        question: "What happens if there is a technological issue?",
        answer: "We understand that there may be a tech snag once in a while. We give you the authority to reschedule your class that has been affected by tech issues, after consulting with your student."
    },
    {
        question: "Do I have to look for students after joining Edulyte?",
        answer: "We will do all the marketing for you, however, sharing your profile and courses with your network will help you build your brand and trust. After you are on board, we get you students as per your availability and requirement, it should not be treated as a guarantee. Our team will keep sharing the best practices to succeed in this industry."
    },
    {
        question: "Can I tutor more than one subject on Edulyte?",
        answer: "Of course! In case you have expertise in more than one subject, you are welcome to teach them on our platform."
    }
];

export const docStatus = {
    statusName: {
        not_submitted: "not_submitted",
        submitted: "submitted",
        verified: "verified",
        under_review: "under_review",
        accept: "accept",
        reject: "reject",
        re_upload: "re_upload"
    },
}

export const slotStatus = {
    statusName: {
        not_submitted: "not_submitted",
        submitted: "submitted",
        scheduled: "scheduled",
        re_scheduled: "re_scheduled"
    }
}

export const onBoardingEventStatus = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    NOT_SUBMITTED: {
        label: "Not Submitted",
        value: "not_submitted",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    DECLINED: {
        label: "Declined",
        value: "declined",
        lightColor: 'red-100',
        darkColor: 'red-500',
    },
    RESCHEDULED: {
        label: "Rescheduled",
        value: "rescheduled",
        lightColor: 'pink-100',
        darkColor: 'pink-500',
    },
    SCHEDULED: {
        label: "Scheduled",
        value: "scheduled",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    ON_GOING: {
        label: "On Going",
        value: "on_going",
        lightColor: 'yellow-100',
        darkColor: 'yellow-500',
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
}

export const titleOptions = {
    MR: {
        label: "Mr.",
        value: "mr",
    },
    MRS: {
        label: "Mrs.",
        value: "mrs"
    },
    Dr: {
        label: "Dr.",
        value: "dr"
    }
}

export const genderOptions = {
    MALE: {
        label: "Male",
        value: "male",
    },
    FEMALE: {
        label: "Female",
        value: "female"
    },
    OTHER: {
        label: "Other",
        value: "other"
    }
}

export const onBoardingStatus = {
    NOT_SUBMITTED: {
        label: "Not Submitted",
        value: "not_submitted",
        color: "text-red-500"
    },
    SUBMITTED: {
        label: "Submitted",
        value: "submitted",
        color: "text-yellow-500"
    },
    UNDER_REVIEW: {
        label: "Under Review",
        value: "under_review",
        color: "text-secondary-main"
    },
    VERIFIED: {
        label: "Verified",
        value: "verified",
        color: "text-green-500"
    },
    RE_UPLOAD: {
        label: "Re Upload",
        value: "re_upload",
        color: "text-orange-500"
    }
}

export const tHeader = [
    "Category",
    "File Name",
    "status",
    "Edit",
    "Delete"
];

export const ReferenceTableHeader = [
    "Reference Name",
    "Relationship",
    "Years or Months",
    "Phone",
    "Email",
    "Edit",
    "Delete"
]

export const OptionalQuestionAnswer = [
    {
        question: 'Do you have any experience in teaching online?',
        answer: {
            options: [
                {
                    label: "Yes",
                },
                {
                    label: "No",
                },
                {
                    label: "Not Sure, I can learn quickly"
                }
            ]
        }
    },
    {
        question: "Would you like to create self-paced courses or teach Live?",
        answer: {
            options: [
                {
                    label: "I'll create self-paced courses"
                },
                {
                    label: "I'll teach live"
                },
                {
                    label: "I can do both"
                }
            ]
        }
    },
    {
        question: "Do you have a computer/laptop with a high-speed internet connection?",
        answer: {
            options: [
                {
                    label: "Yes"
                },
                {
                    label: "No"
                }
            ]
        }
    },
    {
        question: "How many hours per week can you commit? suggested weekly availability",
        answer: {
            options: [
                {
                    label: "Full Time"
                },
                {
                    label: "10 hours per week"
                },
                {
                    label: "20 hours per week"
                },
                {
                    label: "30 hours per week"
                },
                {
                    label: "Weekends Only (Min 5 hours)"
                }
            ]
        }
    }
]

export const inputFieldQuestionAnswer = [
    "What are the top three subjects / skills / categories / topics you can teach?"
]

export const fileCategory = {
    IDENTITY_DOCUMENTS: {
        label: "Identity Documents",
        value: "identity_documents"
    },
    ACADEMIC_QUALIFICATIONS: {
        label: "Academic Qualifications",
        value: "academic_qualifications"
    },
    CERTIFICATIONS: {
        label: "Certifications",
        value: "certifications"
    },
    WORK_EXPERIENCE: {
        label: "Work Experience",
        value: "work_experience"
    },
    OTHERS: {
        label: "Others",
        value: "others"
    }
}

export const resumeCategory = {
    RESUME: {
        label: "Resume",
        value: "resume"
    }
}

export const resumeTableHeader = [
    "Category",
    "File Name",
    "Status",
    "Edit",
    "Delete"
]

export const videoCategory = {
    VIDEO_URL: {
        label: "URL",
        value: "url"
    }
}

export const videoTableHeader = [
    "Category",
    "File Name",
    "Status",
    "Edit",
    "Delete"
]

export const selectedslotTableHeader = [
    "Interviewer",
    "Date",
    "Start Time",
    "Status",
    "Edit",
    "Delete"
]

export const monthsOptions = {
    JANUARY: {
        label: "0",
        value: "0"
    },
    FEBURARY: {
        label: "1",
        value: "1"
    },
    MARCH: {
        label: "2",
        value: "2"
    },
    APRIL: {
        label: "3",
        value: "3"
    },
    MAY: {
        label: "4",
        value: "4"
    },
    JUNE: {
        label: "5",
        value: "5"
    },
    JULY: {
        label: "6",
        value: "6"
    },
    AUGUST: {
        label: "7",
        value: "7"
    },
    SEPTEMBER: {
        label: "8",
        value: "8"
    },
    OCTOBER: {
        label: "9",
        value: "9"
    },
    NOVEMBER: {
        label: "10",
        value: "10"
    },
    DECEMBER: {
        label: "11",
        value: "11"
    },
}

export const availability =

{
    "weeklyAvail": {
        "Monday": [
            {
                "startTime": "08:30:00",
                "endTime": "09:00:00"
            },
            {
                "startTime": "12:15:00",
                "endTime": "14:00:00"
            }
        ],
        "Tuesday": [
            {
                "startTime": "08:30:00",
                "endTime": "08:45:00"
            },
            {
                "startTime": "12:15:00",
                "endTime": "14:00:00"
            }
        ],
        "Wednesday": [
            {
                "startTime": "08:30:00",
                "endTime": "08:45:00"
            }
        ],
        "Thursday": [
            {
                "startTime": "08:30:00",
                "endTime": "08:45:00"
            }
        ],
        "Friday": [
            {
                "startTime": "08:30:00",
                "endTime": "08:45:00"
            }
        ],
        "Saturday": [
            {
                "startTime": "08:30:00",
                "endTime": "08:45:00"
            }
        ],
        "Sunday": [
            {
                "startTime": "08:30:00",
                "endTime": "08:45:00"
            }
        ]
    },
    "offDays": [
        {
            "name": "Diwali",
            "date": "2022-05-31",
            "timeSlots": [
                {
                    "startTime": "19:00:00",
                    "endTime": "23:00:00"
                }
            ]
        },
        {
            "name": "Edulyte",
            "date": "2022-05-13",
            "timeSlots": [
                {
                    "startTime": "07:00 AM",
                    "endTime": "10:00 AM"
                }
            ]
        },
        {
            "name": "Edulyte",
            "date": "2022-05-27",
            "timeSlots": [
                {
                    "startTime": "04:00 AM",
                    "endTime": "10:00 AM"
                }
            ]
        }
    ],
    "specialDays": [
        {
            "name": "Diwali",
            "date": "2021-02-26",
            "timeSlots": [
                {
                    "startTime": "19:00:00",
                    "endTime": "23:00:00"
                }
            ]
        }
    ]
}


export const userEventList = [
    {
        "eventId": 38,
        "eventName": "Hindi",
        "startDateTime": "2023-03-30T06:30:00Z",
        "endDateTime": "2023-03-30T07:30:00Z",
        "status": "on_going",
        "updatedAt": "2022-04-29T02:29:09.040559Z",
        "createdAt": "2022-04-12T20:18:31.345205Z",
        "course": {
            "courseId": 2,
            "courseTitle": "Learn English",
            "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
            "courseType": "one_on_one"
        }
    },
    {
        "eventId": 68,
        "eventName": "Learn English",
        "startDateTime": "2022-05-01T04:30:00Z",
        "endDateTime": "2022-05-01T05:30:00Z",
        "status": "pending",

        "updatedAt": "2022-04-29T03:40:33.880875Z",

        "createdAt": "2022-04-29T03:40:33.880875Z",
        "course": {
            "courseId": 2,
            "courseTitle": "Learn English",
            "thumbnailPicUrl": "https://images.unsplash.com/photo-1501504905252-473c47e087f8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1074&q=80",
            "courseType": "one_on_one"
        }
    }
]

export const interviewerDetails = {
    thumbnailPicUrl: "https://source.unsplash.com/random/1920x1080/?wallpaper,landscape",
    sessionDuration: "40",
    interviewerProfile: {
        profilePicUrl: "https://source.unsplash.com/random/1920x1080/?wallpaper,landscape",
        firstName: "Rachel",
        lastName: "Sharma",
        countryDomain: "india",
        bio: "Avinash Jain is currently a senior at UC Berkeley majoring in Electrical Engineering and Computer Science. He's the CEO and Founder of TheCodex, an online educational platform focused on bringing the best programming content to hundreds of thousands of students around the world."
    }
}