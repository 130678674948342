import {
    FaTwitter,
    FaLinkedinIn,
    FaFacebookF,
    FaGithub,
    FaPinterest,
    FaTumblr,
    FaYoutube,
    FaInstagram,
} from 'react-icons/fa';

export const socialIconsData = [
    {
        name: 'Twitter',
        icon: FaTwitter,
        path: 'https://twitter.com/edulyte',
    },
    {
        name: 'LinkedinIn',
        icon: FaLinkedinIn,
        path: 'https://in.linkedin.com/company/edulyte',
    },
    {
        name: 'FacebookF',
        icon: FaFacebookF,
        path: 'https://www.facebook.com/EdulyteMarketplace',
    },
    {
        name: 'Github',
        icon: FaGithub,
        path: 'https://www.facebook.com/EdulyteMarketplace',
    },
    {
        name: 'Pinterest',
        icon: FaPinterest,
        path: 'https://www.facebook.com/EdulyteMarketplace',
    },
    {
        name: 'Tumblr',
        icon: FaTumblr,
        path: 'https://www.facebook.com/EdulyteMarketplace',
    },
    {
        name: 'Youtube',
        icon: FaYoutube,
        path: 'https://www.youtube.com/channel/UCVcwA1v0_qJquMrEsXJCvlA',
    },
    {
        name: 'Instagram',
        icon: FaInstagram,
        path: 'https://www.instagram.com/edulyte2020',
    },
]

export const footerData = [
    {
        linksTitle: 'QUICK LINKS',
        linksItems: [
            {
                name: 'Blog',
                path: 'https://www.edulyte.com/blogs/',
                pathType: 'href',
            },
            {
                name: 'FAQs',
                path: '#',
                pathType: 'to',
            },
            {
                name: 'Support',
                path: '#',
                pathType: 'to',
            },
            {
                name: 'About Us',
                path: '#',
                pathType: 'to',
            },
        ]
    },
    {
        linksTitle: 'PRODUCT',
        linksItems: [
            {
                name: 'Log In',
                path: '/log-in',
                pathType: 'to',
            },
            {
                name: 'Personal',
                path: '#',
                pathType: 'to',
            },
            {
                name: 'Business',
                path: '#',
                pathType: 'to',
            },
            {
                name: 'Team',
                path: '#',
                pathType: 'to',
            },
        ]
    },
    {
        linksTitle: 'LEGAL',
        linksItems: [
            {
                name: 'GDPR',
                path: '#',
                pathType: 'to',
            },
            {
                name: 'Privacy Policy',
                path: 'https://www.edulyte.com/privacy-policy/',
                pathType: 'href',
            },
            {
                name: 'Terms of Service',
                path: 'https://www.edulyte.com/terms-of-service/',
                pathType: 'href',
            },
            {
                name: 'Disclaimer',
                path: '#',
                pathType: 'to',
            },
        ]
    },
    {
        linksTitle: 'CONTACT',
        linksItems: [
            {
                name: 'Australia:  +61480080151',
                path: '#',
                pathType: 'href',
            },
            {
                name: 'India:  +917795687953',
                path: '#',
                pathType: 'href',
            },
            {
                name: 'support@edulyte.com',
                path: 'mailto:support@edulyte.com',
                pathType: 'href',
            },
            {
                name: 'Sales',
                path: '#',
                pathType: 'href',
            },
            {
                name: 'Report Abuse',
                path: '#',
                pathType: 'href',
            },
        ]
    },
]