import { createSlice } from "@reduxjs/toolkit";
import { AUTH_INITIAL_STATE } from "redux/auth/auth.initalState";


const auth = createSlice({
    name: "auth",
    initialState: AUTH_INITIAL_STATE,
    reducers: {
        // logIn-SignUp reducers
        setLogInSignUpLoading: (state, { payload }) => {
            state.logInSignUp.isLoading = payload
        },
        setLogIn: (state) => {
            state.logInSignUp.isLoading = false
            state.logInSignUp.isLogedIn = true
            state.logInSignUp.errorMsg = AUTH_INITIAL_STATE.logInSignUp.errorMsg
        },
        setToken: (state, { payload }) => {
            state.logInSignUp.accessToken = payload
            state.logInSignUp.errorMsg = AUTH_INITIAL_STATE.logInSignUp.errorMsg
        },
        setLogInSignUpErrorMsg: (state, { payload }) => {
            state.logInSignUp.errorMsg = payload
            state.logInSignUp.isLogedIn = AUTH_INITIAL_STATE.logInSignUp.isLogedIn
            state.logInSignUp.accessToken = AUTH_INITIAL_STATE.logInSignUp.accessToken
        },
        setSignUpPayload: (state, { payload }) => {
            state.logInSignUp.signUpPayload = payload
        },
        setClearSignUpPayload: (state) => {
            state.logInSignUp.signUpPayload = AUTH_INITIAL_STATE.logInSignUp.signUpPayload
        },
        setLogInPayload: (state, { payload }) => {
            state.logInSignUp.logInPayload = payload
        },
        setClearLogInPayload: (state) => {
            state.logInSignUp.logInPayload = AUTH_INITIAL_STATE.logInSignUp.logInPayload
        },
        setClearLogInSignUp: (state) => {
            state.logInSignUp = AUTH_INITIAL_STATE.logInSignUp
        },

        // reducers for verify referral
        setVerifyReferralLoading: (state, { payload }) => {
            state.verifyReferral.isLoading = payload
        },
        setVerifyReferral: (state, { payload }) => {
            state.verifyReferral.verifyReferral = payload
            state.verifyReferral.errorMsg = AUTH_INITIAL_STATE.verifyReferral.errorMsg
        },
        setVerifyReferralErrorMsg: (state, { payload }) => {
            state.verifyReferral.errorMsg = payload
            state.verifyReferral.verifyReferral = AUTH_INITIAL_STATE.verifyReferral.verifyReferral
        },
        setClearVerifyReferral: (state) => {
            state.verifyReferral = AUTH_INITIAL_STATE.verifyReferral
        },

        // reducers for logout
        setLogoutLoading: (state, { payload }) => {
            state.logout.isLoading = payload
        },
        setLogout: (state, { payload }) => {
            state.logout.logout = payload
            state.isLogedIn = false
            state.accessToken = null
            state.logout.errorMsg = AUTH_INITIAL_STATE.logout.errorMsg
        },
        setLogoutErrorMsg: (state, { payload }) => {
            state.logout.errorMsg = payload
            state.logout.logout = AUTH_INITIAL_STATE.logout.logout
        },
        setClearLogout: (state) => {
            state.logout = AUTH_INITIAL_STATE.logout
        }

    }
})

export const {
    setLogInSignUpLoading,
    setLogIn,
    setToken,
    setLogInSignUpErrorMsg,
    setSignUpPayload,
    setClearSignUpPayload,
    setLogInPayload,
    setClearLogInPayload,
    setClearLogInSignUp,

    setVerifyReferralLoading,
    setVerifyReferral,
    setVerifyReferralErrorMsg,
    setClearVerifyReferral,

    setLogoutLoading,
    setLogout,
    setLogoutErrorMsg,
    setClearLogout
} = auth.actions

export default auth.reducer