import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import clsx from 'clsx';

import { MdEdit } from 'react-icons/md';
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";

import InputOtp from 'components/inputOtp/InputOtp';

import { createSendOtp, createVerifyOtp, } from 'redux/notification/notification.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearVerifyOtp, setClearSendOtp } from 'redux/notification/notification.slice';
import { setSignUpPayload } from 'redux/auth/auth.slice';
import { otpStatus } from "redux/notification/notification.const";

import { validateEmail } from 'utils/validation-functions';

const EmailVerificationContainer = () => {
    const { logInSignUp } = useAppState((s) => s.auth)
    const { sendOtp, verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()

    const [myOtp, setMyOtp] = useState("")
    const [isOtpError, setIsOtpError] = useState(false)
    const [isOpenEmailOtpContainer, setIsOpenEmailOtpContainer] = useState(false)
    const [emailAddress, setEmailAddress] = useState(logInSignUp?.signUpPayload?.email)
    const [isEmailEditMode, setIsEmailEditMode] = useState(false)

    useEffect(() => {
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(setClearVerifyOtp())
            dispatcher(setClearSendOtp())
            setIsOpenEmailOtpContainer(false)
            dispatcher(setSignUpPayload({
                ...logInSignUp?.signUpPayload,
                isEmailVerified: true
            }))
            toast.success("Code verified successfully!")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.PENDING.value) {
            setIsOpenEmailOtpContainer(false)
            toast.warning("Something went wrong! Please wait!")
            return;
        }
        if (verifyOtp?.verifyOtp && verifyOtp?.verifyOtp?.status === otpStatus.EXPIRED.value) {
            setIsOpenEmailOtpContainer(false)
            toast.error("Code Expired")
            return;
        }
        if (!verifyOtp?.verifyOtp && verifyOtp?.errorMsg) {
            toast.error("Incorrect Code!")
            setIsOtpError(true)
            return;
        }
    }, [verifyOtp?.verifyOtp || verifyOtp?.errorMsg])

    const handleEditEmail = () => {
        if (isEmailEditMode) {
            if (validateEmail(emailAddress)) {
                dispatcher(setSignUpPayload({
                    ...logInSignUp?.signUpPayload,
                    email: emailAddress?.toLowerCase()
                }))
                setIsEmailEditMode(!isEmailEditMode)
            }
            else {
                toast.warning("Enter Valid Email!")
            }
        }
        else {
            setIsEmailEditMode(!isEmailEditMode)
        }
    }

    const handleEmailVerification = async () => {
        if (isOpenEmailOtpContainer) {
            if (myOtp?.length < 4) return;
            dispatcher(setClearVerifyOtp())
            if (sendOtp?.sendOtp?.otpNotificationId) {
                dispatcher(createVerifyOtp({
                    otpNotificationId: sendOtp?.sendOtp?.otpNotificationId,
                    otp: myOtp
                }))
            }
            else {
                toast.error("Incorrect Code")
                setIsOtpError(true)
            }
        }
        if (!isOpenEmailOtpContainer) {
            if (emailAddress) {
                dispatcher(createSendOtp({
                    otpDeliveryMethod: "email",
                    email: logInSignUp?.signUpPayload?.email
                }))
                toast.success("Code Sent Successfully")
                setIsOpenEmailOtpContainer(true)
                return;
            }
            else {
                toast.warning("Please Enter Email for Verification")
                setIsEmailEditMode(true)
            }
        }
    }

    return (
        <>
            {!logInSignUp?.signUpPayload?.isEmailVerified &&
                <div className='space-y-5'>
                    <div className='flex items-center justify-center gap-3'>
                        <span className='font-bodyPri font-normal text-text-700 text-base whitespace-nowrap'>
                            {"Email Address:"}
                            <span className='text-red-500'>{" * "}</span>
                        </span>
                        {!isEmailEditMode &&
                            <span className='font-bodySec font-normal text-text-900 text-base'>
                                {logInSignUp?.signUpPayload?.email}
                            </span>
                        }
                        {isEmailEditMode &&
                            <input
                                type={"text"}
                                className={clsx("w-full px-3 py-1 border-2 border-secondary-main border-dashed focus:outline-none",
                                    "font-bodySec font-normal text-text-900 text-base"
                                )}
                                value={emailAddress}
                                autoFocus={true}
                                onChange={(e) => setEmailAddress(e.target.value)}
                            />
                        }
                        <div className='cursor-pointer' onClick={handleEditEmail}>
                            {!isEmailEditMode && <MdEdit className='text-2xl text-text-500' />}
                            {isEmailEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                        </div>
                    </div>
                    {isOpenEmailOtpContainer &&
                        <div className='flex flex-col items-center justify-start space-y-2'>
                            <InputOtp
                                email={logInSignUp?.signUpPayload?.email}
                                setMyOtp={setMyOtp}
                                isOtpError={isOtpError}
                                setIsOtpError={setIsOtpError}
                            />
                        </div>
                    }
                    <div className={"flex items-center justify-center"}>
                        <div className={clsx(
                            "py-1.5 rounded-lg",
                            "text-text-50 font-buttons font-medium text-base w-full text-center cursor-pointer",
                            !isOpenEmailOtpContainer && "bg-primary-main hover:bg-secondary-dark",
                            isOpenEmailOtpContainer && myOtp?.length < 4 && "bg-divider-medium",
                            isOpenEmailOtpContainer && myOtp?.length === 4 && "bg-primary-main hover:bg-secondary-dark"
                        )}
                            onClick={handleEmailVerification}
                        >
                            {isOpenEmailOtpContainer ? "Verify Code" : "Verify Email"}
                        </div>
                    </div>
                </div>
            }
            {logInSignUp?.signUpPayload?.isEmailVerified &&
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <BsCheckLg className='text-4xl text-green-500' />
                    <span className='font-bodyPri font-semibold text-lg text-text-900'>
                        {"Email Verified Successfully!"}
                    </span>
                </div>
            }
        </>
    )
}

export default EmailVerificationContainer;