import { useEffect, useState } from 'react';
import clsx from 'clsx';

import ReviewCard from 'components/ReviewCard';

import { getCompanyReviewList } from "redux/company/company.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';

const itemsPerPage = 4

const Reviews = () => {
    const { companyReviewList } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        dispatcher(getCompanyReviewList({ activePage: activePage, itemsPerPage: itemsPerPage }))
    }, [])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"REVIEWS"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                        {"Reviews"}
                    </span>
                    <div className={"grid grid-cols-2 gap-3 grid-flow-row auto-cols-max p-5"}>
                        {companyReviewList?.companyReviewList?.reviews?.map((reviews, idx) => (
                            <ReviewCard
                                key={idx}
                                rating={reviews?.rating}
                                content={reviews?.review}
                                profileImg={reviews?.provider?.profilePicUrl}
                                fName={reviews?.provider?.firstName}
                                lName={reviews?.provider?.lastName}
                                lessonCount={reviews?.provider?.noOfLesson}
                                createdAt={reviews?.provider?.createdAt}
                            />
                        ))}
                    </div>
                    {companyReviewList?.companyReviewList?.totalPages &&
                        <div
                            className={clsx(
                                "w-full flex justify-center items-center border border-text-500 p-2 rounded",
                                "font-bodyPri font-medium text-text-800 text-base cursor-pointer",
                                "hover:bg-background-medium"
                            )}
                            onClick={() => setActivePage(activePage + 1)}
                        >
                            {"Show more"}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Reviews