import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/tools/tools.data';

import { EducatorToolLinksSideBar } from 'pages/auth/tools/LinksSidebar';
import { PopularTools } from 'pages/auth/tools/PopularTools';

import PageHeader from 'components/pageHeader/PageHeader';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';
 

const EducatorToolsPage = () => {
    const dispatcher = useAppDispatcher()
    const { currentPageInfo } = useAppState((state: any) => state.pageInfo)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TOOLS))
    }, [dispatcher, currentPageInfo])

    return (
        <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-5">
            <PageHeader pageHeading={pageHeading} />
            <div className='grid grid-cols-12 gap-5'>
                <div className='col-span-full md:col-span-9'>
                    <PopularTools />
                </div>
                <div className='shadow-sm col-span-full md:col-span-3'>
                    <EducatorToolLinksSideBar />
                </div>
            </div>
        </div>
    );
};

export default EducatorToolsPage;
