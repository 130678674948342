import tw, { styled } from 'twin.macro';
import { NavLink as RouteLink } from 'react-router-dom';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import SvgIcon from 'components/generalComponent/SvgIcon';

export const Container = tw(Section)``;

export const Content = tw(SectionContent)`
    grid grid-cols-1 gap-10 lg:(grid-cols-2 gap-0)
`;

export const ItemImage = styled.div`
    ${tw`
        col-span-1 flex items-center justify-center lg:justify-start
    `}
    img {
        ${tw`
            w-full h-full md:(w-3/4)
        `}
    }
`;

export const ItemContent = tw.div`
    grid col-span-1 gap-3 flex flex-col items-start
`;


export const Header = tw.div`
    flex flex-col items-start justify-start gap-3 
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-700
`;

export const Lists = tw.ul`
    list-disc list-inside
`;

export const ListItem = tw.li`
    font-bulletPoints font-medium text-xl text-text-700 list-inside
`;

export const AppStoreContainer = tw.div`
    flex flex-col md:flex-row items-start gap-5 mt-2 w-full
`;

export const AppStore = tw(RouteLink)`
    flex flex-row items-center justify-center gap-2
    shadow rounded-full bg-white py-2 px-3 w-full md:w-1/3
    font-buttons font-medium text-base text-text-700 whitespace-nowrap
`;

export const AppIcon = tw(SvgIcon)`
    fill-current w-8 h-8 whitespace-nowrap
`;