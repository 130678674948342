import AccordionItem from './AccordionItem';
import {
    Container,
    AccordionTitle,
} from './AccordionStyle';

const Accordion = ({ title, dataFAQ }) => {
    if (!dataFAQ) dataFAQ = [];

    return (
        <Container>
            {title && <AccordionTitle>{title}</AccordionTitle>}
            {dataFAQ.map((itemFAQ, index) => (
                <AccordionItem
                    key={index}
                    index={index}
                    itemFAQ={itemFAQ}
                />
            ))}
        </Container>
    )
}

export default Accordion;
