import clsx from "clsx";
import * as React from "react";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { GiftCardSelection } from "./components/GiftCardSelection";
import { NavigationDots } from "./components/NavigationDots";
import { OrderSummary } from "./components/OrderSummary";
import { PaymentSummary } from "./components/PaymentSummary";
import { RecipientSelection } from "./components/RecipientSelection";
import { setActiveImageIdx } from "./store/GiftCardSlice";
import { GIFT_CARD_IMAGES } from "./store/mock-data";

export const PurchaseGiftCard: React.FC = () => {
  const state = useAppState((s) => s.buyGiftCard.activeStep);
  const idx = useAppState((s) => s.buyGiftCard.selectedImageIdx);
  const dispatcher = useAppDispatcher()

  return (
    <div
      className={clsx(
        "max-w-lg mx-4 md:mx-auto rounded-lg shadow-lg min-h-lg",
        "bg-sky-50"
      )}
    >
      <div className="relative p-8 overflow-auto rounded-xl">
        <button
          className={clsx(
            "absolute top-0 bottom-0 left-0 flex items-center justify-center p-0 text-center mx-10",
            "border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
          )}
          onClick={() =>
            dispatcher(setActiveImageIdx({ idx: (idx > 0 ? idx - 1 : GIFT_CARD_IMAGES.length - 1) }))
          }
        >
          <div className="p-1 bg-gray-300 rounded-full shadow-lg opacity-70 hover:opacity-100">
            <FaChevronCircleLeft className="text-lime-600" size={25} />
          </div>
        </button>
        <div className="col-start-1 col-end-1 mx-auto overflow-hidden text-center rounded-lg sm:w-auto">
          <img
            className="object-cover w-full h-48"
            src={GIFT_CARD_IMAGES[idx]}
          />
        </div>
        <button
          className={clsx(
            "absolute top-0 bottom-0 right-0 flex items-center justify-center p-0 text-center mx-10",
            "border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
          )}
          onClick={() =>
            dispatcher(setActiveImageIdx({ idx: (idx + 1) % GIFT_CARD_IMAGES.length }))
          }
        >
          <div className="p-1 bg-gray-300 rounded-full shadow-lg opacity-70 hover:opacity-100">
            <FaChevronCircleRight className="text-lime-600" size={25} />
          </div>
        </button>
      </div>
      <div className="w-full px-6">
        <NavigationDots />
      </div>
      <div className="px-6 py-2">
        {state === "step1" && <GiftCardSelection />}
        {state === "step2" && <RecipientSelection />}
        {state === "step3" && <OrderSummary />}
        {state === "step4" && <PaymentSummary />}
      </div>

      <GiftCardFooter />
    </div>
  );
};

const GiftCardFooter = () => {
  const [promoCode, setPromoCode] = React.useState("")
  const [balanceCheck, setBalanceCheck] = React.useState("")
  const [isBalanceEnabled, setIsBalanceEnabled] = React.useState(false)
  const [isPromoCodeEnabled, setIsPromoCodeEnabled] = React.useState(false)

  return (
    <div className="p-3 my-2 text-center bg-sky-100">
      <p className="font-bold text-sky-500">
        Thanks for choosing out gift card
      </p>
      <p className="py-2 text-sm">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit.
      </p>
      <div className="grid grid-cols-2 gap-5 px-8 py-2">
        {
          isPromoCodeEnabled ? <>
            <div className="col-span-2">
              <div className="flex w-8/12 mx-auto shadow-sm">
                <input
                  type="text"
                  placeholder="Enter Your Code"
                  className="w-full px-3 py-2 rounded-l-lg outline-none focus:ring-1 focus:ring-blue-300"
                  onChange={(e) => setPromoCode(e.target.value)}
                  value={promoCode}
                />
                <button
                  // onClick={() => onBuy(card)}
                  className={clsx(
                    "text-sm whitespace-nowrap px-4 py-2 text-white bg-blue-600 rounded-r-lg outline-none",
                    "focus:ring-1 focus:ring-blue-300 ring-offset-2"
                  )}
                >
                  Promo Code
                </button>
              </div>
            </div>
          </> :
            <button onClick={() => setIsPromoCodeEnabled(true)} className={clsx("place-self-start", "px-2 py-4 underline text-sky-700")}>Promo</button>
        }
        {
          isBalanceEnabled ? <>
            <div className="col-span-2">
              <div className="flex w-8/12 mx-auto shadow-sm">
                <input
                  type="text"
                  placeholder="Enter Your Account"
                  className="w-full px-3 py-2 rounded-l-lg outline-none focus:ring-1 focus:ring-blue-300"
                  onChange={(e) => setBalanceCheck(e.target.value)}
                  value={balanceCheck}
                />
                <button
                  // onClick={() => onBuy(card)}
                  className={clsx(
                    "text-sm whitespace-nowrap px-4 py-2 text-white bg-blue-600 rounded-r-lg outline-none",
                    "focus:ring-1 focus:ring-blue-300 ring-offset-2"
                  )}
                >
                  Check
                </button>
              </div>
            </div>
          </> :
            <button onClick={() => setIsBalanceEnabled(true)}
              className={clsx("col-start-2 place-self-end", "px-2 py-4 underline text-sky-700")}>
              Check Balance
            </button>
        }
      </div>
    </div>
  )
}
