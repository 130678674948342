import { useEffect } from "react";
import clsx from "clsx";

import {
  FaDollarSign,
  FaPercent,
} from "react-icons/fa";
import { FaInfoCircle } from 'react-icons/fa';

import { Button } from "components/common-components/Buttons";
import { InputFieldWithPrefix } from "components/common-components/Form";
import { OptionSelector } from "components/common-components/Select";
import FullPageLoader from "components/loader/FullPageLoader";

import {
  pricingPrefDurationConst,
  pricingPrefDiscountConst,
  pricingPreferenceToolTip
} from "pages/auth/preferences/data";

import { getTutorUserPriceByUserId, updateTutorUserPriceByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import {
  setTutorUserPrice,
  setClearTutorUserPrice
} from "redux/tutor/tutor.slice";

const PricePreferenceHeader = () => {

  return (
    <div className={"py-3 space-y-3"}>
      <div className="flex justify-center w-full">
        <div className="flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"Pricing Preferences"}
          </span>
          <div className={"relative has-tooltip cursor-pointer"}>
            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            <span
              className={clsx(
                "w-56 px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip top-6.5",
                "flex flex-col items-start justify-start overflow-hidden"
              )}
            >
              {pricingPreferenceToolTip}
            </span>
          </div>
        </div>
      </div>
      <div className="h-0.5 bg-gray-300 w-full"></div>
    </div>
  );
}

export default function PricingPreferencesSettings() {
  const { user } = useAppState((s) => s.user)
  const { tutorUserPrice } = useAppState((s) => s.tutor)
  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(getTutorUserPriceByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearTutorUserPrice())
    }
  }, [])

  const generatePricingPref = async () => {
    const pricing = {
      trialCourseDuration: tutorUserPrice?.tutorUserPrice?.trialCourseDuration,
      minTrialCoursePrice: tutorUserPrice?.tutorUserPrice?.minTrialCoursePrice,
      minOneToOneCoursePrice: tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice,
      minGroupCoursePrice: tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice,
      pctDisOnFiveOneToOneSession: tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession
    }
    return pricing
  }

  const onConfirm = async () => {
    const pricingPreference = await generatePricingPref()
    dispatcher(updateTutorUserPriceByUserId(user?.user?.userId, pricingPreference))
  }

  if (tutorUserPrice?.isLoading) {
    return <FullPageLoader isLoading={tutorUserPrice?.isLoading} />
  }

  return (
    <div className="">
      <PricePreferenceHeader />
      {tutorUserPrice?.tutorUserPrice &&
        <div className="px-4 py-10 space-y-8">
          {/* Trail lesson - Time and Duration */}
          <div>
            <div className="flex space-x-1">
              <FaDollarSign size={20} />
              <p className="text-base font-bold tracking-wide">
                {"Trial Session"}
              </p>
            </div>
            <div className="flex my-4 ml-5 space-x-4">
              <div className="w-52">
                <p className="text-sm font-bold">
                  {"Duration"}
                </p>
                <OptionSelector
                  onChange={(option) => {
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      trialCourseDuration: option.value
                    }))
                  }}
                  options={Object.values(pricingPrefDurationConst)}
                  value={tutorUserPrice?.tutorUserPrice?.trialCourseDuration}
                />
              </div>
              <div>
                <p className="text-sm font-bold">
                  {"Price"}
                </p>
                <InputFieldWithPrefix
                  onChange={(e) => {
                    let safe_number = Number(e.target.value)
                    if (isNaN(safe_number)) {
                      safe_number = 0
                    }
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      minTrialCoursePrice: (safe_number * 100)
                    }))
                  }}
                  placeholder="0.0"
                  prefix="USD"
                  value={(tutorUserPrice?.tutorUserPrice?.minTrialCoursePrice / 100)?.toString()}
                />
              </div>
            </div>
          </div>

          {/* Minimum hoursly charge - 1-to-1 */}
          <div>
            <div className="flex space-x-1">
              <FaDollarSign size={20} />
              <p className="text-base font-bold tracking-wide">
                {"Minimum hourly charges for 1 to 1 Course"}
              </p>
            </div>
            <div className="flex my-4 ml-5 space-x-4">
              <div>
                <p className="text-sm font-bold">Price</p>
                <InputFieldWithPrefix
                  onChange={(e) => {
                    let safe_number = Number(e.target.value)
                    if (isNaN(safe_number)) {
                      safe_number = 0
                    }
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      minOneToOneCoursePrice: (safe_number * 100)
                    }))
                  }}
                  placeholder="0.0"
                  prefix="USD"
                  value={(tutorUserPrice?.tutorUserPrice?.minOneToOneCoursePrice / 100)?.toString()}
                />
              </div>
            </div>
          </div>

          {/* Minimum hoursly charge - group session */}
          <div>
            <div className="flex space-x-1">
              <FaDollarSign size={20} />
              <p className="text-base font-bold tracking-wide">
                {"Minimum hourly charges for group sessions"}
              </p>
            </div>
            <div className="flex my-4 ml-5 space-x-4">
              <div>
                <p className="text-sm font-bold">Price</p>
                <InputFieldWithPrefix
                  onChange={(e) => {
                    let safe_number = Number(e.target.value)
                    if (isNaN(safe_number)) {
                      safe_number = 0
                    }
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      minGroupCoursePrice: (safe_number * 100)
                    }))
                  }}
                  placeholder="0.0"
                  prefix={"USD"}
                  value={(tutorUserPrice?.tutorUserPrice?.minGroupCoursePrice / 100)?.toString()}
                />
              </div>
            </div>
          </div>

          {/* % discount */}
          <div>
            <div className="flex space-x-1">
              <FaPercent size={15} />
              <p className="px-2 text-base font-bold tracking-wide">
                {"Offer discount to students who book more than 5 1-to-1 lessons"}
              </p>
            </div>
            <div className="flex my-4 ml-5 space-x-4">
              <div className="w-52">
                <p className="text-sm font-bold">
                  {"Discount"}
                </p>
                <OptionSelector
                  onChange={(e) => {
                    dispatcher(setTutorUserPrice({
                      ...tutorUserPrice?.tutorUserPrice,
                      pctDisOnFiveOneToOneSession: Number(e.value)
                    }))
                  }}
                  options={Object.values(pricingPrefDiscountConst)}
                  value={tutorUserPrice?.tutorUserPrice?.pctDisOnFiveOneToOneSession}
                />
              </div>
            </div>
          </div>
        </div>
      }
      {tutorUserPrice?.errorMsg &&
        <div className={"w-full h-full flex justify-center items-center p-5 font-bodyPri font-semibold text-text-800 text-md"}>
          {tutorUserPrice?.errorMsg}
        </div>
      }

      <div className="border-t shadow-sm">
        <div className="flex justify-center py-6 space-x-4">
          <Button name="Cancel" style="outline" />
          <Button name="Save" style="primary" onClick={onConfirm} />
        </div>
      </div>
    </div>
  );
}
