import clsx from "clsx"

import { FiEdit } from "react-icons/fi"
import { RiDeleteBinLine } from "react-icons/ri"

import { onBoardingStatus } from 'pages/auth/tutorOnboard/TutorOnboardData';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setAddReferencePayload, setClearAddReference, setReferenceList } from "redux/tutorOnboarding/tutorOnboarding.slice";
import { modalConst } from "redux/local/local.const";

const ReferenceListItems = ({ task, reference }) => {
    const { modal } = useAppState((s) => s.local)
    const { referenceList } = useAppState((s) => s.tutorOnboarding)
    const dispatcher = useAppDispatcher()

    const handleEditReference = (referenceId, refStatus) => {
        if (refStatus === onBoardingStatus.SUBMITTED.value) {
            const filteredData = referenceList?.referenceList?.find((items) => items.referenceId === referenceId)
            dispatcher(setClearAddReference())
            dispatcher(setAddReferencePayload({
                ...filteredData,
                isFormSubmitted: false,
                referenceStatus: onBoardingStatus.NOT_SUBMITTED.value
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.REFERENCE_FORM_MODAL.stateKey]: true
            }))
        }
    }

    const handleDeleteReference = (referenceId, refStatus) => {
        if (refStatus === onBoardingStatus.SUBMITTED.value) {
            const filteredList = referenceList?.referenceList?.filter((items) => items.referenceId !== referenceId)
            if (filteredList?.length > 0) {
                dispatcher(setReferenceList(filteredList))
            }
            if (filteredList?.length <= 0) {
                task.status = onBoardingStatus.NOT_SUBMITTED.value
                dispatcher(setReferenceList(filteredList))
            }
        }
    }

    const handleOnSendEmail = (referenceId, emailSent) => {
        if (!emailSent) {
            let filterReference = referenceList?.referenceList?.find((item) => item.referenceId === referenceId)
            let modifiedReference = { ...filterReference, isEmailSent: true, referenceStatus: onBoardingStatus.UNDER_REVIEW.value }
            let filteredReferenceList = referenceList?.referenceList?.filter((items) => items.referenceId !== referenceId)

            dispatcher(setReferenceList([...filteredReferenceList, modifiedReference]))
            alert("Email send Successfully!")
        }
    }

    return (
        <div className=" p-5 border-2 border-text-300 rounded-xl w-full flex flex-col justify-between space-y-3">
            <div className="space-y-2">
                <div className="font-bodyPri font-bold text-text-700 text-md text-center">
                    {"Reference " + reference?.referenceId}
                </div>
                <div className="space-y-2">

                    {/* Reference Name */}
                    <div className="flex items-start justify-start gap-2">
                        <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">
                            {"Reference Name: "}
                        </span>
                        <span className="font-bodyPri font-medium text-text-900 text-sm">
                            {reference?.referenceName}
                        </span>
                    </div>

                    {/* Reference RelationShip */}
                    <div className="flex items-start justify-start gap-2">
                        <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">
                            {"Reference Relationship: "}
                        </span>
                        <span className="font-bodyPri font-medium text-text-900 text-sm">
                            {reference?.referenceRelationship}
                        </span>
                    </div>

                    {/* Reference Years and Months */}
                    <div className="flex flex-col items-start justify-start gap-0.5">
                        <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">
                            {"How many years you know them"}
                        </span>
                        <div className='ml-5 flex items-center gap-2'>
                            <span className='flex items-center gap-2'>
                                <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">{"Years:"}</span>
                                <span className="font-bodyPri font-medium text-text-900 text-sm">{reference?.knownYears}</span>
                            </span>
                            <span className='flex items-center gap-2'>
                                <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">{"Months:"}</span>
                                <span className="font-bodyPri font-medium text-text-900 text-sm">{reference?.knownMonths}</span>
                            </span>
                        </div>
                    </div>

                    {/* Mobile number */}
                    <div className="flex items-start justify-start gap-2">
                        <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">
                            {"Phone: "}
                        </span>
                        <span className="font-bodyPri font-medium text-text-900 text-sm">
                            {reference?.referenceMobile}
                        </span>
                    </div>

                    {/* Email */}
                    <div className="flex items-start justify-start gap-2">
                        <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">
                            {"Email: "}
                        </span>
                        <span className="font-bodyPri font-medium text-text-900 text-sm">
                            {reference?.referenceEmail}
                        </span>
                    </div>

                    {/* Status */}
                    <div className="flex items-start gap-2">
                        <span className="font-bodyPri font-normal text-sm text-text-800 whitespace-nowrap">
                            {"Status: "}
                        </span>
                        {Object.keys(onBoardingStatus).map((item) => onBoardingStatus[item].value === reference?.referenceStatus &&
                            <span className={clsx("font-bodyPri font-medium text-text-900 text-sm",
                                onBoardingStatus[item].color
                            )}>
                                {onBoardingStatus[item].label}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex justify-between">
                {/* send button */}
                <span className={clsx(
                    "px-2 py-0.5 rounded-md font-bodyPri font-medium text-md tracking-wide italic",
                    reference?.isEmailSent && "text-text-300 border border-text-500",
                    !reference?.isEmailSent && "cursor-pointer border-2 border-primary-main text-primary-main hover:text-primary-dark hover:border-primary-dark"
                )}
                    onClick={() => handleOnSendEmail(reference?.referenceId, reference?.isEmailSent)}
                >
                    {!reference?.isEmailSent ? "Send Reference Email Now" : "Email Sent"}
                </span>

                {/*  */}
                <span className='flex justify-between gap-5'>
                    <FiEdit
                        className={clsx("text-xl",
                            reference?.referenceStatus === onBoardingStatus?.SUBMITTED.value && "text-primary-main cursor-pointer hover:text-primary-dark",
                            reference?.referenceStatus === onBoardingStatus?.UNDER_REVIEW.value && "text-text-300"
                        )}
                        onClick={() => handleEditReference(reference?.referenceId, reference?.referenceStatus)}
                    />
                    <RiDeleteBinLine
                        className={clsx("text-xl",
                            reference?.referenceStatus === onBoardingStatus?.SUBMITTED.value && "text-primary-main cursor-pointer hover:text-primary-dark",
                            reference?.referenceStatus === onBoardingStatus?.UNDER_REVIEW.value && "text-text-300"

                        )}
                        onClick={() => handleDeleteReference(reference?.referenceId, reference?.referenceStatus)}
                    />
                </span>
            </div>
        </div>
    )
}

export default ReferenceListItems