import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { IoIosArrowBack } from "react-icons/io";

import ProgressBar from 'components/modals/needATutorModal/ProgressBar';
import { viewContainerConst } from 'components/modals/needATutorModal/data';

import Level from 'components/modals/needATutorModal/Level';
import Subject from 'components/modals/needATutorModal/Subject';
import Name from 'components/modals/needATutorModal/Name';
import Email from 'components/modals/needATutorModal/Email';
import VerifyCode from 'components/modals/needATutorModal/VerifyCode';
import Phone from 'components/modals/needATutorModal/Phone';
import City from 'components/modals/needATutorModal/City';
import ContactTime from 'components/modals/needATutorModal/ContactTime';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearSendOtp, setClearVerifyOtp } from 'redux/notification/notification.slice';
import { setClearNeedTutorPayload, setNeedTutorPayload } from 'redux/needTutor/needTutor.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { pagesInfo } from "utils/pagesInfo";

const totalStepCount = 8

const NeedATutorModal = () => {
    const { logInSignUp } = useAppState(s => s.auth)
    const { user } = useAppState((state) => state.user)
    const { modal } = useAppState((state) => state.local)
    const { needTutor } = useAppState((state) => state.needTutor)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [stepCount, setStepCount] = useState(1)
    const [viewContainer, setViewContainer] = useState(viewContainerConst.LEVEL.value)

    useEffect(() => {
        return () => {
            dispatcher(setClearNeedTutorPayload())
        }
    }, [])

    const handleBackButton = () => {
        if (viewContainer === viewContainerConst.SUBJECT.value) {
            setStepCount(1)
            setViewContainer(viewContainerConst.LEVEL.value)
        }
        if (viewContainer === viewContainerConst.NAME.value) {
            setStepCount(2)
            setViewContainer(viewContainerConst.SUBJECT.value)
        }
        if (viewContainer === viewContainerConst.EMAIL.value) {
            setStepCount(3)
            setViewContainer(viewContainerConst.NAME.value)
        }
        if (viewContainer === viewContainerConst.VERIFY_CODE.value) {
            dispatcher(setClearSendOtp())
            dispatcher(setClearVerifyOtp())
            dispatcher(setNeedTutorPayload({
                ...needTutor?.needTutorPayload,
                isEmailVerified: false
            }))
            setStepCount(4)
            setViewContainer(viewContainerConst.EMAIL.value)
        }
        if (viewContainer === viewContainerConst.PHONE.value) {
            if (logInSignUp.isLogedIn) {
                setStepCount(2)
                setViewContainer(viewContainerConst.SUBJECT.value)
            }
            if (!logInSignUp.isLogedIn) {
                setStepCount(4)
                setViewContainer(viewContainerConst.EMAIL.value)
            }
        }
        if (viewContainer === viewContainerConst.CITY.value) {
            if (!user?.user?.mobileNo) {
                setStepCount(6)
                setViewContainer(viewContainerConst.PHONE.value)
            }
            if (user?.user?.mobileNo) {
                setStepCount(2)
                setViewContainer(viewContainerConst.SUBJECT.value)
            }
        }
        if (viewContainer === viewContainerConst.CONTACT_TIME.value) {
            setStepCount(7)
            setViewContainer(viewContainerConst.CITY.value)
        }
    }

    const onHandleDashboardButton = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.NEED_A_TUTOR_MODAL.stateKey]: false
        }))
        navigate(pagesInfo.DASHBOARD.pagePath)
    }


    return (
        <div className={"space-y-8 overflow-x-hidden"}>
            <div className={'min-h-[24rem] block space-y-10'}>
                <div className={"w-full"}>
                    <ProgressBar
                        pctValue={Math.floor(parseFloat((stepCount / totalStepCount * 100)))}
                    />
                </div>

                {viewContainer === viewContainerConst.LEVEL.value &&
                    <Level
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {viewContainer === viewContainerConst.SUBJECT.value &&
                    <Subject
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {viewContainer === viewContainerConst.NAME.value &&
                    <Name
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {viewContainer === viewContainerConst.EMAIL.value &&
                    <Email
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {viewContainer === viewContainerConst.VERIFY_CODE.value &&
                    <VerifyCode
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {(viewContainer === viewContainerConst.PHONE.value && !user?.user?.mobileNo) &&
                    <Phone
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {viewContainer === viewContainerConst.CITY.value &&
                    <City
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
                {viewContainer === viewContainerConst.CONTACT_TIME.value &&
                    <ContactTime
                        setStepCount={setStepCount}
                        setViewContainer={setViewContainer}
                    />
                }
            </div>
            {viewContainer !== viewContainerConst.LEVEL.value &&
                <div className={"w-full flex items-center justify-between px-5"}>
                    <div
                        className={"flex justify-start gap-0.5 text-primary-dark cursor-pointer"}
                        onClick={handleBackButton}
                    >
                        <IoIosArrowBack className={"text-2xl"} />
                        <span className={"font-buttons font-medium text-base"}>
                            {"Back"}
                        </span>
                    </div>
                    {viewContainer === viewContainerConst.CONTACT_TIME.value &&
                        <div className={"font-bodyPri font-medium text-primary-dark text-lg cursor-pointer"}
                            onClick={onHandleDashboardButton}
                        >
                            <span className={""}>
                                {"Your Dashboard"}
                            </span>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default NeedATutorModal