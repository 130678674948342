import { useEffect } from 'react';
import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { PageContentStyle} from './style';

import { FAQSection } from "pages/auth/subscriptions/components/FAQs";
import { GiftCardUsageCard } from "./components/GiftCardUsageCard";
import { PurchaseGiftCard } from "./PurchaseGiftCard";

const faqs = [
  {
    question: "What is the gift card?",
    answer:
      "A gift card is a prepaid card that you can use to purchase a course or a subscription.",
  },
  {
    question: "How do I use the gift card?",
    answer: "You can use the gift card to purchase a course or a subscription. You can also use the gift card to buy a course or a subscription for someone else.",
  },
];

const GiftCardFeature = () => {
  return (
    <div className="max-w-5xl mx-auto">
      <h1 className="text-3xl font-bold text-center text-sky-600">
        Send your friends and family valuable and amazing learning experience
      </h1>
      <div className="py-7">
        <GiftCardUsageCard />
      </div>
      <div className="my-10">
        <PurchaseGiftCard />
      </div>
      <div className="p-3 bg-white">
        <FAQSection faqs={faqs} />
      </div>
    </div>
  );
};

export default function BuyGiftCardPage() {
  const dispatcher = useAppDispatcher()
  const { currentPageInfo } = useAppState((state: any) => state.pageInfo)

  useEffect(() => {
      dispatcher(setPageInfo(currentPageInfo, pagesInfo.BUY_GIFT_CARD))
  }, [dispatcher, currentPageInfo])
  return (
    <PageContentStyle>
        <GiftCardFeature />
    </PageContentStyle>
  )
}
