import { useEffect, useState } from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { pageHeading, navigationOptions } from 'pages/auth/preferences/data';

import PageHeader from "components/pageHeader/PageHeader";
import PreferencesNavigation from "./components/PreferencesNavigation";


const PreferencesPage = ({ allowedRoles }) => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)
  const { logInSignUp } = useAppState((s) => s.auth)
  const { user } = useAppState((s) => s.user)

  const [tabList, setTabList] = useState(navigationOptions)

  const dispatcher = useAppDispatcher()
  const location = useLocation()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.PREFERENCES))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    const filteredOptions = navigationOptions.filter((option) => option.roles.includes(locals.userRole))
    setTabList(filteredOptions)
  }, [locals.userRole])

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 ">
        <PreferencesNavigation options={tabList} />
        <div className="w-full rounded-lg overflow-hidden p-5 bg-white">
          {logInSignUp?.isLogedIn && user?.user?.roles?.find(role => allowedRoles?.includes(role.role) && role.role === locals.userRole)
            ? <Outlet />
            : <Navigate to={pagesInfo.UNAUTHORIZED.pagePath} state={{ from: location }} replace />
          }
        </div>
      </div>
    </div>
  )
};


export default PreferencesPage;
