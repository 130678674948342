import { useEffect } from 'react';
import dayjs from 'dayjs';

import TodosHeader from 'pages/auth/schedule/todos/TodosHeader';
import TodoItem from "pages/auth/schedule/todos/TodoItem";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { getUserTodoList } from 'redux/todo/todo.request';
import { setClearTodoList } from 'redux/todo/todo.slice';
import ComponentLoader from 'components/loader/ComponentLoader';


const Todos = () => {
    const { todoList } = useAppState((s) => s.todo);

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getUserTodoList())

        return () => {
            dispatcher(setClearTodoList())
        }
    }, [])

    const sortTodoList = (todo1, todo2) => {
        let dateTime1 = dayjs(todo1?.updatedAt)
        let dateTime2 = dayjs(todo2?.updatedAt)
        return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
    }

    return (
        <div className={"space-y-3"}>
            <TodosHeader/>
            <div className={"p-5 bg-white rounded-lg grid grid-cols-1 md:grid-cols-2 gap-3"}>
                <ComponentLoader isLoading={todoList?.isLoading}/>
                {todoList?.filteredTodoList?.slice(0)?.sort(sortTodoList)?.map((todoItem, index) => (
                    <TodoItem key={index} todoItem={todoItem} id={"todoSection"} />
                ))}
                {(!todoList?.filteredTodoList || todoList?.filteredTodoList.length === 0) &&
                    <span className={"col-span-2 font-bodySec font-medium text-xl text-text-600 text-center"}>
                        {"No Todo Available! \n Please Add a todo!"}
                    </span>
                }
            </div>

        </div>
    );
};

export default Todos;
