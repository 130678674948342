import React, { useEffect, useState } from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//component imports
import SearchChats from "../SearchChats/SearchChats";
import ChatList from "../ChatList/ChatList";
import ChatListContainerHeader from "../ChatListContainerHeader/ChatListContainerHeader";
import ChatFilterModal from "../../components/ChatFilterModal/ChatFilterModal";
import ChatFilter from "../ChatFilter/ChatFilter";

//Icon imports
import { RefreshIcon } from "@heroicons/react/outline";

//style imports
import {
  StyledChatListContainer,
  StyledModalContainer,
  ChatListContainerLoader,
} from "./ChatListContainer.styles";

export default function ChatListContainer() {
  const { filters, chats, loadingChats } = useChat();
  const { user } = useUser();
  const [chatsToDisplay, setChatsToDisplay] = useState([]);
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const { userId } = user;

  useEffect(() => {
    if (chats.length === 0) return;
    const filteredChats = chats.filter((chat) => {
      if (filters.length === 0) return true;
      if (filters.length === 2) {
        const { lastMessage } = chat;
        const isSender = lastMessage.sender === userId;
        if (isSender) return false;
        const qualifiesCategory =
          chat.type === filters[0] || chat.type === filters[1];
        const qualifiesStatus =
          lastMessage.status === filters[0] ||
          lastMessage.status === filters[1];
        return qualifiesCategory && qualifiesStatus;
      }
      return filters.every((filter) => {
        const { lastMessage } = chat;
        const notSender = lastMessage.sender !== userId;
        const passesFilter = notSender && lastMessage.status === filter;
        return chat.type === filter || passesFilter;
      });
    });
    setChatsToDisplay(filteredChats);
  }, [chats, filters.length, filters, userId]);

  //display filter chats component
  const showFilters = filters.length >= 1;

  //Close filter modal when clicked outside of modal
  function closeChatFilterModal() {
    setShowFiltersModal(false);
  }

  function searchChat(e) {
    const searchParam = e.target.value.toLowerCase();
    if (searchParam.trim() === "") {
      setChatsToDisplay(chats);
      return;
    }
    const filteredChats = chats.filter((chat) => {
      if (chat.type === "group") {
        return chat.name.toLowerCase().includes(searchParam);
      } else {
        const otherUserId = chat.users.filter(
          (chatUser) => chatUser !== userId
        )[0];
        const { name } = chat[otherUserId];
        return name.toLowerCase().includes(searchParam);
      }
    });
    setChatsToDisplay(filteredChats);
  }

  return (
    <StyledChatListContainer>
      <ChatListContainerHeader setShowFiltersModal={setShowFiltersModal} />
      <SearchChats onChange={searchChat} />
      {loadingChats && (
        <ChatListContainerLoader>
          <RefreshIcon className="h-10 w-10 text-gray-500 animate-spin" />
        </ChatListContainerLoader>
      )}
      {!loadingChats && (
        <>
          {showFilters && <ChatFilter />}
          {showFiltersModal && (
            <>
              <StyledModalContainer
                onClick={closeChatFilterModal}
              ></StyledModalContainer>
              <ChatFilterModal setShowFiltersModal={setShowFiltersModal} />
            </>
          )}
          <ChatList chatsToDisplay={chatsToDisplay} />
        </>
      )}
    </StyledChatListContainer>
  );
}
