export const url = {
    GET_USER_LANGUAGE: "/culture/user/language/list",
}

export const USER_LANGUAGE_INITIAL_STATE = {
    userLanguage: {
        isLoading: false,
        userLanguage:null,
        errorMsg: null
    }
}