import { useState } from "react";
import clsx from "clsx";

import { FaFacebook, FaTwitter } from "react-icons/fa";

import MySocialMediaPresence from "pages/auth/settings/components/socialSettings/MySocialMediaPresence";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

export default function SocialSettings() {
  const { modal } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()

  const [editBtnConst, setEditBtnConst] = useState(null)

  const handleSocialShare = () => {
    dispatcher(setModal({
      ...modal,
      [modalConst.COMING_SOON_MODAL.stateKey]: true
    }))
  }

  return (
    <div className="">
      <MySocialMediaPresence
        editBtnConst={editBtnConst}
        setEditBtnConst={setEditBtnConst}
      />
      <div className="py-4 mx-8 border-b">
        <p className="text-lg text-center">{"Coming Soon"}</p>
      </div>

      <div className="my-3">
        <p className="max-w-lg mx-auto text-sm text-center text-slate-700">
          You are control of your social media accounts, we never post on your
          behalf.
        </p>
        <p className="max-w-md mx-auto my-6 font-semibold text-center">
          Easily share update, lessons, and benefit from the power of social
          network.
        </p>

        <div className="w-full mx-auto space-y-4 md:max-w-sm">
          <button
            className={clsx(
              "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
              "relative  bg-[#4267B2] rounded-lg px-14 hover:bg-opacity-90",
              "font-semibold  whitespace-nowrap text-white rounded-md"
            )}
            onClick={handleSocialShare}
          >
            <span className="absolute left-4">
              <FaFacebook size={25} className="text-white" />
            </span>
            Connect with facebook
          </button>
          <button
            className={clsx(
              "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
              "relative  bg-[#1DA1F2] rounded-lg px-14 hover:bg-opacity-90",
              "font-semibold  whitespace-nowrap text-white rounded-md"
            )}
            onClick={handleSocialShare}
          >
            <span className="absolute left-4">
              <FaTwitter size={25} className="text-white" />
            </span>
            Connect with Twitter
          </button>
          <button
            className={clsx(
              "flex w-full justify-center items-center px-3 py-3 space-x-2 rounded-sm",
              "relative  bg-[#0e76a8] rounded-lg px-14 hover:bg-opacity-90",
              "font-semibold  whitespace-nowrap text-white rounded-md"
            )}
            onClick={handleSocialShare}
          >
            <span className="absolute left-4">
              <FaTwitter size={25} className="text-white" />
            </span>
            Connect with LinkedIn
          </button>
        </div>
      </div>
    </div>
  );
}
