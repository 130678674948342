import clsx from "clsx";
import * as React from "react";
import { FaCheckCircle } from "react-icons/fa";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Button } from "components/common-components/Buttons";

import { pagesInfo } from "utils/pagesInfo";

import { DOMAIN_NAME } from "const/default.const";

import { useAppState } from "hooks/useStore";

function InputWithCopyTraillingButton({
  buttonText,
  link,
}: {
  link: string;
  buttonText: string;
}) {
  const { user } = useAppState((s) => s.user)
  const [copySuccess, setCopySuccess] = React.useState(false);

  const referralUrl = `${DOMAIN_NAME}${pagesInfo.REFERRAL_SIGN_UP.pagePath}/${user?.user?.referralCode}`

  const onCopy = () => {
    navigator.clipboard.writeText(referralUrl);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };
  return (
    <div className="inline-flex shadow-sm">
      <input
        type="text"
        className={clsx(
          "flex-1 px-3 py-2 border outline-none focus:border-lime-100",
          "border-sky-200"
        )}
        placeholder="Link"
        value={link}
        disabled={true}
      />
      <div>
        {copySuccess ? (
          <div className="inline-flex items-center justify-center p-2 py-2 bg-primary-light">
            <FaCheckCircle size={20} className="text-lime-500" />
            <p className="w-32 h-full font-semibold text-lime-500">Link Copied!</p>
          </div>
        ) : (
          <button
            className="w-32 h-full font-buttons font-normal text-base bg-primary-light text-primary-main"
            onClick={onCopy}
          >
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
}

export default function ReferalCard() {
  const { user } = useAppState((s) => s.user)
  
  const referralUrl = `${DOMAIN_NAME}${pagesInfo.REFERRAL_SIGN_UP.pagePath}/${user?.user?.referralCode}`

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-lg font-semibold text-gray-800">
          Refer a friend and get rewarded
        </p>
        <div>
          <Button name="Learn more" style="outline" size="small" />
        </div>
      </div>

      <div className="flex flex-col py-6 space-y-3 text-slate-700">
        <InputWithCopyTraillingButton
          buttonText="Copy Link"
          link={referralUrl}
        />
        <InputWithCopyTraillingButton
          buttonText="Copy Code"
          link={user?.user?.referralCode}
        />
      </div>

      <div className="flex justify-between">
        <p className="text-sky-500">Share via</p>
        <div className="flex space-x-2">
          <FacebookShareButton url={referralUrl}>
            <FacebookIcon size={30} />
          </FacebookShareButton>
          <WhatsappShareButton url={referralUrl}>
            <WhatsappIcon size={30} />
          </WhatsappShareButton>
          <LinkedinShareButton url={referralUrl}>
            <LinkedinIcon size={30} />
          </LinkedinShareButton>
          <TwitterShareButton url={referralUrl}>
            <TwitterIcon size={30} />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
}
