import clsx from 'clsx';
import { useEffect, useReducer, useState } from 'react';

import { BsCheckAll } from "react-icons/bs";
import { FaSpinner } from 'react-icons/fa';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';

import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import {
    Container,
    BoxSection,
    NameSection,
    InputBox,
    MobileBoxSection,
    ReferralBox,
    ReferralText,
    TextMessage,
    ErrorText,
    SignUpButton,
} from './SignUpFormStyle'

import { createVerifyReferral } from "redux/auth/auth.request";
import { getMasterCountryList, getTimeZoneMasterCountryDetailByTimeZone } from 'redux/master/master.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setSignUpPayload, setClearVerifyReferral } from "redux/auth/auth.slice";
import { setClearCountryList, setClearTimeZoneCountryDetail } from 'redux/master/master.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { validateEmail, validateMobileNo } from 'utils/validation-functions';
import { timeZone } from "utils/dateTime.utils";

const formReducer = (state, event) => {
    return {
        ...state,
        [event.name]: event.value,
    }
}

const SignUpForm = ({ formSubmit }) => {
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { modal } = useAppState(s => s.local)
    const { logInSignUp, verifyReferral } = useAppState((s) => s.auth)

    const dispatcher = useAppDispatcher()

    const [formData, setFromData] = useReducer(formReducer, {});
    const [emailErrorText, setEmailErrorText] = useState('')
    const [mobileErrorText, setMobileErrorText] = useState('')
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
    const [referralCode, setReferralCode] = useState('')
    const [openReferralMsgBox, setOpenReferralMsgBox] = useState(false)
    const [countryCode, setCountryCode] = useState("")
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })

    useEffect(() => {
        dispatcher(getTimeZoneMasterCountryDetailByTimeZone(timeZone))
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearTimeZoneCountryDetail())
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        const { email, mobileNo } = formData;

        if (email && !validateEmail(email)) setEmailErrorText('Enter valid Email!');
        else setEmailErrorText('');

        if (mobileNo && !validateMobileNo(mobileNo)) setMobileErrorText('Enter valid MobileNo!');
        else setMobileErrorText('');

        const isDisabled = (emailErrorText.length === 0 || mobileErrorText.length === 0) && !(formData.firstName && formData.lastName && (formData.email || formData.mobileNo))
        setIsSubmitDisabled(isDisabled)

    }, [formData])

    useEffect(() => {
        if (logInSignUp?.signUpPayload?.referralCode) {
            dispatcher(createVerifyReferral(logInSignUp?.signUpPayload?.referralCode))
            setOpenReferralMsgBox(true)
            setReferralCode(logInSignUp?.signUpPayload?.referralCode)
        }

        return () => {
            dispatcher(setClearVerifyReferral())
        }
    }, [logInSignUp?.signUpPayload?.referralCode])


    const submitForm = (event) => {
        event.preventDefault();
        dispatcher(setSignUpPayload({
            ...logInSignUp?.signUpPayload,
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            email: formData?.email,
            countryCode: formData?.countryCode,
            mobileNo: countryCode + "-" + formData?.mobileNo
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.CODE_VERIFICATION_MODAL.stateKey]: true
        }))
        // formSubmit(formData);
    }

    const handleOnChange = (event) => {
        setFromData({
            name: event.target.name,
            value: event.target.value,
        })
    }

    const handleReferralModal = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.APPLY_REFERRAL_MODAL.stateKey]: true
        }))
        dispatcher(setSignUpPayload({
            ...logInSignUp?.signUpPayload,
            referralCode: referralCode
        }))
    }

    const handleDeleteReferralCode = () => {
        setReferralCode("")
        dispatcher(setSignUpPayload({
            ...logInSignUp?.signUpPayload,
            referralCode: ""
        }))
        setOpenReferralMsgBox(false)
    }

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    return (
        <Container onSubmit={submitForm}>
            <NameSection>
                <BoxSection>
                    <InputBox type='text' name='firstName' placeholder='First Name *' onChange={handleOnChange} autoFocus={true} required />
                </BoxSection>
                <BoxSection>
                    <InputBox type='text' name='lastName' placeholder='Last Name *' onChange={handleOnChange} required />
                </BoxSection>
            </NameSection>
            <BoxSection>
                <InputBox type='email' name='email' placeholder='Enter your email *' onChange={handleOnChange} required />
                {formData.email && emailErrorText.length === 0 && <BsCheckAll className='text-green-500' />}
                {formData.email && emailErrorText.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
            </BoxSection>
            <MobileBoxSection>
                {countryList?.isLoading &&
                    <FaSpinner className={"text-lg text-primary-main animate-spin"} />
                }
                {countryList?.countryList &&
                    <IsdCodeSelector
                        options={countryList?.countryList?.map((country) => ({
                            label: country?.isdCode,
                            value: country?.masterCountryId,
                            country: country
                        }))}
                        value={myCountry?.masterCountryId}
                        onChange={(option) => onSelectIsdCode(option)}
                    />
                }
                <InputBox className={"input-number-spin-none"} type='number' name='mobileNo' placeholder='Your mobile no. (optional)' onChange={handleOnChange} />
                {formData.mobileNo && mobileErrorText.length === 0 && <BsCheckAll className='text-green-500' />}
                {formData.mobileNo && mobileErrorText.length > 0 && <MdCancel className=' text-base font-bodyPri text-red-800' />}
            </MobileBoxSection>
            {!openReferralMsgBox &&
                <BoxSection onClick={handleReferralModal}>
                    <ReferralBox>
                        <LocalOfferOutlinedIcon className='text-primary-main hover:text-secondary-dark' />
                        <ReferralText>{"Apply Referral"}</ReferralText>
                    </ReferralBox>
                    <IoIosArrowForward className='text-2xl cursor-pointer text-text-500' />
                </BoxSection>
            }
            {(openReferralMsgBox) &&
                <BoxSection>
                    {verifyReferral?.isLoading && <FaSpinner className='animate-spin text-lg text-primary-main' />}
                    {(!verifyReferral?.isLoading && logInSignUp?.signUpPayload?.referralCode) &&
                        <ReferralBox onClick={handleReferralModal}>
                            <LocalOfferOutlinedIcon className={clsx(
                                verifyReferral?.verifyReferral?.referralCode === logInSignUp?.signUpPayload?.referralCode && 'text-green-500',
                                verifyReferral?.verifyReferral?.referralCode !== logInSignUp?.signUpPayload?.referralCode && 'text-red-500'
                            )} />
                            <TextMessage className={clsx(
                                "text-sm font-bodyPri font-normal tracking-wide cursor-pointer",
                                verifyReferral?.verifyReferral?.referralCode === logInSignUp?.signUpPayload?.referralCode && "text-green-500",
                                verifyReferral?.verifyReferral?.referralCode !== logInSignUp?.signUpPayload?.referralCode && "text-red-500"
                            )}>
                                {logInSignUp?.signUpPayload?.referralCode + " , "}
                                {verifyReferral?.verifyReferral?.referralCode === logInSignUp?.signUpPayload?.referralCode &&
                                    <TextMessage>
                                        {"Code Applied"}
                                        <BsCheckAll className='text-green-500' />
                                    </TextMessage>
                                }
                                {verifyReferral?.verifyReferral?.referralCode !== logInSignUp?.signUpPayload?.referralCode &&
                                    <TextMessage>
                                        {"Invalid Referral Code!"}
                                        <MdCancel className='text-red-800' />
                                    </TextMessage>
                                }
                            </TextMessage>
                        </ReferralBox>
                    }
                    <RiDeleteBinLine
                        className='text-2xl text-red-500 cursor-pointer'
                        onClick={handleDeleteReferralCode}
                    />
                </BoxSection>
            }
            <SignUpButton isDisabled={isSubmitDisabled} type={"submit"} aria-disabled="true">
                {'Sign up'}
            </SignUpButton>
        </Container>
    )
};

export default SignUpForm;
