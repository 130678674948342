import { Link } from 'react-router-dom';

import {
    Container,
    Content,
    TeachWithUsCard,
    TeachWithUsImage,
    CardHeader,
    Heading,
    SubHeading,
    CardFooter,
    AnchorLink,
} from './TeachWithUsHeroStyle';

import HDividerWithText from 'components/hDividerWithText/HDividerWithText';
import SignUpForm from 'components/signUpForm/SignUpForm';
import SocialAuth from 'components/socialAuth/SocialAuth';

import { useAppState } from 'hooks/useStore';

const teachWithUsHeroOneImageUrl = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/teach-with-us-hero-one.png"

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const TeachWithUsHero = () => {
    const { logInSignUp } = useAppState((s) => s.auth)

    return (
        <Container>
            <div className='-ml-[34rem] w-1/2 h-screen bg-primary-dark rounded-br-full hidden lg:block'>
            </div>
            {/* <img
                src={'https://edulyte-website-image.s3.ap-south-1.amazonaws.com/becomete.png'}
                alt='teach-with-us-hero'
            /> */}

            {!logInSignUp?.isLogedIn &&
                <Content>
                    <TeachWithUsCard>
                        <CardHeader>
                            <Heading>{'Apply to become a Teacher'}</Heading>
                            <SubHeading>
                                {'Be your own boss, share your knowledge anytime, anywhere'}
                            </SubHeading>
                        </CardHeader>
                        <SignUpForm />
                        <HDividerWithText text={'or continue with'} />
                        <SocialAuth />
                        <CardFooter>
                            {"By signing up, I agree to "}
                            <a
                                href={edulyteTermsOfService}
                                rel={"noreferrer"}
                                target={"_blank"}
                                className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                            >
                                {"Terms of service"}
                            </a>,
                            <AnchorLink to={'#'}>
                                {" Community Guidelines"}
                            </AnchorLink>
                            {" and "}
                            <a
                                href={edulytePrivacyPolicy}
                                rel={"noreferrer"}
                                target={"_blank"}
                                className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                            >
                                {"Privacy Policy"}
                            </a>
                        </CardFooter>
                    </TeachWithUsCard>
                    <TeachWithUsImage>
                        <img src={teachWithUsHeroOneImageUrl} alt={'teach-with-us-hero-one'} className={'w-full h-full object-contain'} />
                    </TeachWithUsImage>
                </Content>
            }
            {logInSignUp?.isLogedIn &&
                <div className='absolute z-30 w-full h-[80vh] grid grid-cols-12 place-items-center gap-3'>
                    <div className='pt-5 sm:pt-10 md:pt-16 col-start-3 col-span-full lg:col-start-2 lg:col-span-3 flex flex-col justify-end gap-16'>
                        <div className='relative w-48 h-12 flex justify-center items-center bg-secondary-main rounded'>
                            <Link to={"/tutor-onboard"} className='font-bodyPri font-normal text-text-50 text-xl tracking-wide'>
                                {"Become a Tutor"}
                            </Link>
                            <div className='z-[-100] absolute w-8 h-8 bg-secondary-main rounded -right-4 rotate-45'>
                            </div>
                        </div>
                        <div className='relative w-48 h-12 flex justify-center items-center bg-primary-main rounded'>
                            <Link to={"/dashboard"} className='font-bodyPri font-normal text-text-50 text-xl tracking-wide'>
                                {"Go to Dashboard"}
                            </Link>
                            <div className='z-[-100] absolute w-8 h-8 bg-primary-main rounded -right-4 rotate-45'>
                            </div>
                        </div>
                    </div>
                    <div className='col-start-3 col-span-full lg:col-start-6 lg:col-span-4 w-[22rem] h-[22rem] md:w-[36rem] md:h-[36rem] lg:h-[42rem] lg:w-[42rem] overflow-hidden'>
                        <img src={teachWithUsHeroOneImageUrl} alt={'teach-with-us-hero-one'} className={'w-full h-full object-contain'} />
                    </div>
                </div>
            }
        </Container>
    )
}

export default TeachWithUsHero;
