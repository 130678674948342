import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { viewContainerConst } from 'components/modals/needATutorModal/data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setNeedTutorPayload } from 'redux/needTutor/needTutor.slice';

const City = ({ setStepCount, setViewContainer }) => {
    const { user } = useAppState((state) => state.user)
    const { needTutor } = useAppState((state) => state.needTutor)

    const dispatcher = useAppDispatcher()

    const [city, setCity] = useState("")

    useEffect(() => {
        if (needTutor?.needTutorPayload?.city) {
            setCity(needTutor?.needTutorPayload?.city)
        }
    }, [needTutor?.needTutorPayload?.city])

    const onAddCity = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        dispatcher(setNeedTutorPayload({
            ...needTutor?.needTutorPayload,
            city: city?.trim()
        }))
        setStepCount(8)
        setViewContainer(viewContainerConst.CONTACT_TIME.value)
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                <span className={"capitalize"}>
                    {needTutor?.needTutorPayload?.firstName || user?.user?.firstName}
                </span>
                {", which city/locality are you from?"}
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                    {"Your City"}
                </span>
                <input
                    type={"text"}
                    placeholder={"for example Sydney..."}
                    className={clsx(
                        "w-1/2 p-3 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                        "font-bodyPri font-normal text-text-800 text-lg",
                        "placeholder:text-text-500 placeholder:text-lg"
                    )}
                    autoFocus={true}
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                    onKeyDown={onAddCity}
                />
            </div>
            <span
                className={clsx(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {"Continue"}
            </span>
        </div>
    )
}

export default City;