import clsx from 'clsx';

import { FaSpinner } from 'react-icons/fa';

import { eventNoteConst } from 'components/modals/eventActionModal/eventActionModal.data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import {
    acceptEventByEventId,
    declineEventByEventId,
    rescheduleEventByEventId,
    startEventByEventId,
    completeEventByEventId,
} from 'redux/event/event.request';
import { eventStatus, eventType } from 'redux/event/event.const';
import { courseType } from 'redux/course/course.const';
import { cancelBookingByBookingId, cancelBookingByCourseId } from 'redux/booking/booking.request';
import { userRoles } from 'redux/auth/auth.const';


const EventActionFooter = ({ selectedAction }) => {
    const { eventDetail, updateEvent } = useAppState(s => s.event)
    const { updateBooking } = useAppState(s => s.booking)

    const dispatcher = useAppDispatcher()

    const isSubmitDisable = eventDetail?.eventDetail?.status === selectedAction.value
        || (eventNoteConst[selectedAction.value.toUpperCase()].isNoteRequired
            && (updateEvent?.updateEventPayload?.note.length < 20
                || updateEvent?.updateEventPayload?.note.length > 100))

    const handleOnCancel = () => {
        if (eventDetail?.eventDetail?.eventType === eventType.COURSE.value) {
            if (eventDetail?.eventDetail?.role === userRoles.STUDENT.value ||
                (eventDetail?.eventDetail?.role === userRoles.TUTOR.value && eventDetail?.eventDetail?.course?.courseType === courseType.ONE_ON_ONE.value)) {
                if (!window.confirm("Are you sure? \nYour booking will be cancle.")) return;
                dispatcher(cancelBookingByBookingId(eventDetail?.eventDetail?.bookings[0].bookingId, { note: updateEvent?.updateEventPayload?.note }))
                return;
            }

            if (eventDetail?.eventDetail?.course?.courseType === courseType.GROUP.value) {
                if (eventDetail?.eventDetail?.role !== userRoles.TUTOR.value) {
                    alert("Only tutor will cancel group session.")
                    return;
                }
                if (!window.confirm("Are you sure? \nYour all booking of this course will be cancel.")) return;
                dispatcher(cancelBookingByCourseId(eventDetail?.eventDetail?.course?.courseId, { note: updateEvent?.updateEventPayload?.note }))
                return;
            }
        }
    }

    const handleSubmit = () => {
        if (isSubmitDisable) return;

        switch (selectedAction.value) {
            case eventStatus.PENDING.value:
                alert('Pending status is not changeable!')
                break;
            case eventStatus.DECLINED.value:
                if (!window.confirm("Are you sure?")) return;
                dispatcher(declineEventByEventId(eventDetail?.eventDetail?.eventId, { note: updateEvent?.updateEventPayload?.note }))
                break;
            case eventStatus.RESCHEDULED.value:
                alert('Reschedule event comming soon!')
                break;
            case eventStatus.SCHEDULED.value:
                dispatcher(acceptEventByEventId(eventDetail?.eventDetail?.eventId, { note: updateEvent?.updateEventPayload?.note }))
                break;
            case eventStatus.ON_GOING.value:
                dispatcher(startEventByEventId(eventDetail?.eventDetail?.eventId, { note: updateEvent?.updateEventPayload?.note }))
                break;
            case eventStatus.COMPLETED.value:
                dispatcher(completeEventByEventId(eventDetail?.eventDetail?.eventId, { note: updateEvent?.updateEventPayload?.note }))
                break;
            case eventStatus.CANCELLED.value:
                // alert('Cancel event comming soon!')
                handleOnCancel()
                break;
            default:
                alert("Something went wrong!")
        }
    }

    return (
        <div className={"flex justify-between"}>
            <div className={"flex items-center justify-center gap-2"}>
                {(updateEvent?.isLoading || updateBooking?.isLoading) &&
                    <>
                        <span className={"font-bodyPri font-normal text-base text-text-700"}>
                            {"Loading"}
                        </span>
                        <FaSpinner className="text-primary-main animate-spin" />
                    </>
                }
            </div>
            <span onClick={handleSubmit} className={clsx(
                "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                "font-buttons font-normal text-base text-secondary-main",
                "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                (isSubmitDisable || updateEvent?.isLoading || updateBooking?.isLoading) &&
                "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
            )}>
                {"Submit"}
            </span>
        </div>
    )
}

export default EventActionFooter