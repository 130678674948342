import {
    FeaturedItemContainer,
    ItemImage,
} from './CategoryFeaturedStyle';

const FeaturedItem = ({ item }) => {
    return (
        <FeaturedItemContainer>
            <ItemImage>
                <img src={item.imageUrl} alt={"category-icon"} />
            </ItemImage>
            {item.label}
        </FeaturedItemContainer>
    )
}

export default FeaturedItem;
