import { baseApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/payment/payment.const";


class PaymentService {

    addMakePayment = async ({ body }) => {
        const response = await baseApiInstance().post(
            url.MAKE_PAYMENT,
            body
        )

        return response
    }
}

export default new PaymentService()