import { statData } from 'pages/auth/schedule/data';

import { useAppState } from "hooks/useStore";


const ScheduleStat = ({ statItem }) => {
  const { userEventList } = useAppState((s) => s.event)

  statItem.data = userEventList?.userEventList
    ? userEventList?.userEventList?.filter(event => event.status === statItem.value || statItem.value === statData.ALL.value).length
    : 0

  return (
    <div className={"flex flex-col bg-white rounded-lg px-5 py-3 gap-3"}>
      <div className={"flex justify-between"}>
        {statItem.icon}
        <span className={"font-bodyComp font-medium text-lg text-text-800"}>
          {statItem.label}
        </span>
      </div>
      <div className="flex justify-between items-end">
        <span className="font-bodyPri font-bold text-xl text-text-900">{statItem.data}</span>
        <span className="font-bodyPri font-normal text-sm text-text-500">{statItem.unit}</span>
      </div>
    </div>
  )
}


const ScheduleStatistics = () => {

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
      {Object.keys(statData).map((key, index) => (
        <ScheduleStat key={index} statItem={statData[key]} />
      ))}
    </div>
  );
};

export default ScheduleStatistics;