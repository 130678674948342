import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from 'redux/student/student.const';


class StudentService {

    getMyStudentUserListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.MY_STUDENT_USER_LIST, params),
            { params: query }
        )

        return response
    }

    getFavStudentUserListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.FAV_STUDENT_USER_LIST, params),
            { params: query }
        )

        return response
    }

    createFavStudentUserDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.FAV_STUDENT_USER_LIST, params),
            body
        )

        return response
    }

    deleteFavStudentUserDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.FAV_STUDENT_USER_LIST, params),
            { data: body }
        )

        return response
    }

    getStudentUserDetailByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.STUDENT_USER_DETAIL, params)
        )

        return response
    }

    updateStudentUserDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.STUDENT_USER_DETAIL, params),
            body
        )

        return response
    }

    getStudentUserLanguageListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.STUDENT_USER_LANGUAGE_LIST, params)
        )

        return response
    }

    createStudentUserLanguageDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.STUDENT_USER_LANGUAGE_LIST, params),
            body
        )

        return response
    }

    updateStudentLanguageDetailByStudentLanguageId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.STUDENT_LANGUAGE_DETAIL, params),
            body
        )

        return response
    }

    deleteStudentLanguageDetailByStudentLanguageId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.STUDENT_LANGUAGE_DETAIL, params)
        )

        return response
    }

    getStudentUserCategoryListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.STUDENT_USER_CATEGORY_LIST, params)
        )

        return response
    }

    createStudentUserCategoryDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.STUDENT_USER_CATEGORY_LIST, params),
            body
        )

        return response
    }

    updateStudentCategoryDetailByStudentCategoryId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.STUDENT_CATEGORY_DETAIL, params),
            body
        )

        return response
    }

    deleteStudentCategoryDetailByStudentCategoryId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.STUDENT_CATEGORY_DETAIL, params)
        )

        return response
    }

}

export default new StudentService();