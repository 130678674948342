import { toast } from 'react-toastify';

import walletService from 'redux/wallet/wallet.service';
import {
    setAddCreditToWalletLoading,
    setAddCreditToWallet,
    setAddCreditToWalletErrorMsg,

    setWalletConfirmationLoading,
    setWalletConfirmation,
    setWalletConfirmationErrorMsg,

    setWalletTxnListLoading,
    setWalletTxnList,
    setWalletTxnListErrorMsg
} from 'redux/wallet/wallet.slice';

import { getMyProfile } from 'redux/user/user.request';

export const getWalletTxnList = () => async (dispatch) => {
    dispatch(setWalletTxnListLoading(true))

    try {
        const response = await walletService.getWalletTxnList()
        if (response.status === 200) {
            dispatch(setWalletTxnList(response.data))
        } else {
            dispatch(setWalletTxnListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setWalletTxnListErrorMsg(error.message))
    } finally {
        dispatch(setWalletTxnListLoading(false))
    }
}

export const addWalletCredit = () => async (dispatch, getState) => {
    dispatch(setAddCreditToWalletLoading(true))
    const { addCreditToWallet } = getState().wallet

    try {
        const requestData = {
            body: {
                amount: parseInt(addCreditToWallet?.addCreditPayload?.totalAmount),
                gatewayPaymentId: addCreditToWallet?.addCreditPayload?.gatewayPaymentId,
                gatewayName: addCreditToWallet?.addCreditPayload?.gatewayName
            }
        }
        const response = await walletService.addCreditToWallet(requestData)
        if (response.status === 200) {
            dispatch(setAddCreditToWallet(response.data))
            dispatch(getWalletTxnList())
            dispatch(getMyProfile())
            toast.success(response.data.message)
        } else {
            dispatch(setAddCreditToWalletErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setAddCreditToWalletErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setAddCreditToWalletLoading(false))
    }
}

export const getWalletConfirmationByVirtualTxnId = (virtualTxnId) => async (dispatch) => {
    dispatch(setWalletConfirmationLoading(true))

    try {
        const requestData = {
            params: { virtualTxnId: virtualTxnId }
        }

        const response = await walletService.getWalletConfirmationByVirtualTxnId(requestData)
        if (response.status === 200) {
            dispatch(setWalletConfirmation(response.data))
        } else {
            dispatch(setWalletConfirmationErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setWalletConfirmationErrorMsg(error.message))
    } finally {
        dispatch(setWalletConfirmationLoading(false))
    }
}