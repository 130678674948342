import React, { useEffect } from 'react';

import { pageHeading } from 'pages/auth/subscriptions/subscriptions.data';

import PageHeader from 'components/pageHeader/PageHeader';

import { Button } from "components/common-components/Buttons";
import TableComponent from "components/common-components/Table";
import BillingInformationCard from "./components/PaymentDetailsCard";
import { FAQSection } from "./components/FAQs";
import ReferalCard from "./components/ReferalCard";
import SubscriptionBillingInformation from "./components/SubscriptionBillingInformation";
import SubscriptionModal from "./components/SubscriptionModal";
import InvoiceModal from "./components/InvoiceModal";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { MOCK_FAQS } from "./store/mock-data";
import { openInvoiceModal, openPlansModal } from "./store/SubscriptionSlice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';


const SubscriptionsPage: React.FC = () => {
  const { currentPageInfo } = useAppState((state: any) => state.pageInfo)
  const { modal } = useAppState((s) => s.local)
  const state = useAppState((s) => s.subscriptions.subscriptions);

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.SUBSCRIPTIONS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    dispatcher(setModal({
      ...modal,
      [modalConst.COMING_SOON_MODAL.stateKey]: true
    }))
  }, [])

  const columns = [
    "Plan Name",
    "Amount",
    "Next Payment",
    "Status",
    "Payment Method",
    "Invoice",
    "Change",
  ];

  const rows = state.map((subscription: any) => {
    return [
      subscription.planName,
      subscription.amount,
      subscription.nextPayment,
      subscription.status,
      subscription.paymentMethod,

      subscription.amount === "Free" ? (
        <p>-</p>
      ) : (
        <Button
          name="Show"
          onClick={() => {
            dispatcher(openInvoiceModal(subscription.id));
          }}
          size="small"
          style="outline"
        />
      ),
      subscription.amount === "Free" ? (
        <p>-</p>
      ) : (
        <Button
          name="Change"
          onClick={() => {
            dispatcher(openPlansModal());
          }}
          size="small"
          style="primary"
        />
      ),
    ];
  });

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <div className="space-y-3 sm:space-y-0 sm:flex sm:space-x-3">
        <SubscriptionBillingInformation />
        <div className="p-5 bg-white rounded-lg shadow-md sm:flex-1">
          <ReferalCard />
        </div>
      </div>
      <div>
        <TableComponent
          headers={columns}
          rows={rows}
          title="My Subscriptions"
        />
      </div>
      <BillingInformationCard />
      <div>
        {/* Add modals here, they show up when you toggle their respective state in redux store - SubscriptionSlice */}
        <SubscriptionModal />
        <InvoiceModal />
      </div>
      <div className="bg-white rounded-lg overflow-hidden">
        <FAQSection faqs={MOCK_FAQS} />
      </div>
    </div>
  );
};

export default SubscriptionsPage;
