import tw, { styled } from 'twin.macro';

export const Container = tw.form`
    flex flex-col flex-1 items-center justify-center gap-5
`;
export const BoxSection = tw.div`
w-full border-2 border-divider-medium bg-white rounded-md py-1.5 px-5 flex items-center
`;
export const NameSection = tw.div`
    flex flex-col lg:flex-row items-center justify-center gap-3 w-full
`;
export const InputBox = tw.input`
    text-text-900 text-base font-bodyPri w-full
    focus:outline-none bg-white
`;

export const MobileBoxSection = tw.div`
    border-2 border-divider-medium bg-white rounded-md py-1.5 px-2
    text-text-900 text-base font-bodyPri w-full
    focus:outline-none flex items-center gap-3
`;

export const ReferralBox = tw.span`
    w-full flex gap-2 justify-start items-center cursor-pointer
`;

export const ReferralText = tw.span`
    text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer
`;

export const TextMessage = tw.span`
    flex items-center gap-1 
`;

export const ErrorText = tw.span`
    text-sm font-bodyPri text-red-500 cursor-pointer
`;

export const SignUpButton = styled.button`
    ${tw`
        bg-primary-main hover:bg-secondary-dark py-1.5 rounded-lg
        text-text-50 font-buttons font-medium text-base w-full
    `}
    ${({ isDisabled }) => isDisabled && tw`
        bg-blue-200 hover:bg-blue-200
    `}
`;