import { useState, useEffect } from "react";

import ReferralTermsAndConditions from "components/modals/termsAndConditionsModal/ReferralTermsAndConditions";

import { useAppState } from "hooks/useStore";
import { termsAndConditionsAction } from "redux/local/local.const";

const TermsAndConditionsModal = () => {
  const {termsAndConditions } = useAppState((state) => state.local)
  const [activeTermsAndConditions, setActiveTermsAndConditions] = useState(null)

  useEffect(() => {
    setActiveTermsAndConditions(termsAndConditions?.termsAndConditionsAction)
  }, [termsAndConditions?.termsAndConditionsAction])

  return (
    <div className={"space-y-5 overflow-x-hidden"}>
      <div className={"min-h-[24rem] block space-y-5 pt-5"}>

        {activeTermsAndConditions === termsAndConditionsAction.REFERRAL_TERMS_AND_CONDITIONS.value &&
          <ReferralTermsAndConditions />
        }
      </div>
    </div>
  )
}

export default TermsAndConditionsModal