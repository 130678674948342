import {
    LeftNavLinksContainer,
    NavLink,
    LogoLink,
    ArrowDropDownIcon,
} from './NavBarStyle';

import { useAppState } from 'hooks/useStore';
import { pagesInfo } from 'utils/pagesInfo';

import CenterNavLinks from './CenterNavLinks';


const LeftNavLinks = ({ isScrolled, isMouseHover }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;

    return (
        <LeftNavLinksContainer>
            <LogoLink to={pagesInfo.HOME.pagePath}
                isScrolled={isScrolled} isMouseHover={isMouseHover} pageName={pageName}
            >
                {'Edulyte'}
            </LogoLink>
            <NavLink to={'/skills'}>
                {'Categories'}
                <ArrowDropDownIcon />
            </NavLink>
            {pageName === pagesInfo.HOME.pageName &&
                <CenterNavLinks />
            }
        </LeftNavLinksContainer>
    )
}

export default LeftNavLinks;