import * as React from "react";
import clsx from "clsx";

import { tutorTabs } from "pages/auth/myTutors/data"

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setFilterProps } from "redux/tutor/tutor.slice";
import { filterName } from "redux/tutor/tutor.const";
import { userRoles } from "redux/auth/auth.const";


const MyTutorsSearch: React.FC = ({ myActiveTab }: any) => {
    const { myTutorList, favTutorList, filterProps } = useAppState((s) => s.tutor)
    const [searchText, setSearchText] = React.useState("");
    const dispatcher = useAppDispatcher();
  const { locals } = useAppState((s: any) => s.local)

    const handleFilter = () => {
        if (tutorTabs.ALL_TUTORS.value === myActiveTab) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, searchText: searchText },
                list: myTutorList?.myTutorList?.results,
                filterName: filterName.ALL_TUTORS.value
            }));
        }
        if (tutorTabs.FAV_TUTORS.value === myActiveTab) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, searchText: searchText },
                list: favTutorList?.filteredFavTutorList?.results,
                filterName: filterName.FAV_TUTORS.value
            }));
        }
    }
    
    return (
        <div className="flex justify-center p-4 bg-white rounded-lg">
            <div className="inline-flex mx-auto shadow-sm">
                <input
                    type="text"
                    className={clsx(
                        "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                        "border-sky-200 w-full sm:w-80"
                    )}
                    placeholder={locals.userRole === userRoles.TUTOR.value ? "Student name..." : "Tutor name..."}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>
                    <button
                        className="px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white"
                        onClick={handleFilter}
                    >
                        {"Search"}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default MyTutorsSearch;
