import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Header from 'components/Filters';
import CourseCard from 'components/courseCard/CourseCard';
import CourseCardLoader from 'components/loader/CourseCardLoader';
import Pagination from 'components/pagination/Pagination';
import Footer from 'components/footer/Footer';

import { PageContentStyle } from 'pages/global/findCourses/FindCourses.style'
import styles from 'pages/global/findCourses/FindCoursesPage.module.css';

import SortDropdown from 'pages/global/findCourses/SortDropdown';
import { data, sortingMenuItems } from "pages/global/findCourses/data";

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import {
    getCoursePublicList,
    createFavCourseUserDetailByUserId,
    deleteFavCourseDetailByUserId
} from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearCourseList } from 'redux/course/course.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 9


const FindCoursesPage = () => {
    const { user } = useAppState((s) => s.user)
    const { courseList } = useAppState((s) => s.course)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    const [activeMenuItem, setActiveMenuItem] = useState(sortingMenuItems.RELEVANCE.value)

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.FIND_COURSES))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        const query = generateQueryParams(location.search)

        dispatcher(getCoursePublicList({ page: query.pn || 1, records: RECORDS }))

        return () => {
            dispatcher(setClearCourseList())
        }
    }, [location.search])

    const courseMenuItem = (activeItem) => {
        setActiveMenuItem(activeItem)
    }

    const sortCourseHighToLow = (course1, course2) => {
        return Number(course2?.price / 100) - Number(course1?.price / 100)
    }

    const sortCourseLowToHigh = (course1, course2) => {
        return Number(course1?.price / 100) - Number(course2?.price / 100)
    }

    const sortCourse = (course1, course2) => {
        if (activeMenuItem === sortingMenuItems.PRICE_LOWEST_FIRST.value) {
            return sortCourseLowToHigh(course1, course2)
        }
        if (activeMenuItem === sortingMenuItems.PRICE_HIGHEST_FIRST.value) {
            return sortCourseHighToLow(course1, course2)
        }
    }

    return (
        <PageContentStyle>
            <Header {...data} />

            {/* Sort Button */}
            <div className={`grid grid-cols-12 grid-rows-1 ${styles?.coursesSort}`}>
                <div className="col-span-12 md:col-span-11 flex flex-row items-center justify-center md:justify-end pb-4 z-20">
                    <SortDropdown courseMenuItem={courseMenuItem} />
                </div>
                <div className="md:col-span-1"></div>
            </div>

            {/* Courses List */}
            <div className='flex justify-center items-center pb-5'>
                <div className='max-w-sm md:max-w-3xl lg:max-w-6xl w-full space-y-5'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                        {courseList?.filteredCourseList?.results?.slice(0)?.sort(sortCourse).map((course, idx) => (
                            <div key={idx} className='col-span-1'>
                                <CourseCard
                                    course={course}
                                    isFav={user?.user?.favCourses?.filter((favCourse) =>
                                        favCourse.courseId === course.courseId).length > 0 ? true : false}
                                    handleFav={(courseId) => {
                                        const favoriteCourses = user?.user?.favCourses?.filter((favCourse) =>
                                            favCourse.courseId === courseId)
                                        if (favoriteCourses?.length > 0) {
                                            dispatcher(deleteFavCourseDetailByUserId(user?.user?.userId, { courseId: courseId }))
                                        }
                                        else {
                                            dispatcher(createFavCourseUserDetailByUserId(user?.user?.userId, { courseId: courseId }))
                                        }
                                    }}
                                />
                            </div>
                        ))}
                        {new Array(RECORDS).fill("").map((_, index) => (<CourseCardLoader key={index} isLoading={courseList?.isLoading} />))}
                    </div>
                    {courseList?.filteredCourseList && courseList?.filteredCourseList.records > 0 && (
                        <div className="w-full flex justify-center items-center">
                            <Pagination
                                page={courseList?.filteredCourseList?.page}
                                totalPages={courseList?.filteredCourseList?.totalPages}
                                onChangePage={(page) => navigate(pagesInfo.FIND_COURSES.pagePath + "?pn=" + page)}
                            />
                        </div>
                    )}
                    {(courseList?.filteredCourseList?.records === 0 || courseList.errorMsg) &&
                        <span className='w-full h-full col-span-10 text-center text-base font-bodyPri tracking-wide pt-5'>
                            {courseList.errorMsg}
                        </span>
                    }
                </div>
            </div>
            <Footer />
        </PageContentStyle>
    );
}

export default FindCoursesPage