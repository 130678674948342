import React, { useState } from 'react';

import {
    Container,
    LargeNavBar,
    SideBarHamburgerButton,
    SideBarCloseButton,
    MediumNavBar,
    SmallNavBar,
    HamburgerButton,
    LogoLink,
} from './NavBarStyle';

import { useAppState } from 'hooks/useStore';
import { pagesInfo, sideTopBarPagesName } from 'utils/pagesInfo';

import LeftNavLinks from './LeftNavLinks';
import CenterNavLinks from './CenterNavLinks';
import RightNavLinks from './RightNavLinks';
import HamburgerMenu from './HamburgerMenu';
import SideNavBar from 'components/sideNavBar/SideNavBar';
import TopNavBar from 'components/topNavBar/TopNavBar';

const NavBar = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;

    const [isScrolled, setIsScrolled] = useState(false);
    const [isMouseHover, setIsMouseHover] = useState(false);
    const [isMediumhamburger, setIsMediumHamburger] = useState(false);
    const [isShowSideBar, setIsShowSideBar] = useState(true)

    window.onscroll = () => {
        setIsScrolled(window.pageYOffset > 5 ? true : false);
        return () => window.onscroll = null;
    }

    return (
        <>
            <Container onMouseOver={() => setIsMouseHover(true)} onMouseOut={() => setIsMouseHover(false)}
                isScrolled={isScrolled} pageName={pageName}
            >
                {sideTopBarPagesName.includes(pageName) &&
                    <SideBarHamburgerButton onClick={() => setIsShowSideBar(!isShowSideBar)} />
                }
                <LargeNavBar>
                    <LeftNavLinks
                        isScrolled={isScrolled}
                        isMouseHover={isMouseHover}
                    />
                    {pageName !== pagesInfo.HOME.pageName &&
                        <CenterNavLinks />
                    }
                    <RightNavLinks
                        isScrolled={isScrolled}
                        isMouseHover={isMouseHover}
                    />
                </LargeNavBar>
                <MediumNavBar>
                    <LeftNavLinks
                        pageName={pageName}
                        isScrolled={isScrolled}
                        isMouseHover={isMouseHover}
                    />
                    <HamburgerButton
                        onClick={() => setIsMediumHamburger(true)}
                        isScrolled={isScrolled}
                        isMouseHover={isMouseHover}
                    />
                    <HamburgerMenu
                        visible={isMediumhamburger}
                        setVisible={setIsMediumHamburger}
                    />
                </MediumNavBar>
                <SmallNavBar>
                    <LogoLink to={pagesInfo.HOME.pagePath}
                        isScrolled={isScrolled}
                        isMouseHover={isMouseHover}
                    >
                        {'Edulyte'}
                    </LogoLink>
                    <HamburgerButton
                        onClick={() => setIsMediumHamburger(true)}
                        isScrolled={isScrolled}
                        isMouseHover={isMouseHover}
                    />
                    <HamburgerMenu
                        visible={isMediumhamburger}
                        setVisible={setIsMediumHamburger}
                    />
                </SmallNavBar>
            </Container>
            <TopNavBar />
            <SideNavBar visible={isShowSideBar} />
        </>
    )
}

export default NavBar;