import moment from "moment";

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calender.css";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getEventDetailByEventId } from 'redux/event/event.request';
import { setClearEventDetail } from "redux/event/event.slice";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import ComponentLoader from "components/loader/ComponentLoader";

const localizer = momentLocalizer(moment);

const ScheduleCalendar = () => {
  const { userEventList } = useAppState((s) => s.event)
  const { modal } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher();

  const onEventSlotSelect = (eventId: number) => {
    dispatcher(setClearEventDetail())
    dispatcher(getEventDetailByEventId(eventId))
    dispatcher(setModal({
      ...modal,
      [modalConst.EVENT_DETAIL_MODAL.stateKey]: true
    }))
  };

  if (userEventList?.isLoading) {
    return (<ComponentLoader isLoading={userEventList?.isLoading} />)
  }

  return (
    <div className="w-full h-full pt-5 px-5 rounded-lg bg-white">
      <div className="w-full h-full">
        <Calendar
          className="pageSchedualarCalender"
          localizer={localizer}
          events={userEventList?.filteredUserEventList ? userEventList?.filteredUserEventList?.map((event: any) => ({ ...event, eventName: event?.eventName?.slice(0, 15) + " ..." })): []}
          startAccessor={(event: any) => new Date(event?.startDateTime)}
          endAccessor={(event: any) => new Date(event?.endDateTime)}
          titleAccessor={(event: any) => event?.eventName}
          style={{ height: 500 }}
          onSelectEvent={(event: any) => onEventSlotSelect(event?.eventId)}
        />
      </div>
      <p className="mt-3 text-center text-red-500 opacity-60 lg:opacity-0">
        {"Best Viewed on Desktop Browser"}
      </p>
    </div>
  );
};

export default ScheduleCalendar;
