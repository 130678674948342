import clsx from "clsx";

import { useAppState } from "hooks/useStore";

const InputPasswordBox = ({ inputPassword, setInputPassword, isInputPasswordError }) => {
    const { user } = useAppState((state) => state.user)
    const { userGeneralDetailPayload } = useAppState((state) => state.user)

    return (
        <div className="w-full h-full flex flex-col items-center justify-center gap-8">
            <span className={"font-bodyPri font-normal text-text-800 text-lg"}>
                {"+" + user?.user?.isdCode + " - " + userGeneralDetailPayload?.userGeneralDetailPayload?.mobileNo}
            </span>
            <div className={"space-y-1"}>
                <div className={"flex items-center justify-start gap-2"}>
                    <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                        {"Password:"}
                    </span>
                    <input
                        type={"password"}
                        className={clsx(
                            "w-full px-3 py-1 rounded-md focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-base",
                            isInputPasswordError && "border-2 border-red-500",
                            !isInputPasswordError && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400"
                        )}
                        value={inputPassword}
                        onChange={(event) => setInputPassword(event.target.value)}
                    />
                </div>
                {isInputPasswordError &&
                    <div className={"w-full flex items-center justify-center"}>
                        <div className={"font-bodyPri font-normal text-red-500 text-sm"}>
                            {"This is not the correct password for your account"}
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default InputPasswordBox