import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/message/message.data';
import { sampleCourses } from "pages/auth/message/helpers/sampleCourses";
import { ChatContextProvider } from "pages/auth/message/contexts/chatContext";

import ChatDashboard from "./components/ChatDashboard/ChatDashboard";

import FullPageLoader from 'components/loader/FullPageLoader';
import PageHeader from 'components/pageHeader/PageHeader';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';
import { useUser } from 'pages/auth/message/contexts/userContext';


const Message = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { user } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()
  const userContext = useUser()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.MESSAGE))
  }, [dispatcher, currentPageInfo])

  return (
    <div className='height[100vh] w-full flex flex-col gap-3 px-5 pb-5'>
      <PageHeader pageHeading={pageHeading} />
      <FullPageLoader isLoading={user?.isLoading || userContext?.isLoading} />
      {user?.user && userContext?.user &&
        <ChatContextProvider courses={sampleCourses}>
          <ChatDashboard />
        </ChatContextProvider>
      }
    </div>
  );
}

export default Message;