import segmentListService  from "redux/segment/segment.service"
import {
    setSegmentListLoading,
    setSegmentList,
    setSegmentListErrorMsg
} from "redux/segment/segment.slice"

export const getAllSegmentCategoryList = () => async (dispatch) => {
    dispatch(setSegmentListLoading(true))

    try {
        const response = await segmentListService.getAllSegmentCategoryList()
        if (response.status === 200) {
            dispatch(setSegmentList(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setSegmentListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setSegmentListLoading(false))
    }
}
