import * as React from "react";
import { Toggle } from "components/common-components/Toggle";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import { toggleNotificationWithId } from "../store/MySettingsSlice";

export const NotificationSettings = () => {
  const state = useAppState((s) => s.mySettings.notificationSettings);
  const dispathcer = useAppDispatcher();
  const onNotificationChange = (id: string, code: string) => {
    dispathcer(toggleNotificationWithId({ id, code }));
  };
  return (
    <div className="">
      <div className="py-4 mx-8 border-b">
        <p className="text-lg text-center">Notification Settings</p>
      </div>
      <div className="my-6">
        <div className="flex flex-col space-y-6 divide-y">
          {state.map((item: any, index: Number) => (
            <div className="flex flex-col pt-2 md:flex-row">
              <div className="mr-4 md:w-48">
                <p className="font-bold tracking-wider">{item.title}</p>
                <p className="text-sm tracking-tight">{item.description}</p>
              </div>
              <div className="flex-1 mt-4 space-y-4 divide-y">
                {item.settings.map((setting: any, index: Number) => (
                  <div>
                    <div className="flex items-center space-x-1">
                      <Toggle
                        value={setting.status}
                        key={setting.code}
                        onChange={() => {
                          onNotificationChange(item.id, setting.code);
                        }}
                      />
                      <p className="text-sm font-bold tracking-wider">
                        {setting.title}
                      </p>
                    </div>
                    <div className="pl-2 text-sm opacity-90">
                      {setting.description}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
