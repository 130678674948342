const teachWithUsFeaturedFindStudentImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/teach-with-up-featured-find-students.png';
const teachWithUsFeaturedAnytimeAnywhereImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/teach-with-up-featured-anytime-anywhere.png';
const teachWithUsFeaturedTeachWhatYouWantImageUrl = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/teach-with-us/teach-with-up-featured-teach-what-you-want.png';


export const featuredData = [
    {
        icon: teachWithUsFeaturedFindStudentImageUrl,
        title: 'Find new students',
        description: 'Edulyte opens the entire country to you to gain students.The country is our classroom',
    },
    {
        icon: teachWithUsFeaturedAnytimeAnywhereImageUrl,
        title: 'Anytime, anywhere',
        description: 'Manage your schedule and price.Save travel time, no hassle of payment collection',
    },
    {
        icon: teachWithUsFeaturedTeachWhatYouWantImageUrl,
        title: 'Teach what you want',
        description: 'With an exhaustive list of subject, you can share your expertise with your students',
    },
]