export const pageHeading = {
    heading: "My Bookings",
    subHeading: "Browse all your bookings and take actions!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/bookings.svg"
}

export const myBookingToolTip = "Click on eye icon in the last column to view details of a booking. Use tabs One-on-One or Group to filter your bookings. See Schedule under any booking, send message to your student who made any given booking."

export const sHeaders = [
    "Booking ID",
    "Trial",
    "Tutor Name",
    "Amount",
    "Type",
    "Status",
    "Booking Date",
    "Actions",
];

export const tHeaders = [
    "Booking ID",
    "Trial",
    "Student Name",
    "Amount",
    "Type",
    "Status",
    "Booking Date",
    "Actions",
];

export const bookingLogConst = {
    PENDING: {
        title: "Pending",
    },
    CONFIRMED: {
        title: "Confirmed",
    },
    COMPLETED: {
        title: "Completed",
    },
    CANCELLED: {
        title: "Cancelled",
    },
    REFUNDED: {
        title: "Refunded",
    }
}