import notificationService from "redux/notification/notification.service";
import {
    setSendOtpLoading,
    setSendOtp,
    setSendOtpErrorMsg,

    setVerifyOtpLoading,
    setVerifyOtp,
    setVerifyOtpErrorMsg,
} from "redux/notification/notification.slice";

export const createSendOtp = (body) => async (dispatch) => {
    dispatch(setSendOtpLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await notificationService.createSendOtp(requestData)
        if (response.status === 200) {
            dispatch(setSendOtp(response.data))
        }
        else {
            dispatch(setSendOtpErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setSendOtpErrorMsg(error.message))
    } finally {
        dispatch(setSendOtpLoading(false))
    }
}

export const createVerifyOtp = (body) => async (dispatch) => {
    dispatch(setVerifyOtpLoading(true))

    try {
        const requestData = {
            body: body
        }
        const response = await notificationService.createVerifyOtp(requestData)
        if (response.status === 200) {
            dispatch(setVerifyOtp(response.data))
        } else {
            dispatch(setVerifyOtpErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setVerifyOtpErrorMsg(error.message))
    } finally {
        dispatch(setVerifyOtpLoading(false))
    }
}