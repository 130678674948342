import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { MdOutlineContentCopy } from 'react-icons/md';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { eventStatus, eventType, eventUserType } from 'redux/event/event.const';

import { dayjs, timeZone } from 'utils/dateTime.utils';
import { userRoles } from 'redux/auth/auth.const';
import { startEventByEventId } from 'redux/event/event.request';


const EventDetailCountDown = () => {
    const { eventDetail } = useAppState(s => s.event)

    const dispatcher = useAppDispatcher()

    const [isEventStarted, setIsEventStarted] = useState(false)
    const [isEventEnded, setIsEventEnded] = useState(false)
    const [isMeetingLinkCopied, setIsMeetingLinkCopied] = useState(false)
    const [countDownTimer, setCountDownTimer] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
    })

    const isPremium = eventType.PREMIUM.value === eventDetail?.eventDetail?.eventType

    useEffect(() => {
        let interval
        if (eventDetail?.eventDetail && [eventStatus.SCHEDULED.value, eventStatus.ON_GOING.value, eventStatus.COMPLETED.value].includes(eventDetail?.eventDetail?.status)) {
            const nowTimeStamp = parseInt(dayjs().tz(timeZone).valueOf() / 1000)
            const startTimeStamp = parseInt(dayjs(eventDetail?.eventDetail?.startDateTime).tz(timeZone).valueOf() / 1000)
            const endTimeStamp = parseInt(dayjs(eventDetail?.eventDetail?.endDateTime).tz(timeZone).valueOf() / 1000)
            const deltaTimeStampToStart = startTimeStamp - nowTimeStamp
            const deltaTimeStampToEnd = endTimeStamp - nowTimeStamp
            setIsEventStarted(deltaTimeStampToStart < 0)
            setIsEventEnded(deltaTimeStampToEnd < 0)

            interval = setInterval(() => {
                if (!isEventStarted) {
                    setCountDownTimer({
                        ...countDownTimer,
                        seconds: parseInt(deltaTimeStampToStart) % 60,
                        minutes: parseInt(deltaTimeStampToStart / 60) % 60,
                        hours: parseInt(deltaTimeStampToStart / (60 * 60)) % 24,
                        days: parseInt(deltaTimeStampToStart / (60 * 60 * 24)),
                    })
                }
            }, 1000)
        }

        return () => {
            clearInterval(interval)
        }
    })

    const handleCopyMeetingUrl = () => {
        if (isMeetingLinkCopied || !isEventStarted || isEventEnded) return;

        navigator.clipboard.writeText(eventDetail?.eventDetail?.meeting?.meetingUrl)
        setIsMeetingLinkCopied(true)
        setTimeout(() => {
            setIsMeetingLinkCopied(false)
        }, 1000)

    }

    const handleRediredMeeting = () => {
        if (!isEventStarted && isEventEnded) return;

        if (eventDetail?.eventDetail?.eventType === eventType.COURSE.value) {
            if (eventDetail?.eventDetail?.status === eventStatus.SCHEDULED.value && eventDetail?.eventDetail?.role === userRoles.TUTOR.value) {
                dispatcher(startEventByEventId(eventDetail?.eventDetail?.eventId, { note: '' }))
            }
            window.open(eventDetail?.eventDetail?.meeting?.meetingUrl, '_blank')
        }

        if (eventDetail?.eventDetail?.eventType === eventType.PREMIUM.value) {
            if (eventDetail?.eventDetail?.status === eventStatus.SCHEDULED.value && eventDetail?.eventDetail?.eventUserType === eventUserType.MODERATOR.value) {
                dispatcher(startEventByEventId(eventDetail?.eventDetail?.eventId, { note: '' }))
            }
            window.open(eventDetail?.eventDetail?.meeting?.meetingUrl, '_blank')
        }

    }

    return eventDetail?.eventDetail &&
        [eventStatus.SCHEDULED.value, eventStatus.ON_GOING.value, eventStatus.COMPLETED.value].includes(eventDetail?.eventDetail?.status) ? (
        <div className={"space-y-5"}>
            <div className={"flex flex-col items-center justify-center gap-2"}>
                <div className={"font-bodyPri font-medium text-base text-text-900"}>
                    {isEventEnded
                        ? "Meeting Ended"
                        : isEventStarted
                            ? "Meeting Started"
                            : "Meeting Starts In"
                    }
                </div>
                <div className={"w-full flex items-center justify-evenly gap-2"}>
                    {Object.keys(countDownTimer).map((key, index) => (
                        <span key={index} className={clsx(
                            "flex flex-col items-center justify-center w-16 py-2",
                            "rounded-lg border-2 border-divider-medium shadow"
                        )}>
                            <span className={"font-bodyPri font-semibold text-lg text-primary-dark"}>
                                {String(countDownTimer[key]).padStart(2, '0')}
                            </span>
                            <span className={"font-bodyPri font-normal text-xs text-text-900 capitalize"}>
                                {key}
                            </span>
                        </span>
                    ))}
                </div>
            </div>
            <div className={"flex items-center justify-between gap-2"}>
                <div onClick={handleCopyMeetingUrl} className={clsx(
                    "flex items-center justify-center gap-1 text-text-700 whitespace-nowrap",
                    "border border-text-500 w-28 py-0.5 rounded-md",
                    (!isEventStarted || isEventEnded)
                        ? "!text-text-300 border-text-300"
                        : "hover:!text-text-50 hover:!border-text-500 hover:!bg-text-500 cursor-pointer"
                )}>
                    <span className={"font-buttons font-normal text-base"}>
                        {isMeetingLinkCopied ? "Copied" : "Copy Link"}
                    </span>
                    {!isMeetingLinkCopied && <MdOutlineContentCopy className={"text-base"} />}
                </div>
                <div onClick={handleRediredMeeting} className={clsx(
                    "flex items-center justify-center gap-1 text-secondary-main whitespace-nowrap",
                    "border border-secondary-main w-28 py-0.5 rounded-md",
                    (!isEventStarted || isEventEnded)
                        ? "!text-secondary-light border-secondary-light"
                        : "hover:!text-text-50 hover:!border-secondary-main hover:!bg-secondary-main cursor-pointer"
                )}>
                    <span className={"font-buttons font-normal text-base"}>
                        {"Join Meeting"}
                    </span>
                </div>
            </div>
        </div>
    ) : null
}

export default EventDetailCountDown