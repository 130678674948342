import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/referral/referral.const"

class ReferralServices {
    getUserReferralList = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            `${url.GET_USER_REFERRAL_LIST}/${params.userId}`
        )
        return response
    }

}
export default new ReferralServices;