import clsx from 'clsx';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { modalConst } from 'redux/local/local.const';
import { paymentType } from 'redux/payment/payment.const';
import { setModal } from 'redux/local/local.slice';
import {
    setMakePaymentPayload,
    setClearMakePaymentPayload
} from 'redux/payment/payment.slice';
import { setAddBookingPayload } from 'redux/booking/booking.slice';
import { makeAddBooking } from 'redux/booking/booking.request';


const MakePaymentFooter = () => {
    const { logInSignUp } = useAppState(s => s.auth)
    const { user } = useAppState(s => s.user)
    const { modal } = useAppState(s => s.local)
    const { addCreditToWallet } = useAppState(s => s.wallet)
    const { addBooking } = useAppState(s => s.booking)
    const addBookingPayload = addBooking?.addBookingPayload
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const dispatcher = useAppDispatcher()

    let payableAmount = 0;
    let payButtonText = '';
    if (logInSignUp?.isLogedIn && user?.user && addBookingPayload?.isWalletAdded) {
        if (addBookingPayload?.gatewayAmountToPay > 0) {
            payableAmount = addBookingPayload?.gatewayAmountToPay
            payButtonText = 'Add & Pay'
        } else {
            payableAmount = addBookingPayload?.walletAmountToPay
            payButtonText = 'Pay From Wallet'
        }
    } else {
        payableAmount = addBookingPayload?.gatewayAmountToPay
        payButtonText = 'Pay Now'
    }

    const paymentMethod = (paymentType) => {
        dispatcher(setMakePaymentPayload({
            ...makePaymentPayload,
            paymentType: paymentType.type,
            amountToPay: addBookingPayload?.gatewayAmountToPay >= 100 ? addBookingPayload?.gatewayAmountToPay : 100,
            description: paymentType.type
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.MAKE_PAYMENT_MODAL.stateKey]: false,
            [modalConst.PAYMENT_METHOD_MODAL.stateKey]: true,
        }))
    }

    const handlePay = () => {
        if (makePayment?.isLoading || addCreditToWallet?.isLoading || addBooking?.isLoading) return;

        if (logInSignUp?.isLogedIn && user?.user && addBookingPayload?.isWalletAdded) {
            if (addBookingPayload?.gatewayAmountToPay > 0) {
                dispatcher(setClearMakePaymentPayload())
                paymentMethod(paymentType.BUY_COURSE)
            } else {
                dispatcher(setAddBookingPayload({
                    ...addBookingPayload,
                    confirm: true
                }))
                dispatcher(makeAddBooking())
            }
        } else {
            dispatcher(setClearMakePaymentPayload())
            paymentMethod(paymentType.BUY_COURSE_AS_GUEST)
        }
    }

    return (
        <div className={"grid grid-cols-2"}>
            <div className={"w-full col-span-1 flex flex-col items-start justify-center"}>
                <div className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-medium text-lg text-text-900"}>
                    <span>{"USD"}</span>
                    <span>{parseFloat(payableAmount / 100).toFixed(2)}</span>
                </div>
                <div className={"whitespace-nowrap flex items-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                    <span>{"Approx"} {"INR"}</span>
                    <span>{parseFloat(payableAmount / 100).toFixed(2)}</span>
                </div>
            </div>
            <div className={"w-full col-span-1 flex items-center justify-end"}>
                <span onClick={handlePay} className={clsx(
                    "whitespace-nowrap px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    (makePayment?.isLoading || addCreditToWallet?.isLoading || addBooking?.isLoading)
                    && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {payButtonText}
                </span>
            </div>
        </div>
    )
}

export default MakePaymentFooter
