export const messageTemplateList = [
    {
        message: "Can I discuss your availabilty?",
    },
    {
        message: "I have queries on pricing",
    },
    {
        message: "I have some questions about your teaching style?",
    },
    {
        message: "Other",
    },
]