import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader"

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getUserEventListByUserId } from 'redux/event/event.request';
import { setClearUserEventList } from "redux/event/event.slice"
import { eventStatus } from "redux/event/event.const";

import { pagesInfo } from "utils/pagesInfo";


const UpcomingEventList = ({ userEvent }: any) => {
    return userEvent?.status === eventStatus.SCHEDULED.value ? (
        <div className="flex items-center gap-5 py-2 rounded-md hover:bg-gray-50 cursor-pointer">
            <img className="w-10 h-10 rounded-full" src={userEvent?.course?.thumbnailPicUrl} alt="" />
            <p className="font-bodyPri font-normal text-base text-text-900">
                {userEvent?.course?.courseTitle}
            </p>
        </div>
    ) : null
}

export const UpcomingClasses: React.FC = () => {
    const { locals } = useAppState((s) => s.local)
    const { user } = useAppState((s: any) => s.user)
    const { userEventList } = useAppState((s) => s.event)
    const dispatcher = useAppDispatcher()

    React.useEffect(() => {
        dispatcher(getUserEventListByUserId(user?.user?.userId))

        return () => {
            dispatcher(setClearUserEventList())
        }
    }, [locals.userRole])

    return (
        <div className="px-5 space-y-3 w-full bg-white rounded-lg">
            <div className="flex justify-between">
                <p className="text-lg font-medium font-bodyPri text-text-900 tracking-wider">{"Upcoming Sessions"}</p>
                <p className="text-sm text-text-700 font-bodyPri whitespace-nowrap">
                    <Link to={pagesInfo.SCHEDULE.pagePath}>{"View all"}</Link>
                </p>
            </div>
            <div className={clsx(
                "flex flex-col max-h-96 h-full overflow-hidden overflow-y-scroll",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
                 <ComponentLoader isLoading={userEventList?.isLoading} className={"h-80"}/>
                {userEventList?.userEventList?.length > 0 &&
                    userEventList?.userEventList?.slice(0, 5)?.map((userEvent: any, idx: any) => (
                        <UpcomingEventList key={idx}  userEvent={userEvent} />
                    ))}
                {(userEventList?.errorMsg || !userEventList?.userEventList) &&
                    <p className="font-bodyPri font-normal text-text-800 text-md">
                        {userEventList?.errorMsg}
                    </p>
                }
            </div>
        </div>
    );
};
