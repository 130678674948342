import tw, { styled } from "twin.macro";

export const StyledChatListContainer = styled.div`
  ${tw`w-full h-screen max-h-screen bg-white md:(max-w-sm height[80vh] px-0 border-r border-gray-200)`}
`;

export const StyledModalContainer = styled.div`
  ${tw`absolute inset-0 flex flex-col bg-primary-main bg-opacity-5 z-20`}
`;

export const ChatListContainerLoader = styled.div`
  ${tw`w-full flex items-center justify-center height[90%]`}
`;
