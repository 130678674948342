import { createSlice } from "@reduxjs/toolkit";
import { REVIEW_INITIAL_STATE } from 'redux/review/review.initialState';


const review = createSlice({
    name: "review",
    initialState: REVIEW_INITIAL_STATE,
    reducers: {
        setTutorReviewLoading: (state, { payload }) => {
            state.tutorReviewList.isLoading = payload
        },
        setTutorReviewList: (state, { payload }) => {
            state.tutorReviewList.tutorReviewList = payload
            state.tutorReviewList.filteredTutorReviewList = payload
            state.tutorReviewList.errorMsg = REVIEW_INITIAL_STATE.tutorReviewList.errorMsg
        },
        setTutorReviewErrorMsg: (state, { payload }) => {
            state.tutorReviewList.errorMsg = payload
            state.tutorReviewList.tutorReviewList = REVIEW_INITIAL_STATE.tutorReviewList.tutorReviewList
            state.tutorReviewList.filteredTutorReviewList = REVIEW_INITIAL_STATE.tutorReviewList.filteredTutorReviewList
        },
        setClearTutorReviewList: (state) => {
            state.tutorReviewList = REVIEW_INITIAL_STATE.tutorReviewList
        }
    }
})

export const {
    setTutorReviewLoading,
    setTutorReviewList,
    setTutorReviewErrorMsg,
    setClearTutorReviewList
} = review.actions
export default review.reducer