import { referralTermsAndConditions } from "components/modals/termsAndConditionsModal/TermsAndConditionsData"

const ReferralTermsAndConditions = () => {

    return (
        <div className={"space-y-5"}>
            {referralTermsAndConditions?.map((termsAndConditions) => (
                <div className={"flex flex-col items-start justify-start gap-5"}>
                    <hr className={"w-full border border-divider-medium"} />
                    <div className={"space-y-0.5"}>
                        <li className={"font-bodyPri font-medium text-text-900 text-2xl"}>
                            {termsAndConditions?.title}
                        </li>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {termsAndConditions?.content}
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default ReferralTermsAndConditions