export const userSocialMediaLinksConst = {
    FACEBOOK: {
        label: "Facebook",
        value: "facebook",
        placeholder: "https://facebook.com/your_facebook_name",
        demoKey1: "https://facebook.com/your_facebook_name",
        demoKey2: "",
        socialLinkUrl: "https://facebook.com/"
    },
    INSTAGRAM: {
        label: "Instagram",
        value: "instagram",
        placeholder: "https://instagram.com/your_instagram_name",
        demoKey1: "https://instagram.com/your_instagram_name",
        demoKey2: "or @your_instagram_name",
        socialLinkUrl: "https://instagram.com/"
    },
    TWITTER: {
        label: "Twitter",
        value: "twitter",
        placeholder: "https://twitter.com/your_twitter_name",
        demoKey1: "https://twitter.com/your_twitter_name",
        demoKey2: "@Your_Twitter_Name",
        socialLinkUrl: "https://twitter.com/"
    },
    LINKEDIN: {
        label: "Linkedin",
        value: "linkedin",
        placeholder: "https://linkedin.com/yourlinkedinname",
        demoKey1: "https://linkedin.com/yourlinkedinname",
        demoKey2: "",
        socialLinkUrl: "https://linkedin.com/"
    }
}