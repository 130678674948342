import { eventStatus } from 'redux/event/event.const';


export const eventLogConst = {
    NOT_MAPPED: {
        title: "Not Mapped",
    },
    PENDING: {
        title: "Pending",
    },
    SCHEDULED: {
        title: "Schedule",
    },
    ON_GOING: {
        title: "On Going",
    },
    COMPLETED: {
        title: "Completed",
    },
    DECLINED: {
        title: "Declined",
    },
    RESCHEDULED: {
        title: "Rescheduled",
    },
    CANCELLED: {
        title: "Cancelled",
    },
}


export const createEventLog = (eventLogs) => {
    return eventLogs.map(event => {
        return {
            ...eventStatus[event.status.toUpperCase()],
            ...event,
            title: eventLogConst[event.status.toUpperCase()].title
        }
    })
}