import { useEffect } from 'react';
import clsx from 'clsx';
import { dayjs, timeZone } from 'utils/dateTime.utils';

import { FiEdit } from 'react-icons/fi';
import { MdGroups } from 'react-icons/md';
import { IoMdPeople } from 'react-icons/io';
import { AiOutlineCalendar } from 'react-icons/ai';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddBooking, setAddBookingPayload } from 'redux/booking/booking.slice';
import { courseType } from 'redux/course/course.const';
import { modalConst } from 'redux/local/local.const';

import { utcWeekDays } from 'utils/dateTime.utils';
import ComponentLoader from 'components/loader/ComponentLoader';
import { setClearAddCreditToWallet } from 'redux/wallet/wallet.slice';
import { setClearMakePayment } from 'redux/payment/payment.slice';


const DayItems = ({ day, timeSlots }) => {
    return timeSlots?.length > 0 && (
        <div className={clsx(
            "col-spna-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>{day}</span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot?.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}

const CourseCheckoutModal = () => {
    const { user } = useAppState(s => s.user)
    const { logInSignUp } = useAppState(s => s.auth)
    const { selectedCourse } = useAppState(s => s.course)
    const { modal } = useAppState(s => s.local)
    const { myCourseList } = useAppState((s) => s.course)
    const { addBooking } = useAppState(s => s.booking)
    const addBookingPayload = addBooking?.addBookingPayload

    const dispatcher = useAppDispatcher()

    const isMyOffering = user?.user?.userId === selectedCourse?.selectedCourse?.tutor?.userId
    const isMyCourse = myCourseList?.filteredCourseList?.filter((course) => course?.courseType === courseType?.GROUP.value)
        .filter((myCourse) => myCourse?.courseId === selectedCourse?.selectedCourse?.courseId)?.length > 0

    useEffect(() => {
        if (selectedCourse?.selectedCourse) {
            if (selectedCourse?.selectedCourse?.courseType === courseType.GROUP.value) {
                dispatcher(setAddBookingPayload({
                    ...addBookingPayload,
                    course: selectedCourse?.selectedCourse,
                    coursePrice: selectedCourse?.selectedCourse?.price,
                    coursePctDiscount: selectedCourse?.selectedCourse?.discountPct,
                    volumePctDiscount: 0,
                    sessionDuration: selectedCourse?.selectedCourse?.sessionDuration,
                    noOfSessions: selectedCourse?.selectedCourse?.noOfSessions,
                    startDate: selectedCourse?.selectedCourse?.startDate,
                    endDate: selectedCourse?.selectedCourse?.endDate,
                    weeklyTimeSlot: selectedCourse?.selectedCourse?.weeklyTimeSlot
                }))
            }

            if (addBookingPayload && selectedCourse?.selectedCourse?.courseType === courseType.ONE_ON_ONE.value) {
                dispatcher(setAddBookingPayload({
                    ...addBookingPayload,
                    course: selectedCourse?.selectedCourse,
                    coursePrice: selectedCourse?.selectedCourse?.price,
                    coursePctDiscount: selectedCourse?.selectedCourse?.discountPct,
                    volumePctDiscount: selectedCourse?.selectedCourse?.volumePctDiscount,
                    sessionDuration: addBookingPayload?.sessionDuration,
                    noOfSessions: addBookingPayload?.noOfSessions,
                    startDate: addBookingPayload?.startDate,
                    endDate: addBookingPayload?.endDate,
                    weeklyTimeSlot: addBookingPayload?.weeklyTimeSlot
                }))
            }
        }
    }, [selectedCourse?.selectedCourse])

    useEffect(() => {
        if (addBookingPayload?.course)
            setPurchasedPrice()
    }, [addBookingPayload?.course])

    const setPurchasedPrice = () => {
        let sellingPrice = 0
        if (addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value) {
            sellingPrice = parseFloat(addBookingPayload.course.price / 2.0) * (addBookingPayload.sessionDuration / 30)
            sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.coursePctDiscount) / 100))
            sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.tutorPctDiscount) / 100))
            sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.offerPctDiscount) / 100))
            if (addBookingPayload?.noOfSessions === 5) {
                sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.volumePctDiscount) / 100))
            }
        }

        if (addBookingPayload?.course?.courseType === courseType.GROUP.value) {
            sellingPrice = parseFloat(addBookingPayload.course.price)
            sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.coursePctDiscount) / 100))
            sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.tutorPctDiscount) / 100))
            sellingPrice = parseFloat(sellingPrice * ((100 - addBookingPayload?.offerPctDiscount) / 100))
        }

        sellingPrice = sellingPrice * addBookingPayload?.noOfSessions

        dispatcher(setAddBookingPayload({
            ...addBookingPayload,
            purchasedPrice: sellingPrice
        }))
    }

    const handleCheckout = () => {
        if (!logInSignUp?.isLogedIn || !user?.user) {
            dispatcher(setModal({
                ...modal,
                [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                [modalConst.AUTHORIZATION_MODAL.stateKey]: true,
            }))
            return;
        }

        dispatcher(setClearMakePayment())
        dispatcher(setClearAddCreditToWallet())
        dispatcher(setAddBooking(null))
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
            [modalConst.MAKE_PAYMENT_MODAL.stateKey]: true,
        }))
    }

    const editChooseTimeSlot = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
            [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true,
        }))
    }

    const editNoOfSession = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
            [modalConst.NO_SESSION_MODAL.stateKey]: true,
        }))
    }

    if (selectedCourse?.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedCourse?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedCourse?.errorMsg) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedCourse?.errorMsg}
                </span>
            </div>
        )
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] block space-y-3"}>
                <div className={"grid grid-cols-12"}>
                    <div className={"w-full col-start-1 col-span-9 flex flex-col items-start gap-1"}>
                        <span className={"w-full self-start turncate text-start font-bodyPri font-medium text-base text-text-900"}>
                            {addBookingPayload?.course?.courseTitle.substring(0, 50) + (addBookingPayload?.course?.courseTitle.length > 50 ? "..." : "")}
                        </span>
                        <span className={"w-full self-end turncate text-end font-bodyPri font-normal text-sm text-text-800"}>
                            {addBookingPayload?.course?.tutor?.firstName + " " + addBookingPayload?.course?.tutor?.lastName.charAt(0) + "."}
                        </span>
                    </div>
                    <div className={"col-start-10 col-span-3"}>
                        <div className={"w-full flex items-center justify-end"}>
                            <div className={'relative w-12 h-12 rounded-full'}>
                                <img src={addBookingPayload?.course?.tutor?.profilePicUrl} alt={"profile-pic"}
                                    className="w-full h-full rounded-full object-cover"
                                />
                                <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                                    <img src={`https://countryflagsapi.com/png/${addBookingPayload?.course?.tutor?.countryDomain}`} alt={"country-flag"}
                                        className='w-full h-full object-cover'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                            {"No of sessions"}
                        </span>
                        {addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value &&
                            <FiEdit onClick={editNoOfSession} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                        }
                    </div>
                    <div className={"flex items-center justify-between"}>
                        <span className={"font-bodyPrim font-medium text-base text-text-900"}>
                            {addBookingPayload?.noOfSessions + " sessions"}
                        </span>
                        <span className={"bg-primary-light px-3 py-1 rounded flex gap-1 border-2 border-divider-medium"}>
                            <span className={"font-bodyPrim font-medium text-base text-primary-dark"}>
                                {"USD"}
                            </span>
                            <span className={"font-bodyPrim font-medium text-base text-primary-dark"}>
                                {parseFloat(addBookingPayload?.purchasedPrice / 100).toFixed(2)}
                            </span>
                        </span>
                    </div>
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-normal font-base text-sm text-text-800"}>
                            {"Date and time"}
                        </span>
                        {addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value &&
                            <FiEdit onClick={editChooseTimeSlot} className={"text-xl text-text-700 hover:text-text-800 cursor-pointer"} />
                        }
                    </div>
                    <div className={"flex flex-col gap-2"}>
                        <div className={"flex flex-row items-start justify-start gap-2"}>
                            <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                {dayjs(addBookingPayload?.startDate).tz(timeZone).format('ddd, MMM DD YYYY')}
                            </span>
                            {addBookingPayload?.course?.courseType === courseType.GROUP.value &&
                                <>
                                    <span className={"font-bodyPri font-medium text-base text-text-700"}>{"-"}</span>
                                    <span className={"font-bodyPri font-medium text-base text-text-900"}>
                                        {dayjs(addBookingPayload?.endDate).tz(timeZone).format('ddd, MMM DD YYYY')}
                                    </span>
                                </>
                            }
                        </div>
                        <div className={clsx(
                            "grid grid-flow-col gap-2 px-1 pb-3 overflow-hidden overflow-x-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {Object.keys(addBookingPayload?.weeklyTimeSlot).map((day, index) => (
                                <DayItems key={index} day={day} timeSlots={addBookingPayload?.weeklyTimeSlot[day]} />
                            ))}
                        </div>
                    </div>
                </div>

                <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>

                <div className={"w-full space-y-2"}>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value && "One on One"}
                            {addBookingPayload?.course?.courseType === courseType.GROUP.value && "Group"}
                        </span>
                        {addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value &&
                            <IoMdPeople className={"text-xl text-text-800"} />
                        }
                        {addBookingPayload?.course?.courseType === courseType.GROUP.value &&
                            <MdGroups className={"text-xl text-text-800"} />
                        }
                    </div>
                    <div className={"flex flex-row items-center justify-between"}>
                        <span className={"font-bodyPri font-medium text-base text-text-900"}>
                            {addBookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value && "Single event"}
                            {addBookingPayload?.course?.courseType === courseType.GROUP.value && "Recurring event"}
                        </span>
                        <AiOutlineCalendar className={"text-xl text-text-800"} />
                    </div>
                </div>
            </div>
            {!(isMyOffering || isMyCourse) &&
                <div className={"flex justify-end"}>
                    <span onClick={handleCheckout} className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}>
                        {"Checkout"}
                    </span>
                </div>
            }
        </div>
    )
}

export default CourseCheckoutModal