import { useEffect } from 'react';

import TabLayout from 'pages/auth/preferences/components/TabLayout'
import LogInCard from 'pages/global/logIn/logInCard/LogInCard'
import SignUpCard from 'pages/global/signUp/signUpCard/SignUpCard';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const AuthorizationModal = () => {
  const {modal} = useAppState((s) => s.local)
  const { logInSignUp} = useAppState((s) => s.auth)
  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (logInSignUp?.isLogedIn) {
      dispatcher(setModal({
        ...modal,
        [modalConst.AUTHORIZATION_MODAL.stateKey]: false
      }))
    }
  }, [logInSignUp?.isLogedIn])

  const tabConfig = [
    {
      element: <LogInCard />,
      label: "Log In",
    },
    {
      element: <SignUpCard />,
      label: "Sign Up",
    },
  ];

  return (
    <div className={"space-y-5"}>
      <div className={"min-h-[24rem] block"}>
        <div className='w-full'>
          <TabLayout config={tabConfig} />
        </div>
      </div>
    </div>
  )
}

export default AuthorizationModal