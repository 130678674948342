export const pageHeading = {
    heading: "My Teachers",
    subHeading: "Find the list of teachers and liked teachers!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-tutors.svg"
}

export const tutorTabs = {
    ALL_TUTORS: {
        label: "All Tutors",
        value: "all_tutors"
    },
    FAV_TUTORS: {
        label: "Favourite",
        value: "fav_tutors"
    }
}

