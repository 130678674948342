import { createSlice } from "@reduxjs/toolkit";

import { MASTER_INITIAL_STATE } from "redux/master/master.initialState";

const master = createSlice({
    name: "master",
    initialState: MASTER_INITIAL_STATE,
    reducers: {
        // reducers for country list
        setCountryListLoading: (state, { payload }) => {
            state.countryList.isLoading = payload
        },
        setCountryList: (state, { payload }) => {
            state.countryList.countryList = payload
            state.countryList.errorMsg = MASTER_INITIAL_STATE.countryList.errorMsg
        },
        setCountryListErrorMsg: (state, { payload }) => {
            state.countryList.errorMessage = payload
            state.countryList.countryList = MASTER_INITIAL_STATE.countryList.countryList
        },
        setClearCountryList: (state) => {
            state.countryList = MASTER_INITIAL_STATE.countryList
        },

        // reducers for country Detail
        setCountryDetailLoading: (state, { payload }) => {
            state.countryDetail.isLoading = payload
        },
        setCountryDetail: (state, { payload }) => {
            state.countryDetail.countryDetail = payload
            state.countryDetail.errorMsg = MASTER_INITIAL_STATE.countryDetail.errorMsg
        },
        setCountryDetailErrorMsg: (state, { payload }) => {
            state.countryDetail.errorMessage = payload
            state.countryDetail.countryDetail = MASTER_INITIAL_STATE.countryDetail.countryDetail
        },
        setClearCountryDetail: (state) => {
            state.countryDetail = MASTER_INITIAL_STATE.countryDetail
        },

        // reducers for timeZone country Detail
        setTimeZoneCountryDetailLoading: (state, { payload }) => {
            state.timeZoneCountryDetail.isLoading = payload
        },
        setTimeZoneCountryDetail: (state, { payload }) => {
            state.timeZoneCountryDetail.timeZoneCountryDetail = payload
            state.timeZoneCountryDetail.errorMsg = MASTER_INITIAL_STATE.timeZoneCountryDetail.errorMsg
        },
        setTimeZoneCountryDetailErrorMsg: (state, { payload }) => {
            state.timeZoneCountryDetail.errorMessage = payload
            state.timeZoneCountryDetail.timeZoneCountryDetail = MASTER_INITIAL_STATE.timeZoneCountryDetail.timeZoneCountryDetail
        },
        setClearTimeZoneCountryDetail: (state) => {
            state.timeZoneCountryDetail = MASTER_INITIAL_STATE.timeZoneCountryDetail
        },

        // reducers for state list
        setStateListLoading: (state, { payload }) => {
            state.stateList.isLoading = payload
        },
        setStateList: (state, { payload }) => {
            state.stateList.stateList = payload
            state.stateList.errorMsg = MASTER_INITIAL_STATE.stateList.errorMsg
        },
        setStateListErrorMsg: (state, { payload }) => {
            state.stateList.errorMsg = payload
            state.stateList.stateList = MASTER_INITIAL_STATE.stateList.stateList
        },
        setClearStateList: (state) => {
            state.stateList = MASTER_INITIAL_STATE.stateList
        },

        // reducers for city list
        setCityListLoading: (state, { payload }) => {
            state.cityList.isLoading = payload
        },
        setCityList: (state, { payload }) => {
            state.cityList.cityList = payload
            state.cityList.errorMsg = MASTER_INITIAL_STATE.cityList.errorMsg
        },
        setCityListErrorMsg: (state, { payload }) => {
            state.cityList.errorMsg = payload
            state.cityList.cityList = MASTER_INITIAL_STATE.cityList.cityList
        },
        setClearCityList: (state) => {
            state.cityList = MASTER_INITIAL_STATE.cityList
        },

        // reducers for currency list
        setCurrencyListLoading: (state, { payload }) => {
            state.currencyList.isLoading = payload
        },
        setCurrencyList: (state, { payload }) => {
            state.currencyList.currencyList = payload
            state.currencyList.errorMsg = MASTER_INITIAL_STATE.currencyList.errorMsg
        },
        setCurrencyListErrorMsg: (state, { payload }) => {
            state.currencyList.errorMsg = payload
            state.currencyList.currencyList = MASTER_INITIAL_STATE.currencyList.currencyList
        },
        setClearCurrencyList: (state) => {
            state.currencyList = MASTER_INITIAL_STATE.currencyList
        },

        // reducers for timeZone list
        setTimeZoneListLoading: (state, { payload }) => {
            state.timeZoneList.isLoading = payload
        },
        setTimeZoneList: (state, { payload }) => {
            state.timeZoneList.timeZoneList = payload
            state.timeZoneList.errorMsg = MASTER_INITIAL_STATE.timeZoneList.errorMsg
        },
        setTimeZoneListErrorMsg: (state, { payload }) => {
            state.timeZoneList.errorMsg = payload
            state.timeZoneList.timeZoneList = MASTER_INITIAL_STATE.timeZoneList.timeZoneList
        },
        setClearTimeZoneList: (state) => {
            state.timeZoneList = MASTER_INITIAL_STATE.timeZoneList
        },

        // reducers for language list
        setLanguageListLoading: (state, { payload }) => {
            state.languageList.isLoading = payload
        },
        setLanguageList: (state, { payload }) => {
            state.languageList.languageList = payload
            state.languageList.errorMsg = MASTER_INITIAL_STATE.languageList.errorMsg
        },
        setLanguageListErrorMsg: (state, { payload }) => {
            state.languageList.errorMsg = payload
            state.languageList.languageList = MASTER_INITIAL_STATE.languageList.languageList
        },
        setClearLanguageList: (state) => {
            state.languageList = MASTER_INITIAL_STATE.languageList
        },

        // reducers for language level list
        setLanguageLevelListLoading: (state, { payload }) => {
            state.languageLevelList.isLoading = payload
        },
        setLanguageLevelList: (state, { payload }) => {
            state.languageLevelList.languageLevelList = payload
            state.languageLevelList.errorMsg = MASTER_INITIAL_STATE.languageLevelList.errorMsg
        },
        setLanguageLevelListErrorMsg: (state, { payload }) => {
            state.languageLevelList.errorMsg = payload
            state.languageLevelList.languageLevelList = MASTER_INITIAL_STATE.languageLevelList.languageLevelList
        },
        setClearLanguageLevelList: (state) => {
            state.languageLevelList = MASTER_INITIAL_STATE.languageLevelList
        },

        // prof List
        setProfListLoading: (state, { payload }) => {
            state.isLoading = payload
        },
        setProfList: (state, { payload }) => {
            state.profList.profList = payload
            state.profList.errorMsg = MASTER_INITIAL_STATE.profList.errorMsg
        },
        setProfListErrorMsg: (state, { payload }) => {
            state.profList.errorMsg = payload
            state.profList.profList = MASTER_INITIAL_STATE.profList.profList
        },
        setClearProfList: (state) => {
            state.profList = MASTER_INITIAL_STATE.profList
        },

        // reducers for tag list
        setTagListLoading: (state, { payload }) => {
            state.tagList.isLoading = payload
        },
        setTagList: (state, { payload }) => {
            state.tagList.tagList = payload
            state.tagList.errorMsg = MASTER_INITIAL_STATE.tagList.errorMsg
        },
        setTagListErrorMsg: (state, { payload }) => {
            state.tagList.errorMsg = payload
            state.tagList.tagList = MASTER_INITIAL_STATE.tagList.tagList
        },
        setClearTagList: (state) => {
            state.tagList = MASTER_INITIAL_STATE.tagList
        },
    }
})

export const {
    setCountryListLoading,
    setCountryList,
    setCountryListErrorMsg,
    setClearCountryList,

    setCountryDetailLoading,
    setCountryDetail,
    setCountryDetailErrorMsg,
    setClearCountryDetail,
    
    setTimeZoneCountryDetailLoading,
    setTimeZoneCountryDetail,
    setTimeZoneCountryDetailErrorMsg,
    setClearTimeZoneCountryDetail,

    setStateListLoading,
    setStateList,
    setStateListErrorMsg,
    setClearStateList,

    setCityListLoading,
    setCityList,
    setCityListErrorMsg,
    setClearCityList,

    setCurrencyListLoading,
    setCurrencyList,
    setCurrencyListErrorMsg,
    setClearCurrencyList,

    setTimeZoneListLoading,
    setTimeZoneList,
    setTimeZoneListErrorMsg,
    setClearTimeZoneList,

    setLanguageListLoading,
    setLanguageList,
    setLanguageListErrorMsg,
    setClearLanguageList,

    setLanguageLevelListLoading,
    setLanguageLevelList,
    setLanguageLevelListErrorMsg,
    setClearLanguageLevelList,

    setProfListLoading,
    setProfList,
    setProfListErrorMsg,
    setClearProfList,

    setTagListLoading,
    setTagList,
    setTagListErrorMsg,
    setClearTagList,
} = master.actions

export default master.reducer