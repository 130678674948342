import React, { useEffect, useRef, useState } from "react";
import { JaaSMeeting } from "@jitsi/react-sdk";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Helper functions
import chatName from "../../helpers/chatName";
import updateMeetingLink from "../../helpers/updateMeetingLink";

//Components
import JitsiRenderSpinner from "../JitsiRenderSpinner/JitsiRenderSpinner";

//Icon imports
import { XCircleIcon } from "@heroicons/react/outline";

//Style imports
import {
  StyledJitsiMeetComponent,
  StyledCloseButton,
} from "./JitsiMeetComponent.styles";

export default function JitsiMeetComponent() {
  const { selectedChat, dispatch } = useChat();
  const { user } = useUser();
  const apiRef = useRef();
  const [jwtToken, setJwtToken] = useState(null);
  const [loadingJwt, setLoadingJwt] = useState(true);
  const roomName = chatName(selectedChat, user);
  
  const combinedName = `${user.firstName} ${user.lastName}`;

  const appId = process.env.REACT_APP_JAAS_APP_ID;

  //fetches the jwt token from the server
  useEffect(() => {
    async function fetchJwtToken() {
      const meetingLink = `https://8x8.vc/${appId}/${roomName}`;
      const userName = combinedName;
      const response = await fetch("/generate-jitsi-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userName }),
      });
      const data = await response.json();
      await updateMeetingLink(selectedChat, meetingLink);
      console.log("Effect ran");
      setJwtToken(data.jwt);
      setLoadingJwt(false);
    }
    fetchJwtToken();
  }, [appId, combinedName, roomName, selectedChat]);


  const handleChatUpdates = (payload, ref) => {
    if (payload.isOpen || !payload.unreadCount) {
      return;
    }
    ref.current.executeCommand("toggleChat");
  };

  const handleJitsiIFrameRef1 = (iframeRef) => {
    iframeRef.style.border = "1px solid cadetblue";
    iframeRef.style.background = "cadetblue";
    iframeRef.style.height = "100vh";
    iframeRef.style.width = "100vw";
  };

  const handleApiReady = (apiObj, ref) => {
    ref.current = apiObj;
    ref.current.addEventListeners({
      // Listening to events from the external API
      chatUpdated: (payload) => handleChatUpdates(payload, ref),
    });
  };

  function closeMeetingComponent() {
    dispatch({ type: "START_MEETING", payload: false });
  }

  return (
    <StyledJitsiMeetComponent>
      {/* {!loadingJwt && jwtToken && (
        <StyledCloseButton onClick={closeMeetingComponent}>
          <XCircleIcon className="h-10 w-10 text-white" />
        </StyledCloseButton>
      )} */}
      {!jwtToken && loadingJwt && <JitsiRenderSpinner />}
      {!loadingJwt && jwtToken && (
        <JaaSMeeting
          appId={appId}
          roomName={roomName}
          jwt={jwtToken}
          configOverwrite={{
            disableThirdPartyRequests: true,
            disableLocalVideoFlip: false,
            backgroundAlpha: 0.5,
          }}
          interfaceConfigOverwrite={{
            VIDEO_LAYOUT_FIT: "nocrop",
            MOBILE_APP_PROMO: false,
            TILE_VIEW_MAX_COLUMNS: 4,
          }}
          spinner={JitsiRenderSpinner}
          onApiReady={(externalApi) => handleApiReady(externalApi, apiRef)}
          getIFrameRef={handleJitsiIFrameRef1}
          onReadyToClose={closeMeetingComponent}
        />
      )}
    </StyledJitsiMeetComponent>
  );
}
