export const findTutorData = {
  filterText: {
    title: 'Learn online from qualified & trusted tutors',
    steps: [
      {
        step: '1',
        stepText: 'Select a tutor',
      },
      {
        step: '2',
        stepText: 'Book a lesson',
      },
      {
        step: '3',
        stepText: 'Start learning',
      },
    ]
  },
  filters: {
    topics: {
      label: "TOPIC",
      options: [
        {
          id: 1,
          name: "Any"
        },
        {
          id: 2,
          name: "Options 1"
        }
      ]
    },
    hourlyFees: {
      label: "HOURLY FEE",
      options: [
        {
          id: 1,
          name: "100 Rs - 300 Rs"
        },
        {
          id: 2,
          name: "200 Rs - 400 Rs"
        }
      ]
    },
    availability: {
      label: "AVAILABILITY",
      options: [
        {
          id: 1,
          name: "Any time"
        },
        {
          id: 2,
          name: "Option 1"
        }
      ]
    },
    tutorIsFrom: {
      label: "TUTOR IS FROM",
      options: [
        {
          id: 1,
          name: "Any country"
        },
        {
          id: 2,
          name: "Option 1"
        }
      ]
    },
    speaks: {
      label: "Speaks",
      options: [
        {
          id: 1,
          name: "Speaks 1"
        },
        {
          id: 2,
          name: "Speaks 2"
        }
      ]
    },
    sessionType: {
      label: "Session Type",
      options: [
        {
          id: 1,
          name: "Session Type 1"
        },
        {
          id: 2,
          name: "Session Type 2"
        }
      ]
    },
    studentLevel: {
      label: "Student Level",
      options: [
        {
          id: 1,
          name: "Student Level 1"
        },
        {
          id: 2,
          name: "Student Level 2"
        }
      ]
    },
    learnerAge: {
      label: "Learner's Age",
      options: [
        {
          id: 1,
          name: "Learner's Age 1"
        },
        {
          id: 2,
          name: "Learner's Age 2"
        }
      ]
    }
  }
}

export const sortingMenuItems = {
  RELEVANCE: {
    label: "Relevance",
    value: "relevance"
  },
  PRICE_LOWEST_FIRST: {
    label: "Price lowest first",
    value: "price_lowest_first"
  },
  PRICE_HIGHEST_FIRST: {
    label: "Price highest first",
    value: "price_highest_first"
  }
}