export const NOTIFICATION_INITIAL_STATE = {
    sendOtp: {
        isLoading: false,
        sendOtp: null,
        errorMsg: null
    },

    verifyOtp: {
        isLoading: false,
        verifyOtp: null,
        errorMsg: null
    },
}