import tw, { styled } from "twin.macro";

export const StyledJitsiMeetComponent = styled.div`
  ${tw`absolute top[50%] left[50%] transform[translate(-50%, -50%)] z-50`}
`;

export const StyledCloseButton = styled.div`
  ${tw`absolute top-0 right-0 m-2 cursor-pointer z-index[1000]`}
`;

export const StyledJitsiRenderSpinner = styled.div`
  ${tw`absolute flex items-center justify-center bg-black z-50 top[50%] left[50%] transform[translate(-50%, -50%)] height[100vh] width[100vw]`}
`;
