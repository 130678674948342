import { MdOutlineCloudDone } from 'react-icons/md';
import { MdOutlinePendingActions } from 'react-icons/md';
import { AiOutlineSchedule } from 'react-icons/ai';
import { CgMediaLive } from 'react-icons/cg';
import { AiOutlineFileDone } from 'react-icons/ai';
import { BsEject } from 'react-icons/bs';
import { MdCancelPresentation } from 'react-icons/md';

import { eventStatus } from 'redux/event/event.const';


export const pageHeading = {
    heading: " My Schedule & Calendar",
    subHeading: "See your daily, weekly or monthly schedule in your personalised calendar",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/schedule.svg"
}

export const statData = {
    ALL: {
        ...eventStatus.ALL,
        lable: 'Total Session',
        icon: <MdOutlineCloudDone className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    },
    PENDING: {
        ...eventStatus.PENDING,
        lable: 'Pending Session',
        icon: <MdOutlinePendingActions className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    },
    SCHEDULED: {
        ...eventStatus.SCHEDULED,
        lable: 'Schedule Session',
        icon: <AiOutlineSchedule className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    },
    ON_GOING: {
        ...eventStatus.ON_GOING,
        lable: 'On Going Session',
        icon: <CgMediaLive className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    },
    COMPLETED: {
        ...eventStatus.COMPLETED,
        lable: 'Completed Session',
        icon: <AiOutlineFileDone className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    },
    DECLINED: {
        ...eventStatus.DECLINED,
        lable: 'Declined Session',
        icon: <BsEject className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    },
    CANCELLED: {
        ...eventStatus.CANCELLED,
        lable: 'Cancelled Session',
        icon: <MdCancelPresentation className={"text-3xl text-secondary-dark"} />,
        data: 0,
        unit: 'All time',
    }
}