import {
    DashboardCustomize,
    EventNoteTwoTone,
    SupportAgentOutlined,
    AccountBalanceWallet,
    Message,
    People,
    LibraryBooks,
    AccountCircle,
    RoomPreferences,
    LocalOffer,
    CollectionsBookmark,
    School,
    Subscriptions,
    CardGiftcard,
    Settings,
    ContactSupport,
    Logout,
} from '@mui/icons-material';

import { userRoles } from 'redux/auth/auth.const';

export const navLinkList = [
    {
        icon: DashboardCustomize,
        name: 'Dashboard',
        path: '/dashboard',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: EventNoteTwoTone,
        name: 'Calendar',
        path: '/calendar',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: SupportAgentOutlined,
        name: 'Tools',
        path: '/tools',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: AccountBalanceWallet,
        name: 'Wallet',
        path: '/wallet',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: Message,
        name: 'Messages',
        path: '/message',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: People,
        name: 'My Tutors',
        path: '/my-tutors',
        roles: [userRoles.STUDENT.value]
    },
    {
        icon: LibraryBooks,
        name: 'My Classes',
        path: '/my-classes',
        roles: [userRoles.STUDENT.value]
    },
    {
        icon: People,
        name: 'My Students',
        path: '/my-students',
        roles: [userRoles.TUTOR.value]
    },
    {
        icon: AccountCircle,
        name: 'Profile',
        path: '/profile',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: RoomPreferences,
        name: 'Preferences',
        path: '/preferences',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: LocalOffer,
        name: 'Classes',
        path: '/classes',
        roles: [userRoles.TUTOR.value]
    },
    {
        icon: CollectionsBookmark,
        name: 'Bookings',
        path: '/bookings',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: School,
        name: 'Referral',
        path: '/referral',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: Subscriptions,
        name: 'Subscriptions',
        path: '/subscriptions',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: CardGiftcard,
        name: 'Buy Gift Card',
        path: '/buy-gift-card',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: Settings,
        name: 'Settings',
        path: '/settings',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: ContactSupport,
        name: 'Support',
        path: '/support',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
    {
        icon: Logout,
        name: 'Log Out',
        path: '#',
        roles: [userRoles.STUDENT.value, userRoles.TUTOR.value],
    },
]