import {
  AvailabilityStatus,
  PreferenceStatus,
  PricingPreferences,
  SubjectPreference,
} from "./types";

export const MOCK_AVAILABILITIES: AvailabilityStatus[] = [
  {
    day: "Monday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "04:00 AM",
        endTime: "06:00 AM",
      },
    ],
  },
  {
    day: "Tuesday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
    ],
  },
  {
    day: "Wednesday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
    ],
  },
  {
    day: "Thursday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
    ],
  },
  {
    day: "Friday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
    ],
  },
  {
    day: "Saturday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
    ],
  },
  {
    day: "Sunday",
    schedule: [
      {
        _id: Math.floor(Math.random() * 10000),
        startTime: "10:00 AM",
        endTime: "12:00 AM",
      },
    ],
  },
];

export const MOCK_LESSON: PreferenceStatus[] = [
  {
    id: "automatically_confirm_lessons",
    title: "Automatically Confirm Lessons",
    description:
      "Using this option would confirm all lesson request automatically",
    status: false,
  },
  {
    id: "accept_lesson_request_current_student",
    title: "Accept Lesson Request from current student",
    description: "",
    status: true,
  },
  {
    id: "accept_lesson_request_new_student",
    title: "Accept Lesson Request from new student",
    description: "",
    status: false,
  },
  {
    id: "instant_booking_current_student",
    title: "Instant Booking From Current Student",
    description: "",
    status: false,
  },
  {
    id: "instant_booking_new_student",
    title: "Instant Booking From New Student",
    description: "",
    status: false,
  },
];

export const MOCK_OTHER_PREFERENCES: PreferenceStatus[] = [
  {
    id: "personalized_recommendation",
    title: "Personal recommendation",
    description: "Allow personal recommendation",
    status: false,
  },
  {
    id: "in_for_content_creation_work",
    title: "Count me in for content creation work",
    description: "You will create your own content",
    status: true,
  },
  {
    id: "in_for_paid_influencer_work",
    title: "Count me in for paid influencer work",
    description: "",
    status: false,
  },
];

export const MOCK_PRICING_PREFERENCES: PricingPreferences = {
  trial_lesson: {
    time: 30,
    price: 100,
  },
  discount: 10,
  minimum_hourly_price_group: 30,
  minimum_hourly_price_one_to_one: 30,
};

export const MOCK_SUBJECT_PREFERENCES: SubjectPreference[] = [];
