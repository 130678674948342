import React, { useEffect, useState } from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Hook imports
import useMediaQuery from "../../hooks/useMediaQuery";

//Helper functions
import chatName from "../../helpers/chatName";

//Icon imports
import { XCircleIcon } from "@heroicons/react/outline";

//style imports
import {
  StyledChatViewHeaderUser,
  StyledViewChatViewHeaderUserImage,
  StyledViewChatViewHeaderUserImageContainer,
  StyledChatViewHeaderUserText,
  StyledChatViewHeaderPresence,
} from "./ChatViewHeader.styles";

//firebase imports
import { doc, getDoc } from "firebase/firestore";
import { database } from "config/firebase"
import getLastSeen from "../../helpers/lastSeen";

export default function ChatViewHeaderUser({ toggleGroupDetails }) {
  const { selectedChat, dispatch } = useChat();
  const [chatUserDetails, setChatUserDetails] = useState(null);

  const isMobile = useMediaQuery("(max-width: 768px)");
  const { user } = useUser();
  const { userId } = user;
  const { type } = selectedChat;

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  useEffect(() => {
    if (type === "group") return;
    async function fetchUserDetails() {
      const userRef = doc(database, "users", otherUser);
      const response = await getDoc(userRef);
      const details = { id: response.id, ...response.data() };
      setChatUserDetails(details);
    }
    fetchUserDetails();
  }, [otherUser, type]);

  //Get chat image
  function imageToDisplay() {
    if (selectedChat.type === "group") {
      const { image } = selectedChat;
      return image;
    }

    const userDetails = selectedChat[otherUser];
    const { image } = userDetails;
    return image;
  }

  const chatNameToDisplay = chatName(selectedChat, user);
  const formattedChatName =
    chatNameToDisplay.length > 20
      ? `${chatNameToDisplay.substring(0, 20)}...`
      : chatNameToDisplay;

  function closeChatView() {
    dispatch({
      type: "SET_SELECTED_CHAT",
      payload: null,
    });
  }

  function showLastSeen() {
    if (chatUserDetails?.available) return "Available";
    if (!chatUserDetails?.available) {
      const lastSeen = getLastSeen(chatUserDetails?.lastSeen);
      return `Last seen ${lastSeen}`;
    }
  }

  const isGroup = selectedChat.type === "group";

  return (
    <StyledChatViewHeaderUser onClick={toggleGroupDetails} isGroup={isGroup}>
      {isMobile && (
        <XCircleIcon
          onClick={closeChatView}
          className="w-6 h-6 text-secondary-dark cursor-pointer mr-1"
        />
      )}
      {!isMobile && (
        <StyledViewChatViewHeaderUserImageContainer>
          <StyledViewChatViewHeaderUserImage
            src={imageToDisplay()}
            alt="user avatar"
          />
        </StyledViewChatViewHeaderUserImageContainer>
      )}
      <div>
        <StyledChatViewHeaderUserText>
          {formattedChatName}
        </StyledChatViewHeaderUserText>
        {chatUserDetails && chatUserDetails.lastSeen && (
          <StyledChatViewHeaderPresence>
            {showLastSeen()}
          </StyledChatViewHeaderPresence>
        )}
      </div>
    </StyledChatViewHeaderUser>
  );
}
