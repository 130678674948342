import { useState, useEffect } from "react";

import { MdEdit } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setOfferingCourse } from "redux/offering/offering.slice";

import { getYouTubeVideoIdFromUrl } from "utils/generators.utils";

const VideoSelector: React.FC<{
    url: string;
}> = ({ url }) => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const [isVideoSrcEditable, setIsVideoEditable] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        setVideoUrl(`https://www.youtube.com/watch?v=${offeringCourse?.offeringCourse?.videoUrl}`)
    }, [offeringCourse?.offeringCourse?.videoUrl])

    const updateVideoSrc = () => {
        const urlKey = getYouTubeVideoIdFromUrl(videoUrl)
        dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, videoUrl: urlKey }))
    };

    return (
        <div className="relative rounded-t-lg overflow-hidden w-full h-48">
            <div className="absolute right-0 flex items-center justify-end space-x-1 left-8 top-1">
                {isVideoSrcEditable && (
                    <input
                        type="text"
                        className="w-full px-2 py-1 text-sm bg-white focus:outline-none focus:ring focus:ring-offset-1"
                        placeholder="url"
                        value={videoUrl}
                        onChange={(e) => setVideoUrl(e.target.value)}
                    />
                )}
                <button
                    className="flex items-center w-6 h-6 bg-white rounded-full"
                    onClick={() => {
                        if (isVideoSrcEditable) {
                            updateVideoSrc();
                        }
                        setIsVideoEditable(!isVideoSrcEditable);
                    }}
                >
                    <span className="m-auto">
                        {isVideoSrcEditable ? (
                            <AiFillCheckCircle className="text-secondary-dark" size={25} />
                        ) : (
                            <MdEdit className="text-secondary-dark" size={25} />
                        )}
                    </span>
                </button>
            </div>
            <iframe
                className="w-full rounded-t-sm h-full"
                src={url}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            >
            </iframe>
        </div>
    );
};

export default VideoSelector;