// get the video id from video url
export const getYouTubeVideoIdFromUrl = (url) => {
    // Our regex pattern to look for a youTube ID
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    //Match the url with the regex
    const match = url.match(regExp);
    //Return the result
    return match && match[2].length === 11 ? match[2] : '';
};


// get the id from vimeo url
export const getVimeoIdFromUrl = (url) => {
    // Look for a string with 'vimeo', then whatever, then a
    // forward slash and a group of digits.
    const match = /vimeo.*\/(\d+)/i.exec(url);
    // If the match isn't null (i.e. it matched)
    if (match) {
        // The grouped/matched digits from the regex
        return match[1];
    }
};


// generate your own urls for youtube
export const generateYouTubeUrl = (videoId)=> {
    return `//www.youtube.com/embed/${videoId}?autoplay=1&autohide=1&showinfo=0&modestbranding=1&controls=0&mute=0&rel=0&enablejsapi=1`;
};


// generate your own urls for vimeo
export const generateVimeoUrl = (videoId) => {
    return `https://player.vimeo.com/video/${videoId}?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&#t=235s`;
};


// Convert queryString to queryParma object
export const generateQueryParams = (search) => {
    const query = {}
    search && search.slice(1).split("&").forEach(searchItem => {
        let keyValue = searchItem.split("=")
        query[keyValue[0]] = keyValue[1]
    })
    return query
}


// Convert a Base64-encoded string to a File object
export function base64StringToFile(base64String, filename) {
    var arr = base64String.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n)
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
}


// Download a Base64-encoded file
export function downloadBase64File(base64Data, filename) {
    var element = document.createElement('a')
    element.setAttribute('href', base64Data)
    element.setAttribute('download', filename)
    element.style.display = 'none'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
}


// Extract an Base64 Image's File Extension
export function extractImageFileExtensionFromBase64(base64Data) {
    return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
}
