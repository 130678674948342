import tw, { styled } from 'twin.macro';
import { NavLink as RouteLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
    ArrowBackIosNewOutlined,
    ArrowForwardIosOutlined
} from '@mui/icons-material';
import { School } from '@mui/icons-material';
import { FaChalkboardTeacher } from 'react-icons/fa';

import { sideTopBarPagesName } from 'utils/pagesInfo';


export const Container = styled.div`
    ${tw`
        hidden
    `}
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        h-auto w-screen bg-white fixed z-30 top-[4rem]
        bg-white py-2 px-5 border-b-2 border-t-2 border-divider-medium
        flex items-center overflow-x-auto scrollbar-none select-none
        lg:hidden
    `}
`;

export const ArrowLeftIcon = tw(ArrowBackIosNewOutlined)`
    fixed z-50 left-0 bg-white border border-text-500 w-auto px-1 py-1
    text-4xl text-text-500 rounded-full ml-2 cursor-pointer shadow
    hover:(bg-primary-main text-text-50)
`;

export const ArrowRightIcon = tw(ArrowForwardIosOutlined)`
    fixed z-50 right-0 bg-white border border-text-500 w-auto px-1 py-1
    text-4xl text-text-500 rounded-full mr-2 cursor-pointer shadow
    hover:(bg-primary-main text-text-50 border-primary-main)
`;

export const ListItems = styled.div`
    ${tw`
        flex gap-5 items-center mx-10  whitespace-nowrap
    `}
    .active {
        ${tw`
            bg-primary-main text-text-50 hover:(bg-primary-dark border-primary-dark)
        `}
    }
`;

export const ListItem = tw.span`
    px-3 py-1 flex flex-row items-center gap-2
    rounded-full font-bodyPri font-normal text-base text-primary-main
    border border-primary-main
    hover:(bg-primary-light) cursor-pointer
`;

export const ItemLink = tw(RouteLink)`
    px-3 py-1 flex flex-row items-center gap-2
    rounded-full font-bodyPri font-normal text-base text-primary-main
    border border-primary-main
    hover:(bg-primary-light) cursor-pointer
`;

export const StudentIcon = motion(tw(School)``);

export const TutorIcon = motion(tw(FaChalkboardTeacher)`
    text-2xl
`);