import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { GiGraduateCap } from "react-icons/gi"
import { FaRegComment } from "react-icons/fa"

import CourseCard from 'components/courseCard/CourseCard';
import FullPageLoader from 'components/loader/FullPageLoader';
import Icon from "components/Icon";
import Pagination from 'components/pagination/Pagination';
import RatingStars from "components/ratingStars/RatingStars";
import ReviewCard from "components/ReviewCard";
import Footer from 'components/footer/Footer';

import { PageContentStyle } from './TutorCoursePage.style';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorUserPublicProfileByUserId } from 'redux/tutor/tutor.request';
import {
    createFavCourseUserDetailByUserId,
    deleteFavCourseDetailByUserId,
    getCourseTutorUserPublicListByUserId
} from "redux/course/course.request";
import { getTutorReviewListByTutorId } from "redux/review/review.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearCourseList } from "redux/course/course.slice";
import { setClearTutorPublicProfile } from "redux/tutor/tutor.slice";
import { setClearTutorReviewList } from 'redux/review/review.slice';

import { pagesInfo } from 'utils/pagesInfo';

const TutorCourse = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { user } = useAppState((s) => s.user)
    const { tutorPublicProfile } = useAppState((s) => s.tutor)
    const { tutorReviewList } = useAppState((s) => s.review)
    const { tutorCourseList } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()
    const { userId } = useParams()
    const navigate = useNavigate()

    const [firstIndex, setFirstIndex] = useState(0)
    const [lastIndex, setLastIndex] = useState(5)
    const [itemsPerPage] = useState(5)

    const NEW_TUTOR_PUBLIC_PROFILE_RATING = 2;
    const loadingElement = tutorPublicProfile?.isLoading || tutorCourseList?.isLoading || tutorReviewList?.isLoading

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.TUTOR_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getTutorUserPublicProfileByUserId(Number(userId)))
        dispatcher(getCourseTutorUserPublicListByUserId(Number(userId), { page: 1, records: 5 }))

        return () => {
            dispatcher(setClearTutorPublicProfile())
            dispatcher(setClearCourseList())
        }
    }, [])

    useEffect(() => {
        if (tutorPublicProfile?.tutorPublicProfile?.tutorId) {
            dispatcher(getTutorReviewListByTutorId(tutorPublicProfile?.tutorPublicProfile?.tutorId))
        }

        return () => {
            dispatcher(setClearTutorReviewList())
        }
    }, [tutorPublicProfile?.tutorPublicProfile])


    useEffect(() => {
        setFirstIndex(0)
        setLastIndex(itemsPerPage)
    }, [itemsPerPage, tutorCourseList?.tutorCourseList])

    if (loadingElement) {
        return (
            <FullPageLoader isLoading={loadingElement} />
        )
    }

    return (
        <PageContentStyle>
            <div className='space-y-5'>
                <div className='relative w-full h-96 bg-[#303030]'>
                    <div className='absolute z-30 top-10 w-full p-5 flex justify-center items-center'>
                        {tutorPublicProfile?.tutorPublicProfile &&
                            <div className="md:max-w-3xl lg:max-w-6xl w-full p-5">
                                <div className="grid grid-cols-12 gap-5 py-3">
                                    <div className="col-start-1 col-span-9 md:col-start-1 md:col-span-5 lg:col-start-1 lg:col-span-4 space-y-3 p-2">
                                        <div className="flex gap-3 text-2xl font-bodyPri tracking-wide">
                                            <div className="flex items-center gap-2 font-bodyPri tracking-wide text-text-50">{tutorPublicProfile?.tutorPublicProfile?.firstName} {tutorPublicProfile?.tutorPublicProfile?.lastName}
                                                {tutorPublicProfile?.tutorPublicProfile?.onlineStatus == "online" && <div className={"w-3 h-3 rounded-full bg-green-600"}></div>}
                                            </div>
                                            {tutorPublicProfile?.tutorPublicProfile?.instantBooking &&
                                                <div className="">
                                                    <Icon type="light" />
                                                </div>}
                                        </div>
                                        <div className="font-bodyPri text-lg text-text-50">
                                            {tutorPublicProfile?.tutorPublicProfile?.elevatorPitch}
                                        </div>
                                        {tutorPublicProfile?.tutorPublicProfile?.ratingCount < NEW_TUTOR_PUBLIC_PROFILE_RATING
                                            ? <div className='h-1 w-5 bg-secondary-main rounded-lg'></div>
                                            : <div className='h-1 w-5 bg-primary-main rounded-lg'></div>
                                        }

                                        {/* <div className='flex gap-2 items-center'>
                                            {tutorPublicProfile?.tutorPublicProfile?.badges?.slice(0, 3).map((items, index) => (
                                                <div key={index} className='relative has-tooltip w-8 h-8 rounded-full border border-text-500 p-0.5 cursor-pointer'>
                                                    <img src={items.badgePicUrl} alt={items.badgeCategory}
                                                        className={"w-full h-full object-cover"}
                                                    />
                                                    <span className='tooltip top-8 -left-5 px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide'>
                                                        {items.badge}
                                                    </span>
                                                </div>
                                            ))}
                                            {tutorPublicProfile?.tutorPublicProfile?.badges?.length > 3 &&
                                                <span className='relative has-tooltip text-primary-main font-medium text-lg cursor-pointer'>
                                                    {`+${tutorPublicProfile?.tutorPublicProfile?.badges?.length - 3}`}
                                                    <span className='tooltip top-8 -left-5 w-20 px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide'>
                                                        {"see more"}
                                                    </span>
                                                </span>
                                            }
                                        </div> */}

                                        <div className="flex flex-col gap-3">
                                            <div className="flex items-center text-base font-bodyPri tracking-wide gap-2">
                                                <FaRegComment className="text-text-400" size={24} />
                                                <span className="font-normal font-bodyPri tracking-wide text-text-50">
                                                    {"Speaks:"}
                                                </span>
                                                {tutorPublicProfile?.tutorPublicProfile?.languages?.map((language, index) => (
                                                    <span
                                                        key={index}
                                                        className="flex justify-center items-center gap-1 font-medium font-bodyPri tracking-wide text-medium text-text-50"
                                                    >
                                                        {index !== 0 &&
                                                            <p>{"|"}</p>
                                                        }
                                                        {language?.language}
                                                    </span>
                                                ))}
                                            </div>
                                            <div className="flex items-start justify-start text-base font-bodyPri tracking-wide gap-2 overflow-hidden">
                                                <GiGraduateCap className='text-text-500' size={24} />
                                                <span className="font-normal font-bodyPri tracking-wide text-text-50">
                                                    {"Teaches:"}
                                                </span>
                                                <span className="font-medium font-bodyPri tracking-wide text-text-50 text-medium">
                                                    {tutorPublicProfile?.tutorPublicProfile?.categories.slice(0, 2).join(" | ")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-start-10 col-span-2 md:col-start-8 md:col-span-4 lg:col-start-6 lg:col-span-6 flex items-center flex-col gap-3">
                                        <div className="relative w-20 h-20 rounded-full sm:w-20 sm:h-20 md:w-36 md:h-36">
                                            <img
                                                src={tutorPublicProfile?.tutorPublicProfile?.profilePicUrl}
                                                alt="profileImg"
                                                className="w-full h-full rounded-full p-2 object-cover"
                                            />
                                            <div className='absolute bottom-2.5 right-2.5 w-[18%] h-[18%] rounded-full overflow-hidden border-2 border-white sm:bottom-3 sm:right-3 md:bottom-4 md:right-4 lg:bottom-4 lg:right-4'>
                                                <img src={`https://countryflagsapi.com/png/${tutorPublicProfile?.tutorPublicProfile?.fromCountryDomain}`} alt={"country-flag"}
                                                    className='w-full h-full object-cover'
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-center gap-1">
                                            {tutorPublicProfile?.tutorPublicProfile?.avgRating > NEW_TUTOR_PUBLIC_PROFILE_RATING
                                                ? <><RatingStars
                                                    rating={tutorPublicProfile?.tutorPublicProfile?.avgRating}
                                                    fullRating={5}
                                                />
                                                    <div className="font-normal text-xs text-complementry-main">
                                                        {tutorPublicProfile?.tutorPublicProfile?.avgRating} ({tutorPublicProfile?.tutorPublicProfile?.ratingCount})
                                                    </div>
                                                </>
                                                : <div className='text-sm font-bodyPri tracking-wide text-complementry-main'>
                                                    {"New"}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {tutorPublicProfile?.errorMsg &&
                            <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                                {tutorPublicProfile?.errorMsg}
                            </div>
                        }
                    </div>
                </div>
                <div className='flex justify-center items-center'>
                    <div className='max-w-sm md:max-w-3xl lg:max-w-6xl w-full space-y-5'>
                        <div className="py-3 px-5 bg-white rounded-lg shadow-md">
                            <span className='font-bodyPri font-medium text-lg tracking-wide text-text-900'>
                                {"My Classes"}
                            </span>
                        </div>

                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                            {tutorCourseList?.tutorCourseList?.records > 0
                                && tutorCourseList?.tutorCourseList?.results?.slice(firstIndex, lastIndex).map((course, idx) => (
                                    <div className='col-span-1'>
                                        <CourseCard
                                            key={idx}
                                            course={course}
                                            isFav={user?.user?.favCourses?.filter((favCourse) =>
                                                favCourse.courseId === course.courseId).length > 0 ? true : false}
                                            handleFav={(courseId) => {
                                                const favoriteCourses = user?.user?.favCourses?.filter((favCourse) =>
                                                    favCourse.courseId === courseId)
                                                if (favoriteCourses?.length > 0) {
                                                    dispatcher(deleteFavCourseDetailByUserId(user?.user?.userId, { courseId: courseId }))
                                                }
                                                else {
                                                    dispatcher(createFavCourseUserDetailByUserId(user?.user?.userId, { courseId: courseId }))
                                                }
                                            }}
                                        />
                                    </div>
                                ))}
                        </div>
                        {tutorCourseList?.tutorCourseList && tutorCourseList?.tutorCourseList?.records > 0 && (
                            <div className="w-full flex justify-center items-center">
                                <Pagination
                                    page={tutorCourseList?.tutorCourseList?.page}
                                    totalPages={tutorCourseList?.tutorCourseList?.totalPages}
                                    onChangePage={(page) => navigate(`${pagesInfo.TUTOR_COURSE.pagePath}/${userId}/course` + "?pn=" + page)}
                                />
                            </div>
                        )}
                        {(tutorCourseList?.tutorCourseList?.records === 0 || tutorCourseList?.errorMsg) &&
                            <span className='w-full h-full col-span-10 text-center text-base font-bodyPri tracking-wide pt-5'>
                                {tutorCourseList?.errorMsg || "No Class Found!"}
                            </span>}
                    </div>
                </div>
                <div className='p-5 bg-white'>
                    <div className='flex justify-center items-center'>
                        <div className="max-w-sm md:max-w-3xl lg:max-w-6xl w-full space-y-8">
                            <div className="flex items-center">
                                <span className='font-bodyPri font-medium text-lg tracking-wide text-text-900'>
                                    {"Reviews"}
                                </span>
                                {tutorReviewList?.filteredTutorReviewList?.avgRating && <RatingStars rating={tutorReviewList?.filteredTutorReviewList?.avgRating} fullRating={5} />}
                                <span className="font-medium text-md">
                                    {tutorReviewList?.filteredTutorReviewList?.avgRating} ({tutorReviewList?.filteredTutorReviewList?.ratingCount})
                                </span>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                                {tutorReviewList?.filteredTutorReviewList?.reviews &&
                                    tutorReviewList?.filteredTutorReviewList?.reviews?.map((ele, idx) => (
                                        <div className='col-span-1'>
                                            <ReviewCard
                                                key={idx}
                                                rating={ele?.rating}
                                                content={ele?.review}
                                                profileImg={ele?.provider?.profilePicUrl}
                                                fName={ele?.provider?.firstName}
                                                lName={ele?.provider?.lastName}
                                                createdAt={ele?.createdAt}
                                            />
                                        </div>
                                    ))}
                                {(tutorReviewList?.length === 0 || tutorReviewList?.errorMsg) &&
                                    <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                                        {tutorReviewList?.errorMsg || "No reviews Found!"}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </PageContentStyle>
    )
}

export default TutorCourse;