import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Menu from "@mui/material/Menu";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";

import Icon from "components/Icon";

import { menu, menuItems } from './data';
import { useAppDispatcher } from "hooks/useStore";
import { updateOfferingByCourseId } from "redux/offering/offering.request";


const useStyles = makeStyles({
  root: {},
});


const MyMenu = ({ offering }) => {
  const dispatcher = useAppDispatcher()
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const location = useLocation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (ele) => {
    setAnchorEl(null);
    switch (ele.value) {
      case menuItems.SHARE_LINK.value:
        break;
      case menuItems.CLONE.value:
        break;
      case menuItems.MAKE_PRIVATE.value:
        dispatcher(updateOfferingByCourseId(offering?.courseId, { visibility: menuItems.MAKE_PRIVATE.value, courseType: offering?.courseType }))
        break;
      case menuItems.MAKE_PUBLIC.value:
        dispatcher(updateOfferingByCourseId(offering?.courseId, { visibility: menuItems.MAKE_PUBLIC.value, courseType: offering?.courseType }))
        break;
      case menuItems.MODIFY_DETAILS.value:
        const from = location.state?.from?.pathName || `/edit-class/${offering?.courseId}`
        navigate(from)
        break;
      default:
    }
  };

  return (
    <div>
      <div onClick={handleClick}>
        <Icon type="threedot" />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        classes={{
          root: classes.root,
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {
          offering && menu[offering?.status.toUpperCase()] && menu[offering?.status.toUpperCase()][offering?.visibility].map((ele, idx) =>
            <MenuItem key={idx} onClick={() => handleClose(ele)} value={ele.label}>{ele.label}</MenuItem>
          )
        }
      </Menu>
    </div>
  );
};

export default MyMenu;
