import { useState } from 'react';
import {
    Container,
    Content,
    Header,
    Heading,
    SubHeading,
    ItemContainer,
    TabsController,
    TabItem,
    FeaturedTutorContainer,
} from './FeaturedTutorsStyle';
import { tutorsTabData } from './FeaturedTutorsData';
import FeaturedTutorItem from './FeaturedTutorItem';

const FeaturedTutors = () => {
    const tabKeys = Object.keys(tutorsTabData)
    const [activeTab, setActiveTab] = useState(tabKeys[0])

    const featuredTutorsVarient = {
        current: {
            opacity: 1,
            scale: 1,
            display: 'flex',
        },
        hidden: {
            opacity: 0,
            scale: 0.8,
            display: "none",
        }
    }

    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'Featured tutors'}</Heading>
                    <SubHeading>{'Meet our featured educators'}</SubHeading>
                </Header>
                <ItemContainer>
                    <TabsController>
                        {tabKeys.map((tabItem, index) => (
                            <TabItem key={index}
                                isActive={tabItem === activeTab}
                                onClick={() => setActiveTab(tabItem)}
                            >
                                {tabItem}
                            </TabItem>
                        ))}
                    </TabsController>
                    {tabKeys.map((tabKey, index) => (
                        <FeaturedTutorContainer key={index}
                            variants={featuredTutorsVarient}
                            initial={activeTab === tabKey ? "current" : "hidden"}
                            animate={activeTab === tabKey ? "current" : "hidden"}
                        >
                            <FeaturedTutorItem tutorItem={tutorsTabData[activeTab]} />
                        </FeaturedTutorContainer>
                    ))}
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default FeaturedTutors
