import { IoMdPeople } from 'react-icons/io';
import { MdGroups } from 'react-icons/md';

import { useAppState } from 'hooks/useStore';
import { courseType } from 'redux/course/course.const';


const EventDetailCourseType = () => {
    const { eventDetail } = useAppState(s => s.event)

    return eventDetail?.eventDetail?.course?.courseType ? (
        <div className={"flex items-center justify-start gap-2"}>
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
                {"Course Type: "}
            </span>
            <div className={"flex items-center justify-center gap-1"}>
                {courseType[eventDetail?.eventDetail?.course?.courseType.toUpperCase()].value === courseType.ONE_ON_ONE.value &&
                    <IoMdPeople className={"text-lg text-text-900"} />
                }
                {courseType[eventDetail?.eventDetail?.course?.courseType.toUpperCase()].value === courseType.GROUP.value &&
                    <MdGroups className={"text-lg text-text-900"} />
                }
                <span className={"font-bodyPri font-medium text-md text-text-900"}>
                    {courseType[eventDetail?.eventDetail?.course?.courseType.toUpperCase()].label}
                </span>
            </div>
        </div>
    ) : null
}

export default EventDetailCourseType