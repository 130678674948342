import React, { useState } from "react";

//Firebase
import { Timestamp } from "firebase/firestore";

//helper imports
import sendMessage from "../../helpers/sendMessage";
import updateTyping from "../../helpers/updateTyping";
import cloudStorageFileUpload from "../../helpers/fileUpload";
import sendNotification from "../../helpers/sendNotification";

//Helper functions
import markMessagesRead from "../../helpers/markMessagesRead";

//Component imports
import ChatViewSendMessageAttach from "./ChatViewSendMessageAttach";
import ChatViewSendMessageSend from "./ChatViewSendMessageSend";
import ChatViewSendMessageMenu from "./ChatViewSendMessageMenu";

//Context imports
import { useUser } from "../../contexts/userContext";
import { useChat } from "../../contexts/chatContext";

//Style imports
import {
  StyledChatViewSendMessage,
  StyledChatViewSendMessageInput,
  StyledChatViewSendMessageTyping,
} from "./ChatViewSendMessage.styles";
//LxMGncg4PVaMLfXnIawl - Test User
//30DGqtQkMtunzE7hXzCn- Jane Doe

export default function ChatViewSendMessage({ setShowCourseMenu }) {
  const { user } = useUser();
  const { selectedChat, chats, messages, groupMembers } = useChat();
  const [messageText, setMessageText] = useState("");
  const [textMessageError, setTextMessageError] = useState(null);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const isGroup = selectedChat.type === "group";
  const isTutor = user.roles.includes("tutor");

  const userName = `${user.firstName} ${user.lastName}`;

  const { userId } = user;

  const syncedSelectedChat = chats.filter(
    (chat) => chat.id === selectedChat.id
  )[0];

  const otherChatUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  function getOtherChatUserName() {
    if (selectedChat.type !== "group") {
      const otherChatUserName = selectedChat[otherChatUser].name;
      return otherChatUserName;
    }
  }

  //Update message text state
  async function handleMessage(e) {
    if (textMessageError) {
      setTextMessageError(null);
    }
    const text = e.target.value;
    setMessageText(text);
  }

  //Send message
  async function sendTextMessage() {
    if (messageText.trim() === "") return;
    const sender = userId;
    // const testReciever = "LxMGncg4PVaMLfXnIawl";
    const individualMessageObject = {
      sender: sender,
      text: messageText,
      type: "text",
      status: "sent",
      sentAt: Timestamp.fromDate(new Date()),
    };
    const groupMessageObject = {
      sender: sender,
      text: messageText,
      type: "text",
      sentAt: Timestamp.fromDate(new Date()),
    };

    const individualNotificationObject = {
      fromName: userName,
      fromId: userId,
      receiver: otherChatUser,
      type: "text",
      sentAt: Timestamp.fromDate(new Date()),
      seen: false,
      chatId: selectedChat.id,
      chatType: selectedChat.type,
    };

    const groupNotificationObject = {
      sentAt: Timestamp.fromDate(new Date()),
      type: "text",
    };

    const messageObject = isGroup
      ? groupMessageObject
      : individualMessageObject;

    const notificationObject = isGroup
      ? groupNotificationObject
      : individualNotificationObject;

    try {
      setMessageText("");
      await sendMessage(messageObject, selectedChat, groupMembers);
      await sendNotification(
        notificationObject,
        selectedChat,
        groupMembers,
        user
      );
      if (!isGroup) {
        await updateTyping(selectedChat.id, false, userId);
      }
    } catch (err) {
      setTextMessageError("Error sending message");
      console.log(err);
    }
  }

  //Send file
  async function uploadFile(e) {
    if (fileUploadError) setFileUploadError(null);
    setFileUploading(true);
    try {
      const file = e.target.files[0];
      const fileDetails = await cloudStorageFileUpload(
        file,
        user,
        selectedChat
      );
      const individualNotificationObject = {
        fromName: userName,
        fromId: userId,
        receiver: otherChatUser,
        type: "file",
        sentAt: Timestamp.fromDate(new Date()),
        seen: false,
        chatId: selectedChat.id,
        chatType: selectedChat.type,
      };

      const groupNotificationObject = {
        sentAt: Timestamp.fromDate(new Date()),
        type: "file",
      };

      const notificationObject = isGroup
        ? groupNotificationObject
        : individualNotificationObject;

      await sendMessage(fileDetails, selectedChat, groupMembers);
      await sendNotification(
        notificationObject,
        selectedChat,
        groupMembers,
        user
      );
      setFileUploading(false);
    } catch (err) {
      setFileUploadError(true);
      setFileUploading(false);
      console.log(err);
    }
  }

  //Enter key sends message, other keys update typing state
  async function handleEnterKey(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      await sendTextMessage();
    } else {
      if (isGroup) return;
      await updateTyping(selectedChat.id, true, userId);
    }
  }

  //Set typing status to false
  async function removeTypingState() {
    if (isGroup) return;
    await updateTyping(selectedChat.id, false, userId);
  }

  //Set typing state to true when input is focused and has content
  //Also updates message status for groups and individual chats
  async function addTypingState() {
    if (messageText.trim() !== "") {
      await updateTyping(selectedChat.id, true, userId);
    }
    markMessagesRead(isGroup, groupMembers, messages, selectedChat, user);
  }

  //Open course menu
  function toggleCourseMenu() {
    setShowCourseMenu(true);
  }

  //Check if user is typing for individual chats
  function userTyping() {
    if (isGroup) return;
    const otherChatUserDetails = syncedSelectedChat[otherChatUser];
    return otherChatUserDetails.typing;
  }

  const isTyping = userTyping();

  return (
    <StyledChatViewSendMessage>
      <div>
        <ChatViewSendMessageAttach
          uploadFile={uploadFile}
          fileUploading={fileUploading}
          fileUploadError={fileUploadError}
        />
      </div>
      {isTutor && (
        <div onClick={toggleCourseMenu}>
          <ChatViewSendMessageMenu />
        </div>
      )}
      <div style={{ flexGrow: 3 }}>
        {isTyping && !isGroup && (
          <StyledChatViewSendMessageTyping>
            {`${getOtherChatUserName()} is typing...`}
          </StyledChatViewSendMessageTyping>
        )}
        <StyledChatViewSendMessageInput
          type="text"
          placeholder={textMessageError ?? "Type your message here"}
          value={messageText}
          onChange={handleMessage}
          onKeyDown={(e) => {
            handleEnterKey(e);
          }}
          onFocus={addTypingState}
          onBlur={removeTypingState}
          textMessageError={textMessageError}
        />
      </div>
      <div onClick={sendTextMessage}>
        <ChatViewSendMessageSend />
      </div>
    </StyledChatViewSendMessage>
  );
}
