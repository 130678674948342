import * as React from "react";
import { Button } from "components/common-components/Buttons";

export const AboutUs = () => {
  return (
    <div className="">
      <div className="py-4 mx-8 border-b">
        <p className="text-lg text-center">About US</p>
      </div>
      <div className="my-8">
        <p>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Porro rerum
          adipisci ullam accusantium molestiae accusamus, rem hic facere
          doloremque, voluptate, ipsam cumque quas obcaecati ipsum sit expedita
          magni esse temporibus.
        </p>
      </div>

      <div className="flex justify-center">
        <Button name="Begin" />
      </div>
    </div>
  );
};
