import { onBoardingStatus, titleOptions, genderOptions } from "pages/auth/tutorOnboard/TutorOnboardData"

const tutorOnboardingPayload = {
    formData: [],
    resumeFile: null,
    videoFile: null,
    referencesDetails: null
}

const addPersonalDetailPayload = {
    userId: 0,
    title: titleOptions.MR.value,
    firstName: "",
    lastName: "",
    preferredName: "",
    dateOfBirth: "",
    gender: genderOptions.MALE.value,
    email: "",
    countryCode: null,
    mobilePhone: "",
    homePhone: "",
    workPhone: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    countryName: "",
    facebookLink: "",
    instagramLink: "",
    twitterLink: "",
    linkedin: "",
    personalDetailStatus: null,
    isFormSubmitted: false,
}

const addDocumentPayload = {
    documentId: 0,
    fileCategory: null,
    fileName: "",
    file: null,
    fileUrl: "",
    documentStatus: null,
    isDocumentSubmitted: false
}

const categoryListPayload = {
    categoryListItems: null,
    resumeTask: false
}

const addRefrencePayload = {
    referenceId: 0,
    referenceName: "",
    referenceRelationship: "",
    knownYears: 0,
    knownMonths: 0,
    referenceMobile: "",
    referenceEmail: "",
    isEmailSent: false,
    isPrivacyEnabled: true,
    isFormSubmitted: false,
    referenceStatus: onBoardingStatus.NOT_SUBMITTED.value
}

export const weeklyTimeSlot = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

const addInterviewSlotPayload = {
    sessionDuration: 10,
    startDate: null,
    endDate: null,
    weeklyTimeSlot: weeklyTimeSlot
}

export const TUTOR_ONBOARDING_INITIAL_STATE = {
    tutorOnboarding: {
        isLoading: false,
        tutorOnboarding: null,
        tutorOnboardingPayload: tutorOnboardingPayload,
        errorMsg: null
    },
    personalDetail: {
        isLoading: false,
        personalDetail: null,
        errorMsg: null
    },
    addPersonalDetail: {
        isLoading: false,
        addPersonalDetailPayload: addPersonalDetailPayload,
        addPersonalDetail: null,
        errorMsg: null
    },
    documentList: {
        isLoading: false,
        documentList: [],
        errorMsg: null
    },
    addDocument: {
        isLoading: false,
        addDocumentPayload: addDocumentPayload,
        addDocument: null,
        errorMsg: null
    },
    categoryList: {
        isLoading: false,
        categoryList: categoryListPayload,
        errorMsg: null
    },
    referenceList: {
        isLoading: false,
        referenceList: [],
        errorMsg: null,

    },
    addReference: {
        isLoading: false,
        addReferencePayload: addRefrencePayload,
        addReference: null,
        errorMsg: null
    },
    interviewSlotList: {

    },
    addInterviewSlot: {
        isLoading: false,
        addInterviewSlotPayload: addInterviewSlotPayload,
        errorMsg: null
    }
}