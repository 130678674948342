import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//helper functions
import formatDate from "../../helpers/formatDate";

//style imports here
import {
  StyledChatListItem,
  StyledChatListItemImage,
  StyledChatListItemText,
  StyledChatListItemName,
  StyledChatListItemMessageText,
  StyledChatListItemImageContainer,
} from "./ChatListItem.styles";

export default function ChatListItem({ chat }) {
  const { user } = useUser();
  const { dispatch, selectedChat } = useChat();
  const { lastMessage } = chat;
  const { sentAt, type } = lastMessage;

  const { userId } = user;

  const isGroup = chat.type === "group";

  const paymentRequest = type === "paymentRequest";
  const file = type === "file";
  const message = type === "text";
  const shareCourse = type === "shareCourse";

  const dateOptions = { day: "numeric", month: "numeric", year: "numeric" };
  const dateToDisplay = formatDate(sentAt, dateOptions);

  const selected = selectedChat?.id === chat.id;

  const unread = lastMessage.status === "sent" && lastMessage.sender !== userId;

  //Get chat name
  function chatName() {
    if (isGroup) {
      const { name } = chat;
      const nameToDisplay = name.length > 20 ? name.slice(0, 20) + "..." : name;
      return nameToDisplay;
    } else {
      const otherUser = chat.users.filter((chatUser) => chatUser !== userId)[0];
      const nameToDisplay = chat[otherUser].name;
      return nameToDisplay;
    }
  }

  //Get chat image
  function chatImage() {
    if (isGroup) {
      const { image } = chat;
      return image;
    } else {
      const otherUser = chat.users.filter((chatUser) => chatUser !== userId)[0];
      const imageToDisplay = chat[otherUser].image;
      return imageToDisplay;
    }
  }

  //Select chat
  function selectChat() {
    if (selected) return;
    dispatch({
      type: "SET_MESSAGES",
      payload: [],
    });
    dispatch({
      type: "SET_SELECTED_CHAT",
      payload: chat,
    });
  }

  //Display message based on type
  function messageToDisplay() {
    if (paymentRequest) {
      const { amount } = lastMessage;
      return `Payment Request for $${amount}`;
    }
    if (file) {
      const { fileName } = lastMessage;
      const formattedFileName =
        fileName.length > 20 ? fileName.slice(0, 20) + "..." : fileName;
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      const toDisplay = `${fileExtension.toUpperCase()}: ${formattedFileName}`;
      return toDisplay;
    }
    if (message) {
      const { text } = lastMessage;
      const textToDisplay = text.length > 20 ? text.slice(0, 20) + "..." : text;
      return textToDisplay;
    }
    if (shareCourse) {
      const { course } = lastMessage;
      const messageInfo = `Shared Course: ${course}`;
      const textToDisplay =
        messageInfo.length > 20
          ? messageInfo.slice(0, 20) + "..."
          : messageInfo;
      return textToDisplay;
    }
  }

  return (
    <StyledChatListItem
      onClick={selectChat}
      selected={selected}
      unread={unread}
    >
      <StyledChatListItemImageContainer>
        <StyledChatListItemImage src={chatImage()} alt="user avatar" />
      </StyledChatListItemImageContainer>
      <StyledChatListItemText>
        <div>
          <StyledChatListItemName unread={unread}>
            {chatName()}
          </StyledChatListItemName>
          <p>{dateToDisplay}</p>
        </div>
        <StyledChatListItemMessageText unread={unread}>
          {messageToDisplay()}
        </StyledChatListItemMessageText>
      </StyledChatListItemText>
    </StyledChatListItem>
  );
}
