import {
    TutoringItemContainer,
    IconContainer,
    TutoringImage,
    TextGroup,
    TitleText,
    DescriptionText,
} from './PersonalisedTutoringStyle';

const TutoringItem = ({ tutoringItem }) => {
    return (
        <TutoringItemContainer>
            <IconContainer>
                <TutoringImage>
                    <img src={tutoringItem.imageUrl} alt={tutoringItem.title} />
                </TutoringImage>
            </IconContainer>
            <TextGroup>
                <TitleText>{tutoringItem.title}</TitleText>
                <DescriptionText>{tutoringItem.description}</DescriptionText>
            </TextGroup>
        </TutoringItemContainer>
    )
}

export default TutoringItem;
