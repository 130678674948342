import React from "react";

// Components
import Icon from "../Icon";
import dayjs from "dayjs"

const ReviewCard = (props) => {

    return (
        <div className="h-fit w-full col-span-1 bg-gray-100 p-5 space-y-3 rounded-lg">
            <div className="flex gap-3">
                <Icon type="star" />
                <span>{props.rating}</span>
                <span className="font-bodyPri">TESTINOMINAL</span>
            </div>

            <div className="italic">{props.content}</div>
            <div className="flex items-center gap-3">
                <div className="w-10 h-10"><img src={props.profileImg} alt="profileImg" className="w-full h-full rounded-full object-cover" /></div>
                <div className="">
                    <div className="flex gap-1 text-base font-medium">
                        <span>{props.fName}</span>
                        <span>{props.lName}</span>
                    </div>
                    <div className="text-gray-500 text-sm">{dayjs(props.createdAt).local().format("MMM D, YY HH:MM A").toString()}</div>
                </div>
            </div>
        </div>
    )
}

export default ReviewCard;