import { useEffect } from "react";
import clsx from 'clsx';

import { FaInfoCircle } from 'react-icons/fa';

import { setGetLessonPref, lessonPreferenceToolTip } from "pages/auth/preferences/data"

import { ToggleWithText } from "components/common-components/Toggle";
import FullPageLoader from "components/loader/FullPageLoader";

import { getTutorUserLessonByUserId, updateTutorUserLessonByUserId } from "redux/tutor/tutor.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearTutorUserLesson } from "redux/tutor/tutor.slice";

type tLessonPref = {
  key: string;
  label: string;
  description: string;
  value: boolean;
}

const LessonSettingHeader = () => {
  return (
    <div className={"py-3 space-y-3"}>
      <div className="flex justify-center w-full">
        <div className="flex item-start justify-center gap-1">
          <span className="font-subHeading font-medium text-2xl text-text--800">
            {"Lesson Preferences"}
          </span>
          <div className={"relative has-tooltip cursor-pointer"}>
            <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
            <span
              className={clsx(
                "w-56 px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip top-6.5",
                "flex flex-col items-start justify-start overflow-hidden"
              )}
            >
              {lessonPreferenceToolTip}
            </span>
          </div>
        </div>
      </div>
      <div className="h-0.5 bg-gray-300 w-full"></div>
    </div>
  );
}

export default function LessonSettings() {
  const { tutorUserLesson } = useAppState((s) => s.tutor)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher();

  useEffect(() => {
    dispatcher(getTutorUserLessonByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearTutorUserLesson())
    }
  }, [])


  const updateLessonStatus = (key: string, value: boolean) => {
    const lessonPref = { [key]: value }
    dispatcher(updateTutorUserLessonByUserId(user?.user?.userId, lessonPref))
  }

  if (tutorUserLesson?.isLoading) {
    return (<FullPageLoader isLoading={tutorUserLesson?.isLoading} />)
  }

  return (
    <div className="">
      <LessonSettingHeader />
      <div className="px-4 py-10 space-y-2 divide-y divide-blue-50">
        {tutorUserLesson?.tutorUserLesson &&
          setGetLessonPref(tutorUserLesson?.tutorUserLesson)?.map((lesson: tLessonPref, index: number) => (
            <ToggleWithText
              key={index}
              description={lesson.description}
              onChange={(isToggle) => updateLessonStatus(lesson.key, isToggle)}
              title={lesson.label}
              value={lesson.value}
            />
          ))}
        {tutorUserLesson?.errorMsg &&
          <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
            {tutorUserLesson?.errorMsg}
          </div>
        }
      </div>
    </div>
  );
}
