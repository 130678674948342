import tw from 'twin.macro';
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";

export const Container = tw.div`
    flex flex-col md:flex-row items-center justify-between w-full gap-3
`;

export const BrandContainer = tw.div`
    flex flex-row items-center justify-center gap-2 w-full cursor-pointer
    border-2 border-divider-medium rounded-md py-1.5 hover:bg-primary-light
`;

export const BrandName = tw.span`
    font-bodyPri font-normal text-base text-text-900
`;

export const GoogleLogo = tw(FcGoogle)`
    text-lg
`;

export const FacebookLogo = tw(FaFacebookF)`
    text-primary-dark text-lg
`;
