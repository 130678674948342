import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/profile/data';
import Profile from "pages/auth/profile/Profile";

import PageHeader from "components/pageHeader/PageHeader";
import FullPageLoader from 'components/loader/FullPageLoader';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
  getUserDetailByUserId,
  getUserCultureDetailByUserId,
  getUserEducationListByUserId
} from 'redux/user/user.request';
import {
  getStudentUserDetailByUserId,
  getStudentUserLanguageListByUserId
} from 'redux/student/student.request';
import {
  getTutorUserDetailByUserId,
  getTutorUserLanguageListByUserId
} from 'redux/tutor/tutor.request';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';


const ProfilePage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const {locals} = useAppState((state) => state.local)
  const { user, userDetail } = useAppState((state) => state.user)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.PROFILE))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    dispatcher(getUserDetailByUserId(user?.user?.userId))
    dispatcher(getUserCultureDetailByUserId(user?.user?.userId))
    dispatcher(getUserEducationListByUserId(user?.user?.userId))
    
    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(getStudentUserDetailByUserId(user?.user?.userId))
      dispatcher(getStudentUserLanguageListByUserId(user?.user?.userId))
    } else if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(getTutorUserDetailByUserId(user?.user?.userId))
      dispatcher(getTutorUserLanguageListByUserId(user?.user?.userId))
    }

  }, [])

  if (userDetail?.isLoading) {
    return (<FullPageLoader isLoading={userDetail?.isLoading} />)
  }

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <Profile />
    </div>
  );
};

export default ProfilePage;