import { Link } from 'react-router-dom';

import RatingStars from 'components/ratingStars/RatingStars';
import { useAppState } from "hooks/useStore";


export const AuthorProfileSection = () => {
  const { user } = useAppState((s) => s.user)
  const { offeringCourse } = useAppState((s) => s.offering)
  const AVG_RATING = 2

  return (
    <div className="w-full flex items-center justify-start gap-3">
      <div className='relative w-20 h-20'>
        <Link to={`/tutors/${offeringCourse?.offeringCourse?.tutor?.userId}`}>
          <img src={offeringCourse?.offeringCourse?.tutor?.profilePicUrl} alt={"tutor-public-profile"} className="w-full h-full object-cover rounded-full" />
        </Link>
        <div className='absolute bottom-0.5 right-0.5 w-6 h-6 rounded-full overflow-hidden border-2 border-white'>
          <img src={`https://countryflagsapi.com/png/${offeringCourse?.offeringCourse?.tutor?.fromCountryDomain}`} alt={"country-flag"}
            className='w-full h-full object-cover'
          />
        </div>
      </div>
      <div className="text-white flex flex-col items-start justify-center">
        <div className='flex items-center gap-2'>
          <span className="text-lg font-bodyPri">{offeringCourse?.offeringCourse?.tutor?.firstName + " " + offeringCourse?.offeringCourse?.tutor?.lastName.charAt(0) + "."}</span>
          {offeringCourse?.offeringCourse?.tutor?.onlineStatus === "online" && <span className={"w-2 h-2 rounded-full bg-green-600"}></span>}
        </div>
        <div className='flex items-center'>
          {offeringCourse?.offeringCourse?.tutor?.tutor?.avgRating > AVG_RATING
            ? <>
              <RatingStars rating={offeringCourse?.offeringCourse?.tutor?.tutor?.avgRating} fullRating={5} />
              <span className="font-bold">
                {offeringCourse?.offeringCourse?.tutor?.tutor?.avgRating}({offeringCourse?.offeringCourse?.tutor?.tutor?.ratingCount})
              </span>
            </>
            : <div className='text-sm font-bodyPri tracking-wide text-complementry-main'>{"New"}</div>
          }
        </div>
      </div>
    </div>
  );
};
