import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { toast } from "react-toastify";

import { MdEdit } from 'react-icons/md'
import { AiFillCheckCircle } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs"

import { validateMobileNo } from 'utils/validation-functions'

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { setSignUpPayload } from 'redux/auth/auth.slice'

const MobileVerificationContainer = () => {
    const { logInSignUp } = useAppState((s) => s.auth)
    const { countryList } = useAppState(s => s.master)

    const dispatcher = useAppDispatcher()

    const [otp, setOtp] = useState(new Array(4).fill(''))
    const [isOtpError, setIsOtpError] = useState(false)

    const [isOpenMobileOtpContainer, setIsOpenMobileOtpContainer] = useState(false)
    const [isMobileEditMode, setIsMobileEditMode] = useState(false)

    const [minutes, setMinutes] = useState(1)
    const [seconds, setSeconds] = useState(59)

    const [mobileNo, setMobileNo] = useState(null)
    const [countryCode, setCountryCode] = useState(null)

    const [generatedOtp, setGeneratedOtp] = useState();
    const [digits, setDigits] = useState()

    useEffect(() => {
        if (logInSignUp?.signUpPayload?.mobileNo !== "-undefined") {
            const userMobileData = logInSignUp?.signUpPayload?.mobileNo?.split("-")
            setCountryCode(userMobileData[0])
            setMobileNo(userMobileData[1])
        }
    }, [])
    useEffect(() => {
        const timer = setInterval(() => {
            if (isOpenMobileOtpContainer && seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (isOpenMobileOtpContainer && seconds === 0) {
                if (minutes === 0) {
                    clearInterval(timer)
                }
                else {
                    setMinutes(minutes - 1)
                    setSeconds(59)
                }
            }
        }, 1000)

        if (!isOpenMobileOtpContainer) {
            setSeconds(59)
            setMinutes(1)
        }
        return () => {
            clearInterval(timer)
        }
    })

    useEffect(() => {
        const otpMapping = otp.map(Number)
        const singleNumber = Number(otpMapping.join(''))
        setDigits(singleNumber)
    }, [otp])

    const handleEditMobileNo = () => {
        if (isMobileEditMode) {
            if (validateMobileNo(mobileNo)) {
                dispatcher(setSignUpPayload({
                    ...logInSignUp?.signUpPayload,
                    mobileNo: countryCode + "-" + mobileNo
                }))
                setIsMobileEditMode(!isMobileEditMode)
            }
            else {
                toast.warning("Enter Valid Mobile No.")
            }
        }
        else {
            setIsMobileEditMode(!isMobileEditMode)
        }
    }

    const generateOTP = () => {

        let digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 4; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d)]);
        // focus next input
        if (element.nextSibling) {
            element.nextSibling.focus()
        }
    };

    const resendOtp = () => {
        if (minutes === 0 && seconds === 0) {
            setGeneratedOtp(generateOTP())
            toast.success("Code sent succeessfully")
            setMinutes(1)
            setSeconds(59)
        }
    }

    const handleMobileVerification = async () => {
        if (isOpenMobileOtpContainer) {
            if (generatedOtp == digits) {
                toast.success("Mobile No Verified Successfully")
                dispatcher(setSignUpPayload({
                    ...logInSignUp?.signUpPayload,
                    isMobileNoVerified: true
                }))
            }
            else {
                toast.error("Incorrect Code Entered!")
                setIsOtpError(true)
            }
        }
        if (!isOpenMobileOtpContainer) {
            if (logInSignUp?.signUpPayload?.mobileNo) {
                setGeneratedOtp(generateOTP())
                toast.success("Code Sent Succeessfully")
                setIsOpenMobileOtpContainer(true)
            }
            else {
                toast.warning("Please Enter Mobile No. for verification")
                setIsMobileEditMode(true)
            }
        }
    }

    return (
        <>
            {!logInSignUp?.signUpPayload?.isMobileNoVerified &&
                <div className='space-y-5'>
                    <div className='flex items-center justify-center gap-3'>
                        <span className='font-bodyPri font-normal text-text-700 text-base whitespace-nowrap'>
                            {"Mobile No. : "}
                        </span>
                        {!isMobileEditMode &&
                            <span className='font-bodySec font-normal text-text-900 text-base'>
                                {(countryCode && mobileNo) && countryCode + " - " + mobileNo}
                            </span>
                        }
                        {isMobileEditMode &&
                            <div className={clsx("w-full flex items-center py-1 input-number-spin-none border-2 border-secondary-main border-dashed focus:outline-none",
                                "font-bodySec font-normal text-text-900 text-base"
                            )}>
                                <select name='countryCode' className='focus:outline-none' onChange={(e) => setCountryCode(e.target.value)}>
                                    {countryList?.countryList?.map((item) => (
                                        <option className='w-auto h-auto'>
                                            {"+" + item.country_phone_code}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type={"number"}
                                    className={clsx("w-full px-3 py-1 input-number-spin-none focus:outline-none",
                                        "font-bodySec font-normal text-text-900 text-base"
                                    )}
                                    value={mobileNo}
                                    autoFocus={true}
                                    onChange={(e) => setMobileNo(e.target.value)}
                                />
                            </div>
                        }
                        <div className='cursor-pointer' onClick={handleEditMobileNo}>
                            {!isMobileEditMode && <MdEdit className='text-2xl text-text-500' />}
                            {isMobileEditMode && <AiFillCheckCircle className='text-2xl text-secondary-main' />}
                        </div>
                    </div>
                    {isOpenMobileOtpContainer &&
                        <div className='space-y-2'>
                            <div className='flex justify-center items-center gap-2'>
                                {otp.map((data, index) => (
                                    <input className={"w-8 h-8 py-2 px-2 border border-divider-dark text-base font-bodyPri text-text-900 bg-white rounded-sm focus:outline-none"}
                                        isOtpError={isOtpError}
                                        type='text'
                                        maxLength={1}
                                        key={index}
                                        value={data}
                                        autoFocus={index === 0 && true}
                                        onChange={e => handleOtpChange(e.target, index)}
                                        onFocus={e => e.target.select()}
                                    />
                                ))}

                            </div>
                            <div className={clsx("flex justify-center items-center font-bodyPri font-medium text-sm",
                                "text-text-600",
                                minutes === 0 && seconds === 0 && "text-primary-main cursor-pointer"
                            )}
                                onClick={resendOtp}
                            >
                                {minutes === 0 && seconds === 0 ? "Resend OTP" :
                                    `Resend OTP in ${minutes < 10 ? '0' + minutes : minutes}: ${seconds < 10 ? '0' + seconds : seconds}`}
                            </div>
                        </div>
                    }
                    <div className={clsx(
                        "py-1.5 rounded-lg",
                        "text-text-50 font-buttons font-medium text-base w-full text-center cursor-pointer",
                        validateMobileNo(mobileNo) ? "bg-primary-main hover:bg-secondary-dark" : "bg-primary-light"
                    )}
                        onClick={handleMobileVerification}
                    >
                        {isOpenMobileOtpContainer ? "Verify Code" : "Verify Mobile"}
                    </div>
                </div>
            }
            {
                logInSignUp?.signUpPayload?.isMobileNoVerified &&
                <div className='flex flex-col justify-center items-center space-y-2'>
                    <BsCheckLg className='text-4xl text-green-500' />
                    <span className='font-bodyPri font-semibold text-lg text-text-900'>
                        {"Mobile No. Verified Succeessfully!"}
                    </span>
                </div>
            }
        </>
    )
}

export default MobileVerificationContainer