import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Section,
  Button,
  HLine,
  Footer
} from 'pages/auth/support/style';

import { pageHeading, SupportActionList, actionTextConst } from 'pages/auth/support/support.data';

import PageHeader from 'components/pageHeader/PageHeader';

import SendEmailAction from "pages/auth/support/components/SendEmailAction"

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { pagesInfo } from 'utils/pagesInfo';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';

function Support() {
  const { currentPageInfo } = useAppState((state: any) => state.pageInfo)
  const { locals } = useAppState((s) => s.local)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const [isSendEmailTextField, setIsSendEmailTextField] = useState(false)

  const studentKnowledgeBase = 'https://support.edulyte.com/portal/en/kb/student-helpdesk'
  const teacherKnowledgeBase = 'https://support.edulyte.com/portal/en/kb/tutor-helpdesk'
  const createATicket = 'https://support.edulyte.com/portal/en/newticket?departmentId=31629000000010772&layoutId=31629000000011350'

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.SUPPORT))
  }, [dispatcher, currentPageInfo])

  const handleOnClick = (action: any) => {
    if (action.text === actionTextConst.KNOWLEDGE_BASE.label) {
      if (locals.userRole === userRoles.TUTOR.value) {
        window.open(teacherKnowledgeBase, "_blank")
      }
      if (locals.userRole === userRoles.STUDENT.value) {
        window.open(studentKnowledgeBase, "_blank")
      }
    }
    if (action.text === actionTextConst.SEND_AN_EMAIL.label) {
      setIsSendEmailTextField(!isSendEmailTextField)
    }
    if (action.text === actionTextConst.CREATE_A_TICKET.value) {
      window.open(createATicket, "_blank")
    }
    if (action.text === actionTextConst.CHAT_WITH_US.label) {
      navigate("/message")
    }
  }

  const closeEmailContainer = () => {
    setIsSendEmailTextField(false)
  }

  return (
    <div className={"w-full h-full px-5 mx-auto min-h-screen space-y-3"}>
      <PageHeader pageHeading={pageHeading} />
      <Section>
        {SupportActionList.map((action) => (
          <Button onClick={() => handleOnClick(action)}>
            <div className={"w-16 h-16 overflow-hidden"}>
              <img
                src={action.image}
                className={"w-full h-full object-cover"}
              />
            </div>
            <span className={"font-semibold text-center text-base text-primary-main group-hover:text-secondary-main whitespace-nowrap"}>
              {action.text}
            </span>
          </Button>
        ))}
      </Section>
      {isSendEmailTextField &&
        <Section>
          <SendEmailAction
            closeEmailContainer={closeEmailContainer}
          />
        </Section>
      }
      <Footer>
        <h3 className={"font-bodyPri font-semibold text-text-800 text-md pt-10"}>
          {"Frequently Asked Questions "}
          <span className='font-bodyPri font-semibold text-text-800 text-sm'>
            {" (Coming Soon)"}
          </span>
        </h3>
        <HLine>
          <hr />
        </HLine>
      </Footer>
    </div>
  );
}

export default Support;
