import tw from 'twin.macro';
import { PageContent } from 'components/generalComponent/StyledComponent';

export const PageContentStyle = tw(PageContent)`
    bg-white relative h-screen my-10 md:my-0 flex justify-between items-center
`;

export const SignUpContainer = tw.div`
    absolute grid grid-cols-12 gap-3 place-items-center min-h-screen
`;

export const SignUpCardContainer = tw.div`
    w-[28rem] col-start-4 col-span-5 lg:col-start-3 lg:col-span-3
`;

export const ImageContainer = tw.div`
    hidden lg:block col-start-8 col-span-2 w-[28rem] h-[28rem] overflow-hidden rounded-md
`;