import tw from 'twin.macro';
import { motion } from 'framer-motion';
import { NavLink as RouterLink } from 'react-router-dom';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)`
    bg-[#14212D] pb-10
`;

export const Content = tw(SectionContent)`
    md:(col-start-2 col-span-10) lg:(col-start-3 col-span-8) gap-10
`;

export const HorizontalDivider = tw.div`
    bg-text-600 h-[2px] w-full
`;

export const LinksSection = tw.div`
    w-full grid grid-cols-2 md:grid-cols-4 gap-10
`;

export const ItemListContainer = tw.div`
    col-span-1 flex flex-col gap-2
`;

export const LinksTitle = tw.div`
    font-bodyPri font-medium text-base text-text-50
`;

export const LinksItemContainer = tw.div`
    flex flex-col gap-2
`;

export const AnchorLink = tw.a`
    font-bodyPri font-normal text-base bg-[#14212D] text-text-50
    hover:(text-primary-light underline) self-start text-left
`;

export const RouteLink = tw(RouterLink)`
    font-bodyPri font-normal text-base bg-[#14212D] text-text-50
    hover:(text-primary-light underline) self-start text-left
`;

export const SocialMediaSection = tw.div`
    flex flex-col items-center justify-center gap-8
`;

export const SocialIconContainer = tw.div`
    flex flex-row flex-wrap gap-10 items-center justify-center
`;

export const IconContainer = motion(tw.a`
    text-xl text-text-400 hover:text-text-50 cursor-pointer
`);

export const TermsAndCondition = tw.div`
    w-full grid grid-cols-2 md:grid-cols-5 gap-3
`;

export const TCItem = tw.span`
    col-span-2 font-bodyPri font-normal text-base bg-[#14212D]
    text-text-50 text-left self-start
`;

export const TCItemLink = tw(RouterLink)`
    col-span-1 font-bodyPri font-normal text-base bg-[#14212D]
    text-text-50 text-left self-start hover:(text-primary-light underline)
`;