import clsx from "clsx";
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { pagesInfo } from 'utils/pagesInfo';
import { updateOfferingByCourseId } from "redux/offering/offering.request";
import { getMasterLanguageList, getMasterProficiencyList } from "redux/master/master.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { setClearLanguageList, setClearProfList } from "redux/master/master.slice";
import { courseType } from "redux/course/course.const";
import { offeringStatus } from "redux/offering/offering.const";
import { modalConst } from "redux/local/local.const";

const SubmitCourseModal = () => {
    const { modal } = useAppState((s) => s.local)
    const { offeringCourse } = useAppState((s) => s.offering)
    const { languageList, profList } = useAppState((s) => s.master)
    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [comments, setComments] = useState('')
    const [characterLength, setCharacterLength] = useState(0)
    const [maxCharacterLength] = useState(500)

    useEffect(() => {
        dispatcher(getMasterProficiencyList())
        dispatcher(getMasterLanguageList())

        return () => {
            dispatcher(setClearProfList())
            dispatcher(setClearLanguageList())
        }
    }, [])

    useEffect(() => {
        if (offeringCourse?.offeringCourse && [offeringStatus.UNDER_REVIEW.value, offeringStatus.PUBLISHED.value].includes(offeringCourse?.offeringCourse?.status)) {
            dispatcher(setModal({
                ...modal,
                [modalConst.SUBMIT_COURSE_MODAL.stateKey]: false
            }))
            navigate(pagesInfo.OFFERINGS.pagePath, { replace: true })
        }
    }, [offeringCourse?.offeringCourse])

    const handleOnChange = (event) => {
        const myText = event.target.value
        setComments(myText)
        setCharacterLength(myText?.length)
    }

    const validateOffering = async () => {
        return true
    }

    const generateOfferings = async () => {
        const offering = {
            courseTitle: offeringCourse?.offeringCourse?.courseTitle,
            courseTitleDescription: offeringCourse?.offeringCourse?.courseTitleDescription,
            courseDescription: offeringCourse?.offeringCourse?.courseDescription,
            category: offeringCourse?.offeringCourse?.category,
            skills: offeringCourse?.offeringCourse?.skills,
            masterProficiencyId: profList?.profList?.filter((prof) => (
                prof?.proficiency === offeringCourse?.offeringCourse?.proficiency
            ))[0]?.masterProficiencyId,
            masterLanguageId: languageList?.languageList?.filter((language) => (
                language?.language === offeringCourse?.offeringCourse?.language
            ))[0]?.masterLanguageId,
            price: offeringCourse?.offeringCourse?.price,
            discountPct: offeringCourse?.offeringCourse?.discountPct,
            thumbnailPicUrl: offeringCourse?.offeringCourse?.thumbnailPicUrl,
            videoUrl: offeringCourse?.offeringCourse?.videoUrl
        }

        let restGroupOffering = {}
        if (offeringCourse?.offeringCourse?.courseType === courseType.GROUP.value) {
            restGroupOffering = {
                studentCapacity: offeringCourse?.offeringCourse?.studentCapacity,
                sessionDuration: offeringCourse?.offeringCourse?.sessionDuration,
                noOfSessions: offeringCourse?.offeringCourse?.noOfSessions,
                totalDuration: offeringCourse?.offeringCourse?.totalDuration,
                startDate: offeringCourse?.offeringCourse?.startDate,
                endDate: offeringCourse?.offeringCourse?.endDate,
                weeklyTimeSlot: offeringCourse?.offeringCourse?.weeklyTimeSlot
            }
        }
        return {
            ...offering,
            ...restGroupOffering,
            courseType: offeringCourse?.offeringCourse?.courseType
        }
    }

    const handleSubmitButton = async () => {
        // if (!validateOffering()) {
        //   alert("course not found!")
        //   return;
        // }
        const offering = await generateOfferings()
        dispatcher(updateOfferingByCourseId(
            offeringCourse?.offeringCourse?.courseId,
            {
                ...offering,
                status: offeringStatus.UNDER_REVIEW.value
            }))
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[12rem] block space-y-5 pt-5"}>
                <div className={"flex flex-col items-start gap-3"}>
                    <div className={"w-full font-bodyPri font-semibold text-text-900 text-md tracking-wide"}>
                        {"Are you sure?"}
                    </div>
                    <div className={"w-full font-bodyPri font-normal text-text-900 text-sm tracking-wide"}>
                        <span>{"Please write your comments/requests for the reviewer."}</span>
                        <span className={"text-xs"}>{" (optional)"}</span>
                    </div>
                    <div className={"w-full"}>
                        <textarea
                            className={clsx(
                                "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                                "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                            )}
                            type={"text"}
                            placeholder="Write your comments ...."
                            value={comments}
                            onChange={(event) => handleOnChange(event)}
                            rows={4}
                            maxlength={500}
                            autoFocus={true}
                        />
                        <div className={clsx("flex justify-end",
                            characterLength === maxCharacterLength && "w-full flex justify-between items-center"
                        )}>
                            {characterLength === maxCharacterLength &&
                                <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                                    {"Maximum character reached"}
                                </span>
                            }
                            <span className={"font-bodyPri font-normal text-text-800 text-xs"}>
                                {characterLength + "/" + maxCharacterLength}
                            </span>
                        </div>
                    </div>
                    {offeringCourse?.errorMsg &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {offeringCourse?.errorMsg}
                        </span>
                    }
                </div>
            </div>
            <div className={"flex justify-end"}>
                <span
                    className={clsx(
                        "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                    onClick={handleSubmitButton}
                >
                    {"Submit"}
                </span>
            </div>
        </div>
    )
}

export default SubmitCourseModal