import { useEffect, useState } from 'react'
import clsx from 'clsx';

import { BsCheckLg } from 'react-icons/bs';
import { FaSpinner } from 'react-icons/fa';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { addUserTodo, updateUserTodo } from "redux/todo/todo.request";
import { setAddTodoPayload, setClearAddTodo } from "redux/todo/todo.slice";
import { todoStatus, todoAction } from 'redux/todo/todo.const';
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';


const TodoModal = () => {
    const { modal } = useAppState(s => s.local)
    const { addTodo } = useAppState(s => s.todo)

    const dispatcher = useAppDispatcher()

    const [userTodo, setUserTodo] = useState({
        todo: addTodo?.addTodoPayload?.todo,
        status: addTodo?.addTodoPayload?.status
    })
    const [isSubmitDisable, setIsSubmitDisable] = useState(true)

    useEffect(() => {
        return () => {
            dispatcher(setClearAddTodo())
        }
    }, [])

    useEffect(() => {
        if (addTodo?.addTodo) {
            dispatcher(setModal({
                ...modal,
                [modalConst.TODO_MODAL.stateKey]: false
            }))
        }
    }, [dispatcher, addTodo?.addTodo])

    useEffect(() => {
        if (todoAction.UPDATE_TODO.value === addTodo?.addTodoPayload?.todoAction?.value &&
            ((userTodo?.todo && userTodo?.todo !== addTodo?.addTodoPayload?.todo) ||
                (userTodo?.status && userTodo?.status !== addTodo?.addTodoPayload?.status))) {
            setIsSubmitDisable(false)
        } else if (todoAction.ADD_TODO.value === addTodo?.addTodoPayload?.todoAction?.value &&
            ((userTodo?.todo && userTodo?.todo !== addTodo?.addTodoPayload?.todo && userTodo?.status))) {
            setIsSubmitDisable(false)
        } else {
            setIsSubmitDisable(true)
        }
    }, [userTodo, addTodo?.addTodoPayload])

    const handleOnChange = (event) => {
        event.preventDefault();

        setUserTodo({
            ...userTodo,
            [event.target.name]: event.target.value
        })
    }

    const onHandleCheckbox = () => {
        setUserTodo({
            ...userTodo,
            status: (
                userTodo?.status === todoStatus.COMPLETED.value
                    ? todoStatus.PENDING.value
                    : todoStatus.COMPLETED.value
            )
        })
    }

    const handleOnSubmit = () => {
        if (isSubmitDisable) return;

        dispatcher(setAddTodoPayload({
            ...addTodo?.addTodoPayload,
            todo: userTodo?.todo,
            status: userTodo?.status
        }))

        if (addTodo?.addTodoPayload?.todoAction?.value === todoAction?.ADD_TODO.value) {
            dispatcher(addUserTodo())
        }
        if (addTodo?.addTodoPayload?.todoAction?.value === todoAction?.UPDATE_TODO.value) {
            dispatcher(updateUserTodo())
        }
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[16rem] block space-y-2 pt-5 px-3'}>
                <div className={"flex items-center justify-start"}>
                    {addTodo?.addTodoPayload?.todoAction?.value === todoAction.ADD_TODO.value &&
                        <span className={"font-bodyPri font-medium text-md text-text-900 text-center"}>
                            {"Create a Todo"}
                        </span>
                    }
                    {addTodo?.addTodoPayload?.todoAction?.value === todoAction.UPDATE_TODO.value &&
                        <span className={"font-bodyPri font-medium text-md text-text-900 text-center"}>
                            {"Edit your Todo"}
                        </span>
                    }
                </div>

                <div className={"flex flex-col items-start gap-3"}>
                    <textarea
                        className={clsx(
                            "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                            "outline-none border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",
                            "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                        )}
                        value={userTodo?.todo}
                        onChange={handleOnChange}
                        name={"todo"}
                        placeholder="Write your task ...."
                        rows={4}
                    />
                    <div className={"flex items-center jusitify-start gap-1"}>
                        <div onClick={onHandleCheckbox} className={clsx(
                            "w-5 h-5 rounded cursor-pointer flex items-center justify-center border-2",
                            userTodo?.status === todoStatus.COMPLETED.value
                                ? `border-${todoStatus.COMPLETED.darkColor}`
                                : "border-text-500"
                        )}>
                            {userTodo?.status === todoStatus.COMPLETED.value &&
                                <BsCheckLg className={`text-md text-${todoStatus.COMPLETED.darkColor}`} />
                            }
                        </div>
                        <span className={"font-bodyPri font-normal text-base text-text-800"}>
                            {"Make complete"}
                        </span>
                    </div>
                    {addTodo?.errorMsg &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {addTodo?.errorMsg}
                        </span>
                    }
                </div>

            </div>

            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center gap-2"}>
                    {addTodo?.isLoading &&
                        <>
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {"Loading"}
                            </span>
                            <FaSpinner className="text-primary-main animate-spin" />
                        </>
                    }
                </div>
                <span onClick={handleOnSubmit} className={clsx(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    (isSubmitDisable || addTodo?.isLoading) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {addTodo?.addTodoPayload?.todoAction?.label}
                </span>
            </div>
        </div>
    )
}

export default TodoModal;