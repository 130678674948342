import React from "react";

//Component imports
import ChatView from "../ChatView/ChatView";
import ChatListContainer from "../ChatListContainer/ChatListContainer";
// import NotificationBar from "../NotificationBar/NotificationBar";

//Context imports
import { useChat } from "../../contexts/chatContext";

//Hooks
import useMediaQuery from "../../hooks/useMediaQuery";

//Utilities
import showChatList from "../../helpers/showChatList";

//Style imports
import {
  StyledDashBoardSelectChat,
  StyledDashboardContainer,
  StyledChatViewContainer,
} from "./ChatDashboard.styles";

export default function ChatDashboard() {
  const { selectedChat } = useChat();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const viewChatList = showChatList(isMobile, selectedChat);

  return (
    <StyledDashboardContainer>
      {/* <NotificationBar /> */}
      <StyledChatViewContainer>
        {viewChatList && <ChatListContainer />}
        {selectedChat && <ChatView />}
        {!selectedChat && !isMobile && (
          <StyledDashBoardSelectChat>
            <p>No chat Selected! You can select one on the left</p>
          </StyledDashBoardSelectChat>
        )}
      </StyledChatViewContainer>
    </StyledDashboardContainer>
  );
}
