import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { findTutorData, sortingMenuItems } from "./data";

import { PageContentStyle } from './FindTutor.style';

import Header from "components/Filters";
import TutorCard from "components/tutorCard/TutorCard";
import Pagination from "components/pagination/Pagination";
import TutorCardLoader from 'components/loader/TutorCardLoader';
import Footer from 'components/footer/Footer';

import SortDropdown from 'pages/global/findTutors/SortDropDown';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getTutorPublicList, createFavTutorUserDetailByUserId, deleteFavTutorUserDetailByUserId } from 'redux/tutor/tutor.request';
import { setClearTutorList } from 'redux/tutor/tutor.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 5


const TutorsPage = () => {
  const { currentPageInfo } = useAppState((state) => state.pageInfo)
  const { user } = useAppState((s) => s.user)
  const { tutorList } = useAppState((s) => s.tutor)

  const dispatcher = useAppDispatcher();
  const navigate = useNavigate()
  const location = useLocation()

  const [activeMenuItem, setActiveMenuItem] = useState(sortingMenuItems.RELEVANCE.value)

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.FIND_TUTORS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    const query = generateQueryParams(location.search)

    dispatcher(getTutorPublicList({ page: query.pn || 1, records: RECORDS }))

    return () => {
      dispatcher(setClearTutorList())
    }
  }, [location.search])


  const tutorMenuItem = (activeItem) => {
    setActiveMenuItem(activeItem)
  }

  const sortCourseHighToLow = (tutor1, tutor2) => {
    return Number(tutor2?.price / 100) - Number(tutor1?.price / 100)
  }

  const sortCourseLowToHigh = (tutor1, tutor2) => {
    return Number(tutor1?.price / 100) - Number(tutor2?.price / 100)
  }

  const sortCourse = (tutor1, tutor2) => {
    if (activeMenuItem === sortingMenuItems.PRICE_LOWEST_FIRST.value) {
      return sortCourseLowToHigh(tutor1, tutor2)
    }
    if (activeMenuItem === sortingMenuItems.PRICE_HIGHEST_FIRST.value) {
      return sortCourseHighToLow(tutor1, tutor2)
    }
  }

  return (
    <PageContentStyle>
      <Header {...findTutorData} />
      <div className="grid grid-cols-12 pb-5 pt-16">
        <div className="col-span-12 md:col-span-11 flex flex-row items-center justify-center md:justify-end pr-20 pb-4 z-20">
          <SortDropdown tutorMenuItem={tutorMenuItem} />
        </div>
        <div className='col-start-2 col-span-10 md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8 w-full space-y-5'>
          {tutorList?.filteredTutorList?.results?.slice(0)?.sort(sortCourse)?.map((tutor, index) => (
            <TutorCard key={index}
              tutor={tutor}
              isFav={user?.user?.favTutors?.filter((favTutor) => favTutor?.tutorId === tutor?.tutorId).length > 0}
              handleFav={(tutorId) => {
                const favoriteTutors = user?.user?.favTutors?.filter((favTutor) => favTutor?.tutorId === tutorId)
                if (favoriteTutors.length > 0) {
                  dispatcher(deleteFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                }
                else {
                  dispatcher(createFavTutorUserDetailByUserId(user?.user?.userId, { tutorId: tutorId }))
                }
              }}
            />
          ))}
          {new Array(RECORDS).fill("").map((_, index) => <TutorCardLoader key={index} isLoading={tutorList?.isLoading} />)}
          {tutorList?.filteredTutorList && tutorList?.filteredTutorList?.records > 0 &&
            <div className="w-full flex justify-center items-center">
              <Pagination
                page={tutorList?.filteredTutorList?.page}
                totalPages={tutorList?.filteredTutorList?.totalPages}
                onChangePage={(page) => navigate(pagesInfo.FIND_TUTORS.pagePath + "?pn=" + page)}
              />
            </div>
          }
          {(tutorList?.filteredTutorList?.records === 0 || tutorList?.errorMsg) &&
            <span className='w-full h-full flex items-center justify-center text-center text-base font-bodyPri tracking-wide pt-5'>
              {tutorList?.errorMsg}
            </span>
          }
        </div>
      </div>
      <Footer />
    </PageContentStyle>
  );
};

export default TutorsPage;
