import AvailabilityCard from 'pages/auth/preferences/features/availability/AvailabilityCard';

import { Button } from "components/common-components/Buttons";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import {
  getUserAvailabilityByUserId,
  updateAvailabilityByUserId
} from 'redux/availability/availability.request';


export default function WeeklyScheduleSection() {
  const { user } = useAppState((s) => s.user)
  const { availability } = useAppState((s) => s.availability)

  const dispatcher = useAppDispatcher()

  const handleOnReset = () => {
    dispatcher(getUserAvailabilityByUserId(user?.user?.userId))
  }

  const handleOnSave = () => {
    dispatcher(updateAvailabilityByUserId(
      user?.user?.userId,
      { weeklyAvail: availability?.availability?.weeklyAvail }
    ))
  }

  return (
    <div>
      <div className="flex flex-col max-w-4xl mx-auto space-y-4">
        {availability?.availability?.weeklyAvail &&
          Object.keys(availability?.availability?.weeklyAvail).map((day, idx) => (
            <AvailabilityCard key={idx} day={day} />
          ))
        }
        <div className="flex justify-center py-6 space-x-4">
          <Button name="Reset" style="outline" size="medium" onClick={handleOnReset} />
          <Button name="Save" style="primary" size="medium" onClick={handleOnSave} />
        </div>
      </div>
    </div>
  );
}