import * as React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

// Components
import Icon from "../Icon";

const useStyles = makeStyles({
  buttonRoot: {
    background: "#cbe9f3 !important",
    borderRadius: "15px !important",
    textTransform: "capitalize !important",
    color: "#664c4c !important",
    borderColor: "white !important",
    display: "flex !important",
    margin: "8px 8px 8px 0px !important",
  },
  menuRoot: {
      marginLeft: 6,
      marginTop: 1
  }
});

const MyMenu = (props) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (name, value) => {
    props.handleChange(name, value);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        classes={{
            root: classes.buttonRoot
        }}
      >
        {props.value} <span className="ml-2"><Icon type="downArrow"/></span>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        classes={{
            root: classes.menuRoot
        }}
      >
        {props.options && props.options.map((ele, idx) => 
         <MenuItem key={idx} onClick={()=>handleClose(props.name, ele.name)}>{ele.name}</MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MyMenu;
