import { createSlice } from "@reduxjs/toolkit";
import { TUTOR_INITIAL_STATE } from "redux/tutor/tutor.initialState";
import { filterName } from "redux/tutor/tutor.const";

const tutor = createSlice({
    name: "tutor",
    initialState: TUTOR_INITIAL_STATE,
    reducers: {
        // reducers for tutor list
        setTutorListLoading: (state, { payload }) => {
            state.tutorList.isLoading = payload
        },
        setTutorList: (state, { payload }) => {
            state.tutorList.tutorList = payload
            state.tutorList.filteredTutorList = payload
            state.tutorList.errorMsg = TUTOR_INITIAL_STATE.tutorList.errorMsg
        },
        setTutorListErrorMsg: (state, { payload }) => {
            state.tutorList.errorMsg = payload
            state.tutorList.tutorList = TUTOR_INITIAL_STATE.tutorList.tutorList
            state.tutorList.filteredTutorList = TUTOR_INITIAL_STATE.tutorList.filteredTutorList
        },
        setClearTutorList: (state) => {
            state.tutorList = TUTOR_INITIAL_STATE.tutorList
        },

        // reducers for tutor public profile
        setTutorPublicProfileLoading: (state, { payload }) => {
            state.tutorPublicProfile.isLoading = payload
        },
        setTutorPublicProfile: (state, { payload }) => {
            state.tutorPublicProfile.tutorPublicProfile = payload
            state.tutorPublicProfile.errorMsg = TUTOR_INITIAL_STATE.tutorPublicProfile.errorMsg
        },
        setTutorPublicProfileErrorMsg: (state, { payload }) => {
            state.tutorPublicProfile.errorMsg = payload
            state.tutorPublicProfile.tutorPublicProfile = TUTOR_INITIAL_STATE.tutorPublicProfile.tutorPublicProfile
        },
        setClearTutorPublicProfile: (state) => {
            state.tutorPublicProfile = TUTOR_INITIAL_STATE.tutorPublicProfile
        },

        // reducers for my tutor list
        setMyTutorListLoading: (state, { payload }) => {
            state.myTutorList.isLoading = payload
        },
        setMyTutorList: (state, { payload }) => {
            state.myTutorList.myTutorList = payload
            state.myTutorList.filteredMyTutorList = payload
            state.myTutorList.errorMsg = TUTOR_INITIAL_STATE.myTutorList.errorMsg
        },
        setMyTutorListErrorMsg: (state, { payload }) => {
            state.myTutorList.errorMsg = payload
            state.myTutorList.myTutorList = TUTOR_INITIAL_STATE.myTutorList.myTutorList
            state.myTutorList.filteredMyTutorList = TUTOR_INITIAL_STATE.myTutorList.filteredMyTutorList
        },
        setClearMyTutorList: (state) => {
            state.myTutorList = TUTOR_INITIAL_STATE.myTutorList
        },

        // reducers for fav tutor list
        setFavTutorListLoading: (state, { payload }) => {
            state.favTutorList.isLoading = payload
        },
        setFavTutorList: (state, { payload }) => {
            state.favTutorList.favTutorList = payload
            state.favTutorList.filteredFavTutorList = payload
            state.favTutorList.errorMsg = TUTOR_INITIAL_STATE.favTutorList.errorMsg
        },
        setFavTutorListErrorMsg: (state, { payload }) => {
            state.favTutorList.errorMsg = payload
            state.favTutorList.favTutorList = TUTOR_INITIAL_STATE.favTutorList.favTutorList
            state.favTutorList.filteredFavTutorList = TUTOR_INITIAL_STATE.favTutorList.filteredFavTutorList
        },
        setClearFavTutorList: (state) => {
            state.favTutorList = TUTOR_INITIAL_STATE.favTutorList
        },

        // reducers for add fav tutors
        setAddFavTutorLoading: (state, { payload }) => {
            state.addFavTutor.isLoading = payload
        },
        setAddFavTutorErrorMsg: (state, { payload }) => {
            state.addFavTutor.errorMsg = payload
            state.addFavTutor.successMsg = TUTOR_INITIAL_STATE.addFavTutor.addFavTutor
        },
        setClearAddFavTutor: (state) => {
            state.addFavTutor = TUTOR_INITIAL_STATE.addFavTutor
        },

        // reducers for tutor user detail
        setTutorUserDetailLoading: (state, { payload }) => {
            state.tutorUserDetail.isLoading = payload
        },
        setTutorUserDetail: (state, { payload }) => {
            state.tutorUserDetail.tutorUserDetail = payload
            state.tutorUserDetail.errorMsg = TUTOR_INITIAL_STATE.tutorUserDetail.errorMsg
        },
        setTutorUserDetailErrorMsg: (state, { payload }) => {
            state.tutorUserDetail.errorMsg = payload
            state.tutorUserDetail.tutorUserDetail = TUTOR_INITIAL_STATE.tutorUserDetail.tutorUserDetail
        },
        setClearTutorUserDetail: (state) => {
            state.tutorUserDetail = TUTOR_INITIAL_STATE.tutorUserDetail
        },

        // reducers for update tutor user detail
        setAddTutorUserDetailLoading: (state, { payload }) => {
            state.addTutorUserDetail.isLoading = payload
        },
        setAddTutorUserDetailErrorMsg: (state, { payload }) => {
            state.addTutorUserDetail.errorMsg = payload
        },
        setClearAddTutorUserDetail: (state) => {
            state.addTutorUserDetail = TUTOR_INITIAL_STATE.addTutorUserDetail
        },

        // reducers for tutor user price
        setTutorUserPriceLoading: (state, { payload }) => {
            state.tutorUserPrice.isLoading = payload
        },
        setTutorUserPrice: (state, { payload }) => {
            state.tutorUserPrice.tutorUserPrice = payload
            state.tutorUserPrice.errorMsg = TUTOR_INITIAL_STATE.tutorUserPrice.errorMsg
        },
        setTutorUserPriceErrorMsg: (state, { payload }) => {
            state.tutorUserPrice.errorMsg = payload
            state.tutorUserPrice.tutorUserPrice = TUTOR_INITIAL_STATE.tutorUserPrice.tutorUserPrice
        },
        setClearTutorUserPrice: (state) => {
            state.tutorUserPrice = TUTOR_INITIAL_STATE.tutorUserPrice
        },

        // reducers for update tutor user price
        setAddTutorUserPriceLoading: (state, { payload }) => {
            state.addTutorUserPrice.isLoading = payload
        },
        setAddTutorUserPriceErrorMsg: (state, { payload }) => {
            state.addTutorUserPrice.errorMsg = payload
        },
        setClearAddTutorUserPrice: (state) => {
            state.addTutorUserPrice = TUTOR_INITIAL_STATE.addTutorUserPrice
        },

        // reducers for tutor user lesson
        setTutorUserLessonLoading: (state, {payload}) => {
            state.tutorUserLesson.isLoading = payload
        },
        setTutorUserLesson: (state, {payload}) => {
            state.tutorUserLesson.tutorUserLesson = payload
            state.tutorUserLesson.errorMsg = TUTOR_INITIAL_STATE.tutorUserLesson.errorMsg
        },
        setTutorUserLessonErrorMsg: (state, {payload}) => {
            state.tutorUserLesson.errorMsg = payload
            state.tutorUserLesson.tutorUserLesson = TUTOR_INITIAL_STATE.tutorUserLesson.tutorUserLesson
        },
        setClearTutorUserLesson: (state) => {
            state.tutorUserLesson = TUTOR_INITIAL_STATE.tutorUserLesson
        },

        // reducers for update tutor user lesson
        setAddTutorUserLessonLoading: (state, {payload}) => {
            state.addTutorUserLesson.isLoading = payload
        },
        setAddTutorUserLessonErrorMsg: (state, {payload}) => {
            state.addTutorUserLesson.errorMsg = payload
        },
        setClearAddTutorUserLesson: (state) => {
            state.addTutorUserLesson = TUTOR_INITIAL_STATE.addTutorUserLesson
        },

        // reducers for tutor user language
        setTutorUserLanguageListLoading: (state, { payload }) => {
            state.tutorUserLanguageList.isLoading = payload
        },
        setTutorUserLanguageList: (state, { payload }) => {
            state.tutorUserLanguageList.tutorUserLanguageList = payload
            state.tutorUserLanguageList.filteredTutorUserLanguageList = payload
            state.tutorUserLanguageList.errorMsg = TUTOR_INITIAL_STATE.tutorUserLanguageList.errorMsg
        },
        setTutorUserLanguageListErrorMsg: (state, { payload }) => {
            state.tutorUserLanguageList.errorMsg = payload
            state.tutorUserLanguageList.tutorUserLanguageList = TUTOR_INITIAL_STATE.tutorUserLanguageList.tutorUserLanguageList
        },
        setClearTutorUserLanguageList: (state) => {
            state.tutorUserLanguageList = TUTOR_INITIAL_STATE.tutorUserLanguageList
        },

        // reducers for update tutor user language
        setAddTutorLanguageDetailLoading: (state, {payload}) => {
            state.addTutorLanguageDetail.isLoading = payload
        },
        setAddTutorLanguageDetailErrorMsg: (state, {payload}) => {
            state.addTutorLanguageDetail.errorMsg = payload
        },
        setClearAddTutorLanguageDetail: (state) => {
            state.addTutorLanguageDetail = TUTOR_INITIAL_STATE.addTutorLanguageDetail
        },

        // reducers for tutor category list
        setTutorUserCategoryListLoading: (state, { payload }) => {
            state.tutorUserCategoryList.isLoading = payload
        },
        setTutorUserCategoryList: (state, { payload }) => {
            state.tutorUserCategoryList.tutorUserCategoryList = payload
            state.tutorUserCategoryList.filteredTutorUserCategoryList = payload
            state.tutorUserCategoryList.errorMsg = TUTOR_INITIAL_STATE.tutorUserCategoryList.errorMsg
        },
        setTutorUserCategoryListErrorMsg: (state, { payload }) => {
            state.tutorUserCategoryList.errorMsg = payload
            state.tutorUserCategoryList.tutorUserCategoryList = TUTOR_INITIAL_STATE.tutorUserCategoryList.tutorUserCategoryList
            state.tutorUserCategoryList.filteredTutorUserCategoryList = TUTOR_INITIAL_STATE.tutorUserCategoryList.filteredTutorUserCategoryList
        },
        setClearTutorUserCategoryList: (state) => {
            state.tutorUserCategoryList = TUTOR_INITIAL_STATE.tutorUserCategoryList
        },

        // reducers for update tutor category list
        setAddTutorCategoryDetailLoading: (state, {payload}) => {
            state.addTutorCategoryDetail.isLoading = payload
        },
        setAddTutorCategoryDetailErrorMsg: (state, {payload}) => {
            state.addTutorCategoryDetail.errorMsg = payload
        },
        setClearAddTutorCategoryDetail: (state) => {
            state.addTutorCategoryDetail = TUTOR_INITIAL_STATE.addTutorCategoryDetail
        },

        // reducers for filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps
            const filteredList = payload.list
                .filter((tutor) => (
                    !payload.filterProps.searchText || (tutor.firstName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())
                        || tutor.lastName.toLowerCase().includes(payload.filterProps.searchText.toLowerCase()))
                ))

            switch (payload.filterName) {
                case filterName.FIND_TUTORS.value:
                    state.tutorList.filteredTutorList.results = filteredList
                    break;
                case filterName.ALL_TUTORS.value:
                    state.myTutorList.filteredMyTutorList.results = filteredList
                    break;
                case filterName.FAV_TUTORS.value:
                    state.myTutorList.filteredMyTutorList.results = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = TUTOR_INITIAL_STATE.filterProps
        }
    }
});

export const {
    setTutorListLoading,
    setTutorList,
    setTutorListErrorMsg,
    setClearTutorList,

    setTutorPublicProfileLoading,
    setTutorPublicProfile,
    setTutorPublicProfileErrorMsg,
    setClearTutorPublicProfile,

    setMyTutorListLoading,
    setMyTutorList,
    setMyTutorListErrorMsg,
    setClearMyTutorList,

    setFavTutorListLoading,
    setFavTutorList,
    setFavTutorListErrorMsg,
    setClearFavTutorList,

    setAddFavTutorLoading,
    setAddFavTutorErrorMsg,
    setClearAddFavTutor,

    setTutorUserDetailLoading,
    setTutorUserDetail,
    setTutorUserDetailErrorMsg,
    setClearTutorUserDetail,

    setAddTutorUserDetailLoading,
    setAddTutorUserDetailErrorMsg,
    setClearAddTutorUserDetail,

    setTutorUserPriceLoading,
    setTutorUserPrice,
    setTutorUserPriceErrorMsg,
    setClearTutorUserPrice,

    setAddTutorUserPriceLoading,
    setAddTutorUserPriceErrorMsg,
    setClearAddTutorUserPrice,

    setTutorUserLessonLoading,
    setTutorUserLesson,
    setTutorUserLessonErrorMsg,
    setClearTutorUserLesson,

    setAddTutorUserLessonLoading,
    setAddTutorUserLessonErrorMsg,
    setClearAddTutorUserLesson,

    setTutorUserLanguageListLoading,
    setTutorUserLanguageList,
    setTutorUserLanguageListErrorMsg,
    setClearTutorUserLanguageList,

    setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailErrorMsg,
    setClearAddTutorLanguageDetail,

    setTutorUserCategoryListLoading,
    setTutorUserCategoryList,
    setTutorUserCategoryListErrorMsg,
    setClearTutorUserCategoryList,

    setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailErrorMsg,
    setClearAddTutorCategoryDetail,

    setFilterProps,
    setClearFilterProps
} = tutor.actions

export default tutor.reducer;