import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import PrivateRouter from 'routers/PrivateRouter';
import LayoutRouter from "routers/LayoutRouter";
import PersistLogin from "routers/PersistLoginRouter";

import { pagesInfo } from 'utils/pagesInfo';

import AvailabilityPreferencePage from "pages/auth/preferences/features/availability/AvailabilityPreferencePage";
import LessonSettings from "pages/auth/preferences/features/lessons/LessonsSettings";
import OtherPreferencesSettings from "pages/auth/preferences/features/others/OtherPreferencesSettings";
import PricingPreferencesSettings from "pages/auth/preferences/features/price/PricingPreferencesSettings";
import SubjectPreferenceSettings from "pages/auth/preferences/features/subject/SubjectPreferenceSettings";

import General from "pages/auth/settings/components/general/General";
import BillingPage from 'pages/auth/settings/components/BillingPage';
import { PaymentPage } from "pages/auth/settings/components/PaymentPage";
import PayoutPage from "pages/auth/settings/components/PayoutPage";
import { CalenderPage } from "pages/auth/settings/components/CalenderPage";
import { NotificationSettings } from "pages/auth/settings/components/NotificationSettings";
import SocialSettings from "pages/auth/settings/components/socialSettings/SocialSettings";
import { RateApp } from "pages/auth/settings/components/RateApp";
import { AboutUs } from "pages/auth/settings/components/AboutUs";


const InitRouter = () => {

  const location = useLocation()

  return (
    <Routes>
      <Route path={"/"} element={<LayoutRouter />}>
        {/* public routes */}
        <Route path={pagesInfo.BUY_GIFT_CARD.routePath} exact element={<pagesInfo.BUY_GIFT_CARD.component />} />

        <Route path={pagesInfo.COMPANY.routePath} exact element={<pagesInfo.COMPANY.component />} />

        <Route path={pagesInfo.COURSE.routePath} exact element={<pagesInfo.COURSE.component />} />

        <Route path={pagesInfo.FIND_COURSES.routePath} exact element={<pagesInfo.FIND_COURSES.component />} />

        <Route path={pagesInfo.FIND_TUTORS.routePath} exact element={<pagesInfo.FIND_TUTORS.component />} />

        <Route path={pagesInfo.HOME.routePath} exact element={<pagesInfo.HOME.component />} />

        <Route path={pagesInfo.LOG_IN.routePath} exact element={<pagesInfo.LOG_IN.component />} />

        <Route path={pagesInfo.REFERRAL_SIGN_UP.routePath} exact element={<pagesInfo.REFERRAL_SIGN_UP.component />} />

        <Route path={pagesInfo.SEGMENTS.routePath} exact element={<pagesInfo.SEGMENTS.component />} />

        <Route path={pagesInfo.SIGN_UP.routePath} exact element={<pagesInfo.SIGN_UP.component />} />

        <Route path={pagesInfo.TEACH_WITH_US.routePath} exact element={<pagesInfo.TEACH_WITH_US.component />} />

        <Route path={pagesInfo.THANK_YOU.routePath} exact element={<pagesInfo.THANK_YOU.component />} />

        <Route path={pagesInfo.TUTOR.routePath} exact element={<pagesInfo.TUTOR.component />} />

        <Route path={pagesInfo.TUTOR_COURSE.routePath} exact element={<pagesInfo.TUTOR_COURSE.component />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.BOOKINGS.roles} />}>
            <Route path={pagesInfo.BOOKINGS.routePath} exact element={<pagesInfo.BOOKINGS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.BOOKING_CONFIRMATION.roles} />}>
            <Route path={pagesInfo.BOOKING_CONFIRMATION.routePath} exact element={<pagesInfo.BOOKING_CONFIRMATION.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.CREATE_COURSE.roles} />}>
            <Route path={pagesInfo.CREATE_COURSE.routePath} exact element={<pagesInfo.CREATE_COURSE.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.DASHBOARD.roles} />}>
            <Route path={pagesInfo.DASHBOARD.routePath} exact element={<pagesInfo.DASHBOARD.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_COURSE.roles} />}>
            <Route path={pagesInfo.EDIT_COMPANY.routePath} exact element={<pagesInfo.EDIT_COMPANY.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.EDIT_COURSE.roles} />}>
            <Route path={pagesInfo.EDIT_COURSE.routePath} exact element={<pagesInfo.EDIT_COURSE.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.MEETING.roles} />}>
            <Route path={pagesInfo.MEETING.routePath} exact element={<pagesInfo.MEETING.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.MESSAGE.roles} />}>
            <Route path={pagesInfo.MESSAGE.routePath} exact element={<pagesInfo.MESSAGE.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.MESSAGES.roles} />}>
            <Route path={pagesInfo.MESSAGES.routePath} exact element={<pagesInfo.MESSAGES.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.MY_STUDENTS.roles} />}>
            <Route path={pagesInfo.MY_STUDENTS.routePath} exact element={<pagesInfo.MY_STUDENTS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.MY_TUTORS.roles} />}>
            <Route path={pagesInfo.MY_TUTORS.routePath} exact element={<pagesInfo.MY_TUTORS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.MY_COURSES.roles} />}>
            <Route path={pagesInfo.MY_COURSES.routePath} exact element={<pagesInfo.MY_COURSES.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.OFFERINGS.roles} />}>
            <Route path={pagesInfo.OFFERINGS.routePath} exact element={<pagesInfo.OFFERINGS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.OFFERS.roles} />}>
            <Route path={pagesInfo.OFFERS.routePath} exact element={<pagesInfo.OFFERS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.PREFERENCES.roles} />}>
            <Route path={pagesInfo.PREFERENCES.routePath} exact element={
              <pagesInfo.PREFERENCES.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="availability" element={<AvailabilityPreferencePage />} />
            </Route>
            <Route path={pagesInfo.PREFERENCES.routePath} exact element={
              <pagesInfo.PREFERENCES.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="subjects" element={<SubjectPreferenceSettings />} />
            </Route>
            <Route path={pagesInfo.PREFERENCES.routePath} exact element={
              <pagesInfo.PREFERENCES.component allowedRoles={['tutor']} />
            }>
              <Route path="lesson" element={<LessonSettings />} />
            </Route>
            <Route path={pagesInfo.PREFERENCES.routePath} exact element={
              <pagesInfo.PREFERENCES.component allowedRoles={['tutor']} />
            }>
              <Route path="price" element={<PricingPreferencesSettings />} />
            </Route>
            <Route path={pagesInfo.PREFERENCES.routePath} exact element={
              <pagesInfo.PREFERENCES.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="other" element={<OtherPreferencesSettings />} />
            </Route>
            <Route path={pagesInfo.PREFERENCES.routePath} exact element={
              <pagesInfo.PREFERENCES.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="" element={<Navigate to={"availability"} state={{ from: location }} replace />} />
            </Route>
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.PROFILE.roles} />}>
            <Route path={pagesInfo.PROFILE.routePath} exact element={<pagesInfo.PROFILE.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.REFERRAL.roles} />}>
            <Route path={pagesInfo.REFERRAL.routePath} exact element={<pagesInfo.REFERRAL.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.SETTINGS.roles} />}>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="general" element={<General />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="billing-info" element={<BillingPage />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="payment-methods" element={<PaymentPage />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['tutor']} />
            }>
              <Route path="payout-methods" element={<PayoutPage />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="calender" element={<CalenderPage />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="notifications" element={<NotificationSettings />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="social-settings" element={<SocialSettings />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="rate-app" element={<RateApp />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="about" element={<AboutUs />} />
            </Route>
            <Route path={pagesInfo.SETTINGS.routePath} exact element={
              <pagesInfo.SETTINGS.component allowedRoles={['student', 'tutor']} />
            }>
              <Route path="" element={<Navigate to={"general"} state={{ from: location }} replace />} />
            </Route>
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.SCHEDULE.roles} />}>
            <Route path={pagesInfo.SCHEDULE.routePath} exact element={<pagesInfo.SCHEDULE.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.SUBSCRIPTIONS.roles} />}>
            <Route path={pagesInfo.SUBSCRIPTIONS.routePath} exact element={<pagesInfo.SUBSCRIPTIONS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.SUPPORT.roles} />}>
            <Route path={pagesInfo.SUPPORT.routePath} exact element={<pagesInfo.SUPPORT.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.TOOLS.roles} />}>
            <Route path={pagesInfo.TOOLS.routePath} exact element={<pagesInfo.TOOLS.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.TUTORS_ONBOARD.roles} />}>
            <Route path={pagesInfo.TUTORS_ONBOARD.routePath} exact element={<pagesInfo.TUTORS_ONBOARD.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.UNAUTHORIZED.roles} />}>
            <Route path={pagesInfo.UNAUTHORIZED.routePath} exact element={<pagesInfo.UNAUTHORIZED.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.WALLET.roles} />}>
            <Route path={pagesInfo.WALLET.routePath} exact element={<pagesInfo.WALLET.component />} />
          </Route>

          <Route element={<PrivateRouter allowedRoles={pagesInfo.WALLET_CONFIRMATION.roles} />}>
            <Route path={pagesInfo.WALLET_CONFIRMATION.routePath} exact element={<pagesInfo.WALLET_CONFIRMATION.component />} />
          </Route>

        </Route>

        <Route path={pagesInfo.PAGE_NOT_FOUND.routePath} exact element={<pagesInfo.PAGE_NOT_FOUND.component />} />
      </Route>

      <Route element={<PrivateRouter allowedRoles={pagesInfo.MEETING_ROOM.roles} />} >
        <Route path={pagesInfo.MEETING_ROOM.routePath} exact element={<pagesInfo.MEETING_ROOM.component />} />
      </Route>
    </Routes>
  )
}

export default InitRouter;