import { useAppState } from 'hooks/useStore';
import { userRoles } from 'redux/auth/auth.const';
import { eventUserType } from 'redux/event/event.const';

const EventDetailRoleView = () => {
    const { eventDetail } = useAppState(s => s.event)

    return eventDetail?.eventDetail?.role ? (
        <div className={"flex items-center justify-start gap-2"}>
            <span className={"font-bodyPri font-normal text-base text-text-800"}>
                {"Me As: "}
            </span>
            <div className={"flex items-center justify-center gap-1"}>
                <span className={"font-bodyPri font-medium text-md text-text-900"}>
                    {userRoles[eventDetail?.eventDetail?.role?.toUpperCase()].label}
                </span>
                <span className={"font-bodyPri font-normal text-sm text-text-900"}>
                    ({eventUserType[eventDetail?.eventDetail?.eventUserType?.toUpperCase()].label})
                </span>
            </div>
        </div>
    ) : null
}

export default EventDetailRoleView