import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";

import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlineCloseCircle } from 'react-icons/ai';


const Modal = ({
    openModal = false,
    onCloseModal,
    onBackModal,
    maxWidth = "max-w-5xl",
    bgColor = "bg-white",
    title = '',
    children
}: {
    openModal?: boolean;
    onCloseModal: (state: boolean) => void;
    onBackModal: () => void | undefined;
    maxWidth?: string;
    bgColor ?: string;
    title?: string;
    children: React.ReactNode;
}) => {
    const closeModal = () => {
        onCloseModal(false)
    }

    const backModal = () => {
        onBackModal()
    }

    return (
        <Transition appear show={openModal} as={Fragment}>
            <Dialog
                as="div"
                className="fixed max-h-screen inset-0 z-50 overflow-y-auto"
                open={openModal}
                onClose={closeModal}
            >
                <div className="p-5 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span aria-hidden="true" className="inline-block h-screen align-middle">&#8203;</span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={clsx(
                            "inline-block w-full p-8 shadow-xl rounded-lg space-y-5 overflow-hidden",
                            `text-left align-middle transition-all transform ${maxWidth} ${bgColor}`
                        )}>
                            <Dialog.Title as="div">
                                <div className={"w-full grid grid-cols-12"}>
                                    {onBackModal !== undefined &&
                                        <IoIosArrowBack onClick={backModal} className={"w-full col-start-1 col-span-1 text-2xl text-center text-text-700 hover:text-text-900 cursor-pointer"} />
                                    }
                                    <span className={"col-start-2 col-span-10 font-bodyPri font-normal text-2xl text-center text-primary-dark leading-6 tracking-wider"}>
                                        {title}
                                    </span>
                                    <AiOutlineCloseCircle onClick={closeModal} className={"w-full col-start-12 col-spna-1 text-center text-2xl text-text-700 hover:text-text-900 cursor-pointer"} />
                                </div>
                            </Dialog.Title>
                            <Dialog.Description as="div">
                                <div className={clsx(
                                    "w-full max-h-[90vh] overflow-hidden overflow-y-scroll",
                                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                                )}>
                                    {children}
                                </div>
                            </Dialog.Description>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
}

export default Modal