export const pageHeading = {
    heading: "My Students",
    subHeading: "Find the list of students and liked students!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/my-students.svg"
}

export const studentTabs = {
    ALL_STUDENT: {
        label: "All Student",
        value: "all_student"
    },
    FAV_STUDENT: {
        label: "Favourite",
        value: "fav_student"
    }
}
