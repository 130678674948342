import { useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

import SaveAndCancelBtn from "pages/auth/settings/components/SaveAndCancelBtn";

import { BsEye, BsEyeSlash } from "react-icons/bs";

import { validatePassword } from "utils/validation-functions";

const TEST_PASSWORD = "Test@123"

const ChangePassword = ({setIsUpdatePassword}) => {
    const [password, setPassword] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    })
    const [isShowPassword, setIsShowPassword] = useState({
        showOldPassword: false,
        showNewPassword: false,
        showConfirmPassword: false
    })

    const onSavePassword = () => {
        if ((password.oldPassword !== TEST_PASSWORD || password.newPassword !== password.confirmPassword || !validatePassword(password.newPassword))) {
            toast.warn("Invalid Password!")
            return;
        }
        toast.success("Password updated successfully!")
        // dispatcher(updateUserGeneralDetailByUserId(user?.user?.userId))
    }

    return (
        <div className={"col-start-1 col-span-full grid grid-cols-12 gap-5 py-5"}>

            {/* old password container */}
            <span className={clsx(
                "col-start-1 col-span-full md:col-start-1 md:col-span-2 lg:col-start-1 lg:col-span-2",
                "font-bodyPri font-normal text-text-800 text-base tracking-wide"
            )}
            >
                {"Old Password"}
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5"}>
                <div className={clsx(
                    "w-full flex items-center justify-between gap-1",
                    "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400 hover:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                )}>
                    <input
                        type={isShowPassword.showOldPassword ? "text" : "password"}
                        className={"focus:outline-none"}
                        value={password.oldPassword}
                        onChange={(event) => setPassword({
                            ...password,
                            oldPassword: event.target.value
                        })}
                    />
                    {isShowPassword.showOldPassword &&
                        <span className={""} onClick={() => setIsShowPassword({
                            ...isShowPassword,
                            showOldPassword: false
                        })}>
                            <BsEye className={"text-text-800 text-lg cursor-pointer"} />
                        </span>
                    }
                    {!isShowPassword.showOldPassword &&
                        <span className={""} onClick={() => setIsShowPassword({
                            ...isShowPassword,
                            showOldPassword: true
                        })}>
                            <BsEyeSlash className={"text-text-800 text-lg cursor-pointer"} />
                        </span>
                    }
                </div>
                {(password?.oldPassword && password?.oldPassword !== TEST_PASSWORD) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {"This is not the correct password for your account"}
                    </span>
                }
            </div>

            {/* new password container */}
            <span className={"col-start-1 col-span-full md:col-start-1 md:col-span-2 lg:col-start-1 lg:col-span-2 font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
                {"New Password"}
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5"}>
                <div className={clsx(
                    "flex items-center justify-between gap-1",
                    "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400 hover:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                )}>
                    <input
                        type={isShowPassword.showNewPassword ? "text" : "password"}
                        className={"focus:outline-none"}
                        value={password.newPassword}
                        onChange={(event) => setPassword({
                            ...password,
                            newPassword: event.target.value
                        })}
                    />
                    {isShowPassword.showNewPassword &&
                        <span className={""} onClick={() => setIsShowPassword({
                            ...isShowPassword,
                            showNewPassword: false
                        })}>
                            <BsEye className={"text-text-800 text-lg cursor-pointer"} />
                        </span>
                    }
                    {!isShowPassword.showNewPassword &&
                        <span className={""} onClick={() => setIsShowPassword({
                            ...isShowPassword,
                            showNewPassword: true
                        })}>
                            <BsEyeSlash className={"text-text-800 text-lg cursor-pointer"} />
                        </span>
                    }
                </div>
                {(password.newPassword && (!validatePassword(password.newPassword))) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {"Passwords must be 8 to 20 characters, contain more than 2 types of characters, and not include spaces."}
                    </span>
                }
            </div>

            {/* confirm password container */}
            <span className={clsx(
                "col-start-1 col-span-full md:col-start-1 md:col-span-2 lg:col-start-1 lg:col-span-2",
                "font-bodyPri font-normal text-text-800 text-base tracking-wide lg:whitespace-nowrap"
            )}
            >
                {"Confirm Password"}
            </span>
            <div className={"col-start-1 col-span-full md:col-start-4 md:col-span-6 lg:col-start-3 lg:col-span-5"}>
                <div className={clsx(
                    "flex items-center justify-between gap-1",
                    "px-3 py-1 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400 hover:border-text-400",
                    "font-bodyPri font-normal text-text-800 text-base",
                )}>
                    <input
                        type={isShowPassword.showConfirmPassword ? "text" : "password"}
                        className={"focus:outline-none"}
                        value={password.confirmPassword}
                        onChange={(event) => setPassword({
                            ...password,
                            confirmPassword: event.target.value
                        })}
                    />
                    {isShowPassword.showConfirmPassword &&
                        <span className={""} onClick={() => setIsShowPassword({
                            ...isShowPassword,
                            showConfirmPassword: false
                        })}>
                            <BsEye className={"text-text-800 text-lg cursor-pointer"} />
                        </span>
                    }
                    {!isShowPassword.showConfirmPassword &&
                        <span className={""} onClick={() => setIsShowPassword({
                            ...isShowPassword,
                            showConfirmPassword: true
                        })}>
                            <BsEyeSlash className={"text-text-800 text-lg cursor-pointer"} />
                        </span>
                    }
                </div>
                {((password.newPassword && password.confirmPassword) && password.newPassword !== password.confirmPassword) &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {"The passwords you entered do not match"}
                    </span>
                }
            </div>
            <div className={"col-start-1 col-span-4 md:col-start-4 md:col-span-4 lg:col-start-3 lg:col-span-3"}>
                <SaveAndCancelBtn
                    onCancel={() => setIsUpdatePassword(false)}
                    onSave={() => onSavePassword()}
                />
            </div>
        </div>
    )
}

export default ChangePassword