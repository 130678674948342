import { useState } from "react";

import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import Typography from "components/Typography";

import { pagesInfo } from "utils/pagesInfo";

import { useAppState } from "hooks/useStore";

import { DOMAIN_NAME } from 'const/default.const';


const Refer = () => {
  const { user } = useAppState((s) => s.user)
  const [copiedURL, setCopiedURL] = useState(false);
  const [copiedCode, setCopiedCode] = useState(false);

  const referralUrl = `${DOMAIN_NAME}${pagesInfo.REFERRAL_SIGN_UP.pagePath}/${user?.user?.referralCode}`
  const referAndGetReward = "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/refer-and-earn-page/header-refer-and-earn.png"

  const handleCopyURL = () => {
    navigator.clipboard.writeText(referralUrl);
    setCopiedURL(true);
    setTimeout(() => {
      setCopiedURL(false);
    }, 2000);
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(user?.user?.referralCode);
    setCopiedCode(true);
    setTimeout(() => {
      setCopiedCode(false);
    }, 2000);
  };

  return (
    <div className="bg-white rounded-lg p-5">
      <div className="grid grid-cols-12 w-full">
        <div className="col-span-12 col-start-1 lg:col-span-4 flex items-center justify-center">
          <div className="w-60 h-60 flex justify-center items-center overflow-hidden">
            <img src={referAndGetReward} alt="Refer-Main" className="w-full h-full object-cover" />
          </div>
        </div>
        <div className="col-span-12 col-start-1 lg:col-start-5 lg:col-span-8">
          <div className="flex justify-center items-center flex-col">
            <Typography className="text-3xl font-medium text-primary-main py-4">
              {"Refer a friend and get rewarded!"}
            </Typography>
            <Typography className="text-lg font-medium text-text-600">
              {"When a friend joins Edulyte and pays for a lesson,"}
            </Typography>
            <Typography className="text-lg font-medium text-text-600 pb-16">
              {"you will both get USD 5 credit in wallet."}
            </Typography>
          </div>

          <div className="flex justify-center">
            <div className="flex flex-wrap flex-col">
              <div className="flex flex-wrap items-center">
                <div
                  id="refer-url"
                  className="w-80 border border-gray-300 text-gray-500 font-medium p-1"
                >
                  {referralUrl}
                </div>
                <div
                  className="w-32 py-1 font-medium cursor-pointer text-center bg-primary-light text-primary-main hover:text-text-50 hover:bg-primary-main"
                  onClick={handleCopyURL}
                >
                  {copiedURL ? "Copied" : "Copy Link"}
                </div>
              </div>

              <div className="flex flex-wrap items-center mt-4">
                <div
                  id="refer-code"
                  className="w-80 border border-gray-300 text-gray-500 font-medium p-1"
                >
                  {user?.user?.referralCode}
                </div>
                <div
                  className="w-32 py-1 font-medium cursor-pointer text-center bg-primary-light text-primary-main hover:text-text-50 hover:bg-primary-main"
                  onClick={handleCopyCode}
                >
                  {copiedCode ? "Copied" : "Copy Code"}
                </div>
              </div>

              <div className="flex flex-wrap mt-10 w-full justify-between items-center">
                <Typography className="text-xl text-text-700 font-medium">
                  {"Share via:"}
                </Typography>
                <div className="flex space-x-2">
                  <FacebookShareButton url={referralUrl}>
                    <FacebookIcon size={50} />
                  </FacebookShareButton>
                  <WhatsappShareButton url={referralUrl}>
                    <WhatsappIcon size={50} />
                  </WhatsappShareButton>
                  <LinkedinShareButton url={referralUrl}>
                    <LinkedinIcon size={50} />
                  </LinkedinShareButton>
                  <TwitterShareButton url={referralUrl}>
                    <TwitterIcon size={50} />
                  </TwitterShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refer;
