import { useState } from 'react';
import tw from 'twin.macro';

import { useUser } from 'pages/auth/message/contexts/userContext';

import {
    LogedInNavLink,
    ReferFriend,
    IconSection,
    IconContainer,
    MessageIcon,
    NotificationIcon,
    BadgeCounter,
    ProfileIcon,
} from './NavBarStyle';
import RightLogedInToggleMenu from './RightLogedInToggleMenu';
import DropDownMenu from 'components/dropdownMenu/DropDownMenu';
import NotificationBar from 'pages/auth/message/components/NotificationBar/NotificationBar';

import { useAppState } from 'hooks/useStore';
import { pagesInfo } from 'utils/pagesInfo';

const messageCount = 11;
const notificationCount = 5;

const RightLogedInNavLinks = ({ isScrolled, isMouseHover }) => {
    const { user } = useAppState((state) => state.user)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const pageName = currentPageInfo.pageName;

    const [isClick, setIsClick] = useState(false)

    const userContext = useUser()

    return (
        <LogedInNavLink>
            {pageName !== pagesInfo.REFERRAL.pageName && <ReferFriend to={pagesInfo.REFERRAL.pagePath}
                isScrolled={isScrolled}
                isMouseHover={isMouseHover}
                pageName={pageName}
            >{'Refer a friend'}</ReferFriend>
            }
            <IconSection>
                <IconContainer>
                    {userContext?.loadSuccess && userContext?.user && <NotificationBar />}
                </IconContainer>
                {/* <IconContainer to={'#'}>
                    <MessageIcon />
                    <BadgeCounter>{messageCount >= 10 ? '9+' : messageCount}</BadgeCounter>
                </IconContainer>
                <IconContainer to={'#'}>
                    <NotificationIcon />
                    <BadgeCounter>{notificationCount >= 10 ? '9+' : notificationCount}</BadgeCounter>
                </IconContainer> */}
            </IconSection>
            <ProfileIcon onClick={() => setIsClick(!isClick)}>
                <img src={user?.user?.profilePicUrl} alt={'profile-pic'} />
            </ProfileIcon>
            <DropDownMenu isClick={isClick} setIsClick={setIsClick} boxStyle={tw`bg-white right-0`} arrowStyle={tw`bg-white`}>
                <RightLogedInToggleMenu setIsClick={setIsClick} />
            </DropDownMenu>
        </LogedInNavLink>
    )
}

export default RightLogedInNavLinks
