import tw, { styled } from 'twin.macro';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';


export const Container = tw(Section)``;

export const Content = tw(SectionContent)`
    col-start-2 col-span-10 lg:(col-start-3 col-span-8) space-y-8
`;

export const Header = tw.div`
    flex flex-col items-center gap-5
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800 text-center
`;

export const ItemContainer = tw.div`
    grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-10 gap-x-5
`;

export const FeaturedItemContainer = tw.div`
    grid col-span-1 cursor-pointer
    flex flex-col justify-center items-center gap-3 justify-self-center
    bg-white rounded-2xl shadow w-5/6 p-3
    font-bodyPri font-medium text-base text-text-900 text-center
    hover:(text-primary-dark bg-primary-light)
    scale-110 transition ease-in-out delay-100 duration-150
`;

export const ItemImage = styled.div`
    ${tw`
        h-16 w-16 overflow-hidden
    `}
    img {
        ${tw`
            w-full h-full object-cover
        `}
    }
`;