import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getToken } from 'redux/auth/auth.request';


const PersistLoginRouter = () => {
    const { logInSignUp } = useAppState(s => s.auth)
    const [persist] = useState(JSON.parse(localStorage.getItem("persist")) || true)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (!logInSignUp?.isLogedIn && persist) {
            dispatcher(getToken())
        }
    }, [])

    return (
        <>
            {!persist
                ? <Outlet />
                : logInSignUp?.isLoading
                    ? <p>Loading...</p>
                    : <Outlet />
            }
        </>
    )
}

export default PersistLoginRouter