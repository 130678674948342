import { useState } from "react";
import clsx from 'clsx';

import { FiEdit } from 'react-icons/fi';
import { AiOutlineClose } from "react-icons/ai";

import { profileEditBtn } from 'pages/auth/profile/data';

import SaveAndCancelBtn from 'pages/auth/profile/SaveAndCancelBtn';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { updateStudentUserDetailByUserId } from 'redux/student/student.request';
import { updateTutorUserDetailByUserId } from 'redux/tutor/tutor.request';
import { userRoles } from 'redux/auth/auth.const';


const Skills = ({ editBtnConst, setEditBtnConst }) => {
  const { locals } = useAppState((state) => state.local);
  const { user } = useAppState((state) => state.user);
  const { studentUserDetail } = useAppState((state) => state.student);
  const { tutorUserDetail } = useAppState((state) => state.tutor);

  const dispatcher = useAppDispatcher()

  const [skillList, setSkillList] = useState([])
  const [inputSkill, setInputSkill] = useState("");

  const onHandleDelete = (selectedSkill) => {
    setSkillList(skillList.filter(skill => skill.skill !== selectedSkill.skill))
  };

  const onHandleAdd = (e) => {
    if ((e.keyCode === 9 || e.keyCode === 13 || e.keyCode === 32) && inputSkill.trim()) {
      const skillTag = inputSkill.trim()
      setSkillList([...skillList, { skill: skillTag }])
      setInputSkill("");
      const element = document.getElementById("skill");
      element.focus();
    }
  }

  const onHandleSave = (key, value) => {
    if (skillList.length < 5) {
      alert("Please enter at least 5 skills.")
      return;
    }

    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(updateStudentUserDetailByUserId(user?.user?.userId, { [key]: value }))
    } else if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(updateTutorUserDetailByUserId(user?.user?.userId, { [key]: value }))
    }
    setEditBtnConst(null)
  }


  return (
    <div className="">
      {locals.userRole === userRoles.STUDENT.value &&
        <div className={"flex flex-col items-start justify-start gap-3"}>
          <div className="flex items-center gap-3">
            <div className={"flex items-center gap-2"}>
              <span className={"text-xl font-medium text-text-800 font-bodyPri tracking-wide"}>
                {'Skills I would like to learn:'}
              </span>
              <span className={clsx(
                "text-xs text-text-800 font-bodyPri font-normal tracking-wide",
                studentUserDetail?.studentUserDetail?.skills?.length < 5 && "text-red-500"
              )}>
                {"(add at least 5 skills)"}
              </span>
            </div>
            {!editBtnConst &&
              <FiEdit className="text-lg text-text-500 cursor-pointer"
                onClick={() => {
                  setSkillList(studentUserDetail?.studentUserDetail?.skills)
                  setEditBtnConst(profileEditBtn.skills.value)
                }}
              />
            }
          </div>
          {editBtnConst !== profileEditBtn.skills.value &&
            <div className="w-full px-3 py-2">
              {studentUserDetail?.studentUserDetail?.skills?.length > 0 ? (
                <div className="flex flex-wrap px-3">
                  {studentUserDetail?.studentUserDetail?.skills?.map((skill, index) => (
                    <span className="w-max px-4 py-1 rounded-xl bg-blue-100 text-blue-600 text-md font-semibold m-2 ml-0"
                      key={index}
                    >
                      {skill.skill}
                    </span>
                  ))}
                </div>
              ) : (
                <div className="italic text-gray-500 mb-24">
                  {"add at least 5 skills seperated by commas"}
                </div>
              )}
            </div>
          }
        </div>
      }
      {locals.userRole === userRoles.TUTOR.value &&
        <div className={"flex flex-col items-start justify-start gap-3"}>
          <div className="flex items-center gap-3">
            <div className={"flex items-center gap-2"}>
              <span className={"text-xl font-medium text-text-800 font-bodyPri tracking-wide"}>
                {'Skills I teach:'}
              </span>
              <span className={clsx(
                "text-xs text-text-800 font-bodyPri font-normal tracking-wide",
                tutorUserDetail?.tutorUserDetail?.skills?.length < 5 && "text-red-500"
              )}>
                {"(add at least 5 skills)"}
              </span>
            </div>
            {!editBtnConst &&
              <FiEdit className="text-lg text-text-500 cursor-pointer"
                onClick={() => {
                  setSkillList(tutorUserDetail?.tutorUserDetail?.skills)
                  setEditBtnConst(profileEditBtn.skills.value)
                }}
              />
            }
          </div>
          {editBtnConst !== profileEditBtn.skills.value &&
            <div className="w-full px-3 py-2">
              {tutorUserDetail?.tutorUserDetail?.skills?.length > 0 ? (
                <div className="flex flex-wrap px-3">
                  {tutorUserDetail?.tutorUserDetail?.skills?.map((skill, index) => (
                    <span className="w-max px-4 py-1 rounded-xl bg-blue-100 text-blue-600 text-md font-semibold m-2 ml-0"
                      key={index}
                    >
                      {skill.skill}
                    </span>
                  ))}
                </div>
              ) : (
                <div className="italic text-gray-500 mb-24">
                  {"add at least 5 skills seperated by commas"}
                </div>
              )}
            </div>
          }
        </div>
      }
      {editBtnConst === profileEditBtn.skills.value &&
        <div className="flex flex-col items-start justify-start gap-2">
          <div className="w-full mt-2 border-secondary-dark border-dashed border-2 focus:outline-none text-lg flex flex-wrap py-2 px-4">
            {skillList?.map((skill, index) => (
              <div key={index}
                className="flex items-center w-max px-4 py-1 rounded-xl bg-blue-100 text-blue-600 text-md font-semibold m-2 ml-0"
              >
                <span>
                  {skill.skill}
                </span>
                <AiOutlineClose onClick={() => onHandleDelete(skill)} className={"text-lg cursor-pointer"} />
              </div>
            ))}
            <input
              className="w-40 h-10 rounded-lg mt-1 px-2 py-0 border-2 border-gray-100 focus:outline-none"
              type="text"
              placeholder="Add Skills"
              id="skill"
              name="skill"
              autoFocus={true}
              value={inputSkill}
              onChange={(e) => setInputSkill(e.target.value)}
              onKeyDown={onHandleAdd}
            />
          </div>
          <SaveAndCancelBtn
            onCancel={() => setEditBtnConst(null)}
            onSave={() => onHandleSave(editBtnConst, skillList)}
          />
        </div>
      }
    </div>
  );
};

export default Skills;
