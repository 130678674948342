import { toast } from "react-toastify";

import tutorService from "redux/tutor/tutor.services"
import {
    setTutorListLoading,
    setTutorList,
    setTutorListErrorMsg,

    setTutorPublicProfileLoading,
    setTutorPublicProfile,
    setTutorPublicProfileErrorMsg,

    setMyTutorListLoading,
    setMyTutorList,
    setMyTutorListErrorMsg,

    setFavTutorListLoading,
    setFavTutorList,
    setFavTutorListErrorMsg,

    setAddFavTutorLoading,
    setAddFavTutorErrorMsg,

    setTutorUserDetailLoading,
    setTutorUserDetail,
    setTutorUserDetailErrorMsg,

    setAddTutorUserDetailLoading,
    setAddTutorUserDetailErrorMsg,

    setTutorUserPriceLoading,
    setTutorUserPrice,
    setTutorUserPriceErrorMsg,

    setAddTutorUserPriceLoading,
    setAddTutorUserPriceErrorMsg,

    setTutorUserLessonLoading,
    setTutorUserLesson,
    setTutorUserLessonErrorMsg,

    setAddTutorUserLessonLoading,
    setAddTutorUserLessonErrorMsg,

    setTutorUserLanguageListLoading,
    setTutorUserLanguageList,
    setTutorUserLanguageListErrorMsg,

    setAddTutorLanguageDetailLoading,
    setAddTutorLanguageDetailErrorMsg,

    setTutorUserCategoryListLoading,
    setTutorUserCategoryList,
    setTutorUserCategoryListErrorMsg,

    setAddTutorCategoryDetailLoading,
    setAddTutorCategoryDetailErrorMsg
} from "redux/tutor/tutor.slice"

import { setUser } from "redux/user/user.slice";

// request for public tutor list
export const getTutorPublicList = (query) => async (dispatch) => {
    dispatch(setTutorListLoading(true))
    const requestData = {
        query: { roleStatus: "active", isVerified: "verified", ...query }
    }
    try {
        const response = await tutorService.getTutorPublicList(requestData)
        if (response.status === 200) {
            dispatch(setTutorList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorListLoading(false))
    }
}

// request for tutor public profile by user id
export const getTutorUserPublicProfileByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorPublicProfileLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", isVerified: "verified" },
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserPublicProfileByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorPublicProfile(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorPublicProfileErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorPublicProfileLoading(false))
    }
}

// request fro get my tutor list by user id 
export const getMyTutorUserListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setMyTutorListLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", isVerified: "verified", ...query },
            params: { userId: userId }
        }
        const response = await tutorService.getMyTutorUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setMyTutorList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setMyTutorListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setMyTutorListLoading(false))
    }
}

// request for get fav tutor list by user id
export const getFavTutorUserListByUserId = (userId, query) => async (dispatch) => {
    dispatch(setFavTutorListLoading(true))

    try {
        const requestData = {
            query: { roleStatus: "active", isVerified: "verified", ...query },
            params: { userId: userId }
        }
        const response = await tutorService.getFavTutorUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setFavTutorList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setFavTutorListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setFavTutorListLoading(false))
    }
}

// request for add fav tutor by user id
export const createFavTutorUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavTutorLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.createFavTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favTutors: [...user?.user?.favTutors, { tutorId: response.data.data.tutorId }]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFavTutorErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddFavTutorLoading(false))
    }
}

// request for delete fav tutor by user id
export const deleteFavTutorUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavTutorLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.deleteFavTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favTutors: user?.user?.favTutors?.filter((tutor) => tutor?.tutorId !== body.tutorId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFavTutorErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddFavTutorLoading(false))
    }
}

// request for tutor detail by user id
export const getTutorUserDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorUserDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorUserDetailLoading(false))
    }
}

// request for update tutor detail by user id
export const updateTutorUserDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddTutorUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.updateTutorUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorUserDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setTutorUserDetailLoading(false))
    }
}

// request for tutor price by user id
export const getTutorUserPriceByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserPriceLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserPriceByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserPrice(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorUserPriceErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorUserPriceLoading(false))
    }
}

// request for update tutor price by user id
export const updateTutorUserPriceByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddTutorUserPriceLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.updateTutorUserPriceByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserPrice(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorUserPriceErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorUserPriceLoading(false))
    }
}

// request for tutor lesson by user id
export const getTutorUserLessonByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserLessonLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserLessonByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLesson(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorUserLessonErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorUserLessonLoading(false))
    }
}

// request for update tutor lesson by user id
export const updateTutorUserLessonByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddTutorUserLessonLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.updateTutorUserLessonByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLesson(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.log(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorUserLessonErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorUserLessonLoading(false))
    }
}

// request for tutor language list
export const getTutorUserLanguageListByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserLanguageListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserLanguageListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorUserLanguageListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorUserLanguageListLoading(false))
    }
}

// request for add tutor language detail
export const createTutorUserLanguageDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const { tutorUserLanguageList } = getState().tutor
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.createTutorUserLanguageDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList([
                ...tutorUserLanguageList?.tutorUserLanguageList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorLanguageDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

// request for update tutor language detail
export const updateTutorLanguageDetailByTutorLanguageId = (tutorLanguageId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const { tutorUserLanguageList } = getState().tutor
        const requestData = {
            params: { tutorLanguageId: tutorLanguageId },
            body: body
        }
        const response = await tutorService.updateTutorLanguageDetailByTutorLanguageId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList(
                tutorUserLanguageList?.tutorUserLanguageList.map(tutorUserLanguage => (
                    tutorUserLanguage.tutorLanguageId === tutorLanguageId ? response.data.data : tutorUserLanguage
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorLanguageDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

export const deleteTutorLanguageDetailByTutorLanguageId = (tutorLanguageId) => async (dispatch, getState) => {
    dispatch(setAddTutorLanguageDetailLoading(true))

    try {
        const { tutorUserLanguageList } = getState().tutor
        const requestData = {
            params: { tutorLanguageId: tutorLanguageId }
        }
        const response = await tutorService.deleteTutorLanguageDetailByTutorLanguageId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserLanguageList(
                tutorUserLanguageList?.tutorUserLanguageList.filter(tutorUserLanguage => (
                    tutorUserLanguage.tutorLanguageId !== tutorLanguageId
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorLanguageDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorLanguageDetailLoading(false))
    }
}

// request for tutor category list 
export const getTutorUserCategoryListByUserId = (userId) => async (dispatch) => {
    dispatch(setTutorUserCategoryListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await tutorService.getTutorUserCategoryListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setTutorUserCategoryListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setTutorUserCategoryListLoading(false))
    }
}

// request for add tutor category detail
export const createTutorUserCategoryDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorCategoryDetailLoading(false))

    try {
        const { tutorUserCategoryList } = getState().tutor
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await tutorService.createTutorUserCategoryDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList([
                ...tutorUserCategoryList?.tutorUserCategoryList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorCategoryDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}

// request for update tutor category detail
export const updateTutorCategoryDetailByTutorCategoryId = (tutorCategoryId, body) => async (dispatch, getState) => {
    dispatch(setAddTutorCategoryDetailLoading(false))

    try {
        const { tutorUserCategoryList } = getState().tutor
        const requestData = {
            params: { tutorCategoryId: tutorCategoryId },
            body: body
        }
        const response = await tutorService.updateTutorCategoryDetailByTutorCategoryId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList(
                tutorUserCategoryList?.tutorUserCategoryList.map(tutorUserCategory => (
                    tutorUserCategory?.tutorCategoryId === tutorCategoryId ? response.data.data : tutorUserCategory
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorCategoryDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}

// request for delete tutor category detail
export const deleteTutorCategoryDetailByTutorCategoryId = (tutorCategoryId) => async (dispatch, getState) => {
    dispatch(setAddTutorCategoryDetailLoading(false))

    try {
        const { tutorUserCategoryList } = getState().tutor
        const requestData = {
            params: { tutorCategoryId: tutorCategoryId }
        }
        const response = await tutorService.deleteTutorCategoryDetailByTutorCategoryId(requestData)
        if (response.status === 200) {
            dispatch(setTutorUserCategoryList(
                tutorUserCategoryList?.tutorUserCategoryList.filter(tutorUserCategory => (
                    tutorUserCategory?.tutorCategoryId !== tutorCategoryId
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddTutorCategoryDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddTutorCategoryDetailLoading(false))
    }
}