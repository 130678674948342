import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import clsx from "clsx";

import { FaInfoCircle } from 'react-icons/fa';

import ProgressBar from "components/progressBar/ProgressBar";

import NameDetails from "pages/auth/profile/NameDetails";
import UserLanguage from "pages/auth/profile/UserLanguage";
import UserElevatorPitch from "pages/auth/profile/UserElevatorPitch";
import About from "pages/auth/profile/About";
import Skills from "pages/auth/profile/Skills";
import Qualification from "pages/auth/profile/Qualification";
import PersonalDetail from "pages/auth/profile/personalDetails/index";
import MyTeachingStyle from "pages/auth/profile/MyTeachingStyle";
import MyLearningStyle from "pages/auth/profile/MyLearningStyle";
import { importantLinks } from "pages/auth/profile/data";

import { useAppState } from "hooks/useStore";
import { userRoles } from "redux/auth/auth.const";

const ProfileHeader = () => {
  const { locals } = useAppState((state) => state.local)
  const { user } = useAppState((s) => s.user)

  return (
    <div className="p-5 w-full flex flex-col md:flex-row justify-between items-center gap-2">
      <div className={"hidden md:flex flex-1 items-center justify-start"}></div>
      <div className="flex flex-1 item-start justify-center gap-1">
        <span className="font-subHeading font-medium text-2xl text-text--800">
          {"Update My Profile"}
        </span>
        <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700 cursor-pointer" />
      </div>
      <div className={"flex flex-1 items-center justify-end"}>
        {locals.userRole === userRoles.TUTOR.value &&
          <Link to={`/tutors/${user?.user?.userId}`}
            className={clsx(
              "px-12 py-1 m-2 inline-block rounded-xl cursor-pointer font-medium whitespace-nowrap",
              "border-2 border-primary-main hover:bg-primary-main text-primary-main hover:text-white"
            )}
          >
            {"View Profile"}
          </Link>
        }
      </div>
    </div>
  );
}

const Profile = () => {
  const { locals } = useAppState((s) => s.local)
  const { user, userEducationList, userDetail, userCultureDetail } = useAppState((state) => state.user)
  const { studentUserDetail, studentUserLanguageList } = useAppState((state) => state.student);
  const { tutorUserDetail, tutorUserLanguageList } = useAppState((state) => state.tutor);

  const [editBtnConst, setEditBtnConst] = useState(null)
  const [totalStepCount, setTotalStepCount] = useState(13)
  const [stepCount, setStepCount] = useState(0)

  useEffect(() => {
    setTotalStepCount(locals.userRole === userRoles?.TUTOR.value ? 14 : 13)
    let count = 0;
    if (user?.user?.profilePicUrl) {
      count++
    }
    if (user?.user?.firstName) {
      count++
    }
    if (user?.user?.lastName) {
      count++
    }
    if (userEducationList?.userEducationList?.length > 0) {
      count++
    }
    if (userDetail?.userDetail?.gender) {
      count++
    }
    if (userDetail?.userDetail?.dateOfBirth) {
      count++
    }
    if (userCultureDetail?.userCultureDetail?.fromCountry?.country) {
      count++
    }
    if (userCultureDetail?.userCultureDetail?.liveInCountry?.country) {
      count++
    }


    if (locals.userRole === userRoles?.STUDENT.value && studentUserLanguageList?.studentUserLanguageList?.length > 0) {
      count++
    }
    if (locals.userRole === userRoles?.STUDENT.value && studentUserDetail?.studentUserDetail?.elevatorPitch) {
      count++
    }
    if (locals.userRole === userRoles?.STUDENT.value && studentUserDetail?.studentUserDetail?.learningStyleBio) {
      count++
    }
    if (locals.userRole === userRoles?.STUDENT.value && studentUserDetail?.studentUserDetail?.bio) {
      count++
    }
    if (locals.userRole === userRoles?.STUDENT.value && studentUserDetail?.studentUserDetail?.skills?.length > 4) {
      count++
    }


    if (locals.userRole === userRoles?.TUTOR.value && tutorUserDetail?.tutorUserDetail?.videoUrl) {
      count++
    }
    if (locals.userRole === userRoles?.TUTOR.value && tutorUserLanguageList?.tutorUserLanguageList?.length > 0) {
      count++
    }
    if (locals.userRole === userRoles?.TUTOR.value && tutorUserDetail?.tutorUserDetail?.elevatorPitch) {
      count++
    }
    if (locals.userRole === userRoles?.TUTOR.value && tutorUserDetail?.tutorUserDetail?.bio) {
      count++
    }
    if (locals.userRole === userRoles?.TUTOR.value && tutorUserDetail?.tutorUserDetail?.teachingStyleBio) {
      count++
    }
    if (locals.userRole === userRoles?.TUTOR.value && tutorUserDetail?.tutorUserDetail?.skills?.length > 4) {
      count++
    }

    setStepCount(count)
  }, [
    user?.user,
    userDetail?.userDetail,
    userEducationList?.userEducationList,
    userCultureDetail?.userCultureDetail,
    studentUserLanguageList?.studentUserLanguageList,
    tutorUserLanguageList?.tutorUserLanguageList,
    studentUserDetail?.studentUserDetail,
    tutorUserDetail?.tutorUserDetail
  ])

  return (
    <div className="p-5 bg-white rounded-lg space-y-8">
      <ProfileHeader />

      <div className="border border-divider-lightDark"></div>

      <div className={"flex flex-col items-center gap-3"}>
        <span className={"font-bodyPri font-medium text-lg text-primary-main text-center"}>
          {"Progress Bar"}
        </span>
        <div className={"w-2/3"}>
          <ProgressBar pctValue={parseFloat((stepCount / totalStepCount * 100).toFixed(2))} />
        </div>
      </div>

      <div className={"space-y-8 px-8"}>

        <NameDetails
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

        <UserLanguage
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

        <UserElevatorPitch
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

        <About
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

        {locals.userRole === userRoles.STUDENT.value &&
          <MyLearningStyle
            editBtnConst={editBtnConst}
            setEditBtnConst={setEditBtnConst}
          />
        }
        {locals.userRole === userRoles.TUTOR.value &&
          <MyTeachingStyle
            editBtnConst={editBtnConst}
            setEditBtnConst={setEditBtnConst}
          />
        }

        <Skills
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

        <Qualification
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

        <PersonalDetail
          editBtnConst={editBtnConst}
          setEditBtnConst={setEditBtnConst}
        />

      </div>

      <div className="border border-divider-lightDark"></div>

      <div className="flex justify-center items-center py-8 flex-wrap">
        {importantLinks.map((impLink, index) => impLink.roles.includes(locals.userRole) && (
          <Link key={index} to={impLink.url}
            className={"border-2 rounded-xl border-primary-main px-12 py-1 inline-block font-medium cursor-pointer hover:bg-primary-main m-2 text-primary-main hover:text-white"}
          >
            {impLink.label}
          </Link>
        ))}
      </div>

    </div>
  );
};

export default Profile;
