import {
    Container,
    Content
} from './TeachWithUsHowItWorkStyle';
import { timeLineData } from './TeachWithUsHowItWorkData';
import VerticalTimeLine from 'components/timeLine/verticalTimeLine/VerticalTimeLine';

const TeachWithUsHowItWork = () => {
    return (
        <Container>
            <Content>
                <VerticalTimeLine title={'How it works?'} timeLineData={timeLineData} />
            </Content>
        </Container>
    )
}

export default TeachWithUsHowItWork;
