import React from "react";
//Icont imports
import { FilterIcon } from "@heroicons/react/outline";

//Style imports
import { StyledChatListContainerHeader } from "./ChatListContainerHeader.styles";

export default function ChatListContainerHeader({ setShowFiltersModal }) {
  function displayChatFilterModal() {
    setShowFiltersModal(true);
  }

  return (
    <StyledChatListContainerHeader>
      <p>Messages</p>
      <div onClick={displayChatFilterModal}>
        <FilterIcon className="h-4 w-4 text-primary-dark" />
        <p>Filter</p>
      </div>
    </StyledChatListContainerHeader>
  );
}
