import React from "react";
import sendicon from "../../icons/sendicon.svg";

export default function ChatViewSendMessageSend() {
  return (
    <div className="h-8 w-8">
      <img className="block" src={sendicon} alt="send message" />
    </div>
  );
}
