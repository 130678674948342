import clsx from 'clsx';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import FullPageLoader from 'components/loader/FullPageLoader';
import CourseCard from 'components/courseCard/CourseCard';
import Footer from 'components/footer/Footer';

import { PageContentStyle } from 'pages/global/course/CoursePage.style';

import CourseHeader from 'pages/global/course/CourseHeader';
import CourseDetailBar from 'pages/global/course/CourseDetailBar';
import SkillsDiv from 'pages/global/course/SkillsDiv';
import CourseDescriptionDiv from 'pages/global/course/CourseDescriptionDiv';
import { SubHeaders } from 'pages/global/course/CourseDetailed1To1PageStyle';
import CourseHeaderCard from 'pages/global/course/CourseHeaderCard';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import {
    getCoursePublicList,
    getCoursePublicProfileByCourseId,
    createFavCourseUserDetailByUserId,
    deleteFavCourseDetailByUserId
} from "redux/course/course.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearCourseDetail } from 'redux/course/course.slice'

import { pagesInfo } from 'utils/pagesInfo';

const RECORDS = 6


const CoursePage = () => {
    const { currentPageInfo } = useAppState((s) => s.pageInfo)
    const { user } = useAppState((s) => s.user)
    const { courseDetail, courseList } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()

    const { courseId } = useParams()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getCoursePublicProfileByCourseId(courseId))

        return () => {
            dispatcher(setClearCourseDetail())
        }
    }, [])

    useEffect(() => {
        if (courseDetail?.courseDetail) {
            dispatcher(getCoursePublicList({ page: 1, records: RECORDS }))
        }

    }, [courseDetail?.courseDetail])


    if (courseDetail?.isLoading) {
        return (
            <FullPageLoader isLoading={courseDetail?.isLoading} />
        )
    }

    if (courseDetail?.errorMsg) {
        return (
            <PageContentStyle className={"h-screen"}>
                <div className={"flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {courseDetail?.errorMsg}
                </div>
            </PageContentStyle>
        )
    }

    return (
        <PageContentStyle>
            <div className="pt-16 col-span-12 bg-background-dark flex justify-center">
                <div className="max-w-sm md:max-w-2xl lg:max-w-5xl w-full px-0 lg:px-10">
                    <CourseHeader />
                </div>
            </div>

            <div className="flex justify-center">
                <div className="max-w-sm md:max-w-2xl lg:max-w-5xl w-full px-0 lg:px-10 grid grid-cols-12 space-y-5">

                    <div className="col-start-1 col-span-12 lg:col-span-8 bg-white p-5">
                        <CourseDetailBar />
                    </div>
                    <div className="col-start-1 col-span-12 lg:col-span-8 bg-white p-5">
                        <SkillsDiv />
                    </div>
                    <div className='lg:hidden h-auto relative col-start-1 col-span-12 lg:col-span-8 bg-white p-5'>
                        <CourseHeaderCard show={true} position="relative w-full " />
                    </div>
                    <div className="col-start-1 col-span-12 lg:col-span-8 bg-white p-5">
                        <CourseDescriptionDiv />
                    </div>
                    <div className="col-start-1 col-span-12 lg:col-span-8 bg-white p-5 space-y-3">
                        <SubHeaders>{"Similar Classes"}</SubHeaders>
                        <div className={clsx(
                            "grid grid-flow-col gap-5 py-5 overflow-hidden overflow-x-scroll",
                            "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                            "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                        )}>
                            {courseDetail?.courseDetail && courseList?.courseList?.results?.filter((course) => course.category === courseDetail?.courseDetail?.category).map((course, index) => (
                                <div className={"min-w-[20rem] max-w-[20rem] shadow-lg"}>
                                    <CourseCard
                                        key={index}
                                        course={course}
                                        isFav={user?.user?.favCourses?.filter((favCourse) =>
                                            favCourse.courseId === course.courseId).length > 0 ? true : false}
                                        handleFav={(courseId) => {
                                            const favoriteCourses = user?.user?.favCourses?.filter((favCourse) =>
                                                favCourse.courseId === courseId)
                                            if (favoriteCourses?.length > 0) {
                                                dispatcher(deleteFavCourseDetailByUserId(user?.user?.userId, { courseId: courseId }))
                                            }
                                            else {
                                                dispatcher(createFavCourseUserDetailByUserId(user?.user?.userId, { courseId: courseId }))
                                            }
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </PageContentStyle>
    )
}

export default CoursePage