import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { MdEdit } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";

import { OptionSelector } from "components/common-components/Select";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { offering, setOfferingCourse } from 'redux/offering/offering.slice';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone, utcWeekDays, generateTimeOption } from "utils/dateTime.utils";
import { validateIsEndBeforeStart, validateIsTimeSlotExist, getIsTimeSlotBooked } from 'utils/availability.utils';



const DayItems = ({ day, timeSlots, editBtnIsEnable }) => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const startTimeOption = generateTimeOption(timeZone)

    const [isAddNew, setIsAddNew] = useState(false)
    const [startTime, setStartTime] = useState(null)

    useEffect(() => {
        if (!editBtnIsEnable) {
            setIsAddNew(false)
            setStartTime(null)
        }
    }, [editBtnIsEnable])

    const handleOnAdd = () => {
        if (isAddNew) {
            setIsAddNew(false)
            setStartTime(null)
        } else {
            setStartTime(dayjs().utc().format('HH:[00:00]'))
            setIsAddNew(true)
        }
    }

    const handleOnAddTimeSlot = () => {
        const selectedTimeSlot = {
            startTime: startTime,
            endTime: dayjs(utcWeekDays[day.toUpperCase()].date + " " + startTime + "+00:00")
                .utc().add(offeringCourse?.offeringCourse?.sessionDuration, 'minute').format("HH:MM:ss")
        }
        console.log(offeringCourse?.offeringCourse?.sessionDuration, offeringCourse?.offeringCourse?.weeklyTimeSlot[day], selectedTimeSlot)
        if (validateIsEndBeforeStart(day, selectedTimeSlot)) {
            alert("End time should be after the start time!")
            return;
        }

        if (validateIsTimeSlotExist(day, selectedTimeSlot, offeringCourse?.offeringCourse?.weeklyTimeSlot[day])) {
            alert("Slot is already exist!")
            return;
        }

        dispatcher(setOfferingCourse({
            ...offeringCourse?.offeringCourse,
            weeklyTimeSlot: {
                ...offeringCourse?.offeringCourse?.weeklyTimeSlot,
                [day]: [...offeringCourse?.offeringCourse?.weeklyTimeSlot[day], selectedTimeSlot]
            }
        }))
        setStartTime(null)
        setIsAddNew(!isAddNew)
    }

    const handleOnDeleteTimeSlot = (selectedSlot) => {
        const filteredTimeSlots = offeringCourse?.offeringCourse?.weeklyTimeSlot[day]?.filter(timeSlot => (
            timeSlot.startTime !== selectedSlot.startTime && timeSlot.endTime !== selectedSlot.endTime
        ))
        dispatcher(setOfferingCourse({
            ...offeringCourse?.offeringCourse,
            weeklyTimeSlot: {
                ...offeringCourse?.offeringCourse?.weeklyTimeSlot,
                [day]: filteredTimeSlots
            }

        }))
    }


    return (timeSlots.length > 0 || editBtnIsEnable) && (
        <div className={clsx(
            "col-spna-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <div className={"flex items-center justify-center gap-2"}>
                <span className={"font-bodyPri font-medium text-base text-primary-dark"}>
                    {dayjs(utcWeekDays[day.toUpperCase()].date + " " + dayjs().utc().format("HH:mm:ss") + "+00:00").tz(timeZone).format('dddd')}
                </span>
                {editBtnIsEnable && !isAddNew && <IoMdAddCircleOutline onClick={handleOnAdd} className={"text-base text-primary-dark cursor-pointer"} />}
                {editBtnIsEnable && isAddNew && <AiFillCheckCircle onClick={handleOnAddTimeSlot} className={"text-base text-primary-dark cursor-pointer"} />}
            </div>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <div className={clsx(
                        "flex items-center justify-center gap-2",
                        editBtnIsEnable && "px-1 py-0.5 rounded-lg bg-white border-2 border-divider-medium"
                    )}>
                        <span key={index} className={clsx("font-bodyPri font-medium text-base text-primary-dark", editBtnIsEnable && "font-normal !text-text-800")}>
                            {dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot?.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
                        </span>
                        {editBtnIsEnable && <AiOutlineCloseCircle onClick={() => handleOnDeleteTimeSlot(timeSlot)} className={"text-base text-secondary-dark cursor-pointer"} />}
                    </div>
                ))}
                {editBtnIsEnable && isAddNew &&
                    <div className={""}>
                        <OptionSelector
                            options={startTimeOption}
                            onChange={(option) => setStartTime(option.value)}
                            value={startTime}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default DayItems