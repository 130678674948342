import clsx from "clsx"
import { useState, useEffect } from "react"

import { FiEdit } from "react-icons/fi";
import { GiCancel } from "react-icons/gi";

import { UploadVideoButton } from "pages/auth/tutorOnboard/TutorOnboardStyle"
import { docStatus, videoCategory, videoTableHeader } from "pages/auth/tutorOnboard/TutorOnboardData"

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setTutorOnboardingPayload } from "redux/tutorOnboarding/tutorOnboarding.slice";

const VideoContainer = ({ task }) => {
    const { tutorOnboarding } = useAppState((s) => s.tutorOnboarding)
    const [videoData, setVideoData] = useState({
        fileCategory: videoCategory.VIDEO_URL,
        videoUrl: '',
        fileName: ''
    })
    const [videoUrlInput, setVideoUrlInput] = useState(false)
    const [openVideoDetailTable, setOpenVideoDetailTable] = useState(false)
    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (task.status === docStatus.statusName.not_submitted) {
            setOpenVideoDetailTable(false)
            setVideoUrlInput(true)
        }
        if (task.status === docStatus.statusName.submitted) {
            setVideoUrlInput(false)
            setOpenVideoDetailTable(true)
        }
    }, [])

    const handleUploadVideoUrl = () => {
        if (videoData?.videoUrl?.includes("https")) {
            task.status = docStatus.statusName.submitted
            dispatcher(setTutorOnboardingPayload({ ...tutorOnboarding?.tutorOnboarding, videoData: videoData }))
            setVideoUrlInput(false)
            setOpenVideoDetailTable(true)
            alert("video uploaded Successfully!")
        }
        else {
            setVideoData({
                fileCategory: videoCategory.VIDEO_URL,
                videoUrl: '',
                fileName: ''
            })
            alert("please enter valid video url")
        }
    }

    const handleEditDocument = () => {
        setOpenVideoDetailTable(true)
        setVideoUrlInput(true)
    }

    const handleDeleteEntry = () => {
        task.status = docStatus.statusName.not_submitted
        setVideoData({
            fileCategory: videoCategory.VIDEO_URL,
            videoUrl: '',
            fileName: ''
        })
        dispatcher(setTutorOnboardingPayload(videoData))
        setOpenVideoDetailTable(false)
        setVideoUrlInput(true)
    }

    return (
        <div className="space-y-5">
            {openVideoDetailTable &&
                <table className="table-fixed">
                    <thead>
                        <tr>
                            {videoTableHeader.map((head, index) => (
                                <th
                                    key={index}
                                    className={clsx(
                                        "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                        "font-bold whitespace-nowrap border border-gray-200"
                                    )}
                                >
                                    {head}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-text-200">
                        <tr className={''} >
                            <td className="border border-gray-200">
                                <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                    {tutorOnboarding?.tutorOnboardingPayload?.videoData?.fileCategory?.label}
                                </span>
                            </td>
                            <td className={"border border-gray-200"}>
                                <a
                                    href={tutorOnboarding?.tutorOnboardingPayload?.videoData?.videoUrl}
                                    target={"_blank"}
                                    className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center underline truncate'>
                                    {tutorOnboarding?.tutorOnboardingPayload?.videoData?.fileName}
                                </a>
                            </td>
                            <td className="border border-gray-200">
                                <span className="px-2 font-bodyPri font-normal text-text-900 text-base italic text-center">{task.status}</span>
                            </td>
                            <td className={"px-5 py-2 border border-gray-200"}>
                                <FiEdit
                                    className={"text-xl text-primary-main cursor-pointer hover:text-primary-dark"}
                                    onClick={handleEditDocument}
                                />
                            </td>
                            <td className={"px-5 py-2 border border-gray-200"}>
                                <GiCancel
                                    className={"text-xl text-text-500 hover:text-text-600 cursor-pointer"}
                                    onClick={handleDeleteEntry}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            {videoUrlInput &&
                <div className="inline-flex mx-auto pt-5">
                    <input
                        type="text"
                        className={"md:flex-1 lg:flex-1 flex px-3 py-1 border outline-none focus:border-primary-light rounded-l-md border-primary-light font-bodyPri font-normal text-base text-text-900 w-48 sm:w-60"}
                        placeholder={"Upload Video Url"}
                        value={videoData.fileName}
                        onChange={(e) => setVideoData({
                            ...videoData,
                            fileName: e.target.value,
                            videoUrl: e.target.value
                        })}
                    />
                    <UploadVideoButton onClick={handleUploadVideoUrl}>
                        {"Upload"}
                    </UploadVideoButton>
                </div>
            }
        </div>
    )
}

export default VideoContainer