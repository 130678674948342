export const blogsData = [
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/blog1new.jpg",
        // author: "Study Abroad, Study in Australia",
        // category: "SEO",
        title: "How Long is a Police Check Valid for in Australia for Educators?",
        description: "The term makes many of us anxious and petrified. However, it doesn’t mean that you are under suspicion.",
        redirectPath: "https://www.edulyte.com/blog/how-long-is-a-police-check-valid-for/"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/blog2new.jpg",
        // author: "Owais Khan",
        // category: "Advertising",
        title: "Edulyte Student Success Model",
        description: "Building a great career requires a solid foundation. It is a no brainer that the only way to building a rock-solid foundation is through academic excellence. Gone are the days when there were limited options in terms of courses to choose from.",
        redirectPath: "https://www.edulyte.com/blog/edulyte-student-success-model/"
    },
    {
        imageUrl: "https://edulyte-website-image.s3.ap-south-1.amazonaws.com/blog3new.jpg",
        // author: "MS Excel, Technology & Development",
        // category: "Social Media",
        title: "Why Online Teaching is Perfect for Working Mothers?",
        description: "Becoming a mother to two beautiful children and watching them grow up is my life’s biggest blessing. It is rightly said that once a mother, always a mother!",
        redirectPath: "https://www.edulyte.com/blog/why-online-teaching-is-perfect-for-working-mothers/"
    }
];