import { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from 'react-router-dom';

import { BsChatLeft} from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

import { dayjs, getTimeZoneOffset, timeZone } from 'utils/dateTime.utils';

import ComponentLoader from "components/loader/ComponentLoader";
import LogTimeLine from "components/logTimeLine/LogTimeLine";

import { bookingLogConst } from "pages/auth/bookings/data";

import { getBookingDetail } from "redux/booking/booking.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearBookingDetail, setClearBookingDetailPayload } from "redux/booking/booking.slice";
import { userRoles } from "redux/auth/auth.const";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { setClearMessageTemplate, setMessageTemplate } from 'redux/firebase/firebase.slice';
import { createFBIndividulaChat, getFBChat, getFBChatId, sendFBMessage } from 'redux/firebase/firebase.request';
import { bookingStatus } from "redux/booking/booking.const";
import { courseType } from "redux/course/course.const";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const createBookingLogs = (bookingLogs) => {
  return bookingLogs.map((booking) => {
    return {
      ...bookingStatus[booking.status.toUpperCase()],
      ...booking,
      title: bookingLogConst[booking.status.toUpperCase()].title
    }
  })
}


export const BookingSideOverContent = () => {
  const { bookingDetail } = useAppState((s) => s.booking)
  const { locals } = useAppState((s) => s.local)
  const { logInSignUp } = useAppState(s => s.auth)
  const { user } = useAppState((s) => s.user)
  const { messageTemplate } = useAppState((s) => s.firebase)
  const { modal } = useAppState((s) => s.local)
  
  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()

  const userIdOne = user?.user?.userId
  let userIdTwo = 0
  if (locals.userRole === userRoles.STUDENT.value) { 
    userIdTwo = bookingDetail?.bookingDetail?.tutor?.userId
  } else if (locals.userRole === userRoles.TUTOR.value) { 
    userIdTwo = bookingDetail?.bookingDetail?.student?.userId
  }

  useEffect(() => {
    if (bookingDetail?.bookingDetailPayload?.bookingId) {
      dispatcher(getBookingDetail(bookingDetail?.bookingDetailPayload?.bookingId))
    }

    return () => {
      dispatcher(setClearBookingDetailPayload())
      dispatcher(setClearBookingDetail())
    }
  }, [bookingDetail?.bookingDetailPayload?.bookingId])

  const handleSendMessage = async () => {
    if (!logInSignUp.isLogedIn) {
        dispatcher(setModal({
            ...modal,
            [modalConst.AUTHORIZATION_MODAL.stateKey]: true
        }))
        return;
    }
    
    if (userIdOne === userIdTwo) {
      alert("Something went wrong!")
      return;
    }    

    const chatId = await getFBChatId(userIdOne, userIdTwo)
    let fbChat = await getFBChat(chatId)

    if (!fbChat || fbChat === undefined) {
        dispatcher(setMessageTemplate({
            ...messageTemplate,
            isLoading: false,
            userIdOne: userIdOne,
            userIdTwo: userIdTwo,
            message: '',
            errorMsg: null
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
        }))
        return;
    }

    navigate(`/message/${fbChat.chatId}`)
  }

  useEffect(() => {
        if (messageTemplate?.message && userIdOne === messageTemplate?.userIdOne && userIdTwo === messageTemplate?.userIdTwo) {
            createFBChatAndRedirect()
        }
    }, [messageTemplate?.message])

  const createFBChatAndRedirect = async () => {
    const fbChat = await createFBIndividulaChat(messageTemplate?.userIdOne, messageTemplate?.userIdTwo)

    if (!fbChat || fbChat === undefined) {
        dispatcher(setMessageTemplate({
            ...messageTemplate,
            isLoading: false,
            errorMsg: "Chat not exist!",
        }))
        return;
    }

    await sendFBMessage({
        senderUserId: user?.user?.userId,
        text: messageTemplate?.message,
        chatId: fbChat.chatId
    })

    dispatcher(setClearMessageTemplate())
    dispatcher(setModal({
        ...modal,
        [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: false
    }))

    navigate(`/message/${fbChat.chatId}`)
  }

  const ProfileSection = () =>
    bookingDetail?.bookingDetail ? (
      <div className="px-2 py-2 rounded-lg shadow-sm">
        <div
          className={clsx(
            "flex justify-between items-center",
            "text-slate-500 mt-6 text-sm md:text-base"
          )}
        >
          <p className="font-bodyPri tracking-wide text-text-800">{"Booking ID :"} {bookingDetail?.bookingDetail?.bookingId}</p>
          <p className="font-bodyPri tracking-wide text-text-800">{"Price : USD"} {parseFloat('' + (bookingDetail?.bookingDetail?.purchasedPrice / 100)).toFixed(2)}</p>
        </div>
        <div className="flex flex-col items-center justify-between sm:flex-row">
          {/* Profile */}
          <div className="inline-flex flex-col items-center justify-center my-4 space-y-1 sm:flex-row">
            <img
              src={bookingDetail?.bookingDetail?.student?.profilePicUrl}
              alt=""
              className="object-cover rounded-full w-14 h-14"
            />
            <div className="pl-2">
              <p className="text-lg font-semibold font-bodyPri tracking-wide text-text-900">
                {bookingDetail?.bookingDetail?.student?.firstName + " " + bookingDetail?.bookingDetail?.student?.lastName.charAt(0)}
              </p>
              <p className="text-sm text-center text-opacity-40 md:text-left">
                {locals.userRole === userRoles.TUTOR.value ? 'Student': 'Tutor'}
              </p>
            </div>
          </div>
          {/* Actions */}
          <div className="inline-flex flex-end items-center space-x-2">
            <div>
              <BsChatLeft size={15} />
            </div>
            <div>
              <button onClick={() => handleSendMessage()} className="px-3 py-1 font-semibold bg-primary-light rounded-lg text-sky-500 font-bodyPri tracking-wide">
                {"Send Message"}
              </button>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  const CourseSection = () =>
    bookingDetail?.bookingDetail ? (
      <div className="p-2 shadow-sm">
        <span className="text-lg font-semibold font-bodyPri tracking-wide text-text-900 text-left text-clip">
          {bookingDetail?.bookingDetail?.course.courseTitle}
        </span>
        <div className="grid gap-3 pt-4 mt-2 text-sm md:grid-cols-2 opacity-70 place-items-start">
          <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"CATEGORY:"}</p>
            <p className="font-bold">{bookingDetail?.bookingDetail?.course.category}</p>
          </div>
          <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"LEVEL:"}</p>
            <p className="font-bold">{bookingDetail?.bookingDetail?.course?.proficiency}</p>
          </div>
          <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"CLASS TYPE:"}</p>
            <p className="font-bold capitalize">{courseType[bookingDetail?.bookingDetail?.course.courseType?.toUpperCase()]?.label}</p>
          </div>
          <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"SESSION:"}</p>
            <p className="font-bold">{bookingDetail?.bookingDetail?.noOfSessions}</p>
          </div>
          <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"LANGUAGE:"}</p>
            {/* <p className="font-bold">{bookingDetail?.bookingDetail?.displayLanguage}</p> */}
          </div>
          <div className="inline-flex space-x-2 whitespace-nowrap font-bodyPri tracking-wide text-text-900">
            <p>{"DURATION:"}</p>
            <p className="font-bold">{bookingDetail?.bookingDetail?.sessionDuration + " min."}</p>
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  const Schedule = () => {
    const [visible, setVisible] = useState(false);

    return (
      <>
        {bookingDetail?.bookingDetail && (
          <div id="schedule">
              <div className="w-full flex items-center justify-between cursor-pointer"
                onClick={() => setVisible(!visible)}
              >
                <div className="flex justify-between items-center space-x-5">
                <span className="text-lg font-bold font-bodyPri">{"Schedule"}</span>
                <span className="text-xs text-text-700 font-bodyPri font-normal tracking-wide">
                {`Your local timezone:  ${timeZone} ${getTimeZoneOffset(timeZone)}`}
                </span>
              </div>
                <span>
                  {visible ? <FaChevronUp size={20}  /> : <FaChevronDown size={20} />}
                </span>
            </div>
            {visible && (
              <div className="w-full pt-4 space-y-3 divide-y sm:divide-y-0">
                {bookingDetail?.bookingDetail?.events.map((event) => (
                  <div className="flex items-center justify-between">
                    <span className="text-xs md:text-base sm:flex-row sm:w-9/12">{dayjs(event?.startDateTime).format('ddd, MMM DD, YYYY')}</span>
                    <div className="w-full space-x-1">
                      <span className="text-slate-600">{dayjs(event?.startDateTime).format('hh:mm A')}</span>
                      <span>-</span>
                      <span className="text-slate-500 sm:text-slate-600">
                        {dayjs(event?.endDateTime).format('hh:mm A')}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  
  return (
    <div className="px-4 py-3 sm:px-10 max-w-2xl md:max-w-xl lg:max-w-lg">
      <ComponentLoader isLoading={bookingDetail?.isLoading} />
      {bookingDetail?.bookingDetail && (
        <>
          <ProfileSection />
          <CourseSection />
          <div className="mt-4 bg-gray-500 h-0.5 w-11/12 mx-auto opacity-40 mb-3" />
          <Schedule />
          <div className="py-2">
            <div className="mt-4 bg-gray-500 h-0.5 w-11/12 mx-auto opacity-40" />
            <div className="mt-4">
              <LogTimeLine logList={createBookingLogs(bookingDetail?.bookingDetail?.bookingLogs)}/>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BookingSideOverContent;
