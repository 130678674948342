import clsx from "clsx";
import { useState } from "react";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps } from "redux/offering/offering.slice";
import { filterName } from "redux/offering/offering.const";
import { userRoles } from "redux/auth/auth.const";

export const OfferingSearch = () => {
    const { offeringList, filterProps } = useAppState((s) => s.offering)
    const { locals } = useAppState((s) => s.local)
    const [searchText, setSearchText] = useState("");
    const dispatcher = useAppDispatcher();

    return (
        <div className="flex justify-center p-4 bg-white rounded-lg">
            <div className="inline-flex mx-auto shadow-sm">
                <input
                    type="text"
                    className={clsx(
                        "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
                        "border-sky-200 w-full sm:w-80"
                    )}
                    placeholder={locals.userRole === userRoles.TUTOR.value && "Search Class name..."}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
                <div>
                    <button
                        className="px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white"
                        onClick={() => dispatcher(setFilterProps({
                            filterProps: { ...filterProps, searchText: searchText },
                            list: offeringList?.offeringList?.results,
                            filterName: filterName.OFFERING.value
                        }))}
                    >
                        {"Search"}
                    </button>
                </div>
            </div>
        </div>
    );
};
