import React from 'react'

const OfferingCourseLoader = ({ isLoading = false }) => {
    return isLoading ? (
        <div className='animate-pulse shadow-[0_0_10px_1px_rgba(0,0,0,0.3)] p-5 bg-white space-y-3'>
            <div className='flex flex-row items-center justify-between gap-5'>
                <div className='bg-slate-200 py-4 w-32'></div>
                <div className='bg-slate-200 py-4 w-[36rem]'></div>
                <div className='flex items-center gap-5'>
                    <div className='bg-slate-200 w-5 h-5 rounded-full'></div>
                    <div className='bg-slate-200 w-2 h-8 rounded-full'></div>
                </div>
            </div>
            <div className="bg-slate-200 h-0.5"></div>
            <div className='grid grid-cols-12'>
                <div className='col-span-12 md:col-span-8'>
                    <div className='px-8'>
                        <div className='bg-slate-200 w-96 h-48 flex items-center mb-4'>
                        </div>
                    </div>
                </div>
                <div className='px-3 col-span-12 md:col-span-4 overflow-hidden space-y-3'>
                    <div className='bg-slate-200 w-96 h-48 flex justify-center pl-8 md:pl-0 flex-col items-center'></div>
                    <div className='flex justify-between'>
                        <div className='flex justify-end w-28 h-8 bg-slate-200 pr-1'></div>
                        <div className='flex justify-end w-28 h-8 bg-slate-200 pr-1'></div>
                    </div>
                </div>
            </div>
        </div>
    ) : null
}

export default OfferingCourseLoader