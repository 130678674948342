import { useState } from 'react';
import {
    Container,
    Content,
    Header,
    Heading,
    ItemContainer,
    Brands,
    BrandItem,
} from './BrandFeaturedStyle';
import { brandData } from './BrandFeaturedData';

const BrandFeatured = () => {
    const [hoverItemIndex, setHoverItemIndex] = useState(null);

    const scaleVarient = {
        scaleOut: {
            scale: 1,
        },
        scaleIn: {
            scale: 1.2,
        }
    }

    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'As featured in..'}</Heading>
                </Header>
                <ItemContainer>
                    <Brands>
                        {brandData.map((brandItem, index) => (
                            <BrandItem key={index}
                                onMouseOver={() => setHoverItemIndex(index)}
                                onMouseOut={() => setHoverItemIndex(null)}
                                variants={scaleVarient}
                                initial={'scaleOut'}
                                animate={hoverItemIndex === index ? 'scaleIn' : 'scaleOut'}
                            >
                                <img src={brandItem.imageUrl} alt={'brand'} />
                            </BrandItem>
                        ))}
                    </Brands>
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default BrandFeatured
