import {
    BlogItemContainer,
    ImageContainer,
    TextContainer,
    Title,
    Description,
    RedirectPath,
} from './BlogsStyle'

const BlogItem = ({ blogItem }) => {
    return (
        <BlogItemContainer>
            <ImageContainer imageUrl={blogItem.imageUrl} />
            <TextContainer>
                <Title>{blogItem.title}</Title>
                <Description>{blogItem.description.substring(0, 100)}...</Description>
                <RedirectPath href={blogItem.redirectPath} target={'_blank'}>{'Read more'}</RedirectPath>
            </TextContainer>
        </BlogItemContainer>
    )
}

export default BlogItem
