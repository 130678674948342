import { useState } from "react";
import clsx from "clsx";

import { Screen, DivText } from "pages/auth/offersAndDiscount/styles/Style"

import { pageHeading } from 'pages/auth/offersAndDiscount/offersAndDiscount.data';

import EdOffers from "pages/auth/offersAndDiscount/components/EdOffers";
import PartOffers from "pages/auth/offersAndDiscount/components/PartOffers";

import PageHeader from 'components/pageHeader/PageHeader';


function Offers() {
    const [value, setValue] = useState("Edulyte Offers");

    return (
        <Screen>
            <PageHeader pageHeading={pageHeading} />
            <div className="p-4 bg-white rounded-lg">
                <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
                    <div className="rounded-md ring-2 ring-primary-main divide-x-2 divide-primary-main overflow-hidden ">
                        <button
                            className={clsx(
                                "w-32 font-buttons font-normal text-base",
                                value === "Edulyte Offers"
                                    ? "bg-secondary-main text-white"
                                    : "text-primary-main hover:bg-primary-light"
                            )}
                            onClick={() => {
                                setValue("Edulyte Offers")
                            }}
                        >
                            <DivText isActive={value === "Edulyte Offers"}> Edulyte Offers
                            </DivText>
                        </button>
                        <button
                            className={clsx(
                                "w-32 font-buttons font-normal text-base",
                                value === "Partner Offers"
                                    ? "bg-primary-main text-white"
                                    : "text-primary-main hover:bg-primary-light"
                            )}
                            onClick={() => {
                                setValue("Partner Offers")
                            }}
                        ><DivText isActive={value === "Partner Offers"}>
                                Partner Offers
                            </DivText>
                        </button>
                    </div>
                </div>
            </div>
            {value === "Edulyte Offers" && <EdOffers />}
            {value === "Partner Offers" && <PartOffers />}


        </Screen>
    );
}
export default Offers;
