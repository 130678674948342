import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Pagination from 'components/pagination/Pagination';
import CourseCard from 'components/courseCard/CourseCard';
import CourseCardLoader from "components/loader/CourseCardLoader";

import {
    getMyCourseUserListByUserId,
    deleteFavCourseDetailByUserId,
    createFavCourseUserDetailByUserId
} from "redux/course/course.request";

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setClearMyCourseList } from 'redux/course/course.slice';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 6


const AllCourses = () => {
    const { user } = useAppState((s) => s.user)
    const { myCourseList } = useAppState((s) => s.course)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const query = generateQueryParams(location.search)

        dispatcher(getMyCourseUserListByUserId(user?.user?.userId, { page: query.pn || 1, records: RECORDS }))

        return () => {
            dispatcher(setClearMyCourseList())
        }
    }, [location.search])

    return (
        <div className='flex justify-center items-center'>
            <div className='max-w-sm md:max-w-3xl lg:max-w-full w-full space-y-5'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-8 lg:gap-5'>
                    {myCourseList?.filteredCourseList?.results?.map((course, index) => (
                        <div
                            key={index}
                            className='col-span-1 flex justify-center'
                        >
                            <CourseCard
                                course={course}
                                isFav={user?.user?.favCourses?.filter((favCourse) =>
                                    favCourse.courseId === course.courseId).length > 0 ? true : false}
                                handleFav={(courseId) => {
                                    const favoriteCourses = user?.user?.favCourses?.filter((favCourse) =>
                                        favCourse.courseId === courseId)
                                    if (favoriteCourses?.length > 0) {
                                        dispatcher(deleteFavCourseDetailByUserId(user?.user?.userId, {courseId:courseId}))
                                    }
                                    else {
                                        dispatcher(createFavCourseUserDetailByUserId(user?.user?.userId, {courseId:courseId}))
                                    }
                                }}
                            />
                        </div>
                    ))}
                    {new Array(RECORDS).fill("").map((_, index) => (<CourseCardLoader key={index} isLoading={myCourseList?.isLoading} />))}
                </div>
                {myCourseList?.filteredCourseList && myCourseList?.filteredCourseList.records > 0 && (
                    <div className="w-full flex justify-center items-center">
                        <Pagination
                            page={myCourseList?.filteredCourseList?.page}
                            totalPages={myCourseList?.filteredCourseList?.totalPages}
                            onChangePage={(page) => navigate(pagesInfo.MY_COURSES.pagePath + "?pn=" + page)}
                        />
                    </div>
                )}
                {(myCourseList?.filteredCourseList?.records === 0 || myCourseList?.errorMsg) &&
                    <span className='w-full h-full col-span-10 text-center text-base font-bodyPri tracking-wide pt-5'>
                        {"No Course Found!"}
                    </span>
                }
            </div>
        </div>
    )
}

export default AllCourses;