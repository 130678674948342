import tw from "twin.macro";
import styled from "@emotion/styled";

export const Screen = tw.div`
 bg-gray-100 min-h-screen px-3 space-y-3
 `;

export const Header = tw.div`
 flex justify-between p-5 bg-white shadow rounded-lg mb-3
 `;

export const Main = tw.div`
 flex items-center justify-center gap-5
 `;

export const PrimaryButton = styled.div`
 ${tw`cursor-pointer border-0 border-b-2 border-blue-600 border-solid font-semibold`}
 ${({ isActive }: { isActive: boolean }) => isActive && tw`border-pink-600`}
 `;

export const DivText = styled.div`
 ${tw`w-full flex flex-col items-center justify-center text-primary-main py-2 px-2 bg-blue-100`}
 ${({ isActive }: { isActive: boolean }) => isActive && tw`bg-primary-main text-white`}
 `;



