import { Dayjs } from "dayjs";

export type GiftCardPlan = {
  id: string;
  name: string;
  price: number;
};

export type GiftCardRecipient = {
  type: "yourself" | "someone_else";
  quantity: 1; // later useful for extending the quantity
  name: string;
  recipientName: string;
  recipientEmail: string;
  message: string;
  isPrint: boolean;

  deliveryType: "Instantly" | "Custom Date";
  selectedDate: string;
  selectedTime: {
    label: string;
    value: string;
  };
};

export const AllSteps = ["step1", "step2", "step3", "step4"] as const;
export type StepValue = {
  [key in GiftCardStep]: number;
};
export const StepValues: StepValue = {
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
};

export type GiftCardStep = typeof AllSteps[number];

export type TGiftCard = {
  activeStep: GiftCardStep;
  selectedImageIdx: number;
  cardSelection: {
    isCompleted: boolean;
    selectedCard: GiftCardPlan | null;
  };
  recipentSelection: {
    isCompleted: boolean;
    selectedRecipent: GiftCardRecipient;
  };
  orderSummary: {
    isCompleted: boolean;
    paymentEmail: string;
    paymentPhone: string;
  };
  paymentSummary: {
    isPaymentSuccess: boolean;
    amountPaid?: number;
    errorMessage?: string;
    isCompleted: boolean;
    paymentId: string | null;
    orderId: string | null;
    otherdetails: string;
  };
  _meta: {
    isLoading: boolean;
    showPaymentModal: boolean;
  };
};
