import { AiOutlineStar } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";
import { IoIosFlash } from "react-icons/io";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { GiSandsOfTime } from "react-icons/gi";
import { FiVideo } from "react-icons/fi";

import { userRoles } from 'redux/auth/auth.const';


export const heroCard = {
    title: "Welcome back, {0}",
    studentText: "You completed {0} session! Your current learning score is {1}",
    tutorText: "You completed {0} session! Your current teaching score is {1}",
}

export const dashboardStats = {
    HOURS_TAUGHT: {
        value: 'HOURS_TAUGHT',
        label: "Hours Taught",
        data: 0,
        icon: <GiSandsOfTime className="text-3xl text-secondary-main" />,
        unit: "Hour",
        roles: [userRoles.TUTOR.value]
    },
    LEARNERS: {
        value: 'LEARNERS',
        label: "Learners",
        data: 0,
        icon: <BsPerson className="text-3xl text-secondary-main" />,
        unit: "Student",
        roles: [userRoles.TUTOR.value]
    },
    HOURS_LEARN: {
      value: 'HOURS_LEARN',
      label: "Hours Learnt",
      data: 0,
      icon: <GiSandsOfTime className="text-3xl text-secondary-main" />,
      unit: "Hour",
      roles: [userRoles.STUDENT.value]
  },
    SESSION_COMPLETED: {
        value: 'SESSION_COMPLETED',
        label: "Sessions Completed",
        data: 0,
        icon: <FiVideo className="text-3xl text-secondary-main" />,
        unit: "Session",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value]
    },
    MY_BALANCE: {
        value: 'MY_BALANCE',
        label: "My Balance",
        data: 0,
        icon: <MdOutlineAccountBalanceWallet className="text-3xl text-secondary-main" />,
        unit: "USD",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value]
    },
    REVIEWS_EARNED: {
        value: 'REVIEWS_EARNED',
        label: "Reviews Earned",
        data: 0,
        icon: <AiOutlineStar className="text-3xl text-secondary-main" />,
        unit: "",
        roles: [userRoles.TUTOR.value]
    },
    BOOKINGS: {
        value: 'BOOKINGS',
        label: "Bookings",
        data: 0,
        icon: <IoIosFlash className="text-3xl text-secondary-main" />,
        unit: "Confirmed",
        roles: [userRoles.TUTOR.value, userRoles.STUDENT.value]
    },
}

export const listOfTimeZones = [
    {
        name: "Sydney",
        zone: "Australia/Sydney",
    },
    {
        name: "Adelaide",
        zone: "Australia/Adelaide",
    },
    {
        name: "Mumbai",
        zone: "Asia/Kolkata",
    },
    {
        name: "New York",
        zone: "America/New_York",
    },
    {
        name: "Singapore",
        zone: "Asia/Singapore",
    },
    {
        name: "Beijing",
        zone: "Asia/Shanghai",
    },
];

export const doSomethingActionList = [
    {
      name: "Offer 1 to 1 lesson",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/offer-one-to-one.svg",
      href: "/create-class",
      roles: [userRoles.TUTOR.value]
    },
    {
      name: "Offer a group lesson",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/offer-group.svg",
      href: "/create-class",
      roles: [userRoles.TUTOR.value]
    },
    {
      name: "Update availability",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/update-availability.svg",
      href: "/preferences/availability",
      roles: [userRoles.TUTOR.value]
    },
    {
      name: "Withdraw money",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/withdraw-money.svg",
      href: "/wallet",
      roles: [userRoles.TUTOR.value]
    },
    {
      name: "Share your profile",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/share-your-profile.svg",
      href: "/dashboard",
      roles: [userRoles.TUTOR.value]
    },
    // {
    //   name: "Get certified badge",
    //   image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/add-money.svg",
    //   href: "/get-certified-badge",
    //   roles: [userRoles.TUTOR.value]
    // },
    {
      name: "Find a Tutor",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/find-a-tutor.svg",
      href: "/find-tutors",
      roles: [userRoles.STUDENT.value]
    },
    {
      name: "Find a Live Class",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/find-a-live-course.svg",
      href: "/find-classes",
      roles: [userRoles.STUDENT.value]
    },
    {
      name: "Refer a Friend",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/refer-a-friend.svg",
      href: "/referral",
      roles: [userRoles.STUDENT.value]
    },
    {
      name: "Add Money",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/add-money.svg",
      href: "/wallet",
      roles: [userRoles.STUDENT.value]
    },
    // {
    //   name: "Buy a Gift Card",
    //   image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/add-money.svg",
    //   href: "/share-your-profile",
    //   roles: [userRoles.STUDENT.value]
    // },
    {
      name: "Apply to teach",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/apply-to-teach.svg",
      href: "/teach-with-us",
      roles: [userRoles.STUDENT.value]
    },
    {
      name: "Update Profile",
      image: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/do-something/update-profile.svg",
      href: "/profile",
      roles: [userRoles.STUDENT.value]
    },
  ];