import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { FiEdit } from "react-icons/fi"
import { GiCancel } from "react-icons/gi"
import { FaPlusCircle } from "react-icons/fa";

import { IconWithTextButton } from "components/common-components/Buttons";

import { onBoardingStatus, fileCategory, tHeader, resumeCategory } from "pages/auth/tutorOnboard/TutorOnboardData"

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import {
    setAddDocumentPayload,
    setDocumentList,
    setClearAddDocument,
    setDocumentCategoryList,
    setClearDocumentCategoryList
} from "redux/tutorOnboarding/tutorOnboarding.slice"
import { modalConst } from 'redux/local/local.const';
import { setModal } from 'redux/local/local.slice';


const DocumentsTable = ({ task }) => {
    const { modal } = useAppState((s) => s.local)
    const { documentList, addDocument } = useAppState((s) => s.tutorOnboarding)
    const [openDocumentTable, setOpenDocumentTable] = useState(false)
    const dispatcher = useAppDispatcher()

    const filteredDocumentList = documentList?.documentList?.filter((documentList) => documentList?.fileCategory?.value !== resumeCategory.RESUME.value)

    useEffect(() => {
        if (task.status === onBoardingStatus.NOT_SUBMITTED.value) {
            setOpenDocumentTable(false)
        }
        if (task.status === onBoardingStatus.SUBMITTED.value) {
            setOpenDocumentTable(true)
        }
    }, [])

    const handleAddDocument = () => {
        dispatcher(setClearDocumentCategoryList())
        dispatcher(setDocumentCategoryList({
            categoryListItems: fileCategory,
            resumeTask: false
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
        }))
    }

    const handleEditEntry = (documentId) => {
        const filteredDocument = filteredDocumentList?.find((document) => document?.documentId === documentId)
        dispatcher(setClearAddDocument())
        dispatcher(setAddDocumentPayload({
            ...filteredDocument,
            isDocumentSubmitted: false,
            documentStatus: onBoardingStatus.NOT_SUBMITTED.value
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]: true
        }))
    }

    const handleDeleteEntry = (documentId) => {
        const filteredList = filteredDocumentList?.filter((items, idx) => items.documentId !== documentId)

        if (filteredList?.length > 0) {
            dispatcher(setDocumentList(filteredList))
        }
        if (filteredList?.length <= 0) {
            task.status = onBoardingStatus.NOT_SUBMITTED.value
            dispatcher(setDocumentList(filteredList))
        }
    }

    return (
        <div className='space-y-3'>
            {(openDocumentTable && filteredDocumentList?.length > 0) &&
                <div className='flex flex-col'>
                    <span className='w-full flex justify-end'>
                        <IconWithTextButton
                            placeholder="Add New"
                            icon={<FaPlusCircle />}
                            onClick={handleAddDocument}
                        />
                    </span>
                    <table className="table-fixed">
                        <thead>
                            <tr>
                                {tHeader.map((head, index) => (
                                    <th
                                        key={index}
                                        className={clsx(
                                            "px-5 py-2 text-base font-medium font-bodyPri tracking-wider text-left text-text-600",
                                            "font-bold whitespace-nowrap border border-gray-200"
                                        )}
                                    >
                                        {head}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-text-200">
                            {filteredDocumentList?.map((data, index) => (
                                <tr className={''} key={index}>
                                    <td className="border border-gray-200">
                                        <span className='px-5 py-2 font-bodyPri font-medium text-text-900 text-base text-center truncate'>
                                            {data?.fileCategory?.label}
                                        </span>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <a
                                            href={data?.fileUrl}
                                            target={"_blank"}
                                            className='px-5 py-2 font-bodyPri font-medium text-primary-main text-base text-center underline truncate'>
                                            {data?.fileName}
                                        </a>
                                    </td>
                                    <td className={"border border-gray-200"}>
                                        <span className='px-2 font-bodyPri font-normal text-text-900 text-base italic text-center'>
                                            {data?.documentStatus}
                                        </span>
                                    </td>
                                    <td className={"px-5 py-2 border border-gray-200"}>
                                        <FiEdit
                                            className={"text-xl text-primary-main cursor-pointer hover:text-primary-dark"}
                                            onClick={() => {
                                                handleEditEntry(data?.documentId)
                                            }}
                                        />
                                    </td>
                                    <td className={"px-5 py-2 border border-gray-200"}>
                                        <GiCancel
                                            className={"text-xl text-text-500 hover:text-text-600 cursor-pointer"}
                                            onClick={() => handleDeleteEntry(data?.documentId)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default DocumentsTable