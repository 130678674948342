import * as React from "react";
import clsx from "clsx";

import { studentTabs } from "pages/auth/myStudents/data";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps } from "redux/student/student.slice";
import { filterName } from "redux/student/student.const"
import { userRoles } from "redux/auth/auth.const";


export const MyStudentSearch: React.FC = ({ myActiveTab }: any) => {
  const { myStudentList, favStudentList, filterProps } = useAppState((s) => s.student)
  const { locals } = useAppState((s: any) => s.local)
  const [searchText, setSearchText] = React.useState("");
  const dispatcher = useAppDispatcher();

  const handleFilter = () => {
    if (myActiveTab === studentTabs.ALL_STUDENT.value) {
      dispatcher(setFilterProps({
        filterProps: { ...filterProps, searchText: searchText },
        list: myStudentList?.myStudentList,
        filterName: filterName.ALL_STUDENTS.value
      }))
    }
    if (myActiveTab === studentTabs.FAV_STUDENT.value) {
      dispatcher(setFilterProps({
        filterProps: { ...filterProps, searchText: searchText },
        list: favStudentList?.filteredFavStudentList,
        filterName: filterName.FAV_STUDENTS.value
      }))
    }
  }

  return (
    <div className="flex justify-center p-4 bg-white rounded-lg">
      <div className="inline-flex mx-auto shadow-sm">
        <input
          type="text"
          className={clsx(
            "flex-1 px-3 py-1 border outline-none focus:border-primary-light rounded-l-md",
            "border-sky-200 w-full sm:w-80"
          )}
          placeholder={locals.userRole === userRoles.TUTOR.value ?"Student name..." : "Teacher name..."}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div>
          <button
            className="px-3 py-2 rounded-r-md bg-primary-light text-primary-main hover:bg-primary-main hover:text-white"
            onClick={handleFilter}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
