import { useState, useEffect } from 'react';

import EventActionTitle from 'components/modals/eventActionModal/EventActionTitle';
import EventActionInput from 'components/modals/eventActionModal/EventActionInput';
import EventActionFooter from 'components/modals/eventActionModal/EventActionFooter';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { eventStatus } from 'redux/event/event.const';
import { modalConst } from 'redux/local/local.const';


const EventStatusActionModal = () => {
    const { eventDetail, updateEvent } = useAppState((s) => s.event)
    const { updateBooking } = useAppState((s) => s.booking)
    const { modal } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const [selectedAction, setSelectedAction] = useState(eventStatus[eventDetail?.eventDetail?.status.toUpperCase()])

    useEffect(() => {
        if (updateEvent?.updateEvent || updateBooking?.updateBooking) {
            dispatcher(setModal({
                ...modal,
                [modalConst.EVENT_ACTION_MODAL.stateKey]: false
            }))
        }
    }, [updateEvent?.updateEvent, updateBooking?.updateBooking])

    return (
        <div className={"space-y-5 overflow-x-hidden select-none"}>

            <div className={"min-h-[24rem] block space-y-5 pt-2"}>

                <EventActionTitle />

                <EventActionInput
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                />

            </div>
            <EventActionFooter
                selectedAction={selectedAction}
            />
        </div>
    )
}

export default EventStatusActionModal;