import meetingService from 'redux/meeting/meeting.service';
import {
    setMeetingLoading,
    setMeeting,
    setMeetingErrorMsg,
} from 'redux/meeting/meeting.slice'


export const getMeetingByMeetingId = (meetingId) => async (dispatch) => {
    dispatch(setMeetingLoading(true));

    try {
        const requestData = {
            params: { meetingId: meetingId }
        }
        const response = await meetingService.getMeetingByMeetingId(requestData)
        if (response.status === 200) {
            dispatch(setMeeting(response.data))
        } else {
            dispatch(setMeetingErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setMeetingErrorMsg(error.message))
    } finally {
        dispatch(setMeetingLoading(false));
    }
}