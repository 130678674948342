import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from 'clsx';

import { OptionSelector } from 'components/common-components/Select';

import { onBoardingStatus, monthsOptions } from "pages/auth/tutorOnboard/TutorOnboardData";

import { getMasterCountryList } from 'redux/master/master.request';
import { updateReference } from "redux/tutorOnboarding/tutorOnboarding.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setAddReferencePayload } from "redux/tutorOnboarding/tutorOnboarding.slice";

import { validateEmail, validateMobileNo } from 'utils/validation-functions';

const pattern = /[a-zA-z]{1}/;

const ReferenceFormModal = () => {
    const { countryList } = useAppState(s => s.master)
    const { addReference } = useAppState((s) => s.tutorOnboarding)
    const dispatcher = useAppDispatcher()

    const [emailErrorText, setEmailErrorText] = useState('')
    const [mobileErrorText, setmobileErrorText] = useState('')

    useEffect(() => {
        dispatcher(getMasterCountryList())
    }, [])

    useEffect(() => {
        if (addReference?.addReferencePayload?.referenceEmail && !validateEmail(addReference?.addReferencePayload?.referenceEmail)) setEmailErrorText('Enter valid Email!');
        else setEmailErrorText('');

        if (addReference?.addReferencePayload?.referenceMobile && !validateMobileNo(addReference?.addReferencePayload?.referenceMobile)) setmobileErrorText('Enter valid MobileNo!');
        else setmobileErrorText('');
    }, [addReference?.addReferencePayload?.referenceMobile, addReference?.addReferencePayload?.referenceEmail])

    const handleReferenceForm = (event) => {
        event.preventDefault();

        if (!addReference?.addReferencePayload?.referenceId) {
            dispatcher(setAddReferencePayload({
                ...addReference?.addReferencePayload,
                referenceId: Math.floor((Math.random() * 10) + 1),
                referenceStatus: onBoardingStatus.SUBMITTED.value,
                isFormSubmitted: true,
            }))
            alert("Reference details uploaded successfully")
        }
        if (addReference?.addReferencePayload?.referenceId) {
            dispatcher(updateReference())
            alert("Reference details updated successfully")
        }
    }

    return (
        <form onSubmit={handleReferenceForm} className="space-y-5">
            <div className={"min-h-[16rem] block w-full border border-text-400 space-y-3"}>
                <div className='w-full px-2 py-1 bg-text-300 font-bodyPri font-medium text-md text-text-900 tracking-wide'>
                    {"Professional Reference Details (Not a family member or a friend)"}
                </div>
                {/* <span className="px-2 font-bodyPri font-normal text-xs">{"(* is mandatory)"}</span> */}
                <div className="grid grid-cols-2 gap-5 px-2">
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Reference Name "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. John Doe"}
                            value={addReference?.addReferencePayload?.referenceName}
                            className={clsx(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )} onChange={(e) => dispatcher(setAddReferencePayload({
                                ...addReference?.addReferencePayload,
                                referenceName: (e.target.value).charAt(0).toUpperCase() + (e.target.value).slice(1)
                            }))}
                            required
                        />
                    </div>
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Relationship "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            required
                            placeholder={"e.g. manager, professor, supervisor"}
                            value={addReference?.addReferencePayload?.referenceRelationship}
                            className={clsx(
                                "w-full px-3 py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setAddReferencePayload({
                                ...addReference?.addReferencePayload,
                                referenceRelationship: (e.target.value).charAt(0).toUpperCase() + (e.target.value).slice(1)
                            }))}
                        />
                    </div>
                </div>
                <div className="">
                    <span className='w-full px-2 font-bodyPri font-normal text-text-900 text-sm'>
                        {"How many years have you know them?"}
                    </span>
                    <div className="grid grid-cols-2 gap-5 px-2">
                        <div className=''>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Years "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <input
                                type={"number"}
                                required
                                value={addReference?.addReferencePayload?.knownYears}
                                placeholder={"e.g.1, 2, 3"}
                                className={clsx(
                                    "w-full px-3  py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => dispatcher(setAddReferencePayload({
                                    ...addReference?.addReferencePayload,
                                    knownYears: e.target.value
                                }))}
                            />
                        </div>
                        <div className=''>
                            <span className='font-bodyPri font-normal text-text-900 text-sm'>
                                {"Months "}
                                <span className="text-red-500 text-lg">{"*"}
                                </span>
                            </span>
                            <OptionSelector
                                options={Object.values(monthsOptions)}
                                placeholder={"Please pick a type"}
                                value={addReference?.addReferencePayload?.knownMonths}
                                onChange={(option) => dispatcher(setAddReferencePayload({
                                    ...addReference?.addReferencePayload,
                                    knownMonths: option.value
                                }))}
                            />
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-5 px-2 pt-1">
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Mobile No. "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <div className={clsx(
                            "border-2 border-divider-medium bg-white rounded-md px-2 py-1 focus:outline-none flex items-center gap-3",
                            "text-text-900 text-base font-bodyPri w-full"
                        )}>
                            {!pattern.test(addReference?.addReferencePayload?.referenceMobile) &&
                                <select className='focus:outline-none'>
                                    {countryList?.countryList?.map((item) => (
                                        <option className='w-auto h-auto'>
                                            {"+" + item.isdCode}
                                        </option>
                                    ))}
                                </select>
                            }
                            <input
                                type={"number"}
                                required
                                placeholder={"e.g. 0123456789"}
                                value={addReference?.addReferencePayload?.referenceMobile}
                                className={clsx(
                                    "w-full input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-base",
                                    "placeholder:text-text-500 placeholder:text-sm"
                                )}
                                onChange={(e) => dispatcher(setAddReferencePayload({
                                    ...addReference?.addReferencePayload,
                                    referenceMobile: e.target.value
                                }))}
                            />
                        </div>
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {mobileErrorText}
                        </span>
                    </div>
                    <div className=''>
                        <span className='font-bodyPri font-normal text-text-900 text-sm'>
                            {"Email "}
                            <span className="text-red-500 text-lg">{"*"}
                            </span>
                        </span>
                        <input
                            type={"text"}
                            required
                            value={addReference?.addReferencePayload?.referenceEmail}
                            placeholder={"e.g. johndoe@example.com"}
                            className={clsx(
                                "w-full px-3  py-1 input-number-spin-none border-2 border-divider-medium rounded-md focus:outline-none",
                                "font-bodyPri font-normal text-text-800 text-base",
                                "placeholder:text-text-500 placeholder:text-sm"
                            )}
                            onChange={(e) => dispatcher(setAddReferencePayload({
                                ...addReference?.addReferencePayload,
                                referenceEmail: e.target.value
                            }))}
                        />
                        <span className="font-bodyPri font-normal text-xs text-red-500">
                            {emailErrorText}
                        </span>
                    </div>
                </div>
                <div className='px-2 pb-2  space-y-2'>
                    <div className="p-5 space-y-3 rounded-md bg-primary-light">
                        <div className="flex items-start justify-start gap-1">
                            <input
                                type={"checkbox"}
                                checked={addReference?.addReferencePayload?.isEmailSent}
                                className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                onChange={(e) => dispatcher(setAddReferencePayload({
                                    ...addReference?.addReferencePayload,
                                    isEmailSent: e.target.checked
                                }))}
                            />
                            <span className='font-bodyPri font-medium text-text-900 text-xs text-start w-full'>
                                {"Send email to my references."}
                            </span>
                        </div>
                        <div className="flex items-start justify-start gap-1">
                            <input
                                type={"checkbox"}
                                className={"border border-divider-medium rounded-md focus:outline-none font-bodyPri font-normal text-text-800 text-base"}
                                checked={addReference?.addReferencePayload?.isPrivacyEnabled}
                                onChange={(e) => {
                                    console.log(e.target.checked)
                                    dispatcher(setAddReferencePayload({
                                        ...addReference?.addReferencePayload,
                                        isPrivacyEnabled: e.target.checked
                                    }))
                                }}
                            />
                            <span className='font-bodyPri font-medium text-text-900 text-xs text-start w-full'>
                                {"Click here to give authority to my Referees to all relevent information relating to my application. I acknowledge the reference answers are confidential between the company and the refree. "}
                                <Link to={"/"} className={"text-primary-main"}>
                                    {"Click here to view the terms."}
                                </Link>
                            </span>
                        </div>
                    </div>
                    <div className="flex justify-center items-center">
                        <span className="font-bodyPri font-normal text-text-900 text-xs tracking-wide">
                            {"Please Send your reference once you have entered all the correct information"}
                        </span>
                    </div>
                </div>
            </div>
            <div className={"flex justify-end"}>
                <button
                    type={"submit"}
                    className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    )}>
                    {"Submit"}
                </button>
            </div>
        </form >
    )
}

export default ReferenceFormModal;
