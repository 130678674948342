import { useState } from 'react';
import {
    SocialIconContainer,
    IconContainer,
} from './FooterStyle';
import { socialIconsData } from './FooterData';

const SocialIcons = () => {
    const [hoverItemIndex, setHoverItemIndex] = useState(null)

    const scaleVarient = {
        scaleOut: {
            scale: 1,
        },
        scaleIn: {
            scale: 1.5,
        }
    }

    return (
        <SocialIconContainer>
            {socialIconsData.map((iconItem, index) => (
                <IconContainer key={index}
                    href={iconItem.path}
                    target={'_blank'}
                    onMouseOver={() => setHoverItemIndex(index)}
                    onMouseOut={() => setHoverItemIndex(null)}
                    variants={scaleVarient}
                    initial={'scaleOut'}
                    animate={hoverItemIndex === index ? 'scaleIn' : 'scaleOut'}
                >
                    <iconItem.icon />
                </IconContainer>
            ))}
        </SocialIconContainer>
    )
}

export default SocialIcons
