import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/createCourse/data';

import PageHeader from 'components/pageHeader/PageHeader';
import ProgressIndicator from 'pages/auth/createCourse/ProgressIndicator';
import SelectTemplate from 'pages/auth/createCourse/SelectTemplate';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { getTempCourseList } from 'redux/template/template.request';
import { setClearTempCourseList } from 'redux/template/template.slice';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import FullPageLoader from 'components/loader/FullPageLoader';

import { pagesInfo } from 'utils/pagesInfo';

const CreateCoursePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    let { tempCourseList } = useAppState((s) => s.template)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.CREATE_COURSE))
    }, [dispatcher, currentPageInfo])

    useEffect(() => {
        dispatcher(getTempCourseList())

        return () => {
            dispatcher(setClearTempCourseList())
        }
    }, [])

    if (tempCourseList?.isLoading) {
        return (<FullPageLoader isLoading={tempCourseList?.isLoading} />)
    }

    return (
        <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
            <PageHeader pageHeading={pageHeading} />
            <ProgressIndicator />
            {tempCourseList?.tempCourseList &&
                <div className="grid grid-cols-12">
                    <div className="col-span-full">
                        <SelectTemplate />
                    </div>
                </div>
            }
            {tempCourseList?.errorMsg &&
                <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                    {tempCourseList?.errorMsg}
                </div>
            }
        </div>
    )
}

export default CreateCoursePage
