import MUITabs from "pages/auth/editCompany/components/tabs/MUITabs";

const Tabs = (props) => {

  return (
    <div className={"flex items-center justify-center sticky top-[4rem] px-5 md:px-0"}>
      <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
        <div className={"grid grid-cols-12 space-y-3 bg-white sticky top-[20rem]"}>
          <div className={"col-span-12 md:col-span-7 xl:col-span-8"}>
            <div className={"h-full"}>
              <MUITabs tabs={props?.tabsList?.tabs} containerIDs={props.containerIDs} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
