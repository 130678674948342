import { useState, useEffect } from "react";
import clsx from "clsx";

import { FaSpinner } from "react-icons/fa";
import IsdCodeSelector from 'components/optionSelector/IsdCodeSelector';

import { getMasterCountryList, getTimeZoneMasterCountryDetailByTimeZone } from 'redux/master/master.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCountryList, setClearTimeZoneCountryDetail } from 'redux/master/master.slice';
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

import { timeZone } from "utils/dateTime.utils";
import { validateEmail, validateMobileNo } from 'utils/validation-functions';

const SpeakToUsModal = () => {
    const { countryList, timeZoneCountryDetail } = useAppState(s => s.master)
    const { modal } = useAppState((state) => state.local)

    const dispatcher = useAppDispatcher()

    const [emailAddressError, setEmailAddressError] = useState("")
    const [mobileNoError, setMobileNoError] = useState("")
    const [myCountry, setMyCountry] = useState({
        masterCountryId: 0,
        country: "",
        countryDomain: "",
        isdCode: ""
    })
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        mobileNo: ""
    })

    useEffect(() => {
        dispatcher(getTimeZoneMasterCountryDetailByTimeZone(timeZone))
        dispatcher(getMasterCountryList())

        return () => {
            dispatcher(setClearTimeZoneCountryDetail())
            dispatcher(setClearCountryList())
        }
    }, [])

    useEffect(() => {
        if (countryList?.countryList && timeZoneCountryDetail?.timeZoneCountryDetail) {
            let myLocalCountryList = countryList?.countryList?.filter((country) => (
                country.masterCountryId === timeZoneCountryDetail?.timeZoneCountryDetail.masterCountryId
            ))

            if (myLocalCountryList.length === 0) {
                myLocalCountryList = countryList?.countryList?.filter((country) => (
                    country.countryDomain === "US"
                ))
            }
            setMyCountry(myLocalCountryList[0])
        }
    }, [countryList?.countryList, timeZoneCountryDetail?.timeZoneCountryDetail])

    useEffect(() => {
        if (userData?.email && !validateEmail(userData?.email)) {
            setEmailAddressError("Invalid Email")
        }
        if (userData?.email && validateEmail(userData?.email)) {
            setEmailAddressError("")
        }
        if (userData?.mobileNo && !validateMobileNo(userData?.mobileNo)) {
            setMobileNoError("Invalid Phone Number")
        }
        if (userData?.mobileNo && validateMobileNo(userData?.mobileNo)) {
            setMobileNoError("")
        }
    }, [userData])

    const onSelectIsdCode = (option) => {
        const selectedCountry = countryList?.countryList?.find((country) => country?.masterCountryId === option.value)
        setMyCountry(selectedCountry)
    }

    const onSubmit = (event) => {
        event.preventDefault()
        dispatcher(setModal({
            ...modal,
            [modalConst.SPEAK_TO_US_MODAL.stateKey]: false
        }))
    }

    return (
        <form className={"space-y-5 overflow-x-hidden"} onSubmit={onSubmit}>
            <div className={"min-h-[24rem] block space-y-8 pt-3"}>
                <div className={"flex items-center justify-center font-bodyPri font-normal text-text-800 text-lg"}>
                    {"Share your details and our friendly team member will get back to you"}
                </div>

                <div className={"flex flex-col items-center justify-center gap-5"}>

                    {/* name container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {"Name"}
                            <span className={"text-lg text-red-500"}>{" *"}</span>
                        </span>
                        <input
                            type={"text"}
                            placeholder={"e.g. John Doe"}
                            className={clsx(
                                "w-full p-3 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-md",
                                "placeholder:text-text-500 placeholder:text-md"
                            )}
                            value={userData?.name}
                            onChange={(event) => setUserData({
                                ...userData,
                                name: event.target.value
                            })}
                            required
                        />
                    </div>

                    {/* email address container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {"Email Address"}
                            <span className={"text-lg text-red-500"}>{" *"}</span>
                        </span>
                        <input
                            type={"email"}
                            placeholder={"e.g. johndoe@gmail.com"}
                            className={clsx(
                                "w-full p-3 border-2 border-divider-medium rounded-md focus:outline-none focus:border-2 focus:border-text-400",
                                "font-bodyPri font-normal text-text-800 text-md",
                                "placeholder:text-text-500 placeholder:text-md"
                            )}
                            value={userData?.email}
                            onChange={(event) => setUserData({
                                ...userData,
                                email: event.target.value
                            })}
                            required
                        />
                        {emailAddressError &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {emailAddressError}
                            </span>
                        }
                    </div>

                    {/* phone number container */}
                    <div className={"w-full flex flex-col items-start justify-start gap-0.5"}>
                        <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                            {"Phone Number"}
                            <span className={"text-lg text-red-500"}>{" *"}</span>
                        </span>
                        <div className={"w-full p-3 flex justify-start items-center gap-1.5 rounded-md border-2 border-divider-medium focus:border-2 focus:border-text-400"}>
                            {countryList?.isLoading
                                && <FaSpinner className={"animate-spin text-primary-main text-lg"} />
                            }
                            {countryList?.countryList &&
                                <IsdCodeSelector
                                    options={countryList?.countryList?.map((country) => ({
                                        label: country?.isdCode,
                                        value: country?.masterCountryId,
                                        country: country
                                    }))}
                                    value={myCountry?.masterCountryId}
                                    onChange={(option) => onSelectIsdCode(option)}
                                />
                            }
                            <input
                                type={"number"}
                                placeholder={"0123456789"}
                                className={clsx(
                                    "input-number-spin-none focus:outline-none",
                                    "font-bodyPri font-normal text-text-800 text-md",
                                    "placeholder:text-text-500 placeholder:text-md"
                                )}
                                value={userData?.mobileNo}
                                onChange={(event) => setUserData({
                                    ...userData,
                                    mobileNo: event.target.value
                                })}
                                required
                            />
                        </div>
                        {mobileNoError &&
                            <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                                {mobileNoError}
                            </span>
                        }
                    </div>
                </div>
            </div>
            <div className={"flex justify-end px-5"}>
                <button
                    type={"submit"}
                    className={clsx(
                        "px-3 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                >
                    {"Contact Me"}
                </button>
            </div>
        </form>
    )
}

export default SpeakToUsModal