import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from "clsx";
import dayjs from "dayjs";

import { txnCardTitles } from 'pages/auth/wallet/data';

import BookingItem from 'pages/auth/wallet/booking/BookingItem';

import ComponentLoader from 'components/loader/ComponentLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getBookingList } from 'redux/booking/booking.request';
import { setClearBookingList } from 'redux/booking/booking.slice';

import { pagesInfo } from 'utils/pagesInfo';

const RECORDS = 10

const BookingListCard = () => {
  const { bookingList } = useAppState(s => s.booking)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(getBookingList({ page: 1, records: RECORDS }))

    return () => {
      dispatcher(setClearBookingList())
    }
  }, [])

  const sortBookingList = (txn1, txn2) => {
    let dateTime1 = dayjs(txn1?.createdAt)
    let dateTime2 = dayjs(txn2?.createdAt)
    return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
  }

  return (
    <div className="bg-white rounded-lg py-5 px-8 flex flex-col gap-5">
      <div className="flex justify-between items-center">
        <Link to={pagesInfo.BOOKINGS.pagePath} className="font-bodyPri font-sm text-base text-text-700 cursor-pointer hover:text-text-900">
          {'View All'}
        </Link>
        <span className="font-bodyPri font-medium text-lg text-text-900">
          {txnCardTitles.BOOKING.label}
        </span>
        {/* <Icon type="filter" /> */}
        <span></span>
      </div>
      <div className={clsx(
        "flex flex-col gap-2 divide-y-2 h-[30rem] px-5 overflow-hidden overflow-y-auto",
        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
      )}>
        <ComponentLoader isLoading={bookingList.isLoading} />
        {bookingList?.filteredBookingList?.results?.slice()?.sort(sortBookingList)?.map((bookingItem, index) => (
          <BookingItem key={index} bookingItem={bookingItem} />
        ))}
        {(!bookingList?.filteredBookingList || bookingList?.filteredBookingList?.records === 0) &&
          <span className={"h-full flex items-center justify-center font-bodyPri font-medium text-lg text-text-500"}>
            {bookingList.errorMsg}
          </span>
        }
      </div>
    </div>
  )
};

export default BookingListCard;