import { pagesInfo } from 'utils/pagesInfo';
import {
    ItemListContainer,
    LinksTitle,
    LinksItemContainer,
    AnchorLink,
    RouteLink,
} from './FooterStyle';

import { useAppState } from 'hooks/useStore';

const FooterItemsList = ({ footerItemsList }) => {
    const { logInSignUp } = useAppState((state) => state.auth)

    return (
        <ItemListContainer>
            <LinksTitle>{footerItemsList.linksTitle}</LinksTitle>
            <LinksItemContainer>
                {footerItemsList.linksItems.map((linkItem, index) => {
                    if (linkItem.pathType === 'href') {
                        return (
                            <AnchorLink key={index} href={linkItem.path} target={'_blank'}>{linkItem.name}</AnchorLink>
                        )
                    } else {
                        if (logInSignUp?.isLogedIn && linkItem?.name === "Log In") {
                            return (
                                <RouteLink key={index} to={pagesInfo?.DASHBOARD?.pagePath}>{"Dashboard"}</RouteLink>
                            )
                        }
                        return (
                            <RouteLink key={index} to={linkItem.path}>{linkItem.name}</RouteLink>
                        )
                    }
                })}
            </LinksItemContainer>
        </ItemListContainer>
    )
}

export default FooterItemsList
