export const referralTermsAndConditions = [
    {
        title: "heading1",
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
    },
    {
        title: "heading2",
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
    },
    {
        title: "heading3",
        content: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam ullam numquam reprehenderit provident, et nemo aperiam doloribus deleniti. Voluptas, ipsam? Voluptatem unde eligendi vero a sapiente cupiditate ea magnam, nemo, quod molestiae, illo voluptates facilis."
    },

]