import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

import { createSendOtp } from "redux/notification/notification.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setUserGeneralDetailPayload } from "redux/user/user.slice";

import { validateEmail } from "utils/validation-functions";

const NewEmailVerificationBox = ({ setIsCodeSent }) => {
    const { userGeneralDetailPayload } = useAppState((state) => state.user)

    const dispatcher = useAppDispatcher()

    const [inputEmail, setInputEmail] = useState("")
    const [isInputEmailError, setIsInputEmailError] = useState({
        isAlreadyExistEmail: false,
        isInvalidEmail: false
    })

    useEffect(() => {
        setInputEmail(userGeneralDetailPayload?.userGeneralDetailPayload?.email)
    }, [])

    useEffect(() => {
        if (!validateEmail(inputEmail)) {
            setIsInputEmailError({
                isAlreadyExistEmail: false,
                isInvalidEmail: true
            })
        }
        if (inputEmail === userGeneralDetailPayload?.userGeneralDetailPayload?.email) {
            setIsInputEmailError({
                isAlreadyExistEmail: true,
                isInvalidEmail: false
            })
        }
        if (validateEmail(inputEmail) && inputEmail !== userGeneralDetailPayload?.userGeneralDetailPayload?.email) {
            setIsInputEmailError({
                isAlreadyExistEmail: false,
                isInvalidEmail: false
            })
        }
    }, [inputEmail])

    const onSendCode = () => {
        if (!inputEmail || isInputEmailError?.isAlreadyExistEmail || isInputEmailError?.isInvalidEmail) return;

        dispatcher(setUserGeneralDetailPayload({
            ...userGeneralDetailPayload?.userGeneralDetailPayload,
            email: inputEmail
        }))
        dispatcher(createSendOtp({
            otpDeliveryMethod: "email",
            email: inputEmail
        }))
        toast.success("Code sent successfully")
        setIsCodeSent(true)
    }

    return (
        <div className={"w-full h-full flex flex-col items-center justify-center gap-8"}>
            <div className={"w-full flex flex-col justify-start items-start gap-1.5"}>
                <div className={"font-bodyPri font-medium text-text-800 text-base"}>
                    {"Email:"}
                </div>
                <div className={"w-full flex flex-col items-start justify-center gap-1"}>
                    <input
                        type={"text"}
                        className={clsx(
                            "w-full px-3 py-1 rounded-md focus:outline-none",
                            "font-bodyPri font-normal text-text-800 text-base",
                            (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                            && "border-2 border-red-500",
                            (!isInputEmailError?.isInvalidEmail || !isInputEmailError?.isAlreadyExistEmail)
                            && "border-2 border-divider-medium focus:border-2 focus:border-text-400 hover:border-text-400"
                        )}
                        value={inputEmail}
                        onChange={(event) => setInputEmail(event.target.value)}
                    />
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {isInputEmailError?.isInvalidEmail &&
                            "Invalid Email!"
                        }
                    </span>
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {isInputEmailError?.isAlreadyExistEmail &&
                            "An account already exist for this email"
                        }
                    </span>
                </div>
            </div>
            <div onClick={onSendCode}
                className={clsx(
                    "w-full py-1 flex item-center justify-center rounded-md",
                    "font-buttons font-normal text-base text-text-50",
                    (isInputEmailError?.isInvalidEmail || isInputEmailError?.isAlreadyExistEmail)
                    && "bg-text-300",
                    (!isInputEmailError?.isInvalidEmail && !isInputEmailError?.isAlreadyExistEmail)
                    && "bg-primary-main cursor-pointer"
                )}>
                <span className={""}>
                    {"Send Code"}
                </span>
            </div>
        </div>
    )
}

export default NewEmailVerificationBox