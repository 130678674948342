import tw, { styled } from 'twin.macro';
import { motion } from 'framer-motion';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)`
    bg-white 
`;

export const Content = tw(SectionContent)`
    gap-10
`;

export const Header = tw.div`
    flex flex-col items-center gap-5 md:mx-16 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const ItemContainer = tw.div`
    w-full
`;

export const Brands = tw.div`
    w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-10 items-center
`;

export const BrandItem = motion(styled.div`
    ${tw`
        w-full h-2/3 shadow rounded-lg border border-divider-medium cursor-pointer overflow-hidden
        flex items-center justify-center hover:shadow-lg
    `}
    img {
        ${tw`
            w-full h-full bg-center bg-contain
        `}
    }
`);


