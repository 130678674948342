import tw, { styled } from "twin.macro";

export const StyledChatListItem = styled.div(({ selected, unread }) => [
  tw`flex items-center w-full py-5 cursor-pointer`,
  selected && tw`bg-primary-light bg-opacity-30 rounded-md px-1.5`,
]);

export const StyledChatListItemImage = styled.img`
  ${tw`w-12 h-12 rounded-full object-cover`}
`;

export const StyledChatListItemText = styled.div`
  ${tw`w-full`}
  div {
    ${tw`flex items-center justify-between`}
    p {
      ${tw` text-xs text-gray-400`}
    }
  }
`;

export const StyledChatListItemName = styled.h3(({ unread }) => [
  tw`font-bold text-primary-dark`,
]);

export const StyledChatListItemMessageText = styled.p(({ unread }) => [
  tw`text-gray-500`,
  unread && tw`font-bold`,
]);

export const StyledChatListItemImageContainer = styled.div`
  ${tw`min-width[48px] min-height[48px] rounded-full bg-primary-light mr-4`}
`;
