import { useAppState } from 'hooks/useStore';

const CourseDescriptionDiv = () => {
    const { courseDetail } = useAppState((s) => s.course)

    return (
        <div className="w-full h-full overflow-hidden space-y-3">
            <div className='flex items-center gap-1'>
                <span className="text-xl font-medium font-bodyPri tracking-wide text-text-900">
                    {"Class Description"}
                    </span>
            </div>
            <div className='px-3' 
                dangerouslySetInnerHTML={{ __html: courseDetail?.courseDetail?.courseDescription }}
            >
            </div>
        </div>
    )
}

export default CourseDescriptionDiv
