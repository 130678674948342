import { toast } from 'react-toastify';

import userService from 'redux/user/user.service';
import {
    setUserLoading,
    setUser,
    setUserErrorMsg,

    setUserDetailLoading,
    setUserDetail,
    setUserDetailErrorMsg,

    setAddUserDetailLoading,
    setAddUserDetailErrorMsg,

    setUserCultureDetailLoading,
    setUserCultureDetail,
    setUserCultureDetailErrorMsg,

    setAddUserCultureDetailLoading,
    setAddUserCultureDetailErrorMsg,

    setUserEducationListLoading,
    setUserEducationList,
    setUserEducationListErrorMsg,

    setAddUserEducationDetailLoading,
    seAddUserEducationDetailErrorMsg,

    setUserOtherPrefLoading,
    setUserOtherPref,
    setUserOtherPrefErrorMsg,

    setAddUserOtherPrefLoading,
    setAddUserOtherPrefErrorMsg,

    setUserSocialMediaLinksLoading,
    setUserSocialMediaLinks,
    setUserSocialMediaLinksErrorMsg,

    setAddUserSocialMediaLinksLoading,
    setAddUserSocialMediaLinksErrorMsg
} from 'redux/user/user.slice';

import { setLogIn } from 'redux/auth/auth.slice';

import { setUserLocals } from "redux/user/user.utils";

import { userSocialMediaLinks } from 'pages/auth/settings/settings.data';

export const getMyProfile = () => async (dispatch) => {
    dispatch(setUserLoading(true));

    try {
        const response = await userService.getMyProfile()
        if (response.status === 200) {
            dispatch(setUser(response.data.data))
            await setUserLocals(response.data.data)
            dispatch(setLogIn())
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserLoading(false));
    }
}

export const getUserDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserDetailLoading(false))
    }
}

export const updateUserDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(getMyProfile())
            dispatch(setUserDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddUserDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddUserDetailLoading(false))
    }
}

export const getUserCultureDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setUserCultureDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserCultureDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserCultureDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserCultureDetailLoading(false))
    }
}

export const updateUserCultureDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddUserCultureDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserCultureDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserCultureDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddUserCultureDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddUserCultureDetailLoading(false))
    }
}

export const getUserEducationListByUserId = (userId) => async (dispatch) => {
    dispatch(setUserEducationListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserEducationListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserEducationListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserEducationListLoading(false))
    }
}

export const createUserEducationDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserEducationDetailLoading(true))

    try {
        const { userEducationList } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.createUserEducationDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList([
                ...userEducationList?.userEducationList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddUserEducationDetailLoading(false))
    }
}

export const updateEducationDetailByUserEducationId = (userEducationId, body) => async (dispatch, getState) => {
    console.log(userEducationId, body)
    dispatch(setAddUserEducationDetailLoading(true))

    try {
        const { userEducationList } = getState().user
        const requestData = {
            params: { userEducationId: userEducationId },
            body: body
        }
        const response = await userService.updateEducationDetailByUserEducationId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList(
                userEducationList?.userEducationList?.map((userEducation) => (
                    userEducation.userEducationId === response.data.data.userEducationId ? response.data.data : userEducation
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddUserEducationDetailLoading(false))
    }
}

export const deleteEducationDetailByUserEducationId = (userEducationId, body) => async (dispatch, getState) => {
    dispatch(setAddUserEducationDetailLoading(true))

    try {
        const { userEducationList } = getState().user
        const requestData = {
            params: { userEducationId: userEducationId },
            body: body
        }
        const response = await userService.deleteEducationDetailByUserEducationId(requestData)
        if (response.status === 200) {
            dispatch(setUserEducationList(
                userEducationList?.userEducationList?.filter((userEducation) => (
                    userEducation.userEducationId !== userEducationId
                ))
            ))
            toast.success(response.data.message)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(seAddUserEducationDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddUserEducationDetailLoading(false))
    }
}

export const getUserOtherPrefDetailByUserId = (userId) => async (dispatch, getState) => {
    dispatch(setUserOtherPrefLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await userService.getUserOtherPrefDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserOtherPref(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserOtherPrefErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserOtherPrefLoading(false))
    }
}

export const updateUserOtherPrefDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddUserOtherPrefLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await userService.updateUserOtherPrefDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUserOtherPref(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddUserOtherPrefErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddUserOtherPrefLoading(false))
    }
}

// request for user social media presence
export const getUserSocialMediaLinks = () => async (dispatch) => {
    dispatch(setUserSocialMediaLinksLoading(true))

    try {
        const response = userSocialMediaLinks
        dispatch(setUserSocialMediaLinks(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setUserSocialMediaLinksLoading(false))
    }
}

export const updateUserSocialMediaLink = (userSocialId, body) => async (dispatch, getState) => {
    dispatch(setAddUserSocialMediaLinksLoading(true))

    try {
        const { userSocialMediaLinks } = getState().user
        const filteredOriginalList = userSocialMediaLinks?.userSocialMediaLinks?.filter((link) => link?.userSocialId !== userSocialId)
        const filteredLink = userSocialMediaLinks?.userSocialMediaLinks?.find((link) => link?.userSocialId === userSocialId)
        const updateSocialLink = { ...filteredLink, userSocialLinks: body.userSocialLink }
        const response = [updateSocialLink, ...filteredOriginalList]
        dispatch(setUserSocialMediaLinks(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddUserSocialMediaLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddUserSocialMediaLinksLoading(false))
    }
}
