import { toast } from 'react-toastify';

import todoServices from 'redux/todo/todo.services';
import {
    setTodoListLoading,
    setTodoList,
    setTodoListErrorMsg,

    setAddTodoLoading,
    setAddTodo,
    setAddTodoErrorMsg
} from 'redux/todo/todo.slice'


export const getUserTodoList = () => async (dispatch) => {
    dispatch(setTodoListLoading(true))

    try {
        const response = await todoServices.getUserTodoList()
        if (response.status === 200) {
            dispatch(setTodoList(response.data))
        } else {
            dispatch(setTodoListErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error)
        dispatch(setTodoListErrorMsg(error.message))
    } finally {
        dispatch(setTodoListLoading(false))
    }
}

export const addUserTodo = () => async (dispatch, getState) => {
    dispatch(setAddTodoLoading(true))

    const { addTodo } = getState().todo

    try {
        const requestData = {
            body: {
                todo: addTodo?.addTodoPayload?.todo,
                status: addTodo?.addTodoPayload?.status
            }
        }
        const response = await todoServices.addUserTodo(requestData)
        if (response.status === 200) {
            dispatch(setAddTodo(response.data))
            dispatch(getUserTodoList())
            toast.success(response.data.message)
        } else {
            dispatch(setAddTodoErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error)
        dispatch(setAddTodoErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setAddTodoLoading(false))
    }
}

export const updateUserTodo = () => async (dispatch, getState) => {
    dispatch(setAddTodoLoading(true))

    const { addTodo } = getState().todo
    try {
        const requestData = {
            params: { userTodoId: addTodo?.addTodoPayload?.userTodoId },
            body: {
                todo: addTodo?.addTodoPayload?.todo,
                status: addTodo?.addTodoPayload?.status
            }
        }
        const response = await todoServices.updateUserTodo(requestData)
        if (response.status === 200) {
            dispatch(setAddTodo(response.data))
            dispatch(getUserTodoList())
            toast.success(response.data.message)
        } else {
            dispatch(setAddTodoErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error)
        dispatch(setAddTodoErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setAddTodoLoading(false))
    }
}

export const deleteUserTodo = (userTodoId) => async (dispatch) => {
    dispatch(setAddTodoLoading(true))

    try {
        const requestData = {
            params: { userTodoId: userTodoId }
        }
        const response = await todoServices.deleteUserTodo(requestData)
        if (response.status === 200) {
            dispatch(setAddTodo(response.data))
            dispatch(getUserTodoList())
            toast.success(response.data.message)
        } else {
            dispatch(setAddTodoErrorMsg(response.statusText))
            toast.error(response.data.message)
        }
    } catch (error) {
        console.error(error)
        dispatch(setAddTodoErrorMsg(error.message))
        toast.error(error.response.message)
    } finally {
        dispatch(setAddTodoLoading(false))
    }
}