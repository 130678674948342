import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { createOffering } from "redux/offering/offering.request"
import { setFilterProps } from 'redux/template/template.slice';
import { setClearAddOffering } from 'redux/offering/offering.slice';
import { filterName, tempCourseType } from 'redux/template/template.const';


const SelectTemplate = () => {
    let { tempCourseList, filterProps } = useAppState((s) => s.template)
    const filteredTempCourseList = tempCourseList?.filteredTempCourseList
    const { addOffering } = useAppState((s) => s.offering)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathName || `/edit-class/${addOffering?.addOffering?.courseId}`

    const [myTemplate, setMyTemplate] = useState(null);

    const handleTemplate = (template) => {
        setMyTemplate(template)
    }

    useEffect(() => {
        if (tempCourseList?.tempCourseList) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, tempCourseType: tempCourseType.ALL.value },
                list: tempCourseList?.tempCourseList,
                filterName: filterName.CREATE_COURSE.value
            }))
        }
    }, [tempCourseList?.tempCourseList])

    useEffect(() => {
        if (filteredTempCourseList) {
            setMyTemplate(filteredTempCourseList[0])
        }
    }, [filteredTempCourseList])

    useEffect(() => {
        if (addOffering?.addOffering) {
            dispatcher(setClearAddOffering())
            navigate(from, { replace: true })
        }
    }, [addOffering?.addOffering])

    const onHandleEditCourse = (template) => {
        if (addOffering?.isLoading) {
            return;
        }

        dispatcher(createOffering(template?.tempCourseId))
    }

    const handleOnChangeTempCourseType = (tempCourseType) => {
        dispatcher(setFilterProps({
            filterProps: { ...filterProps, tempCourseType: tempCourseType },
            list: tempCourseList?.tempCourseList,
            filterName: filterName.CREATE_COURSE.value
        }))
    }


    return (
        <div className={"space-y-3"}>
            <div className="w-full px-4 py-5 bg-white shadow-md rounded-lg space-y-5">
                <div className={"flex flex-row gap-3 self-start"}>
                    {Object.keys(tempCourseType).map((key, index) => {
                        return (
                            <div key={index} onClick={() => handleOnChangeTempCourseType(tempCourseType[key].value)}
                                className={clsx(
                                    "w-24 py-1 font-buttons font-normal text-base",
                                    "flex items-center justify-center rounded-full cursor-pointer",
                                    tempCourseType[key].value === filterProps?.tempCourseType ?
                                        "bg-primary-main text-text-50" :
                                        "bg-primary-light text-primary-main hover:bg-primary-main hover:text-text-50"
                                )}
                            >
                                {tempCourseType[key].label}
                            </div>
                        )
                    })}
                </div>
                <p className="text-base font-normal text-text-700 text-center self-centre">
                    {`Customise a template to create your own ${filterProps?.tempCourseType} sessions`}
                </p>
                <div className={clsx(
                    "grid grid-flow-col-dense gap-8 px-4 py-4 overflow-hidden overflow-x-scroll",
                    "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                    "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                )}>
                    {tempCourseList?.filteredTempCourseList?.map((templateItem, idx) => (
                        <div key={idx} onClick={() => handleTemplate(templateItem)} className={clsx(
                            "relative flex flex-col content-between justify-between rounded-lg shadow-lg bg-black w-52 cursor-pointer",
                            "hover:scale-105 hover:transition duration-200 hover:shadow-lg",
                            templateItem?.tempCourseId === myTemplate?.tempCourseId ?
                                "ring-offset-2 ring-4 ring-secondary-dark" :
                                "ring-offset-2 ring-4 ring-white"
                        )}>
                            <img src={templateItem?.tempThumbnailPicUrl} alt="template-thumbnail"
                                className="object-cover w-full h-32 rounded-lg opacity-90"
                            />
                            <div className={"absolute w-full h-full rounded-lg overflow-hidden flex justify-center items-center"}>
                                <span className={"bg-black bg-opacity-50 px-4 py-2 rounded-sm text-text-50 font-bodyPri font-normal text-base"}>
                                    {templateItem?.tempTitle}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {myTemplate &&
                <div className='w-full px-4 py-5 bg-white shadow-md rounded-lg space-y-3 flex flex-col'>
                    <div className={clsx(
                        "cursor-pointer px-3 py-2 flex items-center justify-center rounded-xl self-center",
                        "font-buttons font-normal text-base text-primary-main ring-2 ring-primary-main",
                        "hover:bg-primary-main hover:text-text-50 group"
                    )} onClick={() => onHandleEditCourse(myTemplate)}>
                        {!addOffering?.isLoading &&
                            <span>{'Customize This Template'}</span>
                        }
                        {addOffering?.isLoading
                            && <FaSpinner className={"text-2xl animate-spin text-primary-main group-hover:text-text-50"} />
                        }
                    </div>
                    <div className={"w-full flex items-center justify-center"}>
                        {!addOffering?.errorMsg &&
                            <span className={"font-bodyPri font-normal text-sm text-red-500"}>
                                {addOffering?.errorMsg}
                            </span>
                        }
                    </div>
                    <img src={myTemplate?.tempPreviewPicUrl} alt={'template-preview'}
                        className="w-full"
                    />
                </div>
            }
        </div>
    )
}

export default SelectTemplate