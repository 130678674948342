import { Link } from "react-router-dom"

const ComingSoonModal = () => {
    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[16rem] block pt-5"}>
                <div className='w-full h-full flex flex-col justify-between items-center space-y-5'>
                    <span className='flex text-3xl font-bodyPri font-medium text-text-900'>
                        {"We are at work."}
                    </span>
                    <span className='pt-8 font-bodyPri font-normal'>
                        {"This feature is currently under development, we will make it available in the near future."}
                    </span>
                </div>
            </div>
            <span className="font-bodySec font-normal text-sm text-text-700">
                {"For any query contact us at "}
                <Link to={"/"} className={"text-primary-main cursor-pointer"}>
                    {"support@edulyte.com"}
                </Link>
            </span>
        </div>
    )
}

export default ComingSoonModal