import tw, { styled } from 'twin.macro';
import { Page, PageContainer } from 'components/generalComponent/StyledComponent';

import { sideTopBarPagesName } from 'utils/pagesInfo';

export const PageStyle = styled(Page)`
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        lg:(flex-row h-screen)
    `}
`;

export const PageContainerStyle = styled(PageContainer)`
    ${({ pageName }) => sideTopBarPagesName.includes(pageName) && tw`
        mt-32 lg:mt-20
    `}
`;