import format from "string-format";

import { baseApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/tutor/tutor.const";


class TutorService {
    getTutorPublicList = async ({ query }) => {
        const response = await baseApiInstance().get(
            url.TUTOR_PUBLIC_LIST,
            { params: query }
        )

        return response
    }

    getTutorUserPublicProfileByUserId = async ({ params, query }) => {
        const response = await baseApiInstance().get(
            format(url.TUTOR_USER_PUBLIC_PROFILE, params),
            { params: query }
        )

        return response;
    }

    getMyTutorUserListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.MY_TUTOR_USER_LIST, params),
            { params: query }
        )

        return response
    }

    getFavTutorUserListByUserId = async ({ params, query }) => {
        const response = await baseAuthApiInstance().get(
            format(url.FAV_TUTOR_USER_LIST, params),
            { params: query }
        )

        return response
    }

    createFavTutorUserDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.FAV_TUTOR_USER_LIST, params),
            body
        )

        return response
    }

    deleteFavTutorUserDetailByUserId = async ({ params, body }) => {
        const response = baseAuthApiInstance().delete(
            format(url.FAV_TUTOR_USER_LIST, params),
            { data: body }
        )

        return response
    }

    getTutorUserDetailByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.TUTOR_USER_DETAIL, params)
        )
        
        return response
    }

    updateTutorUserDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.TUTOR_USER_DETAIL, params),
            body
        )

        return response
    }

    getTutorUserPriceByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.TUTOR_USER_PRICE, params)
        )

        return response
    }

    updateTutorUserPriceByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.TUTOR_USER_PRICE, params),
            body
        )

        return response
    }

    getTutorUserLessonByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.TUTOR_USER_LESSON, params)
        )

        return response
    }

    updateTutorUserLessonByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.TUTOR_USER_LESSON, params),
            body
        )

        return response
    }

    getTutorUserLanguageListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.TUTOR_USER_LANGUAGE_LIST, params)
        )

        return response
    }

    createTutorUserLanguageDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.TUTOR_USER_LANGUAGE_LIST, params),
            body
        )

        return response
    }

    updateTutorLanguageDetailByTutorLanguageId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.TUTOR_LANGUAGE_DETAIL, params),
            body
        )

        return response
    }

    deleteTutorLanguageDetailByTutorLanguageId = async ({ params}) => {
        const response = await baseAuthApiInstance().delete(
            format(url.TUTOR_LANGUAGE_DETAIL, params)
        )

        return response
    }

    getTutorUserCategoryListByUserId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.TUTOR_USER_CATEGORY_LIST, params)
        )

        return response
    }

    createTutorUserCategoryDetailByUserId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            format(url.TUTOR_USER_CATEGORY_LIST, params),
            body
        )

        return response
    }

    updateTutorCategoryDetailByTutorCategoryId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.TUTOR_CATEGORY_DETAIL, params),
            body
        )

        return response
    }

    deleteTutorCategoryDetailByTutorCategoryId = async ({ params }) => {
        const response = await baseAuthApiInstance().delete(
            format(url.TUTOR_CATEGORY_DETAIL, params)
        )

        return response
    }
}

export default new TutorService();
