import { useEffect } from "react"
import {FaSpinner} from "react-icons/fa"

import EmailVerificationContainer from 'components/modals/codeVerificationModal/components/EmailVerificationContainer'
import MobileVerificationContainer from 'components/modals/codeVerificationModal/components/MobileVerificationContainer'

import { useAppDispatcher, useAppState } from 'hooks/useStore'
import { setClearSignUpPayload } from "redux/auth/auth.slice"

const CodeVerificationModal = () => {
  const { logInSignUp } = useAppState((s) => s.auth)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher()

  const loadingElement = logInSignUp?.isLoading || user?.isLoading

  useEffect(() => {
    return () => {
      dispatcher(setClearSignUpPayload())
    }
  }, [])

  return (
    <div className={"space-y-5"}>
      <div className={"min-h-[24rem] block"}>
        <div className='w-full h-full space-y-5 p-5'>
          <EmailVerificationContainer />
          <hr className='border border-divider-medium' />
          <MobileVerificationContainer />
        </div>
      </div>
      {loadingElement &&
        <div className={"flex justify-center items-center gap-1"}>
          <div className={"px-5 animate-spin"}>
            <FaSpinner className={"text-primary-main text-2xl"} />
          </div>
          <span className={"w-full font-bodyPri font-bold text-primary-main text-lg whitespace-nowrap"}>
            {"Loading..."}
          </span>
        </div>
      }
    </div>
  )
}

export default CodeVerificationModal