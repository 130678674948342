import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'string-format';

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { addWalletCredit } from 'redux/wallet/wallet.request';

import AmountTopUpCard from 'components/modals/addCreditModal/AmountTopUpCard';
import AddCreditModalFooter from 'components/modals/addCreditModal/AddCreditModalFooter';
import { setClearAddCreditToWallet } from 'redux/wallet/wallet.slice';
import { setClearMakePayment } from 'redux/payment/payment.slice';
import { setClearModal } from 'redux/local/local.slice';
import { paymentMethodStatus } from 'redux/payment/payment.const';

const walletConfirmationUrl = "/wallet/confirmation?status={status}&virtualTxnId={virtualTxnId}"

const AddCreditModal = () => {
    const { user } = useAppState(s => s.user)
    const { makePayment } = useAppState(s => s.payment)
    const { addCreditToWallet } = useAppState(s => s.wallet)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    useEffect(() => {
        if (makePayment?.makePayment?.status === paymentMethodStatus.SUCCEEDED.value) {
            dispatcher(addWalletCredit())
            dispatcher(setClearMakePayment())
        } else if (makePayment?.makePayment) {
            dispatcher(setClearMakePayment())
            dispatcher(setClearAddCreditToWallet())
            const query = {
                status: 'failed'
            }
            dispatcher(setClearModal())
            navigate(format(walletConfirmationUrl.split('&')[0], query))
        }
    }, [makePayment?.makePayment])

    useEffect(() => {
        if (addCreditToWallet?.addCreditToWallet) {
            const query = {
                status: 'success',
                virtualTxnId: addCreditToWallet?.addCreditToWallet.virtualTxnId
            }
            dispatcher(setClearAddCreditToWallet())
            dispatcher(setClearModal())
            navigate(format(walletConfirmationUrl, query))
        }
    }, [addCreditToWallet?.addCreditToWallet])

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[24rem] block space-y-5 pt-5"}>

                <div className={""}>
                    <div className={"flex items-center justify-between gap-1"}>
                        <span className={"font-bodyPri font-medium text-base text-text-800"}>
                            {"Available Credit:"}
                        </span>
                        <div className={"flex items-center justify-center gap-1 font-bodyPri font-semibold text-lg text-text-900"}>
                            <span>{"USD"}</span>
                            <span>{parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className={"flex items-center justify-end gap-1"}>
                        <span className={"font-bodyPri font-normal text-sm text-text-800"}>
                            {"Approx."}
                        </span>
                        <div className={"flex items-center justify-center gap-1 font-bodyPri font-normal text-sm text-text-800"}>
                            <span>{"INR"}</span>
                            <span>{parseFloat(user?.user?.netWalletCredit / 100).toFixed(2)}</span>
                        </div>
                    </div>
                </div>

                <div className={""}>
                    <AmountTopUpCard />
                </div>
                <div className={"w-full h-10"}>
                    {(addCreditToWallet?.errorMsg) &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                                {addCreditToWallet?.errorMsg}
                            </span>
                        </div>
                    }
                    {(addCreditToWallet?.isLoading) &&
                        <div classsName={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait while processing ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>

            </div>

            <div className={"flex justify-end"}>
                <AddCreditModalFooter />
            </div>
        </div>
    )
}

export default AddCreditModal