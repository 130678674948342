import clsx from 'clsx'
import { Link } from 'react-router-dom';

import { FaChevronRight } from 'react-icons/fa';
import { FaInfoCircle } from 'react-icons/fa';

import { companyTools, popularTools } from 'pages/auth/tools/tools.data';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';


export const PopularTools = () => {
    const { modal, locals } = useAppState((s) => s.local)

    const dispatcher = useAppDispatcher()

    const handleLink = (label: any) => {
        if (label === 'Upgrade Plan') {
            dispatcher(setModal({
                ...modal,
                [modalConst.COMING_SOON_MODAL.stateKey]: true
            }))
        }
    }

    return (
        <div className='w-full p-5 bg-white rounded-lg'>
            <div className={clsx('grid sm:grid-cols-2 gap-5 px-6 py-4 text-slate-700 items-center')}>
                {popularTools.map((tool, index) => tool.roles.includes(locals.userRole) && (
                    <div onClick={() => handleLink(tool.label)}>
                        <Link to={tool.to} key={index} className={clsx(
                            'relative flex items-center p-3 py-6 space-x-4 border',
                            "rounded-lg shadow-md",
                            "h-min w-full",
                            "hover:ring-[2px] hover:ring-offset-2 ring-primary-main",
                        )}>
                            <div>{tool.icon}</div>
                            <p className='flex-1'>{tool.label}</p>
                            <FaChevronRight className={'text-xl opacity-30'} />
                            <button className='absolute top-1 right-1 has-tooltip'>
                                <span className='w-40 p-1 -mt-6 text-xs bg-gray-100 border rounded shadow-lg text-slate-500 tooltip'>
                                    {tool.toolTip}
                                </span>
                                <FaInfoCircle className='text-gray-300' />
                            </button>
                        </Link>
                    </div>
                ))}
            </div>
            <hr className={"w-full border border-text-500"} />
            <div className={clsx('grid sm:grid-cols-2 gap-5 px-6 py-4 text-slate-700 items-center')}>
                {companyTools.map((tool, index) => (
                    <div onClick={() => handleLink(tool.label)}>
                        <Link to={tool.to} key={index} className={clsx(
                            'relative flex items-center p-3 py-6 space-x-4 border',
                            "rounded-lg shadow-md",
                            "h-min w-full",
                            "hover:ring-[2px] hover:ring-offset-2 ring-primary-main",
                        )}>
                            <div className={""}>{tool.icon}</div>
                            <p className='flex-1'>{tool.label}</p>
                            <FaChevronRight className={'text-xl opacity-30'} />
                            <button className='absolute top-1 right-1 has-tooltip'>
                                <span className='w-40 p-1 -mt-6 text-xs bg-gray-100 border rounded shadow-lg text-slate-500 tooltip'>
                                    {tool.toolTip}
                                </span>
                                <FaInfoCircle className='text-gray-300' />
                            </button>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    )
}