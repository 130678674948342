import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { OptionSelector } from "components/common-components/Select";

import { tabs } from 'pages/auth/offerings/data';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setFilterProps } from 'redux/offering/offering.slice';
import { filterName, offeringStatus } from "redux/offering/offering.const";
import { courseType } from 'redux/course/course.const';

import { pagesInfo } from 'utils/pagesInfo';

const FilterSection = (props) => {
    const { offeringList, filterProps } = useAppState((s) => s.offering);
    const { activeTab, setActiveTab } = props
    const dispatcher = useAppDispatcher()

    const handleFilter = (tab) => {
        setActiveTab(tab)
        if (filterProps.courseType === courseType.ALL.value) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, courseType: tab.value },
                filterName: filterName.OFFERING.value,
                list: offeringList?.offeringList?.results
            }))
        }
        if (filterProps.courseType === courseType.ONE_ON_ONE.value) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, courseType: tab.value },
                filterName: filterName.OFFERING.value,
                list: offeringList?.offeringList?.results
            }))
        }
        if (filterProps.courseType === courseType.GROUP.value) {
            dispatcher(setFilterProps({
                filterProps: { ...filterProps, courseType: tab.value },
                filterName: filterName.OFFERING.value,
                list: offeringList?.offeringList?.results
            }))
        }
    }

    return (
        <div className="p-4 bg-white rounded-lg">
            <div className="flex flex-col sm:flex-row items-center justify-between gap-3">
                <div className={"flex flex-row gap-2"}>
                    {Object?.values(courseType)?.map((tab, index) => (
                        <div key={index}
                            className={clsx(
                                "px-5 py-[2px] font-buttons font-normal text-base whitespace-nowrap rounded-xl flex items-center justify-center cursor-pointer",
                                activeTab.value === tab.value
                                    ? "bg-primary-main text-text-50"
                                    : "bg-primary-light text-primary-main hover:text-text-50 hover:bg-primary-main"
                            )}
                            onClick={() => handleFilter(tab)}
                        >{tab.label}</div>
                    ))}
                </div>
                <div className="flex justify-between items-center gap-3">
                    <div className="w-full sm:w-40">
                        <OptionSelector
                            onChange={(v) => {
                                dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, offeringStatus: v.value },
                                    filterName: filterName.OFFERING.value,
                                    list: offeringList?.offeringList?.results
                                }))
                            }
                            }
                            options={Object.values(offeringStatus)}
                            value={filterProps?.offeringStatus}
                            className="w-44"
                        />
                    </div>
                    <div className="w-32 py-1.5 border-2 border-primary-main rounded-xl text-primary-main bg-white font-medium flex justify-center items-center cursor-pointer hover:bg-primary-main hover:text-white ml-12">
                        <Link to={pagesInfo?.CREATE_COURSE.pagePath}>
                            {"Create New"}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterSection;