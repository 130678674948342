const learnEnglish = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/learn-english-category-featured.png';
const ielts = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/ielts-category-featured.png';
const pteAcademic = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/pte-academic-category-featured.png';
const cfaLevelOne = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/cfa-level-one-category-featured.png';
const studyInAustralia = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/study-in-australia-category-featured.png';
const mathematics = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/maths-category-featured.png';
const assignmentHelp = 'https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/home-page/assignment-help-category-featured.png';

export const featuredData = [
    {
        label: 'Learn English',
        imageUrl: learnEnglish,
        url: '#',
    },
    {
        label: 'IELTS',
        imageUrl: ielts,
        url: '#',
    },
    {
        label: 'PTE Academic',
        imageUrl: pteAcademic,
        url: '#',
    },
    {
        label: 'CFA Level 1',
        imageUrl: cfaLevelOne,
        url: '#',
    },
    {
        label: 'Study in Australia',
        imageUrl: studyInAustralia,
        url: '#',
    },
    {
        label: 'Maths',
        imageUrl: mathematics,
        url: '#',
    },
    {
        label: 'Assignment Help',
        imageUrl: assignmentHelp,
        url: '#',
    },
]