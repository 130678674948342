import { useEffect } from "react";
import clsx from "clsx";

import ReviewCard from "components/ReviewCard";
import RatingStars from "components/ratingStars/RatingStars";

import Container from "./Container";

import { getTutorReviewListByTutorId } from "redux/review/review.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearTutorReviewList } from 'redux/review/review.slice';

const Reviews = (props) => {
  const { tutorPublicProfile } = useAppState((s) => s.tutor)
  const { tutorReviewList } = useAppState((s) => s.review)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    if (tutorPublicProfile?.tutorPublicProfile?.tutorId) {
      dispatcher(getTutorReviewListByTutorId(tutorPublicProfile?.tutorPublicProfile?.tutorId))
    }

    return () => {
      dispatcher(setClearTutorReviewList())
    }
  }, [tutorPublicProfile?.tutorPublicProfile])

  return (
    <Container
      id={props.id}
      leftSide={
        <div className="p-6">
          <div className="flex items-center gap-3">
            <span className="text-2xl font-bold">
              {"Reviews"}
            </span>
            <RatingStars rating={tutorReviewList?.filteredTutorReviewList?.avgRating || 0} fullRating={5} />
            <span className="font-medium text-md">{tutorReviewList?.filteredTutorReviewList?.avgRating} ({tutorReviewList?.filteredTutorReviewList?.ratingCount})</span>
          </div>
          <div className="hidden md:block">
            <div className={clsx("mt-6 grid grid-cols-2 gap-3 grid-flow-row auto-cols-max")}>
              {tutorReviewList?.filteredTutorReviewList?.reviews &&
                tutorReviewList?.filteredTutorReviewList?.reviews?.map((ele, idx) => (
                  <ReviewCard
                    key={idx}
                    rating={ele?.rating}
                    content={ele?.review}
                    profileImg={ele?.provider?.profilePicUrl}
                    fName={ele?.provider?.firstName}
                    lName={ele?.provider?.lastName}
                    createdAt={ele?.createdAt}
                  />
                ))}
              {(tutorReviewList?.errorMsg || !tutorReviewList?.filteredTutorReviewList?.reviews)
                && <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                  {tutorReviewList?.errorMsg || "No Reviews Found!"}
                </span>
              }
            </div>
          </div>
          <div className="block md:hidden">
            <div className={clsx("mt-6 flex flex-col gap-3")}>
              {tutorReviewList?.filteredTutorReviewList?.reviews &&
                tutorReviewList?.filteredTutorReviewList?.reviews?.map((ele, idx) => (
                  <ReviewCard
                    key={idx}
                    rating={ele?.rating}
                    content={ele?.review}
                    profileImg={ele?.provider?.profilePicUrl}
                    fName={ele?.provider?.firstName}
                    lName={ele?.provider?.lastName}
                    createdAt={ele?.createdAt}
                  />
                ))}
              {(tutorReviewList?.errorMsg || !tutorReviewList?.filteredTutorReviewList?.reviews)
                && <span className={"font-bodyPri font-normal text-text-800 text-base"}>
                  {tutorReviewList?.errorMsg || "No Reviews Found!"}
                </span>
              }
            </div>
          </div>
        </div>
      }
      rightSide={null}
      marginTop
    />
  );
};

export default Reviews;
