import { useEffect } from 'react';
import clsx from "clsx";
import dayjs from "dayjs";

import { txnCardTitles } from 'pages/auth/wallet/data';

import WalletTxnItem from 'pages/auth/wallet/walletTxn/WalletTxnItem';

import Icon from "components/Icon";
import ComponentLoader from 'components/loader/ComponentLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getWalletTxnList } from 'redux/wallet/wallet.request';
import { setClearWalletTxnList } from 'redux/wallet/wallet.slice';


const WalletTxnListCard = () => {
    const { walletTxnList } = useAppState(s => s.wallet)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(getWalletTxnList())

        return () => {
            dispatcher(setClearWalletTxnList())
        }
    }, [])

    const sortWalletTxnList = (txn1, txn2) => {
        let dateTime1 = dayjs(txn1?.createdAt)
        let dateTime2 = dayjs(txn2?.createdAt)
        return (dateTime1.isBefore(dateTime2)) ? 1 : -1;
    }

    return (
        <div className="bg-white rounded-lg py-5 px-8 flex flex-col gap-5">
            <div className="flex justify-between items-center">
                <span className="font-bodyPri font-sm text-base text-text-700 cursor-pointer hover:text-text-900">
                    {/* {'View All'} */}
                </span>
                <span className="font-bodyPri font-medium text-lg text-text-900">
                    {txnCardTitles.WALLET.label}
                </span>
                {/* <Icon type="filter" /> */}
                <span></span>
            </div>
            <div className={clsx(
                "flex flex-col gap-2 divide-y-2 h-[30rem] px-5 overflow-hidden overflow-y-auto",
                "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
                "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
                <ComponentLoader isLoading={walletTxnList.isLoading} />
                {walletTxnList?.walletTxnList?.slice()?.sort(sortWalletTxnList)?.map((walletTxnItem, index) => (
                    <WalletTxnItem key={index} walletTxnItem={walletTxnItem} />
                ))}
                {(!walletTxnList?.walletTxnList || walletTxnList?.walletTxnList?.length === 0) &&
                    <spna className={"h-full flex items-center justify-center font-bodyPri font-medium text-lg text-text-500"}>
                        {walletTxnList.errorMsg}
                    </spna>
                }
            </div>
        </div>
    )
};

export default WalletTxnListCard;