import { setAndGetLocalStorage } from "redux/local/local.utils";
import { localsConst } from "redux/local/local.const";
import { userRoles } from "redux/auth/auth.const";

import store from "redux/store";
import { setLocals } from "redux/local/local.slice";


export const setUserLocals = (user) => {
    const { dispatch } = store
    const { locals } = store.getState().local

    const userRole = setAndGetLocalStorage(localsConst.USER_ROLE.value)
    const filteredRoles = user?.roles?.filter(role => role.role === userRole)
    dispatch(setLocals({
        ...locals,
        [localsConst.USER_ROLE.value]: filteredRoles.length !== 0 ? userRole : userRoles.STUDENT.value
    }))
}