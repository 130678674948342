import { useRef } from 'react';
import {
    Container,
    ListItems,
    ArrowLeftIcon,
    ArrowRightIcon,
    ListItem,
    StudentIcon,
    TutorIcon
} from './TopNavBarStyle';

import TopNavBarItem from './TopNavBarItem';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { userRoles } from 'redux/auth/auth.const';
import { modalConst } from 'redux/local/local.const';

import { navLinkList } from 'utils/sideAndTopNavLink.utils';


const TopNavBar = () => {
    const { modal, locals } = useAppState((state) => state.local)
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const pageName = currentPageInfo.pageName;

    const listItemsRef = useRef();

    const handleDir = (dir) => {
        if (dir === 'left') {
            listItemsRef.current.scrollLeft -= 200;
        } else {
            listItemsRef.current.scrollLeft += 200;
        }
    }
    const dispatcher = useAppDispatcher()

    const handleRoleSwitch = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    return (
        <Container pageName={pageName} ref={listItemsRef}>
            <ArrowLeftIcon onClick={() => handleDir('left')} />
            <ListItems>
                {locals &&
                    <ListItem onClick={handleRoleSwitch}>
                        {locals.userRole === userRoles.STUDENT.value && <StudentIcon />}
                        {locals.userRole === userRoles.TUTOR.value && <TutorIcon />}
                        {userRoles[locals?.userRole?.toUpperCase()].label}
                    </ListItem>
                }
                {navLinkList.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                    <TopNavBarItem key={index} dataItem={dataItem} />
                ))}
            </ListItems>
            <ArrowRightIcon onClick={() => handleDir('right')} />
        </Container>
    )
}

export default TopNavBar;
