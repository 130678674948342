import React from "react";

//Style imports
import { StyledButton } from "./Button.styles";

export default function Button(props) {
  const { onClick, text, variant, loading } = props;
  return (
    <StyledButton
      onClick={onClick}
      variant={variant}
      loading={loading ? true : false}
      disabled={loading}
    >
      {text}
    </StyledButton>
  );
}
