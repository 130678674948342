export default function getTimestamps(rawDate) {
  const transformedDate = new Date(rawDate.seconds * 1000);
  const timeOptions = { hour: "numeric", minute: "numeric" };

  const diff = new Date() - transformedDate;
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  let timeStamp;

  if (days <= 1) {
    const time = transformedDate.toLocaleTimeString("en-Uk", timeOptions);
    timeStamp = `Today at ${time}`;
  }

  if (days > 1 && days <= 7) {
    const dateOptions = { weekday: "short" };
    const day = transformedDate.toLocaleDateString("en-Uk", dateOptions);
    const time = transformedDate.toLocaleTimeString("en-Uk", timeOptions);
    timeStamp = `${day} at ${time}`;
  }

  if (days > 7 && days <= 365) {
    const dateOptions = { month: "short", day: "numeric" };
    const day = transformedDate.toLocaleDateString("en-Uk", dateOptions);
    const time = transformedDate.toLocaleTimeString("en-Uk", timeOptions);
    timeStamp = `${day} at ${time}`;
  }

  if (days > 365) {
    const dateOptions = { month: "short", day: "numeric", year: "numeric" };
    const day = transformedDate.toLocaleDateString("en-Uk", dateOptions);
    timeStamp = `${day}`;
  }

  return timeStamp;
}
