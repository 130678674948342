import React from 'react'
import tw from 'twin.macro'
import CouponCard from './CouponCard'

const EdDiv = tw.div`flex justify-start items-start flex-wrap py-3 sm:w-full`;

const EdCard = tw.div
    `flex flex-col items-center space-y-4 flex-wrap justify-center box-border h-full  py-3 mb-32 overflow-x-auto bg-white lg:w-full sm:w-full sm:mx-0 md:mx-20 px-7
`;
const EdOffers = () => {
    return (
        <EdDiv >
            <EdCard>
                <h4 className='text-primary-dark font-bold'>Edulyte Offers</h4>
                <p className='font-semibold'>Use our available coupon code today</p>
                <CouponCard />
            </EdCard>
        </EdDiv>
    )
}

export default EdOffers