import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { MOCK_DASHBOARD_DATA } from "./data";
import { Schedule } from "./types";
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const DashboardSlice = createSlice({
  initialState: MOCK_DASHBOARD_DATA,
  name: "dashboard",
  reducers: {
    setSchedule: (state, action: PayloadAction<{ schedules: Schedule[] }>) => {
      const schedule = action.payload.schedules;
      const sortedSchedule = [...schedule].sort((a, b) => {
        const aTime = dayjs(a.from, "hh:m A");
        const bTime = dayjs(b.from, "hh:m A");

        if (aTime.isBefore(bTime)) {
          return -1;
        } else if (aTime.isAfter(bTime)) {
          return 1;
        }
        return 0;
      });
      
      state.schedules = sortedSchedule;
    },
    setSideOverPanel: (state, action: PayloadAction<{ status: boolean }>) => {
      state._meta.dashboardSideOverVisible = action.payload.status;
    },
  },
});

export default DashboardSlice.reducer;
export const { setSchedule, setSideOverPanel } = DashboardSlice.actions;
