import * as React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import ComponentLoader from "components/loader/ComponentLoader";
import OfferingCard from "components/offeringCard/OfferingCard"

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearOfferingList } from "redux/offering/offering.slice";
import { getOfferingList } from "redux/offering/offering.request";
import { getMyCourseUserListByUserId } from 'redux/course/course.request';
import { setClearMyCourseList } from 'redux/course/course.slice';
import { userRoles } from 'redux/auth/auth.const';

import { pagesInfo } from 'utils/pagesInfo';

export const MyOfferings: React.FC = () => {
  const { user } = useAppState((s: any) => s.user)
  const { offeringList } = useAppState((s: any) => s.offering)
  const { myCourseList } = useAppState((s: any) => s.course)
  const { locals } = useAppState((s: any) => s.local)

  const dispatcher = useAppDispatcher()

  React.useEffect(() => {
    if (locals.userRole === userRoles.STUDENT.value) {
      dispatcher(getMyCourseUserListByUserId(user?.user?.userId, { page: 1, records: 5 }))
    }

    if (locals.userRole === userRoles.TUTOR.value) {
      dispatcher(getOfferingList({ page: 1, records: 5 }))
    }

    return () => {
      dispatcher(setClearMyCourseList())
      dispatcher(setClearOfferingList())
    }
  }, [locals.userRole])

  return (
    <div className="w-full p-5 bg-white shadow-md rounded-lg">
      <div className="flex justify-between">
        <p className="text-xl text-text-900 font-medium font-bodyPri tracking-wider">
          {"My Classes"}
        </p>
        <p className="text-sm text-text-700 font-bodyPri">
          {locals.userRole === userRoles.STUDENT.value &&
            <Link to={pagesInfo.MY_COURSES.pagePath}>
              {"View all"}
            </Link>
          }
          {locals.userRole === userRoles.TUTOR.value &&
            <Link to={pagesInfo.OFFERINGS.pagePath + "?pageNo=1"}>
              {"View all"}
            </Link>
          }
        </p>
      </div>

      <div className={clsx(
        "grid grid-flow-col gap-8 px-4 py-4 overflow-hidden overflow-x-scroll",
        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
      )}>
        <ComponentLoader isLoading={offeringList?.isLoading} />
        {(locals.userRole === userRoles.STUDENT.value && myCourseList?.myCourseList?.records > 0) &&
          myCourseList?.myCourseList?.results?.map((courses: any, idx: any) => (
            <OfferingCard key={idx} offering={courses} />
          ))
        }
        {(locals.userRole === userRoles.TUTOR.value && offeringList?.offeringList?.records > 0) &&
          offeringList?.offeringList?.results?.map((offering: any, idx: any) => (
            <OfferingCard key={idx} offering={offering} />
          ))
        }
        {(myCourseList?.errorMsg || offeringList?.errorMsg) &&
          <span className="w-full font-bodyPri font-normal text-text-800 text-md text-center">
            {myCourseList?.errorMsg || offeringList?.errorMsg}
          </span>
        }
      </div>
    </div>
  );
};
