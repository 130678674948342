import clsx from 'clsx';

import { useAppState } from 'hooks/useStore';

import { dayjs, timeZone } from 'utils/dateTime.utils';


const EventDetailDateTimeSlot = () => {
    const { eventDetail } = useAppState(s => s.event)

    return eventDetail?.eventDetail ? (
        <div className={clsx(
            "flex flex-col items-center justify-center gap-2 p-3 mx-5",
            "bg-primary-light rounded-lg border-2 border-divider-medium",
            "font-bodyPri font-medium text-base text-primary-dark",
        )}>
            <span className={""}>
                {dayjs(eventDetail?.eventDetail?.startDateTime).tz(timeZone).format('dddd, DD MMM YYYY')}
            </span>
            <span className={""}>
                {dayjs(eventDetail?.eventDetail?.startDateTime).tz(timeZone).format('hh:mm A') + " - " + dayjs(eventDetail?.eventDetail?.endDateTime).tz(timeZone).format('hh:mm A')}
            </span>
        </div>
    ) : null
}

export default EventDetailDateTimeSlot