import { useEffect } from 'react';

import { PageContentStyle } from './HomePageStyle';

import Footer from 'components/footer/Footer';

// import HomeHero from './homeHero/HomeHero';
import Hero from "pages/global/home/hero/Hero";
import CategoryFeatured from './categoryFeatured/CategoryFeatured';
import PersonalisedTutoring from './personalisedTutoring/PersonalisedTutoring';
import LearningNeverStops from './learningNeverStops/LearningNeverStops';
import HowEdulyteWorks from './howEdulyteWorks/HowEdulyteWorks';
import LearningApp from './learningApp/LearningApp';
import Testimonials from './testimonials/Testimonials';
import FeaturedTutors from './featuredTutors/FeaturedTutors';
import BrandFeatured from './brandFeatured/BrandFeatured';
import BecomeTutor from './becomeTutor/BecomeTutor';
import Blogs from './blogs/Blogs';
import Subscribe from './subscribe/Subscribe';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { useAppDispatcher, useAppState } from 'hooks/useStore';

import { pagesInfo } from 'utils/pagesInfo';

const HomePage = () => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.HOME))
    }, [dispatcher, currentPageInfo])

    return (
        <PageContentStyle>
            {/* <HomeHero /> */}
            <Hero />
            <FeaturedTutors />
            <PersonalisedTutoring />
            <LearningNeverStops />
            <HowEdulyteWorks />
            <LearningApp />
            <Testimonials />
            <CategoryFeatured />
            <BrandFeatured />
            <BecomeTutor />
            <Blogs />
            <Subscribe />
            <Footer />
        </PageContentStyle>
    )
}

export default HomePage;