import { useState } from 'react';
import {
    Container,
    Content,
    Header,
    Heading,
    SubHeading,
    VideoButton,
    PlayVideoButton,
    ItemContainer,
    VideoModal,
} from './HowEdulyteWorksStyle';

import { workData } from './HowEdulyteWorksData';
import HowEdulyteWorksItem from './HowEdulyteWorksItem';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';

const EMBED_VIDEO_URL = 'https://youtube.com/embed/VQcG5LLxhGA';

const HowEdulyteWorks = () => {
    const { modal } = useAppState((s) => s.local)
    const dispatcher = useAppDispatcher()

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COMING_SOON_MODAL.stateKey]: true
        }))
    }
    const handleClose = () => setOpen(false);

    return (
        <>
            <VideoModal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <iframe
                    title={'Embeded Video'}
                    src={EMBED_VIDEO_URL}
                    frameBorder={'0'}
                />
            </VideoModal>
            <Container>
                <Content>
                    <Header>
                        <Heading>{'How Edulyte Works'}</Heading>
                        <SubHeading>{'Learn live with qualified and trusted tutors, anytime, anywhere'}</SubHeading>
                        <VideoButton onClick={handleOpen}>
                            {'See how it works'}
                            <PlayVideoButton />
                        </VideoButton>
                    </Header>
                    <ItemContainer>
                        {workData.map((workDataItem, index) => (
                            <HowEdulyteWorksItem key={index} workDataItem={workDataItem} />
                        ))}
                    </ItemContainer>
                </Content>
            </Container>
        </>
    )
}

export default HowEdulyteWorks
