import * as React from "react";
import { Button } from "components/common-components/Buttons";
import { useAppState } from "hooks/useStore";


export default function SubscriptionBillingInformation() {
  const state = useAppState((s) => s.subscriptions.billingInformation);
  return (
    <>
      {/* Billing Info */}
      <div className="flex-1 p-5 bg-white rounded-lg shadow-md">
        {/* Header */}
        <div className="flex justify-between">
          <p className="text-lg font-semibold text-gray-800">
            Billing Information
          </p>
          <Button name="Update" style="outline" size="small" />
        </div>
        {/* Body */}
        <div className="grid grid-cols-12 pt-4 gap-y-2">
          {
            <>
              <p className="col-span-5 font-semibold text-gray-600">Name</p>
              <p className="col-span-7 text-gray-600">{state.name}</p>
              <p className="col-span-5 font-semibold text-gray-600">Company</p>
              <p className="col-span-7 text-gray-600">{state.company}</p>
              <p className="col-span-5 font-semibold text-gray-600">
                Billing Address
              </p>
              <p className="col-span-7 text-gray-600 min-h-xs">
                {state.billingAddress}
              </p>
              <p className="col-span-5 font-semibold text-gray-600">GST</p>
              <p className="col-span-7 text-gray-600">{state.GST}</p>
            </>
          }
        </div>
      </div>
    </>
  );
}
