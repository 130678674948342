import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { FaSpinner } from 'react-icons/fa';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMakePaymentPayload } from 'redux/payment/payment.slice';
import { addMakePayment } from 'redux/payment/payment.request';

import UserInfoForm from 'components/modals/paymentMethodModal/UserInfoForm';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};


const PaymentForm = () => {
    const { makePayment } = useAppState(s => s.payment)
    const makePaymentPayload = makePayment?.makePaymentPayload

    const [error, setError] = useState({ emailError: '', cardError: '' })
    const [isPayDisable, setIsPayDisable] = useState(true)

    const dispatcher = useAppDispatcher();
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        setIsPayDisable(
            error.cardError.length !== 0 ||
            error.emailError.length !== 0 ||
            !makePaymentPayload?.email ||
            !makePaymentPayload?.firstName ||
            !makePaymentPayload?.lastName
        )
    }, [error, makePaymentPayload?.firstName, makePaymentPayload?.lastName, makePaymentPayload?.email])

    const handleCardOnChange = (event) => {
        if (event.error) {
            setError({
                ...error,
                cardError: event.error.message
            })
            setIsPayDisable(false)
        }
        else {
            setError({ ...error, cardError: '' });
        }
    }

    const onCeatePaymentMethod = async () => {
        try {
            const card = elements.getElement(CardElement)
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: card
            });
            if (error) {
                console.error(error)
                alert("Something went wrong!")
                return;
            }
            dispatcher(setMakePaymentPayload({
                ...makePaymentPayload,
                paymentMethodId: paymentMethod.id
            }))
            return paymentMethod.id
        } catch (error) {
            console.error(error)
            alert("Something went wrong!")
            return;
        }
    }

    const handlePay = async () => {
        if (isPayDisable || makePayment?.isLoading) return;

        const paymentMethodId = await onCeatePaymentMethod();
        if (!paymentMethodId) return;

        dispatcher(addMakePayment())
    }

    return (
        <div className={"block space-y-16"}>

            <div className={"block space-y-2"}>
                <span className={"text-start font-bodyPri font-medium text-base text-text-800"}>
                    {"Fill your details:"}
                </span>
                <div>
                    <UserInfoForm error={error} setError={setError} />
                </div>
                <div className={"w-full space-y-1"}>
                    <CardElement onChange={handleCardOnChange} options={CARD_ELEMENT_OPTIONS} />
                    <span className={"font-bodyPri font-normal text-xs text-red-500"}>
                        {error.cardError}
                    </span>
                </div>
                <div className={"w-full h-10"}>
                    {makePayment?.errorMsg &&
                        <div className={"flex items-center justify-start"}>
                            <span className={"font-bodyPri font-medium text-base text-red-500 text-center"}>
                                {makePayment?.errorMsg}
                            </span>
                        </div>
                    }
                    {makePayment?.isLoading &&
                        <div classsName={"flex items-center justify-start gap-2 whitespace-nowrap"}>
                            <span className={"font-bodyPri font-normal text-base text-text-700 whitespace-nowrap"}>
                                {"Please wait while processing ..."}
                            </span>
                            <FaSpinner className="inline-flex text-primary-main animate-spin" />
                        </div>
                    }
                </div>
            </div>

            <div className={"flex justify-end"}>
                <span onClick={handlePay} className={clsx(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    (isPayDisable || makePayment?.isLoading) && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {"Pay"}
                </span>
            </div>
        </div>
    )
}

export default PaymentForm;