import clsx from "clsx";

import { FaPlusCircle } from "react-icons/fa";

import { IconWithTextButton } from "components/common-components/Buttons";

import ReferenceListItems from "pages/auth/tutorOnboard/components/professionalReferences/ReferenceListItems";

import { useAppState, useAppDispatcher } from "hooks/useStore"
import { setClearAddReference } from "redux/tutorOnboarding/tutorOnboarding.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";





const ReferenceList = ({ task }) => {
    const { modal } = useAppState((s) => s.local)
    const { referenceList } = useAppState((s) => s.tutorOnboarding)
    const dispatcher = useAppDispatcher()

    const handleAddReference = () => {
        dispatcher(setClearAddReference())
        dispatcher(setModal({
            ...modal,
            [modalConst.REFERENCE_FORM_MODAL.stateKey]: true
        }))
    }


    return (
        <div className={clsx(referenceList?.referenceList?.length > 1 && "grid grid-cols-1 lg:grid-cols-2 w-full gap-5")}>
            {referenceList?.referenceList?.map((reference, index) => (
                <div className={clsx(referenceList?.referenceList?.length === 1 && "grid grid-cols-1 lg:grid-cols-2 place-items-center w-full gap-5")}>
                    <ReferenceListItems
                        key={index}
                        reference={reference}
                        task={task}
                    />
                    {referenceList?.referenceList?.length === 1 &&
                        <div className=''>
                            <IconWithTextButton
                                placeholder="Add New Reference"
                                className="text-primary-main bg-primary-light hover:bg-primary-main hover:text-text-50 font-bodyPri font-medium text-lg"
                                icon={<FaPlusCircle />}
                                onClick={handleAddReference}
                            />
                        </div>
                    }
                </div>
            ))}
        </div>
    )
}

export default ReferenceList;