import React from "react";

//Context imports
import { useUser } from "../../contexts/userContext";
import { useChat } from "../../contexts/chatContext";

//Helper functions
import getTimestamps from "../../helpers/getTimestamps";

//Component imports
import ChatViewMessageRead from "../ChatViewMessageRead/ChatViewMessageRead";

//Style imports
import {
  StyledChatViewCourse,
  StyledChatViewCourseText,
  StyledChatViewCourseTextTime,
  StyledCardHeader,
  StyledCardDescription,
  StyledCardLink,
  StyledChatViewCourseImage,
  StyledChatViewCourseImageContainer,
} from "./ChatViewCourse.styles";
import showProfilePicture from "../../helpers/showProfilePicture";

export default function ChatViewCourse({ message, isGroup }) {
  const { user } = useUser();
  const { selectedChat, messages, groupMembers } = useChat();
  const {
    sender,
    sentAt,
    status,
    course,
    description,
    courseUrl,
    classCoverImageUrl,
  } = message;
  const seen = status === "seen";

  const { userId } = user;

  const isSender = sender === userId;

  const timeToDisplay = getTimestamps(sentAt);

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  function getImageToDisplay() {
    if (selectedChat.type === "group") {
      const photoUrl = groupMembers.filter((member) => member.id === sender)[0]
        .image;
      return photoUrl;
    } else {
      return selectedChat[otherUser].image;
    }
  }

  const imageToDisplay = getImageToDisplay();

  const showPhoto = showProfilePicture(message, messages);

  return (
    <StyledChatViewCourse isSender={isSender}>
      {showPhoto && !isSender && (
        <img
          className="w-12 h-12 rounded-full mr-2"
          src={imageToDisplay}
          alt="profile"
        />
      )}
      {!showPhoto && <div className="w-12 h-12 rounded-full mr-2"></div>}

      <StyledChatViewCourseText isSender={isSender}>
        <StyledChatViewCourseImage>
          {classCoverImageUrl && (
            <StyledChatViewCourseImageContainer
              src={classCoverImageUrl}
              alt={course}
            />
          )}
        </StyledChatViewCourseImage>
        <StyledCardHeader isSender={isSender}>{course}</StyledCardHeader>
        {description && (
          <StyledCardDescription isSender={isSender}>
            {description}
          </StyledCardDescription>
        )}
        <StyledChatViewCourseTextTime isSender={isSender}>
          {timeToDisplay}
        </StyledChatViewCourseTextTime>
        {courseUrl && (
          <StyledCardLink href={courseUrl} target="blank" isSender={isSender}>
            Open
          </StyledCardLink>
        )}
      </StyledChatViewCourseText>
      {isSender && !isGroup && <ChatViewMessageRead seen={seen} />}
    </StyledChatViewCourse>
  );
}
