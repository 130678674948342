import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom'

import { MdOutlineVideoCall } from 'react-icons/md';
import { BiTimeFive } from 'react-icons/bi';
import { MdGroups } from 'react-icons/md';
import { IoIosStats } from 'react-icons/io';
import { MdOutlineAccountBalanceWallet } from 'react-icons/md';
import { BiCalendar } from 'react-icons/bi';
import { FaLanguage } from 'react-icons/fa';
import { FiMessageSquare } from 'react-icons/fi';
import { BsInfoCircle } from "react-icons/bs";
import { IoMdPeople } from "react-icons/io";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { getSelectedCourseByCourseId } from 'redux/course/course.request';
import { setModal } from 'redux/local/local.slice';
import { setChooseTimeSlotPayload, setClearAddBookingPayload } from 'redux/booking/booking.slice';
import { modalConst } from 'redux/local/local.const';
import { courseType } from 'redux/course/course.const';
import { chooseTimeSlotAction, weeklyTimeSlot } from 'redux/booking/booking.const';
import { setClearMessageTemplate, setMessageTemplate } from 'redux/firebase/firebase.slice';
import { createFBIndividulaChat, getFBChat, getFBChatId, sendFBMessage } from 'redux/firebase/firebase.request';

const CourseHeaderCard = (props) => {
    const { courseDetail, myCourseList } = useAppState((s) => s.course)
    const { addBooking } = useAppState(s => s.booking)
    const { modal } = useAppState((s) => s.local)
    const { logInSignUp } = useAppState(s => s.auth)
    const { user } = useAppState((s) => s.user)
    const { messageTemplate } = useAppState((s) => s.firebase)

    const dispatcher = useAppDispatcher()
    const navigate = useNavigate()

    const [navstick, setNavstick] = useState(false);

    const isMyOffering = user?.user?.userId === courseDetail?.courseDetail?.tutor?.userId
    const isMyCourse = myCourseList?.filteredCourseList?.results?.filter((course) => course?.courseType === courseType?.GROUP.value)
        ?.filter((myCourse) => myCourse?.courseId === courseDetail?.courseDetail?.courseId)?.length > 0


    const changeNav = () => {
        if (window.scrollY > 400 && window.innerWidth > 1024) {
            setNavstick(true);
        } else {
            setNavstick(false);
        }
    }

    const handleMessage = async () => {
        if (!logInSignUp?.isLogedIn) {
            dispatcher(setModal({
                ...modal,
                [modalConst.AUTHORIZATION_MODAL.stateKey]: true
            }))
            return;
        }

        if (isMyOffering) return;

        const userIdOne = user?.user?.userId
        const userIdTwo = courseDetail?.courseDetail?.tutor?.userId

        const chatId = await getFBChatId(userIdOne, userIdTwo)
        let fbChat = await getFBChat(chatId)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                userIdOne: userIdOne,
                userIdTwo: userIdTwo,
                message: '',
                errorMsg: null
            }))
            dispatcher(setModal({
                ...modal,
                [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: true
            }))
            return;
        }

        navigate(`/message/${fbChat.chatId}`)
    }

    useEffect(() => {
        if (messageTemplate?.message && user?.user?.userId === messageTemplate?.userIdOne && courseDetail?.courseDetail?.tutor?.userId === messageTemplate?.userIdTwo) {
            createFBChatAndRedirect()
        }
    }, [messageTemplate?.message])

    const createFBChatAndRedirect = async () => {
        const fbChat = await createFBIndividulaChat(messageTemplate?.userIdOne, messageTemplate?.userIdTwo)

        if (!fbChat || fbChat === undefined) {
            dispatcher(setMessageTemplate({
                ...messageTemplate,
                isLoading: false,
                errorMsg: "Chat not exist!",
            }))
            return;
        }

        await sendFBMessage({
            senderUserId: user?.user?.userId,
            text: messageTemplate?.message,
            chatId: fbChat.chatId
        })

        dispatcher(setClearMessageTemplate())
        dispatcher(setModal({
            ...modal,
            [modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]: false
        }))

        navigate(`/message/${fbChat.chatId}`)
    }

    const openModal = () => {
        dispatcher(getSelectedCourseByCourseId(courseDetail?.courseDetail?.courseId))
        dispatcher(setClearAddBookingPayload())
        if (courseDetail?.courseDetail?.courseType === courseType.GROUP.value) {
            dispatcher(setModal({ ...modal, [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: true }))
        }

        if (courseDetail?.courseDetail?.courseType === courseType.ONE_ON_ONE.value) {
            dispatcher(setModal({ ...modal, [modalConst.NO_SESSION_MODAL.stateKey]: true }))
        }
    }

    const handleBookNow = () => {
        if (isMyCourse || isMyOffering) return;

        if (courseDetail?.courseDetail?.courseType === courseType.GROUP.value) {
            dispatcher(getSelectedCourseByCourseId(courseDetail?.courseDetail?.courseId))
            dispatcher(setClearAddBookingPayload())
            dispatcher(setModal({ ...modal, [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true }))
        }

        if (courseDetail?.courseDetail?.courseType === courseType.ONE_ON_ONE.value) {
            openModal()
        }
    }

    const handleTrialBooking = () => {
        if (isMyOffering) return;

        dispatcher(getSelectedCourseByCourseId(courseDetail?.courseDetail?.tutor?.trialCourse?.courseId))
        dispatcher(setClearAddBookingPayload())
        dispatcher(setChooseTimeSlotPayload({
            ...addBooking?.chooseTimeSlotPayload,
            chooseTimeSlotAction: chooseTimeSlotAction.BOOKING.value,
            actionItem: { ...addBooking?.chooseTimeSlotPaload?.actionItem, courseId: courseDetail?.courseDetail?.courseId },
            sessionDuration: courseDetail?.courseDetail?.tutor?.trialCourse?.sessionDuration,
            noOfSessions: 1,
            startDate: null,
            endDate: null,
            weeklyTimeSlot: weeklyTimeSlot
        }))
        dispatcher(setModal({ ...modal, [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true }))
    }

    const DISCOUNTED_PRICE = courseDetail?.courseDetail?.price - (courseDetail?.courseDetail?.price * courseDetail?.courseDetail?.discountPct) / 100

    const absoNav = ` ${props.position} object-bottom bg-white rounded-b-xl shadow-md p-3 px-4 flex flex-col gap-3`;
    const fixedNav = "fixed w-80 z-60 top-0 mt-24 object-bottom bg-white rounded-b-xl shadow-md p-3 px-4 flex flex-col gap-3"
    window.addEventListener('scroll', changeNav);

    return (
        <div className={navstick ? fixedNav : absoNav}>

            <div className="flex items-center gap-2 w-full">
                <div className='flex justify-center items-center gap-2'>
                    <span className='text-lg text-text-800'>{"USD"}</span>
                    <span className='text-2xl text-text-900'>{parseFloat(DISCOUNTED_PRICE / 100).toFixed(2)}</span>
                </div>
                <div className='flex flex-col'>
                    <span className='text-text-900 font-semibold'>
                        <del className='text-text-600'>
                            {"USD " + parseFloat(courseDetail?.courseDetail?.price / 100).toFixed(2)}
                        </del>
                        {" " + courseDetail?.courseDetail?.discountPct + "% off"}
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                        {courseDetail?.courseDetail?.courseType === "group" ? "per session" : "per hour"}
                    </span>
                </div>
            </div>
            <div className='flex items-center w-full justify-evenly'>
                <span onClick={handleBookNow} className={clsx(
                    'w-2/3 py-1 flex justify-center items-center gap-1 rounded-full font-normal font-buttons border',
                    isMyOffering
                        ? "border-text-300 text-text-300"
                        : isMyCourse
                            ? "border-text-500 text-text-700"
                            : "border-secondary-main text-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                )}>
                    {isMyCourse && <BsInfoCircle className={"text-primary-main text-base"} />}
                    <span>
                        {isMyCourse ? "Course Purchased" : "Book Now"}
                    </span>
                </span>
                <span onClick={handleMessage} className={clsx(
                    'text-3xl',
                    isMyOffering
                        ? "text-text-300"
                        : "text-text-700 hover:text-primary-main cursor-pointer"
                )}>
                    <FiMessageSquare />
                </span>
            </div>

            <div className="flex flex-col gap-2 pt-2">

                {courseDetail?.courseDetail?.courseType === courseType.ONE_ON_ONE.value &&
                    <div className='flex justify-start gap-3'>
                        <IoMdPeople className="text-gray-700 text-xl" />
                        <span className="text-text-700 font-normal font-bodyPri">
                            {"Class Type:"}
                        </span>
                        <span className='text-text-900 font-medium font-bodyPri'>
                            {courseType.ONE_ON_ONE.label}
                        </span>
                    </div>
                }
                {courseDetail?.courseDetail?.courseType === courseType.GROUP.value &&
                    <div className='flex justify-start gap-3'>
                        <MdGroups className="text-gray-700 text-xl" />
                        <span className="text-text-700 font-normal font-bodyPri">
                            {"Class Type:"}
                        </span>
                        <span className='text-text-900 font-medium font-bodyPri'>
                            {courseType.GROUP.label}
                        </span>
                    </div>
                }

                <div className='flex justify-start gap-3'>
                    <IoIosStats className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {"Level:"}
                    </span>
                    <span className='text-text-900 font-medium font-bodyPri'>
                        {courseDetail?.courseDetail?.proficiency}
                    </span>
                </div>

                <div className="flex justify-start gap-3">
                    <FaLanguage className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {"Language:"}
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                        {courseDetail?.courseDetail?.language}
                    </span>
                </div>

                <div className="flex justify-start gap-3">
                    <BiTimeFive className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {"Session Duration:"}
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                        {courseDetail?.courseDetail?.courseType === "group"
                            ? courseDetail?.courseDetail?.sessionDuration + " min"
                            : "upto 2 hrs"
                        }
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                    </span>
                </div>

                {courseDetail?.courseDetail?.courseType === "group" &&
                    <div className="flex justify-start gap-3">
                        <MdOutlineVideoCall className="text-gray-700 text-xl" />
                        <span className="text-text-700 font-normal font-bodyPri">
                            {"Session Count:"}
                        </span>
                        <span className="text-text-900 font-medium font-bodyPri">
                            {courseDetail?.courseDetail?.noOfSessions}
                        </span>
                    </div>
                }

                {courseDetail?.courseDetail?.courseType === "group" &&
                    <div className="flex justify-start gap-3">
                        <BiTimeFive className="text-gray-700 text-xl" />
                        <span className="text-text-700 font-normal font-bodyPri">
                            {"Total Duration:"}
                        </span>
                        <span className="text-text-900 font-medium font-bodyPri">
                            {courseDetail?.courseDetail?.totalDuration < 60
                                ? courseDetail?.courseDetail?.totalDuration + " min"
                                : Math.ceil(courseDetail?.courseDetail?.totalDuration / 60) + " hrs"
                            }
                        </span>
                    </div>
                }

                {courseDetail?.courseDetail?.courseType === "group" &&
                    <div className="flex justify-start gap-3">
                        <MdGroups className="text-gray-700 text-xl" />
                        <span className="text-text-700 font-normal font-bodyPri">
                            {"Participants:"}
                        </span>
                        <span className="text-text-900 font-medium font-bodyPri">
                            {courseDetail?.courseDetail?.studentCapacity + " max"}
                        </span>
                    </div>
                }

                <div className="flex justify-start gap-3">
                    <MdOutlineAccountBalanceWallet className="text-gray-700 text-xl" />
                    <span className="text-gray-600 mr-3 font-bodyPri">
                        {"Total Cost:"}
                    </span>
                    <span className="text-text-900 font-medium font-bodyPri">
                        {"USD " + parseFloat((DISCOUNTED_PRICE * courseDetail?.courseDetail?.noOfSessions) / 100).toFixed(2)}
                    </span>
                </div>

                <div className="flex justify-start gap-3">
                    <BiCalendar className="text-gray-500 text-lg" />
                    <span className="text-text-700 font-normal font-bodyPri">
                        {courseDetail?.courseDetail?.courseType === "group"
                            ? "Class Time:"
                            : "Availability"
                        }
                    </span>
                    <span className="font-medium font-bodyPri text-primary-main underline cursor-pointer hover:text-secondary-main"
                        onClick={openModal}
                    >
                        {courseDetail?.courseDetail?.courseType === "group"
                            ? "See schedule"
                            : "See calendar"
                        }
                    </span>
                </div>

                <div className="flex justify-start gap-3">
                    <MdOutlineVideoCall className="text-gray-700 text-xl" />
                    <span className="text-text-700 font-normal font-bodyPri whitespace-nowrap">
                        {"Trial Session:"}
                    </span>
                    <div className="text-text-900 font-medium font-bodyPri flex gap-1 whitespace-nowrap">
                        <span>
                            {courseDetail?.courseDetail?.tutor?.trialCourse?.price !== 0 ? "USD " + parseFloat(courseDetail?.courseDetail?.tutor?.trialCourse?.price / 100).toFixed(2) : "Free"}
                        </span>
                        <span>({courseDetail?.courseDetail?.tutor?.trialCourse?.sessionDuration} {"min"})</span>
                    </div>
                </div>

                <hr className="w-full bg-gray-900" />

                <div className='flex flex-col gap-2 pt-2'>
                    <span onClick={handleTrialBooking} className={clsx(
                        'w-full py-0.5 flex justify-center items-center font-buttons rounded-full font-normal',
                        isMyOffering
                            ? "border border-text-300 text-text-300"
                            : "border border-text-700 text-gray-700 hover:border hover:border-secondary-main hover:bg-secondary-main hover:text-text-50 cursor-pointer"
                    )}>
                        {"Book a Trial"}
                    </span>
                    <div className='flex flex-col justify-center items-center p-2 bg-yellow-300 font-bold'>
                        <span>{"Enroll Now"}</span>
                        {courseDetail?.courseDetail?.courseType === "group" && <span>{"Starts on " + dayjs(courseDetail?.courseDetail?.startDate).format("DD MMMM, YYYY")}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CourseHeaderCard