import updateGroupMessageStatus from "./updateGroupMessageStatus";
import updateMessageStatus from "./updateMessageStatus";

export default function markMessagesRead(
  isGroup,
  groupMembers,
  messages,
  selectedChat,
  user
) {
  if (isGroup) {
    const currentMember = groupMembers.filter(
      (groupMember) => groupMember.id === user.userId
    )[0];
    updateGroupMessageStatus(currentMember, selectedChat);
  } else {
    const unreadMessages = messages.filter(
      (message) => message.status === "sent" && message.sender !== user.userId
    );
    if (unreadMessages.length > 0) {
      const unreadMessageIds = unreadMessages.map((message) => message.id);
      updateMessageStatus(unreadMessageIds, selectedChat.id);
    }
  }
}
