import React from "react";

//Context imports
import { useUser } from "../../contexts/userContext";
import { useChat } from "../../contexts/chatContext";

//Helpers functions
import getTimestamps from "../../helpers/getTimestamps";

//Component imports
import ChatViewFileImage from "./ChatViewFileImage";
import ChatViewMessageRead from "../ChatViewMessageRead/ChatViewMessageRead";
import ChatViewFileDocument from "./ChatViewFileDocument";

//Style imports
import { StyledChatViewFile } from "./ChatViewFile.styles";
import showProfilePicture from "../../helpers/showProfilePicture";

//icon imports
import placeholder from "../../icons/placeholder.png";

export default function ChatViewFile({ message, isGroup }) {
  const { user } = useUser();
  const { selectedChat, messages, groupMembers } = useChat();
  const { fileType, fileName, url, sender, status, fileSize, sentAt } = message;
  const isImage = fileType.includes("image");

  const { userId } = user;
  const isSender = userId === sender;
  const seen = status === "seen";

  const timeToDisplay = getTimestamps(sentAt);

  const otherUser = selectedChat.users.filter(
    (chatUser) => chatUser !== userId
  )[0];

  function getImageToDisplay() {
    if (selectedChat.type === "group") {
      const member = groupMembers?.filter((member) => member.id === sender)[0];
      const photoUrl = member ? member.image : placeholder;
      return photoUrl;
    } else {
      return selectedChat[otherUser].image;
    }
  }

  const imageToDisplay = getImageToDisplay();

  const showPhoto = showProfilePicture(message, messages);

  return (
    <StyledChatViewFile isSender={isSender}>
      {showPhoto && !isSender && (
        <img
          className="w-12 h-12 rounded-full mr-2"
          src={imageToDisplay}
          alt="profile"
        />
      )}
      {!showPhoto && <div className="w-12 h-12 rounded-full mr-2"></div>}
      {isImage && (
        <ChatViewFileImage
          fileName={fileName}
          url={url}
          timeToDisplay={timeToDisplay}
          isSender={isSender}
        />
      )}
      {!isImage && (
        <ChatViewFileDocument
          fileName={fileName}
          url={url}
          fileSize={fileSize}
          timeToDisplay={timeToDisplay}
          isSender={isSender}
        />
      )}
      {isSender && !isGroup && <ChatViewMessageRead seen={seen} />}
    </StyledChatViewFile>
  );
}
