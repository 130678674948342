import MUITabs from "components/MUITabs";

import Container from "pages/global/tutor/Container";

const Tabs = ({ data, ...props }) => {
  return (
    <Container
      id={props.id}
      leftSide={
        <>
          <MUITabs tabs={data.tabs} containerIDs={props.containerIDs} />
        </>
      }
      rightSide={null}
    />
  );
};

export default Tabs;
