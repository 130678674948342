import clsx from "clsx";
import * as React from "react";
import { MdEdit } from 'react-icons/md'
import { FaChevronRight, FaPenAlt } from "react-icons/fa";
import { useAppState } from "hooks/useStore";

export const SegmentCategoryView: React.FC<{ onEdit: () => void }> = ({
  onEdit,
}) => {
  const { offeringCourse } = useAppState((s) => s.offering)

  return (
    <div
      className={clsx(
        "relative flex p-2 space-x-2 text-white border border-secondary-dark border-dashed",
        "items-center w-min pr-10 overflow-x-scroll md:overflow-x-hidden"
      )}
    >
      <button className="absolute top-0 right-0 p-2" onClick={() => onEdit()}>
        <MdEdit className="text-lg text-secondary-dark" />
      </button>
      <p className="hidden text-xs font-medium md:block md:text-sm">Browse</p>
      <div className="hidden md:block">
        <FaChevronRight className="w-3 h-3 text-slate-200" />
      </div>
      <p className="text-xs font-medium md:text-sm whitespace-nowrap">
        {offeringCourse?.offeringCourse?.segment || "None"}
      </p>
      <div>
        <FaChevronRight className="w-3 h-3 text-slate-200" />
      </div>
      <p className="text-xs font-medium md:text-sm whitespace-nowrap">
        {offeringCourse?.offeringCourse?.category || "None"}
      </p>
    </div>
  );
};