import React from "react";

//Style imports
import { StyledCourseMenu } from "./CourseMenu.styles";

export default function CourseMenu({
  setShowPaymentRequest,
  setShowCourseMenu,
  setShareCourse,
}) {
  function openPaymentRequestModal() {
    setShowCourseMenu(false);
    setShowPaymentRequest(true);
  }

  function openShareCourseModal() {
    setShowCourseMenu(false);
    setShareCourse(true);
  }
  return (
    <StyledCourseMenu>
      <div onClick={openPaymentRequestModal}>
        <p>Request Payment</p>
      </div>
      <div onClick={openShareCourseModal}>
        <p>Share Course</p>
      </div>
    </StyledCourseMenu>
  );
}
