import {
    StarsContainer,
    FullStar,
    HalfStar,
    EmptyStar,
} from './RatingStarsStyle';

const RatingStars = ({ rating, fullRating, size = 1 }) => {
    const fullStar = new Array(parseInt(rating))?.fill(' ');
    const halfStar = rating % parseInt(rating) === 0 ? parseInt(rating) : parseInt(rating) + 1;
    const emptyStar = new Array(parseInt(fullRating) - (halfStar)).fill(' ');

    return (
        <StarsContainer>
            {fullStar?.map((_, index) => (
                <FullStar key={index} size={size} />
            ))}
            {(rating % parseInt(rating) !== 0) && (
                <HalfStar size={size} />
            )}
            {emptyStar?.map((_, index) => (
                <EmptyStar key={index} size={size} />
            ))}
        </StarsContainer>
    )
}

export default RatingStars
