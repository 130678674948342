import * as React from "react";
import Modal from "components/common-components/Modal";
import SelectSearch, { fuzzySearch } from "react-select-search";
import { useAppDispatcher, useAppState } from "hooks/useStore";
import clsx from "clsx";
import "./styles.css";
import { FaTimes } from "react-icons/fa";
import { getAllCategoryList } from "redux/category/category.request";
import { setOfferingCourse } from "redux/offering/offering.slice";
import ComponentLoader from "components/loader/ComponentLoader";
import { setClearCategoryList } from "redux/category/category.slice";

export type CategoryOption = {
  categoryId: number;
  category: string;
  segmentId: number;
  segment: string;
};

const CategorySelectionSection: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const { offeringCourse } = useAppState((s) => s.offering)
  const { categoryList } = useAppState((s) => s.category)
  const dispatcher = useAppDispatcher();

  const [selectedCategory, setCategory] = React.useState<any>();

  React.useEffect(() => {
    dispatcher(getAllCategoryList())

    return () => {
      dispatcher(setClearCategoryList())
    }
  }, [])

  const onConfirm = () => {
    if (selectedCategory) {
      const selectedSegment = categoryList?.categoryList?.find(
        (category: any) => category?.category === selectedCategory
      );
      dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, category: selectedCategory, segment: selectedSegment?.segment }))
      onClose();
    }
    else {
      alert("Please select category");
    }
  };

  return (
    <div
      className={clsx(
        "w-[280px] sm:w-[400px]",
        "relative max-w-lg overflow-visible"
      )}
    >
      <p className="mb-5 text-2xl font-medium text-center md:text-3xl">
        {"Select a category"}
      </p>
      <div className="absolute top-0 right-0 transition-opacity opacity-30 hover:opacity-95">
        <button
          className={clsx(
            "hover:scale-95 transition-transform bg-red-400",
            "rounded-full flex items-center w-6 h-6 justify-center"
          )}
          onClick={onClose}
        >
          <FaTimes className="w-4 h-4 text-red-50" />
        </button>
      </div>
      {categoryList?.isLoading
        && <ComponentLoader isLoading={categoryList?.isLoading} />
      }
      {categoryList?.categoryList &&
        <div
          className={clsx(
            "max-w-md mx-auto",
            "flex flex-col justify-between space-x-5 items-center md:flex-row"
          )}
        >
          <div className="w-full z-[1000]">
            <input type="hidden" autoFocus={true} />
            <SelectSearch
              options={categoryList?.categoryList?.map((category: any) => ({
                name: category.category,
                value: category.category,
              }))}
              search
              autoFocus={false}
              placeholder="Select Category"
              filterOptions={fuzzySearch}
              value={selectedCategory}
              onChange={(option: any) => {
                setCategory(option);
              }}
            />
          </div>
          <div className={clsx("mt-10 md:mt-0", "flex justify-end items-center")}>
            <button
              className={clsx(
                "py-1 px-4 rounded-lg text-center text-white whitespace-nowrap",
                "bg-primary-main hover:scale-95 transition-transform"
              )}
              onClick={onConfirm}
            >
              {"Confirm"}
            </button>
          </div>
        </div>
      }
      {categoryList?.errorMsg &&
        <span className={"font-bodyPri font-normal text-text-800 text-base tracking-wide"}>
          {categoryList?.errorMsg}
        </span>
      }
    </div>
  );
};

export const CategorySelectionModal: React.FC<{
  show: boolean;
  onClose: () => void;
}> = ({ onClose, show }) => {
  return (
    <Modal isVisible={show} onClose={() => { }}>
      <div className="overflow-visible md:pb-4">
        <CategorySelectionSection onClose={onClose} />
      </div>
    </Modal>
  );
};
