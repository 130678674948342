import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import { pageHeading } from 'pages/auth/offerings/data';

import { OfferingSearch } from "pages/auth/offerings/OfferingSearch";
import FilterSection from "pages/auth/offerings/FilterSection";
import OfferCard from "pages/auth/offerings/OfferCard";

import PageHeader from "components/pageHeader/PageHeader";
import OfferingCourseLoader from "components/loader/OfferingCourseLoader";
import Pagination from "components/pagination/Pagination";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { getOfferingList } from "redux/offering/offering.request"
import { setClearOfferingList } from "redux/offering/offering.slice";
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';
import { generateQueryParams } from 'utils/generators.utils';

const RECORDS = 5


const OfferingsPage = () => {
  const { offeringList } = useAppState((s) => s.offering)
  const { currentPageInfo } = useAppState((s) => s.pageInfo)

  const dispatcher = useAppDispatcher()
  const navigate = useNavigate()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState({ lable: 'All', value: 'all' })

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.OFFERINGS))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {
    const query = generateQueryParams(location.search)

    dispatcher(getOfferingList({ page: query.pn || 1, records: RECORDS }))

    return () => {
      dispatcher(setClearOfferingList())
    }
  }, [location?.search])

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen space-y-3">
      <PageHeader pageHeading={pageHeading} />
      <OfferingSearch />
      <FilterSection
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {offeringList?.filteredOfferingList?.results?.map((offering) => (
        <OfferCard offering={offering} />
      ))}
      {new Array(RECORDS).fill("").map((_, index) => <OfferingCourseLoader key={index} isLoading={offeringList?.isLoading} />)}
      {offeringList?.filteredOfferingList && offeringList?.filteredOfferingList?.records > 0 &&
        <div className="w-full flex justify-center items-center">
          <Pagination
            page={offeringList?.filteredOfferingList?.page}
            totalPages={offeringList?.filteredOfferingList?.totalPages}
            onChangePage={(page) => navigate(pagesInfo.OFFERINGS.pagePath + "?pn=" + page)}
          />
        </div>
      }
      {(offeringList?.filteredOfferingList?.length === 0 || offeringList?.errorMsg) &&
        <div className={"w-full h-full font-bodyPri font-semibold text-text-800 text-md"}>
          {offeringList?.errorMsg}
        </div>
      }
    </div>
  );
};

export default OfferingsPage;
