import { useAppState } from 'hooks/useStore'

const SkillsDiv = () => {
    const { courseDetail } = useAppState((s) => s.course)

    return (
        <div className='space-y-3'>
            <h3 className="text-xl font-medium font-bodyPri tracking-wide text-text-900">{"Skills you will gain"}
            </h3>
            <div className={'flex gap-5 flex-wrap px-3'}>
                {courseDetail?.courseDetail?.skills?.map((skill, index) => (
                    <span key={index}
                        className={'bg-primary-light py-1 px-3 rounded text-text-900 font-bodyPri font-normal text-base'}
                    >
                        {skill?.skill}
                    </span>
                ))}
            </div>

        </div>
    )
}

export default SkillsDiv
