import { useState } from "react";
import clsx from "clsx";

import { FaTimesCircle } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";

import TimeRangeSelection from "./TimeRangeSelection";
import TimeRange from "./TimeRange";

import { IconWithTextButton } from "components/common-components/Buttons";

import { useAppState, useAppDispatcher } from 'hooks/useStore';

import { dayjs, timeZone } from "utils/dateTime.utils";
import { setAvailability } from "redux/availability/availability.slice";

import { validateIsEndBeforeStart, validateIsTimeSlotExist, getIsTimeSlotBooked } from 'utils/availability.utils';


const OffDaysAvailabilityCard = ({ offDay }) => {
  const { availability } = useAppState(s => s.availability)
  const { userEventList } = useAppState(s => s.event)

  const dispatcher = useAppDispatcher()

  const [isAddNew, setIsAddNew] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const handleOnDeleteOffDay = () => {
    const offDays = availability?.availability?.offDays?.filter(item => item.date !== offDay?.date)
    dispatcher(setAvailability({
      ...availability?.availability,
      offDays: [...offDays]
    }))
  }

  const handleOnAdd = () => {
    if (isAddNew) {
      setSelectedTimeSlot(null)
    } else {
      setSelectedTimeSlot({
        startTime: dayjs().utc().format('HH:[00:00]'),
        endTime: dayjs().utc().format('HH:[15:00]')
      })
    }
    setIsAddNew(!isAddNew)
  }

  const handleOnCreate = () => {
    const day = dayjs(offDay.date).utc().format("dddd")
    if (validateIsEndBeforeStart(day, selectedTimeSlot)) {
      alert("End time should be after the start time!")
      return;
    }

    if (validateIsTimeSlotExist(day, selectedTimeSlot, offDay?.timeSlots)) {
      alert("Slot is already exist!")
      return;
    }

    const offDayDateTime = {
      start: dayjs(offDay.date + " " + selectedTimeSlot.startTime + "+00:00").utc().format(),
      end: dayjs(offDay.date + " " + selectedTimeSlot.endTime + "+00:00").utc().format()
    }

    if (getIsTimeSlotBooked(offDayDateTime, userEventList?.userEventList)) {
      alert("Slot are overlapping with booked session!")
      return;
    }

    let newOffDays = []
    availability?.availability?.offDays?.forEach(item => {
      if (item.date === offDay?.date) {
        item = {
          name: item.name,
          date: item.date,
          timeSlots: [...item.timeSlots, selectedTimeSlot]
        }
      }
      newOffDays.push(item)
    })

    dispatcher(setAvailability({
      ...availability?.availability,
      offDays: newOffDays
    }))
    setIsAddNew(false)
  }

  const handleOnUpdate = (oldTimeSlot, newTimeSlot) => {
    const day = dayjs(offDay.date).utc().format("dddd")
    if (validateIsEndBeforeStart(day, newTimeSlot)) {
      alert("End time should be after the start time!")
      return;
    }

    let timeSlots = offDay.timeSlots.filter(slot => oldTimeSlot.startTime !== slot.startTime)

    if (validateIsTimeSlotExist(day, newTimeSlot, timeSlots)) {
      alert("Slot is already exist!")
      return;
    }

    const offDayDateTime = {
      start: dayjs(offDay.date + " " + newTimeSlot.startTime + "+00:00").utc().format(),
      end: dayjs(offDay.date + " " + newTimeSlot.endTime + "+00:00").utc().format()
    }

    if (getIsTimeSlotBooked(offDayDateTime, userEventList?.userEventList)) {
      alert("Slot are overlapping with booked session!")
      return;
    }

    let newOffDays = []
    availability?.availability?.offDays?.forEach(item => {
      if (item.name === offDay?.name && item.date === offDay?.date) {
        item = {
          name: item.name,
          date: item.date,
          timeSlots: item.timeSlots.map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
          ))
        }
      }
      newOffDays.push(item)
    })

    dispatcher(setAvailability({
      ...availability?.availability,
      offDays: newOffDays
    }))
  }

  const handleOnDelete = (selectedSlot) => {
    let newOffDays = []
    availability?.availability?.offDays?.forEach(item => {
      if (item.name === offDay?.name && item.date === offDay?.date) {
        item = {
          name: item.name,
          date: item.date,
          timeSlots: item.timeSlots?.filter(timeSlot => timeSlot.startTime !== selectedSlot.startTime && timeSlot.endTime !== selectedSlot.endTime)
        }
      }
      newOffDays.push(item)
    })

    dispatcher(setAvailability({
      ...availability?.availability,
      offDays: newOffDays
    }))
  }

  return (
    <div className="p-4 border rounded-md">
      <div>
        <div className="w-full p-4 bg-blue-100 rounded-lg shadow-md">
          <div className="flex justify-between">
            <p className="font-bodyPri font-medium text-lg text-text-900 truncate">
              {dayjs(offDay.date).tz(timeZone).format(`dddd, DD MMM YYYY[ - ${offDay?.name}]`)}
            </p>
            <div>
              <button onClick={handleOnAdd} className={clsx(
                "inline-flex items-center justify-center p-2 ",
                "transform rounded-full hover:bg-blue-200 hover:scale-105"
              )}>
                <FaPlusCircle size={20} className={"text-blue-400 hover:text-blue-600"} />
              </button>
              <button onClick={handleOnDeleteOffDay} className={clsx(
                "inline-flex items-center justify-center p-2 ",
                "transform rounded-full hover:bg-red-200 hover:scale-105"
              )}>
                <FaTimesCircle size={20} className={"text-red-400 hover:text-red-600"} />
              </button>
            </div>
          </div>
        </div>

        <div className="px-2 divide-y divide-blue-200 bg-blue-50 rounded-b-xl">

          {/* Edit/View existing schedules */}
          {offDay?.timeSlots && offDay?.timeSlots.length > 0 ? (
            offDay?.timeSlots.map((timeSlot, index) => (
              <TimeRange
                key={index}
                day={dayjs(offDay.date).utc().format("dddd")}
                timeSlot={timeSlot}
                updateTimeSlot={handleOnUpdate}
                deleteTimeSlot={handleOnDelete}
              />
            ))
          ) : (
            <div className="py-5">
              <p className="font-bodyPri font-semibold text-xl text-text-500 text-center">
                No Schedules Found
              </p>
            </div>
          )}

          {/* Add new schedule */}
          {isAddNew && (
            <div className="flex flex-col lg:flex-row items-center w-full p-4 space-x-4 space-y-2 lg:space-y-0 ">
              <TimeRangeSelection
                timeSlot={selectedTimeSlot}
                setTimeSlot={setSelectedTimeSlot}
              />
              <div className="flex mt-4 space-x-4 md:mt-0">
                <IconWithTextButton
                  icon={<FaCheck />}
                  placeholder="Create"
                  className="text-green-700 bg-green-300 border shadow-sm"
                  onClick={handleOnCreate}
                />
                <IconWithTextButton
                  icon={<FaTimes />}
                  placeholder="Cancel"
                  className="bg-white border shadow-sm"
                  onClick={handleOnAdd}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OffDaysAvailabilityCard;