import React from 'react';

// Components
import CircleNumber from 'components/CircleNumber';
import MyTypography from 'components/Typography';

const NumberCard = (props) => {

    return (
        <div className="bg-white rounded-2xl flex flex-row items-center justify-start py-1.5 px-3">
            <CircleNumber num={props.num} textCSS="text-text-700 text-2xl" circleCSS="bg-background-medium"></CircleNumber>
            <MyTypography className="text-lg font-medium text-text-600 ml-2 text-center">{props.title}</MyTypography>
        </div>
    )
}

export default NumberCard;