import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import { companyNameConst } from "pages/global/company/data";

import { useAppState } from "hooks/useStore";

const CompanyInformation = () => {
    const { companyPublicProfile } = useAppState((state) => state.company)

    const { companyName } = useParams()

    const [videoUrl, setVideoUrl] = useState("")

    useEffect(() => {
        setVideoUrl(`https://www.youtube.com/embed/${companyPublicProfile?.companyPublicProfile?.videoUrl}`)
    }, [companyPublicProfile?.companyPublicProfile?.videoUrl])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0 pb-1"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full bg-white p-5"}>
                <div className={"relative w-full h-20"}>
                    <div className={"w-full absolute -top-24 z-30 flex justify-between items-center"}>
                        <div className={clsx(
                            "w-32 h-fit shadow-all bg-white",
                            !companyPublicProfile?.companyPublicProfile?.companyLogo && "p-2"
                            )}>
                            {companyPublicProfile?.companyPublicProfile?.companyLogo &&
                                <img
                                    src={companyPublicProfile?.companyPublicProfile?.companyLogo}
                                    className={"w-full h-full object-cover"}
                                    alt={"company-logo"}
                                />
                            }
                            {!companyPublicProfile?.companyPublicProfile?.companyLogo &&
                                <span className={"font-bodyPri font-semibold text-text-800 text-md capitalize"}>
                                    {companyPublicProfile?.companyPublicProfile?.companyName?.replaceAll("_", " ")}
                                </span>
                            }
                        </div>
                        <div className={"h-40"}>
                            <div className={"hidden md:flex flex-col items-start justify-center"}>
                                <div className="rounded-t-lg overflow-hidden w-96 h-40">
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={videoUrl}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                                    </iframe>
                                </div>
                                <div className={"w-full px-2 py-2 flex justify-center items-center gap-5 bg-white rounded-b-lg shadow-xl"}>
                                    <div
                                        className={clsx
                                            ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                                "border border-text-800 text-text-800",
                                                "font-buttons font-normal text-base",
                                                "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                            )}
                                    >
                                        {"Message"}
                                    </div>
                                    <div
                                        className={clsx
                                            ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                                "border border-text-800 text-text-800",
                                                "font-buttons font-normal text-base",
                                                "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                            )}>
                                        {"More"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col items-start justify-center gap-1"}>
                    <span className={"font-bodyPri font-semibold text-2xl text-text-800"}>
                        {companyNameConst[companyName.toUpperCase()].label}
                    </span>
                    <span className={"font-bodyPri font-medium text-text-800 text-base tracking-wide"}>
                        {companyPublicProfile?.companyPublicProfile?.elevatorPitch}
                    </span>
                    <span className={"font-bodyPri font-normal text-text-700 text-base"}>
                        {companyPublicProfile?.companyPublicProfile?.currentLocation}
                    </span>

                    <div className={"md:hidden flex flex-col items-start justify-center pt-8"}>
                        <div className="rounded-t-lg overflow-hidden w-96 h-40">
                            <iframe
                                width="100%"
                                height="100%"
                                src={companyPublicProfile?.companyPublicProfile?.videoUrl}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
                            </iframe>
                        </div>
                        <div className={"w-full px-2 py-2 flex justify-center items-center gap-5 bg-white rounded-b-lg shadow-xl"}>
                            <div
                                className={clsx
                                    ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                        "border border-text-800 text-text-800",
                                        "font-buttons font-normal text-base",
                                        "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                    )}
                            >
                                {"Message"}
                            </div>
                            <div
                                className={clsx
                                    ("w-36 py-1 flex justify-center items-center rounded-lg cursor-pointer",
                                        "border border-text-800 text-text-800",
                                        "font-buttons font-normal text-base",
                                        "hover:border-secondary-dark hover:bg-secondary-dark hover:text-text-50"
                                    )}>
                                {"More"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CompanyInformation