import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import * as React from "react";
import { FaCheck, FaSortDown } from "react-icons/fa";

type SelectOption = {
  label: string;
  value: any;
};

type InputProps = {
  label?: string;
  options: SelectOption[];
  className?: string;
  disabled?: boolean;
  key?: string;
  testId?: string;
  value: any;
  showCheckMark?: boolean;
  onChange: (e: SelectOption) => void;
  inputProp?: any;
};

export const OptionSelector: React.FC<InputProps> = ({
  onChange,
  showCheckMark = false,
  options,
  value,
  label,
}) => {
  const selectedOption = options.find((o) => o.value === value) || options[0];

  return (
    <div className="">
      <Listbox
        value={selectedOption.value}
        onChange={(value) => {
          const selected = options.find((o) => o.value === value)!;
          onChange(selected);
        }}
      >
        <div className="relative w-full">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-all cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
            <span className="block truncate">{selectedOption.label}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pb-2 pr-2 pointer-events-none">
              <FaSortDown
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={React.Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className={clsx(
              "absolute z-50 w-full py-1 mt-1 overflow-auto max-h-60 rounded-md",
              "text-base bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
              "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
              "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
              {options.map((option, optionIdx) => (
                <Listbox.Option
                  key={optionIdx}
                  className={({ active, selected }) =>
                    clsx(
                      `cursor-pointer select-none relative py-2 pr-4 ${
                        showCheckMark ? "pl-10" : "pl-5"
                      }`,
                      selected && "text-white bg-blue-500",
                      active && !selected && "text-blue-500 "
                    )
                  }
                  value={option.value}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`${selected ? "font-medium text-white" : "font-normal "
                          } block truncate`}
                      >
                        {option.label}
                      </span>
                      {selected && showCheckMark ? (
                        <span
                          className={`text-white absolute inset-y-0 left-0 flex items-center pl-3`}
                        >
                          <FaCheck size={10} aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>{" "}
    </div>
  );
};