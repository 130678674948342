import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

import OTPInput, { ResendOTP } from "otp-input-react";

import { MdCancel } from "react-icons/md";

import { createSendOtp } from "redux/notification/notification.request";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearVerifyOtp } from "redux/notification/notification.slice";

const InputOtp = ({
    email = null,
    setMyOtp,
    isOtpError = null,
    setIsOtpError
}) => {
    
    const dispatcher = useAppDispatcher()

    const [OTP, setOTP] = useState("")
    
    useEffect(() => {
        if (OTP) {
            setMyOtp(OTP)
        }
    }, [OTP])

    const renderButton = (buttonProps) => {

        return (
            <button {...buttonProps}
                className={"font-bodyPri font-medium text-text-600 text-sm"}
            >
                {buttonProps.remainingTime !== 0
                    ? `Resend Code in 
                ${Math.floor((buttonProps.remainingTime % 3600) / 60) < 10
                        ? '0' + Math.floor((buttonProps.remainingTime % 3600) / 60)
                        : Math.floor((buttonProps.remainingTime % 3600) / 60)} : ${Math.floor(buttonProps.remainingTime % 60) < 10
                            ? '0' + Math.floor(buttonProps.remainingTime % 60)
                            : Math.floor(buttonProps.remainingTime % 60)
                    }` : "Resend Code"
                }
            </button>
        )
    }

    const renderTime = () => React.Fragment

    const resendOtp = () => {
        toast.success("Code sent successfully")
        dispatcher(createSendOtp({
            otpDeliveryMethod: "email",
            email: email
        }))
    }

    return (
        <div className={"flex flex-col items-center justify-center gap-1"}>
            <div className={"flex justify-start items-center gap-0.5"}>
                <OTPInput
                    otpType={"number"}
                    inputClassName={clsx(
                        "input-number-spin-none rounded-sm focus:outline-none",
                        "font-bodyPri font-normal text-text-900 text-base bg-white",
                        isOtpError && "border border-red-500",
                        !isOtpError && "border border-divider-dark"
                    )}
                    className={"outline-none"}
                    OTPLength={4}
                    value={OTP}
                    onChange={setOTP}
                    disabled={false}
                    autoFocus={true}
                />
                {isOtpError &&
                    <MdCancel
                        className={'text-base font-bodyPri text-red-800 cursor-pointer'}
                        onClick={() => {
                            dispatcher(setClearVerifyOtp())
                            setIsOtpError(false)
                            setOTP("")
                            setMyOtp("")
                        }}
                    />
                }
            </div>
            <ResendOTP
                maxTime={119}
                renderButton={renderButton}
                renderTime={renderTime}
                onResendClick={resendOtp}
            />
        </div >
    )
}

export default InputOtp