import { useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";

import { validateEmail } from "utils/validation-functions";

const SendEmailAction = ({ closeEmailContainer }) => {
    const [emailAddress, setEmailAddress] = useState("")
    const [emailSubject, setEmailSubject] = useState("")
    const [emailContent, setEmailContent] = useState("")

    const handleSendEmail = () => {
        if (validateEmail(emailAddress) && emailContent?.length > 0) {
            closeEmailContainer()
            setEmailSubject("")
            setEmailAddress("")
            toast.success("Email sent successfully! We will get back soon")
            return;
        }
        if (validateEmail(emailAddress) && !emailSubject && !emailContent) {
            alert("Send this email without subject and text")
            closeEmailContainer()
            toast.success("Email sent successfully! We will get back soon")
            return;
        }
        if (!validateEmail(emailAddress)) {
            toast.warning("Email is not valid!")
            return;
        }
    }

    return (
        <div className="w-full space-y-3">
            <span className={"w-full flex justify-center items-center font-bodyPri font-semibold text-lg text-text-800 text-center truncate tracking-wide"}>
                {"Send Email"}
            </span>
            <div className={clsx("w-full flex flex-col items-start justify-start p-5 pb-2 gap-3",
                "border-2 border-primary-main focus:shadow-all-md focus:shadow-primary-light focus:border-primary-main",

            )}>
                <div className="w-full space-y-1">
                    <div className="flex justify-start items-start gap-3">
                        <span className="font-bodyPri font-normal text-text-600 text-base">
                            {"To"}
                        </span>
                        <input
                            typeof={"text"}
                            className={clsx(
                                "w-full outline-none",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                            )}
                            autoFocus={true}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            value={emailAddress}
                        />
                    </div>
                    <hr className="w-full border border-text-200" />
                </div>
                <div className="w-full space-y-1">
                    <div className="flex justify-start items-start gap-3">
                        <span className="font-bodyPri font-normal text-text-600 text-base">
                            {"Subject"}
                        </span>
                        <input
                            typeof={"text"}
                            className={clsx(
                                "w-full outline-none",
                                "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                            )}
                            onChange={(e) => setEmailSubject(e.target.value)}
                            value={emailSubject}
                        />
                    </div>
                    <hr className="w-full border border-text-200" />
                </div>
                <textarea
                    className={clsx(
                        "w-full resize-none",
                        "outline-none",
                        "font-bodyPri font-normal text-base text-text-800 tracking-wide",
                        "placeholder:text-text-500 text-base font-bodyPri font-normal tracking-wide"
                    )}
                    type={"text"}
                    placeholder={"Write your email ..."}
                    value={emailContent}
                    onChange={(event) => setEmailContent(event.target.value)}
                    rows={8}
                    maxLength={700}
                />
                <span
                    className={clsx(
                        "px-3 py-1 rounded-full cursor-pointer whitespace-nowrap",
                        "font-buttons font-normal text-base text-secondary-main",
                        "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                    onClick={handleSendEmail}
                >
                    {"Send Email"}
                </span>
            </div>
        </div>
    )
}

export default SendEmailAction