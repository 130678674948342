import { OptionSelector } from "components/common-components/Select";

import { timeZone, generateTimeOption } from 'utils/dateTime.utils';


const TimeRangeSelection = ({ timeSlot, setTimeSlot }) => {

  const startTimeOption = generateTimeOption(timeZone)
  const endTimeOption = generateTimeOption(timeZone)

  return (
    <div className={"flex items-center justify-center gap-5"}>

      <div className={"flex flex-col item-center justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-base text-text-800 text-center"}>
          {"Start"}
        </span>
        <div className="w-32">
          <OptionSelector
            options={startTimeOption}
            onChange={(option) => setTimeSlot({ ...timeSlot, startTime: option.value })}
            value={timeSlot?.startTime}
          />
        </div>
      </div>

      <span className={"font-black align-middle h-full"}>{"-"}</span>

      <div className={"flex flex-col item-center justify-center gap-2"}>
        <span className={"font-bodyPri font-normal text-base text-text-800 text-center"}>
          {"End"}
        </span>
        <div className="w-32">
          <OptionSelector
            options={endTimeOption}
            onChange={(option) => setTimeSlot({ ...timeSlot, endTime: option.value })}
            value={timeSlot?.endTime}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeRangeSelection;