import { useState } from 'react';
import {
    CardContainer,
    Heading,
    Description,
    MoreButton,
} from './VerticalTimeLineStyle';
import './verticalTimeLine.css';

const TimeLineCardContent = ({ index, timeLineItem }) => {
    const [selectItem, setSelectItem] = useState(null);

    const isMore = selectItem === index;

    return (
        <CardContainer className='cardContainer'>
            <Heading>{timeLineItem.title}</Heading>
            <Description>
                {timeLineItem.description.substring(0, isMore ? timeLineItem.description.length : 100) + ' '}
                {timeLineItem.description.length > 100 &&
                    <MoreButton onClick={() => setSelectItem(isMore ? null : index)}>{isMore ? '...Less' : '...More'}</MoreButton>
                }
            </Description>
        </CardContainer>
    )
}

export default TimeLineCardContent;
