export const url = {
    GENERATE_S3_PRESIGNED_POST: '/documents/s3_presigned_post'
}


export const fileCategories = {
    courseThumbnail: {
        value: "courseThumbnail"
    },
    profilePicture: {
        value: "profilePicture"
    },
}