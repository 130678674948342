import clsx from "clsx";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useState, useEffect } from 'react'

const ITEM_PER_GROUP = 3

const Pagination = ({
    totalItems,
    itemsPerPage,
    onStartIndex,
    onLastIndex,
}) => {
    const [pages] = useState(new Array(Math.ceil(totalItems / itemsPerPage)).fill().map((_, idx) => idx + 1))
    const [currentPage, setCurrentPage] = useState(1);
    const [start, setStart] = useState(0)
    const [last, setLast] = useState(ITEM_PER_GROUP)

    useEffect(() => {
        setCurrentPage(1)
        // setStart(0)
        // setLast(ITEM_PER_GROUP)
    }, [totalItems])

    useEffect(() => { // on page change - scroll to top
        window.scrollTo({ behavior: 'smooth', top: '0px' });
    }, [currentPage]);

    function previousPage(previous) {
        const startIndex = (previous - 1) * itemsPerPage - itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        onStartIndex(startIndex)
        onLastIndex(endIndex)
        if ((previous - 1) % 3 === 0) {
            setStart(start => start - ITEM_PER_GROUP)
            setLast(last => last - ITEM_PER_GROUP)
        }
    }

    function changePage(page) {
        const startIndex = page * itemsPerPage - itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        onStartIndex(startIndex)
        onLastIndex(endIndex)
    }

    function nextPage(previous) {
        const startIndex = (previous + 1) * itemsPerPage - itemsPerPage;
        const lastIndex = startIndex + itemsPerPage;
        onStartIndex(startIndex)
        onLastIndex(lastIndex)
        if ((previous) % 3 === 0) {
            setStart(start => start + ITEM_PER_GROUP)
            setLast(last => last + ITEM_PER_GROUP)
        }
    }

    return (
        <>
            {totalItems !== 0 && (
                <div className="flex items-center justify-center space-x-2">
                    {currentPage > 1 &&
                        <button className="p-2 bg-white rounded-full shadow-md"
                            onClick={() => {
                                if (currentPage > 1) {
                                    previousPage(currentPage);
                                    setCurrentPage(currentPage - 1)
                                }
                            }}>
                            <FaChevronLeft className={clsx("text-primary-main hover:scale-125")} />
                        </button>
                    }
                    <div className="flex space-x-1">
                        {pages.slice(start, last).map((page, index) => (
                            <button key={index}
                                className={clsx(
                                    " px-2.5 py-1 cursor-pointer",
                                    currentPage === page
                                        ? "font-bold bg-primary-main text-white"
                                        : "text-primary-main",
                                    "hover:scale-110 rounded-full "
                                )}
                                onClick={() => {
                                    setCurrentPage(page)
                                    changePage(page)
                                }}
                            >
                                {page}
                            </button>

                        ))}
                    </div>
                    {currentPage < pages.length &&
                        <button onClick={() => {
                            if (currentPage < pages.length) {
                                nextPage(currentPage);
                                setCurrentPage(currentPage + 1)
                            }
                        }} className="p-2 bg-white rounded-full shadow-md">
                            <FaChevronRight className="text-primary-main hover:scale-125" />
                        </button>
                    }
                </div>
            )}
        </>
    );
};

export default Pagination;


// pagination for reference

// const getPageNumbers = () => {
//     const pageNumbers = [];
//     for (let i = 1; i <= numberOfPages; i++) {
//         pageNumbers.push(i);
//     }
//     return pageNumbers;
// };

// return (
//     <div className="flex items-center justify-center w-full py-2 space-x-2 bg-white rounded-lg shadow-md">
//         <button className="p-2 bg-white rounded-full shadow-md">
//             <FaChevronLeft className="text-sky-500" />
//         </button>
//         <div className="flex space-x-1">
//             {getPageNumbers().map((page) => (
//                 <button
//                     key={page}
//                     className={clsx(
//                         "text-sky-500 p-1 cursor-pointer",
//                         activePageNumber === page && "font-bold"
//                     )}
//                     onClick={() => onPageChange(page)}
//                 >
//                     {page}
//                 </button>
//             ))}
//         </div>
//         <button className="p-2 bg-white rounded-full shadow-md">
//             <FaChevronRight className="text-sky-500" />
//         </button>
//     </div>
// );