import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import clsx from 'clsx';

import { MdOutlineEmail } from 'react-icons/md';

import { viewContainerConst } from 'components/modals/needATutorModal/data';

import { createSendOtp } from "redux/notification/notification.request";

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setNeedTutorPayload } from 'redux/needTutor/needTutor.slice';

import { validateEmail } from 'utils/validation-functions';

const Email = ({ setStepCount, setViewContainer }) => {
    const { needTutor } = useAppState((state) => state.needTutor)

    const dispatcher = useAppDispatcher()

    const [emailAddress, setEmailAddress] = useState("")
    const [emailAddressError, setEmailAddressError] = useState("")

    useEffect(() => {
        if (needTutor?.needTutorPayload?.email) {
            setEmailAddress(needTutor?.needTutorPayload?.email)
        }
    }, [needTutor?.needTutorPayload?.email])

    useEffect(() => {
        if (emailAddress && !validateEmail(emailAddress)) {
            setEmailAddressError("Invalid Email")
        } else {
            setEmailAddressError("")
        }
    }, [emailAddress])

    const onAddEmailAddress = (e) => {
        if (e.keyCode === 13) {
            onHandleButton()
        }
    }

    const onHandleButton = () => {
        if (emailAddressError) return;
        if (emailAddress && (emailAddress === needTutor?.needTutorPayload?.email && needTutor?.needTutorPayload?.isEmailVerified)) {
            setStepCount(6)
            setViewContainer(viewContainerConst.PHONE.value)
            toast.success("Email already verified!")
            return;
        }
        dispatcher(setNeedTutorPayload({
            ...needTutor?.needTutorPayload,
            email: emailAddress
        }))
        dispatcher(createSendOtp({
            otpDeliveryMethod: "email",
            email: emailAddress
        }))
        toast.success("Code sent successfully")
        setStepCount(5)
        setViewContainer(viewContainerConst.VERIFY_CODE.value)
        return;
    }

    return (
        <div className={"w-full flex flex-col items-start justify-start gap-10 px-2 transition ease-in-out delay-1000 duration-1000 transform"}>
            <span className={"font-bodyPri font-bold text-4xl text-text-900 tracking-wide"}>
                <span className={"capitalize"}>{needTutor?.needTutorPayload?.firstName}</span>
                {", where can we send your tutor details?"}
            </span>
            <div className={"w-full flex flex-col items-start justify-start gap-1.5"}>
                <span className={"font-bodyPri font-medium text-xl text-text-900 tracking-wide"}>
                    {"Your Email Address"}
                </span>
                <div className={clsx(
                    "w-1/2 px-2 py-3 flex items-center justify-start gap-1.5 border-2 border-divider-medium focus:border-2 focus:border-text-400 rounded-md"
                )}>
                    <MdOutlineEmail className={"text-3xl text-text-500"} />
                    <input
                        type={"email"}
                        placeholder={"email@domain.com"}
                        className={clsx(
                            "w-full px-2 focus:outline-none font-bodyPri font-normal text-text-800 text-lg",
                            "placeholder:text-text-500 placeholder:text-lg"
                        )}
                        autoFocus={true}
                        value={emailAddress}
                        onChange={(event) => setEmailAddress(event.target.value.toLowerCase())}
                        onKeyDown={onAddEmailAddress}
                    />
                </div>
                {emailAddressError &&
                    <span className={"font-bodyPri font-normal text-red-500 text-sm"}>
                        {emailAddressError}
                    </span>
                }
            </div>
            <span
                className={clsx(
                    "px-12 py-3 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-medium text-2xl text-text-50 bg-primary-dark",
                    "transition ease-in-out delay-100 duration-300 transform hover:scale-105"
                )}
                onClick={onHandleButton}
            >
                {"Continue"}
            </span>
        </div>
    )
}

export default Email;