import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';

import {
    PageContentStyle,
    SearchDiv,
    Section,
    Container
} from "pages/global/segments/style"

import FullPageLoader from 'components/loader/FullPageLoader';
import Footer from 'components/footer/Footer';

import { pagesInfo } from 'utils/pagesInfo';

import { setPageInfo } from 'redux/pageInfo/pageInfo.request';
import { getAllSegmentCategoryList } from 'redux/segment/segment.request';

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setFilterProps, setClearSegmentList, setClearFilterProps } from 'redux/segment/segment.slice';
import { filterName } from "redux/segment/segment.const"


const CategorySegments = () => {
    const { segmentList, filterProps } = useAppState((s) => s.segment)

    const dispatcher = useAppDispatcher()

    const [selectedSegment, setSelectedSegment] = useState("")

    useEffect(() => {
        dispatcher(getAllSegmentCategoryList())

        return () => {
            dispatcher(setClearSegmentList())
            dispatcher(setClearFilterProps())
        }
    }, [])

    if (segmentList?.isLoading) {
        return (<FullPageLoader isLoading={segmentList?.isLoading} />)
    }

    return (
        <PageContentStyle>
            <Section>
                <Container>
                    <div className='flex flex-col md:flex-row justify-between items-start gap-5'>
                        <div className='flex flex-col justify-start gap-3'>
                            <h1 className='font-tagLine font-bold text-2xl text-text-900'>
                                {"Explore Popular topics and skills"}
                                <br />
                                {"(Coming Soon)"}
                            </h1>
                            <p className='font-bodyPri font-medium text-base text-text-600'>
                                {"Learn live from verified tutors and get ahead in life"}
                            </p>
                        </div>
                        <SearchDiv>
                            <input
                                className={"focus:outline-none"}
                                onChange={(e) => dispatcher(setFilterProps({
                                    filterProps: { ...filterProps, searchText: e.target.value },
                                    list: segmentList?.segmentList,
                                    filterName: filterName.SEGMENT.value
                                }))}
                                placeholder='Search by keyword'
                            />
                            <SearchIcon />
                        </SearchDiv>
                    </div>
                </Container>
            </Section>
            <Section>
                <Container>
                    <div className='font-bold font-bodyPri tracking-wide text-text-900'>{"Featured topics"}</div>
                    <div className='grid grid-cols-12 gap-y-8 gap-x-5'>
                        {segmentList?.filteredSegmentList && segmentList?.filteredSegmentList?.map((items: any, index: any) => {
                            const lastIndex = items?.segment === selectedSegment ? items?.categories?.length : 5
                            return (
                                <div key={index} className="overflow-hidden col-span-6 md:col-span-4 lg:col-span-3 flex flex-col justify-start gap-y-3 ">
                                    <span className='font-semibold font-bodyPri text-text-800 whitespace-nowrap truncate'>
                                        {items.segment}
                                    </span>
                                    <div className='flex flex-col gap-y-2 overflow-hidden'>
                                        {items?.categories?.slice(0, lastIndex)?.map((item: any, index: any) => (
                                            <Link to={"#"} key={index}
                                                className='font-normal font-bodyPri text-base text-primary-dark underline whitespace-nowrap truncate cursor-pointer'
                                            >
                                                {item?.category}
                                            </Link>
                                        ))}
                                        {items?.categories?.length >= 5 &&
                                            <span onClick={() => setSelectedSegment((prevValue) => prevValue === items?.segment ? "" : items?.segment)}
                                                className='text-blue-700 underline whitespace-nowrap truncate cursor-pointer'>
                                                {items?.segment === selectedSegment ? "See less..." : "See more..."}
                                            </span>}
                                    </div>
                                </div>
                            )
                        })}
                        {(segmentList?.length === 0 || segmentList?.errorMsg) &&
                            <div className={"w-full h-full flex justify-center items-center font-bodyPri font-semibold text-text-800 text-md"}>
                                {segmentList?.errorMsg}
                            </div>
                        }
                    </div>
                </Container>
            </Section>
        </PageContentStyle>
    )
}

const Segments = () => {
    const { currentPageInfo } = useAppState((state: any) => state.pageInfo)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setPageInfo(currentPageInfo, pagesInfo.SEGMENTS))
    }, [dispatcher, currentPageInfo])

    return ( 
        <>
            <CategorySegments />
            <Footer />
        </>
    )
}

export default Segments;