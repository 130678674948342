import { ThreeDots } from "react-loader-spinner";

const ComponentLoader = ({ isLoading = false, className="" }) => {
    return isLoading ? (
        <div className={`w-full h-full flex justify-center items-center bg-white ${className}`}>
             <ThreeDots heigth="100" width="100" ariaLabel="loading-indicator" color="grey"/>
        </div>
    ) : null
}

export default ComponentLoader