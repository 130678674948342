import {
    setReferenceListLoading,
    setReferenceList,
    setReferenceListErrorMsg,

    setAddReferenceLoading,
    setAddReference,
    setAddReferenceErrorMsg,

    setAddPersonalDetailLoading,
    setAddPersonalDetail,
    setAddPersonalDetailErrorMsg,
    setPersonalDetail,

    setAddDocumentLoading,
    setAddDocument,
    setDocumentList,
    setAddDocumentErrorMsg
} from "redux/tutorOnboarding/tutorOnboarding.slice"

export const getReferenceList = () => async (dispatch) => {
    dispatch(setReferenceListLoading(true))
    try {
        // dispatch(setReferenceList())
    } catch (error) {
        console.error(error)
        dispatch(setReferenceListErrorMsg(error.message))
    } finally {
        dispatch(setReferenceListLoading(false))
    }
}

export const createReference = () => async (dispatch, getState) => {
    dispatch(setAddReferenceLoading(true))

    try {
        const { referenceList, addReference } = getState().tutorOnboarding
        const list = [
            ...referenceList?.referenceList, addReference?.addReferencePayload
        ]
        dispatch(setReferenceList(list))
        dispatch(setAddReference(addReference?.addReferencePayload))

        // dispatch(setClearAddReference())
    } catch (error) {
        console.error(error)
        dispatch(setAddReferenceErrorMsg(error.message))
    } finally {
        dispatch(setAddReferenceLoading(false))
    }
}

export const updateReference = () => async (dispatch, getState) => {
    dispatch(setAddReferenceLoading(true))

    try {
        const { referenceList, addReference } = getState().tutorOnboarding
        const filteredList = referenceList?.referenceList?.filter((reference) => reference.referenceId !== addReference?.addReferencePayload?.referenceId)
        const referencePayload = {
            ...addReference?.addReferencePayload,
            referenceStatus: "submitted",
            isFormSubmitted: true,
            referenceId: addReference?.addReferencePayload?.referenceId
        }
        dispatch(setReferenceList([...filteredList, referencePayload]))
        dispatch(setAddReference(referencePayload))

        // dispatch(setClearAddReference())
    } catch (error) {
        console.error(error)
        dispatch(setAddReferenceErrorMsg(error.message))
    } finally {
        dispatch(setAddReferenceLoading(false))
    }
}

export const deleteReference = () => async (dispatch, getState) => {
    dispatch(setAddReferenceLoading(true))

    try {
        const { referenceList, addReference } = getState().tutorOnboarding
        const filteredList = referenceList?.referenceList?.filter((items) => items.referenceId !== addReference?.addReferencePayload?.referenceId)

        dispatch(setReferenceList(filteredList))
        // dispatch(setClearAddReference())
    } catch (error) {
        console.error(error)
        dispatch(setAddReferenceErrorMsg(error.message))
    } finally {
        dispatch(setAddReferenceLoading(false))
    }
}

export const createPersonalDetail = () => async (dispatch, getState) => {
    dispatch(setAddPersonalDetailLoading(true))

    try {
        const { addPersonalDetail } = getState().tutorOnboarding

        dispatch(setAddPersonalDetail(addPersonalDetail?.addPersonalDetailPayload))
        dispatch(setPersonalDetail(addPersonalDetail?.addPersonalDetailPayload))

    } catch (error) {
        console.error(error.message)
        dispatch(setAddPersonalDetailErrorMsg(error.message))
    } finally {
        dispatch(setAddPersonalDetailLoading(false))
    }
}
export const createDocument = () => async (dispatch, getState) => {
    dispatch(setAddDocumentLoading(true))

    try {
        const { documentList, addDocument } = getState().tutorOnboarding
        const list = [
            ...documentList?.documentList, addDocument?.addDocumentPayload
        ]
        dispatch(setDocumentList(list))
        dispatch(setAddDocument(addDocument?.addDocumentPayload))

    } catch (error) {
        console.error(error.message)
        dispatch(setAddDocumentErrorMsg(error.message))
    } finally {
        dispatch(setAddDocumentLoading(false))
    }
}

export const updateDocument = () => async (dispatch, getState) => {
    dispatch(setAddDocumentLoading(true))

    try {
        const { documentList, addDocument } = getState().tutorOnboarding
        const filteredList = documentList?.documentList?.filter((document) => document.documentId !== addDocument?.addDocumentPayload?.documentId)
        const documentPayload = {
            ...addDocument?.addDocumentPayload,
            documentStatus: "submitted",
            isFormSubmitted: true,
            documentId: addDocument?.addDocumentPayload?.documentId
        }
        dispatch(setDocumentList([...filteredList, documentPayload]))
        dispatch(setAddDocument(documentPayload))

        // dispatch(setClearAddDocument())
    } catch (error) {
        console.error(error)
        dispatch(setAddDocumentErrorMsg(error.message))
    } finally {
        dispatch(setAddDocumentLoading(false))
    }
}

