import { Section, SectionContent } from 'components/generalComponent/StyledComponent';
import tw from 'twin.macro';

export const Container = tw(Section)`
    bg-background-light
`;

export const Content = tw(SectionContent)`
    col-span-12 md:(col-start-2 col-span-10) lg:(col-start-3 col-span-8)
`;
