import { courseType } from "redux/course/course.const"

export const url = {
    OFFERING_LIST: "/offerings/list",
    OFFERING_COURSE_BY_COURSE_ID: "/offerings/{courseId}",
    ADD_OFFERING: "/offerings/add",
    UPDATE_OFFERING_BY_COURSE_ID: "/offerings/update/{courseId}"
}

export const offeringStatus = {
    ALL: {
        label: 'All',
        value: 'all'
    },
    PUBLISHED: {
        label: 'Published',
        value: 'published',
    },
    DRAFT: {
        label: 'Draft',
        value: 'draft',
    },
    UNDER_REVIEW: {
        label: 'Under Review',
        value: 'under_review',
    },
    INACTIVE: {
        label: 'Inactive',
        value: 'inactive',
    },
}

export const filterName = {
    OFFERING: {
        value: "offering"
    }
}

export const offeringFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    courseType: courseType.ALL.value,
    offeringStatus: offeringStatus.ALL.value
}