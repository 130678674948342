import {
    Container,
    Content,
    ListItems,
    ListItem,
    ItemIcon,
    ItemName,
    StudentIcon,
    TutorIcon
} from './SideNavBarStyle';

import SideBarItem from './SideBarItem';

import { useAppDispatcher, useAppState } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { userRoles } from 'redux/auth/auth.const';
import { modalConst } from 'redux/local/local.const';

import { navLinkList } from 'utils/sideAndTopNavLink.utils';


const SideNavBar = ({ visible }) => {
    const { currentPageInfo } = useAppState((state) => state.pageInfo)
    const { modal, locals } = useAppState((state) => state.local)
    const pageName = currentPageInfo.pageName;

    const containerVarients = { close: { maxWidth: '6.5rem' }, open: { maxWidth: '13rem' } }
    const itemNameVarients = { close: { width: '0px' }, open: { width: '100%' } }

    const transition = { duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }
    const dispatcher = useAppDispatcher()

    const handleRoleSwitch = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.ROLE_SWITCH_MODAL.stateKey]: true
        }))
    }

    return (
        <Container pageName={pageName}
            variants={containerVarients}
            initial={'open'}
            animate={visible ? 'open' : 'close'}
            transition={transition}
        >
            <Content>
                <ListItems>
                    <ListItem onClick={handleRoleSwitch}>
                        <ItemIcon>
                            {locals.userRole === userRoles.STUDENT.value && <StudentIcon />}
                            {locals.userRole === userRoles.TUTOR.value && <TutorIcon />}
                        </ItemIcon>
                        {locals &&
                            <ItemName
                                variants={itemNameVarients}
                                initial={'open'}
                                animate={visible ? 'open' : 'close'}
                                transition={transition}
                            >
                                {userRoles[locals?.userRole?.toUpperCase()].label}
                            </ItemName>
                        }
                    </ListItem>
                    {navLinkList.map((dataItem, index) => dataItem.roles.includes(locals.userRole) && (
                        <SideBarItem key={index} dataItem={dataItem} visible={visible} />
                    ))}
                </ListItems>
            </Content>
        </Container>
    )
}

export default SideNavBar;