import { createSlice } from "@reduxjs/toolkit";
import { COURSE_INITIAL_STATE } from "redux/course/course.initialState";
import { filterName } from "redux/course/course.const";

import { courseTabs } from "pages/auth/myCourses/data";


const course = createSlice({
    name: "course",
    initialState: COURSE_INITIAL_STATE,
    reducers: {
        // course list
        setCourseListLoading: (state, { payload }) => {
            state.courseList.isLoading = payload
        },
        setCourseList: (state, { payload }) => {
            state.courseList.courseList = payload
            state.courseList.filteredCourseList = payload
            state.courseList.errorMsg = COURSE_INITIAL_STATE.courseList.errorMsg
        },
        setCourseListErrorMsg: (state, { payload }) => {
            state.courseList.errorMsg = payload
            state.courseList.courseList = COURSE_INITIAL_STATE.courseList.courseList
            state.courseList.filteredCourseList = COURSE_INITIAL_STATE.courseList.filteredCourseList
        },
        setClearCourseList: (state) => {
            state.courseList = COURSE_INITIAL_STATE.courseList
        },

        // my course list
        setMyCourseListLoading: (state, { payload }) => {
            state.myCourseList.isLoading = payload
        },
        setMyCourseList: (state, { payload }) => {
            state.myCourseList.myCourseList = payload
            state.myCourseList.filteredCourseList = payload
            state.myCourseList.errorMsg = COURSE_INITIAL_STATE.myCourseList.errorMsg
        },
        setMyCourseListErrorMsg: (state, { payload }) => {
            state.myCourseList.errorMsg = payload
            state.myCourseList.myCourseList = COURSE_INITIAL_STATE.myCourseList.myCourseList
            state.myCourseList.filteredCourseList = COURSE_INITIAL_STATE.myCourseList.filteredCourseList
        },
        setClearMyCourseList: (state) => {
            state.myCourseList = COURSE_INITIAL_STATE.myCourseList
        },

        // tutor course list
        setTutorCourseListLoading: (state, { payload }) => {
            state.tutorCourseList.isLoading = payload
        },
        setTutorCourseList: (state, { payload }) => {
            state.tutorCourseList.tutorCourseList = payload
            state.tutorCourseList.filteredTutorCourseList = payload
            state.tutorCourseList.errorMsg = COURSE_INITIAL_STATE.tutorCourseList.errorMsg
        },
        setTutorCourseListErrorMsg: (state, { payload }) => {
            state.tutorCourseList.errorMsg = payload
            state.tutorCourseList.tutorCourseList = COURSE_INITIAL_STATE.tutorCourseList.tutorCourseList
            state.tutorCourseList.filteredTutorCourseList = COURSE_INITIAL_STATE.tutorCourseList.filteredTutorCourseList
        },
        setClearTutorCourseList: (state) => {
            state.tutorCourseList = COURSE_INITIAL_STATE.tutorCourseList
        },

        // fav course list
        setFavCourseListLoading: (state, { payload }) => {
            state.favCourseList.isLoading = payload
        },
        setFavCourseList: (state, { payload }) => {
            state.favCourseList.favCourseList = payload
            state.favCourseList.filteredFavCourseList = payload
            state.favCourseList.errorMsg = COURSE_INITIAL_STATE.favCourseList.errorMsg
        },
        setFavCourseListErrorMsg: (state, { payload }) => {
            state.favCourseList.errorMsg = payload
            state.favCourseList.favCourseList = COURSE_INITIAL_STATE.favCourseList.favCourseList
            state.favCourseList.filteredFavCourseList = COURSE_INITIAL_STATE.favCourseList.filteredFavCourseList
        },
        setClearFavCourseList: (state) => {
            state.favCourseList = COURSE_INITIAL_STATE.favCourseList
        },

        // my course page tab (my courses and favourite tabs)
        setCourseTabs: (state, { payload }) => {
            if (payload.value === courseTabs.ALL_COURSES.value) {
                state.myCourseList.filteredCourseList = state.myCourseList.myCourseList
            }
            if (payload.value === courseTabs.FAV_COURSES.value) {
                state.myCourseList.filteredCourseList = state.favCourseList.favCourseList
            }
        },

        // course detail
        setCourseDetailLoading: (state, { payload }) => {
            state.courseDetail.isLoading = payload
        },
        setCourseDetail: (state, { payload }) => {
            state.courseDetail.courseDetail = payload
            state.courseDetail.errorMsg = COURSE_INITIAL_STATE.courseDetail.errorMsg
        },
        setCourseDetailErrorMsg: (state, { payload }) => {
            state.courseDetail.errorMsg = payload
            state.courseDetail.courseDetail = COURSE_INITIAL_STATE.courseDetail.courseDetail
        },
        setClearCourseDetail: (state) => {
            state.courseDetail = COURSE_INITIAL_STATE.courseDetail
        },

        // selected course
        setSelectedCourseLoading: (state, { payload }) => {
            state.selectedCourse.isLoading = payload
        },
        setSelectedCourse: (state, { payload }) => {
            state.selectedCourse.selectedCourse = payload
            state.selectedCourse.errorMsg = COURSE_INITIAL_STATE.selectedCourse.errorMsg
        },
        setSelectedCourseErrorMsg: (state, { payload }) => {
            state.selectedCourse.errorMsg = payload
            state.selectedCourse.selectedCourse = COURSE_INITIAL_STATE.selectedCourse.selectedCourse
        },
        setClearSelectedCourse: (state) => {
            state.selectedCourse = COURSE_INITIAL_STATE.selectedCourse
        },

        // add or remove fav course
        setAddFavCourseLoading: (state, { payload }) => {
            state.addFavCourse.isLoading = payload
        },
        setAddFavCourse: (state, { payload }) => {
            state.addFavCourse.addFavCourse = payload
            state.addFavCourse.errorMsg = COURSE_INITIAL_STATE.addFavCourse.errorMsg
        },
        setAddFavCourseErrorMsg: (state, { payload }) => {
            state.addFavCourse.errorMsg = payload
            state.addFavCourse.addFavCourse = COURSE_INITIAL_STATE.addFavCourse.addFavCourse
        },
        setClearAddFavCourse: (state) => {
            state.addFavCourse = COURSE_INITIAL_STATE.addFavCourse
        },

        // filter props
        setFilterProps: (state, { payload }) => {
            state.filterProps = payload.filterProps;
            const filteredList = payload.list
                .filter((course) => (
                    !payload.filterProps.searchText || course.courseTitle.toLowerCase().includes(payload.filterProps.searchText.toLowerCase())
                ))
                // .filter((course) => (
                //     payload.filterProps.language === "all" || course.language === payload.filterProps.language
                // ))

            switch (payload.filterName) {
                case filterName.FIND_COURSES.value:
                    state.courseList.filteredCourseList.records = filteredList
                    break;
                case filterName.ALL_COURSES.value:
                    state.myCourseList.filteredCourseList.records = filteredList
                    break;
                case filterName.FAV_COURSES.value:
                    state.myCourseList.filteredCourseList.records = filteredList
                    break;
                default:
                    break;
            }
        },
        setClearFilterProps: (state) => {
            state.filterProps = COURSE_INITIAL_STATE.filterProps
        }
    }
})

export const {
    setFilteredCourseList,
    setClearFilteredCourseList,

    setCourseListLoading,
    setCourseList,
    setCourseListErrorMsg,
    setClearCourseList,

    setMyCourseListLoading,
    setMyCourseList,
    setMyCourseListErrorMsg,
    setClearMyCourseList,

    setTutorCourseListLoading,
    setTutorCourseList,
    setTutorCourseListErrorMsg,
    setClearTutorCourseList,

    setFavCourseListLoading,
    setFavCourseList,
    setFavCourseListErrorMsg,
    setClearFavCourseList,

    setCourseDetailLoading,
    setCourseDetail,
    setCourseDetailErrorMsg,
    setClearCourseDetail,

    setSelectedCourseLoading,
    setSelectedCourse,
    setSelectedCourseErrorMsg,
    setClearSelectedCourse,

    setCourseTabs,

    setAddFavCourseLoading,
    setAddFavCourse,
    setAddFavCourseErrorMsg,
    setClearAddFavCourse,

    setFilterProps,
    setClearFilterProps,
} = course.actions

export default course.reducer