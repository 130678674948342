import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SUBSCRIPTION_INITIAL_DATA } from "./mock-data";

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: SUBSCRIPTION_INITIAL_DATA,
  reducers: {
    openPlansModal: (state) => {
      state._meta.showPlansModal = true;
    },
    closePlansModal: (state) => {
      state._meta.showPlansModal = false;
    },
    openInvoiceModal: (state, action) => {
      state._meta.showInvoiceModal.showModal = true;
      state._meta.showInvoiceModal.invoiceId = action.payload;
    },
    closeInvoiceModal: (state) => {
      state._meta.showInvoiceModal.showModal = false;
    },
  },
});

export const {
  closePlansModal,
  openPlansModal,
  closeInvoiceModal,
  openInvoiceModal,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
