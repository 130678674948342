import { courseType } from "redux/course/course.const"

export const url = {
    GET_STUDENT_BOOKING_LIST: "/bookings/list/student",
    GET_TUTOR_BOOKING_LIST: "/bookings/list/tutor",
    GET_BOOKING_DETAIL: "/bookings",
    ADD_BOOKING: '/bookings/add',
    CONFIRM_ADD_BOOKING_BY_BOOKING_ID: '/bookings/confirm-add',
    GET_BOOKING_CONFIRMATION_BY_BOOKING_ID: "/bookings/confirmation/{bookingId}",
    CANCEL_BOOKING_BY_BOOKING_ID: "/bookings/cancel/booking/{bookingId}",
    CANCEL_BOOKING_BY_COURSE_ID: "/bookings/cancel/course/{courseId}"
}


export const filterName = {
    BOOKING: {
        value: "booking"
    }
}

export const bookingStatus = {
    ALL: {
        label: "All",
        value: "all",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    PENDING: {
        label: "Pending",
        value: "pending",
        lightColor: 'orange-100',
        darkColor: 'orange-500',
    },
    CONFIRMED: {
        label: "Confirmed",
        value: "confirmed",
        lightColor: 'blue-100',
        darkColor: 'blue-500',
    },
    COMPLETED: {
        label: "Completed",
        value: "completed",
        lightColor: 'green-100',
        darkColor: 'green-500',
    },
    CANCELLED: {
        label: "Cancelled",
        value: "cancelled",
        lightColor: 'gray-100',
        darkColor: 'gray-500',
    },
    REFUNDED: {
        label: "Refunded",
        value: "refunded",
        lightColor: 'red-100',
        darkColor: 'red-500',
    }
}

export const weeklyTimeSlot = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

export const bookingType = {
    ALL: {
        value: 'all',
        label: 'All'
    },
    REGULAR: {
        value: 'regular',
        label: 'Regular'
    },
    TRIAL: {
        value: 'trial',
        lable: 'Trial',
    },
    ON_CHAT: {
        value: 'on_chat',
        lable: 'On Chat'
    }
}

export const chooseTimeSlotAction = {
    BOOKING: {
        value: 'booking',
        label: 'Booking',
    },
    RESCHEDULED: {
        value: 'rescheduled',
        label: 'Rescheduled',
    },
    CHOOSE_BOOKED_SLOT: {
        value: 'choose_booked_slot',
        label: 'Choose Booked Slot',
    },
}

export const bookingFilterProps = {
    topic: "",
    priceRange: {
        startPrice: "",
        endPrice: ""
    },
    availability: {
        timeRange: {
            startTime: "",
            endTime: ""
        },
        day: ""
    },
    tutorFrom: "",
    language: "",
    proficiency: "",
    ageGroup: {
        startAge: "",
        endAge: ""
    },
    searchText: "",
    sortBy: "",
    courseType: courseType.ALL.value,
    bookingStatus: bookingStatus.ALL.value
}