import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { dayjs, timeZone } from 'utils/dateTime.utils';

import { MdAccessTimeFilled } from 'react-icons/md';
import { AiTwotoneCalendar } from 'react-icons/ai';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setAddInterviewSlotPayload } from 'redux/tutorOnboarding/tutorOnboarding.slice';

import { availability, interviewerDetails } from "pages/auth/tutorOnboard/TutorOnboardData"
import { getDayAvailability } from 'utils/availability.utils';

import ChooseTimeSlot from './ChooseTimeSlot'


const ChooseInterviewSlotModal = () => {
  const [dailyAvailabilities, setDailyAvailabilities] = useState(null)
  const { addInterviewSlot } = useAppState((s) => s.tutorOnboarding)
  const addInterviewPayload = addInterviewSlot?.addInterviewSlotPayload

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setAddInterviewSlotPayload({
      ...addInterviewPayload,
      sessionDuration: "30"
    }))
  }, [])

  useEffect(() => {
    setAvailability()
  }, [availability, addInterviewPayload?.sessionDuration])

  const setAvailability = async () => {
    const dAvailability = await getDayAvailability(availability, addInterviewPayload?.sessionDuration)
    setDailyAvailabilities(dAvailability)
  }

  return (
    <div className={"space-y-5"}>
      <div className={"min-h-[24rem] block"}>

        <div className={'grid grid-col-12 gap-5'}>

          <div className={"col-span-12 space-y-3"}>
            <div className={"h-0.5 bg-divider-medium w-full rounded-full"}></div>
          </div>

          <div className={"col-span-12 md:col-span-4 space-y-3"}>

            <div className={"w-full md:w-52 flex flex-col items-start gap-2 overflow-hidden"}>
              <div className={"flex flex-col items-start justfy-start space-y-3"}>
                <div className='relative w-12 h-12 rounded-full'>
                  <img src={interviewerDetails?.interviewerProfile?.profilePicUrl} alt={"profile-pic"}
                    className="w-full h-full rounded-full object-cover"
                  />
                  <div className='absolute right-0.5 bottom-0.5 w-3 h-3 rounded-full overflow-hidden border-white'>
                    <img src={`https://countryflagsapi.com/png/${interviewerDetails?.interviewerProfile?.countryDomain}`} alt={"country-flag"}
                      className='w-full h-full object-cover'
                    />
                  </div>
                </div>
                <div className='flex flex-col justify-start items-start space-y-2'>
                  <span className='font-bodyPri font-semibold text-md text-text-900 tracking-wide'>{"Interview Details:"}</span>
                  <div className='flex items-center justify-center gap-2'>
                    <span className='font-bodyPri font-medium text-base text-text-800'>{"Name: "}</span>
                    <span className='font-normal font-bodyPri text-sm text-text-900 tracking-wide'>
                      {interviewerDetails?.interviewerProfile?.firstName + " " + interviewerDetails?.interviewerProfile?.lastName.charAt(0) + "."}
                    </span>
                  </div>
                  <div className='flex items-center justify-center gap-2'>
                    <span className='font-bodyPri font-medium text-base text-text-800'>{"Duration: "}</span>
                    <span className={"font-bodyPri font-normal text-sm text-text-900 flex justify-start items-center gap-1"}>
                      <MdAccessTimeFilled className={"text-sm"} />
                      {addInterviewPayload?.sessionDuration + " min "}
                    </span>
                  </div>
                  <div>
                    <span className='font-bodyPri font-medium text-base text-text-800'>{"About: "}</span>
                    <div 
                    className='w-full h-full font-normal font-bodyPri text-sm text-text-900 tracking-wide'>
                      {interviewerDetails?.interviewerProfile?.bio}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"col-span-12 md:col-span-8 space-y-2"}>

            <span className={"font-bodyPri font-normal text-sm text-text-700 text-center"}>
              {"Available Slots"}
            </span>

            <div className={clsx(
              "w-full flex flex-col items-start justify-start gap-5 h-full md:h-96 overflow-hidden overflow-y-scroll",
              "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-w-sm",
              "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
            )}>
              {dailyAvailabilities?.map((dailyAvail, index) => (
                <div key={index} className={"flex flex-col items-start justify-start gap-3"}>
                  <span className={"font-bodyPri font-medium text-base text-text-900 flex items-center justify-start gap-2"}>
                    <AiTwotoneCalendar />
                    {dayjs(dailyAvail.date + " " + dayjs().utc().format("HH:mm:ss") + "+00:00").tz(timeZone).format('dddd, DD MMM YYYY')}
                  </span>
                  <div className={"px-3 flex flex-wrap items-center gap-2"}>
                    {dailyAvail.timeSlots.map((timeSlot, index) => (
                      <ChooseTimeSlot key={index} timeSlot={timeSlot} dailyAvail={dailyAvail} />
                    ))}
                  </div>
                </div>
              ))}
              {dailyAvailabilities?.length <= 0 &&
                <div className={"font-bodyPri font-semibold text-md text-text-700"}>
                  {"No Avalilable Slots"}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseInterviewSlotModal