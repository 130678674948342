import { toast } from "react-toastify";

import studentService from "redux/student/student.services"
import {
    setMyStudentListLoading,
    setMyStudentList,
    setMyStudentListErrorMsg,

    setFavStudentListLoading,
    setFavStudentList,
    setFavStudentListErrorMsg,

    setAddFavStudentLoading,
    setAddFavStudentErrorMsg,

    setStudentUserDetailLoading,
    setStudentUserDetail,
    setStudentUserDetailErrorMsg,

    setAddStudentUserDetailLoading,
    setAddStudentUserDetailErrorMsg,

    setStudentUserLanguageListLoading,
    setStudentUserLanguageList,
    setStudentUserLanguageListErrorMsg,

    setAddStudentLanguageDetailLoading,
    setAddStudentLanguageDetailErrorMsg,

    setStudentUserCategoryListLoading,
    setStudentUserCategoryList,
    setStudentUserCategoryListErrorMsg,

    setAddStudentCategoryDetailLoading,
    setAddStudentCategoryDetailErrorMsg
} from "redux/student/student.slice"

import { setUser } from "redux/user/user.slice";

// request for my student list
export const getMyStudentUserListByUserId = (userId) => async (dispatch) => {
    dispatch(setMyStudentListLoading(true))

    const requestData = {
        query: { roleStatus: 'active', isVerified: 'verified' },
        params: { userId: userId }
    }
    try {
        const response = await studentService.getMyStudentUserListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setMyStudentList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setMyStudentListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setMyStudentListLoading(false))
    }
}

// request for fav student list
export const getFavStudentUserListByUserId = (userId) => async (dispatch) => {
    dispatch(setFavStudentListLoading(true))

    try {
        const requestData = {
            query: { roleStatus: 'active', isVerified: 'verified' },
            params: { userId: userId }
        }
        const response = await studentService.getFavStudentUserListByUserId(requestData)

        if (response.status === 200) {
            dispatch(setFavStudentList(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setFavStudentListErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setFavStudentListLoading(false))
    }
}

// request for add fav student
export const createFavStudentUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavStudentLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body,
        }

        const response = await studentService.createFavStudentUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favStudents: [...user?.user?.favStudents, { studentId: response.data.data.studentId }]
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFavStudentErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message)
    } finally {
        dispatch(setAddFavStudentLoading(false))
    }
}

// request for delete fav student
export const deleteFavStudentUserDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddFavStudentLoading(true))

    try {
        const { user } = getState().user
        const requestData = {
            params: { userId: userId },
            body: body
        }

        const response = await studentService.deleteFavStudentUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setUser({
                ...user?.user,
                favStudents: user?.user?.favStudents?.filter((student) => student?.studentId !== body.studentId)
            }))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFavStudentErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddFavStudentLoading(false))
    }
}

// request for student user detail
export const getStudentUserDetailByUserId = (userId) => async (dispatch) => {
    dispatch(setStudentUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await studentService.getStudentUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setStudentUserDetailErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setStudentUserDetailLoading(false))
    }
}

// request for update student user detail
export const updateStudentUserDetailByUserId = (userId, body) => async (dispatch) => {
    dispatch(setAddStudentUserDetailLoading(true))

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await studentService.updateStudentUserDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserDetail(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentUserDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentUserDetailLoading(false))
    }
}

// request for student language list
export const getStudentUserLanguageListByUserId = (userId) => async (dispatch) => {
    dispatch(setStudentUserLanguageListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await studentService.getStudentUserLanguageListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserLanguageList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setStudentUserLanguageListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setStudentUserLanguageListLoading(false))
    }
}

// request for add student user language
export const createStudentUserLanguageDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddStudentLanguageDetailLoading(true))

    try {
        const { studentUserLanguageList } = getState().student
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await studentService.createStudentUserLanguageDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserLanguageList([
                ...studentUserLanguageList?.studentUserLanguageList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentLanguageDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentLanguageDetailLoading(false))
    }
}

// request for update student user language
export const updateStudentLanguageDetailByStudentLanguageId = (studentLanguageId, body) => async (dispatch, getState) => {
    dispatch(setAddStudentLanguageDetailLoading(true))

    try {
        const { studentUserLanguageList } = getState().student
        const requestData = {
            params: { studentLanguageId: studentLanguageId },
            body: body
        }
        const response = await studentService.updateStudentLanguageDetailByStudentLanguageId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserLanguageList(
                studentUserLanguageList?.studentUserLanguageList.map(studentUserLanguage => (
                    studentUserLanguage.studentLanguageId === studentLanguageId ? response.data.data : studentUserLanguage
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentLanguageDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentLanguageDetailLoading(false))
    }
}

// request for delete student language details
export const deleteStudentLanguageDetailByStudentLanguageId = (studentLanguageId) => async (dispatch, getState) => {
    dispatch(setAddStudentLanguageDetailLoading(true))

    try {
        const { studentUserLanguageList } = getState().student
        const requestData = {
            params: { studentLanguageId: studentLanguageId }
        }
        const response = await studentService.deleteStudentLanguageDetailByStudentLanguageId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserLanguageList(
                studentUserLanguageList?.studentUserLanguageList.filter(studentUserLanguage => (
                    studentUserLanguage.studentLanguageId !== studentLanguageId
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentLanguageDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentLanguageDetailLoading(false))
    }
}

// request for student category list
export const getStudentUserCategoryListByUserId = (userId) => async (dispatch) => {
    dispatch(setStudentUserCategoryListLoading(true))

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await studentService.getStudentUserCategoryListByUserId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserCategoryList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setStudentUserCategoryListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setStudentUserCategoryListLoading(false))
    }
}

// request for add category detail
export const createStudentUserCategoryDetailByUserId = (userId, body) => async (dispatch, getState) => {
    dispatch(setAddStudentCategoryDetailLoading(true))

    try {
        const { studentUserCategoryList } = getState().student
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await studentService.createStudentUserCategoryDetailByUserId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserCategoryList([
                ...studentUserCategoryList.studentUserCategoryList,
                response.data.data
            ]))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentCategoryDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentCategoryDetailLoading(false))
    }
}

// request for update category detail
export const updateStudentCategoryDetailByStudentCategoryId = (studentCategoryId, body) => async (dispatch, getState) => {
    dispatch(setAddStudentCategoryDetailLoading(true))

    try {
        const { studentUserCategoryList } = getState().student
        const requestData = {
            params: { studentCategoryId: studentCategoryId },
            body: body
        }
        const response = await studentService.updateStudentCategoryDetailByStudentCategoryId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserCategoryList(
                studentUserCategoryList?.studentUserCategoryList?.map((studentUserCategory) => (
                    studentUserCategory.studentCategoryId === studentCategoryId ? response.data.data : studentUserCategory
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentCategoryDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentCategoryDetailLoading(false))
    }
}

// request for delete category list
export const deleteStudentCategoryDetailByStudentCategoryId = (studentCategoryId) => async (dispatch, getState) => {
    dispatch(setAddStudentCategoryDetailLoading(true))

    try {
        const { studentUserCategoryList } = getState().student
        const requestData = {
            params: { studentCategoryId: studentCategoryId }
        }
        const response = await studentService.deleteStudentCategoryDetailByStudentCategoryId(requestData)
        if (response.status === 200) {
            dispatch(setStudentUserCategoryList(
                studentUserCategoryList?.studentUserCategoryList?.filter((studentUserCategory) => (
                    studentUserCategory.studentCategoryId !== studentCategoryId
                ))
            ))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddStudentCategoryDetailErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setAddStudentCategoryDetailLoading(false))
    }
}
