import {
    Container
} from './HamburgerMenuStyle';

const HamburgerMenu = ({ visible, children }) => {

    const containerVarients = { close: { x: 400 }, open: { x: 0 } }

    const transition = { duration: 0.6, ease: [0.04, 0.62, 0.23, 0.98] }

    return (
        <Container
            variants={containerVarients}
            initial={'close'}
            animate={visible ? 'open' : 'close'}
            transition={transition}
        >
            {children}
        </Container>
    )
}

export default HamburgerMenu;
