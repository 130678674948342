import { combineReducers } from 'redux';

import pageInfoReducer from 'redux/pageInfo/pageInfo.reducer';
import localSlice from 'redux/local/local.slice';
import masterSlice from 'redux/master/master.slice';
import authSlice from 'redux/auth/auth.slice';
import userSlice from 'redux/user/user.slice';
import tutorSlice from 'redux/tutor/tutor.slice';
import reviewSlice from 'redux/review/review.slice';
import courseSlice from 'redux/course/course.slice';
import studentSlice from 'redux/student/student.slice';
import todoSlice from 'redux/todo/todo.slice';
import eventSlice from 'redux/event/event.slice';
import availabilitySlice from 'redux/availability/availability.slice';
import bookingSlice from 'redux/booking/booking.slice';
import walletSlice from 'redux/wallet/wallet.slice';
import paymentSlice from 'redux/payment/payment.slice';
import preferenceSlice from 'redux/preference/preference.slice';
import userLanguageSlice from 'redux/culture/culture.slice';
import categorySlice from "redux/category/category.slice";
import referralSlice from "redux/referral/referral.slice";
import offeringSlice from 'redux/offering/offering.slice';
import templateSlice from "redux/template/template.slice";
import segmentSlice from 'redux/segment/segment.slice';
import documentSlice from 'redux/document/document.slice';
import tutorOnboardingSlice from 'redux/tutorOnboarding/tutorOnboarding.slice';
import meetingSlice from 'redux/meeting/meeting.slice';
import firebaseSlice from 'redux/firebase/firebase.slice';
import notificationSlice from 'redux/notification/notification.slice';
import companySlice from 'redux/company/company.slice';
import needTutorSlice from 'redux/needTutor/needTutor.slice';

import preferencesSlice from 'pages/auth/preferences/store/PreferencesSlice';
import subscriptionSlice from 'pages/auth/subscriptions/store/SubscriptionSlice';
import buyGiftCardSlice from 'pages/global/buyGiftCard/store/GiftCardSlice';
import myDashboardSlice from 'pages/auth/dashboard/store/DashboardSlice';
import mySettingsSlice from 'pages/auth/settings/store/MySettingsSlice';
import offersSlice from 'pages/auth/offersAndDiscount/store/OffersSlice';


const mainRootReducer = combineReducers({
    pageInfo: pageInfoReducer,
    local: localSlice,
    master: masterSlice,
    auth: authSlice,
    user: userSlice,
    tutor: tutorSlice,
    review: reviewSlice,
    course: courseSlice,
    student: studentSlice,
    todo: todoSlice,
    event: eventSlice,
    availability: availabilitySlice,
    booking: bookingSlice,
    referral: referralSlice,
    wallet: walletSlice,
    payment: paymentSlice,
    preference: preferenceSlice,
    userLanguage: userLanguageSlice,
    category: categorySlice,
    offering: offeringSlice,
    template: templateSlice,
    segment: segmentSlice,
    document: documentSlice,
    tutorOnboarding: tutorOnboardingSlice,
    meeting: meetingSlice,
    firebase: firebaseSlice,
    notification: notificationSlice,
    company: companySlice,
    needTutor: needTutorSlice,

    preferences: preferencesSlice,
    subscriptions: subscriptionSlice,
    buyGiftCard: buyGiftCardSlice,
    dashboard: myDashboardSlice,
    mySettings: mySettingsSlice,
    offers: offersSlice,
});

export default mainRootReducer;