import { useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import CompanyCourseCard from "components/companyCourseCard/CompanyCourseCard";

import { getCourseListByCompanyId } from 'redux/company/company.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearCompanyCourseList } from "redux/company/company.slice";

const Courses = () => {
    const { companyPublicProfile, companyCourseList } = useAppState((state) => state.company)

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        if (companyPublicProfile?.companyPublicProfile?.companyId) {
            dispatcher(getCourseListByCompanyId(companyPublicProfile?.companyPublicProfile?.companyId))
        }

        return () => {
            dispatcher(setClearCompanyCourseList())
        }
    }, [companyPublicProfile?.companyPublicProfile?.companyId])

    return (
        <div className={"flex items-center justify-center px-5 md:px-0"} id={"COURSES"}>
            <div className={"max-w-full md:max-w-3xl lg:max-w-5xl w-full"}>
                <div className={"space-y-3 p-5 bg-white"}>
                    <div className={"flex items-center justify-between"}>
                        <span className={"font-bodyPri font-semibold text-text-800 text-xl"}>
                            {"Courses"}
                        </span>
                        <Link
                            to={`/company/${companyPublicProfile?.companyPublicProfile?.companyId}/course`}
                            className={"font-bodyPri font-normal text-text-800 text-base"}
                        >
                            {"View All"}
                        </Link>
                    </div>
                    <div className={clsx(
                        "grid grid-flow-col gap-5 p-5 overflow-hidden overflow-x-scroll",
                        "scrollbar scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-h-sm",
                        "hover:scrollbar-thumb-divider-lightDark hover:scrollbar-track-divider-darkLight"
                    )}>
                        {companyCourseList?.companyCourseList?.length > 0 &&
                            companyCourseList?.companyCourseList?.map((course, index) => (
                                <CompanyCourseCard
                                    key={index}
                                    course={course}
                                />
                            ))}
                        {companyCourseList?.companyCourseList?.length === 0 &&
                            <span className={"font-bodyPri font-normal text-text-800 text-md tracking-wide"}>
                                {"No course Found!"}
                            </span>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Courses