import format from 'string-format';

import { baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/booking/booking.const"


class BookingService {

    getTutorBookingList = async ({ params, query }) => {
        const response = baseAuthApiInstance().get(
            `${url.GET_TUTOR_BOOKING_LIST}/${params.tutorId}`,
            { params: query }
        )

        return response
    }

    getStudentBookingList = async ({ params, query }) => {
        const response = baseAuthApiInstance().get(
            `${url.GET_STUDENT_BOOKING_LIST}/${params.studentId}`,
            { params: query }
        )

        return response
    }

    getBookingDetail = async ({ params }) => {
        const response = baseAuthApiInstance().get(
            `${url.GET_BOOKING_DETAIL}/${params.bookingId}`
        )

        return response
    }

    addBooking = async ({ body }) => {
        const response = await baseAuthApiInstance().post(
            url.ADD_BOOKING,
            body
        )

        return response
    }

    confirmAddBookingByBookingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().post(
            `${url.CONFIRM_ADD_BOOKING_BY_BOOKING_ID}/${params.bookingId}`,
            body
        )

        return response
    }

    getBookingConfirmationByBookingId = async ({ params }) => {
        const response = await baseAuthApiInstance().get(
            format(url.GET_BOOKING_CONFIRMATION_BY_BOOKING_ID, params)
        )

        return response
    }

    cancelBookingByBookingId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.CANCEL_BOOKING_BY_BOOKING_ID, params)
        )

        return response
    }

    cancelBookingByCourseId = async ({ params, body }) => {
        const response = await baseAuthApiInstance().put(
            format(url.CANCEL_BOOKING_BY_COURSE_ID, params)
        )

        return response
    }
}
export default new BookingService();