export const pageHeading = {
  heading: "My Classes",
  subHeading: "Find the list of your lessons, see details, clone and modify them!",
  headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/offerings.svg"
}

export const menuItems = {
  MAKE_PRIVATE: {
    label: 'Make Private',
    value: 'hidden'
  },
  MAKE_PUBLIC: {
    label: 'Make Public',
    value: 'public'
  },
  SHARE_LINK: {
    label: 'Share Link',
    value: 'share_link'
  },
  CLONE: {
    label: 'Clone',
    value: 'clone'
  },
  MODIFY_DETAILS: {
    label: 'Modify Details',
    value: 'modify_detail'
  },
}

export const menu = {
  DRAFT: {
    public: [
      {
        label: 'Make Private',
        value: 'hidden'
      },
      {
        label: 'Modify Details',
        value: 'modify_detail'
      }
    ],
    hidden: [
      {
        label: 'Make Public',
        value: 'public'
      },
      {
        label: 'Modify Details',
        value: 'modify_detail'
      }
    ],
  },
  UNDER_REVIEW: {
    public: [
      {
        label: 'Clone',
        value: 'clone'
      }
    ],
    hidden: [
      {
        label: 'Clone',
        value: 'clone'
      }
    ],
  },
  PUBLISHED: {
    public: [
      {
        label: 'Share Link',
        value: 'share_link'
      },
      {
        label: 'Clone',
        value: 'clone'
      },
      {
        label: 'Make Private',
        value: 'hidden'
      }
    ],
    hidden: [
      {
        label: 'Share Link',
        value: 'share_link'
      },
      {
        label: 'Clone',
        value: 'clone'
      },
      {
        label: 'Make Public',
        value: 'public'
      }
    ],
  },
  INACTIVE: {
    public: [
      {
        label: 'Clone',
        value: 'clone'
      },
    ],
    hidden: [
      {
        label: 'Clone',
        value: 'clone'
      },
    ],
  },
};
