export const data = {
  tabs: [
    {
      tab: "About Me",
     },
    {
      tab: "Skills",
    },
    {
      tab: "Topics",
    },
    {
      tab: "Classes",
    },
    {
      tab: "Reviews",
    },
  ],
};
