import { useState } from "react";
import clsx from "clsx";

import { FaCheck } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";

import TimeRangeSelection from './TimeRangeSelection';
import TimeRange from './TimeRange';

import { Button, IconWithTextButton } from "components/common-components/Buttons";

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setAvailability } from "redux/availability/availability.slice";

import { validateIsEndBeforeStart, validateIsTimeSlotExist } from 'utils/availability.utils';
import { dayjs, utcWeekDays, timeZone } from "utils/dateTime.utils";


const AvailabilityCard = ({ day }) => {
    const { availability } = useAppState((s) => s.availability)

    const dispatcher = useAppDispatcher()

    const [isAddNew, setIsAddNew] = useState(false);

    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

    const localDay = dayjs(utcWeekDays[day.toUpperCase()].date).tz(timeZone).format('dddd')

    const handleOnAdd = () => {
        if (isAddNew) {
            setSelectedTimeSlot(null)
        } else {
            setSelectedTimeSlot({
                startTime: dayjs().utc().format('HH:[00:00]'),
                endTime: dayjs().utc().format('HH:[15:00]')
            })
        }
        setIsAddNew(!isAddNew)
    }

    const handleOnCreate = () => {
        if (validateIsEndBeforeStart(day, selectedTimeSlot)) {
            alert("End time should be after the start time!")
            return;
        }

        const timeSlots = availability?.availability?.weeklyAvail[day]

        if (validateIsTimeSlotExist(day, selectedTimeSlot, timeSlots)) {
            alert("Slot is already exist!")
            return;
        }

        dispatcher(setAvailability({
            ...availability?.availability,
            weeklyAvail: {
                ...availability?.availability?.weeklyAvail,
                [day]: [...timeSlots, selectedTimeSlot]
            }
        }))
        setIsAddNew(false)
    }

    const handleOnUpdate = (oldTimeSlot, newTimeSlot) => {
        if (validateIsEndBeforeStart(day, newTimeSlot)) {
            alert("End time should be after the start time!")
            return;
        }

        let timeSlots = availability?.availability?.weeklyAvail[day].filter(slot => oldTimeSlot.startTime !== slot.startTime)

        if (validateIsTimeSlotExist(day, newTimeSlot, timeSlots)) {
            alert("Slot is already exist!")
            return;
        }

        timeSlots = availability?.availability?.weeklyAvail[day].map(slot => (
            oldTimeSlot.startTime === slot.startTime && oldTimeSlot.endTime === slot.endTime ? newTimeSlot : slot
        ))

        dispatcher(setAvailability({
            ...availability?.availability,
            weeklyAvail: {
                ...availability?.availability?.weeklyAvail,
                [day]: timeSlots
            }
        }))
    }

    const handleOnDelete = (selectedSlot) => {
        const timeSlots = availability?.availability?.weeklyAvail[day].filter(slot => slot.startTime !== selectedSlot.startTime)

        dispatcher(setAvailability({
            ...availability?.availability,
            weeklyAvail: {
                ...availability?.availability?.weeklyAvail,
                [day]: timeSlots
            }
        }))
    }

    const handleOnApplyAll = () => {
        dispatcher(setAvailability({
            ...availability?.availability,
            weeklyAvail: {
                Monday: availability?.availability?.weeklyAvail[day],
                Tuesday: availability?.availability?.weeklyAvail[day],
                Wednesday: availability?.availability?.weeklyAvail[day],
                Thursday: availability?.availability?.weeklyAvail[day],
                Friday: availability?.availability?.weeklyAvail[day],
                Saturday: availability?.availability?.weeklyAvail[day],
                Sunday: availability?.availability?.weeklyAvail[day],
            }
        }))
    }

    return (
        <div>
            <div className="w-full p-4 bg-blue-100 rounded-lg shadow-md">
                <div className="flex items-center justify-between">
                    <p className="font-bodyPri font-medium text-lg text-text-900">
                        {localDay}
                    </p>
                    <div className="flex items-center justify-center space-x-2">
                        {localDay === "Monday" &&
                            <Button onClick={handleOnApplyAll} className="hover:opacity-80 opacity-60" name="Apply to all" size="small" style="primary" />
                        }
                        <button onClick={handleOnAdd} className={clsx(
                            "inline-flex items-center justify-center p-2",
                            "transform rounded-full hover:scale-105",
                            isAddNew ? "hover:bg-red-200" : "hover:bg-blue-200"
                        )}>
                            {!isAddNew && <FaPlusCircle className={"text-xl text-blue-400 hover:text-blue-600"} />}
                            {isAddNew && <AiFillCloseCircle className={"text-xl text-red-400 hover:text-red-600"} />}
                        </button>
                    </div>
                </div>
            </div>
            <div className="px-2 divide-y divide-blue-200 bg-blue-50 rounded-b-xl">

                {/* Edit/View existing schedules */}
                {availability?.availability?.weeklyAvail && availability?.availability?.weeklyAvail[localDay].length > 0 ? (
                    availability?.availability?.weeklyAvail[localDay].map((timeSlot, index) => (
                        <TimeRange
                            key={index}
                            day={day}
                            timeSlot={timeSlot}
                            updateTimeSlot={handleOnUpdate}
                            deleteTimeSlot={handleOnDelete}
                        />
                    ))
                ) : (
                    <div className="py-5">
                        <p className="font-bodyPri font-semibold text-xl text-text-500 text-center">
                            No Schedules Found
                        </p>
                    </div>
                )}

                {/* Add new schedule */}
                {isAddNew && (
                    <div className="flex flex-col items-center w-full p-4 space-x-4 lg:flex-row">
                        <TimeRangeSelection
                            timeSlot={selectedTimeSlot}
                            setTimeSlot={setSelectedTimeSlot}
                        />
                        <IconWithTextButton
                            icon={<FaCheck />}
                            placeholder="Create"
                            className="text-green-700 bg-green-300 border shadow-sm"
                            onClick={handleOnCreate}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AvailabilityCard