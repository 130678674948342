import paymentService from 'redux/payment/payment.service';
import {
    setMakePaymentLoading,
    setMakePayment,
    setMakePaymentErrorMsg,
} from 'redux/payment/payment.slice';


export const addMakePayment = () => async (dispatch, getState) => {
    dispatch(setMakePaymentLoading(true))
    const { makePaymentPayload } = getState()?.payment?.makePayment

    try {
        const requestData = {
            body: {
                firstName: makePaymentPayload?.firstName,
                lastName: makePaymentPayload?.lastName,
                email: makePaymentPayload?.email,
                currency: makePaymentPayload?.currency,
                amount: parseInt(makePaymentPayload?.totalAmountToPay),
                paymentMethodId: makePaymentPayload?.paymentMethodId,
                description: makePaymentPayload?.description
            }
        }
        const response = await paymentService.addMakePayment(requestData)
        if (response.status === 200) {
            dispatch(setMakePayment(response.data))
        } else {
            dispatch(setMakePaymentErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error.message)
        dispatch(setMakePaymentErrorMsg(error.message))
    } finally {
        dispatch(setMakePaymentLoading(false))
    }
}