import tw, { styled } from "twin.macro";

export const StyledChatFilterModal = styled.div`
  ${tw`absolute inset-1/2 text-center z-30 w-64 h-[fit-content] py-5 px-7 rounded-md bg-white`}

  h3 {
    ${tw`text-lg text-primary-main font-semibold mb-2.5 border-b border-dotted border-primary-main pb-2.5`}
  }
`;

export const StyledChatFilterModalListItem = styled.div(({ selected }) => [
  tw`text-center p-1.5 text-sm font-semibold cursor-pointer text-primary-main mb-1.5 bg-primary-main bg-opacity-20 rounded-md`,
  selected && tw`opacity-40`,
]);
