import tw, { styled } from "twin.macro";

export const StyledChatViewSendMessage = styled.div`
  ${tw`flex items-center absolute bottom-0 left-0 w-full  mb-2.5 z-10`}
  div {
    ${tw`mx-0.5 lg:(mx-2)`}
  }
  svg {
    ${tw`cursor-pointer`}
  }
`;

export const StyledChatViewSendMessageInput = styled.input(
  ({ textMessageError }) => [
    tw`relative outline-none border border-gray-300 rounded-md p-2 w-full`,
    textMessageError && tw`border-secondary-dark`,
  ]
);

export const StyledChatViewSendMessageTyping = styled.p`
  ${tw`absolute bottom[100%] z-50 text-xs font-semibold pl-0.5 text-gray-400`}
`;

export const StyledChatViewSendMessageAttachInput = styled.input`
  ${tw`invisible height[0px] width[0px]`}
`;

export const StyledChatViewSendMessageAttachLabel = styled.label`
  ${tw`flex`}
`;
