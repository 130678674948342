import { useEffect, useState } from "react";
import clsx from "clsx";

import InputPasswordBox from "components/modals/verifyMobileNoModal/InputPasswordBox";
import NewMobileNoVerificationBox from "components/modals/verifyMobileNoModal/NewMobileNoVerificationBox";
import ShowAndVerifyOtp from "components/modals/verifyMobileNoModal/ShowAndVerifyOtp";

import { useAppState } from "hooks/useStore";

const VerifyMobileNoModal = () => {
    const { userGeneralDetailPayload } = useAppState((state) => state.user)

    const [inputPassword, setInputPassword] = useState("")
    const [isInputPasswordError, setIsInputPasswordError] = useState(false)
    const [isInputPasswordVerified, setIsInputPasswordVerified] = useState(false)
    const [isCodeSent, setIsCodeSent] = useState(false)

    useEffect(() => {
        setInputPassword("")
        if (userGeneralDetailPayload?.userGeneralDetailPayload?.isOnlyVerifyMobileNo) {
            setIsCodeSent(true)
        }

        return () => {
            setInputPassword("")
            setIsInputPasswordError(false)
            setIsInputPasswordVerified(false)
            setIsCodeSent(false)
        }
    }, [userGeneralDetailPayload?.userGeneralDetailPayload?.isOnlyVerifyMobileNo])

    const onConfirm = () => {
        if (!inputPassword) {
            alert("Please Enter Password!")
            return;
        }
        if (inputPassword !== userGeneralDetailPayload?.userGeneralDetailPayload?.password) {
            setIsInputPasswordError(true)
            return;
        }
        setIsInputPasswordVerified(true)
        setInputPassword("")
        setIsInputPasswordError(false)
    }

    return (
        <div className={"space-y-5 overflow-x-hidden"}>
            <div className={"min-h-[18rem] block space-y-8 pt-5"}>
                {(!isInputPasswordVerified && !isCodeSent) &&
                    <InputPasswordBox
                        isInputPasswordError={isInputPasswordError}
                        inputPassword={inputPassword}
                        setInputPassword={setInputPassword}
                    />
                }
                {(isInputPasswordVerified && !isCodeSent) &&
                    <NewMobileNoVerificationBox
                        setIsCodeSent={setIsCodeSent}
                    />
                }
                {isCodeSent &&
                    <ShowAndVerifyOtp
                        setIsCodeSent={setIsCodeSent}
                    />
                }
            </div>
            {(!isInputPasswordVerified && !isCodeSent) &&
                <div className={"flex justify-end px-5"}>
                    <div
                        className={clsx(
                            "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                            "font-buttons font-normal text-base",
                            "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                        )}
                        onClick={onConfirm}
                    >
                        {"Confirm"}
                    </div>
                </div>
            }
        </div>
    )
}

export default VerifyMobileNoModal