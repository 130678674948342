import { userRoles } from 'redux/auth/auth.const';


export const pageHeading = {
    heading: "App Settings",
    subHeading: "Choose or update all the settings of your apps on mobile and web!",
    headingPicUrl: "https://edulyte-docs.s3.ap-southeast-2.amazonaws.com/website-image/page-header/settings.svg"
}

export const navigationOptions = [
    { label: "General", to: "/general", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Billing Info", to: "/billing-info", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Payment Methods", to: "/payment-methods", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Payout Methods", to: "/payout-methods", roles: [userRoles.TUTOR.value] },
    { label: "Calendar", to: "/calender", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Notifications", to: "/notifications", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    { label: "Social Settings", to: "/social-settings", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    // { label: "Rate App", to: "/rate-app", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
    // { label: "About", to: "/about", roles: [userRoles.TUTOR.value, userRoles.STUDENT.value] },
];

export const languagesList = {
    ENGLISH: {
        label: "English",
        value: "english"
    },
    HINDI: {
        label: "Hindi",
        value: "hindi"
    },
    NEDERLANDS: {
        label: "Nederlands",
        value: "nederlands"
    },
    ITALIANO: {
        label: "Italiano",
        value: "italiano"
    },
    POLSKI: {
        label: "Polski",
        value: "polski"
    }
}

export const timezoneList = {
    
}

export const userSocialMediaLinks = [
    {
        userSocialId: 1,
        userSocialName: "facebook",
        userSocialLinks: "https://facebook.com/deepmala_sharma",
    },
    {
        userSocialId: 2,
        userSocialName: "instagram",
        userSocialLinks: "https://instagram.com/deepmala_sharma",
    },
    {
        userSocialId: 3,
        userSocialName: "twitter",
        userSocialLinks: "https://twitter.com/deepmala_sharma",
    },
    {
        userSocialId: 4,
        userSocialName: "linkedin",
        userSocialLinks: "https://linkedin.com/deepmalaSharma",
    },
]

export const userSocialMediaBtnConst = {
    facebook: {
        value: "facebook"
    },
    instagram: {
        value: "instagram"
    },
    twitter: {
        value: "twitter"
    },
    linkedin: {
        value: "linkedin"
    }
}

export const userSocialMediaLinksConst = {
    FACEBOOK: {
        label: "Facebook",
        value: "facebook",
        placeholder: "https://facebook.com/your_facebook_name",
        demoKey1: "https://facebook.com/your_facebook_name",
        demoKey2: "",
        socialLinkUrl: "https://facebook.com/"
    },
    INSTAGRAM: {
        label: "Instagram",
        value: "instagram",
        placeholder: "https://instagram.com/your_instagram_name",
        demoKey1: "https://instagram.com/your_instagram_name",
        demoKey2: "or @your_instagram_name",
        socialLinkUrl: "https://instagram.com/"
    },
    TWITTER: {
        label: "Twitter",
        value: "twitter",
        placeholder: "https://twitter.com/your_twitter_name",
        demoKey1: "https://twitter.com/your_twitter_name",
        demoKey2: "@Your_Twitter_Name",
        socialLinkUrl: "https://twitter.com/"
    },
    LINKEDIN: {
        label: "Linkedin",
        value: "linkedin",
        placeholder: "https://linkedin.com/yourlinkedinname",
        demoKey1: "https://linkedin.com/yourlinkedinname",
        demoKey2: "",
        socialLinkUrl: "https://linkedin.com/"
    }
}

export const timeFormatConst = {
    HRS_12: {
        label: "12H",
        value: "hrs_12"
    },
    HRS_24: {
        label: "24H",
        value: "hrs_24"
    } 
}