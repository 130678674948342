import { useState } from 'react'
import clsx from 'clsx';

import { FaSpinner } from 'react-icons/fa'

import { messageTemplateList } from 'components/modals/messageTemplateModal/MessageTemplateModal.data';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setMessageTemplate } from 'redux/firebase/firebase.slice';


const MessageTemplateModal = () => {
    const { messageTemplate } = useAppState(s => s.firebase)

    const dispatcher = useAppDispatcher()

    const [selectedMessage, setSelectedMessage] = useState('')
    const [inputMessage, setInputMessage] = useState('')

    const minCharacterLength = 20
    const maxCharacterLength = 50

    const isSendEnable = !["", "Other"].includes(selectedMessage) || (selectedMessage === "Other" && inputMessage.length > 20 && inputMessage.length < 50)

    const handleSendMessage = () => {
        if (!isSendEnable) return;

        dispatcher(setMessageTemplate({
            ...messageTemplate,
            isLoading: true,
            message: (selectedMessage === 'Other' ? inputMessage : selectedMessage),
            errorMsg: null
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={'min-h-[24rem] block space-y-5 pt-5'}>
                <div className={"w-full space-y-3"}>
                    <div className={"mx-3 flex flex-col items-start justify-start gap-5"}>
                        {messageTemplateList?.map((messageTemp, index) => (
                            <div key={index}
                                onClick={() => {
                                    setSelectedMessage(messageTemp.message);
                                    if (messageTemplate?.errorMsg) {
                                        dispatcher(setMessageTemplate({
                                            ...messageTemplate,
                                            isLoading: false,
                                            errorMsg: null
                                        }))
                                    }
                                }}
                                className={clsx(
                                    "px-3 py-1 rounded-lg border-2 border-primary-dark text-primary-dark cursor-pointer",
                                    "hover:bg-primary-dark hover:text-text-50",
                                    selectedMessage === messageTemp.message && "!bg-primary-dark !text-text-50"
                                )}
                            >
                                <span className={"font-bodyPri font-medium text-base"}>
                                    {messageTemp.message}
                                </span>
                            </div>
                        ))}
                    </div>
                    {selectedMessage === "Other" &&
                        <div className={"flex flex-col items-start gap-3 px-3"}>
                            <textarea
                                className={clsx(
                                    "w-full px-5 py-3 bg-background-light rounded-lg resize-none",
                                    "outline-none border-2 border-primary-dark focus:shadow-all-md focus:shadow-primary-light focus:border-primary-dark",
                                    "font-bodyPri font-normal text-base text-text-800 tracking-wide"
                                )}
                                minLength={minCharacterLength}
                                maxLength={maxCharacterLength}
                                rows={2}
                                placeholder="Write here ...."
                                name={"message"}
                                value={inputMessage}
                                onChange={(event) => {
                                    setInputMessage(event.target.value);
                                    if (messageTemplate?.errorMsg) {
                                        dispatcher(setMessageTemplate({
                                            ...messageTemplate,
                                            isLoading: false,
                                            errorMsg: null
                                        }))
                                    }
                                }}
                            />
                            <div className={"w-full flex justify-between"}>
                                <span className={clsx(
                                    "font-bodyPri font-normal text-xs text-text-800",
                                    (inputMessage.length < minCharacterLength || inputMessage.length > maxCharacterLength) && "text-red-500"
                                )}>
                                    {`Please enter between ${minCharacterLength} and ${maxCharacterLength} characters`}
                                </span>
                                <span className="flex justify-end font-bodyPri font-normal text-text-800 text-xs">
                                    {inputMessage.length + "/" + maxCharacterLength}
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={"flex justify-between"}>
                <div className={"flex items-center justify-center gap-2"}>
                    {messageTemplate?.isLoading &&
                        <>
                            <span className={"font-bodyPri font-normal text-base text-text-700"}>
                                {"Loading"}
                            </span>
                            <FaSpinner className="text-primary-main animate-spin" />
                        </>
                    }
                    {messageTemplate?.errorMsg &&
                        <span className={"font-bodyPri font-normal text-base text-red-500"}>
                            {messageTemplate?.errorMsg}
                        </span>
                    }
                </div>
                <span onClick={handleSendMessage} className={clsx(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50",
                    !isSendEnable && "text-secondary-light border-secondary-light hover:bg-white hover:text-secondary-light"
                )}>
                    {"Send"}
                </span>
            </div>
        </div>
    )
}

export default MessageTemplateModal