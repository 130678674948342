import React from "react";

//Helper functions
import getTimestamps from "../../helpers/getTimestamps";

//Style imports
import {
  StyledGroupNotificationListItem,
  StyledGroupNotificationListItemName,
  StyledGroupNotificationListItemDate,
} from "./GroupNotificationListItem.styles";

export default function GroupNotificationListItem({ notification }) {
  const { groupName, sentAt, type } = notification;
  const timeStamp = getTimestamps(sentAt);
  const groupNameToDisplay =
    groupName.length > 23 ? `${groupName.slice(0, 23)}...` : groupName;

  function notificationText() {
    if (type === "text") return "Message";
    if (type === "paymentRequest") return "Payment request";
    if (type === "course") return "Course";
    if (type === "file") return "File";
  }

  const getNotificationText = notificationText();

  return (
    <StyledGroupNotificationListItem>
      <p>
        <i>{`${getNotificationText} in`} </i>
        <StyledGroupNotificationListItemName>{`${groupNameToDisplay} `}</StyledGroupNotificationListItemName>
      </p>

      <StyledGroupNotificationListItemDate>
        {timeStamp}
      </StyledGroupNotificationListItemDate>
    </StyledGroupNotificationListItem>
  );
}
