import { useEffect } from 'react';

import { pageHeading } from 'pages/auth/schedule/data';

import ScheduleStatistics from "pages/auth/schedule/components/ScheduleStatistics";
import ScheduleFilter from "pages/auth/schedule/components/ScheduleFilter";
import ScheduleCalendar from "pages/auth/schedule/components/ScheduleCalendar";
import Todos from "pages/auth/schedule/todos/Todos";

import PageHeader from 'components/pageHeader/PageHeader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setClearUserEventList } from 'redux/event/event.slice';
import { getUserEventListByUserId } from 'redux/event/event.request';
import { setPageInfo } from 'redux/pageInfo/pageInfo.request';

import { pagesInfo } from 'utils/pagesInfo';


const SchedulePage = () => {
  const { user } = useAppState(s => s.user)
  const { currentPageInfo } = useAppState(s => s.pageInfo)

  const dispatcher = useAppDispatcher()

  useEffect(() => {
    dispatcher(setPageInfo(currentPageInfo, pagesInfo.SCHEDULE))
  }, [dispatcher, currentPageInfo])

  useEffect(() => {

    dispatcher(getUserEventListByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearUserEventList())
    }
  }, [])

  return (
    <div className="h-full w-full px-5 pb-5 mx-auto min-h-screen flex flex-col gap-3">
      <PageHeader pageHeading={pageHeading} />
      <ScheduleStatistics />
      <ScheduleFilter />
      <ScheduleCalendar />
      <Todos />
    </div>
  );
};

export default SchedulePage;