import clsx from "clsx";

import { FaInfoCircle } from "react-icons/fa";

const PageHeader = ({ pageHeading, toolTipText = null }) => {
    return (
        <div className="bg-white rounded-lg p-5">
            <div className="flex justify-between items-center">
                <div className={'flex flex-col items-start gap-1'}>
                    <div className={"flex items-start justify-start gap-1"}>
                        <span className="font-tagLine font-bold text-3xl text-text-900">
                            {pageHeading.heading}
                        </span>
                        {toolTipText &&
                            <div className={"relative has-tooltip cursor-pointer"}>
                                <FaInfoCircle className="inline text-md text-text-500 hover:text-text-700" />
                                <span
                                    className={clsx(
                                        "w-56 px-2 py-0.5 text-xs rounded shadow-lg bg-text-300 text-text-800 tooltip top-6.5",
                                        "flex flex-col items-start justify-start overflow-hidden"
                                    )}
                                >
                                    {toolTipText}
                                </span>
                            </div>
                        }
                    </div>
                    <span className="font-bodyPri font-medium text-base text-text-600">
                        {pageHeading.subHeading}
                    </span>
                </div>
                <div className={"w-20 h-20"}>
                    <img src={pageHeading.headingPicUrl} alt={pageHeading.heading} className={"w-full h-full object-cover"} />
                </div>
            </div>
        </div>
    )
}

export default PageHeader