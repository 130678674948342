import { baseAuthApiInstance } from "apiInstances/axios.apiInstance"
import { url } from "redux/template/template.const"

class TempCourseService {
    getTempCourseList = async ({ query }) => {
        const response = await baseAuthApiInstance().get(
            url.GET_TEMP_COURSE_LIST,
            { params: query }
        )
        return response;
    }
}
export default new TempCourseService()