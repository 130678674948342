import tw, { styled } from "twin.macro";

export const StyledGroupDetailsContainer = styled.div`
  ${tw`flex absolute top[0] left[0] flex-col justify-center items-center width[100vw] height[100vh] bg-white z-index[1000] md:(max-w-sm height[80vh] px-0) lg:(max-w-xl px-0)`}
`;

export const StyledGroupDetailsHeader = styled.div`
  ${tw`flex flex-col justify-center items-center px-5 py-5`}
`;

export const StyledGroupDetailsTitle = styled.p`
  ${tw`text-center text-base font-bold  mt-2 text-primary-dark`}
`;

export const StyledGroupDetailsSubtitle = styled.p`
  ${tw`text-center text-base text-gray-400`}
`;

export const StyledGroupDetailsMember = styled.div`
  ${tw`flex justify-start items-center p-2`}
`;

export const StyledGroupDetailsMemberImage = styled.img`
  ${tw`w-10 h-10 rounded-full mr-2`}
`;

export const StyledGroupDetailsMemberRole = styled.p`
  ${tw` text-sm text-gray-400 text-align[right]`}
`;

export const StyledGroupDetailsMemberName = styled.p`
  ${tw`text-base`}
`;

export const StyledGroupDetailsMemberList = styled.div`
  ${tw`max-height[400px] overflow-auto my-2 px-4`}
`;

export const StyledRemoveMemberButton = styled.button(({ loading }) => [
  tw`bg-primary-dark text-white text-xs py-1 px-2 rounded-md hover:cursor-pointer ml-3`,

  loading && tw`opacity-50 cursor-not-allowed`,
]);
