import React from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";

//style imporrts here
import { StyledChatFilter } from "./ChatFilter.styles";
import ChatFilterItem from "./ChatFilterItem";

export default function ChatFilter() {
  const { filters } = useChat();

  return (
    <StyledChatFilter>
      {filters.map((filter) => {
        return <ChatFilterItem key={filter} filter={filter} />;
      })}
    </StyledChatFilter>
  );
}
