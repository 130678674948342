export const sampleCourseThumbnail = [
    {
        image: 'https://source.unsplash.com/1600x1600/?tutor,nature',
    },
    {
        image: 'https://source.unsplash.com/1600x1600/?tutor,field'
    },
    {
        image: 'https://source.unsplash.com/1600x1600/?tutor,bolly'
    },
    {
        image: 'https://source.unsplash.com/1600x1600/?persons,public'
    },
    {
        image: 'https://source.unsplash.com/1600x1600/?mother,himalaya'
    },
    {
        image: 'https://source.unsplash.com/1600x1600/?land,student'
    },
    {
        image: 'https://source.unsplash.com/1600x1600/?traffic,student'
    },
]

export const pricingDiscountConst = [
    {
        label: "5%",
        value: 5
    },
    {
        label: "10%",
        value: 10
    },
    {
        label: "15%",
        value: 15
    },
    {
        label: "20%",
        value: 20
    }
]

export const sessionDruation = [
    {
        label: "30 min",
        value: 30,
    },
    {
        label: "60 min",
        value: 60,
    },
    {
        label: "90 min",
        value: 30,
    },
    {
        label: "120 min",
        value: 120,
    },
]

export const editBtnConst = {
    level: {
        value: 'level',
        isEnable: false,
    },
    language: {
        value: 'language',
        isEnable: false,
    },
    sessionDuration: {
        value: 'sessionDuration',
        isEnable: false,
    },
    sessionCount: {
        value: 'sessionCount',
        isEnable: false
    },
    studentCapacity: {
        value: 'studentCapacity',
        isEnable: false
    },
    startDate: {
        value: 'startDate',
        isEnable: false,
    },
    endDate: {
        value: 'endDate',
        isEnable: false,
    },
    weeklyTimeSlots: {
        value: 'weeklyTimeSlots',
        isEnable: false,
    },
}