import {
    Container,
    Content,
    Header,
    Heading,
    SubHeading,
    ItemContainer,
    FeaturedItemContainer,
} from './CategoryFeaturedStyle';
import { featuredData } from './CategoryFeaturedData';
import FeaturedItem from './FeaturedItem';
import { useNavigate } from 'react-router-dom';

const CategoryFeatured = () => {
    const navigate = useNavigate()

    const handleOnClick = () => {
        navigate("/skills")
    }

    return (
        <Container>
            <Content>
                <Header>
                    <Heading>{'What do you want to learn?'}</Heading>
                    <SubHeading>{'Tailored classes for your test preperation, language learning, hobby, skill development and much more.'}</SubHeading>
                </Header>
                <ItemContainer>
                    {featuredData.map((item, index) => (
                        <FeaturedItem key={index} item={item} />
                    ))}
                    <FeaturedItemContainer onClick={handleOnClick}>
                        {'See More'}
                    </FeaturedItemContainer>
                </ItemContainer>
            </Content>
        </Container>
    )
}

export default CategoryFeatured;
