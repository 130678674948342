import {
    setCompanyPublicProfileLoading,
    setCompanyPublicProfile,
    setCompanyPublicProfileErrorMsg,

    setAddCompanyPublicProfileLoading,
    setAddCompanyPublicProfileErrorMsg,

    setCompanyCourseListLoading,
    setCompanyCourseList,
    setCompanyCourseListErrorMsg,

    setEmployeeListLoading,
    setEmployeeList,
    setEmployeeListErrorMsg,

    setAddEmployeeLoading,
    setAddEmployeeErrorMsg,

    setCompanyReviewListLoading,
    setCompanyReviewList,
    setCompanyReviewListErrorMsg,

    setImportantLinksListLoading,
    setImportantLinksList,
    setImportantLinksListErrorMsg,

    setAddImportantLinksLoading,
    setAddImportantLinksErrorMsg,

    setSimilarCompanyListLoading,
    setSimilarCompanyList,
    setSimilarCompanyListErrorMsg,

    setFAQListLoading,
    setFAQList,
    setFAQListErrorMsg,

    setAddFAQLoading,
    setAddFAQErrorMsg
} from "redux/company/company.slice";

import {
    companyProfiles,
    courseList,
    employeeData,
    reviewsList,
    importantLinksList,
    similarCompanyList,
    dataFAQ
} from "pages/global/company/data";

export const getCompanyPublicProfileByCompanyName = (companyName) => async (dispatch) => {
    dispatch(setCompanyPublicProfileLoading(true))

    try {
        const filteredCompany = companyProfiles?.find((company) => company?.companyName === companyName)
        dispatch(setCompanyPublicProfile(filteredCompany))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyPublicProfileErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyPublicProfileLoading(false))
    }
}

export const updateCompanyPublicProfileByCompanyId = (companyId, body) => async (dispatch, getState) => {
    dispatch(setAddCompanyPublicProfileLoading(true))

    try {
        const { companyPublicProfile } = getState().company
        dispatch(setCompanyPublicProfile({ ...companyPublicProfile?.companyPublicProfile, bio: body }))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddCompanyPublicProfileErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddCompanyPublicProfileLoading(false))
    }
}

export const getCourseListByCompanyId = (companyId) => async (dispatch) => {
    dispatch(setCompanyCourseListLoading(true))

    try {
        const filteredCourseList = courseList?.filter((course) => course?.companyId === companyId)
        dispatch(setCompanyCourseList(filteredCourseList))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyCourseListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyCourseListLoading(false))
    }
}

export const getEmployeeListByCompanyId = (query, companyId) => async (dispatch, getState) => {
    dispatch(setEmployeeListLoading(true))

    try {
        let response = {}

        let filteredEmployeeList = employeeData?.filter((employees) => employees?.companyId === companyId)

        const { activePage, itemsPerPage } = query
        const { employeeList } = getState().company
        response["totalPages"] = Math.ceil(filteredEmployeeList?.length / itemsPerPage)
        response["activePage"] = activePage
        response["itemsPerPage"] = itemsPerPage
        response["list"] = filteredEmployeeList?.slice((activePage - 1) * itemsPerPage, activePage * itemsPerPage)
        const myData = {
            ...employeeList?.employeeList,
            totalPages: response.totalPages,
            activePage: response.activePage,
            itemsPerPage: response.itemsPerPage,
            list: employeeList?.employeeList
                ? [...employeeList?.employeeList?.list, ...response?.list]
                : response?.list,
        }
        dispatch(setEmployeeList(myData))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setEmployeeListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setEmployeeListLoading(false))
    }
}

export const createEmployeeByCompanyId = (body) => async (dispatch, getState) => {
    dispatch(setAddEmployeeLoading(true))

    try {
        const { employeeList } = getState().company
        const response = {
            ...employeeList?.employeeList,
            list: [...employeeList?.employeeList?.list, body]
        }
        dispatch(setEmployeeList(response))
    } catch (error) {
        console.log(error.response.data.message || error.response.data.error)
        dispatch(setAddEmployeeErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddEmployeeLoading(false))
    }
}

export const updateEmployeeDetailByEmployeeId = (employee) => async (dispatch, getState) => {
    dispatch(setAddEmployeeLoading(true))

    try {
        const { employeeList } = getState().company
        const filteredEmployeeList = employeeList?.employeeList?.list?.filter((people) => people?.employeeId !== employee?.employeeId)
        const response = {
            ...employeeList?.employeeList,
            list: [...filteredEmployeeList, employee]
        }
        dispatch(setEmployeeList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddEmployeeErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddEmployeeLoading(false))
    }
}

export const deleteEmployeeByEmployeeId = (employeeId) => async (dispatch, getState) => {
    dispatch(setAddEmployeeLoading(true))

    try {
        const { employeeList } = getState().company
        const filteredEmployeeList = employeeList?.employeeList?.list?.filter((employee) => employee?.employeeId !== employeeId)
        const response = {
            ...employeeList?.employeeList,
            list: filteredEmployeeList
        }
        dispatch(setEmployeeList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddEmployeeErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddEmployeeLoading(false))
    }
}

export const getCompanyReviewList = (query) => async (dispatch) => {
    dispatch(setCompanyReviewListLoading(true))

    try {
        let response = {}
        response = reviewsList
        dispatch(setCompanyReviewList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setCompanyReviewListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setCompanyReviewListLoading(false))
    }
}

export const getImportantLinksByCompanyId = (companyId) => async (dispatch) => {
    dispatch(setImportantLinksListLoading(true))

    try {
        const filteredImportantLinksList = importantLinksList.filter((links) => links?.companyId === companyId)
        dispatch(setImportantLinksList(filteredImportantLinksList))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setImportantLinksListErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setImportantLinksListLoading(false))
    }
}

export const createImportantLinks = (link) => async (dispatch, getState) => {
    dispatch(setAddImportantLinksLoading(true))

    try {
        const { importantLinksList } = getState().company
        dispatch(setImportantLinksList([...importantLinksList?.importantLinksList, link]))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddImportantLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddImportantLinksLoading(false))
    }
}

export const updateImportantLinksByLinkId = (link) => async (dispatch, getState) => {
    dispatch(setAddImportantLinksLoading(true))

    try {
        const { importantLinksList } = getState().company
        const filteredImportantLinksList = importantLinksList?.importantLinksList?.filter((links) => links?.linkId !== link?.linkId)
        dispatch(setImportantLinksList([...filteredImportantLinksList, link]))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddImportantLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddImportantLinksLoading(false))
    }
}

export const deleteImportantLinkByLinkId = (linkId) => async (dispatch, getState) => {
    dispatch(setAddImportantLinksLoading(true))

    try {
        const { importantLinksList } = getState().company
        const filteredImportantLinksList = importantLinksList?.importantLinksList?.filter((links) => links?.linkId !== linkId)
        dispatch(setImportantLinksList(filteredImportantLinksList))

    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddImportantLinksErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAddImportantLinksLoading(false))
    }
}

export const getSimilarCompanyList = () => async (dispatch) => {
    dispatch(setSimilarCompanyListLoading(true))

    try {
        const response = similarCompanyList
        dispatch(setSimilarCompanyList(response))
    } catch (error) {
        console.error(error?.response?.data.message || error?.response?.data?.error)
        dispatch(setSimilarCompanyListErrorMsg(error?.response?.data.message || error?.response?.data?.error))
    } finally {
        dispatch(setSimilarCompanyListLoading(false))
    }
}

export const getFAQListByCompanyId = (companyId) => async (dispatch, getState) => {
    dispatch(setFAQListLoading(true))

    try {
        const filteredFAQsList = dataFAQ.filter((faq) => faq?.companyId === companyId)
        dispatch(setFAQList(filteredFAQsList))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setFAQListErrorMsg(false))
    } finally {
        dispatch(setFAQListLoading(false))
    }
}

export const createFAQByFAQId = (body) => async (dispatch, getState) => {
    dispatch(setAddFAQLoading(true))

    try {
        const { FAQList } = getState().company
        const response = [...FAQList?.FAQList, body]
        dispatch(setFAQList(response))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFAQErrorMsg(false))
    } finally {
        dispatch(setAddFAQLoading(false))
    }
}

export const updateFAQByFAQId = (selectedFAQ) => async (dispatch, getState) => {

    dispatch(setAddFAQLoading(true))

    try {
        const { FAQList } = getState().company
        const filteredFAQList = FAQList?.FAQList?.filter((FAQ) => FAQ?.FAQId !== selectedFAQ?.FAQId)
        console.log(filteredFAQList)
        dispatch(setFAQList([...filteredFAQList, selectedFAQ]))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFAQErrorMsg(false))
    } finally {
        dispatch(setAddFAQLoading(false))
    }
}

export const deleteFAQByFAQId = (selectedFAQ) => async (dispatch, getState) => {
    dispatch(setAddFAQLoading(true))

    try {
        const { FAQList } = getState().company
        const filteredFAQList = FAQList?.FAQList?.filter((FAQ) => FAQ?.FAQId !== selectedFAQ?.FAQId)
        dispatch(setFAQList(filteredFAQList))
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAddFAQErrorMsg(false))
    } finally {
        dispatch(setAddFAQLoading(false))
    }
}