import masterService from 'redux/master/master.service';
import {
    setCountryListLoading,
    setCountryList,
    setCountryListErrorMsg,

    setCountryDetailLoading,
    setCountryDetail,
    setCountryDetailErrorMsg,
    
    setTimeZoneCountryDetailLoading,
    setTimeZoneCountryDetail,
    setTimeZoneCountryDetailErrorMsg,

    setStateListLoading,
    setStateList,
    setStateListErrorMsg,

    setCityListLoading,
    setCityList,
    setCityListErrorMsg,

    setCurrencyListLoading,
    setCurrencyList,
    setCurrencyListErrorMsg,

    setTimeZoneListLoading,
    setTimeZoneList,
    setTimeZoneListErrorMsg,

    setLanguageListLoading,
    setLanguageList,
    setLanguageListErrorMsg,

    setLanguageLevelListLoading,
    setLanguageLevelList,
    setLanguageLevelListErrorMsg,

    setProfListLoading,
    setProfList,
    setProfListErrorMsg,

    setTagListLoading,
    setTagList,
    setTagListErrorMsg,
} from 'redux/master/master.slice'


export const getMasterCountryList = () => async (dispatch) => {
    dispatch(setCountryListLoading(true));
    try {
        const response = await masterService.getMasterCountryList()
        if (response.status === 200) {
            dispatch(setCountryList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCountryListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCountryListLoading(false));
    }
}

export const getMasterCountryDetailByMasterCountryId = (masterCountryId) => async (dispatch) => {
    dispatch(setCountryDetailLoading(true));
    try {
        const requestData = {
            params: { masterCountryId: masterCountryId }
        }
        const response = await masterService.getMasterCountryDetailByMasterCountryId(requestData)
        if (response.status === 200) {
            dispatch(setCountryDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCountryDetailErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCountryDetailLoading(false));
    }
}

export const getTimeZoneMasterCountryDetailByTimeZone = (timeZone) => async (dispatch) => {
    dispatch(setTimeZoneCountryDetailLoading(true));
    try {
        const requestData = {
            params: { timeZone: timeZone.replace("/", "_") }
        }
        const response = await masterService.getTimeZoneMasterCountryDetailByTimeZone(requestData)
        if (response.status === 200) {
            dispatch(setTimeZoneCountryDetail(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setTimeZoneCountryDetailErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setTimeZoneCountryDetailLoading(false));
    }
}

export const getMasterStateList = (masterCountryId) => async (dispatch) => {
    dispatch(setStateListLoading(true))
    try {
        const requestData = {
            params: { masterCountryId: masterCountryId }
        }
        const response = await masterService.getMasterStateList(requestData)
        if (response.status === 200) {
            dispatch(setStateList(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setStateListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setStateListLoading(false))
    }
}

export const getMasterCityList = (masterStateId) => async (dispatch) => {
    dispatch(setCityListLoading(true))

    try {
        const requestData = {
            params: { masterStateId: masterStateId }
        }
        const response = await masterService.getMasterCityList(requestData)
        if (response.status === 200) {
            dispatch(setCityList(response.data.data))
        }
        else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCityListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCityListLoading(false))
    }
}

export const getMasterCurrencyList = () => async (dispatch) => {
    dispatch(setCurrencyListLoading(true))

    try {
        const response = await masterService.getMasterCurrencyList()
        if (response.status === 200) {
            dispatch(setCurrencyList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setCurrencyListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setCurrencyListLoading(false))
    }
}

export const getMasterTimezoneList = () => async (dispatch) => {
    dispatch(setTimeZoneListLoading(true))

    try {
        const response = await masterService.getMasterTimezoneList()
        if (response.status === 200) {
            dispatch(setTimeZoneList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setTimeZoneListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setTimeZoneListLoading(false))
    }
}

export const getMasterLanguageList = () => async (dispatch) => {
    dispatch(setLanguageListLoading(true))

    try {
        const response = await masterService.getMasterLanguageList()
        if (response.status === 200) {
            dispatch(setLanguageList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setLanguageListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setLanguageListLoading(false))
    }
}

export const getMasterLanguageLevelList = () => async (dispatch) => {
    dispatch(setLanguageLevelListLoading(true))

    try {
        const response = await masterService.getMasterLanguageLevelList()
        if (response.status === 200) {
            dispatch(setLanguageLevelList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setLanguageLevelListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setLanguageLevelListLoading(false))
    }
}

export const getMasterProficiencyList = () => async (dispatch) => {
    dispatch(setProfListLoading(true))

    try {
        const response = await masterService.getMasterProficiencyList()
        if (response.status === 200) {
            dispatch(setProfList(response.data.data))
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setProfListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setProfListLoading(false))
    }
}

export const getMasterTagList = () => async (dispatch) => {
    dispatch(setTagListLoading(true))

    try {
        const response = await masterService.getMasterTagList()
        if (response.status === 200) {
            dispatch(setTagList(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.message || error.response.error)
        dispatch(setTagListErrorMsg(error.response.message || error.response.error))
    } finally {
        dispatch(setTagListLoading(false))
    }
}