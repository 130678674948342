import { CheckCircleRounded, Error } from '@mui/icons-material';
import { PageContent } from 'components/generalComponent/StyledComponent';
import tw, { styled } from 'twin.macro';

import { ArrowForwardIos } from '@mui/icons-material';
import { motion } from 'framer-motion';

export const PageContentStyle = tw(PageContent)`
    grid grid-cols-12 bg-background-light
`;

const Content = tw.div`
    grid col-start-2 col-span-10 md:(col-start-3 col-span-8)
`;

export const Header = tw(Content)`
    w-full flex flex-col items-center justify-center gap-3 py-10
`;

export const Heading = tw.h2`
    font-tagLine font-bold text-4xl text-secondary-main text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-medium text-xl text-primary-dark text-center
`;

export const Description = tw.p`
    font-bodyPri font-normal text-base text-text-900 text-center leading-6 px-0 md:px-8 lg:px-32
`;

export const ProgressBarContainer = tw(Content)`
    flex flex-col justify-center gap-2 py-5 md:px-20 lg:px-40
`;

export const ProgressBarText = tw.div`
font-subHeading font-medium text-xl text-primary-dark text-center
`;

export const TasksContainer = tw(Content)`
    flex flex-col justify-center items-center w-full my-10 shadow
`;

export const Tasks = tw.div`
    w-full h-auto bg-white rounded-xl px-10 py-5 md:(px-16 py-10)
`;

export const TaskItemContainer = styled.div`
    ${tw`
        py-10 grid grid-cols-9
    `}
    ${({ index }) => index !== 0 && tw`
        border-t border-text-300
    `}
`;

export const TaskDetails = tw.div`
    grid col-span-9 lg:col-span-6 flex justify-start gap-3
`;

export const TaskNo = tw.span`
    font-bodyPri font-medium text-lg text-text-900
    px-2.5 py-0.5 h-auto self-start border-2 border-text-500 bg-white rounded-full
`;

export const TaskTitleDesc = tw.div`
    flex flex-col items-start justify-center gap-2
`;

export const TaskTitleText = tw.h3`
    font-subHeading font-semibold text-base text-text-900 text-left
`;

export const TaskDescText = tw.p`
    font-bodyPri font-normal text-sm text-text-900 text-left
`;

export const TaskAction = tw.div`
    grid col-span-9 lg:col-span-3
    flex flex-row lg:flex-col items-center justify-end gap-5 lg:(items-end justify-between gap-0)
    mt-3 lg:(mb-0 mt-0)
`;

export const Normal = styled.button`
   ${tw`
   font-buttons font-normal text-sm text-primary-dark hover:text-secondary-dark
    border border-primary-dark rounded hover:(text-text-50 bg-secondary-dark border-secondary-dark)
    py-1 w-40
   `}
   ${({ taskNo}) => taskNo === 10 && tw`
   border border-text-600 text-text-600 hover:(text-text-500 bg-text-300 border-text-300)
   `}
`;

export const Submited = tw.span`
    flex gap-1 text-yellow-500
    font-buttons font-normal italic text-base cursor-pointer
`;

export const UnderReview = tw.span`
    flex gap-1 text-secondary-main
    font-buttons font-normal italic text-base cursor-pointer
`;

export const Verified = tw.span`
    flex gap-1 text-green-500
    font-buttons font-normal italic text-base
`;

export const TaskTime = tw.span`
    font-bodyPri font-normal text-sm text-text-700
`;

export const VerifiedIcon = styled(CheckCircleRounded)`
    ${tw`
        text-green-500 text-2xl self-start
    `}
`;

export const SubmitedIcon = styled(Error)`
    ${tw`
        text-yellow-500 text-2xl self-start
    `}
`;

export const FAQContainer = tw(Content)`
    py-10
`;

export const NameFieldTextContainer = tw.span`
    flex flex-col items-center justify-center min-w-[20rem] md:min-w-[32rem] lg:min-w-[36rem] pt-5 gap-2
`;

export const OtpContainer = tw.span`
    sm:flex sm:flex-col md:flex lg:flex justify-center items-center gap-2
`;

export const NameField = tw.span`
    w-full flex flex-wrap justify-center font-bodySec text-base text-text-700 truncate
`;

export const OtpText = tw.span`
    font-medium font-bodyPri text-base text-text-900 whitespace-nowrap
`;

export const InputDigitTimerContainer = tw.span`
    flex flex-col justify-center items-center gap-2 pt-5
`;

export const DigitBox = styled.input`
    ${tw`
        w-8 h-8 py-2 px-2 border border-divider-dark text-base font-bodyPri text-text-900 bg-white rounded-sm focus:outline-none
    `}
    ${({ isOtpError }) => isOtpError && tw`
        w-8 h-8 py-2 px-2 border border-red-500 text-base font-bodyPri text-text-900 bg-white rounded-sm focus:outline-none
    `}
`;

export const ResendOtpContainer = styled.div`
    ${tw`
        font-bodyPri font-medium text-sm text-text-600
    `}
    ${({ minutes, seconds }) => (minutes === 0 && seconds === 0) && tw`
        font-bodyPri font-medium text-sm text-primary-main cursor-pointer
    `}
`;

export const VerifyButton = tw.span`
   font-buttons font-normal text-sm text-primary-dark hover:text-secondary-dark
    border border-primary-dark rounded hover:(text-text-50 bg-secondary-dark border-secondary-dark)
    px-3 py-1 w-40 text-center cursor-pointer whitespace-nowrap
`;

export const PersonalDetailContainer = motion(styled.section`
    ${tw`
        col-span-full pt-5 px-10 overflow-hidden
    `}
`);

export const TitleHeading = tw.span`
    md:col-start-1 md:col-span-3 lg:col-start-1 lg:col-span-5 flex flex-col items-start justify-start space-y-5
`;

export const TitleText = tw.span`
    font-bodyPri font-semibold text-text-900
`;

export const ResumeText = tw.span`
    font-bodyPri font-normal text-text-500 italic whitespace-nowrap
`;

export const ActionContainer = tw.span`
   md:col-start-7 md:col-span-1 lg:col-start-6 lg:col-span-3 flex flex-col items-start justify-start space-y-5
`;

export const UploadResumeButton = tw.button`
    font-buttons font-normal text-sm text-primary-dark hover:text-secondary-dark
    border border-primary-dark rounded hover:(text-text-50 bg-secondary-dark border-secondary-dark)
    px-3 py-1 w-40 text-center whitespace-nowrap
`;

export const ActionHeading = tw.span`
    font-bodyPri font-semibold text-text-900 text-center
`;

export const UploadEyeButtonContainer = tw.button`
    w-full flex justify-between items-center
`;

export const StatusContainer = tw.span`
    md:col-start-10 md:col-span-2 lg:col-start-10 lg:col-span-2 flex flex-col justify-start items-start space-y-5
`;

export const StatusHeading = tw.span`
    font-bodyPri font-semibold text-text-900
`;
export const StatusText = tw.span`
    font-subHeading font-bodySec text-base italic whitespace-nowrap
`;

export const InputFieldContainer = tw.span`
    border border-text-500 focus:outline-none
`;

export const VideoUrlContainer = tw.span`
    inline-flex mx-auto shadow-sm pt-5 px-12
`;

export const UploadVideoButton = tw.span`
    px-3 py-2 rounded-r-md bg-primary-light border border-primary-light hover:(text-text-50 bg-secondary-dark border-secondary-dark) 
    cursor-pointer font-buttons font-normal text-sm text-primary-dark
`;

export const AddIconContainer = tw.div`
    relative pt-5 px-12 gap-3 min-w-[42rem] flex flex-col justify-center items-center overflow-hidden cursor-pointer
`;

export const CategoryContainer = tw.span`
    col-start-1 col-span-4 space-y-5
`;

export const CategoryText = tw.span`
    font-bodyPri font-semibold text-text-900
`;

export const DescriptionContainer = tw.span`
    col-start-5 col-span-4
`;

export const DescriptionText = tw.span`
    font-bodyPri font-semibold text-text-900
`;

export const AddContainer = tw.span`
   col-span-full flex justify-center items-center border border-red-400
`;

export const AddTextSpan = tw.span`
    absolute bg-white opacity-75 font-bodyPri font-bold text-4xl text-text-500
`;

export const AddContainerText = tw.span`
    absolute bg-white opacity-50 font-bodyPri font-bold text-2xl text-text-500 tracking-wider whitespace-nowrap
`;

export const DocumentTableContainer = tw.div`
    col-span-full pt-5 px-12 md:px-8 sm:px-2
`;

export const SelectSlotContainer = tw.div`
    col-span-full pt-5 px-12 md:px-8 sm:px-2
`;

export const ArrowToggleIcon = motion(styled(ArrowForwardIos)``);

export const ReferencesCardContainer = tw.div`
    col-span-full pt-5
`;