import { useEffect, useState } from 'react';
import {
    AnchorLink,
    TopLink,
    SignUpCardContent,
    SignUpCardHeader,
    Heading,
    SubHeading,
    SignUpFormWrapper,
    DividerWrapper,
    SocialSignUpWrapper,
    CheckBoxForTips,
    SignUpCardFooter,
} from './SignUpCardStyle';

import SignUpForm from 'components/signUpForm/SignUpForm';
import HDividerWithText from 'components/hDividerWithText/HDividerWithText';
import SocialAuth from 'components/socialAuth/SocialAuth';

import { pagesInfo } from 'utils/pagesInfo';

import { createSignUp } from 'redux/auth/auth.request';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setSignUpPayload } from 'redux/auth/auth.slice';

import { useUser } from 'pages/auth/message/contexts/userContext';
import { otpStatus } from 'redux/notification/notification.const';

const edulyteTermsOfService = "https://www.edulyte.com/terms-of-service/"
const edulytePrivacyPolicy = "https://www.edulyte.com/privacy-policy/"

const SignUpCard = () => {
    const { logInSignUp } = useAppState((s) => s.auth)
    const { verifyOtp } = useAppState((s) => s.notification)

    const dispatcher = useAppDispatcher()
    const userContext = useUser()

    const [isCheckedForTips, setIsCheckedForTips] = useState(false);

    useEffect(() => {
        if (verifyOtp?.verifyOtp?.status === otpStatus.SUBMITTED.value) {
            dispatcher(createSignUp({
                email: logInSignUp?.signUpPayload?.email,
                firstName: logInSignUp?.signUpPayload?.firstName,
                lastName: logInSignUp?.signUpPayload?.lastName,
                companyId: 1,
                referralCode: logInSignUp?.signUpPayload?.referralCode
            }))
        }
    }, [verifyOtp?.verifyOtp])

    const submitSocialAuth = async (socialName) => {
        console.log(socialName, isCheckedForTips)
    }

    const handleTipsBox = (e) => {
        setIsCheckedForTips(e.target.checked)
        dispatcher(setSignUpPayload({
            ...logInSignUp?.signUpPayload,
            isNotificationEnabled: "disabled"
        }))
    }

    return (
        <>
            <TopLink>
                <AnchorLink to={pagesInfo.TEACH_WITH_US.pagePath}>{'Sign up as a teacher'}</AnchorLink>
            </TopLink>
            <SignUpCardContent>
                <SignUpCardHeader>
                    <Heading>{'Sign up free'}</Heading>
                    <SubHeading>
                        {'Already have an account?'}
                        <AnchorLink to={pagesInfo.LOG_IN.pagePath}>{'Log in'}</AnchorLink>
                    </SubHeading>
                </SignUpCardHeader>
                <SignUpFormWrapper>
                    {/* <SignUpForm formSubmit={formSubmit} /> */}
                    <SignUpForm />
                </SignUpFormWrapper>
                <DividerWrapper>
                    <HDividerWithText text={'or continue with'} />
                </DividerWrapper>
                <SocialSignUpWrapper>
                    <SocialAuth submitSocialAuth={submitSocialAuth} />
                </SocialSignUpWrapper>
                <CheckBoxForTips>
                    <input type='checkbox' onClick={(e) => handleTipsBox(e)} />
                    {`Please don't send me tips or marketing via email or SMS.`}
                </CheckBoxForTips>
                <SignUpCardFooter>
                    {"By signing up, I agree to "}
                    <a
                        href={edulyteTermsOfService}
                        rel={"noreferrer"}
                        target={"_blank"}
                        className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                    >
                        {"Terms of service"}
                    </a>,
                    <AnchorLink to={'#'}>
                        {" Community Guidelines"}
                    </AnchorLink>
                    {" and "}
                    <a
                        href={edulytePrivacyPolicy}
                        rel={"noreferrer"}
                        target={"_blank"}
                        className={"text-primary-main hover:text-secondary-dark text-sm font-bodyPri cursor-pointer"}
                    >
                        {"Privacy Policy"}
                    </a>
                </SignUpCardFooter>
            </SignUpCardContent>
        </>
    )
}

export default SignUpCard;
