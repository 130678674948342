import React, { useEffect } from "react";

//Context imports
import { useChat } from "../../contexts/chatContext";
import { useUser } from "../../contexts/userContext";

//Helper functions
import markMessagesRead from "../../helpers/markMessagesRead";

//Component imports
import ChatViewMessage from "../ChatViewMessage/ChatViewMessage";
import ChatViewPaymentRequest from "../ChatViewPaymentRequest/ChatViewPaymentRequest";
import ChatViewFile from "../ChatViewFile/ChatViewFile";
import ChatViewCourse from "../ChatViewCourse/ChatViewCourse";

//Style imports
import { StyledChatViewMessageList } from "./ChatViewMessageList.styles";

export default function ChatViewMessageList() {
  const { user } = useUser();
  const { messages, selectedChat, groupMembers } = useChat();
  const isGroup = selectedChat.type === "group";

  //Scroll to the bottom of message list
  useEffect(() => {
    const element = document.getElementById("messagelist");
    if (element) {
      element.scrollTo({
        top: element.scrollHeight,
        left: 0,
      });
    }
  });

  //Mark messages as seen
  useEffect(() => {
    if (groupMembers.length === 0) return;
    markMessagesRead(isGroup, groupMembers, messages, selectedChat, user);
  }, [
    selectedChat,
    isGroup,
    groupMembers,
    messages,
    groupMembers.length,
    user,
  ]);

  return (
    <StyledChatViewMessageList id="messagelist">
      {messages.map((message) => {
        if (message.type === "text") {
          return (
            <ChatViewMessage
              message={message}
              key={message.id}
              isGroup={isGroup}
            />
          );
        }
        if (message.type === "paymentRequest") {
          return (
            <ChatViewPaymentRequest
              message={message}
              key={message.id}
              isGroup={isGroup}
            />
          );
        }
        if (message.type === "file") {
          return (
            <ChatViewFile
              message={message}
              key={message.id}
              isGroup={isGroup}
            />
          );
        }
        if (message.type === "shareCourse")
          return (
            <ChatViewCourse
              message={message}
              key={message.id}
              isGroup={isGroup}
            />
          );
      })}
    </StyledChatViewMessageList>
  );
}
