import { baseApiInstance, baseCredApiInstance, baseAuthApiInstance } from "apiInstances/axios.apiInstance";
import { url } from "redux/auth/auth.const";


class AuthService {
    login = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.LOGIN,
            body
        )

        return response
    }

    getToken = async () => {
        const response = await baseCredApiInstance().get(
            url.GET_TOKEN
        )

        return response
    }

    createSignUp = async ({ body }) => {
        const response = await baseCredApiInstance().post(
            url.SIGN_UP,
            body
        )

        return response
    }

    createVerifyReferral = async ({ params }) => {
        const response = await baseApiInstance().get(
            `${url.VERIFY_REFERRAL_BY_REFERRAL_CODE}/${params.referralCode}`
        )
        return response
    }

    logout = async () => {
        const response = await baseAuthApiInstance().post(
            url.LOGOUT
        )

        return response
    }
}

export default new AuthService()