const employeeDetailPayload = {
    employeeId: null,
    firstName: "",
    lastName: "",
    profilePicUrl: "",
    about: "",
    designation: ""
}

const importantLinksDetailPayload = {
    linkId: null,
    link: "",
    name: "",
    logoUrl: ""
}

export const FAQPayload = {
    question: "",
    answer: ""
}

export const COMPANY_INITIAL_STATE = {
    companyPublicProfile: {
        isLoading: false,
        companyPublicProfile: null,
        errorMsg: null
    },
    addCompanyPublicProfile: {
        isLoading: false,
        errorMsg: null
    },

    companyCourseList: {
        isLoading: false,
        companyCourseList: null,
        errorMsg: null
    },

    employeeList: {
        isLoading: false,
        employeeList: null,
        errorMsg: null
    },
    addEmployee: {
        isLoading: false,
        errorMsg: null
    },
    employeeDetailPayload: {
        employeeDetailPayload: employeeDetailPayload
    },

    companyReviewList: {
        isLoading: false,
        companyReviewList: null,
        errorMsg: null
    },

    importantLinksList: {
        isLoading: false,
        importantLinksList: null,
        errorMsg: null
    },
    addImportantLinks: {
        isLoading: false,
        errorMsg: null
    },
    importantLinksDetailPayload: {
        importantLinksDetailPayload: importantLinksDetailPayload
    },

    similarCompanyList: {
        isLoading: false,
        similarCompanyList: null,
        errorMsg: null
    },

    FAQList: {
        isLoading: false,
        FAQList: null,
        errorMsg: null
    },
    addFAQ: {
        isLoading: false,
        errorMsg: null
    },
    FAQPayload: {
        FAQPayload: FAQPayload
    }
}