import { useState, useEffect, useRef } from "react"
import {
    NameField,
    OtpContainer,
    OtpText,
    InputDigitTimerContainer,
    DigitBox,
    ResendOtpContainer,
    VerifyButton
} from "pages/auth/tutorOnboard/TutorOnboardStyle"

import { docStatus } from "../TutorOnboardData"

import { useAppState } from "hooks/useStore"

const OtpBoxContainer = ({ task, openOtpBox, closeOtpContainer }) => {
    const { user } = useAppState((s) => s.user)
    const [generatedOtp, setGeneratedOtp] = useState()
    const [otp, setOtp] = useState(new Array(4).fill(''))
    const [digits, setDigits] = useState()
    const [isOtpError, setIsOtpError] = useState(false)
    const [minutes, setMinutes] = useState(1)
    const [seconds, setSeconds] = useState(59)

    const inputRef = useRef()

    useEffect(() => {
        setOtp([...otp.map((d, idx) => '')]);
        setGeneratedOtp(generateOTP())
    }, [])

    useEffect(() => {
        const otpMapping = otp.map(Number)
        const singleNumber = Number(otpMapping.join(''))
        setDigits(singleNumber)
    }, [otp])

    useEffect(() => {
        const timer = setInterval(() => {
            if (openOtpBox && seconds > 0) {
                setSeconds(seconds - 1)
            }
            if (openOtpBox && seconds === 0) {
                if (minutes === 0) {
                    clearInterval(timer)
                }
                else {
                    setMinutes(minutes - 1)
                }
            }
        }, 1000)
        if (!openOtpBox) {
            setSeconds(59)
            setMinutes(1)
        }
        return () => {
            clearInterval(timer)
        }
    })

    const generateOTP = () => {

        let digits = '0123456789';
        let OTP = '';
        for (let i = 0; i < 4; i++) {
            OTP += digits[Math.floor(Math.random() * 10)];
        }
        return OTP;
    }

    const handleOtpVerification = () => {
        if (generatedOtp == digits) {
            alert("Code Verified Succeessfully!")
            task.status = docStatus.statusName.verified
            closeOtpContainer()
        }
        else {
            alert("Invalid Code!")
            setIsOtpError(true)
        }
    }

    const handleOtpChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index) ? element.value : d)]);
        // focus next input
        if (element.nextSibling) {
            element.nextSibling.focus()
        }
    };

    const resendOtp = () => {
        setGeneratedOtp(generateOTP())
        alert("otp sent succeessfully")
        setMinutes(1)
        setSeconds(59)
    }

    return (
        <div>
            <NameField>
                {task.taskNo === 1 && `Email Address: ${user?.user?.email}`}
                {task.taskNo === 2 && `Mobile No.: ${user?.user?.mobileNo}`}
            </NameField>
            <OtpContainer>
                <OtpText>{"Enter Your Code: "}</OtpText>
                <InputDigitTimerContainer>
                    <div className='flex  gap-2'>
                        {otp.map((data, index) => (
                            <DigitBox
                                ref={inputRef}
                                isOtpError={isOtpError}
                                type='text'
                                maxLength={1}
                                key={index}
                                value={data}
                                onChange={e => handleOtpChange(e.target, index)}
                                onFocus={e => e.target.select()}
                            />
                        ))}
                    </div>
                    <ResendOtpContainer
                        onClick={resendOtp}
                        minutes={minutes}
                        seconds={seconds}
                    >
                        {minutes === 0 && seconds === 0 ? "Resend OTP" :
                            `Resend OTP in ${minutes < 10 ? '0' + minutes : minutes}: ${seconds < 10 ? '0' + seconds : seconds}`}
                    </ResendOtpContainer>
                </InputDigitTimerContainer>
                <VerifyButton onClick={handleOtpVerification}>{"Verify Code"}</VerifyButton>
                {generatedOtp}
            </OtpContainer></div>
    )
}

export default OtpBoxContainer