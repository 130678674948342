import clsx from "clsx";

export const TextAreaField: React.FC<{
  rows?: number;
  label?: string;
  className?: string;
  placeholder: string;
  disabled?: boolean;
  key?: string;
  testId?: string;
  error?: string;
  info?: string;
  onChange: (event: any) => void;
  value: string | "";
  inputProp?: any;
}> = ({
  rows = 3,
  label,
  className = "",
  placeholder = "Enter",
  disabled = false,
  key,
  error,
  info,
  inputProp,
  onChange,
  value,
  testId = "",
}) => (
  <div
    className={clsx(
      disabled && "border-none",
      "relative px-3 py-2 border border-gray-300 rounded-md shadow-sm ",
      "focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600"
    )}
  >
    {label && (
      <label
        htmlFor="name"
        className="absolute inline-block px-1 -mt-px text-xs font-medium text-gray-900 bg-white -top-2 left-2"
      >
        {label}
      </label>
    )}
    <textarea
      key={key}
      type="text"
      rows={rows}
      className={clsx(
        "block w-full mt-1 rounded-md focus:ring-indigo-500 focus:border-indigo-500",
        "focus:outline-none",
        disabled && "border-collapse"
      )}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      value={value}
      {...inputProp}
      data-testid={testId}
    />

    {error && <p className="text-xs tracking-wide text-red-700">{error}</p>}
    {info && (
      <p className="mt-1 text-xs tracking-wide text-slate-500">{info}</p>
    )}
  </div>
);
