import { default as cx } from "clsx";

import { InlineTextEditor } from "pages/auth/editCourse/Components/InlineTextEditor";

import { setOfferingCourse } from "redux/offering/offering.slice";

import { useAppDispatcher, useAppState } from "hooks/useStore";

const TitleEditor = () => {
    const { offeringCourse } = useAppState((s) => s.offering)
    const dispatcher = useAppDispatcher()

    return (
        <div className={cx("space-y-2")}>
            <div
                className={cx(
                    "border border-secondary-dark border-dashed p-px"
                )}
            >
                <InlineTextEditor
                    onChange={(text) => {
                        dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, courseTitle: text }));
                    }}
                    rows={2}
                    textClassName="text-3xl font-bold text-white"
                    value={offeringCourse?.offeringCourse?.courseTitle}
                />
            </div>
            <div
                className={cx(
                    "border border-secondary-dark border-dashed p-px"
                )}
            >
                <InlineTextEditor
                    onChange={(t) => {
                        dispatcher(setOfferingCourse({ ...offeringCourse?.offeringCourse, courseTitleDescription: t }));
                    }}
                    rows={2}
                    textClassName="text-base font-light text-white"
                    value={offeringCourse?.offeringCourse?.courseTitleDescription}
                />
            </div>
        </div>
    );
}

export default TitleEditor;