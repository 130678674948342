import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { modalConst } from 'redux/local/local.const';
import { courseType } from 'redux/course/course.const';

import Modal from 'components/modals/modal/Modal';
import RoleSwitchModal from 'components/modals/roleSwitchModal/RoleSwitchModal';
import CourseScheduleModal from 'components/modals/courseScheduleModal/CourseScheduleModal';
import NoOfSessionModal from 'components/modals/noOfSessionModal/NoOfSessionModal';
import ChooseTimeSlotModal from 'components/modals/chooseTimeSlotModal/ChooseTimeSlotModal';
import CourseCheckoutModal from 'components/modals/courseCheckoutModal/CourseCheckoutModal';
import MakePaymentModal from 'components/modals/makePaymentModal/MakePaymentModal';
import PaymentMethodModal from 'components/modals/paymentMethodModal/PaymentMethodModal';
import AddCreditModal from 'components/modals/addCreditModal/AddCreditModal';
import TodoModal from 'components/modals/todoModal/TodoModal';
import EventDetailModal from 'components/modals/eventDetailModal/EventDetailModal';
import EventActionModal from 'components/modals/eventActionModal/EventActionModal';
import CourseScheduleEditModal from 'components/modals/courseScheduleEditModal/CourseScheduleEditModal';
import UploadDocumentModal from 'components/modals/uploadDocumentModal/UploadDocumentModal';
import PreviewDocumentModal from "components/modals/uploadDocumentModal/PreviewDocumentModal";
import QuestionAnswerModal from 'components/modals/questionAnswerModal/QuestionAnswerModal';
import ChooseInterviewSlotModal from 'components/modals/chooseInterviewSlot/ChooseInterviewSlotModal';
import ComingSoonModal from 'components/modals/comingSoonModal/ComingSoonModal';
import ApplyReferralModal from 'components/modals/applyReferralModal/ApplyReferralModal';
import AuthorizationModal from 'components/modals/authorizationModal/AuthorizationModal';
import ReferenceFormModal from 'components/modals/referenceFormModal/ReferenceFormModal';
import PersonalDetailModal from 'components/modals/personalDetailModal/PersonalDetailModal';
import CodeVerificationModal from 'components/modals/codeVerificationModal/CodeVerificationModal';
import SubmitCourseModal from 'components/modals/submitCourseModal/SubmitCourseModal';
import MessageTemplateModal from 'components/modals/messageTemplateModal/MessageTemplateModal';
import ShareSocialModal from 'components/modals/shareSocialModal/ShareSocialModal';
import PostRequirementModal from 'components/modals/postRequirementModal/PostRequirementModal';
import CompanyPeopleModal from 'components/modals/companyPeopleModal/CompanyPeopleModal';
import ImagePickerModal from 'components/modals/imagePickerModal/ImagePickerModal';
import VerifyEmailModal from 'components/modals/verifyEmailModal/VerifyEmailModal';
import VerifyMobileNoModal from 'components/modals/verifyMobileNoModal/VerifyMobileNoModal';
import SpeakToUsModal from 'components/modals/speakToUsModal/SpeakToUsModal';
import NeedATutorModal from 'components/modals/needATutorModal/NeedATutorModal';
import TermsAndConditionModal from 'components/modals/termsAndConditionsModal/TermsAndConditionsModal';

const ModalContainer = () => {
    const { modal, bookingPayload } = useAppState(s => s.local)
    const { needTutor } = useAppState((state) => state.needTutor)
    const dispatcher = useAppDispatcher()

    const onSetModal = (stateKey, value) => {
        dispatcher(setModal({ ...modal, [stateKey]: value }))
    }

    const onBackModal = (stateKey) => {
        switch (stateKey) {
            case modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: false,
                    [modalConst.NO_SESSION_MODAL.stateKey]: true
                }))
                break;
            case modalConst.COURSE_CHECKOUT_MODAL.stateKey:
                if (bookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value)
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                        [modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]: true
                    }))
                if (bookingPayload?.course?.courseType === courseType.GROUP.value)
                    dispatcher(setModal({
                        ...modal,
                        [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: false,
                        [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: true
                    }))
                break;
            case modalConst.MAKE_PAYMENT_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.MAKE_PAYMENT_MODAL.stateKey]: false,
                    [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true
                }))
                break;
            case modalConst.EVENT_ACTION_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.EVENT_ACTION_MODAL.stateKey]: false,
                    [modalConst.EVENT_DETAIL_MODAL.stateKey]: true
                }))
                break;
            case modalConst.NEED_A_TUTOR_MODAL.stateKey:
                dispatcher(setModal({
                    ...modal,
                    [modalConst.NEED_A_TUTOR_MODAL.stateKey]: false,
                    [modalConst.SPEAK_TO_US_MODAL.stateKey]: true
                }))
                break;
            default:
                dispatcher(setModal({ ...modal }))
        }
    }

    return (
        <>
            <Modal
                title={"Switch Role"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.ROLE_SWITCH_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ROLE_SWITCH_MODAL.stateKey, isShow)}
                children={<RoleSwitchModal />}
            />
            <Modal
                title={"Course Schedule"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COURSE_SCHEDULE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COURSE_SCHEDULE_MODAL.stateKey, isShow)}
                children={<CourseScheduleModal />}
            />
            <Modal
                title={"Number of sessions"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.NO_SESSION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.NO_SESSION_MODAL.stateKey, isShow)}
                children={<NoOfSessionModal />}
            />
            <Modal
                title={"Choose time for your first session"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey, isShow)}
                onBackModal={() => onBackModal(modalConst.CHOOSE_TIME_SLOT_MODAL.stateKey)}
                children={<ChooseTimeSlotModal />}
            />
            <Modal
                title={"Course checkout"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COURSE_CHECKOUT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COURSE_CHECKOUT_MODAL.stateKey, isShow)}
                onBackModal={bookingPayload?.course?.courseType === courseType.ONE_ON_ONE.value ? () => onBackModal(modalConst.COURSE_CHECKOUT_MODAL.stateKey) : undefined}
                children={<CourseCheckoutModal />}
            />
            <Modal
                title={"Make Payment"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.MAKE_PAYMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.MAKE_PAYMENT_MODAL.stateKey, isShow)}
                onBackModal={() => onBackModal(modalConst.MAKE_PAYMENT_MODAL.stateKey)}
                children={<MakePaymentModal />}
            />
            <Modal
                title={"Payment method"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.PAYMENT_METHOD_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PAYMENT_METHOD_MODAL.stateKey, isShow)}
                children={<PaymentMethodModal />}
            />
            <Modal
                title={"Add Credit"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.ADD_CREDIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.ADD_CREDIT_MODAL.stateKey, isShow)}
                children={<AddCreditModal />}
            />
            <Modal
                title={"My Todo"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.TODO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.TODO_MODAL.stateKey, isShow)}
                children={<TodoModal />}
            />
            <Modal
                title={"Event Detail"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.EVENT_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.EVENT_DETAIL_MODAL.stateKey, isShow)}
                children={<EventDetailModal />}
            />
            <Modal
                title={"Event Action"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.EVENT_ACTION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.EVENT_ACTION_MODAL.stateKey, isShow)}
                onBackModal={() => onBackModal(modalConst.EVENT_ACTION_MODAL.stateKey)}
                children={<EventActionModal />}
            />
            <Modal
                title={"Edit Course Schedule"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COURSE_SCHEDULE_EDIT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COURSE_SCHEDULE_EDIT_MODAL.stateKey, isShow)}
                children={<CourseScheduleEditModal />}
            />
            <Modal
                title={"Upload Document"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.UPLOAD_DOCUMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.UPLOAD_DOCUMENT_MODAL.stateKey, isShow)}
                children={<UploadDocumentModal />}
            />
            <Modal
                title={"Preview"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.PREVIEW_DOCUMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PREVIEW_DOCUMENT_MODAL.stateKey, isShow)}
                children={<PreviewDocumentModal />}
            />
            <Modal
                title={"Edulyte Tutor's Placement"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.QUESTION_ANSWER_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.QUESTION_ANSWER_MODAL.stateKey, isShow)}
                children={<QuestionAnswerModal />}
            />
            <Modal
                title={"Choose time slot"}
                maxWidth={"max-w-md md:max-w-3xl"}
                openModal={modal[modalConst.CHOOSE_INTERVIEW_SLOT.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CHOOSE_INTERVIEW_SLOT.stateKey, isShow)}
                children={<ChooseInterviewSlotModal />}
            />
            <Modal
                title={"Coming Soon"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.COMING_SOON_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COMING_SOON_MODAL.stateKey, isShow)}
                children={<ComingSoonModal />}
            />
            <Modal
                title={"Referral"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.APPLY_REFERRAL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.APPLY_REFERRAL_MODAL.stateKey, isShow)}
                children={<ApplyReferralModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.AUTHORIZATION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.AUTHORIZATION_MODAL.stateKey, isShow)}
                children={<AuthorizationModal />}
            />
            <Modal
                title={"Reference Form"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.REFERENCE_FORM_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.REFERENCE_FORM_MODAL.stateKey, isShow)}
                children={<ReferenceFormModal />}
            />
            <Modal
                title={"Personal Details"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.PERSONAL_DETAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.PERSONAL_DETAIL_MODAL.stateKey, isShow)}
                children={<PersonalDetailModal />}
            />
            <Modal
                title={"Verify Your Account"}
                maxWidth={"max-w-lg"}
                openModal={modal[modalConst.CODE_VERIFICATION_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.CODE_VERIFICATION_MODAL.stateKey, isShow)}
                children={<CodeVerificationModal />}
            />
            <Modal
                title={"Submit Course"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SUBMIT_COURSE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SUBMIT_COURSE_MODAL.stateKey, isShow)}
                children={<SubmitCourseModal />}
            />
            <Modal
                title={"Select a Message"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.MESSAGE_TEMPLATE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.MESSAGE_TEMPLATE_MODAL.stateKey, isShow)}
                children={<MessageTemplateModal />}
            />
            <Modal
                title={"Share Great Things!"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.SHARE_SOCIAL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SHARE_SOCIAL_MODAL.stateKey, isShow)}
                children={<ShareSocialModal />}
            />
            <Modal
                title={"Post Your Requirement"}
                maxWidth={"max-w-sm"}
                openModal={modal[modalConst.POST_REQUIREMENT_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.POST_REQUIREMENT_MODAL.stateKey, isShow)}
                children={<PostRequirementModal />}
            />
            <Modal
                title={"Add People"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.COMPANY_PEOPLE_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.COMPANY_PEOPLE_MODAL.stateKey, isShow)}
                children={<CompanyPeopleModal />}
            />
            <Modal
                title={"Select Image"}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.IMAGE_PICKER_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.IMAGE_PICKER_MODAL.stateKey, isShow)}
                children={<ImagePickerModal />}
            />
            <Modal
                title={"Verify Email"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VERIFY_EMAIL_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VERIFY_EMAIL_MODAL.stateKey, isShow)}
                children={<VerifyEmailModal />}
            />
            <Modal
                title={"Verify Phone"}
                maxWidth={"max-w-md"}
                openModal={modal[modalConst.VERIFY_MOBILE_NO_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.VERIFY_MOBILE_NO_MODAL.stateKey, isShow)}
                children={<VerifyMobileNoModal />}
            />
            <Modal
                title={"Would you like to speak to us?"}
                maxWidth={"max-w-2xl"}
                openModal={modal[modalConst.SPEAK_TO_US_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.SPEAK_TO_US_MODAL.stateKey, isShow)}
                children={<SpeakToUsModal />}
            />
            <Modal
                title={""}
                maxWidth={"max-w-4xl"}
                openModal={modal[modalConst.NEED_A_TUTOR_MODAL.stateKey]}
                onCloseModal={(isShow) => {
                    if (needTutor?.needTutorPayload?.isDone) {
                        onSetModal(modalConst.NEED_A_TUTOR_MODAL.stateKey, isShow)
                    } else {
                        onBackModal(modalConst.NEED_A_TUTOR_MODAL.stateKey)
                    }
                }}
                children={<NeedATutorModal />}
            />
            <Modal
                title={"Terms and Conditions"}
                maxWidth={"max-w-xl"}
                openModal={modal[modalConst.TERMS_AND_CONDITIONS_MODAL.stateKey]}
                onCloseModal={(isShow) => onSetModal(modalConst.TERMS_AND_CONDITIONS_MODAL.stateKey, isShow)}
                children={<TermsAndConditionModal />}
            />
        </>
    )
}

export default ModalContainer
