import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import clsx from "clsx"

import { BsInfoCircle } from "react-icons/bs"
import { FaSpinner } from "react-icons/fa";

import { createVerifyReferral } from "redux/auth/auth.request";

import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setClearSignUpPayload, setSignUpPayload } from "redux/auth/auth.slice";
import { setModal } from "redux/local/local.slice";
import { modalConst } from "redux/local/local.const";

const ApplyReferralModal = () => {
    const { logInSignUp, verifyReferral } = useAppState((s) => s.auth)
    const { modal } = useAppState((s) => s.local)
    const dispatcher = useAppDispatcher()

    const [isToggle, setIsToggle] = useState(false)
    const [referralCode, setReferralCode] = useState("")

    useEffect(() => {
        if (logInSignUp?.signUpPayload?.referralCode) {
            setReferralCode(logInSignUp?.signUpPayload?.referralCode)
        }
    }, [logInSignUp?.signUpPayload?.referralCode])

    const handleCodeValidation = () => {
        if (!isToggle && referralCode) {
            dispatcher(createVerifyReferral(referralCode))
            setIsToggle(!isToggle)
        }
        if (isToggle) {
            setReferralCode("")
            setIsToggle(!isToggle)
        }
        if (!referralCode) {
            toast.warning("please enter code to verify")
        }
    }

    const handleSubmitButton = () => {
        dispatcher(setSignUpPayload({
            ...logInSignUp?.logInSignUpPayload,
            referralCode: referralCode
        }))
        dispatcher(setModal({
            ...modal,
            [modalConst.APPLY_REFERRAL_MODAL.stateKey]: false
        }))
    }

    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[12rem] block"}>
                <div className='flex flex-col justify-center items-center space-y-5'>
                    <label className='font-bodyPri font-medium text-text-900 text-md'>
                        {"Enter Referral Code"}
                    </label>
                    <div className="flex flex-col gap-1">
                        <div className="flex justify-center items-center space-x-3">
                            <input
                                type={"text"}
                                className={"w-full border-2 border-divider-medium bg-white focus:outline-none rounded-md py-1 px-3 flex items-center font-bodyPri font-normal text-text-900 text-base tracking-wide"}
                                onChange={(e) => setReferralCode(e.target.value)}
                                value={referralCode}
                                autoFocus={true}
                            />
                            <div className={"flex justify-center"}>
                                <span
                                    className={clsx(
                                        "w-20 flex justify-center items-center py-1 rounded-md font-buttons font-normal text-base text-center cursor-pointer",
                                        logInSignUp?.isLoading && "border-primary-light text-primary-light",
                                        !logInSignUp?.isLoading && "border border-primary-dark text-primary-dark hover:text-text-50 hover:bg-primary-dark"
                                    )}
                                    onClick={handleCodeValidation}
                                >
                                    {isToggle ? "Clear" : "Check"}
                                </span>
                            </div>
                        </div>
                        {verifyReferral?.isLoading && <FaSpinner className="animate-spin text-xl text-primary-main" />}
                        {(!verifyReferral?.isLoading && isToggle) &&
                            <span className="flex justify-start items-center gap-2">
                                <BsInfoCircle className={clsx(
                                    "text-sm",
                                    verifyReferral?.verifyReferral?.referralCode && "text-green-500",
                                    !verifyReferral?.verifyReferral?.referralCode && "text-red-500"
                                )} />
                                <p className={clsx(
                                    "font-bodySec font-normal text-xs tracking-wide",
                                    verifyReferral?.verifyReferral?.referralCode && "text-green-500",
                                    !verifyReferral?.verifyReferral?.referralCode && "text-red-500"
                                )}>
                                    {verifyReferral?.verifyReferral?.referralCode && "Code Verified Successfully!"}
                                    {!verifyReferral?.verifyReferral?.referralCode && "Invalid Referral Code"}
                                </p>
                            </span>
                        }
                    </div>
                </div>
            </div>
            <div className={"p-3 px-5 flex justify-end"}>
                <span
                    className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                    onClick={handleSubmitButton}
                >
                    {"Submit"}
                </span>
            </div>
        </div>
    )
}

export default ApplyReferralModal