import { arrayRemove, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { database } from "config/firebase"

export default async function deleteGroupUsers(userId, groupId) {
  const groupRef = doc(database, "chats", groupId);
  const groupMemberRef = doc(database, "chats", groupId, "members", userId);

  await updateDoc(groupRef, {
    users: arrayRemove(userId),
  });

  await deleteDoc(groupMemberRef);
}
