import { useParams } from "react-router-dom";

import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import Icon from "components/Icon";
import RatingStars from "components/ratingStars/RatingStars";

import Container from "pages/global/tutor/Container";
import StickyCard from 'pages/global/tutor/StickyCard';

import {
  createFavTutorUserDetailByUserId,
  deleteFavTutorUserDetailByUserId
} from 'redux/tutor/tutor.request';

import { useAppState, useAppDispatcher } from "hooks/useStore";

const Profile = ({ tutor, ...props }) => {
  const { logInSignUp } = useAppState(s => s.auth)
  const { user } = useAppState((s) => s.user)

  const dispatcher = useAppDispatcher()
  const { userId } = useParams()

  const filteredFavorite = user?.user?.favTutors?.filter((element) => element?.tutorId === tutor?.tutorId)?.length > 0

  const NEW_TUTOR_PUBLIC_PROFILE_RATING = 2;

  const handleFavTutor = () => {
    if (filteredFavorite) {
      dispatcher(deleteFavTutorUserDetailByUserId(Number(userId), { tutorId: tutor?.tutorId }))
    }
    else {
      dispatcher(createFavTutorUserDetailByUserId(Number(userId), { tutorId: tutor?.tutorId }))
    }
  }

  return (
    <Container
      id={props.id}
      leftSide={
        <div className="p-0 md:p-6">
          <div className="grid grid-cols-12 gap-5 py-3">
            <div className="col-span-3 md:col-span-4 flex items-center flex-col gap-3">
              <div className="relative w-20 h-20 rounded-full sm:w-20 sm:h-20 md:w-36 md:h-36">
                <img
                  src={tutor?.profilePicUrl}
                  alt="profileImg"
                  className="w-full h-full rounded-full p-2 object-cover"
                />
                <div className='absolute bottom-2.5 right-2.5 w-[22%] h-[22%] rounded-full overflow-hidden border-2 border-white'>
                  <img
                    src={`https://countryflagsapi.com/png/${tutor?.fromCountryDomain}`}
                    alt={"country-flag"}
                    className='w-full h-full object-cover'
                  />
                </div>
              </div>
              <div className="flex flex-col items-center gap-1">
                {tutor?.avgRating > NEW_TUTOR_PUBLIC_PROFILE_RATING
                  ? <>
                    <RatingStars
                      rating={tutor.avgRating}
                      fullRating={5}
                    />
                    <div className="font-normal text-xs text-complementry-main">
                      {tutor?.avgRating} ({tutor?.ratingCount})
                    </div>
                  </>
                  : <div className='text-sm font-bodyPri tracking-wide text-complementry-main'>{"New"}</div>}
              </div>
            </div>
            <div className="col-span-9 md:col-span-8 flex flex-col space-y-5 p-2">
              <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                  <div className="flex gap-3 text-2xl font-bodyPri tracking-wide">
                    <div className="flex items-center gap-2">
                      {tutor?.firstName} {tutor?.lastName}
                      {tutor?.onlineStatus == "online"
                        && <div className={"w-3 h-3 rounded-full bg-green-600"}></div>
                      }
                    </div>
                    {tutor?.instantBooking &&
                      <div className="">
                        <Icon type="light" />
                      </div>
                    }
                  </div>
                  {logInSignUp?.isLogedIn &&
                    <div className="flex gap-3">
                      <div className={""} onClick={handleFavTutor}>
                        {filteredFavorite
                          ? <FavoriteIcon className='text-red-500 cursor-pointer' style={{ fontSize: "1.8rem" }} />
                          : <FavoriteBorderIcon className='text-text-500 cursor-pointer' style={{ fontSize: "1.8rem" }} />
                        }
                      </div>
                      <div className="">
                        <Icon type="share" />
                      </div>
                    </div>
                  }
                </div>
                <div className="font-bodyPri text-lg">{tutor?.elevatorPitch}</div>
                {tutor?.ratingCount < NEW_TUTOR_PUBLIC_PROFILE_RATING
                  ? <div className='h-1 w-5 bg-secondary-main rounded-lg'></div>
                  : <div className='h-1 w-5 bg-primary-main rounded-lg'></div>
                }
              </div>

              {/* <div className='flex gap-2 items-center'>
                {tutor?.badges?.slice(0, 3).map((items, index) => (
                  <div
                    key={index}
                    className='relative has-tooltip w-8 h-8 rounded-full border border-text-500 p-0.5 cursor-pointer'
                  >
                    <img
                      src={items.badgePicUrl}
                      alt={items.badgeCategory}
                      className={"w-full h-full object-cover"}
                    />
                    <span className='tooltip top-8 -left-5 px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide'>
                      {items.badge}
                    </span>
                  </div>
                ))}
                {tutor?.badges?.length > 3 &&
                  <span className='relative has-tooltip text-primary-main font-medium text-lg cursor-pointer'>
                    {`+${tutor?.badges?.length - 3}`}
                    <span className='tooltip top-8 -left-5 w-20 px-2 py-1 bg-black text-white rounded-lg font-bodyPri font-normal text-xs tracking-wide'>
                      {"see more"}
                    </span>
                  </span>
                }
              </div> */}

              <div className="flex flex-col gap-3">
                <div className="flex text-base font-bodyPri tracking-wide gap-2">
                  <Icon type="comment" />
                  <span className="text-text-600">
                    {"Speaks:"}
                  </span>
                  {tutor?.languages.slice(0, 2).map((language, index) => (
                    <span
                      key={index}
                      className="flex justify-center items-center gap-1 text-medium font-medium"
                    >
                      {index !== 0 &&
                        <p>{"|"}</p>
                      }
                      {language?.language}
                    </span>
                  ))}
                </div>
                <div className="flex text-base font-bodyPri tracking-wide gap-2 overflow-hidden">
                  <Icon type="teach" />
                  <span className="text-text-600">
                    {"Teaches:"}
                  </span>
                  <span className="text-medium font-medium">
                    {tutor?.categories.slice(0, 2).join(" | ")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      rightSide={<StickyCard tutor={tutor} {...props} />}
    />
  );
};

export default Profile;
