import * as React from "react";
import { FaCreditCard } from "react-icons/fa";

export const PaymentPage = () => {
  return (
    <div className="">
      <div className="py-4 mx-8 border-b">
        <p className="text-lg text-left">Payment Methods</p>
      </div>
      <div className="flex ml-4 space-x-8">
        <div className="flex items-center px-4 space-x-1">
          <FaCreditCard size={20} />
          <div className="pl-3">
            <p>Card Ending with</p>
            <p className="text-sm text-slate-600">**569</p>
          </div>
        </div>
        <div>
          <p>Expiration Date</p>
          <p className="text-sm text-slate-600">11.2021</p>
        </div>
      </div>
    </div>
  );
};
