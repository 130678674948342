import * as React from "react";
import clsx from "clsx";
import dayjs from "dayjs";

import ComponentLoader from "components/loader/ComponentLoader"

import { useAppDispatcher, useAppState } from "hooks/useStore";
import { setClearUserEventList } from "redux/event/event.slice"
import { getUserEventListByUserId } from 'redux/event/event.request';
import { eventStatus } from "redux/event/event.const";

const curday = dayjs();

const isCurrentTime = (events: any) => {

  return (
    curday.isAfter(dayjs(events?.startDateTime, "hh:mm A")) &&
    curday.isBefore(dayjs(events?.endDateTime, "hh:mm A"))
  );
};

export const TimelineView = ({ selectedDate }: any) => {
  const { userEventList } = useAppState((s) => s.event)
  const { locals } = useAppState((s) => s.local)
  const { user } = useAppState((s: any) => s.user)
  const dispatcher = useAppDispatcher()

  const filteredUserEventList = userEventList?.userEventList?.filter((events: any) => dayjs(events?.startDateTime)?.isSame(selectedDate, 'day'))

  React.useEffect(() => {
    dispatcher(getUserEventListByUserId(user?.user?.userId))

    return () => {
      dispatcher(setClearUserEventList())
    }
  }, [locals.userRole])

  return (
    <div>
      {filteredUserEventList?.length > 0 &&
        <div className="flex flex-col space-y-3">
          <ComponentLoader isLoading={userEventList?.isLoading} />
          {filteredUserEventList?.map((events: any, idx: any) => (
            <div key={idx} className="flex flex-col space-y-2">
              <div className="w-min">
                {isCurrentTime(events) && (
                  <div className={clsx("relative w-full h-0.5 bg-primary-main mb-2")}>
                    <span className="absolute w-2 h-2 bg-primary-main rounded-full -left-1 -top-[3px]" />
                  </div>
                )}
                {events?.status === eventStatus.SCHEDULED.value && (
                  <>
                    <p className="font-medium text-text-600 text-md">
                      {filteredUserEventList?.length + " Schedules"}
                    </p>
                    <SessionScheduleView
                      from={dayjs(events?.startDateTime)?.format('hh:mm A')}
                      to={dayjs(events?.endDateTime)?.format('hh:mm A')}
                      session={{
                        profile: events?.course!?.thumbnailPicUrl,
                        description: `${dayjs(events?.startDateTime)?.format('hh:mm A')} - ${dayjs(events?.endDateTime)?.format('hh:mm A')}`,
                        title: events?.eventName,
                      }}
                      isActive={isCurrentTime(events)}
                    />
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      }
      {userEventList?.errorMsg &&
        <p className="font-normal text-text-800 text-md pt-5">
          {userEventList?.errorMsg}
        </p>
      }
    </div>
  );
};


const SessionScheduleView: React.FC<{
  from: string;
  to: string;
  session: {
    profile: string;
    title: string;
    description: string;
  };
  isActive?: boolean;
}> = ({ from, session, to, isActive }) => {
  return (
    <div
      className={clsx(
        "w-full flex",
        isActive && "border-2 border-blue-400 bg-blue-300"
      )}
    >
      <div className="flex flex-col justify-between w-20">
        <p className="text-xs text-gray-500 whitespace-nowrap">
          {dayjs(from, "hh:mm A").format("hh:00 a")}
        </p>
        <p className="text-xs text-gray-500 whitespace-nowrap">
          {dayjs(to, "hh:mm A").format("hh:00 a")}
        </p>
      </div>
      <div
        className={clsx(
          "flex items-center space-x-3 w-full p-3 bg-text-200 rounded-lg",
          "w-52"
        )}
      >
        <div className="w-1/4 h-10 overflow-hidden">
          <img
            src={session.profile}
            alt={"img"}
            className="w-full h-full object-cover rounded-full"
          />
        </div>
        <div className="space-y-1 w-3/4 overflow-hidden">
          <p className="text-base font-medium font-bodyPri text-text-900 truncate">{session.title}</p>
          <p className="text-xs font-light text-text-700">
            {session.description}
          </p>
        </div>
      </div>
    </div>
  );
};
