export const url = {
    SEND_OTP: "/notifications/send-otp",
    VERIFY_OTP: "/notifications/verify-otp",
}

export const otpStatus = {
    SUBMITTED: {
        label: "Submitted",
        value: "submitted"
    },
    PENDING: {
        label: "Pending",
        value: "pending"
    },
    EXPIRED: {
        label: "Expired",
        value: "expired"
    }
}