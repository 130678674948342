export const AVAILABILITY_INITIAL_STATE = {
    availability: {
        isLoading: false,
        availability: null,
        errorMsg: null,
    },

    updateAvailability: {
        isLoading: false,
        updateAvailability: null,
        errorMsg: null,
    }
}