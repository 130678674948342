import clsx from "clsx";
import { useState } from "react";

import { OptionalQuestionAnswer, inputFieldQuestionAnswer } from "pages/auth/tutorOnboard/TutorOnboardData";
import { useAppState, useAppDispatcher } from "hooks/useStore";
import { setModal } from "redux/local/local.slice";
import { modalConst } from 'redux/local/local.const';


const QuestionAnswerModal = () => {
    const { modal } = useAppState(s => s.local)
    const dispatcher = useAppDispatcher()
    const [formData, setFormData] = useState(
        {
            textField: "",
            singleAnswer: null,
            multipleAnswer: []
        }
    )

    const handleSubmitButton = () => {
        // const tutorsAnswersList = tutorOnboarding?.tutorOnboardingPayload?.formData
        // dispatcher(setTutorOnboardingPayload({
        //     ...tutorOnboarding?.tutorOnboardingPayload,
        //     tutorsAnswer: [...tutorsAnswersList, formData]
        // }))
        dispatcher(setModal({
            ...modal,
            [modalConst.QUESTION_ANSWER_MODAL.stateKey]: false
        }))
    }

    return (
        <div className="space-y-5 overflow-x-hidden select-none p-5">
            <div className="min-h-[24rem] space-y-8">
                <div className="">
                    {inputFieldQuestionAnswer.map((questions, index) => (
                        <div key={index} className={"flex flex-col justify-items-start space-y-3"}>
                            <div className="flex justify-start gap-1">
                                <span className="text-red-400">{"*"}</span>
                                <span className="font-subHeading font-semibold text-base text-text-900">
                                    {questions}
                                </span>
                            </div>
                            <input
                                type={"text"}
                                className={"border-2 border-divider-medium bg-white rounded-md py-1.5 px-2 text-text-900 text-base font-bodyPri w-full focus:outline-none flex items-center gap-3 lg:w-120"}
                                onChange={(e) => setFormData({
                                    ...formData,
                                    textField: e.target.value
                                })}
                            />
                        </div>
                    ))}
                </div>
                <div className="min-h-[36rem] space-y-8">
                    {/* {OptionalQuestionAnswer?.map((ques, index) => (
                        <div key={index} className={"flex flex-col justify-items-start space-y-3"}>
                            <div className="flex justify-start gap-1">
                                <span className="text-red-400">{"*"}</span>
                                <span className="font-subHeading font-semibold text-base text-text-900">
                                    {ques?.question}
                                </span>
                            </div>
                            <span className={"space-y-2"}>
                                {ques?.answer?.options?.map((items, index) => (
                                    <span key={index} className={"flex items-center gap-3"}>
                                        <input
                                            type={"checkbox"}
                                            name={"items"}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    multipleAnswer: [...formData.multipleAnswer, items.label]
                                                })
                                            }}
                                            className={"border-2 border-divider-Dark"}
                                            required
                                        />
                                        <label className={"font-bodyPri font-normal text-base text-text-900"}>{items.label}</label>
                                    </span>
                                ))}
                            </span>
                        </div>
                    ))} */}
                    {OptionalQuestionAnswer?.map((ques, index) => (
                        <div key={index} className={"flex flex-col justify-items-start space-y-3"}>
                            <div className="flex justify-start gap-1">
                                <span className="text-red-400">{"*"}</span>
                                <span className="font-subHeading font-medium text-lg text-text-900">
                                    {ques?.question}
                                </span>
                            </div>
                            <span className={"space-y-2"}>
                                {ques?.answer?.options?.map((items, index) => (
                                    <span key={index} className={"flex items-center gap-3"}>
                                        <input
                                            type={"radio"}
                                            name={index}
                                            onChange={(e) => { setFormData({ ...formData, singleAnswer: items.label }) }}
                                            className={"border-2 border-divider-Dark"}
                                            required
                                        />
                                        <label className={"font-bodyPri font-normal text-base text-text-900"}>{items.label}</label>
                                    </span>
                                ))}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div className={"p-3 px-5 flex justify-end"}>
                <span
                    className={clsx(
                        "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                        "font-buttons font-normal text-base",
                        "text-secondary-main border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                    )}
                    onClick={handleSubmitButton}
                >
                    {"Submit"}
                </span>
            </div>
        </div>
    )
}

export default QuestionAnswerModal