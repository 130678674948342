import { toast } from "react-toastify";

import availabilityService from 'redux/availability/availability.service';
import {
    setAvailabilityLoading,
    setAvailability,
    setAvailabilityErrorMsg,

    setUpdateAvailabilityLoading,
    setUpdateAvailabilityErrorMsg,
} from 'redux/availability/availability.slice'


export const getUserAvailabilityByUserId = (userId) => async (dispatch) => {
    dispatch(setAvailabilityLoading(true));

    try {
        const requestData = {
            params: { userId: userId }
        }
        const response = await availabilityService.getUserAvailabilityByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAvailability(response.data.data))
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setAvailabilityErrorMsg(error.response.data.message || error.response.data.error))
    } finally {
        dispatch(setAvailabilityLoading(false));
    }
}

export const updateAvailabilityByUserId = (userId, body) => async (dispatch) => {
    dispatch(setUpdateAvailabilityLoading(true));

    try {
        const requestData = {
            params: { userId: userId },
            body: body
        }
        const response = await availabilityService.updateAvailabilityByUserId(requestData)
        if (response.status === 200) {
            dispatch(setAvailability(response.data.data))
            toast.success(response.data.message)
        } else {
            throw new Error(response)
        }
    } catch (error) {
        console.error(error.response.data.message || error.response.data.error)
        dispatch(setUpdateAvailabilityErrorMsg(error.response.data.message || error.response.data.error))
        toast.error(error.response.data.message || error.response.data.error)
    } finally {
        dispatch(setUpdateAvailabilityLoading(false));
    }
}