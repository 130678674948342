import reviewService from "redux/review/review.services"
import {
    setTutorReviewLoading,
    setTutorReviewList,
    setTutorReviewErrorMsg
} from "redux/review/review.slice"


export const getTutorReviewListByTutorId = (tutorId) => async (dispatch) => {
    dispatch(setTutorReviewLoading(true))
    const requestData = {
        params: { tutorId: tutorId },
        query: { status: "published" }
    }
    try {
        const response = await reviewService.getTutorReviewListByTutorId(requestData)
        if (response.status === 200) {
            dispatch(setTutorReviewList(response.data))
        } else {
            dispatch(setTutorReviewErrorMsg(response.statusText))
        }
    } catch (error) {
        console.error(error)
        dispatch(setTutorReviewErrorMsg(error.message))
    } finally {
        dispatch(setTutorReviewLoading(false))
    }
}