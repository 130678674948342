import { useEffect } from 'react';
import clsx from 'clsx';

import ComponentLoader from 'components/loader/ComponentLoader';

import { useAppState, useAppDispatcher } from 'hooks/useStore';
import { setModal } from 'redux/local/local.slice';
import { setAddBookingPayload } from 'redux/booking/booking.slice';

import { utcWeekDays } from 'utils/dateTime.utils';
import { modalConst } from 'redux/local/local.const';

import { dayjs, timeZone } from "utils/dateTime.utils";


const DayItems = ({ day, timeSlots }) => {
    return timeSlots?.length > 0 && (
        <div className={clsx(
            "col-spna-1 flex flex-col justify-start items-center gap-1 py-2 w-32 h-full",
            "border-2 border-divider-medium rounded-lg bg-primary-light"
        )}>
            <span className={"font-bodyPri font-medium text-base text-primary-dark"}>{day}</span>
            <div className={"flex flex-col items-center gap-1"}>
                {timeSlots?.length > 0 && timeSlots?.map((timeSlot, index) => (
                    <span key={index} className={"font-bodyPri font-medium text-base text-primary-dark"}>
                        {dayjs(utcWeekDays[day.toUpperCase()].date + " " + timeSlot?.startTime + "+00:00").tz(timeZone).format("hh:mm A")}
                    </span>
                ))}
            </div>
        </div>
    )
}


const CourseScheduleModal = () => {
    const { selectedCourse } = useAppState((s) => s.course)
    const { modal } = useAppState((s) => s.local)
    const { addBooking } = useAppState((s) => s.booking)
    const addBookingPayload = addBooking?.addBookingPayload

    const dispatcher = useAppDispatcher()

    useEffect(() => {
        dispatcher(setAddBookingPayload({
            ...addBookingPayload,
            course: selectedCourse?.selectedCourse,
            coursePctDiscount: selectedCourse?.selectedCourse?.discountPct,
            sessionDuration: selectedCourse?.selectedCourse?.sessionDuration,
            noOfSessions: selectedCourse?.selectedCourse?.noOfSessions,
            startDate: selectedCourse?.selectedCourse?.startDate,
            endDate: selectedCourse?.selectedCourse?.endDate,
            weeklyTimeSlot: selectedCourse?.selectedCourse?.weeklyTimeSlot
        }))
    }, [selectedCourse?.selectedCourse])

    const handleNext = () => {
        dispatcher(setModal({
            ...modal,
            [modalConst.COURSE_SCHEDULE_MODAL.stateKey]: false,
            [modalConst.COURSE_CHECKOUT_MODAL.stateKey]: true,
        }))
    }

    if (selectedCourse?.isLoading) {
        return (
            <ComponentLoader
                isLoading={selectedCourse?.isLoading}
                className={"min-h-[24rem] flex justify-center items-center"}
            />
        )
    }

    if (selectedCourse?.errorMsg) {
        return (
            <div className={"min-h-[24rem] flex justify-center items-center"}>
                <span className={"font-bodyPri font-medium text-md text-text-700"}>
                    {selectedCourse?.errorMsg}
                </span>
            </div>
        )
    }


    return (
        <div className={"space-y-5"}>
            <div className={"min-h-[24rem] flex flex-col gap-5 overflow-auto"}>
                <div className={"w-full flex items-center justify-center"}>
                    <span className={"turncate text-center font-bodyPri font-medium text-lg text-text-900"}>
                        {addBookingPayload?.course?.courseTitle.substring(0, 50) + (addBookingPayload?.course?.courseTitle.length > 50 ? "..." : "")}
                    </span>
                </div>
                <div className={"self-center gap-1 p-3 rounded-lg bg-primary-light border-2 border-divider-medium"}>
                    <div className={clsx(
                        "flex items-center justify-evenly gap-1",
                        "font-bodyPri font-normal text-sm text-text-800"
                    )}>
                        <span>{"Starts on"}</span>
                        <span>{"  "}</span>
                        <span>{"Ends on"}</span>
                    </div>
                    <div className={clsx(
                        "flex items-center justify-evenly gap-1",
                        "font-bodyPri font-medium text-base text-primary-dark"
                    )}>
                        <span>
                            {dayjs(addBookingPayload?.course?.startDate).tz(timeZone).format('ddd, DD MMM YYYY')}
                        </span>
                        <span>{"-"}</span>
                        <span>
                            {dayjs(addBookingPayload?.course?.endDate).tz(timeZone).format('ddd, DD MMM YYYY')}
                        </span>
                    </div>
                </div>
                <div className={"self-center w-fit grid grid-cols-2 gap-3 pb-2"}>
                    {addBookingPayload?.course?.weeklyTimeSlot &&
                        Object.keys(addBookingPayload?.course?.weeklyTimeSlot).map((day, index) => (
                            <DayItems key={index} day={day} timeSlots={addBookingPayload?.course?.weeklyTimeSlot[day]} />
                        ))
                    }
                </div>
            </div>
            <div className={"flex justify-end"}>
                <span onClick={handleNext} className={clsx(
                    "w-28 py-1 flex justify-center items-center rounded-full cursor-pointer",
                    "font-buttons font-normal text-base text-secondary-main",
                    "border border-secondary-main hover:bg-secondary-main hover:text-text-50"
                )}>
                    {"Next"}
                </span>
            </div>
        </div>
    )
}

export default CourseScheduleModal