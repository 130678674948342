import { Transition } from "@headlessui/react";
import * as React from "react";

type Props = {
  title: string;
  children: React.ReactNode;
};

const Item = ({ title, children }: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="w-full border rounded shadow-sm">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-lg font-medium">{title}</p>
        <div className="flex items-center justify-center w-8 h-8 border rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>

      <Transition
        show={isOpen}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <p className="px-4 pb-2 text-gray-700">{children}</p>
      </Transition>
    </div>
  );
};

type FAQ = {
  question: string;
  answer: string;
};

export const FAQSection: React.FC<{ faqs: FAQ[] }> = ({ faqs }) => {
  return (
    <div className="py-8">
      <div className="text-center">
        <h2 className="text-3xl">FAQs</h2>
      </div>
      <div className="grid gap-3 px-4 py-6 sm:grid-cols-2">
        {faqs.map((faq, index) => (
          <div key={index} className="flex items-start justify-start flex-1 w-full">
            <Item title={faq.question}>{faq.answer}</Item>
          </div>
        ))}
      </div>
    </div>
  );
};
