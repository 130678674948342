export const weeklyTimeSlot = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: []
}

export const localsConst = {
    USER_ROLE: {
        label: "User Role",
        value: "userRole"
    },
    TIME_ZONE: {
        lable: "Time zone",
        value: "timeZone"
    },
    CURRENCY: {
        lable: "Currency",
        value: "currency"
    }
}

export const socialAction = {
    SHARE_TUTOR_PROFILE: {
        label: "Share My Profile",
        value: "share_tutor_profile"
    },
    SHARE_TUTOR_COURSE: {
        label: "Share My Class",
        value: "share_my_course"
    }
}

export const termsAndConditionsAction = {
    REFERRAL_TERMS_AND_CONDITIONS: {
        label: "Referral Terms and Conditions",
        value: "referral_terms_and_conditions"
    }
}

export const modalConst = {
    ROLE_SWITCH_MODAL: {
        stateKey: 'isShowRoleSwitchModal'
    },
    COURSE_SCHEDULE_MODAL: {
        stateKey: 'isShowCourseScheduleModal'
    },
    NO_SESSION_MODAL: {
        stateKey: 'isShowNoOfSessionModal'
    },
    CHOOSE_TIME_SLOT_MODAL: {
        stateKey: 'isShowChooseTimeSlotModal'
    },
    COURSE_CHECKOUT_MODAL: {
        stateKey: 'isShowCourseCheckoutModal'
    },
    MAKE_PAYMENT_MODAL: {
        stateKey: 'isShowMakePaymentModal'
    },
    PAYMENT_METHOD_MODAL: {
        stateKey: 'isShowPaymentMethodModal'
    },
    ADD_CREDIT_MODAL: {
        stateKey: 'isShowAddCreditModal'
    },
    TODO_MODAL: {
        stateKey: 'isShowTodoModal'
    },
    EVENT_DETAIL_MODAL: {
        stateKey: 'isShowEventDetailModal'
    },
    EVENT_ACTION_MODAL: {
        stateKey: 'isShowEventActionModal'
    },
    COURSE_SCHEDULE_EDIT_MODAL: {
        stateKey: 'isShowCourseScheduleEditModal',
    },
    IMAGE_PICKER_MODAL: {
        stateKey: 'isShowImagePickerModal'
    },
    UPLOAD_DOCUMENT_MODAL: {
        stateKey: 'isShowUploadDocumentModal'
    },
    PREVIEW_DOCUMENT_MODAL: {
        stateKey: 'isShowPreviewDocumentModal'
    },
    QUESTION_ANSWER_MODAL: {
        stateKey: 'isShowQuestionAnswerModal'
    },
    CHOOSE_INTERVIEW_SLOT: {
        stateKey: 'isShowChooseIntervalSlot'
    },
    COMING_SOON_MODAL: {
        stateKey: 'isShowComingSoonModal'
    },
    APPLY_REFERRAL_MODAL: {
        stateKey: 'isShowApplyReferralModal'
    },
    AUTHORIZATION_MODAL: {
        stateKey: 'isShowAuthorizationModal'
    },
    REFERENCE_FORM_MODAL: {
        stateKey: 'isShowReferenceFormModal'
    },
    PERSONAL_DETAIL_MODAL: {
        stateKey: 'isShowPersonalDetailModal'
    },
    CODE_VERIFICATION_MODAL: {
        stateKey: 'isShowCodeVerificationModal'
    },
    SUBMIT_COURSE_MODAL: {
        stateKey: 'isShowSubmitCourseModal'
    },
    MESSAGE_TEMPLATE_MODAL: {
        stateKey: 'isShowMessageTemplateModal'
    },
    SHARE_SOCIAL_MODAL: {
        stateKey: 'isShowShareSocialModal'
    },
    POST_REQUIREMENT_MODAL: {
        stateKey: 'isShowPostRequirementModal'
    },
    COMPANY_PEOPLE_MODAL: {
        stateKey: 'isShowCompanyPeopleModal'
    },
    VERIFY_EMAIL_MODAL: {
        stateKey: 'isShowVerifyEmailModal'
    },
    VERIFY_MOBILE_NO_MODAL: {
        stateKey: 'isShowVerifyMobileNoModal'
    },
    SPEAK_TO_US_MODAL: {
        stateKey: 'isShowSpeakToUsModal'
    },
    NEED_A_TUTOR_MODAL: {
        stateKey: 'isShowNeedATutorModal'
    },
    TERMS_AND_CONDITIONS_MODAL: {
        stateKey: 'isShowTermsAndConditionsModal'
    }
}