import tw, { styled } from 'twin.macro';
import { Section, SectionContent } from 'components/generalComponent/StyledComponent';

export const Container = tw(Section)`
    bg-white
`;

export const Content = tw(SectionContent)`
    col-start-2 col-span-10 md:col-start-3 md:col-span-8 flex flex-col items-center justify-center
`;

export const Header = tw.div`
    flex flex-col items-center gap-5 md:mx-16 lg:mx-32
`;

export const Heading = tw.h3`
    font-heading font-semibold text-3xl text-text-900 text-center
`;

export const SubHeading = tw.h4`
    font-subHeading font-normal text-lg text-text-800 text-center
`;

export const ItemContainer = tw.div`
    grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-5 mt-10
`;

export const TutoringItemContainer = tw.div`
    flex flex-row items-start justify-self-center gap-5
    p-5 rounded-3xl cursor-pointer
`;

export const IconContainer = tw.div`
    flex items-center justify-center
`;

export const TutoringImage = styled.div`
    ${tw`
        p-4 shadow rounded-full bg-white w-16 h-16
    `}
    img {
        ${tw`
            w-full h-full object-cover
        `}
    }
`;

export const TextGroup = tw.div`
    flex flex[3] flex-col justify-start gap-3 mt-2
`;

export const TitleText = tw.h3`
    font-subHeading font-bold text-xl text-text-900 text-left
`;

export const DescriptionText = tw.p`
    font-bodyPri font-normal text-base  text-text-800 text-left
`;