import tw, { styled } from "twin.macro";

//Request payment modal main styles
export const StyledRequestPaymentModal = styled.div`
  ${tw`absolute h-screen w-screen flex flex-col items-center justify-center bg-white rounded-md shadow-md px-5 z-30 md:(md:width[350px] height[fit-content]) top[50%] left[50%] transform[translate(-50%, -50%)] py-5 `}
  h3 {
    ${tw`text-xl font-bold mb-5`}
  }
`;

export const StyledRequestPaymentModalContent = styled.div`
  ${tw`w-full mt-5`}
`;

//Error
export const StyledError = styled.p`
  ${tw`text-secondary-dark text-sm font-bold mt-1`}
`;
